import { useAuth0 } from '@auth0/auth0-react'
import { css } from '@emotion/css'
import { t, Trans } from '@lingui/macro'
import Logo from '@pp/assets/ip-logo.svg'
import BuyImage from '@pp/assets/mockup.png'
import { white } from '@pp/common/css/colors'
import { increaseSpecificity, mobileMediaQuery } from '@pp/common/css/css.helper'
import { useStores } from '@pp/store/useStore.hook'
import { useMediaQuery } from '@react-hook/media-query'
import { Grid, Modal, Image, Button } from 'semantic-ui-react'

import Icon from '../../../../common/components/icon/Icon'
import {
  modalCss,
  gridCss,
  rowCss,
  flexCss,
  logoCss,
  imageCss,
  titleCss,
  leftColumnCss,
  listCss,
  listItemCss,
  learnMoreCss,
} from '../buy-modal/buy-modal.css'

const closeButtonCss = css`
  ${increaseSpecificity(`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  `)}
`

const rightColorCss = (isMobile: boolean) => css`
  ${increaseSpecificity(`
  background-color: ${white};
  border-radius: ${isMobile ? '0 0 5px 5px' : '0 5px 5px 0'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;  
`)}
`

type UpgradeModalProps = { open: boolean }

function UpgradeModal({ open }: UpgradeModalProps) {
  const { applicationStore } = useStores()
  const isMobile = useMediaQuery(mobileMediaQuery)
  const { user } = useAuth0()
  const versions = user?.['https://app.ip-pilot.com/versions']
  const isTrial = versions && versions.length > 0 && versions.includes('Trial')

  const onModalClose = () => {
    document.body.className = document.body.className
      .split(' ')
      .filter((bodyClass) => bodyClass !== 'body-scrollable')
      .join(' ')
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    applicationStore.setModal({ open: false, version: '' })
  }

  return (
    <Modal open={open} dimmer className={modalCss(isMobile)} onClose={onModalClose}>
      {isMobile && (
        <Button
          primary
          onClick={() => applicationStore.setModal({ open: false, version: '' })}
          size="medium"
          className={closeButtonCss}
          icon={<Icon iconName="icon-big-x-mark" size={14} margin="0" />}
        />
      )}
      <Grid className={gridCss}>
        <Grid.Row className={rowCss}>
          <Grid.Column width={16}>
            <Image src={Logo} className={logoCss} alt="logo" />
            <span className={titleCss(isMobile)}>
              <Trans id="buyModal.listTitle" />
            </span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={rowCss}>
          <Grid.Column mobile={16} tablet={9} computer={9} className={leftColumnCss(isMobile)}>
            <div className={imageCss}>
              <Image
                className={css`
                  width: 100%;
                `}
                src={BuyImage}
                alt="buy-image"
              />
            </div>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={7} computer={7} className={rightColorCss(isMobile)}>
            <ul className={listCss}>
              <li className={listItemCss}>
                {isTrial ? (
                  <p>
                    This feature is not available during trial.
                    <br />
                    <b>
                      <Trans id="upgradeModal.title" />
                    </b>
                  </p>
                ) : (
                  <p>
                    This feature is not included in your subscription.
                    <br />
                    <b>
                      <Trans id="upgradeModal.title" />
                    </b>
                  </p>
                )}
              </li>
            </ul>
            <Button
              primary
              as="a"
              href="https://www.ip-pilot.com/en/get-access/?utm_medium=static_profiles"
              target="_blank"
            >
              <Trans id="upgradeModal.trialAccess.button" />
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={rowCss}>
          <Grid.Column width={16} className={flexCss}>
            <div
              className={learnMoreCss(isMobile)}
              dangerouslySetInnerHTML={{
                __html: t`buyModal.learnMore.text`,
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Modal>
  )
}

export default UpgradeModal
