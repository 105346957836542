import { RawData } from '@pp/common/typescript/helpers.type'
import * as R from 'ramda'

type THashTable = Record<string, Record<string, number>>
type TResult = { year: number } & Record<string, string | number>

const getYearValueHashTable = (years: number[], values: number[]): Record<string, number> => {
  const result = years
    .map((year, index) => ({ year, value: values[index] }))
    .filter((el) => el.value !== undefined)
    .map(({ year, value }) => ({ [year]: value }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {})

  return result
}

const getHashTable = (incomingData: RawData[]) => {
  const result: { [key: string]: { [key: number]: number } } = {}

  incomingData.forEach(({ name, categories, data, key }, _, array) => {
    const parsedName =
      array.filter((el) => el.name === name).length > 1 && key && typeof key === 'string' ? key.toUpperCase() : name
    result[parsedName] = getYearValueHashTable(categories, data)
  })

  return result
}

export const lineChartToXlsxExportFormat = (incomingData: RawData[]): TResult[] => {
  const rawYears = incomingData.map((el) => el.categories).reduce<number[]>((acc, curr) => [...acc, ...curr], [])
  const allYears = R.uniqBy((node) => node, rawYears).sort()

  const hashTable: THashTable = getHashTable(incomingData)
  const allApplicants = Object.keys(hashTable)

  const result: TResult[] = allYears.map((year) => {
    const dataByApplicants = allApplicants
      .map((applicant) => ({ applicant, value: hashTable[applicant][year] }))
      .filter((el) => el.value !== undefined)
      .map(({ applicant, value }) => ({ [applicant]: value }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {})

    return { year, ...dataByApplicants }
  })

  return result
}
