import { FilterType } from '@pp/common/components/filters/filter.store'
import { EntityFilter } from '@pp/common/components/filters/typescript/filters.type'

export function checkIsHighlighted(
  filterType: EntityFilter,
  activeFilters: FilterType,
  value?: string | number | boolean,
): boolean {
  if (value === undefined) return true
  const filter: (string | number)[] | undefined = activeFilters[filterType]
  if (filter && typeof value !== 'boolean') return filter.includes(value) || filter.includes(`!${value}`)
  return true
}
