import { DateType } from '@pp/modules/analyse/common/profile-header/components/default-filters/default-filters.type'

import { EPValidation } from '../components/table/ep-validations-table/ep-validations-table.type'

export const getDateBasedOnType = (dateType: DateType, validation: EPValidation) => {
  switch (dateType) {
    case DateType.publicationDate:
      return validation.ppDate
    case DateType.applicationFilingDate:
      return validation.filingDate
    case DateType.earliestPublicationDate:
      return validation.earliestPublnDate
    case DateType.grantDate:
      return validation.earliestGrantDate
    case DateType.transferDate:
      return validation.transferDate
  }
}
