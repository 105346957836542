import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { decode } from 'jsonwebtoken'

const INTERVAL = 1000 * 60 * 30 // 30 minutes
const OFSSET = INTERVAL + 30000 // 35 minutes

export const useToken = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const [token, setToken] = useState('')

  useEffect(() => {
    isAuthenticated &&
      getAccessTokenSilently().then((token) => {
        localStorage.setItem('token', token)
        setToken(token)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, isAuthenticated])

  useEffect(() => {
    setInterval(() => {
      if (token) {
        const decoded = decode(token)
        if (decoded && typeof decoded === 'object') {
          const { exp } = decoded as { exp: number }

          const expiresAt = new Date(exp * 1000)
          const currentTimeWithOffset = new Date().getTime() + OFSSET

          if (currentTimeWithOffset > expiresAt.getTime()) {
            getAccessTokenSilently().then((token) => {
              setToken(token)
              localStorage.setItem('token', token)
            })
          }
        }
      }
    }, INTERVAL) // every 30 minutes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return { token }
}
