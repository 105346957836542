import { compareFilters } from '@pp/common/helpers'
import { TrackingEvent } from '@pp/common/typescript/tracking.type'

import {
  checkIfNextEventIsValid,
  checkIfSameContainer,
  checkIfSearchEvent,
  getNextSearch,
  getPartialCheck,
  getPartialRenderCheck,
} from '.'

export const getPreviousDataFetchingEvents = (event: TrackingEvent, dataLayer: TrackingEvent[]) => {
  const nextSearchEvent = getNextSearch(event, dataLayer)
  if (!nextSearchEvent) return []

  const isNextSearchAdvanced = nextSearchEvent.pageInfo?.container === 'standard-search'
  const property = isNextSearchAdvanced ? ('page' as const) : ('tab' as const)
  const value = isNextSearchAdvanced ? nextSearchEvent.pageInfo?.page : nextSearchEvent.pageInfo?.tab

  return dataLayer.reduce((result, cv, index, array) => {
    if (index <= nextSearchEvent.index) {
      const checkIfRenderEvent = getPartialRenderCheck(property, value)
      const isRenderEvent = checkIfRenderEvent(cv) && compareFilters(cv.event?.filters, nextSearchEvent.event?.filters)
      const sameFilters = compareFilters(array[index + 1].event?.filters, nextSearchEvent.event?.filters)
      const checkIfEvent = getPartialCheck(property, value, nextSearchEvent)

      const isNextSearchEventEvent = isNextSearchAdvanced ? true : checkIfSearchEvent(array[index + 1], nextSearchEvent)
      const isNextPageView = checkIfEvent(array[index + 1], 'page-view') && sameFilters

      const isNextRenderEvent =
        checkIfRenderEvent(array[index + 1]) && checkIfSameContainer(array[index + 1], nextSearchEvent)

      const isNextMousePosAndPageView =
        checkIfEvent(array[index + 1], 'mouse-position') && checkIfEvent(array[index + 2], 'page-view') && sameFilters

      const isNextEventTimeAndMousePos =
        checkIfEvent(array[index + 1], 'time-on-page') &&
        checkIfEvent(array[index + 2], 'mouse-position') &&
        sameFilters

      const isNextEventMousePosAndTime =
        checkIfEvent(array[index + 1], 'mouse-position') &&
        checkIfEvent(array[index + 2], 'time-on-page') &&
        sameFilters

      // if I only would calculate time elapsed between search surbmitted and most recent data-rendered event
      // I would get incorrect values because data-rendered events can happen even if user doesn't submit search (i.e. applies and removes filter)
      // therefore I have to check for the following pattern to filter out those un-related data-rendered events
      // cannot look for the most recent data-rendered event because once search is submitted multiple data-rendered events are fired so
      // i have to go through these checks which assume that only mouse position and loading time from previous pages can get in between the render events

      const isNextEventValid = isNextSearchAdvanced
        ? true
        : checkIfNextEventIsValid([
            isNextSearchEventEvent,
            isNextPageView,
            isNextRenderEvent,
            isNextMousePosAndPageView,
            isNextEventTimeAndMousePos,
            isNextEventMousePosAndTime,
          ])

      if (isRenderEvent && isNextEventValid) result.push(cv)
    }

    if (index === array.length - 1) result.push(nextSearchEvent)
    return result
  }, [] as TrackingEvent[])
}
