import { useRef, useLayoutEffect } from 'react'

import { Effect } from '@pp/modules/settings/interfaces/settings.type'

const isBrowser = typeof window !== `undefined`

function getScrollPosition() {
  if (!isBrowser) return { x: 0, y: 0 }
  const target = document.body
  const position = target.getBoundingClientRect()
  return { x: position.left, y: position.top }
}

export function useScrollPosition(effect: Effect) {
  const position = useRef(getScrollPosition())
  const throttleTimeout = useRef(0)
  const wait = 500

  const callBack = () => {
    const currPos = getScrollPosition()
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout.current = 0
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (throttleTimeout.current === 0) throttleTimeout.current = window.setTimeout(callBack, wait)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  })
}
