import { useEffect } from 'react'

import { css } from '@emotion/css'
import { mobileMediaQuery, sidebarWidth } from '@pp/common/css/css.helper'
import { useEventTracker } from '@pp/common/custom-hooks/trackers/useEventTracker.hook'
import { useMediaQuery } from '@react-hook/media-query'
import { Loader } from 'semantic-ui-react'

const placeholder = css`
  min-height: 2500px;
`

const containerCss = (spinnerHeight: string | undefined = '250px') => css`
  position: relative;
  height: ${spinnerHeight};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`
const inlineContainerCss = css`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const spinnerContainer = (isMobile: boolean) => css`
  top: ${isMobile ? '0px' : '100px'};
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: 501px) {
    width: calc(100% - ${sidebarWidth});
  }

  @media (min-width: 1201px) {
    width: calc(77.5% - ${sidebarWidth});
  }
`

interface SpinnerProps {
  inline?: boolean
  profile?: boolean
  spinnerHeight?: string
  trackingData?: {
    container?: string
    entities: string[]
    measureMount: boolean
    measureUnMount: boolean
    page?: string
    tab?: string
    title: string
    url?: string
  }
}

const Spinner = ({ inline = false, profile, trackingData, spinnerHeight }: SpinnerProps) => {
  const isMobile = useMediaQuery(mobileMediaQuery)
  const { handleEventTracker } = useEventTracker()

  useEffect(() => {
    if (trackingData?.measureMount) {
      handleEventTracker({
        event: {
          type: 'loading-time',
          description: `${trackingData.page}:loading`,
          chart: trackingData.title,
          entities: trackingData.entities.reduce((cv, pv) => `${cv} ${pv}`, ''),
          entitiesArray: trackingData.entities,
        },
      })
    }
    return () => {
      if (trackingData?.measureUnMount) {
        handleEventTracker({
          event: {
            type: 'loading-time',
            description: `${trackingData.page}:data-rendered`,
            chart: trackingData.title,
            entities: trackingData.entities.reduce((cv, pv) => `${cv} ${pv}`, ''),
            entitiesArray: trackingData.entities,
          },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (profile)
    return (
      <div className={placeholder}>
        <div className={spinnerContainer(isMobile)}>
          <Loader active />
        </div>
      </div>
    )

  return (
    <div className={inline ? inlineContainerCss : containerCss(spinnerHeight)}>
      <Loader active />
    </div>
  )
}

export default Spinner
