export enum EntityType {
  Agent = 'agent',
  Applicant = 'applicant',
}

export interface EntitySearchResult {
  alternativeName?: string
  applnCount: number
  country: string
  id?: number
  isApplicant?: boolean
  key: string
  name: string
  profile: boolean
  rep_inhouse?: boolean
  tmCount: number
  validationCount?: number
  value: string
}
