import { useEffect } from 'react'

import { FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { useStores } from '@pp/store/useStore.hook'
import { useLocation } from 'react-router'

export const useToggleFamilyType = () => {
  const { applicationStore, filterStore } = useStores()
  const { search } = useLocation()

  const onToggleFamilyType = (familyType: FamilyType) => {
    applicationStore.setFamilyType([familyType])
    filterStore.updateFilters({})
  }

  const onToggleFamilyTypes = () => {
    applicationStore.setFamilyType(['P', 'T'])
    filterStore.updateFilters({})
  }

  useEffect(() => {
    if (!search.includes('family_type')) {
      if (applicationStore.hasTrademarksPermission && applicationStore.hasPatentsPermission) {
        return onToggleFamilyTypes()
      }
      if (applicationStore.hasTrademarksPermission) onToggleFamilyType('T')
      if (applicationStore.hasPatentsPermission) onToggleFamilyType('P')
    }

    // eslint-disable-next-line
  }, [applicationStore.hasPatentsPermission, applicationStore.hasTrademarksPermission])
}
