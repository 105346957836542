import { EntitySearchResult } from '@pp/common/components/entity-name-search/entity-search.interface'
import { arrayFromString } from '@pp/common/helpers'
import { DateType } from '@pp/modules/analyse/common/profile-header/components/default-filters/default-filters.type'
import { FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { RootStoreInterface } from '@pp/store/root.store'
import { makeAutoObservable } from 'mobx'

import { Results } from '../../common/components/SimilarityUiState'

export type SimilarityApplServerFilters = {
  applicant_url: string[]
  date_from: string[]
  date_to: string[]
  date_type: DateType[]
  family_type: FamilyType[]
  target_applicant_country?: string[] | undefined
}

export type SimilarityApplSearchParams = {
  any: []
  applicant_country: string[]
  date_from: number
  date_to: number
  date_type: string
}

type SearchParameterKeys = keyof SimilarityApplSearchParams
type OptionalSimilaritySearchParams = Partial<SimilarityApplSearchParams>

export enum SimilarityApplLimitingClause {
  ApplicantCountry = 'applicant_country',
}

type SimilaritySearchInterface = {
  focalFirms: EntitySearchResult[]
  limitingClauses: SimilarityApplLimitingClause[]
  results: Partial<Results> | null
  searchParameters: SimilarityApplSearchParams
}

const initialSearchParameters: SimilarityApplSearchParams = {
  date_type: 'm',
  date_from: 2015,
  date_to: 2023,
  applicant_country: [],
  any: [],
}

const newFilters = [{ prop: 'applicant_country' as const, value: SimilarityApplLimitingClause.ApplicantCountry }]

export class SimilarityApplicantsSearchStore implements SimilaritySearchInterface {
  private rootStore: RootStoreInterface
  focalFirms: EntitySearchResult[] = []
  searchParameters = initialSearchParameters
  results: Partial<Results> | null = null
  limitingClauses: SimilarityApplLimitingClause[] = [SimilarityApplLimitingClause.ApplicantCountry]

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  get serverFilters() {
    const filter = {
      family_type: this.rootStore.applicationStore.familyType,
      date_from: [this.searchParameters.date_from.toString()],
      date_to: [this.searchParameters.date_to.toString()],
      date_type: [this.searchParameters.date_type.toUpperCase() as DateType],
      applicant_url: this.focalFirms.map((el) => el.value),
      use_summary: true,
      summary_sufficient_urls: 500,
    }

    return filter
  }

  setFocalFirms = (focalFirms: EntitySearchResult[]) => (this.focalFirms = focalFirms)
  setResults = (results: Partial<Results> | null) => (this.results = results)
  setLimitingClauses = (clauses: SimilarityApplLimitingClause[]) => (this.limitingClauses = clauses)

  setSearchParameter = (searchParameter: OptionalSimilaritySearchParams) => {
    this.searchParameters = Object.assign({}, this.searchParameters, searchParameter)
  }

  unsetSearchParameter = (searchParameter: SearchParameterKeys) => {
    this.searchParameters[searchParameter] = initialSearchParameters[searchParameter] as never
  }

  resetSearchUI = () => {
    this.limitingClauses = []
    this.searchParameters = initialSearchParameters

    const routerStore = this.rootStore.routerStore
    routerStore.replace(routerStore.location.pathname)
  }

  initializeSearchFiltersFromUrl = (queryParameters: string) => {
    const urlSearchParams = new URLSearchParams(queryParameters)

    const familyType = urlSearchParams.get('familyType')
    if (familyType) this.rootStore.applicationStore.setFamilyType(familyType.split(',') as FamilyType[])

    const limitingClauses = urlSearchParams.get('limitingClauses')
    if (limitingClauses) this.limitingClauses = limitingClauses.split(',') as SimilarityApplLimitingClause[]

    this.searchParameters.date_from = urlSearchParams.get('date_from')
      ? Number(urlSearchParams.get('date_from'))
      : initialSearchParameters.date_from

    this.searchParameters.date_to = urlSearchParams.get('date_to')
      ? Number(urlSearchParams.get('date_to'))
      : initialSearchParameters.date_to

    this.searchParameters.date_type = urlSearchParams.get('date_type') || initialSearchParameters.date_type

    newFilters.forEach((el) => {
      if (this.limitingClauses.includes(el.value)) {
        this.searchParameters[el.prop] =
          arrayFromString(urlSearchParams.get(el.prop)) || initialSearchParameters[el.prop]
      }
    })
  }

  updateSearchParamsFromFilters = (filters: SimilarityApplServerFilters) => {
    const familyType = this.rootStore.applicationStore.familyType
    const language = `locale=${this.rootStore.applicationStore.language}`
    const searchStrings: string[] = [language]

    if (filters.applicant_url.length) searchStrings.push(`applicant_url=${filters.applicant_url.join(',')}`)
    if (this.limitingClauses.length) searchStrings.push(`limitingClauses=${this.limitingClauses}`)
    if (filters.target_applicant_country?.length) {
      searchStrings.push(`applicant_country=${filters.target_applicant_country.join(',')}`)
    }

    searchStrings.push(`date_from=${filters.date_from}`)
    searchStrings.push(`date_to=${filters.date_to}`)
    searchStrings.push(`date_type=${filters.date_type}`)

    const routerStore = this.rootStore.routerStore
    routerStore.replace(`${routerStore.location.pathname}?familyType=${familyType}&${searchStrings.join('&')}`)
  }
}
