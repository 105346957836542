import { MappedFilterType } from '@pp/common/components/filters/typescript/filters.type'
import _ from 'lodash'

export const mapStandardNegativeFilters = (
  options: { prop: string; values: string[] }[],
  mappedFilters: MappedFilterType,
) => {
  const clonedFilters = _.cloneDeep(mappedFilters)
  options.forEach((option: { prop: string; values: string[] }) => {
    const value = clonedFilters[option.prop]
    if (!value || (Array.isArray(value) && typeof value[0] === 'string' && !value[0].includes('!'))) return
    if (Array.isArray(value) && value[0] === option.values[0]) clonedFilters[option.prop] = [option.values[2]]
    else clonedFilters[option.prop] = [option.values[1]]
  })

  return clonedFilters
}
