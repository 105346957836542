import React, { ReactNode, useContext } from 'react'

import Spinner from '@pp/common/components/spinner/Spinner'
import { useEventTracker } from '@pp/common/custom-hooks/trackers/useEventTracker.hook'
import { GeoLocation } from '@pp/common/typescript/tracking.type'

import { useFetch } from '../../../common/custom-hooks/useFetch.hook'

const URL = 'https://ipapi.co/json/'

type InitialState = { geoInfo: GeoLocation }

const initialState: InitialState = {
  geoInfo: {
    ip: '',
    version: '',
    city: '',
    region: '',
    region_code: '',
    country: '',
    country_name: '',
    country_code: '',
    country_code_iso3: '',
    country_capital: '',
    country_tld: '',
    continent_code: '',
    in_eu: true,
    postal: '',
    latitude: 0,
    longitude: 0,
    timezone: '',
    utc_offset: '',
    country_calling_code: '',
    currency: '',
    currency_name: '',
    languages: '',
    country_area: 0,
    country_population: 0,
    asn: '',
    org: '',
    location: {
      lat: 0,
      lon: 0,
    },
  },
}

const GeoLocationContext = React.createContext(initialState)
const useGeoLocation = () => useContext(GeoLocationContext)

type Props = { children: ReactNode }

export const GeoLocationProvider = ({ children }: Props) => {
  const { handleEventTracker } = useEventTracker()
  const { response: geoInfo, error, isLoading } = useFetch({ url: URL, initialState: initialState.geoInfo })

  if (isLoading) return <Spinner />
  if (error) handleEventTracker({ event: { type: 'error', content: { error } } })

  if (geoInfo)
    geoInfo['location'] = {
      lat: geoInfo.latitude,
      lon: geoInfo.longitude,
    }

  return <GeoLocationContext.Provider value={{ geoInfo }}>{children}</GeoLocationContext.Provider>
}

export { useGeoLocation }
