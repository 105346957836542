import { Fragment } from 'react'

import { useStores } from '@pp/store/useStore.hook'
import { observer } from 'mobx-react-lite'

import Flag from '../flag/Flag'

type EntityLabelProps = { urlIdentifier: string }

const EntityLabel = ({ urlIdentifier }: EntityLabelProps) => {
  const { applicationStore } = useStores()

  const entities = applicationStore.metadata
  const entity = entities.find((entity) => entity.urlIdentifier === urlIdentifier)

  if (entity) {
    return (
      <Fragment>
        <span>{entity && entity.name && entity.name.toUpperCase()}</span>
        &nbsp;
        <Flag country={entity && entity.country} />
      </Fragment>
    )
  }
  return <span>{urlIdentifier}</span>
}

export default observer(EntityLabel)
