import { css } from '@emotion/css'
import { Trans } from '@lingui/react'
import HamburgerIcon from '@pp/assets/icons/hamburger-menu-icon.svg'
import HelpIcon from '@pp/assets/icons/help-icon.svg'
import Logo from '@pp/assets/ip-icon.webp'
import { increaseSpecificity, mobileHeaderHeight } from '@pp/common/css/css.helper'
import { useToggleFamilyType } from '@pp/common/custom-hooks/useToggleFamilyType.hook'
import * as routes from '@pp/routing/routes'
import { observer } from 'mobx-react-lite'
import { NavLink, useLocation } from 'react-router-dom'
import { Image } from 'semantic-ui-react'

import { textColor } from '../../../css/colors'

const headerMenuCss = css`
  height: ${mobileHeaderHeight};
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  z-index: 9;
`

const headerCss = (sidebarVisible: boolean) => css`
  box-shadow: ${sidebarVisible ? 'none' : '2px 2px 12px 0px #e4e4e4'};
  opacity: ${sidebarVisible ? '0 !important' : '1 !important'};
  visibility: ${sidebarVisible ? 'hidden !important' : 'visible !important'};
  transition: visibility 0.3s 0.4s linear, opacity 0.3s 0.4s linear;
  position: fixed;
  top: 0;
  z-index: 103;
  width: 100%;
  background-color: white;
  color: black;
`

const hamburgerIconCss = css`
  ${increaseSpecificity(`
  margin: 10px;
`)}
`

const titleCss = css`
  color: ${textColor};
  font-size: 18px;
  margin: 0;
  padding: 0;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
`

const helpIconCss = css`
  margin-left: 4px;
  cursor: pointer;
`

const mobileLogoCss = css`
  margin: 12px;
`

function MobileMenu({
  setSidebarVisible,
  sidebarVisible,
}: {
  setSidebarVisible: (sidebarVisible: boolean) => void
  sidebarVisible: boolean
}) {
  useToggleFamilyType()
  const handleBurgerClick = () => {
    setSidebarVisible(!sidebarVisible)
  }
  const { pathname } = useLocation()

  const getTitleFromRoute = (route: string) => {
    if (route.includes(routes.mainRoute)) {
      return <Trans id="page.title.homePage" />
    } else if (route.includes(routes.buildYourOwnChartsRoute)) {
      return <Trans id="sidebar.buttons.buildYourOwnCharts" />
    } else if (route.includes(routes.filingCheckRoute)) {
      return <Trans id="header.title.filing-check" />
    } else if (route.includes(routes.whitespotCheckRoute)) {
      return <Trans id="sidebar.menu.whitespot" />
    } else if (route.includes(routes.similarityRoute)) {
      return <Trans id="header.title.similarity" />
    } else if (route.includes(routes.mutualityRoute)) {
      return <Trans id="header.title.mutuality" />
    } else if (route.includes(routes.agentsRoute)) {
      return <Trans id="lawfirms.container.title" />
    } else if (route.includes(routes.applicantsRoute)) {
      return <Trans id="applicants.container.title" />
    } else if (route.includes(routes.advancedSearchRoute)) {
      return <Trans id="sidebar.buttons.search" />
    } else if (route.includes(routes.dashboardsRoute)) {
      return <Trans id="sidebar.buttons.dashboard" />
    } else if (route.includes(routes.settingsRoute)) {
      return <Trans id="sidebar.buttons.tooltip.settings" />
    }
  }

  const getHelpLinkFromRoute = (route: string) => {
    switch (route) {
      case routes.mainRoute:
        return 'https://www.ip-pilot.com/en/help-center/'
      case routes.buildYourOwnChartsRoute:
        return 'https://www.ip-pilot.com/en/help-center/'
      case routes.filingCheckRoute:
        return 'https://www.ip-pilot.com/en/help-center/'
      case routes.agentsRoute:
        return 'https://www.ip-pilot.com/en/help-center/'
      case routes.applicantsRoute:
        return 'https://www.ip-pilot.com/en/help-center/'
      case routes.whitespotCheckRoute:
        return 'https://www.ip-pilot.com/en/help-center/'
      case routes.advancedSearchRoute:
        return 'https://www.ip-pilot.com/en/help-center/'
      case routes.dashboardsRoute:
        return 'https://www.ip-pilot.com/en/help-center/'
      default:
        return 'https://www.ip-pilot.com/en/help-center/'
    }
  }

  return (
    <div className={headerCss(sidebarVisible)}>
      <div className={headerMenuCss}>
        <NavLink to={routes.mainRoute}>
          <Image className={mobileLogoCss} src={Logo} alt="logo" height="24px" width="24px" />
        </NavLink>
        <span className={titleCss}>
          {getTitleFromRoute(pathname)}
          <a href={getHelpLinkFromRoute(pathname)} target="_blank" rel="noreferrer">
            <Image className={helpIconCss} src={HelpIcon} alt="help" height="24px" width="24px" />
          </a>
        </span>
        <Image
          className={hamburgerIconCss}
          src={HamburgerIcon}
          alt="logo"
          height="24px"
          width="24px"
          onClick={handleBurgerClick}
        />
      </div>
    </div>
  )
}

export default observer(MobileMenu)
