import {
  ExportFinishedNotification,
  ExportStartedNotification,
} from '@pp/modules/analyse/common/export/export-profile.interface'
import { RootStoreInterface } from '@pp/store/root.store'
import { makeAutoObservable } from 'mobx'

import { Notification, Status } from './notification-center.type'

type NotificationStoreInterface = { notifications: Notification[] }

export class NotificationStore implements NotificationStoreInterface {
  private rootStore: RootStoreInterface

  notifications: Notification[] = []

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  startExport = ({ id, description }: ExportStartedNotification) => {
    const notifications = [...this.notifications]
    notifications.unshift({
      status: Status.Loading,
      id,
      description,
    })
    this.notifications = notifications
  }

  finishExport = ({ id, url, description }: ExportFinishedNotification) => {
    const notificationIndex = this.notifications.findIndex((entry) => entry.id === id)

    if (notificationIndex > -1) {
      const newNotifications = [...this.notifications]
      newNotifications[notificationIndex].status = url ? Status.Success : Status.Error
      newNotifications[notificationIndex].description = description
      newNotifications[notificationIndex].url = url

      return { entries: newNotifications }
    }
  }
}
