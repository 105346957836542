import { css } from '@emotion/css'
import { primaryGreen } from '@pp/common/css/colors'
import { increaseSpecificity } from '@pp/common/css/css.helper'

export const errorMessageCss = css`
  ${increaseSpecificity(`
    width: 90%;
    margin: auto;
    margin-top: 130px;
    background-color: transparent;
    padding: 2em 2em !important;
  `)}
`

export const h1Css = css`
  font-size: 2.5em;
`

export const h2Css = css`
  font-size: 2em;
`

export const h4Css = css`
  font-size: 1.5em;
  color: ${primaryGreen};
`
