import React, { useEffect, useState } from 'react'

import Logo from '@pp/assets/ip-logo.svg'
import { Dimmer, Header, Icon, Image } from 'semantic-ui-react'

import { dimmerCss, logoCss, iconCss } from './AdBlockWarning'

const EnvironmentWarning = () => {
  const [countDown, setCountDown] = useState(15)

  useEffect(() => {
    const timer = setInterval(() => {
      if (countDown > 0) setCountDown(countDown - 1)
    }, 1000)
    return () => clearInterval(timer)
  }, [countDown])

  if (countDown === 0) window.location.href = 'https://app.ip-pilot.com/'

  return (
    <Dimmer className={dimmerCss} active={true} page>
      <Image className={logoCss} src={Logo} alt="logo" />
      <Header as="h2" icon inverted>
        <Icon className={iconCss} name="ban" />
        It seems that you may have unintentionally attempted to access {`IP Pilot’s`} development version, which serves
        as a testing ground for new product features. <br></br>
        <br></br>If this was intentional, please <a href="mailto:info@ip-pilot.com">contact us </a>about gaining access.
        <br></br>
        <br></br>You are being redirected to the stable and official version of IP Pilot in {countDown} seconds.{' '}
        <br></br>
        <br></br> For future reference, please validate your bookmarks. Use{' '}
        <a href="http://app.ip-pilot.com/" rel="noreferrer">
          app.ip-pilot.com
        </a>{' '}
        {`(correct)`} and avoid app.dev.ip-pilot.com {`(not for public use)`} in your links.
      </Header>
    </Dimmer>
  )
}

export default EnvironmentWarning
