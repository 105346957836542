import { lightBlue } from '@pp/common/css/colors'
import { FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { observer } from 'mobx-react-lite'

import { DefaultProductBtnProps } from './IpButton'
import ProductButtonWrap from './ProductButtonWrap'
import { CustomButton } from '..'

type Props = DefaultProductBtnProps & { onToggleFamilyType: (familyType: FamilyType) => void }

const PatentButton = ({
  isMobile,
  minimizedSideBar,
  isAuthenticated,
  hasFamilyType,
  applicationStore,
  isSmallHight,
  onToggleFamilyType,
  ...rest
}: Props) => {
  const handleClick = () => {
    if (!applicationStore.hasPatentsPermission) applicationStore.setModal({ open: true, version: '' })
    else onToggleFamilyType('P')
  }
  return (
    <CustomButton
      active={hasFamilyType('P') && !hasFamilyType('T')}
      handleClick={handleClick}
      translationKey="sidebar.buttons.patents"
      disabled={isAuthenticated ? false : true}
      btnColor={lightBlue}
      showText={!isMobile && (isSmallHight || minimizedSideBar) ? false : true}
      {...rest}
    />
  )
}

export default ProductButtonWrap(observer(PatentButton))
