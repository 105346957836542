import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { midHeightQuery } from '@pp/common/css/css.helper'
import { FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { useStores } from '@pp/store/useStore.hook'
import { useMediaQuery } from '@react-hook/media-query'

const ProductButtonWrap = (Component: React.ElementType) =>
  function HOC({ ...rest }) {
    const { isAuthenticated } = useAuth0()
    const { applicationStore, filterStore } = useStores()
    const isSmallHight = useMediaQuery(midHeightQuery)

    const hasFamilyType = (familyType: FamilyType) => applicationStore.familyType?.includes(familyType)

    const onToggleFamilyTypes = () => {
      applicationStore.setFamilyType(['P', 'T'])
      filterStore.updateFilters({})
    }

    const onToggleFamilyType = (familyType: FamilyType) => {
      applicationStore.setFamilyType([familyType])
      filterStore.updateFilters({})
    }

    return (
      <Component
        isAuthenticated={isAuthenticated}
        applicationStore={applicationStore}
        isSmallHight={isSmallHight}
        hasFamilyType={hasFamilyType}
        onToggleFamilyTypes={onToggleFamilyTypes}
        onToggleFamilyType={onToggleFamilyType}
        {...rest}
      />
    )
  }

export default ProductButtonWrap
