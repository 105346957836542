import { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import IPIconInverted from '@pp/assets/icons/ip-icon-inverted.svg'
import IPIcon from '@pp/assets/icons/ip-icon-white.svg'
import PatentsIconInverted from '@pp/assets/icons/patents-icon-inverted.svg'
import PatentsIcon from '@pp/assets/icons/patents-icon.svg'
import SidebarCollapseIcon from '@pp/assets/icons/sidebar-collapse-icon.svg'
import TmIconInverted from '@pp/assets/icons/tm-icon-inverted.svg'
import TmIcon from '@pp/assets/icons/tm-icon.svg'
import { printCss } from '@pp/common/chart/config/charts-config'
import { midHeightQuery, sidebarHeightBreakpoint, sidebarWidthBreakpoint } from '@pp/common/css/css.helper'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { useMediaQuery } from '@react-hook/media-query'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
import { Sidebar as SUISidebar, Menu, Button } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'

import { FooterButtons, SidebarLogo, VerticalMenus } from './components'
import {
  buttonGroupCss,
  flexContainerCss,
  ipButtonCss,
  logoContainerCss,
  patButtonCss,
  sidebarCss,
  sliderCss,
  tmButtonCss,
} from './sidebar.css'
import { FamilyType } from '../../../../modules/analyse/common/types/app.type'
import { useToggleFamilyType } from '../../../custom-hooks/useToggleFamilyType.hook'

function Sidebar({
  isMobile,
  sidebarVisible,
  setSidebarVisible,
}: {
  isMobile: boolean
  setSidebarVisible: (sidebarVisible: boolean) => void
  sidebarVisible: boolean
}) {
  useToggleFamilyType()
  const { pathname } = useLocation()
  const { isAuthenticated } = useAuth0()
  const { routerStore, applicationStore, filterStore } = useStores()
  const [active, setActive] = useState(routes.agentsRoute)
  const isSmallHeight = useMediaQuery(midHeightQuery) || isMobile
  const isSidebarHeightBreakpoint = useMediaQuery(sidebarHeightBreakpoint)
  const isSidebarWidthBreakpoint = useMediaQuery(sidebarWidthBreakpoint)
  const { minimizedSideBar, toggleMinimizedSidebar, closeMinimizedSidebar } = applicationStore

  const [menus, setShowMenus] = useState({
    analyzeMenu: !isSmallHeight,
    searchMenu: !isSmallHeight,
    checkMenu: !isSmallHeight,
    resourceSubmenu: false,
  })

  useEffect(() => {
    const root = document.querySelector(':root')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    root?.style?.setProperty('--primary-color', getPrimaryColor())
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    root?.style?.setProperty('--primary-sidebar-color', getPrimarySidebarColor())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationStore.familyType])

  useEffect(() => {
    if (!minimizedSideBar && (isSidebarHeightBreakpoint || isSidebarWidthBreakpoint)) toggleMinimizedSidebar()
    if (minimizedSideBar && (!isSidebarHeightBreakpoint || !isSidebarWidthBreakpoint)) toggleMinimizedSidebar()
    if (isMobile) closeMinimizedSidebar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarHeightBreakpoint, isSidebarWidthBreakpoint])

  useEffect(() => {
    setShowMenus((prevState) => ({
      ...prevState,
      analyzeMenu: !isSmallHeight,
      searchMenu: !isSmallHeight,
      checkMenu: !isSmallHeight,
    }))
  }, [isSmallHeight])

  useEffect(() => {
    setActive(`/${pathname.split('/')[1]}`)
  }, [pathname, applicationStore.hasNegativeFilters])

  const onNavLinkClick = (route: string) => {
    routerStore.push(route)
    isMobile && setSidebarVisible(false)
  }

  const hasFamilyType = (familyType: FamilyType) => applicationStore.familyType?.includes(familyType)

  const onToggleFamilyTypes = () => {
    applicationStore.setFamilyType(['P', 'T'])
    filterStore.updateFilters({})
  }

  const onToggleFamilyType = (familyType: FamilyType) => {
    applicationStore.setFamilyType([familyType])
    filterStore.updateFilters({})
  }

  const handleClick = (familyType: string) => {
    switch (familyType) {
      case 'ip':
        if (!applicationStore.hasTrademarksPermission || !isAuthenticated)
          applicationStore.setModal({ open: true, version: '' })
        else onToggleFamilyTypes()
        break
      case 'tm':
        if (!applicationStore.hasTrademarksPermission || !isAuthenticated)
          applicationStore.setModal({ open: true, version: '' })
        else onToggleFamilyType('T')
        break
      case 'pat':
        if (!applicationStore.hasTrademarksPermission || !isAuthenticated)
          applicationStore.setModal({ open: true, version: '' })
        else onToggleFamilyType('P')
    }
  }

  const handleSingleClick = () => {
    if (minimizedSideBar) toggleMinimizedSidebar()
  }

  const isActiveFamilyType = (familyType: string) => {
    switch (familyType) {
      case 'ip':
        return isAuthenticated ? hasFamilyType('P') && hasFamilyType('T') : true
      case 'tm':
        return hasFamilyType('T') && !hasFamilyType('P')
      case 'pat':
        return !hasFamilyType('T') && hasFamilyType('P')
    }
  }

  const getCorrectIcon = (familyType: string[]) => {
    if (familyType.includes('P') && familyType.includes('T')) return IPIconInverted
    if (familyType.includes('T') && !familyType.includes('P')) return TmIconInverted
    if (familyType.includes('P') && !familyType.includes('T')) return PatentsIconInverted
  }

  const getCorrectText = (familyType: string[]) => {
    if (familyType.includes('P') && familyType.includes('T')) return <Trans id="sidebar.buttons.ippilot" />
    if (familyType.includes('T') && !familyType.includes('P')) return <Trans id="sidebar.buttons.trademarks" />
    if (familyType.includes('P') && !familyType.includes('T')) return <Trans id="sidebar.buttons.patents" />
  }

  const getPrimaryColor = () => {
    if (isActiveFamilyType('ip')) return '#96c347'
    if (isActiveFamilyType('tm')) return '#FFBF00'
    if (isActiveFamilyType('pat')) return '#85C1E9'
  }

  const getPrimarySidebarColor = () => {
    if (isActiveFamilyType('ip')) return '#F4F9EC'
    if (isActiveFamilyType('tm')) return '#FFF9E5'
    if (isActiveFamilyType('pat')) return '#E9F4FB'
  }

  const getFamilyTypeButtons = () => {
    return (
      <Button.Group className={buttonGroupCss(isMobile)}>
        <Button
          className={ipButtonCss(isActiveFamilyType, isMobile)}
          active={isActiveFamilyType('ip')}
          onClick={() => handleClick('ip')}
        >
          <Image
            className={css`
              margin-bottom: ${isMobile ? '0' : '3px'};
              margin-right: ${isMobile ? '8px' : '0'};
            `}
            src={isActiveFamilyType('ip') ? IPIconInverted : IPIcon}
            alt="ip-thumbnail"
            width="24px"
            height="24px"
          />
          <Trans id="sidebar.buttons.ippilot" />
        </Button>
        <Button
          className={tmButtonCss(isActiveFamilyType, isMobile)}
          active={isActiveFamilyType('tm')}
          onClick={() => handleClick('tm')}
        >
          <Image
            className={css`
              margin-bottom: ${isMobile ? '0' : '3px'};
              margin-right: ${isMobile ? '8px' : '0'};
            `}
            src={isActiveFamilyType('tm') ? TmIconInverted : TmIcon}
            alt="tm-thumbnail"
            width="24px"
            height="24px"
          />
          <Trans id="sidebar.buttons.trademarks" />
        </Button>
        <Button
          className={patButtonCss(isActiveFamilyType, isMobile)}
          active={isActiveFamilyType('pat')}
          onClick={() => handleClick('pat')}
        >
          <Image
            className={css`
              margin-bottom: ${isMobile ? '0' : '3px'};
              margin-right: ${isMobile ? '8px' : '0'};
            `}
            src={isActiveFamilyType('pat') ? PatentsIconInverted : PatentsIcon}
            alt="pat-thumbnail"
            width="24px"
            height="24px"
          />
          <Trans id="sidebar.buttons.patents" />
        </Button>
      </Button.Group>
    )
  }

  const getFamilyTypeButton = () => {
    return (
      <Button
        className={css`
          width: 40px !important;
          height: 62px !important;
          background: var(--primary-color) !important;
          border-bottom: 1px solid #e6e6e6 !important;
          border-top: 1px solid #e6e6e6 !important;
          border-left: 1px solid #e6e6e6 !important;
          border-right: 1px solid #e6e6e6 !important;
          border-radius: 8px !important;
          padding: 6px !important;
          margin: 0px 24px 24px 24px !important;
          font-size: 12px !important;
          text-transform: uppercase !important;
          color: #ffffff !important;
        `}
        active={true}
        onClick={() => handleSingleClick()}
      >
        <Image
          className={css`
            margin-bottom: 3px;
          `}
          src={getCorrectIcon(applicationStore.familyType)}
          alt="pat-thumbnail"
          width="24px"
          height="24px"
        />
        {getCorrectText(applicationStore.familyType)}
      </Button>
    )
  }

  return (
    <>
      <SUISidebar
        as={Menu}
        className={cx(sidebarCss(isMobile), printCss, minimizedSideBar && sliderCss)}
        animation="overlay"
        direction={isMobile ? 'top' : 'left'}
        vertical={!isMobile}
        visible={sidebarVisible}
      >
        <div className={flexContainerCss}>
          <div className={logoContainerCss(isMobile)}>
            <SidebarLogo
              isMobile={isMobile}
              setSidebarVisible={setSidebarVisible}
              minimizedSideBar={minimizedSideBar}
            />

            {minimizedSideBar ? getFamilyTypeButton() : getFamilyTypeButtons()}

            <VerticalMenus
              menus={menus}
              setShowMenus={setShowMenus}
              isMobile={isMobile}
              isSmallHeight={isSmallHeight}
              minimizedSideBar={minimizedSideBar}
              active={active}
              onNavLinkClick={onNavLinkClick}
            />
          </div>

          <FooterButtons
            active={active}
            minimizedSideBar={minimizedSideBar}
            isSmallHeight={isSmallHeight}
            isMobile={isMobile}
            locale={applicationStore.language}
            setLocale={applicationStore.setLanguage}
            onNavLinkClick={onNavLinkClick}
          />
        </div>
      </SUISidebar>
      <div
        className={cx(
          printCss,
          css`
            display: ${isMobile ? 'none' : 'block'};
            position: fixed;
            left: ${minimizedSideBar ? '88px' : '192px'};
            top: calc(50% - 80px / 2);
            height: 80px;
            width: 20px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            box-shadow: rgb(240, 240, 240) 6px 2px 6px 0px;
            transition: left 0.6s;
            cursor: pointer;
            z-index: 105;
            background-color: white;
          `,
        )}
        onClick={toggleMinimizedSidebar}
      >
        <div
          className={css`
            background: var(--primary-color);
            -webkit-mask-image: url(${SidebarCollapseIcon});
            mask-image: url(${SidebarCollapseIcon});
            height: 32px;
            width: 8px;
            margin: 24px 6px;
          `}
        ></div>
      </div>
    </>
  )
}

export default observer(Sidebar)
