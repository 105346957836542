import { MappedFilterType } from '@pp/common/components/filters/typescript/filters.type'
import _ from 'lodash'

export const mapEpValType = (mappedFilters: MappedFilterType) => {
  const epValType = mappedFilters.ep_val_type
  if (!epValType || typeof epValType[0] !== 'string' || !epValType[0].includes('!')) return mappedFilters

  const clonedFilters = _.cloneDeep(mappedFilters)

  if (epValType.includes('!OUTBOUND' as never) && epValType.includes('!INBOUND' as never)) {
    clonedFilters.ep_val_type = ['INTERNAL']
  } else if (epValType.includes('!OUTBOUND' as never) && epValType.includes('!INTERNAL' as never)) {
    clonedFilters.ep_val_type = ['INBOUND']
  } else if (epValType.includes('!INBOUND' as never) && epValType.includes('!INTERNAL' as never)) {
    clonedFilters.ep_val_type = ['OUTBOUND']
  } else if (epValType.includes('!OUTBOUND' as never)) clonedFilters.ep_val_type = ['INBOUND', 'INTERNAL']
  else if (epValType.includes('!INBOUND' as never)) clonedFilters.ep_val_type = ['OUTBOUND', 'INTERNAL']
  else if (epValType.includes('!INTERNAL' as never)) clonedFilters.ep_val_type = ['OUTBOUND', 'INBOUND']

  return clonedFilters
}
