import { CSSProperties } from 'react'

import { css } from '@emotion/css'
import { lightGrey, primaryGreen, textColor, white } from '@pp/common/css/colors'
import { increaseSpecificity, sidebarWidth } from '@pp/common/css/css.helper'

export const disabledStyleCss = (isMobile: boolean) => css`
  color: #4d4d4d;
  font-size: 12px;
  padding: ${isMobile ? '11px 10px 11px 40px' : '11px 10px 11px 40px'};
  color: ${textColor};
  display: block;
  cursor: pointer;
`

export const iconCss = (minimizedSideBar: boolean) => css`
  ${increaseSpecificity(`
    margin-right: ${minimizedSideBar ? '0' : '8px'};
  `)}
`

export const disabledIconCss: CSSProperties = {
  padding: '5px',
  marginBottom: '20px',
  color: primaryGreen,
  fontSize: '1.5em',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const buttonCss = increaseSpecificity(`
  padding: 8px 5px;
`)

export const nonAuthenticatedIconCss = css`
  ${increaseSpecificity(`
  color: ${lightGrey};
  font-size: 1.25em;
  padding: 2px;
  pointer-events: none;
  .icon {
    margin: 0;
  }
  `)}
`

export const sidebarCss = (isMobile: boolean) => css`
  ${increaseSpecificity(`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh !important;
  border: none;
  background-color: ${white};
  border-radius: 0;
  box-shadow: ${isMobile ? 'none' : '2px 2px 12px 0px #E4E4E4'};
  overflow: hidden;
  width: ${isMobile ? '100%' : sidebarWidth};
  ${!isMobile && 'transition: width 0.6s;'};
  padding: ${isMobile ? '12px' : '0px'};
`)}
`

export const sliderCss = css`
  ${increaseSpecificity(`
  width: 88px
`)}
`

export const buttonMenuItemCss = (isMobile: boolean) => css`
  ${increaseSpecificity(`
  padding: 5px 10px;
  font-size: 1em;

  width: ${!isMobile && sidebarWidth};
  text-align: center;

  &:before {
      background-color: ${white};
  }
`)}
`
export const flexContainerCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

export const buttonGroupCss = (isMobile: boolean) => css`
  align-self: center;
  width: ${isMobile ? '100%' : 'auto'};
  padding: ${isMobile ? '16px 0 16px 0' : '16px'};
`

export const submenuCss = (active = false, isMobile: boolean) =>
  css`
    ${increaseSpecificity(`
  background-color: ${
    active ? 'linear-gradient(90deg, var(--primary-sidebar-color) 0%, rgba(244, 249, 236, 0) 100%)' : white
  };
  border-radius: 0;
  margin: 0;
  padding: 0;

  ${isMobile && 'display: flex; flex-direction: column; justify-content: center; align-items: center'};

  .item {
    border-top-color: #e0e1e2;
    padding: 0;
    width: 100%;
  }

  a,button,span {
    font-size: 12px;
    padding: 11px 10px 11px 40px;
    color: ${textColor};
    display: block;
    cursor: pointer;

    .notch {
      position: absolute;
      left: ${isMobile ? '12px' : '0'};
      top: 0;
      width: 4px;
      height: 32px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background: var(--primary-color);
      display: none;
    }

    &.active:hover {
      color: ${textColor};

      .notch {
        display: block; 
      }
    }

    &.active {
      color: ${textColor};
      font-weight: 500;
      background: linear-gradient(90deg, var(--primary-sidebar-color) 0%, rgba(244, 249, 236, 0) 100%);

      .notch {
        display: block; 
      }
    }

    &:visited: {
      font-weight: 500;
      color: ${textColor};
    }
    &:hover {
      font-weight: 500;
      background: linear-gradient(90deg, var(--primary-sidebar-color) 0%, rgba(244, 249, 236, 0) 100%);
      color: ${textColor};
    }
  }
`)}
  `

export const menuHeaderCss = (isMobile: boolean, minimizedSideBar: boolean, collapsable?: boolean) => css`
  ${increaseSpecificity(`
    position: relative;
    margin: 1px 0 0 0 !important;
    cursor: pointer;
    color: ${textColor};
    padding: ${isMobile ? '12px 16px 12px 24px' : minimizedSideBar ? '12px 16px' : '12px 16px'};
    font-size: 14px !important;
    font-weight: 500 !important;
    display: flex;
    justify-content: ${collapsable ? 'space-between' : 'normal'};
    align-items: center;
    ${minimizedSideBar && 'justify-content: center;'};
    ${isMobile ? 'width: 100vw;' : ''}

    a, span {
      display: flex;
      color: ${textColor};
    }

    .notch {
      position: absolute;
      left: ${isMobile ? '12px' : '0'};
      top: 0;
      width: 4px;
      height: 40px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background: var(--primary-color);
      display: none;
    }

    &.active {
      color: ${textColor};
      background: linear-gradient(90deg, var(--primary-sidebar-color) 0%, rgba(244, 249, 236, 0) 100%);

      .notch {
        display: block; 
      }
    }

    &.active:hover {
      background: linear-gradient(90deg, var(--primary-sidebar-color) 0%, rgba(244, 249, 236, 0) 100%);
      color: ${textColor};

      .notch {
        display: block; 
      }
    }

    &:hover {
      color: ${textColor};
      background: ${
        isMobile
          ? 'transparent'
          : ' linear-gradient(90deg, var(--primary-sidebar-color) 0%, rgba(244, 249, 236, 0) 100%)'
      };
    }

    .collapsed {
      transform: rotate(0deg);
      transition: transform 0.3s ease;
    }

    .expanded {
      transform: rotate(180deg);
      transition: transform 0.3s ease;
    }
  `)}
`

export const slideOutCss = (submenuVisible: boolean) => css`
  ${increaseSpecificity(`
    overflow: hidden;
    max-height: ${submenuVisible ? '200px' : '0'};
    transition: max-height 0.3s ease;
    width: 100%;
  `)}
`

export const logoContainerCss = (isMobile: boolean) => css`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: ${isMobile ? 'center' : 'left'};
`

export const loginMessageCss = css`
  margin: 0px 20px;
  font-weight: bold;
`

export const minimizedSidebarCss = css`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  :hover {
    color: ${primaryGreen};
    cursor: pointer;
  }
`

export const buttonsContainerCss = css`
  display: flex;
  justify-content: center;
`

export const menuCss = css`
  margin-bottom: 5px;
`

export const ipButtonCss = (isActiveFamilyType: (type: string) => boolean | undefined, isMobile: boolean) => css`
  ${increaseSpecificity(`
    background: ${isActiveFamilyType('ip') ? 'var(--primary-color) !important' : '#fafafa !important'};
    border-bottom: 1px solid #e6e6e6 !important;
    border-top: 1px solid #e6e6e6 !important;
    border-left: 1px solid #e6e6e6 !important;
    border-right: ${
      isActiveFamilyType('ip') ? '1px solid var(--primary-color) !important' : '1px solid #fafafa !important'
    };
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    padding: 8px 12px !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
    font-weight: ${isActiveFamilyType('ip') ? '700 !important' : '400 !important'};
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: ${isMobile ? 'row' : 'column'} !important;

    &.active {
      color: white;
    }

    &:not(.active):hover {
      background: #f4f9ec !important;
      border-bottom: 1px solid #96c347 !important;
      border-top: 1px solid #96c347 !important;
      border-left: 1px solid #96c347 !important;
      border-right: 1px solid #96c347 !important;
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
  `)}
`

export const tmButtonCss = (isActiveFamilyType: (type: string) => boolean | undefined, isMobile: boolean) => css`
  ${increaseSpecificity(`
    background: ${isActiveFamilyType('tm') ? 'var(--primary-color) !important' : '#fafafa !important'};
    border-bottom: 1px solid #e6e6e6 !important;
    border-top: 1px solid #e6e6e6 !important;
    border-left: 1px solid ${isActiveFamilyType('tm') ? 'var(--primary-color) !important' : '#e6e6e6 !important'};
    border-right: 1px solid ${isActiveFamilyType('tm') ? 'var(--primary-color) !important' : '#e6e6e6 !important'};
    padding: 8px 12px !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
    font-weight: ${isActiveFamilyType('tm') ? '700 !important' : '400 !important'};
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: ${isMobile ? 'row' : 'column'} !important;

    &.active {
      color: white;
    }

    &:not(.active):hover {
      background: #fff9e5 !important;
      border-bottom: 1px solid #ffbf00 !important;
      border-top: 1px solid #ffbf00 !important;
      border-left: 1px solid #ffbf00 !important;
      border-right: 1px solid #ffbf00 !important;
    }
  `)}
`

export const patButtonCss = (isActiveFamilyType: (type: string) => boolean | undefined, isMobile: boolean) => css`
  ${increaseSpecificity(`
    background: ${isActiveFamilyType('pat') ? 'var(--primary-color) !important' : '#fafafa !important'};
    border-bottom: 1px solid #e6e6e6 !important;
    border-top: 1px solid #e6e6e6 !important;
    border-left: ${
      isActiveFamilyType('pat') ? '1px solid var(--primary-color) !important' : '1px solid #fafafa !important'
    };
    border-right: 1px solid #e6e6e6 !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    padding: 8px 12px !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
    font-weight: ${isActiveFamilyType('pat') ? '700 !important' : '400 !important'};
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: ${isMobile ? 'row' : 'column'} !important;

    &.active {
      color: white;
    }

    &:not(.active):hover {
      background: #e9f4fb !important;
      border-bottom: 1px solid #85c1e9 !important;
      border-top: 1px solid #85c1e9 !important;
      border-left: 1px solid #85c1e9 !important;
      border-right: 1px solid #85c1e9 !important;
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  `)}
`
