import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
// import { cx, css } from '@emotion/css'
import { css } from '@emotion/css'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import IPIcon from '@pp/assets/icons/ip-icon-white.svg'
import PatentsIcon from '@pp/assets/icons/patents-icon.svg'
import TmIcon from '@pp/assets/icons/tm-icon.svg'
import Logo from '@pp/assets/ip-logo.svg'
import BuyImage from '@pp/assets/mockup.png'
import { useLocation } from 'react-router'
import { Grid, Button, Image } from 'semantic-ui-react'

import {
  gridCss,
  rowCss,
  imageCss,
  listCss,
  listItemCss,
  itemIconCss,
  flexCss,
  logoCss,
  titleCss,
  buttonCss,
  learnMoreCss,
  leftColumnCss,
  loginTextCss,
  rightColorCss,
} from './buy-modal.css'
import Icon from '../../../../common/components/icon/Icon'

type Props = { isMobile: boolean }

const Content = ({ isMobile }: Props) => {
  const { loginWithRedirect } = useAuth0()
  const { pathname } = useLocation()
  const handleLogin = () => loginWithRedirect({ appState: { returnTo: pathname } })

  return (
    <Grid className={gridCss}>
      <Grid.Row className={rowCss}>
        <Grid.Column width={16}>
          <Image src={Logo} className={logoCss} alt="logo" />
          <span className={titleCss(isMobile)}>
            <Trans id="buyModal.listTitle" />
          </span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={rowCss}>
        <Grid.Column mobile={16} tablet={9} computer={9} className={leftColumnCss(isMobile)}>
          <div className={imageCss}>
            <Image
              className={css`
                width: 100%;
              `}
              src={BuyImage}
              alt="buy-image"
            />
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={7} computer={7} className={rightColorCss(isMobile)}>
          <ul className={listCss}>
            <li className={listItemCss}>
              <Image className={itemIconCss} src={IPIcon} alt="ip-icon" />

              <Trans id="buyModal.listItem1" />
            </li>
            <li className={listItemCss}>
              <Image className={itemIconCss} src={PatentsIcon} alt="patents-icon" />

              <Trans id="buyModal.listItem2" />
            </li>
            <li className={listItemCss}>
              <Image className={itemIconCss} src={TmIcon} alt="trademarks-icon" />
              <Trans id="buyModal.listItem3" />
            </li>
            <li className={listItemCss}>
              <div
                className={learnMoreCss(isMobile)}
                dangerouslySetInnerHTML={{
                  __html: t`buyModal.learnMore.text`,
                }}
              />
            </li>
          </ul>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={rowCss}>
        <Grid.Column width={16} className={flexCss}>
          <span className={loginTextCss(isMobile)}>
            <Trans id="buyModal.loginMessage" />
          </span>
          <span
            className={css`
              ${isMobile ? 'width: 100%' : ''};
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            `}
          >
            <Button secondary className={buttonCss} onClick={handleLogin}>
              <Trans id="buyModal.login.button" />
              <Icon iconName="icon-key" size={12} margin="0 0 0 10px" />
            </Button>
            <Button
              primary
              className={buttonCss}
              as="a"
              href="https://www.ip-pilot.com/en/get-access/?utm_medium=static_profiles"
              target="_blank"
            >
              <Trans id="buyModal.getAccess.text" />
            </Button>
          </span>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Content
