import { EntitySearchResult } from '@pp/common/components/entity-name-search/entity-search.interface'
import { getNameOfField } from '@pp/modules/analyse/common/charts/technical-fields/technical-sectors'
import _ from 'lodash'
import * as R from 'ramda'

import { getCountryNameFromCode } from './get-country-name-from-code'

type TopClient = { country?: string; isApplicant?: boolean; name: string; urlIdentifier?: string }

const getTopClientsSummary = (topClients: TopClient[]): string => {
  if (topClients.length === 0) return ''

  if (topClients.length === 1) {
    return `Most important client is ${topClients[0].name}.`
  }

  let s = `Most important clients are ${topClients[0].name}`

  if (topClients.length === 2) {
    s += ` and ${topClients[1].name}`
  }

  if (topClients.length === 3) {
    s += `, ${topClients[1].name} and ${topClients[2].name}`
  }

  return s + `.`
}

const getTopTechnSectorsSummary = (topTechnSectors: (string | undefined)[] | undefined) => {
  if (!topTechnSectors) return ''

  let s = ''

  if (topTechnSectors.length >= 1) {
    s += `and very experienced in ${topTechnSectors[0]}`
  }

  if (topTechnSectors.length === 2) {
    s += ` and ${topTechnSectors[1]}`
  }

  if (topTechnSectors.length === 3) {
    s += `, ${topTechnSectors[1]} and ${topTechnSectors[2]}`
  }

  return s + `.`
}

const renderParagraph = (
  metadata: EntitySearchResult,
  topClients: TopClient[],
  topTechnSectors: (string | undefined)[] | undefined,
) => {
  const country = getCountryNameFromCode(metadata.country)

  const firstParagraph = [
    `${metadata.name} is an intellectual property firm`,
    country ? ` from ${_.capitalize(country)},` : '',
    'prosecuting patents and/or trademarks,',
    getTopTechnSectorsSummary(topTechnSectors),
    topClients ? getTopClientsSummary(topClients) : '',
  ].join(' ')

  return firstParagraph
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateProfileDescription = (metadata: EntitySearchResult, data: any): string => {
  const topTechnSectors = Array.isArray(data.lfOverviewTechnField)
    ? R.take(
        3,
        R.map((sector) => {
          return getNameOfField(sector.name.toString())
        }, data.lfOverviewTechnField),
      )
    : undefined

  const topClients = R.take(
    3,
    R.map(({ country, isApplicant, name, urlIdentifier }) => {
      return {
        country,
        isApplicant,
        name,
        urlIdentifier,
      }
    }, data.lfOverviewClientsByYear || []),
  )

  return `${renderParagraph(metadata, topClients, topTechnSectors)}`
}
