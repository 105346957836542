import { EntityFilter } from './typescript/filters.type'

export const temporaryFilters: EntityFilter[] = [
  'lifecycle',
  'applicant_url',
  'rep_url',
  'publn_auth',
  'applicant_country',
  'direction',
  'grant_status',
  'ipc_1',
  'ipc_4',
  'legal_state',
  'route',
  'applicant_origin',
  'sequence',
  'techn_field_nr',
  'techn_sector_nr',
  'rep_url',
  'rep_country',
  'representative_type',
  'decision_maker',
  'attorney_url',
  'val_appln_auth',
  'ep_val_type',
  'val_rep_url',
  'val_rep_country',
  'ep_val_rep_url',
  'ep_val_rep_country',
  'ep_val_applicant_url',
  'ep_val_applicant_country',
  'ep_val_foreign_rep_url',
  'val_decision_maker',
  'val_service_provider',
  'applicant_geo_bounds_name',
  'rep_geo_bounds_name',
  'appln_type',
  'rep_locality',
  'nice_class',
  'nice_category',
  'verbal_element',
  'dom_publn_auth',
  'dom_rep_country',
  'dom_rep_url',
  'int_publn_auth',
  'int_rep_country',
  'int_rep_url',
  'int_decision_maker',
  'foreign_appln_auth',
  'foreign_publn_auth',
  'foreign_rep_country',
  'foreign_rep_url',
  'applicant_locality',
]
