import { css } from '@emotion/css'
import MenuCloseIcon from '@pp/assets/icons/menu-close-icon.svg'
import IconLogo from '@pp/assets/ip-icon.webp'
import Logo from '@pp/assets/ip-logo.svg'
import { increaseSpecificity, sidebarWidth } from '@pp/common/css/css.helper'
import * as routes from '@pp/routing/routes'
import { NavLink } from 'react-router-dom'
import { Menu, Image } from 'semantic-ui-react'

const collapsedLogoCss = css`
  height: 40px;
`

const logoItemCss = css`
  ${increaseSpecificity(`
  background-color: white;
  position: static;
  min-width: ${sidebarWidth};
  padding: 24px 0px 0px;
`)}
`

const logoCss = css`
  ${increaseSpecificity(`
  width: 50%;
  margin: 0 auto;
`)}
`

const imageContainerCss = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
`

const imageCss = css`
  height: 24px;
`

const minimizedLogoItemCss = css`
  ${increaseSpecificity(`
    font-size: 1em;
    background-color: white;
    text-align: center;
    padding: 24px;
    display: flex;
    justify-content: center
  `)}
`
type Props = { isMobile: boolean; minimizedSideBar: boolean; setSidebarVisible: (sidebarVisible: boolean) => void }

const SidebarLogo = ({ isMobile, setSidebarVisible, minimizedSideBar }: Props) => {
  return isMobile ? (
    <>
      <div className={imageContainerCss}>
        <NavLink to={routes.mainRoute}>
          <Image className={imageCss} src={Logo} alt="logo" />
        </NavLink>
        <Image src={MenuCloseIcon} alt="logo" width={24} height={24} onClick={() => setSidebarVisible(false)} />
      </div>
    </>
  ) : (
    <Menu.Item className={minimizedSideBar ? minimizedLogoItemCss : logoItemCss}>
      <NavLink to={routes.homepageRoute}>
        <Image
          className={minimizedSideBar ? collapsedLogoCss : logoCss}
          src={minimizedSideBar ? IconLogo : Logo}
          alt="logo"
        />
      </NavLink>
    </Menu.Item>
  )
}

export default SidebarLogo
