import { FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { RootStoreInterface } from '@pp/store/root.store'
import { makeAutoObservable } from 'mobx'

import { QueryType, Option, Family } from './typescript/filing-type.type'

export type Result = { filingsRequest: { data_1: Family[] | [] } }
type FilingCheckStoreInterface = {
  fields: Option[]
  query: string
  queryType: QueryType
  result: Result | null
}

export class FilingCheckStore implements FilingCheckStoreInterface {
  private rootStore: RootStoreInterface
  query = ''
  fields: Option[] = [{ key: '', text: '', icon: '' }]
  queryType: QueryType = 'appln_nr'
  result: Result | null = null

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  setQuery = (query: string) => (this.query = query)
  setFields = (fields: Option[]) => (this.fields = fields)
  setQueryType = (queryType: QueryType) => (this.queryType = queryType)
  setResult = (result: Result | null) => (this.result = result)

  resetSearchUI = () => {
    this.query = ''
    this.fields = [{ key: '', text: '', icon: '' }]
    this.queryType = 'appln_nr'
    this.result = null

    const routerStore = this.rootStore.routerStore
    routerStore.replace(routerStore.location.pathname)
  }

  initializeSearchFiltersFromUrl = (queryParameters: string) => {
    const urlSearchParams = new URLSearchParams(queryParameters)
    const query = urlSearchParams.get('query')
    const familyType = urlSearchParams.get('family_type')
    const queryType = urlSearchParams.get('queryType')

    if (query) this.query = query
    if (queryType) this.queryType = queryType as QueryType
    if (familyType) this.rootStore.applicationStore.setFamilyType(familyType.split(',') as FamilyType[])
  }

  updateSearchParamsFromFilters = (query: string) => {
    const routerStore = this.rootStore.routerStore
    const familyType = this.rootStore.applicationStore.familyType
    routerStore.replace(
      `${routerStore.location.pathname}?query=${query}&family_type=${familyType.join(',')}&queryType=${this.queryType}`,
    )
  }
}
