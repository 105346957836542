import { numberDecimalFormatter, splitter, checkIfEqual } from './utils'

export const currencyFormatter = (num: number, decimals = true, withCurrency = true, currency = '€'): string => {
  const formattedNum = numberDecimalFormatter(num, 2)
  const arr = splitter(formattedNum, '.')
  let splittedDigits
  let dash

  const isEqualToDash = checkIfEqual(arr[0][0], '-')
  if (isEqualToDash) {
    splittedDigits = splitter(arr[0].slice(1), '')
    dash = '-'
  } else {
    splittedDigits = splitter(arr[0], '')
  }

  for (let i = splittedDigits.length; i >= 0; i -= 3) {
    const isEqualToNumOfDigits = checkIfEqual(i, splittedDigits.length)
    if (isEqualToNumOfDigits) {
      continue
    } else if (i === 0) {
      break
    } else {
      splittedDigits[i] = `,${splittedDigits[i]}`
    }
  }
  const digitsJoined = splittedDigits.join('')
  const digitsWithDecimals = decimals ? `${digitsJoined}.${arr[1]}` : `${digitsJoined}`
  const digitsWithDecimalsAndDash = dash ? `-${digitsWithDecimals}` : `${digitsWithDecimals}`
  const result = withCurrency ? `${digitsWithDecimalsAndDash} ${currency}` : digitsWithDecimalsAndDash
  return result
}
