import { cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import DiscoverIcon from '@pp/assets/icons/discover-menu-icon.svg'
import * as routes from '@pp/routing/routes'
import { observer } from 'mobx-react-lite'
import { Menu, Image } from 'semantic-ui-react'

import { useStores } from '../../../../../../../store/useStore.hook'
import { searchRoutes } from '../../../route-groups'
import { iconCss, menuHeaderCss } from '../../../sidebar.css'
import { MenuProps } from '../analyze-menu/AnalyzeMenu'

const SearchMenu = ({ active, minimizedSideBar, isMobile, onNavLinkClick }: MenuProps) => {
  const { applicationStore } = useStores()
  const handleClick = () => {
    if (applicationStore.hasStandardSearch) {
      onNavLinkClick(routes.searchFilingsRoute)
    } else {
      applicationStore.setModal({ open: true, version: '' })
    }
  }

  return (
    <>
      <Menu.Header
        className={cx(menuHeaderCss(isMobile, minimizedSideBar), searchRoutes.indexOf(active) > -1 ? 'active' : '')}
        onClick={handleClick}
      >
        <div className="notch"></div>
        <span onClick={handleClick}>
          <Image
            src={DiscoverIcon}
            className={iconCss(minimizedSideBar)}
            alt="analyse-shevron"
            width="16px"
            height="16px"
          />
          {minimizedSideBar ? null : (
            <>
              <Trans id="sidebar.buttons.search" />
            </>
          )}
        </span>
      </Menu.Header>
    </>
  )
}

export default observer(SearchMenu)
