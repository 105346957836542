import { FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { ApplicationStore } from '@pp/store/application.store'
import { observer } from 'mobx-react-lite'

import ProductButtonWrap from './ProductButtonWrap'
import { CustomButton } from '..'

export type DefaultProductBtnProps = {
  applicationStore: ApplicationStore
  hasFamilyType: (familyType: FamilyType) => boolean
  isAuthenticated: boolean
  isMobile: boolean
  isSmallHight: boolean
  minimizedSideBar: boolean
  onToggleFamilyTypes: () => void
}

type Props = DefaultProductBtnProps & { onToggleFamilyTypes: () => void }

const IpButton = ({
  isMobile,
  minimizedSideBar,
  isAuthenticated,
  hasFamilyType,
  applicationStore,
  isSmallHight,
  onToggleFamilyTypes,
  ...rest
}: Props) => {
  const handleClick = () => {
    if (!(applicationStore.hasTrademarksPermission && applicationStore.hasPatentsPermission)) {
      applicationStore.setModal({ open: true, version: '' })
    } else onToggleFamilyTypes()
  }
  return (
    <CustomButton
      active={isAuthenticated ? hasFamilyType('P') && hasFamilyType('T') : true}
      handleClick={handleClick}
      translationKey="sidebar.buttons.ippilot"
      showText={!isMobile && (isSmallHight || minimizedSideBar) ? false : true}
      {...rest}
    />
  )
}

export default ProductButtonWrap(observer(IpButton))
