import { TrackingEvent } from '@pp/common/typescript/tracking.type'

export const checkForValidPageView = (data: TrackingEvent, dataLayer: TrackingEvent[]) => {
  if (data.event?.type !== 'page-view' || !dataLayer) return true
  return !dataLayer.some(
    (event) =>
      event.event?.type === data.event?.type &&
      event.pageInfo?.container === data.pageInfo?.container &&
      event.pageInfo?.page === data.pageInfo?.page &&
      event.pageInfo?.tab === data.pageInfo?.tab &&
      data.date - event.date < 1500,
  )
}
