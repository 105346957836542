import React, { FC, createContext, ReactNode, ReactElement } from 'react'

import { RootStoreInterface } from './root.store'

export const StoreContext = createContext<RootStoreInterface>({} as RootStoreInterface)

export type StoreComponent = FC<{
  children: ReactNode
  store: RootStoreInterface
}>

export const StoreProvider: StoreComponent = ({ store, children }): ReactElement => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}
