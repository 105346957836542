import { mobileMediaQuery } from '@pp/common/css/css.helper'
import { useScrollPosition } from '@pp/common/custom-hooks/useScrollPosition.hook'
import { useStores } from '@pp/store/useStore.hook'
import { useMediaQuery } from '@react-hook/media-query'
import { observer } from 'mobx-react-lite'
import { Modal, Button, Message } from 'semantic-ui-react'

import { messageCss, modalCss } from './buy-modal.css'
import BuyModalContent from './BuyModalContent'
import Icon from '../../../../common/components/icon/Icon'

function BuyModal({ modal = true }: { modal?: boolean }) {
  const { applicationStore } = useStores()
  const isMobile = useMediaQuery(mobileMediaQuery)

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -500 && !applicationStore.modal.open) {
      document.body.className = document.body.className + 'body-scrollable'
      applicationStore.setModal({ open: true, version: '' })
    }
  })

  const onModalClose = () => {
    document.body.className = document.body.className
      .split(' ')
      .filter((bodyClass) => bodyClass !== 'body-scrollable')
      .join(' ')
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    applicationStore.setModal({ open: false, version: '' })
  }

  if (modal)
    return (
      <Modal open={applicationStore.modal.open} dimmer className={modalCss(isMobile)} onClose={onModalClose}>
        {isMobile ? (
          <Button
            primary
            onClick={() => applicationStore.setModal({ open: false, version: '' })}
            size="medium"
            style={{ position: 'absolute', top: '0', right: '0', zIndex: '10' }}
            icon={<Icon iconName="icon-big-x-mark" size={14} margin="0" />}
          />
        ) : null}
        <BuyModalContent isMobile={isMobile} />
      </Modal>
    )

  return (
    <Message floating className={messageCss(isMobile)}>
      <BuyModalContent isMobile={isMobile} />
    </Message>
  )
}

export default observer(BuyModal)
