import { t } from '@lingui/macro'
import { SankeyExportResult } from '@pp/common/typescript/helpers.type'

import { TCaseFlowRecursive, CaseflowDirection } from '../../chart/typescript/caseflow.interface'
import { SankeyChartSeriesOptions } from '../../chart/typescript/chart-series-interface'

export const sankeyChartToXslxExportFormat = (
  rawData: TCaseFlowRecursive[],
  entityName: string,
  caseflowMapperOptions: SankeyChartSeriesOptions,
) => {
  const direction = caseflowMapperOptions.caseflowDirection

  if (direction === CaseflowDirection.Inbound) {
    if (rawData.length > 0) {
      const results: SankeyExportResult[] = []

      rawData.forEach((entry) => {
        if (entry && entry.nodes) {
          const row = {
            from: `${entry.nodes[0].name ? entry.nodes[0].name : t`data.export.unknownRepresentative`} (${
              entry.nodes[0].country ? entry.nodes[0].country : t`data.export.unknownCountry`
            })`,
            entity: `${entry.name} (${entry.country})`,
            to: entityName,
            weight: entry.nodes[0].nodeWeight,
          }
          results.push(row)

          // Iterate over other nodes and add them to the results. We start with 1 since the first element is already added.
          for (let i = 1; i < entry.nodes.length; i++) {
            results.push({
              from: `${entry.nodes[i].name ? entry.nodes[i].name : t`data.export.unknownRepresentative`} (${
                entry.nodes[i].country ? entry.nodes[i].country : t`data.export.unknownCountry`
              })`,
              entity: `${entry.name} (${entry.country})`,
              to: entityName,
              weight: entry.nodes[i].nodeWeight,
            })
          }
        }
      })

      return results
    }
  } else if (direction === CaseflowDirection.Outbound) {
    if (rawData.length > 0) {
      const results: SankeyExportResult[] = []

      rawData.forEach((entry) => {
        if (entry && entry.nodes) {
          const row = {
            from: entityName,
            entity: `${entry ? entry.name : t`data.export.unknownRepresentative`} (${
              entry ? entry.country : t`data.export.unknownCountry`
            })`,
            to: `${
              entry.nodes[0] && entry.nodes[0].name ? entry.nodes[0].name : t`data.export.unknownRepresentative`
            } (${entry.nodes[0] && entry.nodes[0].country ? entry.nodes[0].country : t`data.export.unknownCountry`})`,
            weight: entry.nodes[0].nodeWeight,
          }
          results.push(row)

          // Iterate over other nodes and add them to the results. We start with 1 since the first element is already added.
          for (let i = 1; i < entry.nodes.length; i++) {
            results.push({
              from: entityName,
              entity: `${entry ? entry.name : t`data.export.unknownRepresentative`} (${
                entry ? entry.country : t`data.export.unknownCountry`
              })`,
              to: `${
                entry.nodes[i] && entry.nodes[i].name ? entry.nodes[i].name : t`data.export.unknownRepresentative`
              } (${entry.nodes[i] && entry.nodes[i].country ? entry.nodes[i].country : t`data.export.unknownCountry`})`,
              weight: entry.nodes[i].nodeWeight,
            })
          }
        }
      })

      return results
    }
  } else {
    if (rawData.length > 0) {
      const reverseNodes = caseflowMapperOptions.reverseNodes
      const showOwnEntityCentered = caseflowMapperOptions.showOwnEntityCentered
      const results: SankeyExportResult[] = []

      if (showOwnEntityCentered) {
        rawData.forEach((entry) => {
          if (entry && entry.nodes) {
            const row = {
              from: reverseNodes
                ? `${
                    entry.nodes[0] && entry.nodes[0].name
                      ? entry.nodes[0].name.split('|')[0]
                      : t`data.export.unknownRepresentative`
                  } (${
                    entry.nodes[0] && entry.nodes[0].country ? entry.nodes[0].country : t`data.export.unknownCountry`
                  })`
                : `${entry.name} (${entry.country})`,
              entity: entityName,
              to: reverseNodes
                ? `${entry.name} (${entry.country})`
                : `${
                    entry.nodes[0] && entry.nodes[0].name
                      ? entry.nodes[0].name.split('|')[0]
                      : t`data.export.unknownRepresentative`
                  } (${
                    entry.nodes[0] && entry.nodes[0].country ? entry.nodes[0].country : t`data.export.unknownCountry`
                  })`,
              weight: entry.nodes[0].nodeWeight,
              uniqueWeight: entry.nodes[0].name.split('|')[1],
            }
            results.push(row)

            // Iterate over other nodes and add them to the results. We start with 1 since the first element is already added.
            for (let i = 1; i < entry.nodes.length; i++) {
              results.push({
                from: reverseNodes
                  ? `${
                      entry.nodes[i] && entry.nodes[i].name
                        ? entry.nodes[i].name.split('|')[0]
                        : t`data.export.unknownRepresentative`
                    } (${
                      entry.nodes[i] && entry.nodes[i].country ? entry.nodes[i].country : t`data.export.unknownCountry`
                    })`
                  : `${entry.name} (${entry.country})`,
                entity: entityName,
                to: reverseNodes
                  ? `${entry.name} (${entry.country})`
                  : `${
                      entry.nodes[i] && entry.nodes[i].name
                        ? entry.nodes[i].name.split('|')[0]
                        : t`data.export.unknownRepresentative`
                    } (${
                      entry.nodes[i] && entry.nodes[i].country ? entry.nodes[i].country : t`data.export.unknownCountry`
                    })`,
                weight: entry.nodes[i].nodeWeight,
                uniqueWeight: entry.nodes[i].name.split('|')[1],
              })
            }
          }
        })

        return results
      } else {
        rawData.forEach((entry) => {
          if (entry && entry.nodes) {
            const row = {
              from: entityName,
              entity: `${entry && entry.name ? entry.name : t`data.export.unknownRepresentative`} (${
                entry && entry.country ? entry.country : t`data.export.unknownCountry`
              })`,
              to: `${
                entry.nodes[0] && entry.nodes[0].name ? entry.nodes[0].name : t`data.export.unknownRepresentative`
              } (${entry.nodes[0] && entry.nodes[0].country ? entry.nodes[0].country : t`data.export.unknownCountry`})`,
              weight: entry.nodes[0].nodeWeight,
            }
            results.push(row)

            // Iterate over other nodes and add them to the results. We start with 1 since the first element is already added.
            for (let i = 1; i < entry.nodes.length; i++) {
              results.push({
                from: entityName,
                entity: `${entry && entry.name ? entry.name : t`data.export.unknownRepresentative`} (${
                  entry && entry.country ? entry.country : t`data.export.unknownCountry`
                })`,
                to: `${
                  entry.nodes[i] && entry.nodes[i].name ? entry.nodes[i].name : t`data.export.unknownRepresentative`
                } (${
                  entry.nodes[i] && entry.nodes[i].country ? entry.nodes[i].country : t`data.export.unknownCountry`
                })`,
                weight: entry.nodes[i].nodeWeight,
              })
            }
          }
        })

        return results
      }
    }
  }
  return []
}
