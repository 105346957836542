import React from 'react'

import { css } from '@emotion/css'

function NewLabel({ relative = false }: { relative?: boolean }) {
  return (
    <div
      className={css`
        position: ${relative ? 'relative' : 'absolute'};
        right: ${relative ? '0' : '19px'};
        align-self: center;
        height: 8px;
        border-radius: 50%;
        background: var(--primary-color);
        width: 8px;
        margin-left: ${relative ? '8px' : '0'};
      `}
    ></div>
  )
}

export default NewLabel
