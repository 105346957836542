import { checkIfEqual } from '@pp/common/helpers'
import { TrackingEvent } from '@pp/common/typescript/tracking.type'

export const checkForValidProfileExport = (incomingEvent: TrackingEvent, dataLayer: TrackingEvent[]) => {
  if (incomingEvent.event?.type !== 'export-profile' || !dataLayer) return true
  return dataLayer.some((data) => {
    const isSameEvent = checkIfEqual(data.event?.type, incomingEvent.event?.type)
    const isSameUrl = checkIfEqual(data.pageInfo?.url, incomingEvent.pageInfo?.url)
    const isSameStatus = checkIfEqual(data.event?.content?.status, incomingEvent.event?.content?.status)
    const dateDelta = Math.abs(data.date - incomingEvent.date)
    return isSameEvent && isSameUrl && isSameStatus && dateDelta < 1000
  })
}
