import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js'
import 'regenerator-runtime/runtime'
import 'raf/polyfill'
import 'url-search-params-polyfill'
import 'url-polyfill'

import { AppState, Auth0Provider } from '@auth0/auth0-react'
import Logo from '@pp/assets/ip-logo.svg'
import environment from '@pp/environments'
import ReactDOM from 'react-dom'

import './index.css'
import { browserHistory } from './App'
import 'semantic-ui-less/semantic.less'
import TrackedApp from './tracked-app/TrackedApp'

function isIE() {
  // IE 10 and IE 11
  return /Trident\/|MSIE/.test(window.navigator.userAgent)
}

const { domain, clientId, audience } = environment.auth0
const redirectUri = `${window.location.protocol}//${window.location.host}/callback`

const onRedirectCallback = (appState: AppState | undefined) => {
  browserHistory.replace(appState?.returnTo || window.location.pathname)
}

if (isIE()) {
  const d = document.getElementsByClassName('unsupported-browser')
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  d[0].style['text-align'] = 'center'
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  d[0].style['margin-top'] = '20px'
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  d[0].style['z-index'] = '2'
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  d[0].style['position'] = 'relative'
  d[0].innerHTML = `
  <a href="https://www.ip-pilot.com"><img src=${Logo} alt="logo" style="width: 200px" /></a>
  <h3><b>You are using Internet Explorer or an other unsupported browser.</b></h3><br />
  To be able to bring you the fastest experience possible, we had to drop support for this browser. Please use one of the recent versions of major browsers like Chrome, Firefox, Safari, and Edge.<br />
  If you don't have an option of using another browser, please contact us at <a href='mailto:info@patent-pilot.com'>info@ip-pilot.com</a>.
  `
} else {
  ReactDOM.render(
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      audience={audience}
      advancedOptions={{ defaultScope: 'openid profile email' }}
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
    >
      <TrackedApp />
    </Auth0Provider>,
    document.getElementById('root'),
  )
}
