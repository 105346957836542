import { TrackingEvent } from '@pp/common/typescript/tracking.type'

import { getRelatedEvents, getLastIndexOfUserInteraction, getTimeToDeductFromTimer, getSortedEvents } from '.'

const timeOnPageThresholdInMs = 180000

export const validateTimeOnPage = (data: TrackingEvent, dataLayer: TrackingEvent[]) => {
  if (data.event?.type !== 'time-on-page' || !dataLayer) return true

  const sortedEvents = getSortedEvents(dataLayer)
  const relatedEvents = getRelatedEvents(data, sortedEvents)
  const lastIndexOfUserInteraction = getLastIndexOfUserInteraction(relatedEvents)

  if (lastIndexOfUserInteraction < 0) return false
  const timeToDeductFromTimer = getTimeToDeductFromTimer(relatedEvents, timeOnPageThresholdInMs)

  if (timeToDeductFromTimer >= data.event?.duration) return false

  data.event.duration = data.event?.duration && data.event?.duration - timeToDeductFromTimer
  return true
}
