import { MappedFilterType } from '@pp/common/components/filters/typescript/filters.type'
import _ from 'lodash'

import { mapNegativeFilters } from './map-negative-filters'

export const mapEpValSearchType = (mappedFilters: MappedFilterType) => {
  if (!mappedFilters.ep_val_type) return mappedFilters
  const clonedFilters = _.cloneDeep(mappedFilters)
  const types = (clonedFilters.ep_val_type as Array<string | number>).map((type) =>
    typeof type === 'string' ? type.toLowerCase() : '',
  )

  if (clonedFilters.validation) {
    clonedFilters.validation.val_type = mapNegativeFilters(types, ['inbound', 'outbound', 'internal'])
    delete clonedFilters.ep_val_type
  }

  return clonedFilters
}
