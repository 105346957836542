import React from 'react'

import { css, cx } from '@emotion/css'

import { increaseSpecificity } from '../../css/css.helper'

function Icon({
  iconName,
  margin = '0 8px 0 0',
  size,
  pointer = true,
  color,
  hoverColor,
  rotate,
  loading,
  ...rest
}: {
  color?: string
  hoverColor?: string
  iconName: string
  loading?: boolean
  margin?: string
  onClick?: (event: React.SyntheticEvent) => void
  pointer?: boolean
  rotate?: number
  size: number
}) {
  return (
    <i
      {...rest}
      className={cx(
        iconName,
        css`
          ${increaseSpecificity(
            `cursor: ${pointer ? 'pointer' : 'default'}; 
            margin: ${margin};
            font-size: ${size}px;
            display: inline-block; 
            &:hover {&::before {color: ${hoverColor ? hoverColor : 'inherit'};}}
            &::before {
              color: ${color ? color : 'inherit'};
            }
            transform: ${rotate ? `rotate(${rotate}deg)` : 'none'};
            transition: transform 0.3s ease;
             ${loading && `animation: spin 2s linear infinite;`}
            `,
          )}
        `,
      )}
    ></i>
  )
}

export default Icon
