import { Geolocation } from '@pp/common/components/filters/typescript/filters.type'

const isArrayOfStrings = (arr: string[] | number[] | undefined | boolean[] | Geolocation): arr is string[] => {
  if (!arr) return false
  if (!Array.isArray(arr)) return false
  return arr.some((el: unknown) => el !== 'string')
}

const filterOutSelectedOption = (selected: string, options: string[]): string[] => {
  if (options.length === 0) return [selected]
  if (!selected.includes('!')) return [selected]
  const cleanedSelected = selected.split('!')[1]
  const result = options.filter((el: string) => cleanedSelected !== el)
  return result
}

export const mapNegativeFilters = (
  selectedOptions: string[] | number[] | undefined | boolean[] | Geolocation,
  defaultOptions: string[],
) => {
  if (!selectedOptions || !isArrayOfStrings(selectedOptions)) return
  const finalResult: string[][] = []
  selectedOptions.forEach((selected: string) => finalResult.push(filterOutSelectedOption(selected, defaultOptions)))
  return finalResult.flat()
}
