import { ValidationsQueryState, StateMap } from '../validations-search.type'

export class QueryStateMachine {
  private state: ValidationsQueryState
  private initialState: ValidationsQueryState

  constructor(initialState: ValidationsQueryState) {
    this.state = initialState
    this.initialState = initialState
  }

  public reset(): ValidationsQueryState {
    this.state = this.initialState
    return this.state
  }

  public transition(action: string): ValidationsQueryState | undefined {
    const nextState = stateMap[this.state][action]

    if (nextState) {
      this.state = nextState
      return this.state
    }
    return
  }
}

const stateMap: StateMap = {
  applicants_validateat_eppublnauth: {
    SELECT_APPLICANTS: undefined,
    SELECT_LAWFIRMS: ValidationsQueryState.LawFirmsValidateAtEpPublnAuth,
    SELECT_VALIDATE_AT: undefined,
    SELECT_EPAUTHORITY: undefined,
    SELECT_EPCOUNTRY: undefined,
    SELECT_SEND_TO: undefined,
    SELECT_RECEIVE_FROM: undefined,
  },
  lawfirms_validateat_eppublnauth: {
    SELECT_APPLICANTS: ValidationsQueryState.ApplicantsValidateAtEpPublnAuth,
    SELECT_LAWFIRMS: undefined,
    SELECT_VALIDATE_AT: undefined,
    SELECT_EPAUTHORITY: undefined,
    SELECT_EPCOUNTRY: undefined,
    SELECT_SEND_TO: ValidationsQueryState.LawfirmsSendToEpCountry,
    SELECT_RECEIVE_FROM: ValidationsQueryState.LawfirmsReceiveFromEpCountry,
  },
  lawfirms_sendto_epcountry: {
    SELECT_APPLICANTS: ValidationsQueryState.ApplicantsValidateAtEpPublnAuth,
    SELECT_LAWFIRMS: undefined,
    SELECT_VALIDATE_AT: ValidationsQueryState.LawFirmsValidateAtEpPublnAuth,
    SELECT_EPAUTHORITY: ValidationsQueryState.LawfirmsSendToEpPublnAuth,
    SELECT_EPCOUNTRY: undefined,
    SELECT_SEND_TO: undefined,
    SELECT_RECEIVE_FROM: ValidationsQueryState.LawfirmsReceiveFromEpCountry,
  },
  lawfirms_sendto_eppublnauth: {
    SELECT_APPLICANTS: ValidationsQueryState.ApplicantsValidateAtEpPublnAuth,
    SELECT_LAWFIRMS: undefined,
    SELECT_VALIDATE_AT: ValidationsQueryState.LawFirmsValidateAtEpPublnAuth,
    SELECT_EPAUTHORITY: undefined,
    SELECT_EPCOUNTRY: ValidationsQueryState.LawfirmsSendToEpCountry,
    SELECT_SEND_TO: undefined,
    SELECT_RECEIVE_FROM: ValidationsQueryState.LawfirmsReceiveFromEpCountry,
  },
  lawfirms_receivefrom_epcountry: {
    SELECT_APPLICANTS: ValidationsQueryState.ApplicantsValidateAtEpPublnAuth,
    SELECT_LAWFIRMS: undefined,
    SELECT_VALIDATE_AT: ValidationsQueryState.LawFirmsValidateAtEpPublnAuth,
    SELECT_EPAUTHORITY: undefined,
    SELECT_EPCOUNTRY: undefined,
    SELECT_SEND_TO: ValidationsQueryState.LawfirmsSendToEpCountry,
    SELECT_RECEIVE_FROM: undefined,
  },
}
export { ValidationsQueryState }
