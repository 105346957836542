import { EntityFilter } from '@pp/common/components/filters/typescript/filters.type'
import { ChartWidgetType } from '@pp/common/typescript/dashboard.type'
import { SearchFilter } from '@pp/modules/search/advanced-search/tabs/validations/validations-search.type'
import _ from 'lodash'

import { mapSearchFilters } from './map-search-filters'
import { parseFilters } from './parse-filters'

export const getParsedFiltersWithCharts = (filters: Partial<SearchFilter>, selectedCharts: ChartWidgetType[]) => {
  const clonedFilers = _.cloneDeep(filters)
  const mappedSearchFilters = mapSearchFilters(clonedFilers as unknown as EntityFilter[])
  mappedSearchFilters.selected_charts = selectedCharts
  return parseFilters(mappedSearchFilters)
}
