import { SearchFilters } from '@pp/common/components/filters/typescript/filters.type'
import _ from 'lodash'

import { mapNegativeFilters } from './map-negative-filters'

export const mapValidationTypes = (
  options: { isAppl?: boolean; options: string[]; prop: string }[],
  mappedFilters: SearchFilters,
) => {
  const clonedFilters = _.cloneDeep(mappedFilters)
  options.forEach((el) => {
    if (!clonedFilters[el.prop]) return
    if (el.isAppl && clonedFilters.applicant) {
      clonedFilters.applicant[el.prop] = mapNegativeFilters(clonedFilters[el.prop], el.options)
      delete clonedFilters[el.prop]
    } else if (clonedFilters.validation) {
      clonedFilters.validation[el.prop] = mapNegativeFilters(clonedFilters[el.prop], el.options)
      delete clonedFilters[el.prop]
    }
  })

  return clonedFilters
}
