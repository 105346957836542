import { DateType } from '@pp/modules/analyse/common/profile-header/components/default-filters/default-filters.type'
import wNumb from 'wnumb'

type Operand = string | number | undefined
type GenericObjOrArray = { [s: string]: unknown } | ArrayLike<unknown>

export const extractValuesFromObject = <T extends GenericObjOrArray>(obj: T) => Object.values(obj).join(' ')
export const checkIfObjectIsEmpty = (obj: Object) => Object.keys(obj).length === 0
export const checkIfEqual = (firstOperand: Operand, secondOperand: Operand) => firstOperand === secondOperand
export const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)
export const getSumFromArrayOfNumbers = (entry: number[] | undefined) => (entry ? entry.reduce((pv, cv) => pv + cv) : 0)
export const numberDecimalFormatter = (num: number, decimals: number) => num.toFixed(decimals)
export const splitter = <T>(item: T, pattern: string) => String(item).split(pattern)
export const formatter = wNumb({ mark: '.', thousand: ',' })
export const formatDigitsWithSpace = wNumb({ mark: '.', thousand: ' ' })

export const cleanObjectFromNullValues = <T extends GenericObjOrArray>(obj: T) =>
  Object.fromEntries(Object.entries(obj).filter(([value]) => value))

export const formatNumber = (number: number) => {
  if (!number) return '0'
  return formatter.to(number)
}

export const formatDate = (date: Date | string) => {
  if (!date) return ''
  return new Date(date).toLocaleDateString('en-ZA', { year: 'numeric', month: '2-digit', day: '2-digit' })
}

export const arrayFromString = (str: string | null): string[] => {
  if (str) return str.split(',')
  return []
}

export const getFileName = (num: number) => {
  if (num === 0) return 'JPEG'
  if (num === 1) return 'SVG'
  if (num === 2) return 'PDF'
  if (num === 3) return 'XLSX'
  return ''
}

export const sizeCalculator = (obj: unknown): { kiloBytes: number; megaBytes: number } => {
  const size = new TextEncoder().encode(JSON.stringify(obj)).length
  const kiloBytes = size / 1024
  const megaBytes = kiloBytes / 1024
  return { kiloBytes, megaBytes }
}

export const sortByString = (a: string, b: string) => {
  const titleA = a ? a.toLowerCase() : ''
  const titleB = b ? b.toLowerCase() : ''
  if (titleA < titleB) return -1
  if (titleA > titleB) return 1
  return 0
}

export const getDateTypeFromArray = (date_type: string[] | number[] | undefined) => {
  if (date_type && date_type.length > 0) return date_type[0] as DateType
  return DateType.publicationDate
}
