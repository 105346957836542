import { MappedFilterType } from '@pp/common/components/filters/typescript/filters.type'
import { Filters } from '@pp/common/typescript/helpers.type'
import _ from 'lodash'

export const parseFilters = (filters: MappedFilterType) => {
  const clonedFilters = _.cloneDeep(filters) as unknown as Filters[]
  const result: Filters[] = []
  const recursiveParser = <T>(items: T) => {
    for (const key in items) {
      const value = items[key]
      if (Array.isArray(value) && value.length === 1) result.push({ [key]: value[0] })
      else if (Array.isArray(value)) result.push({ [key]: _.cloneDeep(value) })
      else recursiveParser(value)
    }
  }
  recursiveParser(clonedFilters)
  return result
}
