import { t } from '@lingui/macro'
import { DecisionMakerEnum } from '@pp/common/components/filters/typescript/filters.type'

export const getDecisionMakerType = (value: string) => {
  switch (value) {
    case DecisionMakerEnum.A:
      return t`data.attributes.decision.a`
    case DecisionMakerEnum.LF:
      return t`data.attributes.decision.lf`
    default:
      return value
  }
}
