import { checkIfEqual } from '@pp/common/helpers'
import { compareFilters } from '@pp/common/helpers'
import { TrackingEvent } from '@pp/common/typescript/tracking.type'

import { checkIfSamePageContainerTab } from '.'

export const getRelatedEvents = (data: TrackingEvent, events: TrackingEvent[]) => {
  const filteredData = events.filter((event: TrackingEvent) => {
    const isSamePageContainerTab = checkIfSamePageContainerTab(data, event)
    const eventIsNotLoadingTime = !checkIfEqual(event.event?.type, 'loading-time')
    const hasSameFilters = compareFilters(event.event?.filters, data.event?.filters)

    if (isSamePageContainerTab && eventIsNotLoadingTime && hasSameFilters) return event
    return false
  })

  const pageViewIndex = filteredData.findIndex((el: TrackingEvent) => el.event.type === 'page-view')

  if (pageViewIndex < 0) return filteredData

  return filteredData.slice(0, pageViewIndex + 1)
}
