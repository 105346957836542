import { useAuth0 } from '@auth0/auth0-react'
import { extractFilters, getPageInfo, mapSearchFilters, parseFilters } from '@pp/common/helpers'
import { Event } from '@pp/common/typescript/tracking.type'
import { useStores } from '@pp/store/useStore.hook'
import { useTracking } from 'react-tracking'

import { useDeviceInfo } from '../../../modules/settings/providers/DeviceInfoProvider'
import { useGeoLocation } from '../../../modules/settings/providers/GeoLocationProvider'
import { useUser } from '../../../modules/settings/providers/UserProvider'

type Props = { [index: string]: unknown; tab?: string }

export const useEventTracker = () => {
  const { isAuthenticated } = useAuth0()
  const { trackEvent } = useTracking()
  const { userInfo } = useUser()
  const { deviceInfo } = useDeviceInfo()
  const { filterStore, filingsSearchStore, routerStore } = useStores()
  const { geoInfo } = useGeoLocation()

  const handleEventTracker = ({ tab, ...args }: Props) => {
    const { containerName, pageName, tabName } = getPageInfo(window.location.pathname)
    const search = routerStore.location.search

    const event: Event = {
      userInfo,
      pageInfo: {
        url: window.location.href + search,
        container: containerName,
        ...(pageName && { page: pageName }),
        ...(tab ? { tab: tab } : tabName && { tab: tabName }),
      },
      ...args,
      deviceInfo,
      geoInfo,
      date: new Date(Date.now()),
    }

    if ((containerName === 'law-firms' || containerName === 'applicants') && event.event) {
      event.event.urlFilters = extractFilters(window.location.href)
      event.event.filters = parseFilters(mapSearchFilters(filterStore.activeFilters))
    }

    if (containerName === 'standard-search' && event.event) {
      event.event.urlFilters = extractFilters(window.location.href)
      event.event.filters = parseFilters(mapSearchFilters(filingsSearchStore.serverFilters))
    }

    if (userInfo.userId && isAuthenticated) trackEvent(event)
    else if (!isAuthenticated && userInfo.userId.length === 0) trackEvent(event)
  }

  return { handleEventTracker }
}
