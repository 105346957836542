import _ from 'lodash'

type ChartsRenderDuration = { chart: string; duration: number }
type CombinedChartsRenderDuration = { [index: string]: NewEvent }
type NewEvent = { event: { duration: number; type: string } } & ChartsRenderDuration

export const getReducedChartsRenderDuration = (chartsRenderDuration: ChartsRenderDuration[]) => {
  const combinedChartsRenderDuration: CombinedChartsRenderDuration = {}

  for (let i = 0; i < chartsRenderDuration.length; i++) {
    if (!combinedChartsRenderDuration[chartsRenderDuration[i].chart]) {
      const { duration, ...rest } = _.cloneDeep(chartsRenderDuration[i])
      const newEvent = { ...rest } as NewEvent
      newEvent.event.duration = duration
      combinedChartsRenderDuration[chartsRenderDuration[i].chart] = { ...newEvent }
    } else combinedChartsRenderDuration[chartsRenderDuration[i].chart].duration += chartsRenderDuration[i].duration
  }

  return combinedChartsRenderDuration
}
