import { TrackingEvent } from '@pp/common/typescript/tracking.type'

export const getTimeToDeductFromTimer = (events: TrackingEvent[], treshold: number) =>
  events.reduce((result, cv, index, arr) => {
    if (index + 1 < arr.length) {
      const delta = cv.date - arr[index + 1].date
      if (delta > treshold) result += delta - treshold
    }
    return result
  }, 0) / 1000
