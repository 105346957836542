import { cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import { submenuCss, slideOutCss, disabledStyleCss } from '../../../sidebar.css'
import { DefaultMenuItemsProps } from '../analyze-menu/AnalyzeItems'

const SearchItems = ({ menus, minimizedSideBar, isMobile, active }: DefaultMenuItemsProps) => {
  const { applicationStore } = useStores()

  return (
    <Menu.Menu className={cx(submenuCss(false, isMobile), !minimizedSideBar && slideOutCss(menus.searchMenu))}>
      <Menu.Item>
        {applicationStore.hasStandardSearch ? (
          <NavLink
            className={active.includes(routes.advancedSearchRoute) ? 'active' : ''}
            to={routes.advancedSearchRoute}
          >
            <Trans id="sidebar.menu.advanced-search" />
          </NavLink>
        ) : (
          <div
            className={disabledStyleCss(isMobile)}
            onClick={() => applicationStore.setModal({ open: true, version: '' })}
          >
            <Trans id="sidebar.menu.advanced-search" />
          </div>
        )}
      </Menu.Item>
    </Menu.Menu>
  )
}

export default observer(SearchItems)
