import * as React from 'react'

import { ApolloError } from '@apollo/client'
import { css } from '@emotion/css'
import { Trans } from '@lingui/react'
import { useEventTracker } from '@pp/common/custom-hooks/trackers/useEventTracker.hook'

import Icon from '../icon/Icon'

const getFailureContainerCss = (customStyles?: string, containerHeight: string | undefined = '250px') => {
  const defaultStyles = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#b3b3b3',
    textAlign: 'center',
    height: `${containerHeight}`,
  })

  return css`
    ${defaultStyles};
    ${customStyles};
  `
}

export const iconContainerCss = css({
  fontSize: '3em',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const reloadLinkCss = css({
  cursor: 'pointer',
  background: 'none',
  border: 'none',
  padding: '0',
})

type DefaultFailureProps = { containerHeight?: string; containerStyles?: string; error?: ApolloError | unknown }

const DefaultFailure = ({ containerStyles, containerHeight, error }: DefaultFailureProps) => {
  const { handleEventTracker } = useEventTracker()
  handleEventTracker({ event: { type: 'error', content: { error } } })

  return (
    <div className={getFailureContainerCss(containerStyles, containerHeight)}>
      <div className={iconContainerCss}>
        <Icon iconName="icon-warning" size={20} margin="0" />
      </div>
      <div>
        <p>
          <Trans id="defaultFailure.message1" />
        </p>
        <p>
          <Trans id="defaultFailure.message2" />{' '}
          <button className={reloadLinkCss} onClick={() => location.reload()}>
            <Trans id="defaultFailure.button" />
          </button>
          .
        </p>
      </div>
    </div>
  )
}

export default DefaultFailure
