import { Filters } from '@pp/common/typescript/helpers.type'

export const extractFilters = (url: string): Filters[] | undefined => {
  const splitted = url.split('?')
  if (splitted.length > 1) {
    const searchUrl = splitted[splitted.length - 1]
    const splittedSearchUrl = searchUrl.split('&')
    const result = splittedSearchUrl.map((el: string) => {
      return {
        [el.split('=')[0]]: el.split('=')[1],
      }
    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return result
  }
}
