export enum FilingsQueryState {
  ApplicantsFileatPublnAuth = 'applicants_fileat_publnauth',
  LawfirmsFileatPublnauth = 'lawfirms_fileat_publnauth',
  LawfirmsReceivefromCountry = 'lawfirms_receiveFrom_country',
  LawfirmsReceivefromGeolocation = 'lawfirms_receiveFrom_geoloc',
  LawfirmsReceivefromPublnauth = 'lawfirms_receiveFrom_publnauth',
  LawfirmsSendtoCountry = 'lawfirms_sendto_country',
  LawfirmsSendtoGeolocation = 'lawfirms_sendto_geoloc',
  LawfirmsSendtoPublnauth = 'lawfirms_sendto_publnauth',
}

export enum LocationQueryState {
  AroundLocation = 'around_location',
  FromCountries = 'from_countries',
}

export interface ExtendedState {
  state: FilingsQueryState
}

export class QueryStateMachine {
  private state: FilingsQueryState
  private initialState: FilingsQueryState

  constructor(initialState: FilingsQueryState) {
    this.state = initialState
    this.initialState = initialState
  }

  public reset(): FilingsQueryState {
    this.state = this.initialState
    return this.state
  }

  public transition(action: string): FilingsQueryState | undefined {
    const nextState = stateMap[this.state][action]

    if (nextState) {
      this.state = nextState
      return this.state
    }
    return
  }
}

interface IActionResults {
  [key: string]: FilingsQueryState | undefined
  SELECT_APPLICANTS: FilingsQueryState | undefined
  SELECT_AUTHORITY: FilingsQueryState | undefined
  SELECT_COUNTRY: FilingsQueryState | undefined
  SELECT_FILE_AT: FilingsQueryState | undefined
  SELECT_GEOLOCATION: FilingsQueryState | undefined
  SELECT_LAWFIRMS: FilingsQueryState | undefined
  SELECT_RECEIVE_FROM: FilingsQueryState | undefined
  SELECT_SEND_TO: FilingsQueryState | undefined
}

interface StateMap {
  [key: string]: IActionResults
  applicants_fileat_publnauth: IActionResults
  lawfirms_fileat_publnauth: IActionResults
  lawfirms_receiveFrom_country: IActionResults
  lawfirms_receiveFrom_publnauth: IActionResults
  lawfirms_sendto_country: IActionResults
  lawfirms_sendto_publnauth: IActionResults
}

const stateMap: StateMap = {
  applicants_fileat_publnauth: {
    SELECT_APPLICANTS: undefined,
    SELECT_AUTHORITY: undefined,
    SELECT_COUNTRY: undefined,
    SELECT_GEOLOCATION: undefined,
    SELECT_FILE_AT: undefined,
    SELECT_LAWFIRMS: FilingsQueryState.LawfirmsFileatPublnauth,
    SELECT_RECEIVE_FROM: undefined,
    SELECT_SEND_TO: undefined,
  },
  lawfirms_fileat_publnauth: {
    SELECT_APPLICANTS: FilingsQueryState.ApplicantsFileatPublnAuth,
    SELECT_AUTHORITY: undefined,
    SELECT_COUNTRY: undefined,
    SELECT_GEOLOCATION: undefined,
    SELECT_FILE_AT: undefined,
    SELECT_LAWFIRMS: undefined,
    SELECT_RECEIVE_FROM: FilingsQueryState.LawfirmsReceivefromPublnauth,
    SELECT_SEND_TO: FilingsQueryState.LawfirmsSendtoPublnauth,
  },
  lawfirms_receiveFrom_country: {
    SELECT_APPLICANTS: FilingsQueryState.ApplicantsFileatPublnAuth,
    SELECT_AUTHORITY: FilingsQueryState.LawfirmsReceivefromPublnauth,
    SELECT_COUNTRY: undefined,
    SELECT_GEOLOCATION: FilingsQueryState.LawfirmsReceivefromGeolocation,
    SELECT_FILE_AT: FilingsQueryState.LawfirmsFileatPublnauth,
    SELECT_LAWFIRMS: undefined,
    SELECT_RECEIVE_FROM: undefined,
    SELECT_SEND_TO: FilingsQueryState.LawfirmsSendtoCountry,
  },
  lawfirms_receiveFrom_publnauth: {
    SELECT_APPLICANTS: FilingsQueryState.ApplicantsFileatPublnAuth,
    SELECT_AUTHORITY: undefined,
    SELECT_COUNTRY: FilingsQueryState.LawfirmsReceivefromCountry,
    SELECT_GEOLOCATION: FilingsQueryState.LawfirmsReceivefromGeolocation,
    SELECT_FILE_AT: FilingsQueryState.LawfirmsFileatPublnauth,
    SELECT_LAWFIRMS: undefined,
    SELECT_RECEIVE_FROM: undefined,
    SELECT_SEND_TO: FilingsQueryState.LawfirmsSendtoPublnauth,
  },
  lawfirms_receiveFrom_geoloc: {
    SELECT_APPLICANTS: FilingsQueryState.ApplicantsFileatPublnAuth,
    SELECT_AUTHORITY: FilingsQueryState.LawfirmsReceivefromPublnauth,
    SELECT_COUNTRY: FilingsQueryState.LawfirmsReceivefromCountry,
    SELECT_GEOLOCATION: undefined,
    SELECT_FILE_AT: FilingsQueryState.LawfirmsFileatPublnauth,
    SELECT_LAWFIRMS: undefined,
    SELECT_RECEIVE_FROM: undefined,
    SELECT_SEND_TO: FilingsQueryState.LawfirmsSendtoGeolocation,
  },
  lawfirms_sendto_country: {
    SELECT_APPLICANTS: FilingsQueryState.ApplicantsFileatPublnAuth,
    SELECT_AUTHORITY: FilingsQueryState.LawfirmsSendtoPublnauth,
    SELECT_COUNTRY: undefined,
    SELECT_GEOLOCATION: FilingsQueryState.LawfirmsSendtoGeolocation,
    SELECT_FILE_AT: FilingsQueryState.LawfirmsFileatPublnauth,
    SELECT_LAWFIRMS: undefined,
    SELECT_RECEIVE_FROM: FilingsQueryState.LawfirmsReceivefromCountry,
    SELECT_SEND_TO: undefined,
  },
  lawfirms_sendto_publnauth: {
    SELECT_APPLICANTS: FilingsQueryState.ApplicantsFileatPublnAuth,
    SELECT_AUTHORITY: undefined,
    SELECT_COUNTRY: FilingsQueryState.LawfirmsSendtoCountry,
    SELECT_GEOLOCATION: FilingsQueryState.LawfirmsSendtoGeolocation,
    SELECT_FILE_AT: FilingsQueryState.LawfirmsFileatPublnauth,
    SELECT_LAWFIRMS: undefined,
    SELECT_RECEIVE_FROM: FilingsQueryState.LawfirmsReceivefromPublnauth,
    SELECT_SEND_TO: undefined,
  },
  lawfirms_sendto_geoloc: {
    SELECT_APPLICANTS: FilingsQueryState.ApplicantsFileatPublnAuth,
    SELECT_AUTHORITY: FilingsQueryState.LawfirmsSendtoPublnauth,
    SELECT_COUNTRY: FilingsQueryState.LawfirmsSendtoCountry,
    SELECT_GEOLOCATION: undefined,
    SELECT_FILE_AT: FilingsQueryState.LawfirmsFileatPublnauth,
    SELECT_LAWFIRMS: undefined,
    SELECT_RECEIVE_FROM: FilingsQueryState.LawfirmsReceivefromGeolocation,
    SELECT_SEND_TO: undefined,
  },
}
