import { MappedFilterType } from '@pp/common/components/filters/typescript/filters.type'
import { ChartEndpoints, AdvancedSearch } from '@pp/common/typescript/helpers.type'
import _ from 'lodash'

export const parseApplicantFilter = (filters: MappedFilterType, urls: string[], chartEndpoints: ChartEndpoints) => {
  const clonedFilters = _.cloneDeep(filters) as AdvancedSearch
  clonedFilters.advanced_search = { chart_endpoints: chartEndpoints }
  clonedFilters.applicant = { applicant_url: urls }
  return clonedFilters
}
