import { TrackingEvent } from '@pp/common/typescript/tracking.type'

export const getNextSearch = (data: TrackingEvent, dataLayer: TrackingEvent[]) => {
  return dataLayer.find((el: TrackingEvent, index) => {
    const isSearchFound =
      el.event?.type === 'loading-time' &&
      el.event?.description &&
      el.event.description.includes('search-submitted') &&
      el.date !== data.date

    if (isSearchFound) {
      el.index = index
      return el
    }
    return false
  })
}
