import { cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import DashboardIcon from '@pp/assets/icons/dashboard-menu-icon.svg'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { Menu, Image } from 'semantic-ui-react'

import { dashboardRoutes } from '../../../route-groups'
import { iconCss, menuHeaderCss } from '../../../sidebar.css'
import NewLabel from '../../NewLabel'

type Props = {
  active: string
  isMobile: boolean
  menus: {
    analyzeMenu: boolean
    checkMenu: boolean
    resourceSubmenu: boolean
    searchMenu: boolean
  }
  minimizedSideBar: boolean
  onNavLinkClick: (route: string) => void
}

const DashboardMenu = ({ active, minimizedSideBar, isMobile, onNavLinkClick }: Props) => {
  const { applicationStore } = useStores()

  const handleClick = () => {
    if (applicationStore.hasDashboards) {
      onNavLinkClick(routes.dashboardsRoute)
    } else {
      applicationStore.setModal({ open: true, version: '' })
    }
  }

  return (
    <Menu.Header
      className={cx(menuHeaderCss(isMobile, minimizedSideBar), dashboardRoutes.indexOf(active) > -1 ? 'active' : '')}
      onClick={handleClick}
    >
      <div className="notch"></div>
      <span onClick={handleClick}>
        <Image
          src={DashboardIcon}
          className={iconCss(minimizedSideBar)}
          alt="analyse-shevron"
          width="16px"
          height="16px"
        />
        {minimizedSideBar ? null : <Trans id="sidebar.buttons.dashboard" />}
      </span>
      {minimizedSideBar ? null : <NewLabel />}
    </Menu.Header>
  )
}

export default DashboardMenu
