import { cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import HelpCenterIcon from '@pp/assets/icons/help-center-menu-icon.svg'
import { Menu, Image } from 'semantic-ui-react'

import { useStores } from '../../../../../../../store/useStore.hook'
import { resourcesRoutes } from '../../../route-groups'
import { iconCss, menuHeaderCss } from '../../../sidebar.css'
import NewLabel from '../../NewLabel'
import { MenuProps } from '../analyze-menu/AnalyzeMenu'

const ResourcesMenu = ({ active, minimizedSideBar, isMobile }: MenuProps) => {
  const { applicationStore } = useStores()
  const handleClick = () => {
    if (applicationStore.isAuthenticated) {
      window.open('https://www.ip-pilot.com/en/help-center/', '_blank')
    } else {
      applicationStore.setModal({ open: true, version: '' })
    }
  }

  return (
    <Menu.Header
      className={cx(menuHeaderCss(isMobile, minimizedSideBar), resourcesRoutes.indexOf(active) > -1 ? 'active' : '')}
      onClick={handleClick}
    >
      <div className="notch"></div>
      <>
        <Image
          src={HelpCenterIcon}
          className={iconCss(minimizedSideBar)}
          alt="analyse-shevron"
          width="16px"
          height="16px"
        />
        {minimizedSideBar ? null : <Trans id="sidebar.menu.helpCenter" />}
      </>
      {minimizedSideBar ? null : <NewLabel />}
    </Menu.Header>
  )
}

export default ResourcesMenu
