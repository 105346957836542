import { MappedFilterType, MappedFilters } from '@pp/common/components/filters/typescript/filters.type'
import _ from 'lodash'

export const mapLocationFilters = (options: string[], mappedFilters: MappedFilterType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const clonedFilters = _.cloneDeep(mappedFilters) as any
  options.forEach((option) => {
    const splittedPath = option.split('.')
    const prefix = splittedPath[1] || splittedPath[0]
    const nameProp = `${prefix}_name` as MappedFilters
    const highProp = `${prefix}_high` as MappedFilters
    const lowProp = `${prefix}_low` as MappedFilters
    const distanceProp = `${prefix}_distance` as MappedFilters
    const unitProp = `${prefix}_distance_unit` as MappedFilters

    if (mappedFilters[distanceProp] && mappedFilters[unitProp]) {
      splittedPath.reduce((acc, key, index, arr) => {
        const distanceValue = clonedFilters[distanceProp] as string[] | number[]
        const unitValue = clonedFilters[unitProp] as string[] | number[]
        if (index === arr.length - 1)
          acc[key] = {
            bottom_left: { lat: clonedFilters[lowProp][0], lon: clonedFilters[lowProp][1] },
            top_right: { lat: clonedFilters[highProp][0], lon: clonedFilters[highProp][1] },
            offset: [`${distanceValue[0]}${unitValue[0]}`],
          }
        return acc[key]
      }, clonedFilters)
    }
    ;[nameProp, highProp, lowProp, distanceProp, unitProp].forEach((el) => delete clonedFilters[el])
  })
  return clonedFilters
}
