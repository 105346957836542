import React from 'react'

import { ipcFields } from '@pp/common/constants/ipc.const'
import { getTechnicalSectors } from '@pp/modules/analyse/common/charts/technical-fields/technical-sectors'
import * as R from 'ramda'
import { DropdownItemProps } from 'semantic-ui-react'

import { countriesRaw } from './country-raw.const'
import { ICountry, countries } from './country.const'

export enum UIState {
  DisplayResults = 'display_results',
  Pending = 'pending',
  QueryTimeout = 'query_timeout',
  RequestError = 'request_error',
  ZeroResults = 'zero_results',
}

const years = R.times((i) => 1980 + i, new Date().getFullYear() - 1980 + 1)
export const yearFromOptions: DropdownItemProps[] = R.map(
  (year) => ({ text: year.toString(), value: year.toString() }),
  years,
)
export const yearToOptions: DropdownItemProps[] = R.reverse(yearFromOptions)

export const countryOptions = R.map(
  (country: ICountry) => ({
    flag: country.abb,
    text: country.name,
    content: `${country.name} (${country.abb.toUpperCase()})`,
    value: country.abb.toUpperCase(),
    className: country.abb,
  }),
  R.difference(
    countries,
    R.differenceWith((a, b) => a.abb.toUpperCase() === b, countries, countriesRaw),
  ),
)

export const representativeTypeOptions: DropdownItemProps[] = [
  { text: 'internally', value: 'internal' },
  { text: 'externally', value: 'external' },
]

export const ipcOptions: DropdownItemProps[] = ipcFields.map((ipcField) => ({
  value: ipcField.c,
  text: ipcField.c,
  desc: `${ipcField.c} - ${ipcField.d}`,
  content:
    ipcField.c.length > 1 ? (
      <React.Fragment>
        {'\u00A0\u00A0\u00A0'}
        {`${ipcField.c} - ${ipcField.d}`}
      </React.Fragment>
    ) : (
      <strong>{`${ipcField.c} - ${ipcField.d}`}</strong>
    ),
}))

export const technicalFieldsOptions: DropdownItemProps[] = R.flatten(
  getTechnicalSectors().map((sector) => [
    {
      text: sector.sector,
      content: <strong>{sector.sector}</strong>,
      value: R.map(({ id }) => id, sector.fields).join(),
      className: sector.sector.toLowerCase().replace(/ /gi, '-'),
    },
    ...sector.fields.map((field) => ({
      text: field.field,
      content: <div style={{ marginLeft: '5px', fontWeight: 'normal' }}>{field.field}</div>,
      value: field.id.toString(),
      className: field.field.toLowerCase().replace(/ /gi, '-'),
    })),
  ]),
)

export const formatSentReceivedRatio = (value: Record<string, number>): string => {
  const { sent, received } = value
  if (sent + received === 0) {
    return '-'
  }
  if (sent === 0) {
    return '0:1'
  }
  if (received === 0) {
    return '1:0'
  }
  return sent >= received ? `${Math.round(sent / received)}:1` : `1:${Math.round(received / sent)}`
}
