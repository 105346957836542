import { PageInfoKeys, TrackingEvent } from '@pp/common/typescript/tracking.type'

export const checkIfEventIsValid = (checks: boolean[]) => checks.every((check) => check === true)
export const checkIfNextEventIsValid = (checks: (boolean | string)[]) => checks.some((check) => check === true)

export const checkIfSameContainer = (eventA: TrackingEvent, eventB: TrackingEvent) =>
  eventA.pageInfo?.container === eventB.pageInfo?.container

export const checkIfSearchEvent = (cv: TrackingEvent, nextSearch: TrackingEvent) =>
  cv.event?.description && cv.event.description.includes('search-submitted') && checkIfSameContainer(cv, nextSearch)

export const getLastIndexOfUserInteraction = (events: TrackingEvent[]) =>
  events.findIndex((el: TrackingEvent) => el.event?.type !== 'page-view')

export const getRenderedCharts = (previousDataFetchingEvents: TrackingEvent[]) =>
  previousDataFetchingEvents.filter(
    (event) =>
      event.event.entities === previousDataFetchingEvents[0].event.entities &&
      event.event.description.includes('data-rendered'),
  )

export const getPartialCheck = (property: PageInfoKeys, value: string | undefined, nextSearch: TrackingEvent) => {
  return function (cv: TrackingEvent, event: string) {
    return (
      cv.event?.type === event &&
      cv.pageInfo?.container === nextSearch.pageInfo?.container &&
      cv.pageInfo[property] === value
    )
  }
}

export const getChartsRenderDuration = (chartsRendered: TrackingEvent[], previousDataFetchingEvents: TrackingEvent[]) =>
  chartsRendered.map((event) => ({
    chart: event.event.chart,
    duration: (event.date - previousDataFetchingEvents[previousDataFetchingEvents.length - 1].date) / 1000,
    ...event,
  }))

export const getPartialRenderCheck = (property: PageInfoKeys, value: string | undefined) => {
  return function (cv: TrackingEvent) {
    return cv.event?.description && cv.event.description.includes('data-rendered') && cv.pageInfo[property] === value
  }
}
