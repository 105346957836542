import { FilterType } from '@pp/common/components/filters/filter.store'
import { ChartWidgetType } from '@pp/common/typescript/dashboard.type'
import { FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { DropdownItemProps } from 'semantic-ui-react'

import { UIState } from '../../constants/common-options.const'
import { VLimitingClauseType, Result, VSearchParameters } from '../../typescript/search.interface'
import { LocationQueryState } from '../filings/constants/query-state-machine-filings.const'

export type ValidationsDropdownOption = DropdownItemProps & {
  forstates?: ValidationsQueryState[]
  header?: React.ReactElement
}

export enum ValidationsQueryState {
  ApplicantsValidateAtEpPublnAuth = 'applicants_validateat_eppublnauth',
  LawFirmsValidateAtEpPublnAuth = 'lawfirms_validateat_eppublnauth',
  LawfirmsReceiveFromEpCountry = 'lawfirms_receivefrom_epcountry',
  LawfirmsSendToEpCountry = 'lawfirms_sendto_epcountry',
  LawfirmsSendToEpPublnAuth = 'lawfirms_sendto_eppublnauth',
}

export type ExtendedState = { state: ValidationsQueryState }

export type ValidationsSearchStoreInterface = {
  activePage: number
  limitingClauses: VLimitingClauseType[]
  locationQueryState: LocationQueryState
  queryState: ValidationsQueryState
  results: Result[] | null
  searchParameters: VSearchParameters
  uiState: UIState
}

export type SearchFilter = FilterType & {
  [key: string]:
    | string[]
    | FamilyType[]
    | ['LF' | 'A']
    | number[]
    | number[]
    | string[]
    | ChartWidgetType[]
    | boolean[]
    | undefined
  advanced_search_type: ['LF' | 'A']
  builder_purpose: string[]
  confirmed?: boolean[]
  ep_rep_country?: string[]
  ep_rep_url?: string[]
  family_type: FamilyType[]
  relevant_count_from?: number[]
  relevant_count_to?: number[]
  selected_charts?: ChartWidgetType[]
} & FilterType

export interface IActionResults {
  [key: string]: ValidationsQueryState | undefined
  SELECT_APPLICANTS: ValidationsQueryState | undefined
  SELECT_EPAUTHORITY: ValidationsQueryState | undefined
  SELECT_EPCOUNTRY: ValidationsQueryState | undefined
  SELECT_LAWFIRMS: ValidationsQueryState | undefined
  SELECT_RECEIVE_FROM: ValidationsQueryState | undefined
  SELECT_SEND_TO: ValidationsQueryState | undefined
  SELECT_VALIDATE_AT: ValidationsQueryState | undefined
}

export interface StateMap {
  [key: string]: IActionResults
  applicants_validateat_eppublnauth: IActionResults
  lawfirms_receivefrom_epcountry: IActionResults
  lawfirms_sendto_epcountry: IActionResults
  lawfirms_sendto_eppublnauth: IActionResults
  lawfirms_validateat_eppublnauth: IActionResults
}
