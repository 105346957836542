import { t } from '@lingui/macro'
import { DateType } from '@pp/modules/analyse/common/profile-header/components/default-filters/default-filters.type'

export const getDateType = (dateType: DateType) => {
  switch (dateType) {
    case DateType.publicationDate:
      return t`data.attributes.ppDate`

    case DateType.applicationFilingDate:
      return t`data.attributes.filingDate`

    case DateType.earliestPublicationDate:
      return t`data.attributes.publicationDate`

    case DateType.grantDate:
      return t`data.attributes.grantDate`

    case DateType.transferDate:
      return t`data.attributes.transferDate`
  }
}
