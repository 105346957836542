import { TrackingEvent } from '@pp/common/typescript/tracking.type'

const userInactivityInSeconds = 1800

const checkForSession = (lastActive: number, event: number, userInactivityInSeconds: number) => {
  const deltaInSeconds = (event - lastActive) / 1000
  if (deltaInSeconds > 0 && deltaInSeconds > userInactivityInSeconds) return true
  return false
}

const checkLocalStorage = () => {
  const testKey = 'test'
  const storage = window.localStorage
  try {
    storage.setItem(testKey, '1')
    storage.removeItem(testKey)
    return true
  } catch (error) {
    return false
  }
}

const setNewSession = (date: string) => {
  window.localStorage.setItem('user_web_session_first_active', date)
  window.localStorage.setItem('user_web_session_last_active', date)
}

const updateLastActive = (date: string) => window.localStorage.setItem('user_web_session_last_active', date)
const isUserLoggedIn = (event: TrackingEvent) => event.userInfo.userId.length

export const handleSegmentUserSession = (event: TrackingEvent) => {
  if (!isUserLoggedIn(event)) return

  const isLocalStorageSupported = checkLocalStorage()
  if (!isLocalStorageSupported) return

  const firstActive = window.localStorage.getItem('user_web_session_first_active')
  const lastActive = window.localStorage.getItem('user_web_session_last_active')

  if (!firstActive || !lastActive) return setNewSession(new Date(event.date).toISOString())

  const firstActiveDate = Number(new Date(firstActive))
  const lastActiveDate = Number(new Date(lastActive))
  const isNewSession = checkForSession(lastActiveDate, event.date, userInactivityInSeconds)

  if (!isNewSession) return updateLastActive(new Date(event.date).toISOString())

  const duration = (lastActiveDate - firstActiveDate) / 1000
  if (duration <= 0) return setNewSession(new Date(event.date).toISOString())

  const dataToSend = {
    app: 'Patent-Pilot',
    userInfo: { ...event.userInfo },
    event: {
      type: 'user-session',
      content: { endDate: new Date(lastActive), startDate: new Date(firstActive), duration },
    },
    deviceInfo: { ...event.deviceInfo },
    geoInfo: { ...event.geoInfo },
    date: new Date(lastActiveDate),
  }

  setNewSession(new Date(event.date).toISOString())

  // SEGMENT.IO
  window.analytics.track(dataToSend.event.type, dataToSend)
}
