import environment from '@pp/environments'
import _ from 'lodash'
import track from 'react-tracking'

import {
  validateTimeOnPage,
  checkIfEventIsValid,
  handleDataFetchingDuration,
  checkForValidProfileExport,
  checkForValidPageView,
  handleSegmentUserSession,
  apiService,
} from './helpers'
import App from '../App'
import { TrackingEvent, UserSession } from '../common/typescript/tracking.type'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any
    temporaryStorage: (UserSession | TrackingEvent)[]
    trackingLayer: TrackingEvent[]
  }
}

const handleUserSession = _.throttle(apiService.fetchRequest, 2000)

const TrackedApp = track(
  { app: 'Patent-Pilot' },
  {
    dispatch: async (data: TrackingEvent) => {
      handleSegmentUserSession(data)

      if (environment.name === 'production' && data.userInfo.userId.length > 0) {
        handleUserSession(environment.trackingApiUrl, { method: 'POST', body: JSON.stringify(data) })
      }

      // PAGE-VIEW -> eliminating duplicate page-views
      const validPageView = checkForValidPageView(data, window.trackingLayer)

      // EXPORT-PROFILE -> eliminating duplicate profile exports
      const validProfileExport = checkForValidProfileExport(data, window.trackingLayer)

      // TIME-ON-PAGE
      const validTimeOnPage = validateTimeOnPage(data, window.trackingLayer)

      // HANDLE DATA FETCHING TIME
      handleDataFetchingDuration(data, window.trackingLayer)

      if (validTimeOnPage) {
        // ;(window.dataLayer = window.dataLayer || []).push(data)
        ;(window.trackingLayer = window.trackingLayer || []).push(data)
      }

      const isValidEvent = checkIfEventIsValid([
        validPageView,
        validProfileExport,
        validTimeOnPage,
        data.event?.type !== 'loading-time',
        data.event?.type !== 'mouse-position',
        data.event?.type !== 'scroll-position',
        data.event?.type !== 'mouse-click',
      ])

      if (isValidEvent) {
        // SEGMENT.IO
        window.analytics.track(data.event.type, data)
      }
    },
  },
)(App)

export default TrackedApp
