import { ChartConfiguration } from '@pp/common/typescript/dashboard.type'
import { Entity } from '@pp/common/typescript/meta.interface'
import { BuilderPurpose, FamilyType, LanguageEnum } from '@pp/modules/analyse/common/types/app.type'
import { Basic, Enterprise, Free, Professional, Public, Standard, Trial } from '@pp/store/versions.const'
import _ from 'lodash'
import { makeAutoObservable } from 'mobx'

import { ApplicationStoreInterface, ModalObject, PermissionType, VersionsType } from './application-store.interface'
import { RootStoreInterface } from './root.store'
import { ProfileSummaryType } from '../common/custom-hooks/useStaticSummary.hook'

export class ApplicationStore implements ApplicationStoreInterface {
  private rootStore: RootStoreInterface

  agentUrls: string[] = []
  applicantUrls: string[] = []
  hasValidations = false
  hasDataAccess = false
  hasKpiAccess = false
  showUnconfirmedExternalLinks = false
  hasExportPermission = false
  hasLocationPermission = false
  hasValidationsPermission = false
  hasSearchConstraints = false
  hasMapView = false
  hasExtendedSearchResults = false
  hasWhiteSpotCheck = false
  hasFilingCheck = false
  hasFamilyCheck = false
  hasSuitabilityCheck = false
  hasInstructionCheck = false
  hasPeerGroupCheck = false
  hasOneOnOneComparison = false
  hasEditableProfiles = false
  hasLastSearches = false
  hasSavedSearches = false
  hasDashboards = false
  hasCustomCharts = false
  hasNotes = false
  hasBuildCharts = false
  hasAutomatedReports = false
  hasApiAccess = false
  hasIntegration = false
  hasNameSearch = false
  hasStandardSearch = false
  hasInsightsSearch = false
  hasAttorneysTab = false
  hasFilingsTab = false
  hasOverviewTab = false
  hasCaseExchangeTab = false
  hasInsightsTab = false
  hasExecutiveSummaryTab = false
  hasRankingInfoTab = false
  hasChartFilters = false
  hasPersistentFilters = false
  hasNegativeFilters = false
  hasTrademarksPermission = false
  hasPatentsPermission = false
  hasUnconfirmedPermission = false
  hasSupport = false
  hasLawFirms = false
  hasApplicants = false
  hasDateRangeSelector = false
  hasAdminSection = false
  isAdmin = false
  isPdfExport = false
  hasMutuality = false
  hasSimilarity = false
  isAuthenticated = false
  hasInsights = false

  permissionsFetched: boolean | null = null

  metadata: Entity[] = []
  metadataUrls: string[] = []
  familyType: FamilyType[] = []

  modal: ModalObject = { open: false, version: '' }

  chartConfigurations: ChartConfiguration[] = []

  showNotesSection = false
  hasSavedNotes = false
  minimizedSideBar = false
  showFiltersSection = false

  language = LanguageEnum.EN

  builderPurpose = BuilderPurpose.OVERVIEW

  summaryData: ProfileSummaryType | null = null

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  setPermissions(user: unknown) {
    const noPermissions: PermissionType[] = []
    const noVersions: VersionsType[] = []

    if (user) {
      const products = _.get(user, 'https://app.ip-pilot.com/products', noPermissions)
      if (products.length > 0 && products.includes(PermissionType.Trademarks)) this.hasTrademarksPermission = true
      if (products.length > 0 && products.includes(PermissionType.Patents)) this.hasPatentsPermission = true

      const permissions = _.get(user, 'https://app.ip-pilot.com/permissions', noPermissions)
      if (permissions.length > 0 && permissions.includes(PermissionType.Export)) this.hasExportPermission = true
      if (permissions.length > 0 && permissions.includes(PermissionType.Location)) this.hasLocationPermission = true
      if (permissions.length > 0 && permissions.includes(PermissionType.AttorneysTab)) this.hasAttorneysTab = true
      if (permissions.length > 0 && permissions.includes(PermissionType.FilingsTab)) this.hasFilingsTab = true
      if (permissions.length > 0 && permissions.includes(PermissionType.MapView)) this.hasMapView = true
      if (permissions.length > 0 && permissions.includes(PermissionType.WhiteSpotCheck)) this.hasWhiteSpotCheck = true
      if (permissions.length > 0 && permissions.includes(PermissionType.FilingCheck)) this.hasFilingCheck = true
      if (permissions.length > 0 && permissions.includes(PermissionType.Dashboard)) this.hasDashboards = true
      if (permissions.length > 0 && permissions.includes(PermissionType.Similarity)) this.hasSimilarity = true
      if (permissions.length > 0 && permissions.includes(PermissionType.Mutuality)) this.hasMutuality = true
      if (permissions.length > 0 && permissions.includes(PermissionType.CustomCharts)) this.hasCustomCharts = true
      if (permissions.length > 0 && permissions.includes(PermissionType.Insights)) this.hasInsights = true

      if (permissions.length > 0 && permissions.includes(PermissionType.SearchConstraints)) {
        this.hasSearchConstraints = true
      }
      if (permissions.length > 0 && permissions.includes(PermissionType.ExtendedSearchResults)) {
        this.hasExtendedSearchResults = true
      }

      const isAdmin = _.get(user, 'https://app.ip-pilot.com/admin') as string | undefined
      if (isAdmin && isAdmin.length > 0 && isAdmin === 'yes') this.isAdmin = true

      const dataPacks = _.get(user, 'https://app.ip-pilot.com/dataPacks', noPermissions)
      if (dataPacks.length > 0 && dataPacks.includes(PermissionType.Validations)) this.hasValidationsPermission = true

      const versions = _.get(user, 'https://app.ip-pilot.com/versions', noVersions)

      if (versions.length > 0 && versions.includes(VersionsType.Public)) {
        Public.forEach((permission) => (this[permission] = true))
      }

      if (versions.length > 0 && versions.includes(VersionsType.Free)) {
        Free.forEach((permission) => (this[permission] = true))
      }

      if (versions.length > 0 && versions.includes(VersionsType.Basic)) {
        Basic.forEach((permission) => (this[permission] = true))
      }

      if (versions.length > 0 && versions.includes(VersionsType.Standard)) {
        Standard.forEach((permission) => (this[permission] = true))
      }

      if (versions.length > 0 && versions.includes(VersionsType.Trial)) {
        Trial.forEach((permission) => (this[permission] = true))
      }

      if (versions.length > 0 && versions.includes(VersionsType.Professional)) {
        Professional.forEach((permission) => (this[permission] = true))
      }

      if (versions.length > 0 && versions.includes(VersionsType.Enterprise)) {
        Enterprise.forEach((permission) => (this[permission] = true))
      }
      this.permissionsFetched = true
    } else {
      this.permissionsFetched = false
      this.hasTrademarksPermission = true
      this.hasPatentsPermission = true
    }
  }

  setAgentUrls = (agentUrls: string[]) => (this.agentUrls = agentUrls)
  setApplicantUrls = (applicantUrls: string[]) => (this.applicantUrls = applicantUrls)
  setHasValidations = (hasValidations: boolean) => (this.hasValidations = hasValidations)
  setMetadataUrls = (metadataUrls: string[]) => (this.metadataUrls = metadataUrls)
  setPdfExport = (isPdfExport: boolean) => (this.isPdfExport = isPdfExport)
  setModal = (modalObject: ModalObject) => (this.modal = modalObject)
  setShowNotesSection = (showNotesSection: boolean) => (this.showNotesSection = showNotesSection)
  setShowFiltersSection = (showFiltersSection: boolean) => (this.showFiltersSection = showFiltersSection)
  setHasSavedNotes = (hasSavedNotes: boolean) => (this.hasSavedNotes = hasSavedNotes)
  setBuilderPurpose = (builderPurpose: BuilderPurpose) => (this.builderPurpose = builderPurpose)
  toggleMinimizedSidebar = () => (this.minimizedSideBar = !this.minimizedSideBar)
  closeMinimizedSidebar = () => (this.minimizedSideBar = false)

  setMetadata(entity: Entity | Entity[]) {
    if (Array.isArray(entity)) {
      const nonUniqueArray = [...this.metadata, ...entity]
      this.metadata = _.uniqBy(nonUniqueArray, 'urlIdentifier')
    } else this.metadata.push(entity)
  }

  setSummaryData(data: ProfileSummaryType) {
    this.summaryData = data
  }

  setFamilyType = (familyType: FamilyType[]) => {
    // Permission check on family type
    const localFamilyType: FamilyType[] = []
    if (this.hasTrademarksPermission && familyType.includes('T')) localFamilyType.push('T')
    if (this.hasPatentsPermission && familyType.includes('P')) localFamilyType.push('P')

    this.familyType = localFamilyType
  }

  removeFamilyType = (familyType: FamilyType) => {
    this.familyType = this.familyType.filter((type) => type !== familyType)
  }

  setChartConfigurations = (chartConfigurations: ChartConfiguration | ChartConfiguration[]) => {
    if (Array.isArray(chartConfigurations)) this.chartConfigurations = [...chartConfigurations]
    else this.chartConfigurations.push(chartConfigurations)
  }

  setLanguage = (language: LanguageEnum) => {
    localStorage.setItem('locale', language)
    this.language = language
    const routerStore = this.rootStore.routerStore
    const search = routerStore.location.search || ''
    const searchParams = new URLSearchParams(search)

    if (searchParams.get('locale') !== language) {
      searchParams.set('locale', language)
      routerStore.replace(`${routerStore.location.pathname}?${searchParams.toString()}`)
    }
  }

  setAuthenticated = (isAuthenticated: boolean) => {
    this.isAuthenticated = isAuthenticated
  }
}
