export const compareFilters = (arrA: Object[], arrB: Object[] | undefined): boolean => {
  if (!arrA && !arrB) return true
  if (!arrA || !arrB) return false
  if (arrA.length !== arrB.length) return false
  const filterOutDateTypeA = arrA.filter((el) => !Object.keys(el).includes('date_type'))
  const filterOutDateTypeB = arrB.filter((el) => !Object.keys(el).includes('date_type'))
  const sortedArrA = JSON.stringify(filterOutDateTypeA.map((el) => Object.entries(el).flat()).sort())
  const sortedArrB = JSON.stringify(filterOutDateTypeB.map((el) => Object.entries(el).flat()).sort())
  return sortedArrA === sortedArrB
}
