export type IPCField = { c: string; d: string }

export const ipcFields: IPCField[] = [
  {
    c: 'A',
    d: 'HUMAN NECESSITIES',
  },
  {
    c: 'A01',
    d: 'AGRICULTURE; FORESTRY; ANIMAL HUSBANDRY; HUNTING; TRAPPING; FISHING',
  },
  {
    c: 'A01B',
    d: 'SOIL WORKING IN AGRICULTURE OR FORESTRY; PARTS, DETAILS, OR ACCESSORIES OF AGRICULTURAL MACHINES OR IMPLEMENTS, IN GENERAL',
  },
  {
    c: 'A01B 1',
    d: 'Hand tools',
  },
  {
    c: 'A01B 3',
    d: 'Ploughs',
  },
  {
    c: 'A01B 5',
    d: 'Ploughs with rolling non-driven tools, e.g. discs',
  },
  {
    c: 'A01B 7',
    d: 'Disc-like soil-working implements usable either as ploughs or as harrows, or the like',
  },
  {
    c: 'A01B 9',
    d: 'Ploughs with rotary driven tools',
  },
  {
    c: 'A01B 11',
    d: 'Ploughs with oscillating, digging or piercing tools',
  },
  {
    c: 'A01B 13',
    d: 'Ploughs or like machines for special purposes',
  },
  {
    c: 'A01B 15',
    d: 'Elements, tools, or details of ploughs',
  },
  {
    c: 'A01B 17',
    d: 'Ploughs with special additional arrangements, e.g. means for putting manure under the soil, clod-crushers',
  },
  {
    c: 'A01B 19',
    d: 'Harrows',
  },
  {
    c: 'A01B 21',
    d: 'Harrows with rotary non-driven tools',
  },
  {
    c: 'A01B 23',
    d: 'Elements, tools, or details of harrows',
  },
  {
    c: 'A01B 25',
    d: 'Harrows with special additional arrangements, e.g. means for distributing fertilisers; Harrows for special purposes',
  },
  {
    c: 'A01B 27',
    d: 'Other machines for working soil',
  },
  {
    c: 'A01B 29',
    d: 'Rollers',
  },
  {
    c: 'A01B 31',
    d: 'Drags',
  },
  {
    c: 'A01B 33',
    d: 'Tilling implements with rotary driven tools',
  },
  {
    c: 'A01B 35',
    d: 'Other machines for working soil',
  },
  {
    c: 'A01B 37',
    d: 'Devices for loosening soil compacted by wheels or the like',
  },
  {
    c: 'A01B 39',
    d: 'Other machines specially adapted for working soil on which crops are growing',
  },
  {
    c: 'A01B 41',
    d: 'Thinning machines',
  },
  {
    c: 'A01B 43',
    d: 'Gatherers for removing stones, undesirable roots or the like from the soil, e.g. tractor-drawn rakes',
  },
  {
    c: 'A01B 45',
    d: 'Machines for treating meadows or lawns',
  },
  {
    c: 'A01B 47',
    d: 'Soil-working with electric potential applied between tools and soil',
  },
  {
    c: 'A01B 49',
    d: 'Combined machines',
  },
  {
    c: 'A01B 51',
    d: 'Parts, details or accessories of agricultural machines or implements, in general',
  },
  {
    c: 'A01B 59',
    d: 'Devices specially adapted for connection between animals or tractors and agricultural machines or implements',
  },
  {
    c: 'A01B 61',
    d: 'Devices for, or parts of, agricultural machines or implements for preventing overstrain',
  },
  {
    c: 'A01B 63',
    d: 'Lifting or adjusting devices or arrangements for agricultural machines or implements',
  },
  {
    c: 'A01B 67',
    d: 'Devices for controlling the tractor motor by resistance of tools',
  },
  {
    c: 'A01B 69',
    d: 'Steering of agricultural machines or implements; Guiding agricultural machines or implements on a desired track',
  },
  {
    c: 'A01B 71',
    d: 'Construction or arrangement of setting or adjusting mechanisms, of implement or tool drive or of power take-off; Means for protecting parts against dust, or the like; Adapting machine elements to or for agricultural purposes',
  },
  {
    c: 'A01B 73',
    d: 'Means or arrangements to facilitate transportation of agricultural machines or implements, e.g. folding frames to reduce overall width',
  },
  {
    c: 'A01B 75',
    d: 'Carriers for supporting persons when working in the field, e.g. while thinning beet',
  },
  {
    c: 'A01B 76',
    d: 'Parts, details or accessories of agricultural machines or implements, not provided for in groups A01B0051000000-A01B0075000000',
  },
  {
    c: 'A01B 77',
    d: 'Machines for lifting and treating soil',
  },
  {
    c: 'A01B 79',
    d: 'Methods for working soil',
  },
  {
    c: 'A01C',
    d: 'PLANTING; SOWING; FERTILISING',
  },
  {
    c: 'A01C 1',
    d: 'Apparatus, or methods of use thereof, for testing or treating seed, roots, or the like, prior to sowing or planting',
  },
  {
    c: 'A01C 3',
    d: 'Treating manure; Manuring',
  },
  {
    c: 'A01C 5',
    d: 'Making or covering furrows or holes for sowing, planting, or manuring',
  },
  {
    c: 'A01C 7',
    d: 'Sowing',
  },
  {
    c: 'A01C 9',
    d: 'Potato planters',
  },
  {
    c: 'A01C 11',
    d: 'Transplanting machines',
  },
  {
    c: 'A01C 13',
    d: 'Machines or apparatus for consolidating soil around plants',
  },
  {
    c: 'A01C 14',
    d: 'Methods or apparatus for planting not provided for in other groups of this subclass',
  },
  {
    c: 'A01C 15',
    d: 'Fertiliser distributors',
  },
  {
    c: 'A01C 17',
    d: 'Fertilisers or seeders with centrifugal wheels',
  },
  {
    c: 'A01C 19',
    d: 'Arrangements for driving working parts of fertilisers or seeders',
  },
  {
    c: 'A01C 21',
    d: 'Methods of fertilising',
  },
  {
    c: 'A01C 23',
    d: 'Distributing devices specially adapted for liquid manure or other fertilising liquid, including ammonia, e.g. transport tanks, sprinkling wagons',
  },
  {
    c: 'A01D',
    d: 'HARVESTING; MOWING',
  },
  {
    c: 'A01D 1',
    d: 'Hand implements',
  },
  {
    c: 'A01D 3',
    d: 'Non-abrasive sharpening devices for scythes, sickles, or the like',
  },
  {
    c: 'A01D 5',
    d: 'Containers for whetstones for use during harvesting',
  },
  {
    c: 'A01D 7',
    d: 'Rakes',
  },
  {
    c: 'A01D 9',
    d: 'Forks',
  },
  {
    c: 'A01D 11',
    d: 'Other hand implements',
  },
  {
    c: 'A01D 13',
    d: 'Machines for harvesting root crops',
  },
  {
    c: 'A01D 15',
    d: 'Digging machines with sieve graters but without conveying mechanisms',
  },
  {
    c: 'A01D 17',
    d: 'Digging machines with sieving and conveying mechanisms',
  },
  {
    c: 'A01D 19',
    d: 'Digging machines with centrifugal wheels, drums, or spinners',
  },
  {
    c: 'A01D 21',
    d: 'Digging machines with potato-picking implements',
  },
  {
    c: 'A01D 23',
    d: 'Topping machines',
  },
  {
    c: 'A01D 25',
    d: 'Lifters for beet or like crops',
  },
  {
    c: 'A01D 27',
    d: 'Machines with both topping and lifting mechanisms',
  },
  {
    c: 'A01D 29',
    d: 'Harvesters for peanuts',
  },
  {
    c: 'A01D 31',
    d: 'Other digging harvesters',
  },
  {
    c: 'A01D 33',
    d: 'Accessories for digging harvesters',
  },
  {
    c: 'A01D 34',
    d: 'Harvesters or mowers for grass, cereals or other crops',
  },
  {
    c: 'A01D 37',
    d: 'Reaper-binders',
  },
  {
    c: 'A01D 39',
    d: 'Independent binders, e.g. for hay; Binders attachable to mowers',
  },
  {
    c: 'A01D 41',
    d: 'Combines, i.e. harvesters or mowers combined with threshing devices',
  },
  {
    c: 'A01D 42',
    d: 'Mowers convertible to apparatus for purposes other than mowing; Mowers capable of performing operations other than mowing',
  },
  {
    c: 'A01D 43',
    d: 'Mowers combined with apparatus performing additional operations while mowing',
  },
  {
    c: 'A01D 44',
    d: 'Harvesting of underwater plants, e.g. harvesting of seaweed',
  },
  {
    c: 'A01D 45',
    d: 'Harvesting of standing crops',
  },
  {
    c: 'A01D 46',
    d: 'Picking of fruits, vegetables, hops, or the like; Devices for shaking trees or shrubs',
  },
  {
    c: 'A01D 47',
    d: 'Headers',
  },
  {
    c: 'A01D 51',
    d: 'Apparatus for gathering together crops spread on the soil, e.g. apples, beets, nuts, potatoes',
  },
  {
    c: 'A01D 57',
    d: 'Components of harvesters or mowers for grass or cereals',
  },
  {
    c: 'A01D 59',
    d: 'Equipment for binding harvested or mown produce',
  },
  {
    c: 'A01D 61',
    d: 'Elevators or conveyors for binders or combines',
  },
  {
    c: 'A01D 63',
    d: 'Outside dividers',
  },
  {
    c: 'A01D 65',
    d: 'Grain-crop lifters',
  },
  {
    c: 'A01D 67',
    d: 'Undercarriages or frames specially adapted for harvesters or mowers',
  },
  {
    c: 'A01D 69',
    d: 'Driving mechanisms or parts thereof for harvesters or mowers',
  },
  {
    c: 'A01D 75',
    d: 'Accessories for harvesters or mowers',
  },
  {
    c: 'A01D 76',
    d: 'Haymakers; Crop conditioners',
  },
  {
    c: 'A01D 78',
    d: 'Haymakers with tines moving with respect to the machine',
  },
  {
    c: 'A01D 80',
    d: 'Parts or details of haymakers',
  },
  {
    c: 'A01D 82',
    d: 'Crop conditioners, i.e. machines for crushing or bruising stalks',
  },
  {
    c: 'A01D 84',
    d: 'Haymakers not provided for in a single one of groups A01D0076000000-A01D0082000000',
  },
  {
    c: 'A01D 85',
    d: 'Arrangements for making or setting stacks',
  },
  {
    c: 'A01D 87',
    d: 'Loaders for hay or like field crops',
  },
  {
    c: 'A01D 89',
    d: 'Pick-ups for loaders, chaff-cutters, balers, field-threshers, or the like',
  },
  {
    c: 'A01D 90',
    d: 'Vehicles for carrying harvested crops with means for selfloading or unloading',
  },
  {
    c: 'A01D 91',
    d: 'Methods for harvesting agricultural products',
  },
  {
    c: 'A01D 93',
    d: 'Harvesting apparatus not provided for in other groups of this subclass',
  },
  {
    c: 'A01D 101',
    d: 'Indexing scheme associated with group A01D0034000000, relating to the use of mowers.',
  },
  {
    c: 'A01F',
    d: 'THRESHING',
  },
  {
    c: 'A01F 1',
    d: 'Stationary apparatus or hand tools for forming or binding straw, hay or the like into bundles',
  },
  {
    c: 'A01F 3',
    d: 'Hand-operated implements for cutting-up straw, hay or the like',
  },
  {
    c: 'A01F 5',
    d: 'Threshing',
  },
  {
    c: 'A01F 7',
    d: 'Threshing apparatus',
  },
  {
    c: 'A01F 11',
    d: 'Threshing apparatus specially adapted for maize; Threshing apparatus specially adapted for particular crops other than cereals',
  },
  {
    c: 'A01F 12',
    d: 'Parts or details of threshing apparatus',
  },
  {
    c: 'A01F 13',
    d: 'Baling of straw, hay or the like',
  },
  {
    c: 'A01F 15',
    d: 'Baling presses for straw, hay or the like',
  },
  {
    c: 'A01F 17',
    d: 'Accessories for threshing machines or baling presses',
  },
  {
    c: 'A01F 19',
    d: 'Devices for securing threshing machines or baling presses to the ground, e.g. for compensating for unevenness of the ground',
  },
  {
    c: 'A01F 21',
    d: 'Devices for protecting human beings for threshing machines or baling presses',
  },
  {
    c: 'A01F 25',
    d: 'Storing agricultural or horticultural produce; Hanging-up harvested fruit',
  },
  {
    c: 'A01F 29',
    d: 'Cutting apparatus specially adapted for cutting hay, straw or the like',
  },
  {
    c: 'A01G',
    d: 'HORTICULTURE; CULTIVATION OF VEGETABLES, FLOWERS, RICE, FRUIT, VINES, HOPS OR SEAWEED; FORESTRY; WATERING',
  },
  {
    c: 'A01G 2',
    d: 'Vegetative propagation',
  },
  {
    c: 'A01G 3',
    d: 'Cutting implements specially adapted for horticultural purposes; Delimbing standing trees',
  },
  {
    c: 'A01G 5',
    d: 'Floral handling',
  },
  {
    c: 'A01G 7',
    d: 'Botany in general',
  },
  {
    c: 'A01G 9',
    d: 'Cultivation in receptacles, forcing-frames or greenhouses',
  },
  {
    c: 'A01G 11',
    d: 'Sterilising soil by steam',
  },
  {
    c: 'A01G 13',
    d: 'Protecting plants',
  },
  {
    c: 'A01G 15',
    d: 'Devices or methods for influencing weather conditions',
  },
  {
    c: 'A01G 17',
    d: 'Cultivation of hops, vines, fruit trees, or like trees',
  },
  {
    c: 'A01G 18',
    d: 'Cultivation of mushrooms',
  },
  {
    c: 'A01G 20',
    d: 'Cultivation of turf, lawn or the like; Apparatus or methods therefor',
  },
  {
    c: 'A01G 22',
    d: 'Cultivation of specific crops or plants not otherwise provided for',
  },
  {
    c: 'A01G 23',
    d: 'Forestry',
  },
  {
    c: 'A01G 24',
    d: 'Growth substrates; Culture media; Apparatus or methods therefor',
  },
  {
    c: 'A01G 25',
    d: 'Watering gardens, fields, sports grounds or the like',
  },
  {
    c: 'A01G 27',
    d: 'Self-acting watering devices, e.g. for flower-pots',
  },
  {
    c: 'A01G 29',
    d: 'Root feeders; Injecting fertilisers into the roots',
  },
  {
    c: 'A01G 31',
    d: 'Soilless cultivation, e.g. hydroponics',
  },
  {
    c: 'A01G 33',
    d: 'Cultivation of seaweed',
  },
  {
    c: 'A01H',
    d: 'NEW PLANTS OR PROCESSES FOR OBTAINING THEM; PLANT REPRODUCTION BY TISSUE CULTURE TECHNIQUES',
  },
  {
    c: 'A01H 1',
    d: 'Processes',
  },
  {
    c: 'A01H 3',
    d: 'Processes for modifying phenotypes',
  },
  {
    c: 'A01H 4',
    d: 'Plant reproduction by tissue culture techniques',
  },
  {
    c: 'A01H 5',
    d: 'Products',
  },
  {
    c: 'A01H 6',
    d: 'Angiosperms, i.e. flowering plants, characterised by their botanic taxonomy',
  },
  {
    c: 'A01H 7',
    d: 'Gymnosperms, e.g. conifers',
  },
  {
    c: 'A01H 9',
    d: 'Pteridophytes, e.g. ferns, club-mosses, horse-tails',
  },
  {
    c: 'A01H 11',
    d: 'Bryophytes, e.g. mosses, liverworts',
  },
  {
    c: 'A01H 13',
    d: 'Algae',
  },
  {
    c: 'A01H 15',
    d: 'Fungi; Lichens',
  },
  {
    c: 'A01H 17',
    d: 'Symbiotic or parasitic combinations including one or more new plants, e.g. mycorrhiza',
  },
  {
    c: 'A01J',
    d: 'MANUFACTURE OF DAIRY PRODUCTS',
  },
  {
    c: 'A01J 1',
    d: 'Milking',
  },
  {
    c: 'A01J 3',
    d: 'Milking with catheters',
  },
  {
    c: 'A01J 5',
    d: 'Milking machines or devices',
  },
  {
    c: 'A01J 7',
    d: 'Accessories for milking machines or devices',
  },
  {
    c: 'A01J 9',
    d: 'Milk receptacles',
  },
  {
    c: 'A01J 11',
    d: 'Treatment of milk or cream',
  },
  {
    c: 'A01J 13',
    d: 'Tanks for treating cream',
  },
  {
    c: 'A01J 15',
    d: 'Manufacturing butter',
  },
  {
    c: 'A01J 17',
    d: 'Kneading or forming butter; Kneading or forming margarine or butter substitutes',
  },
  {
    c: 'A01J 19',
    d: 'Hand devices for forming slabs of butter, or the like',
  },
  {
    c: 'A01J 21',
    d: 'Machines for forming slabs of butter, or the like',
  },
  {
    c: 'A01J 23',
    d: 'Devices for dividing bulk butter, or the like',
  },
  {
    c: 'A01J 25',
    d: 'Cheese-making',
  },
  {
    c: 'A01J 27',
    d: 'After-treatment of cheese; Coating the cheese',
  },
  {
    c: 'A01J 99',
    d: 'n.a.',
  },
  {
    c: 'A01K',
    d: 'ANIMAL HUSBANDRY; CARE OF BIRDS, FISHES, INSECTS; FISHING; REARING OR BREEDING ANIMALS, NOT OTHERWISE PROVIDED FOR; NEW BREEDS OF ANIMALS',
  },
  {
    c: 'A01K 1',
    d: 'Animal husbandry in general, especially cattle-raising',
  },
  {
    c: 'A01K 3',
    d: 'Pasturing equipment, e.g. tethering devices; Grids for preventing cattle from straying; Electrified wire fencing',
  },
  {
    c: 'A01K 5',
    d: 'Feeding devices for stock or game',
  },
  {
    c: 'A01K 7',
    d: 'Watering equipment for stock or game',
  },
  {
    c: 'A01K 9',
    d: 'Sucking apparatus for young stock',
  },
  {
    c: 'A01K 11',
    d: 'Marking of animals',
  },
  {
    c: 'A01K 13',
    d: 'Devices for grooming or caring of animals, e.g. curry-combs; Fetlock rings; Tail-holders',
  },
  {
    c: 'A01K 14',
    d: 'Removing the fleece from live sheep or similar animals',
  },
  {
    c: 'A01K 15',
    d: 'Devices for taming animals, e.g. nose-rings or hobbles; Devices for overturning animals in general; Training or exercising equipment; Covering boxes',
  },
  {
    c: 'A01K 17',
    d: 'Dehorners; Horn trainers',
  },
  {
    c: 'A01K 19',
    d: 'Weaning apparatus',
  },
  {
    c: 'A01K 21',
    d: 'Devices for assisting or preventing mating',
  },
  {
    c: 'A01K 23',
    d: 'Manure or urine pouches',
  },
  {
    c: 'A01K 25',
    d: 'Muzzles',
  },
  {
    c: 'A01K 27',
    d: 'Leads or collars, e.g. for dogs',
  },
  {
    c: 'A01K 29',
    d: 'Other apparatus for animal husbandry',
  },
  {
    c: 'A01K 31',
    d: 'Aviculture; Testing, cleaning, stamping, or grading eggs',
  },
  {
    c: 'A01K 33',
    d: 'Nest-eggs',
  },
  {
    c: 'A01K 35',
    d: 'Marking poultry or other birds',
  },
  {
    c: 'A01K 37',
    d: 'Constraining birds, e.g. wing clamps',
  },
  {
    c: 'A01K 39',
    d: 'Feeding or drinking appliances for poultry or other birds',
  },
  {
    c: 'A01K 41',
    d: 'Incubators for poultry',
  },
  {
    c: 'A01K 43',
    d: 'Testing, sorting, or cleaning eggs',
  },
  {
    c: 'A01K 45',
    d: 'Other aviculture appliances, e.g. devices for determining whether a bird is about to lay',
  },
  {
    c: 'A01K 47',
    d: 'Apiculture',
  },
  {
    c: 'A01K 49',
    d: 'Rearing-boxes; Queen transporting or introducing cages',
  },
  {
    c: 'A01K 51',
    d: 'Appliances for treating beehives or parts thereof, e.g. for cleaning or disinfecting',
  },
  {
    c: 'A01K 53',
    d: 'Feeding or drinking appliances for bees',
  },
  {
    c: 'A01K 55',
    d: "Bee-smokers; Bee-keepers' accessories, e.g. veils",
  },
  {
    c: 'A01K 57',
    d: 'Appliances for providing, preventing, or catching swarms; Drone-catching devices',
  },
  {
    c: 'A01K 59',
    d: 'Honey collection',
  },
  {
    c: 'A01K 61',
    d: 'Pisciculture; Aquaria; Terraria',
  },
  {
    c: 'A01K 63',
    d: 'Receptacles for live fish, e.g. aquaria',
  },
  {
    c: 'A01K 65',
    d: 'Fish stringers',
  },
  {
    c: 'A01K 67',
    d: 'Rearing or breeding animals, not otherwise provided for; New breeds of animals',
  },
  {
    c: 'A01K 69',
    d: 'Fishing',
  },
  {
    c: 'A01K 71',
    d: 'Floating fishing nets',
  },
  {
    c: 'A01K 73',
    d: 'Drawn fishing nets',
  },
  {
    c: 'A01K 74',
    d: 'Other catching nets or the like for fishing',
  },
  {
    c: 'A01K 75',
    d: 'Accessories for fishing nets; Details of fishing nets, e.g. structure',
  },
  {
    c: 'A01K 77',
    d: 'Landing-nets for fishing; Landing-spoons for fishing',
  },
  {
    c: 'A01K 79',
    d: 'Methods or means of catching fish in bulk not provided for in groups A01K0069000000-A01K0077000000',
  },
  {
    c: 'A01K 80',
    d: 'Harvesting oysters, mussels, sponges or the like',
  },
  {
    c: 'A01K 81',
    d: 'Fishing with projectiles',
  },
  {
    c: 'A01K 83',
    d: 'Angling',
  },
  {
    c: 'A01K 85',
    d: 'Artificial bait for fishing',
  },
  {
    c: 'A01K 87',
    d: 'Fishing rods',
  },
  {
    c: 'A01K 89',
    d: 'Fishing reels',
  },
  {
    c: 'A01K 91',
    d: 'Fishing lines',
  },
  {
    c: 'A01K 93',
    d: 'Floats for angling, with or without signalling devices',
  },
  {
    c: 'A01K 95',
    d: 'Sinkers for angling',
  },
  {
    c: 'A01K 97',
    d: 'Accessories for angling',
  },
  {
    c: 'A01K 99',
    d: 'Methods or apparatus for fishing not provided for in groups A01K0069000000-A01K0097000000',
  },
  {
    c: 'A01L',
    d: 'SHOEING OF ANIMALS',
  },
  {
    c: 'A01L 1',
    d: 'Shoes for horses or other solipeds fastened with nails',
  },
  {
    c: 'A01L 3',
    d: 'Horseshoes fastened by means other than nails, with or without additional fastening by nailing',
  },
  {
    c: 'A01L 5',
    d: 'Horseshoes made of elastic materials',
  },
  {
    c: 'A01L 7',
    d: 'Accessories for shoeing animals',
  },
  {
    c: 'A01L 9',
    d: 'Shoes for other animals, e.g. oxen',
  },
  {
    c: 'A01L 11',
    d: "Farriers' tools or appliances",
  },
  {
    c: 'A01L 13',
    d: 'Pens for animals while being shod',
  },
  {
    c: 'A01L 15',
    d: 'Apparatus or use of substances for the care of hoofs',
  },
  {
    c: 'A01M',
    d: 'CATCHING, TRAPPING OR SCARING OF ANIMALS',
  },
  {
    c: 'A01M 1',
    d: 'Stationary means for catching or killing insects',
  },
  {
    c: 'A01M 3',
    d: 'Manual implements, other than sprayers or powder distributors, for catching or killing insects, e.g. butterfly nets',
  },
  {
    c: 'A01M 5',
    d: 'Catching insects in fields, gardens, or forests by movable appliances',
  },
  {
    c: 'A01M 7',
    d: 'Special adaptations or arrangements of liquid-spraying apparatus for purposes covered by this subclass',
  },
  {
    c: 'A01M 9',
    d: 'Special adaptations or arrangements of powder-spraying apparatus for purposes covered by this subclass',
  },
  {
    c: 'A01M 11',
    d: 'Special adaptations or arrangements of combined liquid- and powder-spraying apparatus for purposes covered by this subclass',
  },
  {
    c: 'A01M 13',
    d: 'Fumigators; Apparatus for distributing gases',
  },
  {
    c: 'A01M 15',
    d: 'Flame-throwers specially adapted for purposes covered by this subclass',
  },
  {
    c: 'A01M 17',
    d: 'Apparatus for the destruction of vermin in soil or in foodstuffs',
  },
  {
    c: 'A01M 19',
    d: 'Apparatus for the destruction of noxious animals, other than insects, by hot water, steam, hot air, or electricity',
  },
  {
    c: 'A01M 21',
    d: 'Apparatus for the destruction of unwanted vegetation, e.g. weeds',
  },
  {
    c: 'A01M 23',
    d: 'Traps for animals',
  },
  {
    c: 'A01M 25',
    d: 'Devices for dispensing poison for animals',
  },
  {
    c: 'A01M 27',
    d: 'Apparatus having projectiles or killing implements projected to kill the animal, e.g. pierce or shoot, and triggered thereby',
  },
  {
    c: 'A01M 29',
    d: 'Scaring or repelling devices, e.g. bird-scaring apparatus',
  },
  {
    c: 'A01M 31',
    d: 'Hunting appliances',
  },
  {
    c: 'A01M 99',
    d: 'n.a.',
  },
  {
    c: 'A01N',
    d: 'PRESERVATION OF BODIES OF HUMANS OR ANIMALS OR PLANTS OR PARTS THEREOF',
  },
  {
    c: 'A01N 1',
    d: 'Preservation of bodies of humans or animals, or plants, or parts thereof',
  },
  {
    c: 'A01N 3',
    d: 'Preservation of plants or parts thereof, e.g. inhibiting evaporation, improvement of the appearance of leaves',
  },
  {
    c: 'A01N 25',
    d: 'Biocides; Pest repellants or attractants; Plant growth regulators',
  },
  {
    c: 'A01N 27',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing hydrocarbons',
  },
  {
    c: 'A01N 29',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing halogenated hydrocarbons',
  },
  {
    c: 'A01N 31',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing organic oxygen or sulfur compounds',
  },
  {
    c: 'A01N 33',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing organic nitrogen compounds',
  },
  {
    c: 'A01N 35',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing organic compounds containing a carbon atom having two bonds to hetero atoms with at the most one bond to halogen, e.g. aldehyde radical',
  },
  {
    c: 'A01N 37',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing organic compounds containing a carbon atom having three bonds to hetero atoms with at the most two bonds to halogen, e.g. carboxylic acids',
  },
  {
    c: 'A01N 39',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing aryloxy- or arylthio-aliphatic or cycloaliphatic compounds, containing the group or , e.g. phenoxyethylamine, phenylthio-acetonitrile, phenoxyacetone',
  },
  {
    c: 'A01N 41',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing organic compounds containing a sulfur atom bound to a hetero atom',
  },
  {
    c: 'A01N 43',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing heterocyclic compounds',
  },
  {
    c: 'A01N 45',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing compounds having three or more carbocyclic rings condensed among themselves, at least one ring not being a six-membered ring',
  },
  {
    c: 'A01N 47',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing organic compounds containing a carbon atom not being member of a ring and having no bond to a carbon or hydrogen atom, e.g. derivatives of carbonic acid',
  },
  {
    c: 'A01N 49',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing compounds containing the group wherein m+n≥1, both X together may also mean —Y— or a direct carbon-to-carbon bond, and the carbon atoms marked with an asterisk are not part of any ring system other than that which may be formed by the atoms X, the carbon atoms in square brackets being part of any acyclic or cyclic structure, or the group wherein A means a carbon atom or Y, n ≥ 0, and not more than one of these carbon atoms being a member of the same ring system, e.g. juvenile insect hormones or mimics thereof',
  },
  {
    c: 'A01N 51',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing organic compounds having the sequences of atoms O—N—S, X—O—S, N—N—S, O—N—N or O-halogen, regardless of the number of bonds each atom has and with no atom of these sequences forming part of a heterocyclic ring',
  },
  {
    c: 'A01N 53',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing cyclopropane carboxylic acids or derivatives thereof',
  },
  {
    c: 'A01N 55',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing organic compounds containing elements other than carbon, hydrogen, halogen, oxygen, nitrogen and sulfur',
  },
  {
    c: 'A01N 57',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing organic phosphorus compounds',
  },
  {
    c: 'A01N 59',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing elements or inorganic compounds',
  },
  {
    c: 'A01N 61',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing substances of unknown or undetermined composition, e.g. substances characterised only by the mode of action',
  },
  {
    c: 'A01N 63',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing microorganisms, viruses, microbial fungi, animals, e.g. nematodes, or substances produced by, or obtained from microorganisms, viruses, microbial fungi or animals, e.g. enzymes or fermentates',
  },
  {
    c: 'A01N 65',
    d: 'Biocides, pest repellants or attractants, or plant growth regulators containing material from algae, lichens, bryophyta, multi-cellular fungi or plants, or extracts thereof',
  },
  {
    c: 'A01P',
    d: 'BIOCIDAL, PEST REPELLANT, PEST ATTRACTANT OR PLANT GROWTH REGULATORY ACTIVITY OF CHEMICAL COMPOUNDS OR PREPARATIONS',
  },
  {
    c: 'A01P 1',
    d: 'Disinfectants; Antimicrobial compounds or mixtures thereof',
  },
  {
    c: 'A01P 3',
    d: 'Fungicides',
  },
  {
    c: 'A01P 5',
    d: 'Nematocides',
  },
  {
    c: 'A01P 7',
    d: 'Arthropodicides',
  },
  {
    c: 'A01P 9',
    d: 'Molluscicides',
  },
  {
    c: 'A01P 11',
    d: 'Rodenticides',
  },
  {
    c: 'A01P 13',
    d: 'Herbicides; Algicides',
  },
  {
    c: 'A01P 15',
    d: 'Biocides for specific purposes not provided for in groups A01P0001000000-A01P0013000000',
  },
  {
    c: 'A01P 17',
    d: 'Pest repellants',
  },
  {
    c: 'A01P 19',
    d: 'Pest attractants',
  },
  {
    c: 'A01P 21',
    d: 'Plant growth regulators',
  },
  {
    c: 'A01P 23',
    d: 'Chemosterilants',
  },
  {
    c: 'A21',
    d: 'BAKING; EQUIPMENT FOR MAKING OR PROCESSING DOUGHS; DOUGHS FOR BAKING',
  },
  {
    c: 'A21B',
    d: "BAKERS' OVENS; MACHINES OR EQUIPMENT FOR BAKING",
  },
  {
    c: 'A21B 1',
    d: "Bakers' ovens",
  },
  {
    c: 'A21B 2',
    d: 'Baking apparatus employing high-frequency or infra-red heating',
  },
  {
    c: 'A21B 3',
    d: 'Parts or accessories of ovens',
  },
  {
    c: 'A21B 5',
    d: 'Baking apparatus for special goods; Other baking apparatus',
  },
  {
    c: 'A21B 7',
    d: 'Baking plants',
  },
  {
    c: 'A21C',
    d: 'MACHINES OR EQUIPMENT FOR MAKING OR PROCESSING DOUGHS; HANDLING BAKED ARTICLES MADE FROM DOUGH',
  },
  {
    c: 'A21C 1',
    d: 'Mixing or kneading machines for the preparation of dough',
  },
  {
    c: 'A21C 3',
    d: 'Machines or apparatus for shaping batches of dough before subdivision',
  },
  {
    c: 'A21C 5',
    d: 'Dough-dividing machines',
  },
  {
    c: 'A21C 7',
    d: 'Machines which homogenise the subdivided dough by working other than by kneading',
  },
  {
    c: 'A21C 9',
    d: 'Other apparatus for handling dough or dough pieces',
  },
  {
    c: 'A21C 11',
    d: 'Other machines for forming the dough into its final shape before cooking or baking',
  },
  {
    c: 'A21C 13',
    d: 'Provers, i.e. apparatus permitting dough to rise',
  },
  {
    c: 'A21C 14',
    d: 'Machines or equipment for making or processing dough, not provided for in other groups of this subclass',
  },
  {
    c: 'A21C 15',
    d: 'Apparatus for handling baked articles',
  },
  {
    c: 'A21D',
    d: 'TREATMENT, e.g. PRESERVATION, OF FLOUR OR DOUGH FOR BAKING, e.g. BY ADDITION OF MATERIALS; BAKING; BAKERY PRODUCTS; PRESERVATION THEREOF',
  },
  {
    c: 'A21D 2',
    d: 'Treatment of flour or dough by adding materials thereto before or during baking',
  },
  {
    c: 'A21D 4',
    d: 'Preserving flour or dough before baking by storage in an inert atmosphere',
  },
  {
    c: 'A21D 6',
    d: 'Other treatment of flour or dough before baking, e.g. cooling, irradiating, heating',
  },
  {
    c: 'A21D 8',
    d: 'Methods for preparing or baking dough',
  },
  {
    c: 'A21D 10',
    d: 'Batters, dough or mixtures before baking',
  },
  {
    c: 'A21D 13',
    d: 'Finished or partly finished bakery products',
  },
  {
    c: 'A21D 15',
    d: 'Preserving finished bakery products; Improving',
  },
  {
    c: 'A21D 17',
    d: 'Refreshing bakery products',
  },
  {
    c: 'A22',
    d: 'BUTCHERING; MEAT TREATMENT; PROCESSING POULTRY OR FISH',
  },
  {
    c: 'A22B',
    d: 'SLAUGHTERING',
  },
  {
    c: 'A22B 1',
    d: 'Apparatus for fettering animals to be slaughtered',
  },
  {
    c: 'A22B 3',
    d: 'Slaughtering or stunning',
  },
  {
    c: 'A22B 5',
    d: 'Accessories for use during or after slaughtering',
  },
  {
    c: 'A22B 7',
    d: 'Slaughterhouse arrangements',
  },
  {
    c: 'A22C',
    d: 'PROCESSING MEAT, POULTRY, OR FISH',
  },
  {
    c: 'A22C 5',
    d: 'Processing meat',
  },
  {
    c: 'A22C 7',
    d: 'Apparatus for pounding, forming, or pressing meat, sausage-meat, or meat products',
  },
  {
    c: 'A22C 9',
    d: 'Apparatus for tenderising meat, e.g. ham',
  },
  {
    c: 'A22C 11',
    d: 'Sausage-making',
  },
  {
    c: 'A22C 13',
    d: 'Sausage casings',
  },
  {
    c: 'A22C 15',
    d: 'Apparatus for hanging-up meat or sausages',
  },
  {
    c: 'A22C 17',
    d: 'Other devices for processing meat or bones',
  },
  {
    c: 'A22C 18',
    d: 'Plants, factories, or the like for processing meat',
  },
  {
    c: 'A22C 21',
    d: 'Processing poultry',
  },
  {
    c: 'A22C 25',
    d: 'Processing fish, including shellfish',
  },
  {
    c: 'A22C 29',
    d: 'Processing shellfish, e.g. oysters, lobsters',
  },
  {
    c: 'A23',
    d: 'FOODS OR FOODSTUFFS; THEIR TREATMENT, NOT COVERED BY OTHER CLASSES',
  },
  {
    c: 'A23B',
    d: 'PRESERVING, e.g. BY CANNING, MEAT, FISH, EGGS, FRUIT, VEGETABLES, EDIBLE SEEDS; CHEMICAL RIPENING OF FRUIT OR VEGETABLES; THE PRESERVED, RIPENED, OR CANNED PRODUCTS',
  },
  {
    c: 'A23B 4',
    d: 'General methods for preserving meat, sausages, fish or fish products',
  },
  {
    c: 'A23B 5',
    d: 'Preservation of eggs or egg products',
  },
  {
    c: 'A23B 7',
    d: 'Preservation or chemical ripening of fruit or vegetables',
  },
  {
    c: 'A23B 9',
    d: 'Preservation of edible seeds, e.g. cereals',
  },
  {
    c: 'A23C',
    d: 'DAIRY PRODUCTS, e.g. MILK, BUTTER, CHEESE; MILK OR CHEESE SUBSTITUTES; MAKING THEREOF',
  },
  {
    c: 'A23C 1',
    d: 'General dairy technology',
  },
  {
    c: 'A23C 3',
    d: 'Preservation of milk or milk preparations',
  },
  {
    c: 'A23C 7',
    d: 'Other dairy technology',
  },
  {
    c: 'A23C 9',
    d: 'Dairy products; Processes specially adapted therefor',
  },
  {
    c: 'A23C 11',
    d: 'Milk substitutes, e.g. coffee whitener compositions',
  },
  {
    c: 'A23C 13',
    d: 'Cream; Cream preparations',
  },
  {
    c: 'A23C 15',
    d: 'Butter; Butter preparations; Making thereof',
  },
  {
    c: 'A23C 17',
    d: 'Buttermilk; Buttermilk preparations',
  },
  {
    c: 'A23C 19',
    d: 'Cheese; Cheese preparations; Making thereof',
  },
  {
    c: 'A23C 20',
    d: 'Cheese substitutes',
  },
  {
    c: 'A23C 21',
    d: 'Whey; Whey preparations',
  },
  {
    c: 'A23C 23',
    d: 'Other dairy products',
  },
  {
    c: 'A23D',
    d: 'EDIBLE OILS OR FATS, e.g. MARGARINES, SHORTENINGS, COOKING OILS',
  },
  {
    c: 'A23D 7',
    d: 'Edible oil or fat compositions containing an aqueous phase, e.g. margarines',
  },
  {
    c: 'A23D 9',
    d: 'Other edible oils or fats, e.g. shortenings, cooking oils',
  },
  {
    c: 'A23F',
    d: 'COFFEE; TEA; THEIR SUBSTITUTES; MANUFACTURE, PREPARATION, OR INFUSION THEREOF',
  },
  {
    c: 'A23F 3',
    d: 'Tea; Tea substitutes; Preparations thereof',
  },
  {
    c: 'A23F 5',
    d: 'Coffee; Coffee substitutes; Preparations thereof',
  },
  {
    c: 'A23G',
    d: 'COCOA; COCOA PRODUCTS, e.g. CHOCOLATE; SUBSTITUTES FOR COCOA OR COCOA PRODUCTS; CONFECTIONERY; CHEWING GUM; ICE-CREAM; PREPARATION THEREOF',
  },
  {
    c: 'A23G 1',
    d: 'Cocoa; Cocoa products, e.g. chocolate; Substitutes therefor',
  },
  {
    c: 'A23G 3',
    d: 'Sweetmeats; Confectionery; Marzipan; Coated or filled products',
  },
  {
    c: 'A23G 4',
    d: 'Chewing gum',
  },
  {
    c: 'A23G 7',
    d: 'Other apparatus specially adapted for the chocolate or confectionery industry',
  },
  {
    c: 'A23G 9',
    d: 'Frozen sweets, e.g. ice confectionery, ice-cream; Mixtures therefor',
  },
  {
    c: 'A23J',
    d: 'PROTEIN COMPOSITIONS FOR FOODSTUFFS; WORKING-UP PROTEINS FOR FOODSTUFFS; PHOSPHATIDE COMPOSITIONS FOR FOODSTUFFS',
  },
  {
    c: 'A23J 1',
    d: 'Obtaining protein compositions for foodstuffs; Bulk opening of eggs and separation of yolks from whites',
  },
  {
    c: 'A23J 3',
    d: 'Working-up of proteins for foodstuffs',
  },
  {
    c: 'A23J 7',
    d: 'Phosphatide compositions for foodstuffs, e.g. lecithin',
  },
  {
    c: 'A23K',
    d: 'FEEDING-STUFFS SPECIALLY ADAPTED FOR ANIMALS; METHODS SPECIALLY ADAPTED FOR PRODUCTION THEREOF',
  },
  {
    c: 'A23K 10',
    d: 'Animal feeding-stuffs',
  },
  {
    c: 'A23K 20',
    d: 'Accessory food factors for animal feeding-stuffs',
  },
  {
    c: 'A23K 30',
    d: 'Processes specially adapted for preservation of materials in order to produce animal feeding-stuffs',
  },
  {
    c: 'A23K 40',
    d: 'Shaping or working-up of animal feeding-stuffs',
  },
  {
    c: 'A23K 50',
    d: 'Feeding-stuffs specially adapted for particular animals',
  },
  {
    c: 'A23L',
    d: 'FOODS, FOODSTUFFS, OR NON-ALCOHOLIC BEVERAGES, NOT COVERED BY SUBCLASSES A21D OR A23B-A23J; THEIR PREPARATION OR TREATMENT, e.g. COOKING, MODIFICATION OF NUTRITIVE QUALITIES, PHYSICAL TREATMENT',
  },
  {
    c: 'A23L 2',
    d: 'Non-alcoholic beverages; Dry compositions or concentrates therefor; Their preparation',
  },
  {
    c: 'A23L 3',
    d: 'Preservation of foods or foodstuffs, in general, e.g. pasteurising, sterilising, specially adapted for foods or foodstuffs',
  },
  {
    c: 'A23L 5',
    d: 'Preparation or treatment of foods or foodstuffs, in general; Food or foodstuffs obtained thereby; Materials therefor',
  },
  {
    c: 'A23L 7',
    d: 'Cereal-derived products; Malt products',
  },
  {
    c: 'A23L 9',
    d: 'Puddings; Cream substitutes; Preparation or treatment thereof',
  },
  {
    c: 'A23L 11',
    d: 'Pulses, i.e. fruits of leguminous plants, for production of fodder or food; Products from legumes; Preparation or treatment thereof, e.g. treatment with phosphates',
  },
  {
    c: 'A23L 13',
    d: 'Meat products; Meat meal; Preparation or treatment thereof',
  },
  {
    c: 'A23L 15',
    d: 'Egg products; Preparation or treatment thereof',
  },
  {
    c: 'A23L 17',
    d: 'Food-from-the-sea products; Fish products; Fish meal; Fish-egg substitutes; Preparation or treatment thereof',
  },
  {
    c: 'A23L 19',
    d: 'Products from fruits or vegetables; Preparation or treatment thereof',
  },
  {
    c: 'A23L 21',
    d: 'Marmalades, jams, jellies or the like; Products from apiculture; Preparation or treatment thereof',
  },
  {
    c: 'A23L 23',
    d: 'Soups; Sauces',
  },
  {
    c: 'A23L 25',
    d: 'Food consisting mainly of nutmeat or seeds; Preparation or treatment thereof',
  },
  {
    c: 'A23L 27',
    d: 'Spices; Flavouring agents or condiments; Artificial sweetening agents; Table salts; Dietetic salt substitutes; Preparation or treatment thereof',
  },
  {
    c: 'A23L 29',
    d: 'Foods or foodstuffs containing additives',
  },
  {
    c: 'A23L 31',
    d: 'Edible extracts or preparations of fungi; Preparation or treatment thereof',
  },
  {
    c: 'A23L 33',
    d: 'Modifying nutritive qualities of foods; Dietetic products; Preparation or treatment thereof',
  },
  {
    c: 'A23L 35',
    d: 'Food or foodstuffs not provided for in groups A23L0005000000-A23L0033000000; Preparation or treatment thereof',
  },
  {
    c: 'A23N',
    d: 'MACHINES OR APPARATUS FOR TREATING HARVESTED FRUIT, VEGETABLES, OR FLOWER BULBS IN BULK, NOT OTHERWISE PROVIDED FOR; PEELING VEGETABLES OR FRUIT IN BULK; APPARATUS FOR PREPARING ANIMAL FEEDING-STUFFS',
  },
  {
    c: 'A23N 1',
    d: 'Machines or apparatus for extracting juice',
  },
  {
    c: 'A23N 3',
    d: 'Machines for coring or stoning fruit, characterised by their feeding device',
  },
  {
    c: 'A23N 4',
    d: 'Machines for stoning fruit or removing seed-containing sections from fruit, characterised by their stoning or removing device',
  },
  {
    c: 'A23N 5',
    d: 'Machines for hulling, husking, or cracking nuts',
  },
  {
    c: 'A23N 7',
    d: 'Peeling vegetables or fruit',
  },
  {
    c: 'A23N 11',
    d: 'Removing pith from stems',
  },
  {
    c: 'A23N 12',
    d: 'Machines for cleaning, blanching, drying or roasting fruits or vegetables, e.g. coffee, cocoa, nuts',
  },
  {
    c: 'A23N 15',
    d: 'Machines or apparatus for other treatment of fruits or vegetables for human purposes; Machines or apparatus for topping or skinning flower bulbs',
  },
  {
    c: 'A23N 17',
    d: 'Apparatus specially adapted for preparing animal feeding-stuffs',
  },
  {
    c: 'A23P',
    d: 'SHAPING OR WORKING OF FOODSTUFFS, NOT FULLY COVERED BY A SINGLE OTHER SUBCLASS',
  },
  {
    c: 'A23P 10',
    d: 'Shaping or working of foodstuffs characterised by the products',
  },
  {
    c: 'A23P 20',
    d: 'Coating of foodstuffs; Coatings therefor; Making laminated, multi-layered, stuffed or hollow foodstuffs',
  },
  {
    c: 'A23P 30',
    d: 'Shaping or working of foodstuffs characterised by the process or apparatus',
  },
  {
    c: 'A24',
    d: "TOBACCO; CIGARS; CIGARETTES; SMOKERS' REQUISITES",
  },
  {
    c: 'A24B',
    d: 'MANUFACTURE OR PREPARATION OF TOBACCO FOR SMOKING OR CHEWING; TOBACCO; SNUFF',
  },
  {
    c: 'A24B 1',
    d: 'Preparation of tobacco on the plantation',
  },
  {
    c: 'A24B 3',
    d: 'Preparing tobacco in the factory',
  },
  {
    c: 'A24B 5',
    d: 'Stripping tobacco; Treatment of stems or ribs',
  },
  {
    c: 'A24B 7',
    d: 'Cutting tobacco',
  },
  {
    c: 'A24B 9',
    d: 'Control of the moisture content of tobacco products, e.g. cigars, cigarettes, pipe tobacco',
  },
  {
    c: 'A24B 11',
    d: 'Tobacco-twisting machines',
  },
  {
    c: 'A24B 13',
    d: 'Tobacco for pipes, for cigars, e.g. cigar inserts, or for cigarettes; Chewing tobacco; Snuff',
  },
  {
    c: 'A24B 15',
    d: 'Chemical features or treatment of tobacco; Tobacco substitutes',
  },
  {
    c: 'A24C',
    d: 'MACHINES FOR MAKING CIGARS OR CIGARETTES',
  },
  {
    c: 'A24C 1',
    d: 'Making cigars',
  },
  {
    c: 'A24C 3',
    d: 'Complete manufacture of cigars; Combinations of two or more elements of cigar manufacture',
  },
  {
    c: 'A24C 5',
    d: 'Making cigarettes; Making tipping materials for, or attaching filters or mouthpieces to, cigars or cigarettes',
  },
  {
    c: 'A24D',
    d: 'CIGARS; CIGARETTES; TOBACCO SMOKE FILTERS; MOUTHPIECES FOR CIGARS OR CIGARETTES; MANUFACTURE OF TOBACCO SMOKE FILTERS OR MOUTHPIECES',
  },
  {
    c: 'A24D 1',
    d: 'Cigars; Cigarettes',
  },
  {
    c: 'A24D 3',
    d: 'Tobacco smoke filters, e.g. filter-tips, filtering inserts',
  },
  {
    c: 'A24F',
    d: "SMOKERS' REQUISITES; MATCH BOXES",
  },
  {
    c: 'A24F 1',
    d: 'Tobacco pipes',
  },
  {
    c: 'A24F 3',
    d: 'Tobacco pipes combined with other objects',
  },
  {
    c: 'A24F 5',
    d: 'Component parts or accessories for pipes; Mouthpieces',
  },
  {
    c: 'A24F 7',
    d: 'Mouthpieces for pipes; Mouthpieces for cigar or cigarette holders',
  },
  {
    c: 'A24F 9',
    d: "Accessories for smokers' pipes",
  },
  {
    c: 'A24F 11',
    d: 'Seasoning of tobacco pipes',
  },
  {
    c: 'A24F 13',
    d: 'Appliances for smoking cigars or cigarettes',
  },
  {
    c: 'A24F 15',
    d: 'Cigar or cigarette receptacles or boxes',
  },
  {
    c: 'A24F 17',
    d: 'Receptacles for cigarette papers',
  },
  {
    c: 'A24F 19',
    d: "Other smokers' requisites",
  },
  {
    c: 'A24F 21',
    d: "Stands for smokers' requisites",
  },
  {
    c: 'A24F 23',
    d: 'Cases for tobacco, snuff, or chewing tobacco',
  },
  {
    c: 'A24F 25',
    d: 'Devices used by the smoker for controlling the moisture content of, or for scenting, cigars, cigarettes or tobacco',
  },
  {
    c: 'A24F 27',
    d: 'Match receptacles or boxes',
  },
  {
    c: 'A24F 29',
    d: 'Devices for igniting matches; Holders for ignited matches',
  },
  {
    c: 'A24F 31',
    d: 'Pipe-spills; Devices for splitting matches',
  },
  {
    c: 'A24F 47',
    d: "Smokers' requisites not provided for elsewhere",
  },
  {
    c: 'A41',
    d: 'WEARING APPAREL',
  },
  {
    c: 'A41B',
    d: 'SHIRTS; UNDERWEAR; BABY LINEN; HANDKERCHIEFS',
  },
  {
    c: 'A41B 1',
    d: 'Shirts',
  },
  {
    c: 'A41B 3',
    d: 'Collars',
  },
  {
    c: 'A41B 5',
    d: 'Fold-line formings for collars or cuffs',
  },
  {
    c: 'A41B 7',
    d: 'Cuffs',
  },
  {
    c: 'A41B 9',
    d: 'Undergarments',
  },
  {
    c: 'A41B 11',
    d: 'Hosiery; Panti-hose',
  },
  {
    c: 'A41B 13',
    d: 'Baby linen',
  },
  {
    c: 'A41B 15',
    d: 'Handkerchiefs',
  },
  {
    c: 'A41B 17',
    d: 'Selection of special materials for underwear',
  },
  {
    c: 'A41C',
    d: 'CORSETS; BRASSIÈRES',
  },
  {
    c: 'A41C 1',
    d: 'Corsets or girdles',
  },
  {
    c: 'A41C 3',
    d: 'Brassières',
  },
  {
    c: 'A41C 5',
    d: 'Machines, appliances, or methods for manufacturing corsets or brassières',
  },
  {
    c: 'A41D',
    d: 'OUTERWEAR; PROTECTIVE GARMENTS; ACCESSORIES',
  },
  {
    c: 'A41D 1',
    d: 'Garments',
  },
  {
    c: 'A41D 3',
    d: 'Overgarments',
  },
  {
    c: 'A41D 5',
    d: 'Fur garments; Garments of fur substitutes',
  },
  {
    c: 'A41D 7',
    d: 'Bathing gowns; Swim-suits, drawers, or trunks; Beach suits',
  },
  {
    c: 'A41D 10',
    d: 'Pyjamas; Nightdresses',
  },
  {
    c: 'A41D 11',
    d: 'Garments for children',
  },
  {
    c: 'A41D 13',
    d: "Professional, industrial or sporting protective garments, e.g. surgeons' gowns or garments protecting against blows or punches",
  },
  {
    c: 'A41D 15',
    d: 'Convertible garments',
  },
  {
    c: 'A41D 17',
    d: 'Gaiters; Spats',
  },
  {
    c: 'A41D 19',
    d: 'Gloves',
  },
  {
    c: 'A41D 20',
    d: 'Wristbands or headbands, e.g. for absorbing sweat',
  },
  {
    c: 'A41D 23',
    d: 'Scarves; Head-scarves; Neckerchiefs',
  },
  {
    c: 'A41D 25',
    d: 'Neckties',
  },
  {
    c: 'A41D 27',
    d: 'Details of garments or of their making',
  },
  {
    c: 'A41D 29',
    d: 'Uniforms; Parts or accessories of uniforms',
  },
  {
    c: 'A41D 31',
    d: 'Materials specially adapted for outerwear',
  },
  {
    c: 'A41F',
    d: 'GARMENT FASTENINGS; SUSPENDERS',
  },
  {
    c: 'A41F 1',
    d: 'Fastening devices specially adapted for garments',
  },
  {
    c: 'A41F 3',
    d: 'Garment suspenders',
  },
  {
    c: 'A41F 5',
    d: 'Trouser supports attached to the shirt, waistcoat, or the like',
  },
  {
    c: 'A41F 7',
    d: 'Devices for connecting underpants to trousers',
  },
  {
    c: 'A41F 9',
    d: 'Belts, girdles, or waistbands for trousers or skirts',
  },
  {
    c: 'A41F 11',
    d: 'Stocking or sock suspenders',
  },
  {
    c: 'A41F 13',
    d: 'Other devices for supporting or holding stockings or socks during wear',
  },
  {
    c: 'A41F 15',
    d: 'Shoulder or like straps',
  },
  {
    c: 'A41F 17',
    d: 'Means for holding-down garments',
  },
  {
    c: 'A41F 18',
    d: 'Garment suspenders covered by two or more of groups A41F0003000000-A41F0017000000',
  },
  {
    c: 'A41F 19',
    d: 'Garment suspenders not otherwise provided for',
  },
  {
    c: 'A41G',
    d: 'ARTIFICIAL FLOWERS; WIGS; MASKS; FEATHERS',
  },
  {
    c: 'A41G 1',
    d: 'Artificial flowers, fruit, leaves, or trees',
  },
  {
    c: 'A41G 3',
    d: 'Wigs',
  },
  {
    c: 'A41G 5',
    d: 'Hair pieces, inserts, rolls, pads, or the like; Toupées',
  },
  {
    c: 'A41G 7',
    d: 'Masks or dominoes for concealing identity, e.g. for theatrical use',
  },
  {
    c: 'A41G 9',
    d: 'Adornments of natural feathers; Working natural feathers',
  },
  {
    c: 'A41G 11',
    d: 'Artificial feathers',
  },
  {
    c: 'A41H',
    d: 'APPLIANCES OR METHODS FOR MAKING CLOTHES, e.g. FOR DRESS-MAKING, FOR TAILORING, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'A41H 1',
    d: 'Measuring aids or methods',
  },
  {
    c: 'A41H 3',
    d: 'Patterns for cutting-out; Methods of drafting or marking-out such patterns, e.g. on the cloth',
  },
  {
    c: 'A41H 5',
    d: 'Dress forms; Bust forms; Stands',
  },
  {
    c: 'A41H 9',
    d: 'Devices or methods for trimming, levelling, or straightening the hems of garments',
  },
  {
    c: 'A41H 11',
    d: 'Tracing-wheels',
  },
  {
    c: 'A41H 15',
    d: 'Cloth-holders',
  },
  {
    c: 'A41H 17',
    d: 'Cushions for needles or pins',
  },
  {
    c: 'A41H 19',
    d: 'Boxes for needles or pins',
  },
  {
    c: 'A41H 21',
    d: 'Supports for the body whilst trying-on or fitting',
  },
  {
    c: 'A41H 23',
    d: 'Devices for applying chalk; Sharpening or holding chalk',
  },
  {
    c: 'A41H 25',
    d: 'Appliances or methods for marking-out, perforating, or making buttonholes',
  },
  {
    c: 'A41H 27',
    d: 'Mending garments by adhesives or adhesive patches',
  },
  {
    c: 'A41H 31',
    d: 'Other aids for tailors',
  },
  {
    c: 'A41H 33',
    d: 'Machines or appliances for folding the edges of collars, cuffs, or the like while manufacturing',
  },
  {
    c: 'A41H 37',
    d: 'Machines, appliances or methods for setting fastener-elements on garments',
  },
  {
    c: 'A41H 41',
    d: 'Machines or appliances for making garments from natural or artificial fur',
  },
  {
    c: 'A41H 42',
    d: 'Multi-step production lines for making clothes',
  },
  {
    c: 'A41H 43',
    d: 'Other methods, machines, or appliances',
  },
  {
    c: 'A42',
    d: 'HEADWEAR',
  },
  {
    c: 'A42B',
    d: 'HATS; HEAD COVERINGS',
  },
  {
    c: 'A42B 1',
    d: 'Hats; Caps; Hoods',
  },
  {
    c: 'A42B 3',
    d: 'Helmets; Helmet covers',
  },
  {
    c: 'A42B 5',
    d: 'Veils; Holders for veils',
  },
  {
    c: 'A42B 7',
    d: "Fastening means for head coverings; Elastic cords; Ladies' hat fasteners",
  },
  {
    c: 'A42C',
    d: 'MANUFACTURING OR TRIMMING HATS OR OTHER HEAD COVERINGS',
  },
  {
    c: 'A42C 1',
    d: 'Manufacturing hats',
  },
  {
    c: 'A42C 2',
    d: 'Manufacturing helmets by processes not otherwise provided for',
  },
  {
    c: 'A42C 3',
    d: 'Appliances for hat-making not provided for in group A42C0001000000, e.g. for making wire forms for hat-frames',
  },
  {
    c: 'A42C 5',
    d: 'Fittings or trimmings for hats, e.g. hat-bands',
  },
  {
    c: 'A42C 99',
    d: 'n.a.',
  },
  {
    c: 'A43',
    d: 'FOOTWEAR',
  },
  {
    c: 'A43B',
    d: 'CHARACTERISTIC FEATURES OF FOOTWEAR; PARTS OF FOOTWEAR',
  },
  {
    c: 'A43B 1',
    d: 'Characteristic features of footwear',
  },
  {
    c: 'A43B 3',
    d: 'Footwear characterised by the shape or the use',
  },
  {
    c: 'A43B 5',
    d: 'Footwear for sporting purposes',
  },
  {
    c: 'A43B 7',
    d: 'Footwear with health or hygienic arrangements',
  },
  {
    c: 'A43B 9',
    d: 'Footwear characterised by the assembling of the individual parts',
  },
  {
    c: 'A43B 11',
    d: 'Footwear with arrangements to facilitate putting-on or removing, e.g. with straps',
  },
  {
    c: 'A43B 13',
    d: 'Parts of footwear',
  },
  {
    c: 'A43B 15',
    d: 'Welts for footwear',
  },
  {
    c: 'A43B 17',
    d: 'Insoles for insertion, e.g. footbeds or inlays, for attachment to the shoe after the upper has been joined',
  },
  {
    c: 'A43B 19',
    d: 'Shoe-shaped inserts; Inserts covering the instep',
  },
  {
    c: 'A43B 21',
    d: 'Heels; Top-pieces',
  },
  {
    c: 'A43B 23',
    d: 'Uppers; Boot legs; Stiffeners; Other single parts of footwear',
  },
  {
    c: 'A43C',
    d: 'FASTENINGS OR ATTACHMENTS FOR FOOTWEAR; LACES IN GENERAL',
  },
  {
    c: 'A43C 1',
    d: 'Shoe lacing fastenings',
  },
  {
    c: 'A43C 3',
    d: 'Hooks for laces',
  },
  {
    c: 'A43C 5',
    d: 'Eyelets',
  },
  {
    c: 'A43C 7',
    d: 'Holding-devices for laces',
  },
  {
    c: 'A43C 9',
    d: 'Laces; Laces in general for garments made of textiles, leather, or plastics',
  },
  {
    c: 'A43C 11',
    d: 'Other fastenings specially adapted for shoes',
  },
  {
    c: 'A43C 13',
    d: 'Wear-resisting attachments',
  },
  {
    c: 'A43C 15',
    d: 'Non-skid devices or attachments',
  },
  {
    c: 'A43C 17',
    d: 'Spurs',
  },
  {
    c: 'A43C 19',
    d: 'Attachments for footwear, not provided for in other groups of this subclass',
  },
  {
    c: 'A43D',
    d: 'MACHINES, TOOLS, EQUIPMENT OR METHODS FOR MANUFACTURING OR REPAIRING FOOTWEAR',
  },
  {
    c: 'A43D 1',
    d: 'Measuring devices; Lasts; Tools',
  },
  {
    c: 'A43D 3',
    d: 'Lasts',
  },
  {
    c: 'A43D 5',
    d: 'Hand appliances or hand tools for making or repairing shoes, other than those covered by groups A43D0015000000, A43D0019000000, A43D0095000000, A43D0100000000, A43D0117000000',
  },
  {
    c: 'A43D 8',
    d: 'Machines for cutting, ornamenting, marking or otherwise working up shoe part blanks',
  },
  {
    c: 'A43D 9',
    d: 'Pulling-over or lasting',
  },
  {
    c: 'A43D 11',
    d: 'Machines for preliminary treatment or assembling of upper-parts, counters, or insoles on their lasts preparatory to the pulling-over or lasting operations; Applying or removing protective coverings',
  },
  {
    c: 'A43D 13',
    d: 'Machines for pulling-over the uppers when loosely laid upon the last and tacking the toe end',
  },
  {
    c: 'A43D 15',
    d: 'Pulling-over or lasting machines for binding the toe end with cord, string, or wire; Machines for lasting with clamps; Lasting machines with sewing devices, also for platform shoes',
  },
  {
    c: 'A43D 17',
    d: 'Pulling-over or lasting machines with oscillating shoe supports',
  },
  {
    c: 'A43D 19',
    d: 'Hand lasting; Lasting pincers',
  },
  {
    c: 'A43D 21',
    d: 'Lasting machines',
  },
  {
    c: 'A43D 23',
    d: 'Single parts for pulling-over or lasting machines',
  },
  {
    c: 'A43D 25',
    d: 'Making or fastening soles, heels, or welts, or preparing same for fastening to the shoe; Carrying out other operations subsequent to lasting; Turning',
  },
  {
    c: 'A43D 27',
    d: 'Machines for trimming as an intermediate operation',
  },
  {
    c: 'A43D 29',
    d: 'Machines for making soles from strips of material',
  },
  {
    c: 'A43D 31',
    d: 'Machines for making or inserting shank stiffeners',
  },
  {
    c: 'A43D 33',
    d: 'Machines for assembling lifts for heels',
  },
  {
    c: 'A43D 35',
    d: 'Presses for shaping pre-existing loose soles, shoe bottoms, or soles fixed to shoe bottoms',
  },
  {
    c: 'A43D 37',
    d: 'Machines for roughening soles or other shoe parts preparatory to gluing',
  },
  {
    c: 'A43D 39',
    d: 'Machines for making foot-supporting pads or instep-raisers for flat feet',
  },
  {
    c: 'A43D 43',
    d: 'Machines for making stitch lips, or other preparatory treatment of soles or insoles before fixing same',
  },
  {
    c: 'A43D 44',
    d: 'Machines for attaching welts or rands',
  },
  {
    c: 'A43D 47',
    d: 'Machines for trimming or butting welts fixed on uppers',
  },
  {
    c: 'A43D 49',
    d: 'Machines for pounding',
  },
  {
    c: 'A43D 51',
    d: 'Machines for lip-setting',
  },
  {
    c: 'A43D 53',
    d: 'Machines for trimming-off surplus material along the inseam',
  },
  {
    c: 'A43D 55',
    d: 'Machines for flattening, pressing, or rubbing the inseams of lasted shoes',
  },
  {
    c: 'A43D 57',
    d: 'Machines for attaching the welt ends',
  },
  {
    c: 'A43D 59',
    d: 'Machines for rasping the lasting-margins of shoes which are sewn through',
  },
  {
    c: 'A43D 61',
    d: 'Machines for nail-pulling, nail-cutting, or nail-detecting',
  },
  {
    c: 'A43D 63',
    d: 'Machines for carrying out other finishing operations',
  },
  {
    c: 'A43D 67',
    d: 'Machines for fastening soles or heels by means of screws or screwed wire',
  },
  {
    c: 'A43D 69',
    d: 'Shoe-nailing machines',
  },
  {
    c: 'A43D 71',
    d: 'Elements of nailing machines; Nail-feeding devices',
  },
  {
    c: 'A43D 75',
    d: 'Nailing devices on pulling-over or lasting machines',
  },
  {
    c: 'A43D 79',
    d: 'Combined heel-pressing and nailing machines',
  },
  {
    c: 'A43D 81',
    d: 'Machines for attaching top-lifts',
  },
  {
    c: 'A43D 83',
    d: 'Heel-presses without nailing apparatus; Machines for pressing single lifts or punching holes for nailing',
  },
  {
    c: 'A43D 85',
    d: 'Machines or apparatus for turning, e.g. for making turn-shoes',
  },
  {
    c: 'A43D 86',
    d: 'Machines for assembling soles or heels onto uppers, not provided for in groups A43D0025000000-A43D0083000000, e.g. by welding',
  },
  {
    c: 'A43D 87',
    d: 'Performing finishing operations upon uppers, soles, or heels on soled shoes; Making shoe appurtenances',
  },
  {
    c: 'A43D 89',
    d: 'Sole-levelling machines with rolls',
  },
  {
    c: 'A43D 91',
    d: 'Stitch-separating or seam-indenting machines',
  },
  {
    c: 'A43D 93',
    d: 'Edge-indenting machines',
  },
  {
    c: 'A43D 95',
    d: 'Shoe-finishing machines',
  },
  {
    c: 'A43D 97',
    d: 'Machines for making pulling-on pieces',
  },
  {
    c: 'A43D 98',
    d: 'Machines for making laces',
  },
  {
    c: 'A43D 100',
    d: 'Setting or removing eyelets, buttons, lacing-hooks, or elastic gussets in shoes',
  },
  {
    c: 'A43D 111',
    d: 'Other mechanisms for shoemaking or repairing',
  },
  {
    c: 'A43D 113',
    d: 'Machines for making shoes with out-turned flanges of the uppers or for making moccasins',
  },
  {
    c: 'A43D 115',
    d: 'Machines for skiving or removing heel lifts, heels, or soles, or for removing stitches, preparatory to repair',
  },
  {
    c: 'A43D 117',
    d: 'Racks for receiving or transporting shoes or shoe parts; Other conveying means',
  },
  {
    c: 'A43D 119',
    d: 'Driving or controlling mechanisms of shoe machines; Frames for shoe machines',
  },
  {
    c: 'A43D 999',
    d: 'n.a.',
  },
  {
    c: 'A44',
    d: 'HABERDASHERY; JEWELLERY',
  },
  {
    c: 'A44B',
    d: 'BUTTONS, PINS, BUCKLES, SLIDE FASTENERS, OR THE LIKE',
  },
  {
    c: 'A44B 1',
    d: 'Buttons',
  },
  {
    c: 'A44B 3',
    d: 'Collar-studs',
  },
  {
    c: 'A44B 5',
    d: 'Sleeve-links',
  },
  {
    c: 'A44B 6',
    d: 'Retainers or tethers for neckties, cravats, neckerchiefs, or the like, e.g. tie-clips, spring clips with attached tie-tethers, woggles, pins with associated sheathing members tetherable to clothing',
  },
  {
    c: 'A44B 7',
    d: 'Cards for buttons, collar-studs, or sleeve-links',
  },
  {
    c: 'A44B 9',
    d: 'Hat, scarf, or safety pins or the like',
  },
  {
    c: 'A44B 11',
    d: 'Buckles; Similar fasteners for interconnecting straps or the like, e.g. for safety belts',
  },
  {
    c: 'A44B 13',
    d: 'Hook or eye fasteners',
  },
  {
    c: 'A44B 15',
    d: 'Key-rings',
  },
  {
    c: 'A44B 17',
    d: 'Press-button or snap fasteners',
  },
  {
    c: 'A44B 18',
    d: 'Fasteners of the touch-and-close type; Making such fasteners',
  },
  {
    c: 'A44B 19',
    d: 'Slide fasteners',
  },
  {
    c: 'A44B 99',
    d: 'n.a.',
  },
  {
    c: 'A44C',
    d: 'JEWELLERY; BRACELETS; OTHER PERSONAL ADORNMENTS; COINS',
  },
  {
    c: 'A44C 1',
    d: 'Brooches or clips in their decorative or ornamental aspect',
  },
  {
    c: 'A44C 3',
    d: 'Medals; Badges',
  },
  {
    c: 'A44C 5',
    d: 'Bracelets; Wrist-watch straps; Fastenings for bracelets or wrist-watch straps',
  },
  {
    c: 'A44C 7',
    d: 'Ear-rings; Devices for piercing the ear-lobes',
  },
  {
    c: 'A44C 9',
    d: 'Finger-rings',
  },
  {
    c: 'A44C 11',
    d: 'Watch chains; Ornamental chains',
  },
  {
    c: 'A44C 13',
    d: 'Connectible jewellery',
  },
  {
    c: 'A44C 15',
    d: 'Other forms of jewellery',
  },
  {
    c: 'A44C 17',
    d: 'Gems or the like',
  },
  {
    c: 'A44C 19',
    d: 'Devices for preventing pilfering of watches or jewellery',
  },
  {
    c: 'A44C 21',
    d: 'Coins',
  },
  {
    c: 'A44C 23',
    d: 'Rosaries',
  },
  {
    c: 'A44C 25',
    d: 'Fancy ware for personal wear, not provided for in groups A44C0001000000-A44C0019000000 or A44C0023000000, e.g. crosses, crucifixes, charms',
  },
  {
    c: 'A44C 27',
    d: 'Making jewellery or other personal adornments',
  },
  {
    c: 'A45',
    d: 'HAND OR TRAVELLING ARTICLES',
  },
  {
    c: 'A45B',
    d: 'WALKING STICKS',
  },
  {
    c: 'A45B 1',
    d: 'Walking sticks; Sticks for umbrellas',
  },
  {
    c: 'A45B 3',
    d: 'Sticks combined with other objects',
  },
  {
    c: 'A45B 5',
    d: 'Walking sticks or umbrellas convertible into seats; Hunting sticks',
  },
  {
    c: 'A45B 7',
    d: 'Other sticks, e.g. of cranked shape',
  },
  {
    c: 'A45B 9',
    d: 'Details',
  },
  {
    c: 'A45B 11',
    d: 'Umbrellas',
  },
  {
    c: 'A45B 13',
    d: 'Umbrellas made of paper',
  },
  {
    c: 'A45B 15',
    d: 'Umbrellas with detachable covers',
  },
  {
    c: 'A45B 17',
    d: 'Tiltable umbrellas',
  },
  {
    c: 'A45B 19',
    d: 'Special folding or telescoping of umbrellas',
  },
  {
    c: 'A45B 21',
    d: 'Umbrellas convertible into walking sticks',
  },
  {
    c: 'A45B 23',
    d: 'Other umbrellas',
  },
  {
    c: 'A45B 25',
    d: 'Details of umbrellas',
  },
  {
    c: 'A45B 27',
    d: "Ladies' or like fans",
  },
  {
    c: 'A45C',
    d: 'PURSES; LUGGAGE; HAND CARRIED BAGS',
  },
  {
    c: 'A45C 1',
    d: 'Purses; Money-bags; Wallets',
  },
  {
    c: 'A45C 3',
    d: 'Flexible luggage; Hand bags',
  },
  {
    c: 'A45C 5',
    d: 'Rigid or semi-rigid luggage',
  },
  {
    c: 'A45C 7',
    d: 'Collapsible or extensible luggage, bags or the like',
  },
  {
    c: 'A45C 9',
    d: 'Luggage or bags convertible into objects for other use',
  },
  {
    c: 'A45C 11',
    d: 'Receptacles for purposes not provided for in groups A45C0001000000-A45C0009000000',
  },
  {
    c: 'A45C 13',
    d: 'Details; Accessories',
  },
  {
    c: 'A45C 15',
    d: 'Purses, bags, luggage or other receptacles covered by groups A45C0001000000-A45C0011000000, combined with other articles',
  },
  {
    c: 'A45D',
    d: 'HAIRDRESSING OR SHAVING EQUIPMENT; MANICURING OR OTHER COSMETIC TREATMENT',
  },
  {
    c: 'A45D 1',
    d: 'Curling or holding the hair',
  },
  {
    c: 'A45D 2',
    d: 'Hair-curling or hair-waving appliances',
  },
  {
    c: 'A45D 4',
    d: 'Separate devices designed for heating hair curlers or hair-wavers',
  },
  {
    c: 'A45D 6',
    d: 'Details of, or accessories for, hair-curling or hair-waving devices',
  },
  {
    c: 'A45D 7',
    d: 'Processes of waving, straightening or curling hair',
  },
  {
    c: 'A45D 8',
    d: 'Hair-holding devices; Accessories therefor',
  },
  {
    c: 'A45D 19',
    d: 'Devices for cleaning the hair or the scalp, drying the hair or colouring the hair',
  },
  {
    c: 'A45D 20',
    d: 'Hair drying devices; Accessories therefor',
  },
  {
    c: 'A45D 24',
    d: 'Hair combs for care of the hair; Accessories therefor',
  },
  {
    c: 'A45D 26',
    d: 'Hair-singeing apparatus; Apparatus for removing superfluous hair, e.g. tweezers',
  },
  {
    c: 'A45D 27',
    d: 'Shaving accessories',
  },
  {
    c: 'A45D 29',
    d: 'Manicuring or pedicuring',
  },
  {
    c: 'A45D 31',
    d: 'Artificial nails',
  },
  {
    c: 'A45D 33',
    d: 'Containers or accessories specially adapted for handling toilet or cosmetic substances',
  },
  {
    c: 'A45D 34',
    d: 'Containers or accessories specially adapted for handling liquid toilet or cosmetic substances, e.g. perfumes',
  },
  {
    c: 'A45D 37',
    d: 'Sachet pads specially adapted for liquid toilet or cosmetic substances',
  },
  {
    c: 'A45D 40',
    d: 'Casings or accessories specially adapted for storing or handling solid or pasty toilet or cosmetic substances, e.g. shaving soap, lipstick or make-up',
  },
  {
    c: 'A45D 42',
    d: 'Other toilet or cosmetic equipment',
  },
  {
    c: 'A45D 44',
    d: "Other toilet or cosmetic equipment, e.g. for hairdressers' rooms",
  },
  {
    c: 'A45D 97',
    d: 'Hairdressing equipment or cosmetic treatment not provided for in other groups of this subclass',
  },
  {
    c: 'A45F',
    d: 'TRAVELLING OR CAMP EQUIPMENT; SACKS OR PACKS CARRIED ON THE BODY',
  },
  {
    c: 'A45F 3',
    d: 'Travelling or camp articles',
  },
  {
    c: 'A45F 4',
    d: 'Travelling or camp articles which may be converted into articles for other use; Sacks or packs carried on the body and convertible into other articles',
  },
  {
    c: 'A45F 5',
    d: 'Holders or carriers for hand articles; Holders or carriers for use while travelling or camping',
  },
  {
    c: 'A46',
    d: 'BRUSHWARE',
  },
  {
    c: 'A46B',
    d: 'BRUSHES',
  },
  {
    c: 'A46B 1',
    d: 'Brush bodies and bristles moulded as a unit',
  },
  {
    c: 'A46B 3',
    d: 'Brushes characterised by the way in which the bristles are fixed or joined in or on the brush body or carrier',
  },
  {
    c: 'A46B 5',
    d: 'Brush bodies; Handles integral with brushware',
  },
  {
    c: 'A46B 7',
    d: 'Bristle carriers arranged in the brush body',
  },
  {
    c: 'A46B 9',
    d: 'Arrangements of the bristles in the brush body',
  },
  {
    c: 'A46B 11',
    d: 'Brushes with reservoir or other means for applying substances, e.g. paints, pastes, water',
  },
  {
    c: 'A46B 13',
    d: 'Brushes with driven brush bodies',
  },
  {
    c: 'A46B 15',
    d: 'Other brushes; Brushes with additional arrangements',
  },
  {
    c: 'A46B 17',
    d: 'Accessories for brushes',
  },
  {
    c: 'A46D',
    d: 'MANUFACTURE OF BRUSHES',
  },
  {
    c: 'A46D 1',
    d: 'Bristles; Selection of materials for bristles',
  },
  {
    c: 'A46D 3',
    d: 'Preparing brush bodies',
  },
  {
    c: 'A46D 5',
    d: 'Devices for preparing quills for use as carriers for bristles',
  },
  {
    c: 'A46D 7',
    d: 'Pressing devices for making brooms composed of brushwood or the like',
  },
  {
    c: 'A46D 9',
    d: 'Machines for finishing brushes',
  },
  {
    c: 'A46D 99',
    d: 'n.a.',
  },
  {
    c: 'A47',
    d: 'FURNITURE; DOMESTIC ARTICLES OR APPLIANCES; COFFEE MILLS; SPICE MILLS; SUCTION CLEANERS IN GENERAL',
  },
  {
    c: 'A47B',
    d: 'TABLES; DESKS; OFFICE FURNITURE; CABINETS; DRAWERS; GENERAL DETAILS OF FURNITURE',
  },
  {
    c: 'A47B 1',
    d: 'Tables or desks characterised by structural features',
  },
  {
    c: 'A47B 3',
    d: 'Folding or stowable tables',
  },
  {
    c: 'A47B 5',
    d: 'Suspended or hinged panels forming a table; Wall tables',
  },
  {
    c: 'A47B 7',
    d: 'Tables of rigid construction',
  },
  {
    c: 'A47B 9',
    d: 'Tables with tops of variable height',
  },
  {
    c: 'A47B 11',
    d: 'Tables with tops revolvable on vertical spindles',
  },
  {
    c: 'A47B 13',
    d: 'Details of tables or desks',
  },
  {
    c: 'A47B 17',
    d: 'Tables or desks characterised by adaptation for particular purposes',
  },
  {
    c: 'A47B 19',
    d: 'Reading-desks; Lecterns; Pulpits',
  },
  {
    c: 'A47B 21',
    d: 'Tables or desks specially adapted for use at individual computer workstations, e.g. for word processing or other manual data entry; Tables or desks specially adapted for typing; Auxiliary devices for attachment to such tables or desks',
  },
  {
    c: 'A47B 23',
    d: 'Bed-tables',
  },
  {
    c: 'A47B 25',
    d: 'Card tables; Tables for other games',
  },
  {
    c: 'A47B 27',
    d: 'Drawing desks or tables; Carriers for drawing-boards',
  },
  {
    c: 'A47B 29',
    d: 'Sewing-tables',
  },
  {
    c: 'A47B 31',
    d: 'Service or tea tables, trolleys, or wagons',
  },
  {
    c: 'A47B 33',
    d: 'Kitchen or dish-washing tables',
  },
  {
    c: 'A47B 35',
    d: 'Tables combined with ironing-boards, washers, wringers, or the like',
  },
  {
    c: 'A47B 37',
    d: 'Tables adapted for other particular purposes',
  },
  {
    c: 'A47B 39',
    d: 'School benches or desks',
  },
  {
    c: 'A47B 41',
    d: 'School desks or tables',
  },
  {
    c: 'A47B 43',
    d: 'Structural features of cabinets, racks, shelf units or similar furniture; Similar features of built-in cupboards',
  },
  {
    c: 'A47B 45',
    d: 'Cabinets, racks or shelf units, characterised by features enabling enlarging in height, length, or depth',
  },
  {
    c: 'A47B 46',
    d: 'Cabinets, racks or shelf units, having one or more surfaces adapted to be brought into position for use by extending or pivoting',
  },
  {
    c: 'A47B 47',
    d: 'Cabinets, racks or shelf units, characterised by features related to dismountability or building-up from elements',
  },
  {
    c: 'A47B 49',
    d: 'Revolving cabinets or racks; Cabinets or racks with revolving parts',
  },
  {
    c: 'A47B 51',
    d: 'Cabinets with means for moving compartments up and down',
  },
  {
    c: 'A47B 53',
    d: 'Cabinets or racks having several sections one behind the other; Cabinet or rack systems',
  },
  {
    c: 'A47B 55',
    d: 'Cabinets, racks or shelf units, having essential features of rigid construction',
  },
  {
    c: 'A47B 57',
    d: 'Cabinets, racks or shelf units, characterised by features for adjusting shelves or partitions',
  },
  {
    c: 'A47B 61',
    d: 'Special adaptations of cabinets racks, shelf units or similar furniture; Similar features of built-in cupboards',
  },
  {
    c: 'A47B 63',
    d: 'Cabinets, racks or shelf units, specially adapted for storing books, documents, forms, or the like',
  },
  {
    c: 'A47B 65',
    d: 'Book-troughs; Accessories specially adapted for book-storing, e.g. book-ends',
  },
  {
    c: 'A47B 67',
    d: 'Chests; Dressing-tables; Medicine cabinets or the like; Cabinets characterised by the arrangement of drawers',
  },
  {
    c: 'A47B 69',
    d: 'Cocktail cabinets',
  },
  {
    c: 'A47B 71',
    d: 'Cabinets for perishable goods, e.g. meat safes, fly-proof cabinets',
  },
  {
    c: 'A47B 73',
    d: 'Bottle cupboards; Bottle racks',
  },
  {
    c: 'A47B 75',
    d: 'Fruit or potato storage cabinets, racks or trays for domestic use',
  },
  {
    c: 'A47B 77',
    d: 'Kitchen cabinets',
  },
  {
    c: 'A47B 79',
    d: 'Bedside cabinets',
  },
  {
    c: 'A47B 81',
    d: 'Cabinets, racks or shelf units specially adapted for other particular purposes, e.g. for storing guns or skis',
  },
  {
    c: 'A47B 83',
    d: 'Combined or convertible furniture',
  },
  {
    c: 'A47B 85',
    d: 'Furniture convertible into other kinds of furniture',
  },
  {
    c: 'A47B 87',
    d: 'Sectional furniture, i.e. combinations of complete furniture units',
  },
  {
    c: 'A47B 88',
    d: 'Drawers for tables, cabinets or like furniture; Guides for drawers',
  },
  {
    c: 'A47B 91',
    d: 'Feet for furniture in general',
  },
  {
    c: 'A47B 95',
    d: 'Fittings for furniture',
  },
  {
    c: 'A47B 96',
    d: 'Details of cabinets, racks or shelf units not covered by a single one of groups A47B0043000000-A47B0095000000; General details of furniture',
  },
  {
    c: 'A47B 97',
    d: 'Furniture or accessories for furniture, not provided for in other groups of this subclass',
  },
  {
    c: 'A47C',
    d: 'CHAIRS',
  },
  {
    c: 'A47C 1',
    d: 'Chairs; Stools, Benches',
  },
  {
    c: 'A47C 3',
    d: 'Chairs characterised by structural features; Chairs or stools with rotatable or vertically-adjustable seats',
  },
  {
    c: 'A47C 4',
    d: 'Foldable, collapsible or dismountable chairs',
  },
  {
    c: 'A47C 5',
    d: 'Chairs of special materials',
  },
  {
    c: 'A47C 7',
    d: 'Parts, details, or accessories of chairs or stools',
  },
  {
    c: 'A47C 9',
    d: 'Stools for specified purposes',
  },
  {
    c: 'A47C 11',
    d: 'Benches not otherwise provided for',
  },
  {
    c: 'A47C 12',
    d: 'Step-stools',
  },
  {
    c: 'A47C 13',
    d: 'Convertible chairs, stools or benches',
  },
  {
    c: 'A47C 15',
    d: 'Other seating furniture',
  },
  {
    c: 'A47C 16',
    d: 'Rests or supports for feet, legs, arms, back or head',
  },
  {
    c: 'A47C 17',
    d: 'Sofas; Beds',
  },
  {
    c: 'A47C 19',
    d: 'Bedsteads',
  },
  {
    c: 'A47C 20',
    d: 'Head-, foot- or like rests for beds, sofas or the like',
  },
  {
    c: 'A47C 21',
    d: 'Attachments for beds, e.g. sheet holders, bed-cover holders',
  },
  {
    c: 'A47C 23',
    d: 'Spring mattresses with rigid frame or forming part of the bedstead, e.g. box springs; Divan bases; Slatted bed bases',
  },
  {
    c: 'A47C 27',
    d: 'Spring, stuffed or fluid mattresses specially adapted for chairs, beds or sofas',
  },
  {
    c: 'A47C 29',
    d: 'Nets for protection against insects in connection with chairs or beds',
  },
  {
    c: 'A47C 31',
    d: 'Details or accessories for chairs, beds, or the like, not provided for in other groups of this subclass, e.g. upholstery fasteners, mattress protectors, stretching devices for mattress nets',
  },
  {
    c: 'A47D',
    d: 'FURNITURE SPECIALLY ADAPTED FOR CHILDREN',
  },
  {
    c: 'A47D 1',
    d: "Children's chairs",
  },
  {
    c: 'A47D 3',
    d: "Children's tables",
  },
  {
    c: 'A47D 5',
    d: 'Dressing-tables for children',
  },
  {
    c: 'A47D 7',
    d: "Children's beds",
  },
  {
    c: 'A47D 9',
    d: 'Cradles',
  },
  {
    c: 'A47D 11',
    d: "Children's furniture convertible into other kinds of furniture, e.g. children's chairs or benches convertible into beds",
  },
  {
    c: 'A47D 13',
    d: 'Other nursery furniture',
  },
  {
    c: 'A47D 15',
    d: "Accessories for children's furniture, e.g. safety belts, baby-bottle holders",
  },
  {
    c: 'A47F',
    d: 'SPECIAL FURNITURE, FITTINGS, OR ACCESSORIES FOR SHOPS, STOREHOUSES, BARS, RESTAURANTS, OR THE LIKE; PAYING COUNTERS',
  },
  {
    c: 'A47F 1',
    d: 'Racks for dispensing merchandise',
  },
  {
    c: 'A47F 3',
    d: 'Showcases or show cabinets',
  },
  {
    c: 'A47F 5',
    d: 'Show stands, hangers, or shelves characterised by their constructional features',
  },
  {
    c: 'A47F 7',
    d: 'Show stands, hangers, or shelves, adapted for particular articles or materials',
  },
  {
    c: 'A47F 8',
    d: 'Dummies, busts, or the like, e.g. for displaying garments',
  },
  {
    c: 'A47F 9',
    d: 'Shop, bar, bank or like counters',
  },
  {
    c: 'A47F 10',
    d: 'Furniture or installations specially adapted to particular types of service systems, not otherwise provided for',
  },
  {
    c: 'A47F 11',
    d: 'Arrangements in shop windows or showcases',
  },
  {
    c: 'A47F 13',
    d: 'Shop or like accessories',
  },
  {
    c: 'A47G',
    d: 'HOUSEHOLD OR TABLE EQUIPMENT',
  },
  {
    c: 'A47G 1',
    d: 'Mirrors',
  },
  {
    c: 'A47G 3',
    d: 'Removable ornamental heads for nails, screws, or the like',
  },
  {
    c: 'A47G 5',
    d: 'Screens',
  },
  {
    c: 'A47G 7',
    d: 'Flower holders or the like',
  },
  {
    c: 'A47G 9',
    d: 'Bed-covers; Counterpanes; Travelling rugs; Sleeping rugs; Sleeping bags; Pillows',
  },
  {
    c: 'A47G 11',
    d: 'Table linen',
  },
  {
    c: 'A47G 19',
    d: 'Table devices or equipment',
  },
  {
    c: 'A47G 21',
    d: 'Table-ware',
  },
  {
    c: 'A47G 23',
    d: 'Other table equipment',
  },
  {
    c: 'A47G 25',
    d: 'Dwelling equipment',
  },
  {
    c: 'A47G 27',
    d: 'Floor fabrics; Fastenings therefor',
  },
  {
    c: 'A47G 29',
    d: 'Supports, holders, or containers for household use, not provided for in groups A47G0001000000-A47G0027000000 or A47G0033000000',
  },
  {
    c: 'A47G 33',
    d: 'Religious or ritual equipment in dwelling or for general use',
  },
  {
    c: 'A47G 35',
    d: 'Other dwelling equipment',
  },
  {
    c: 'A47H',
    d: 'FURNISHINGS FOR WINDOWS OR DOORS',
  },
  {
    c: 'A47H 1',
    d: 'Curtains; Draperies',
  },
  {
    c: 'A47H 2',
    d: 'Pelmets or the like',
  },
  {
    c: 'A47H 3',
    d: 'Fastening, clamping, or guiding devices for the bands or cords of curtains or the like',
  },
  {
    c: 'A47H 5',
    d: 'Devices for drawing draperies, curtains, or the like',
  },
  {
    c: 'A47H 7',
    d: 'Devices for putting-up and removing curtain rods',
  },
  {
    c: 'A47H 11',
    d: 'Curtain cord appurtenances',
  },
  {
    c: 'A47H 13',
    d: 'Fastening curtains on curtain rods or rails',
  },
  {
    c: 'A47H 15',
    d: 'Runners or gliders for supporting curtains on rails or rods',
  },
  {
    c: 'A47H 19',
    d: 'Rosettes for holding curtains; Festoon holders',
  },
  {
    c: 'A47H 21',
    d: 'Curtain shields',
  },
  {
    c: 'A47H 23',
    d: 'Curtains; Draperies',
  },
  {
    c: 'A47H 27',
    d: 'Boxes, shelves, holders or similar supports for holding flowers',
  },
  {
    c: 'A47H 99',
    d: 'n.a.',
  },
  {
    c: 'A47J',
    d: 'KITCHEN EQUIPMENT; COFFEE MILLS; SPICE MILLS; APPARATUS FOR MAKING BEVERAGES',
  },
  {
    c: 'A47J 9',
    d: 'Apparatus or utensils for shaping butter or the like',
  },
  {
    c: 'A47J 17',
    d: 'Household peeling, stringing, or paring implements or machines',
  },
  {
    c: 'A47J 19',
    d: 'Household machines for straining foodstuffs; Household implements for mashing or straining foodstuffs',
  },
  {
    c: 'A47J 21',
    d: 'Devices for removing stalks from fruit',
  },
  {
    c: 'A47J 23',
    d: 'Devices for stoning fruit',
  },
  {
    c: 'A47J 25',
    d: 'Devices for coring fruit',
  },
  {
    c: 'A47J 27',
    d: 'Cooking; Apparatus for making beverages',
  },
  {
    c: 'A47J 29',
    d: 'Egg-cookers',
  },
  {
    c: 'A47J 31',
    d: 'Apparatus for making beverages',
  },
  {
    c: 'A47J 33',
    d: 'Camp cooking devices without integral heating means',
  },
  {
    c: 'A47J 36',
    d: 'Parts, details or accessories of cooking-vessels',
  },
  {
    c: 'A47J 37',
    d: 'Baking; Roasting; Grilling; Frying',
  },
  {
    c: 'A47J 39',
    d: 'Heat-insulated warming chambers; Cupboards with heating arrangements for warming kitchen utensils',
  },
  {
    c: 'A47J 41',
    d: 'Thermally-insulated vessels, e.g. flasks, jugs, jars',
  },
  {
    c: 'A47J 42',
    d: 'Coffee mills; Spice mills',
  },
  {
    c: 'A47J 43',
    d: 'Implements for preparing or holding food, not provided for in other groups of this subclass',
  },
  {
    c: 'A47J 44',
    d: 'Multi-purpose machines for preparing food',
  },
  {
    c: 'A47J 45',
    d: 'Devices for fastening or gripping kitchen utensils',
  },
  {
    c: 'A47J 47',
    d: 'Kitchen containers, stands or the like, not provided for in other groups of this subclass; Cutting-boards, e.g. for bread',
  },
  {
    c: 'A47K',
    d: 'SANITARY EQUIPMENT NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'A47K 1',
    d: 'Wash-stands; Appurtenances therefor',
  },
  {
    c: 'A47K 3',
    d: 'Baths; Showers; Appurtenances therefor',
  },
  {
    c: 'A47K 4',
    d: 'Combinations of baths, showers, sinks, wash-basins, closets, or urinals, not covered by a single other group of this subclass',
  },
  {
    c: 'A47K 5',
    d: 'Holders or dispensers for soap, toothpaste, or the like',
  },
  {
    c: 'A47K 7',
    d: 'Body washing or cleaning implements',
  },
  {
    c: 'A47K 10',
    d: 'Body-drying implements; Toilet paper; Holders therefor',
  },
  {
    c: 'A47K 11',
    d: 'Closets without flushing',
  },
  {
    c: 'A47K 13',
    d: 'Seats or covers for all kinds of closets',
  },
  {
    c: 'A47K 17',
    d: 'Other equipment',
  },
  {
    c: 'A47L',
    d: 'DOMESTIC WASHING OR CLEANING',
  },
  {
    c: 'A47L 1',
    d: 'Cleaning windows, window shades, window screens, venetian blinds',
  },
  {
    c: 'A47L 3',
    d: 'Safety devices for use in window-cleaning',
  },
  {
    c: 'A47L 4',
    d: 'Cleaning window shades, window screens, venetian blinds',
  },
  {
    c: 'A47L 5',
    d: 'Suction cleaners',
  },
  {
    c: 'A47L 7',
    d: 'Suction cleaners adapted for additional purposes',
  },
  {
    c: 'A47L 9',
    d: 'Details or accessories of suction cleaners, e.g. mechanical means for controlling the suction or for effecting pulsating action; Storing devices specially adapted to suction cleaners or parts thereof; Carrying-vehicles specially adapted for suction cleaners',
  },
  {
    c: 'A47L 11',
    d: 'Cleaning floors, carpets, furniture, walls, or wall coverings',
  },
  {
    c: 'A47L 13',
    d: 'Implements for cleaning floors, carpets, furniture, walls, or wall coverings',
  },
  {
    c: 'A47L 15',
    d: 'Cleaning or polishing household articles or the like',
  },
  {
    c: 'A47L 17',
    d: 'Apparatus or implements used in manual washing or cleaning of crockery, table-ware, cooking-ware or the like',
  },
  {
    c: 'A47L 19',
    d: 'Drying devices for crockery or table-ware, e.g. tea-cloths',
  },
  {
    c: 'A47L 21',
    d: 'Polishing of table-ware, e.g. knives, forks, spoons',
  },
  {
    c: 'A47L 23',
    d: 'Cleaning footwear',
  },
  {
    c: 'A47L 25',
    d: 'Domestic cleaning devices not provided for in other groups of this subclass',
  },
  {
    c: 'A61',
    d: 'MEDICAL OR VETERINARY SCIENCE; HYGIENE',
  },
  {
    c: 'A61B',
    d: 'DIAGNOSIS; SURGERY; IDENTIFICATION',
  },
  {
    c: 'A61B 1',
    d: 'Diagnosis; Psycho-physical tests',
  },
  {
    c: 'A61B 3',
    d: 'Apparatus for testing the eyes; Instruments for examining the eyes',
  },
  {
    c: 'A61B 5',
    d: 'Measuring for diagnostic purposes',
  },
  {
    c: 'A61B 6',
    d: 'Apparatus for radiation diagnosis, e.g. combined with radiation therapy equipment',
  },
  {
    c: 'A61B 7',
    d: 'Instruments for auscultation',
  },
  {
    c: 'A61B 8',
    d: 'Diagnosis using ultrasonic, sonic or infrasonic waves',
  },
  {
    c: 'A61B 9',
    d: 'Instruments for examination by percussion; Pleximeters',
  },
  {
    c: 'A61B 10',
    d: 'Other methods or instruments for diagnosis, e.g. for vaccination diagnosis; Sex determination; Ovulation-period determination; Throat striking implements',
  },
  {
    c: 'A61B 13',
    d: 'Instruments for depressing the tongue',
  },
  {
    c: 'A61B 16',
    d: 'Devices specially adapted for vivisection or autopsy',
  },
  {
    c: 'A61B 17',
    d: 'Surgery',
  },
  {
    c: 'A61B 18',
    d: 'Surgical instruments, devices or methods for transferring non-mechanical forms of energy to or from the body',
  },
  {
    c: 'A61B 34',
    d: 'Computer-aided surgery; Manipulators or robots specially adapted for use in surgery',
  },
  {
    c: 'A61B 42',
    d: 'Surgical gloves; Finger-stalls specially adapted for surgery; Devices for handling or treatment thereof',
  },
  {
    c: 'A61B 46',
    d: 'Surgical drapes',
  },
  {
    c: 'A61B 50',
    d: 'Containers, covers, furniture or holders specially adapted for surgical or diagnostic appliances or instruments, e.g. sterile covers',
  },
  {
    c: 'A61B 90',
    d: 'Instruments, implements or accessories specially adapted for surgery or diagnosis and not covered by any of the groups A61B0001000000-A61B0050000000, e.g. for luxation treatment or for protecting wound edges',
  },
  {
    c: 'A61C',
    d: 'DENTISTRY; APPARATUS OR METHODS FOR ORAL OR DENTAL HYGIENE',
  },
  {
    c: 'A61C 1',
    d: 'Dental surgery',
  },
  {
    c: 'A61C 3',
    d: 'Dental tools or instruments',
  },
  {
    c: 'A61C 5',
    d: 'Filling or capping teeth',
  },
  {
    c: 'A61C 7',
    d: 'Orthodontics, i.e. obtaining or maintaining the desired position of teeth, e.g. by straightening, evening, regulating, separating, or by correcting malocclusions',
  },
  {
    c: 'A61C 8',
    d: 'Means to be fixed to the jaw-bone for consolidating natural teeth or for fixing dental prostheses thereon; Dental implants; Implanting tools',
  },
  {
    c: 'A61C 9',
    d: 'Dental prosthetics; Artificial teeth',
  },
  {
    c: 'A61C 11',
    d: 'Dental articulators, i.e. for simulating movement of the temporo-mandibular joints; Articulation forms or mouldings',
  },
  {
    c: 'A61C 13',
    d: 'Dental prostheses; Making same',
  },
  {
    c: 'A61C 15',
    d: 'Tooth-cleaning or mouth-rinsing devices',
  },
  {
    c: 'A61C 17',
    d: 'Devices for cleaning, polishing, rinsing or drying teeth, teeth cavities or prostheses',
  },
  {
    c: 'A61C 19',
    d: 'Dental auxiliary appliances',
  },
  {
    c: 'A61D',
    d: 'VETERINARY INSTRUMENTS, IMPLEMENTS, TOOLS, OR METHODS',
  },
  {
    c: 'A61D 1',
    d: 'Surgical instruments for veterinary use',
  },
  {
    c: 'A61D 3',
    d: 'Appliances for supporting or fettering animals for operative purposes',
  },
  {
    c: 'A61D 5',
    d: "Instruments for treating animals' teeth",
  },
  {
    c: 'A61D 7',
    d: 'Devices or methods for introducing solid, liquid, or gaseous remedies or other materials into or onto the bodies of animals',
  },
  {
    c: 'A61D 9',
    d: 'Bandages, poultices, compresses specially adapted to veterinary purposes',
  },
  {
    c: 'A61D 11',
    d: 'Washing devices or gaseous curative baths specially adapted to veterinary purposes',
  },
  {
    c: 'A61D 13',
    d: 'Thermometer holders specially adapted to veterinary purposes',
  },
  {
    c: 'A61D 15',
    d: 'Mouth openers',
  },
  {
    c: 'A61D 17',
    d: 'Devices for indicating trouble during labour of animals',
  },
  {
    c: 'A61D 19',
    d: 'Instruments or methods for reproduction or fertilisation',
  },
  {
    c: 'A61D 99',
    d: 'n.a.',
  },
  {
    c: 'A61F',
    d: 'FILTERS IMPLANTABLE INTO BLOOD VESSELS; PROSTHESES; DEVICES PROVIDING PATENCY TO, OR PREVENTING COLLAPSING OF, TUBULAR STRUCTURES OF THE BODY, e.g. STENTS; ORTHOPAEDIC, NURSING OR CONTRACEPTIVE DEVICES; FOMENTATION; TREATMENT OR PROTECTION OF EYES OR EARS; BANDAGES, DRESSINGS OR ABSORBENT PADS; FIRST-AID KITS',
  },
  {
    c: 'A61F 2',
    d: 'Filters; Devices providing patency to tubular structures; Prostheses; Accessories',
  },
  {
    c: 'A61F 3',
    d: 'Lengthening pieces for natural legs',
  },
  {
    c: 'A61F 4',
    d: 'Methods or devices enabling patients or disabled persons to operate an apparatus or a device not forming part of the body',
  },
  {
    c: 'A61F 5',
    d: 'Orthopaedic methods or devices for non-surgical treatment of bones or joints',
  },
  {
    c: 'A61F 6',
    d: 'Contraceptive devices; Pessaries; Applicators therefor',
  },
  {
    c: 'A61F 7',
    d: 'Heating or cooling appliances for medical or therapeutic treatment of the human body',
  },
  {
    c: 'A61F 9',
    d: 'Treatment or protection of the eyes or ears; Substitution by other senses',
  },
  {
    c: 'A61F 11',
    d: 'Methods or devices for treatment of the ears, e.g. surgical; Protective devices for the ears, carried on the body or in the hand',
  },
  {
    c: 'A61F 13',
    d: 'Bandages, dressings or absorbent pads; First-aid kits',
  },
  {
    c: 'A61F 15',
    d: 'Auxiliary appliances for wound dressings; Dispensing containers for dressings or bandages',
  },
  {
    c: 'A61F 17',
    d: 'First-aid kits',
  },
  {
    c: 'A61G',
    d: 'TRANSPORT, PERSONAL CONVEYANCES, OR ACCOMMODATION SPECIALLY ADAPTED FOR PATIENTS OR DISABLED PERSONS',
  },
  {
    c: 'A61G 1',
    d: 'Stretchers',
  },
  {
    c: 'A61G 3',
    d: 'Ambulance aspects of vehicles; Vehicles with special provisions for transporting patients or disabled persons, or their personal conveyances, e.g. for facilitating access of, or for loading, wheelchairs',
  },
  {
    c: 'A61G 5',
    d: 'Chairs or personal conveyances specially adapted for patients or disabled persons, e.g. wheelchairs',
  },
  {
    c: 'A61G 7',
    d: 'Beds or accessories for patients or disabled persons; Treatment rooms for medical purposes; Accommodation for nursing',
  },
  {
    c: 'A61G 9',
    d: 'Bed-pans, urinals or other sanitary devices for bed-ridden persons; Cleaning devices therefor, e.g. combined with toilet-urinals',
  },
  {
    c: 'A61G 10',
    d: 'Treatment rooms for medical purposes',
  },
  {
    c: 'A61G 11',
    d: 'Baby-incubators; Couveuses',
  },
  {
    c: 'A61G 12',
    d: 'Accommodation for nursing, e.g. in hospitals, not covered by groups A61G0001000000-A61G0011000000, e.g. trolleys for transport of medicaments or food; Prescription lists',
  },
  {
    c: 'A61G 13',
    d: 'Operating tables or chairs; Dental chairs',
  },
  {
    c: 'A61G 15',
    d: 'Operating chairs; Dental chairs; Accessories specially adapted therefor, e.g. work stands',
  },
  {
    c: 'A61G 17',
    d: 'Funeral devices',
  },
  {
    c: 'A61G 19',
    d: 'Hoisting or lowering devices for coffins',
  },
  {
    c: 'A61G 21',
    d: 'Funeral aspects of hearses or like vehicles',
  },
  {
    c: 'A61G 99',
    d: 'n.a.',
  },
  {
    c: 'A61H',
    d: 'PHYSICAL THERAPY APPARATUS, e.g. DEVICES FOR LOCATING OR STIMULATING REFLEX POINTS IN THE BODY; ARTIFICIAL RESPIRATION; MASSAGE; BATHING DEVICES FOR SPECIAL THERAPEUTIC OR HYGIENIC PURPOSES OR SPECIFIC PARTS OF THE BODY',
  },
  {
    c: 'A61H 1',
    d: 'Apparatus for passive exercising',
  },
  {
    c: 'A61H 3',
    d: 'Appliances for aiding patients or disabled persons to walk about',
  },
  {
    c: 'A61H 5',
    d: 'Exercisers for the eyes',
  },
  {
    c: 'A61H 7',
    d: 'Devices for suction-kneading massage; Devices for massaging the skin by rubbing or brushing not otherwise provided for',
  },
  {
    c: 'A61H 9',
    d: 'Pneumatic or hydraulic massage',
  },
  {
    c: 'A61H 11',
    d: 'Belts, strips, or combs for massage purposes',
  },
  {
    c: 'A61H 13',
    d: 'Gum massage',
  },
  {
    c: 'A61H 15',
    d: 'Massage by means of rollers, balls, e.g. inflatable, chains, or roller chains',
  },
  {
    c: 'A61H 19',
    d: 'Massage of the genitals',
  },
  {
    c: 'A61H 21',
    d: 'Massage devices for cavities of the body',
  },
  {
    c: 'A61H 23',
    d: 'Percussion or vibration massage, e.g. using supersonic vibration; Suction-vibration massage; Massage with moving diaphragms',
  },
  {
    c: 'A61H 31',
    d: 'Artificial respiration or heart stimulation, e.g. heart massage',
  },
  {
    c: 'A61H 33',
    d: 'Bathing devices for special therapeutic or hygienic purposes',
  },
  {
    c: 'A61H 35',
    d: 'Baths for specific parts of the body, e.g. breast douches',
  },
  {
    c: 'A61H 36',
    d: 'Sweating suits',
  },
  {
    c: 'A61H 37',
    d: 'Accessories for massage',
  },
  {
    c: 'A61H 39',
    d: 'Devices for locating or stimulating specific reflex points of the body for physical therapy, e.g. acupuncture',
  },
  {
    c: 'A61H 99',
    d: 'n.a.',
  },
  {
    c: 'A61J',
    d: 'CONTAINERS SPECIALLY ADAPTED FOR MEDICAL OR PHARMACEUTICAL PURPOSES; DEVICES OR METHODS SPECIALLY ADAPTED FOR BRINGING PHARMACEUTICAL PRODUCTS INTO PARTICULAR PHYSICAL OR ADMINISTERING FORMS; DEVICES FOR ADMINISTERING FOOD OR MEDICINES ORALLY; BABY COMFORTERS; DEVICES FOR RECEIVING SPITTLE',
  },
  {
    c: 'A61J 1',
    d: 'Containers specially adapted for medical or pharmaceutical purposes',
  },
  {
    c: 'A61J 3',
    d: 'Devices or methods specially adapted for bringing pharmaceutical products into particular physical or administering forms',
  },
  {
    c: 'A61J 7',
    d: 'Administering medicines orally; Feeding-bottles in general; Teats; Devices for receiving spittle',
  },
  {
    c: 'A61J 9',
    d: 'Feeding-bottles in general',
  },
  {
    c: 'A61J 11',
    d: 'Teats',
  },
  {
    c: 'A61J 13',
    d: 'Breast-nipple shields',
  },
  {
    c: 'A61J 15',
    d: 'Feeding-tubes for therapeutic purposes',
  },
  {
    c: 'A61J 17',
    d: 'Baby comforters; Teething rings',
  },
  {
    c: 'A61J 19',
    d: 'Devices for receiving spittle, e.g. spittoons',
  },
  {
    c: 'A61K',
    d: 'PREPARATIONS FOR MEDICAL, DENTAL, OR TOILET PURPOSES',
  },
  {
    c: 'A61K 6',
    d: 'Preparations for dentistry',
  },
  {
    c: 'A61K 8',
    d: 'Cosmetics or similar toilet preparations',
  },
  {
    c: 'A61K 9',
    d: 'Medicinal preparations characterised by special physical form',
  },
  {
    c: 'A61K 31',
    d: 'Medicinal preparations containing organic active ingredients',
  },
  {
    c: 'A61K 33',
    d: 'Medicinal preparations containing inorganic active ingredients',
  },
  {
    c: 'A61K 35',
    d: 'Medicinal preparations containing materials or reaction products thereof with undetermined constitution',
  },
  {
    c: 'A61K 36',
    d: 'Medicinal preparations of undetermined constitution containing material from algae, lichens, fungi or plants, or derivatives thereof, e.g. traditional herbal medicines',
  },
  {
    c: 'A61K 38',
    d: 'Medicinal preparations containing peptides',
  },
  {
    c: 'A61K 39',
    d: 'Medicinal preparations containing antigens or antibodies',
  },
  {
    c: 'A61K 41',
    d: 'Medicinal preparations obtained by treating materials with wave energy or particle radiation',
  },
  {
    c: 'A61K 45',
    d: 'Medicinal preparations containing active ingredients not provided for in groups A61K0031000000-A61K0041000000',
  },
  {
    c: 'A61K 47',
    d: 'Medicinal preparations characterised by the non-active ingredients used, e.g. carriers or inert additives; Targeting or modifying agents chemically bound to the active ingredient',
  },
  {
    c: 'A61K 48',
    d: 'Medicinal preparations containing genetic material which is inserted into cells of the living body to treat genetic diseases; Gene therapy',
  },
  {
    c: 'A61K 49',
    d: 'Preparations for testing in vivo',
  },
  {
    c: 'A61K 50',
    d: 'Electrically conductive preparations for use in therapy or testing in vivo, e.g. conductive adhesives or gels to be used with electrodes for electrocardiography',
  },
  {
    c: 'A61K 51',
    d: 'Preparations containing radioactive substances for use in therapy or testing in vivo',
  },
  {
    c: 'A61K 101',
    d: 'Indexing scheme associated with group A61K0051000000, relating to the nature of the radioactive substance.',
  },
  {
    c: 'A61K 103',
    d: 'Radioactive metals',
  },
  {
    c: 'A61K 125',
    d: 'Indexing scheme associated with group A61K0036000000, relating to plant parts with medicinal activity.',
  },
  {
    c: 'A61K 127',
    d: 'Containing or obtained from leaves',
  },
  {
    c: 'A61K 129',
    d: 'Containing or obtained from bark',
  },
  {
    c: 'A61K 131',
    d: 'Containing or obtained from seeds, nuts, fruits or grains',
  },
  {
    c: 'A61K 133',
    d: 'Containing or obtained from flowers or blossoms',
  },
  {
    c: 'A61K 135',
    d: 'Containing or obtained from stems, stalks, branches, twigs or shoots',
  },
  {
    c: 'A61L',
    d: 'METHODS OR APPARATUS FOR STERILISING MATERIALS OR OBJECTS IN GENERAL; DISINFECTION, STERILISATION, OR DEODORISATION OF AIR; CHEMICAL ASPECTS OF BANDAGES, DRESSINGS, ABSORBENT PADS, OR SURGICAL ARTICLES; MATERIALS FOR BANDAGES, DRESSINGS, ABSORBENT PADS, OR SURGICAL ARTICLES',
  },
  {
    c: 'A61L 2',
    d: 'Disinfection or sterilising',
  },
  {
    c: 'A61L 9',
    d: 'Disinfection, sterilisation or deodorisation of air',
  },
  {
    c: 'A61L 11',
    d: 'Disinfection or sterilising methods specially adapted for refuse',
  },
  {
    c: 'A61L 12',
    d: 'Methods or apparatus for disinfecting or sterilising contact lenses; Accessories therefor',
  },
  {
    c: 'A61L 15',
    d: 'Chemical aspects of bandages, dressings, or absorbent pads or use of materials for their realisation; Materials for surgical articles, e.g. surgical sutures; Surgical adhesives or cements; Materials for prostheses, catheters or colostomy devices',
  },
  {
    c: 'A61L 17',
    d: 'Materials for surgical sutures or for ligaturing blood vessels',
  },
  {
    c: 'A61L 24',
    d: 'Surgical adhesives or cements; Adhesives for colostomy devices',
  },
  {
    c: 'A61L 26',
    d: 'Chemical aspects of, or use of materials for, liquid bandages',
  },
  {
    c: 'A61L 27',
    d: 'Materials for prostheses or for coating prostheses',
  },
  {
    c: 'A61L 28',
    d: 'Materials for colostomy devices',
  },
  {
    c: 'A61L 29',
    d: 'Materials for catheters or for coating catheters',
  },
  {
    c: 'A61L 31',
    d: 'Materials for other surgical articles',
  },
  {
    c: 'A61L 33',
    d: 'Antithrombogenic treatment of surgical articles, e.g. sutures, catheters, prostheses, or of articles for the manipulation or conditioning of blood; Materials for such treatment',
  },
  {
    c: 'A61L 101',
    d: 'Indexing scheme associated with groups A61L0002000000-A61L0012000000, relating to the chemical composition of the materials used in disinfecting, sterilising or deodorising.',
  },
  {
    c: 'A61M',
    d: 'DEVICES FOR INTRODUCING MEDIA INTO, OR ONTO, THE BODY',
  },
  {
    c: 'A61M 1',
    d: 'Suction or pumping devices for medical purposes; Devices for carrying-off, for treatment of, or for carrying-over, body-liquids; Drainage systems',
  },
  {
    c: 'A61M 3',
    d: 'Syringes; Irrigators; Baths for subaquatic intestinal cleaning',
  },
  {
    c: 'A61M 5',
    d: 'Devices for bringing media into the body in a subcutaneous, intra-vascular or intramuscular way; Accessories therefor, e.g. filling or cleaning devices, arm rests',
  },
  {
    c: 'A61M 9',
    d: 'Baths for subaquatic intestinal cleaning',
  },
  {
    c: 'A61M 11',
    d: 'Sprayers; Atomisers; Insufflators',
  },
  {
    c: 'A61M 13',
    d: 'Insufflators for therapeutic or disinfectant purposes',
  },
  {
    c: 'A61M 15',
    d: 'Inhaling devices',
  },
  {
    c: 'A61M 16',
    d: 'Devices for influencing the respiratory system of patients by gas treatment, e.g. mouth-to-mouth respiration; Tracheal tubes',
  },
  {
    c: 'A61M 19',
    d: 'Other devices for producing sleep or stupor; Devices for ending sleep or stupor',
  },
  {
    c: 'A61M 21',
    d: 'Other devices or methods to cause a change in the state of consciousness; Devices for producing or ending sleep by mechanical, optical, or acoustical means, e.g. for hypnosis',
  },
  {
    c: 'A61M 25',
    d: 'Catheters; Hollow probes',
  },
  {
    c: 'A61M 27',
    d: 'Drainage appliances for wounds, or the like',
  },
  {
    c: 'A61M 29',
    d: 'Dilators with or without means for introducing media, e.g. remedies',
  },
  {
    c: 'A61M 31',
    d: 'Devices for introducing or retaining media, e.g. remedies, in cavities of the body',
  },
  {
    c: 'A61M 35',
    d: 'Devices for applying media, e.g. remedies, on the human body',
  },
  {
    c: 'A61M 36',
    d: 'Applying radioactive material to the body',
  },
  {
    c: 'A61M 37',
    d: 'Other apparatus for introducing media into the body',
  },
  {
    c: 'A61M 39',
    d: 'Tubes, tube connectors, tube couplings, valves, access sites or the like, specially adapted for medical use',
  },
  {
    c: 'A61M 99',
    d: 'n.a.',
  },
  {
    c: 'A61N',
    d: 'ELECTROTHERAPY; MAGNETOTHERAPY; RADIATION THERAPY; ULTRASOUND THERAPY',
  },
  {
    c: 'A61N 1',
    d: 'Electrotherapy; Circuits therefor',
  },
  {
    c: 'A61N 2',
    d: 'Magnetotherapy',
  },
  {
    c: 'A61N 5',
    d: 'Radiation therapy',
  },
  {
    c: 'A61N 7',
    d: 'Ultrasound therapy',
  },
  {
    c: 'A61P',
    d: 'SPECIFIC THERAPEUTIC ACTIVITY OF CHEMICAL COMPOUNDS OR MEDICINAL PREPARATIONS',
  },
  {
    c: 'A61P 1',
    d: 'Drugs for disorders of the alimentary tract or the digestive system',
  },
  {
    c: 'A61P 3',
    d: 'Drugs for disorders of the metabolism',
  },
  {
    c: 'A61P 5',
    d: 'Drugs for disorders of the endocrine system',
  },
  {
    c: 'A61P 7',
    d: 'Drugs for disorders of the blood or the extracellular fluid',
  },
  {
    c: 'A61P 9',
    d: 'Drugs for disorders of the cardiovascular system',
  },
  {
    c: 'A61P 11',
    d: 'Drugs for disorders of the respiratory system',
  },
  {
    c: 'A61P 13',
    d: 'Drugs for disorders of the urinary system',
  },
  {
    c: 'A61P 15',
    d: 'Drugs for genital or sexual disorders',
  },
  {
    c: 'A61P 17',
    d: 'Drugs for dermatological disorders',
  },
  {
    c: 'A61P 19',
    d: 'Drugs for skeletal disorders',
  },
  {
    c: 'A61P 21',
    d: 'Drugs for disorders of the muscular or neuromuscular system',
  },
  {
    c: 'A61P 23',
    d: 'Anaesthetics',
  },
  {
    c: 'A61P 25',
    d: 'Drugs for disorders of the nervous system',
  },
  {
    c: 'A61P 27',
    d: 'Drugs for disorders of the senses',
  },
  {
    c: 'A61P 29',
    d: 'Non-central analgesic, antipyretic or antiinflammatory agents, e.g antirheumatic agents; Non-steroidal antiinflammatory drugs',
  },
  {
    c: 'A61P 31',
    d: 'Antiinfectives, i.e. antibiotics, antiseptics, chemotherapeutics',
  },
  {
    c: 'A61P 33',
    d: 'Antiparasitic agents',
  },
  {
    c: 'A61P 35',
    d: 'Antineoplastic agents',
  },
  {
    c: 'A61P 37',
    d: 'Drugs for immunological or allergic disorders',
  },
  {
    c: 'A61P 39',
    d: 'General protective or antinoxious agents',
  },
  {
    c: 'A61P 41',
    d: 'Drugs used in surgical methods, e.g. surgery adjuvants for preventing adhesion or for vitreum substitution',
  },
  {
    c: 'A61P 43',
    d: 'Drugs for specific purposes, not provided for in groups A61P0001000000-A61P0041000000',
  },
  {
    c: 'A61Q',
    d: 'SPECIFIC USE OF COSMETICS OR SIMILAR TOILET PREPARATIONS',
  },
  {
    c: 'A61Q 1',
    d: 'Make-up preparations; Body powders; Preparations for removing make-up',
  },
  {
    c: 'A61Q 3',
    d: 'Manicure or pedicure preparations',
  },
  {
    c: 'A61Q 5',
    d: 'Preparations for care of the hair',
  },
  {
    c: 'A61Q 7',
    d: 'Preparations for affecting hair growth',
  },
  {
    c: 'A61Q 9',
    d: 'Preparations for removing hair or for aiding hair removal',
  },
  {
    c: 'A61Q 11',
    d: 'Preparations for care of the teeth, of the oral cavity or of dentures, e.g. dentifrices or toothpastes; Mouth rinses',
  },
  {
    c: 'A61Q 13',
    d: 'Formulations or additives for perfume preparations',
  },
  {
    c: 'A61Q 15',
    d: 'Anti-perspirants or body deodorants',
  },
  {
    c: 'A61Q 17',
    d: 'Barrier preparations; Preparations brought into direct contact with the skin for affording protection against external influences, e.g. sunlight, X-rays or other harmful rays, corrosive materials, bacteria or insect stings',
  },
  {
    c: 'A61Q 19',
    d: 'Preparations for care of the skin',
  },
  {
    c: 'A61Q 90',
    d: 'Cosmetics or similar toilet preparations for specific uses not provided for in other groups of this subclass',
  },
  {
    c: 'A62',
    d: 'LIFE-SAVING; FIRE-FIGHTING',
  },
  {
    c: 'A62B',
    d: 'DEVICES, APPARATUS OR METHODS FOR LIFE-SAVING',
  },
  {
    c: 'A62B 1',
    d: 'Rescuing from fire; Rescuing from buildings or the like',
  },
  {
    c: 'A62B 3',
    d: 'Devices or single parts for facilitating escape from buildings or the like, e.g. protection shields, protection screens; Portable devices for preventing smoke penetrating into distinct parts of buildings',
  },
  {
    c: 'A62B 5',
    d: 'Other devices for rescuing from fire',
  },
  {
    c: 'A62B 7',
    d: 'Respirators; Gas-masks, including breathing apparatus, e.g. for high altitude, or masks therefor; Devices affording protection against harmful chemical agents',
  },
  {
    c: 'A62B 9',
    d: 'Component parts for respiratory or breathing apparatus',
  },
  {
    c: 'A62B 11',
    d: 'Devices for reconditioning breathing air in sealed rooms',
  },
  {
    c: 'A62B 13',
    d: 'Special devices for ventilating gasproof shelters',
  },
  {
    c: 'A62B 15',
    d: 'Installations affording protection against poisonous or injurious substances, e.g. with separate breathing apparatus',
  },
  {
    c: 'A62B 17',
    d: 'Protective clothing affording protection against heat or harmful chemical agents or for use at high altitudes',
  },
  {
    c: 'A62B 18',
    d: 'Breathing masks or helmets, e.g. affording protection against chemical agents or for use at high altitudes',
  },
  {
    c: 'A62B 19',
    d: 'Cartridges with absorbing substances for respiratory apparatus',
  },
  {
    c: 'A62B 21',
    d: 'Devices for producing oxygen from chemical substances for respiratory apparatus',
  },
  {
    c: 'A62B 23',
    d: 'Filters for breathing-protection purposes',
  },
  {
    c: 'A62B 25',
    d: 'Devices for storing respiratory or breathing apparatus',
  },
  {
    c: 'A62B 27',
    d: 'Methods or devices for testing respiratory or breathing apparatus',
  },
  {
    c: 'A62B 29',
    d: 'Devices, e.g. installations,  for rendering harmless or for keeping off harmful chemical agents',
  },
  {
    c: 'A62B 31',
    d: 'Containers or portable cabins for affording breathing protection with devices for reconditioning the breathing air or for ventilating',
  },
  {
    c: 'A62B 33',
    d: 'Devices for allowing seemingly-dead persons to escape or draw attention; Breathing apparatus for accidentally buried person',
  },
  {
    c: 'A62B 35',
    d: 'Safety belts or body harnesses; Similar equipment for limiting displacement of the human body, especially in case of sudden changes of motion',
  },
  {
    c: 'A62B 99',
    d: 'n.a.',
  },
  {
    c: 'A62C',
    d: 'FIRE-FIGHTING',
  },
  {
    c: 'A62C 2',
    d: 'Fire prevention or containment',
  },
  {
    c: 'A62C 3',
    d: 'Fire prevention, containment or extinguishing specially adapted for particular objects or places',
  },
  {
    c: 'A62C 4',
    d: 'Flame traps allowing passage of gas but not of flame or explosion wave',
  },
  {
    c: 'A62C 5',
    d: 'Making of fire-extinguishing materials immediately before use',
  },
  {
    c: 'A62C 8',
    d: 'Hand fire-extinguishers',
  },
  {
    c: 'A62C 11',
    d: 'Portable extinguishers with manually-operated pumps',
  },
  {
    c: 'A62C 13',
    d: 'Portable extinguishers which are permanently pressurised or pressurised immediately before use',
  },
  {
    c: 'A62C 15',
    d: 'Extinguishers essentially of the knapsack type',
  },
  {
    c: 'A62C 17',
    d: 'Hand fire-extinguishers essentially in the form of pistols or rifles',
  },
  {
    c: 'A62C 19',
    d: 'Hand fire-extinguishers in which the extinguishing substance is expelled by an explosion; Exploding containers thrown into the fire',
  },
  {
    c: 'A62C 25',
    d: 'Portable extinguishers with power-driven pumps',
  },
  {
    c: 'A62C 27',
    d: 'Fire-fighting land vehicles',
  },
  {
    c: 'A62C 29',
    d: 'Fire-fighting vessels or like floating structures',
  },
  {
    c: 'A62C 31',
    d: 'Delivery of fire-extinguishing material',
  },
  {
    c: 'A62C 33',
    d: 'Hose accessories',
  },
  {
    c: 'A62C 35',
    d: 'Permanently-installed equipment',
  },
  {
    c: 'A62C 37',
    d: 'Control of fire-fighting equipment',
  },
  {
    c: 'A62C 99',
    d: 'n.a.',
  },
  {
    c: 'A62D',
    d: 'CHEMICAL MEANS FOR EXTINGUISHING FIRES; PROCESSES FOR MAKING HARMFUL CHEMICAL SUBSTANCES HARMLESS, OR LESS HARMFUL, BY EFFECTING A CHEMICAL CHANGE; COMPOSITION OF MATERIALS FOR COVERINGS OR CLOTHING FOR PROTECTING AGAINST HARMFUL CHEMICAL AGENTS; COMPOSITION OF MATERIALS FOR TRANSPARENT PARTS OF GAS-MASKS, RESPIRATORS, BREATHING BAGS OR HELMETS; COMPOSITION OF CHEMICAL MATERIALS FOR USE IN BREATHING APPARATUS',
  },
  {
    c: 'A62D 1',
    d: 'Fire-extinguishing compositions; Use of chemical substances in extinguishing fires',
  },
  {
    c: 'A62D 3',
    d: 'Processes for making harmful chemical substances harmless, or less harmful, by effecting a chemical change in the substances',
  },
  {
    c: 'A62D 5',
    d: 'Composition of materials for coverings or clothing affording protection against harmful chemical agents',
  },
  {
    c: 'A62D 7',
    d: 'Composition of materials for transparent parts of gas-masks, respirators, breathing bags, or helmets',
  },
  {
    c: 'A62D 9',
    d: 'Composition of chemical substances for use in breathing apparatus',
  },
  {
    c: 'A62D 101',
    d: 'Indexing scheme associated with group A62D0003000000 relating to the nature of the harmful chemical substances',
  },
  {
    c: 'A63',
    d: 'SPORTS; GAMES; AMUSEMENTS',
  },
  {
    c: 'A63B',
    d: 'APPARATUS FOR PHYSICAL TRAINING, GYMNASTICS, SWIMMING, CLIMBING, OR FENCING; BALL GAMES; TRAINING EQUIPMENT',
  },
  {
    c: 'A63B 1',
    d: 'Gymnastic exercising apparatus',
  },
  {
    c: 'A63B 3',
    d: 'Parallel bars or similar gymnastic apparatus',
  },
  {
    c: 'A63B 4',
    d: 'Balance beams',
  },
  {
    c: 'A63B 5',
    d: 'Apparatus for jumping',
  },
  {
    c: 'A63B 6',
    d: 'Mats or the like for absorbing shocks for jumping, gymnastics or the like',
  },
  {
    c: 'A63B 7',
    d: 'Freely-suspended gymnastic apparatus',
  },
  {
    c: 'A63B 9',
    d: 'Climbing poles, frames, or stages',
  },
  {
    c: 'A63B 15',
    d: 'Clubs for gymnastics or the like',
  },
  {
    c: 'A63B 17',
    d: 'Exercising apparatus combining several parts such as ladders, rods, beams, slides',
  },
  {
    c: 'A63B 19',
    d: 'Hoop exercising apparatus',
  },
  {
    c: 'A63B 21',
    d: 'Exercising apparatus for developing or strengthening the muscles or joints of the body by working against a counterforce, with or without measuring devices',
  },
  {
    c: 'A63B 22',
    d: 'Exercising apparatus specially adapted for conditioning the cardio-vascular system, for training agility or co-ordination of movements',
  },
  {
    c: 'A63B 23',
    d: 'Exercising apparatus specially adapted for particular parts of the body',
  },
  {
    c: 'A63B 24',
    d: 'Electric or electronic controls for exercising apparatus of groups A63B0001000000-A63B0023000000',
  },
  {
    c: 'A63B 25',
    d: 'Stilts or the like',
  },
  {
    c: 'A63B 26',
    d: 'Exercising apparatus not covered by groups A63B0001000000-A63B0025000000',
  },
  {
    c: 'A63B 27',
    d: 'Climbing; Mountaineering',
  },
  {
    c: 'A63B 29',
    d: 'Apparatus for mountaineering',
  },
  {
    c: 'A63B 31',
    d: 'Swimming',
  },
  {
    c: 'A63B 33',
    d: 'Swimming equipment attachable to the head, e.g. swim caps or goggles',
  },
  {
    c: 'A63B 35',
    d: 'Swimming framework with driving mechanisms operated by the swimmer or by a motor',
  },
  {
    c: 'A63B 37',
    d: 'Balls',
  },
  {
    c: 'A63B 39',
    d: 'Hollow non-inflatable balls',
  },
  {
    c: 'A63B 41',
    d: 'Hollow inflatable balls',
  },
  {
    c: 'A63B 43',
    d: 'Balls with special arrangements',
  },
  {
    c: 'A63B 45',
    d: 'Apparatus or methods for manufacturing balls',
  },
  {
    c: 'A63B 47',
    d: 'Devices for handling or treating balls',
  },
  {
    c: 'A63B 49',
    d: 'Rackets, bats, or other accessories for ball games',
  },
  {
    c: 'A63B 51',
    d: 'Stringing tennis, badminton or like rackets; Strings therefor; Maintenance of racket strings',
  },
  {
    c: 'A63B 53',
    d: 'Golf clubs',
  },
  {
    c: 'A63B 55',
    d: 'Bags for golf clubs; Stands for golf clubs for use on the course; Wheeled carriers specially adapted for golf bags',
  },
  {
    c: 'A63B 57',
    d: 'Golfing accessories',
  },
  {
    c: 'A63B 59',
    d: 'Bats, rackets or the like, not covered by groups A63B0049000000-A63B0057000000',
  },
  {
    c: 'A63B 60',
    d: 'Details or accessories of golf clubs, bats, rackets or the like',
  },
  {
    c: 'A63B 61',
    d: 'Tennis nets or accessories for tennis or like games',
  },
  {
    c: 'A63B 63',
    d: 'Targets or goals for ball games',
  },
  {
    c: 'A63B 65',
    d: 'Implements for throwing',
  },
  {
    c: 'A63B 67',
    d: 'Sporting games or accessories therefor, not provided for in groups A63B0001000000-A63B0065000000',
  },
  {
    c: 'A63B 69',
    d: 'Training appliances or apparatus for special sports',
  },
  {
    c: 'A63B 71',
    d: 'Games or sports accessories not covered in groups A63B0001000000-A63B0069000000',
  },
  {
    c: 'A63B 102',
    d: 'Indexing scheme associated with groups A63B0049000000-A63B0061000000 relating to the type of sport',
  },
  {
    c: 'A63C',
    d: 'SKATES; SKIS; ROLLER SKATES; DESIGN OR LAYOUT OF COURTS, RINKS OR THE LIKE',
  },
  {
    c: 'A63C 1',
    d: 'Skates; Accessories for skating',
  },
  {
    c: 'A63C 3',
    d: 'Accessories for skates',
  },
  {
    c: 'A63C 5',
    d: 'Skis or snowboards; Accessories therefor',
  },
  {
    c: 'A63C 7',
    d: 'Devices preventing skis from slipping back; Ski-stoppers or ski-brakes',
  },
  {
    c: 'A63C 9',
    d: 'Ski bindings',
  },
  {
    c: 'A63C 10',
    d: 'Snowboard bindings',
  },
  {
    c: 'A63C 11',
    d: 'Accessories for skiing or snowboarding',
  },
  {
    c: 'A63C 13',
    d: 'Snow shoes',
  },
  {
    c: 'A63C 17',
    d: 'Roller skates; Skate-boards',
  },
  {
    c: 'A63C 19',
    d: 'Design or layout of playing courts, rinks, bowling greens or areas for water-skiing; Covers therefor',
  },
  {
    c: 'A63D',
    d: 'BOWLING GAMES, e.g. SKITTLES, BOCCE OR BOWLS; INSTALLATIONS THEREFOR; BAGATELLE OR SIMILAR GAMES; BILLIARDS',
  },
  {
    c: 'A63D 1',
    d: 'Installations for bowling games, e.g. bowling-alleys or bocce courts',
  },
  {
    c: 'A63D 3',
    d: 'Table bowling games; Miniature bowling-alleys; Bowling games',
  },
  {
    c: 'A63D 5',
    d: 'Accessories for bowling-alleys or table alleys',
  },
  {
    c: 'A63D 7',
    d: 'Games of pins, e.g. ninepins with tethered balls',
  },
  {
    c: 'A63D 9',
    d: 'Pins',
  },
  {
    c: 'A63D 13',
    d: 'Bagatelle or similar games',
  },
  {
    c: 'A63D 15',
    d: 'Billiards, e.g. carom billiards or pocket billiards; Billiard tables',
  },
  {
    c: 'A63F',
    d: 'CARD, BOARD OR ROULETTE GAMES; INDOOR GAMES USING SMALL MOVING PLAYING BODIES; VIDEO GAMES; GAMES NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'A63F 1',
    d: 'Card games',
  },
  {
    c: 'A63F 3',
    d: 'Board games; Raffle games',
  },
  {
    c: 'A63F 5',
    d: 'Roulette games',
  },
  {
    c: 'A63F 7',
    d: 'Indoor games using small moving playing bodies, e.g. balls, discs or blocks',
  },
  {
    c: 'A63F 9',
    d: 'Games not otherwise provided for',
  },
  {
    c: 'A63F 11',
    d: 'Game accessories of general use',
  },
  {
    c: 'A63F 13',
    d: 'Video games, i.e. games using an electronically generated display having two or more dimensions',
  },
  {
    c: 'A63G',
    d: 'MERRY-GO-ROUNDS; SWINGS; ROCKING-HORSES',
  },
  {
    c: 'A63G 1',
    d: 'Merry-go-rounds or roundabouts',
  },
  {
    c: 'A63G 3',
    d: 'Water roundabouts, e.g. freely floating',
  },
  {
    c: 'A63G 4',
    d: 'Accessories for roundabouts not restricted to one of groups A63G0001000000 or A63G0003000000',
  },
  {
    c: 'A63G 5',
    d: 'Games on roundabouts',
  },
  {
    c: 'A63G 7',
    d: 'Up-and-down-hill tracks; Switchbacks',
  },
  {
    c: 'A63G 9',
    d: 'Swings; See-saws; Rocking horses; Other toy animals for riding',
  },
  {
    c: 'A63G 11',
    d: 'See-saws',
  },
  {
    c: 'A63G 13',
    d: 'Cradle swings; Rocking-horses; Like devices resting on the ground',
  },
  {
    c: 'A63G 15',
    d: 'Rocking horses on runners adapted for progressive movement',
  },
  {
    c: 'A63G 17',
    d: 'Hobby-horses',
  },
  {
    c: 'A63G 19',
    d: 'Toy animals for riding',
  },
  {
    c: 'A63G 21',
    d: 'Chutes; Slides; Similar apparatus for public amusement',
  },
  {
    c: 'A63G 23',
    d: 'Rotating or rocking pots',
  },
  {
    c: 'A63G 25',
    d: 'Autocar-like self-drivers; Runways therefor',
  },
  {
    c: 'A63G 27',
    d: 'Russian swings; Great wheels, e.g. Ferris wheels',
  },
  {
    c: 'A63G 29',
    d: 'Rolling drums turning somersaults with or without rolling seats',
  },
  {
    c: 'A63G 31',
    d: 'Other apparatus for public amusement',
  },
  {
    c: 'A63G 33',
    d: 'Devices allowing competitions between several persons, not otherwise provided for',
  },
  {
    c: 'A63H',
    d: 'TOYS, e.g. TOPS, DOLLS, HOOPS, BUILDING BLOCKS',
  },
  {
    c: 'A63H 1',
    d: 'Tops',
  },
  {
    c: 'A63H 3',
    d: 'Dolls; Figures; Musical toys',
  },
  {
    c: 'A63H 5',
    d: 'Musical or noise-producing devices for additional toy effects other than acoustical',
  },
  {
    c: 'A63H 7',
    d: 'Toy figures led or propelled by the user',
  },
  {
    c: 'A63H 9',
    d: 'Special methods or compositions for the manufacture of dolls, toy animals, toy figures, or parts thereof',
  },
  {
    c: 'A63H 11',
    d: 'Mechanically or gravity driven toy figures',
  },
  {
    c: 'A63H 13',
    d: 'Toy figures with self-moving parts, with or without movement of the toy as a whole',
  },
  {
    c: 'A63H 15',
    d: 'Other gravity-operated toy figures',
  },
  {
    c: 'A63H 17',
    d: 'Toy vehicles; Toy engines',
  },
  {
    c: 'A63H 18',
    d: 'Highways or trackways for toys',
  },
  {
    c: 'A63H 19',
    d: 'Model railways',
  },
  {
    c: 'A63H 21',
    d: 'Other toy railways',
  },
  {
    c: 'A63H 23',
    d: 'Toy boats; Floating toys; Other aquatic toy devices',
  },
  {
    c: 'A63H 25',
    d: 'Toy steam, gas, or compressed-air engines',
  },
  {
    c: 'A63H 27',
    d: 'Toy aircraft; Other flying toys',
  },
  {
    c: 'A63H 29',
    d: 'Driving or controlling toys, e.g. toy vehicles',
  },
  {
    c: 'A63H 30',
    d: 'Remote-control arrangements specially adapted for toys, e.g. for toy vehicles',
  },
  {
    c: 'A63H 31',
    d: 'Gearing for toys',
  },
  {
    c: 'A63H 33',
    d: 'Other toys',
  },
  {
    c: 'A63H 37',
    d: 'Jokes; Confetti, streamers, or other dance favours',
  },
  {
    c: 'A63J',
    d: 'DEVICES FOR THEATRES, CIRCUSES, OR THE LIKE; CONJURING APPLIANCES OR THE LIKE',
  },
  {
    c: 'A63J 1',
    d: 'Stage arrangements',
  },
  {
    c: 'A63J 3',
    d: 'Equipment for, or arrangement of, circuses or arenas',
  },
  {
    c: 'A63J 5',
    d: 'Auxiliaries for producing special effects on stages, or in circuses or arenas',
  },
  {
    c: 'A63J 7',
    d: 'Auxiliary apparatus for artistes',
  },
  {
    c: 'A63J 9',
    d: 'Centrifugal tracks, loop-the-loops or the like',
  },
  {
    c: 'A63J 11',
    d: 'Labyrinths',
  },
  {
    c: 'A63J 13',
    d: 'Panoramas, dioramas, stereoramas, or the like',
  },
  {
    c: 'A63J 15',
    d: 'Peep-shows, e.g. raree-shows; Kaleidoscopic or other opalescence exhibitions',
  },
  {
    c: 'A63J 17',
    d: 'Apparatus for performing colour-music',
  },
  {
    c: 'A63J 19',
    d: 'Puppet, marionette, or shadow shows or theatres',
  },
  {
    c: 'A63J 21',
    d: 'Conjuring appliances; Auxiliary apparatus for conjurers',
  },
  {
    c: 'A63J 25',
    d: 'Equipment specially adapted for cinemas',
  },
  {
    c: 'A63J 99',
    d: 'n.a.',
  },
  {
    c: 'A63K',
    d: 'RACING; RIDING SPORTS; EQUIPMENT OR ACCESSORIES THEREFOR',
  },
  {
    c: 'A63K 1',
    d: 'Race-courses; Race-tracks',
  },
  {
    c: 'A63K 3',
    d: 'Equipment or accessories for racing or riding sports',
  },
  {
    c: 'A63K 99',
    d: 'n.a.',
  },
  {
    c: 'A99',
    d: 'n.a.',
  },
  {
    c: 'A99Z',
    d: 'n.a.',
  },
  {
    c: 'A99Z 99',
    d: 'n.a.',
  },
  {
    c: 'B',
    d: 'PERFORMING OPERATIONS; TRANSPORTING',
  },
  {
    c: 'B01',
    d: 'PHYSICAL OR CHEMICAL PROCESSES OR APPARATUS IN GENERAL',
  },
  {
    c: 'B01B',
    d: 'BOILING; BOILING APPARATUS',
  },
  {
    c: 'B01B 1',
    d: 'Boiling; Boiling apparatus for physical or chemical purposes',
  },
  {
    c: 'B01D',
    d: 'SEPARATION',
  },
  {
    c: 'B01D 1',
    d: 'Evaporating',
  },
  {
    c: 'B01D 3',
    d: 'Distillation or related exchange processes in which liquids are contacted with gaseous media, e.g. stripping',
  },
  {
    c: 'B01D 5',
    d: 'Condensation of vapours; Recovering volatile solvents by condensation',
  },
  {
    c: 'B01D 7',
    d: 'Sublimation',
  },
  {
    c: 'B01D 8',
    d: 'Cold traps; Cold baffles',
  },
  {
    c: 'B01D 9',
    d: 'Crystallisation',
  },
  {
    c: 'B01D 11',
    d: 'Solvent extraction',
  },
  {
    c: 'B01D 12',
    d: 'Displacing liquid, e.g. from wet solids or from dispersions of liquids or from solids in liquids, by means of another liquid',
  },
  {
    c: 'B01D 15',
    d: 'Separating processes involving the treatment of liquids with solid sorbents; Apparatus therefor',
  },
  {
    c: 'B01D 17',
    d: 'Separation of liquids, not provided for elsewhere, e.g. by thermal diffusion',
  },
  {
    c: 'B01D 19',
    d: 'Degasification of liquids',
  },
  {
    c: 'B01D 21',
    d: 'Separation of suspended solid particles from liquids by sedimentation',
  },
  {
    c: 'B01D 24',
    d: 'Filtration; Filtering material, regeneration thereof',
  },
  {
    c: 'B01D 25',
    d: 'Filters formed by clamping together several filtering elements or parts of such elements',
  },
  {
    c: 'B01D 27',
    d: 'Cartridge filters of the throw-away type',
  },
  {
    c: 'B01D 29',
    d: 'Filters with filtering elements stationary during filtration, e.g. pressure or suction filters, not covered by groups B01D0024000000-B01D0027000000;   Filtering elements therefor',
  },
  {
    c: 'B01D 33',
    d: 'Filters with filtering elements which move during the filtering operation',
  },
  {
    c: 'B01D 35',
    d: 'Filtering devices having features not specifically covered by groups B01D0024000000-B01D0033000000, or for applications not specifically covered by groups B01D0024000000-B01D0033000000; Auxiliary devices for filtration; Filter housing constructions',
  },
  {
    c: 'B01D 36',
    d: 'Filter circuits or combinations of filters with other separating devices',
  },
  {
    c: 'B01D 37',
    d: 'Processes of filtration',
  },
  {
    c: 'B01D 39',
    d: 'Filtering material for liquid or gaseous fluids',
  },
  {
    c: 'B01D 41',
    d: 'Regeneration of the filtering material or filter elements outside the filter for liquid or gaseous fluids',
  },
  {
    c: 'B01D 43',
    d: 'Separating particles from liquids, or liquids from solids, otherwise than by sedimentation or filtration',
  },
  {
    c: 'B01D 45',
    d: 'Separating dispersed particles from gases or vapours',
  },
  {
    c: 'B01D 46',
    d: 'Filters or filtering processes specially modified for separating dispersed particles from gases or vapours',
  },
  {
    c: 'B01D 47',
    d: 'Separating dispersed particles from gases, air or vapours by liquid as separating agent',
  },
  {
    c: 'B01D 49',
    d: 'Separating dispersed particles from gases, air or vapours by other methods',
  },
  {
    c: 'B01D 50',
    d: 'Combinations of devices for separating particles from gases or vapours',
  },
  {
    c: 'B01D 51',
    d: 'Auxiliary pretreatment of gases or vapours to be cleaned from dispersed particles',
  },
  {
    c: 'B01D 53',
    d: 'Separation of gases or vapours; Recovering vapours of volatile solvents from gases; Chemical or biological purification of waste gases, e.g. engine exhaust gases, smoke, fumes, flue gases or aerosols',
  },
  {
    c: 'B01D 57',
    d: 'Separation, other than separation of solids, not fully covered by a single other group or subclass, e.g. B03C',
  },
  {
    c: 'B01D 59',
    d: 'Separation of different isotopes of the same chemical element',
  },
  {
    c: 'B01D 61',
    d: 'Processes of separation using semi-permeable membranes, e.g. dialysis, osmosis or ultrafiltration; Apparatus specially adapted therefor; Semi-permeable membranes or their production',
  },
  {
    c: 'B01D 63',
    d: 'Apparatus in general for separation processes using semi-permeable membranes',
  },
  {
    c: 'B01D 65',
    d: 'Accessories or auxiliary operations, in general, for separation processes or apparatus using semi-permeable membranes',
  },
  {
    c: 'B01D 67',
    d: 'Processes specially adapted for manufacturing semi-permeable membranes for separation processes or apparatus',
  },
  {
    c: 'B01D 69',
    d: 'Semi-permeable membranes for separation processes or apparatus characterised by their form, structure or properties; Manufacturing processes specially adapted therefor',
  },
  {
    c: 'B01D 71',
    d: 'Semi-permeable membranes for separation processes or apparatus characterised by the material; Manufacturing processes specially adapted therefor',
  },
  {
    c: 'B01F',
    d: 'MIXING, e.g. DISSOLVING, EMULSIFYING, DISPERSING',
  },
  {
    c: 'B01F 1',
    d: 'Dissolving',
  },
  {
    c: 'B01F 3',
    d: 'Mixing, e.g. dispersing, emulsifying, according to the phases to be mixed',
  },
  {
    c: 'B01F 5',
    d: 'Mixers',
  },
  {
    c: 'B01F 7',
    d: 'Mixers with rotary stirring devices in fixed receptacles; Kneaders',
  },
  {
    c: 'B01F 9',
    d: 'Mixers with rotating receptacles',
  },
  {
    c: 'B01F 11',
    d: 'Mixers with shaking, oscillating, or vibrating mechanisms',
  },
  {
    c: 'B01F 13',
    d: 'Other mixers; Mixing plant, including combinations of dissimilar mixers',
  },
  {
    c: 'B01F 15',
    d: 'Accessories for mixers',
  },
  {
    c: 'B01F 17',
    d: 'Use of substances as emulsifying, wetting, dispersing, or foam-producing agents',
  },
  {
    c: 'B01J',
    d: 'CHEMICAL OR PHYSICAL PROCESSES, e.g. CATALYSIS OR COLLOID CHEMISTRY; THEIR RELEVANT APPARATUS',
  },
  {
    c: 'B01J 2',
    d: 'Processes or devices for granulating materials, in general; Rendering particulate materials free flowing in general, e.g. making them hydrophobic',
  },
  {
    c: 'B01J 3',
    d: 'Processes of utilising sub-atmospheric or super-atmospheric pressure to effect chemical or physical change of matter; Apparatus therefor',
  },
  {
    c: 'B01J 4',
    d: 'Feed devices; Feed or outlet control devices',
  },
  {
    c: 'B01J 6',
    d: 'Calcining; Fusing',
  },
  {
    c: 'B01J 7',
    d: 'Apparatus for generating gases',
  },
  {
    c: 'B01J 8',
    d: 'Chemical or physical processes in general, conducted in the presence of fluids and solid particles; Apparatus for such processes',
  },
  {
    c: 'B01J 10',
    d: 'Chemical processes in general for reacting liquid with gaseous media other than in the presence of solid particles; Apparatus specially adapted therefor',
  },
  {
    c: 'B01J 12',
    d: 'Chemical processes in general for reacting gaseous media with gaseous media; Apparatus specially adapted therefor',
  },
  {
    c: 'B01J 13',
    d: 'Colloid chemistry, e.g. the production of colloidal materials or their solutions, not otherwise provided for; Making microcapsules or microballoons',
  },
  {
    c: 'B01J 14',
    d: 'Chemical processes in general for reacting liquids with liquids; Apparatus specially adapted therefor',
  },
  {
    c: 'B01J 15',
    d: 'Chemical processes in general for reacting gaseous media with non-particulate solids, e.g. sheet material; Apparatus specially adapted therefor',
  },
  {
    c: 'B01J 16',
    d: 'Chemical processes in general for reacting liquids with non-particulate solids, e.g. sheet material; Apparatus specially adapted therefor',
  },
  {
    c: 'B01J 19',
    d: 'Chemical, physical or physico-chemical processes in general; Their relevant apparatus',
  },
  {
    c: 'B01J 20',
    d: 'Solid sorbent compositions; Filter aid compositions; Sorbents for chromatography; Catalysts',
  },
  {
    c: 'B01J 21',
    d: 'Catalysts comprising the elements, oxides or hydroxides of magnesium, boron, aluminium, carbon, silicon, titanium, zirconium or hafnium',
  },
  {
    c: 'B01J 23',
    d: 'Catalysts comprising metals or metal oxides or hydroxides, not provided for in group B01J0021000000',
  },
  {
    c: 'B01J 25',
    d: 'Catalysts of the Raney type',
  },
  {
    c: 'B01J 27',
    d: 'Catalysts comprising the elements or compounds of halogens, sulfur, selenium, tellurium, phosphorus or nitrogen; Catalysts comprising carbon compounds',
  },
  {
    c: 'B01J 29',
    d: 'Catalysts comprising molecular sieves',
  },
  {
    c: 'B01J 31',
    d: 'Catalysts comprising hydrides, coordination complexes or organic compounds',
  },
  {
    c: 'B01J 32',
    d: 'Catalyst carriers in general',
  },
  {
    c: 'B01J 33',
    d: 'Protection of catalysts, e.g. by coating',
  },
  {
    c: 'B01J 35',
    d: 'Catalysts, in general, characterised by their form or physical properties',
  },
  {
    c: 'B01J 37',
    d: 'Processes, in general, for preparing catalysts; Processes, in general, for activation of catalysts',
  },
  {
    c: 'B01J 38',
    d: 'Regeneration or reactivation of catalysts, in general',
  },
  {
    c: 'B01J 39',
    d: 'Ion-exchange',
  },
  {
    c: 'B01J 41',
    d: 'Anion exchange; Use of material as anion exchangers; Treatment of material for improving the anion exchange properties',
  },
  {
    c: 'B01J 43',
    d: 'Amphoteric ion-exchange, i.e. using ion-exchangers having cationic and anionic groups; Use of material as amphoteric ion-exchangers; Treatment of material for improving their amphoteric ion-exchange properties',
  },
  {
    c: 'B01J 45',
    d: 'Ion-exchange in which a complex or a chelate is formed; Use of material as complex or chelate forming ion-exchangers; Treatment of material for improving the complex or chelate forming ion-exchange properties',
  },
  {
    c: 'B01J 47',
    d: 'Ion-exchange processes in general; Apparatus therefor',
  },
  {
    c: 'B01J 49',
    d: 'Regeneration or reactivation of ion-exchangers; Apparatus therefor',
  },
  {
    c: 'B01L',
    d: 'CHEMICAL OR PHYSICAL LABORATORY APPARATUS FOR GENERAL USE',
  },
  {
    c: 'B01L 1',
    d: 'Enclosures; Chambers',
  },
  {
    c: 'B01L 3',
    d: 'Containers or dishes for laboratory use, e.g. laboratory glassware',
  },
  {
    c: 'B01L 5',
    d: 'Gas handling apparatus',
  },
  {
    c: 'B01L 7',
    d: 'Heating or cooling apparatus',
  },
  {
    c: 'B01L 9',
    d: 'Supporting devices; Holding devices',
  },
  {
    c: 'B01L 99',
    d: 'n.a.',
  },
  {
    c: 'B02',
    d: 'CRUSHING, PULVERISING, OR DISINTEGRATING; PREPARATORY TREATMENT OF GRAIN FOR MILLING',
  },
  {
    c: 'B02B',
    d: 'PREPARING GRAIN FOR MILLING; REFINING GRANULAR FRUIT TO COMMERCIAL PRODUCTS BY WORKING THE SURFACE',
  },
  {
    c: 'B02B 1',
    d: 'Preparing grain for milling or like processes',
  },
  {
    c: 'B02B 3',
    d: 'Hulling; Husking; Decorticating',
  },
  {
    c: 'B02B 5',
    d: 'Grain treatment not otherwise provided for',
  },
  {
    c: 'B02B 7',
    d: 'Auxiliary devices',
  },
  {
    c: 'B02C',
    d: 'CRUSHING, PULVERISING, OR DISINTEGRATING IN GENERAL; MILLING GRAIN',
  },
  {
    c: 'B02C 1',
    d: 'Crushing or disintegrating by reciprocating members',
  },
  {
    c: 'B02C 2',
    d: 'Crushing or disintegrating by gyratory or cone crushers',
  },
  {
    c: 'B02C 4',
    d: 'Crushing or disintegrating by roller mills',
  },
  {
    c: 'B02C 7',
    d: 'Crushing or disintegrating by disc mills',
  },
  {
    c: 'B02C 9',
    d: 'Other milling methods or mills specially adapted for grain',
  },
  {
    c: 'B02C 11',
    d: 'Other auxiliary devices or accessories specially adapted for grain mills',
  },
  {
    c: 'B02C 13',
    d: 'Disintegrating by mills having rotary beater elements',
  },
  {
    c: 'B02C 15',
    d: 'Disintegrating by milling members in the form of rollers or balls co-operating with rings or discs',
  },
  {
    c: 'B02C 17',
    d: 'Disintegrating by tumbling mills, i.e. mills having a container charged with the material to be disintegrated with or without special disintegrating members such as pebbles or balls',
  },
  {
    c: 'B02C 18',
    d: 'Disintegrating by knives or other cutting or tearing members which chop material into fragments',
  },
  {
    c: 'B02C 19',
    d: 'Other disintegrating devices or methods',
  },
  {
    c: 'B02C 21',
    d: 'Disintegrating plant with or without drying of the material',
  },
  {
    c: 'B02C 23',
    d: 'Auxiliary methods or auxiliary devices or accessories specially adapted for crushing or disintegrating not provided for in groups B02C0001000000-B02C0021000000 or not specially adapted to apparatus covered by one only of groups B02C0001000000-B02C0021000000',
  },
  {
    c: 'B02C 25',
    d: 'Control arrangements specially adapted for crushing or disintegrating',
  },
  {
    c: 'B03',
    d: 'SEPARATION OF SOLID MATERIALS USING LIQUIDS OR USING PNEUMATIC TABLES OR JIGS; MAGNETIC OR ELECTROSTATIC SEPARATION OF SOLID MATERIALS FROM SOLID MATERIALS OR FLUIDS; SEPARATION BY HIGH-VOLTAGE ELECTRIC FIELDS',
  },
  {
    c: 'B03B',
    d: 'SEPARATING SOLID MATERIALS USING LIQUIDS OR USING PNEUMATIC TABLES OR JIGS',
  },
  {
    c: 'B03B 1',
    d: 'Conditioning for facilitating separation by altering physical properties of the matter to be treated',
  },
  {
    c: 'B03B 4',
    d: 'Separating by pneumatic tables or by pneumatic jigs',
  },
  {
    c: 'B03B 5',
    d: 'Washing granular, powdered or lumpy materials; Wet separating',
  },
  {
    c: 'B03B 7',
    d: 'Combinations of wet processes or apparatus with other processes or apparatus, e.g. for dressing ores or garbage',
  },
  {
    c: 'B03B 9',
    d: 'General arrangement of separating plant, e.g. flow sheets',
  },
  {
    c: 'B03B 11',
    d: 'Feed or discharge devices integral with washing or wet-separating equipment',
  },
  {
    c: 'B03B 13',
    d: 'Control arrangements specially adapted for wet- separating apparatus or for dressing plant, using physical effects',
  },
  {
    c: 'B03C',
    d: 'MAGNETIC OR ELECTROSTATIC SEPARATION OF SOLID MATERIALS FROM SOLID MATERIALS OR FLUIDS; SEPARATION BY HIGH-VOLTAGE ELECTRIC FIELDS',
  },
  {
    c: 'B03C 1',
    d: 'Magnetic separation',
  },
  {
    c: 'B03C 3',
    d: 'Separating dispersed particles from gases or vapour, e.g. air, by electrostatic effect',
  },
  {
    c: 'B03C 5',
    d: 'Separating dispersed particles from liquids by electrostatic effect',
  },
  {
    c: 'B03C 7',
    d: 'Separating solids from solids by electrostatic effect',
  },
  {
    c: 'B03C 9',
    d: 'Electrostatic separation not provided for in any single one of the other main groups of this subclass',
  },
  {
    c: 'B03C 11',
    d: 'Separation by high-voltage electrical fields, not provided for in other groups of this subclass',
  },
  {
    c: 'B03D',
    d: 'FLOTATION; DIFFERENTIAL SEDIMENTATION',
  },
  {
    c: 'B03D 1',
    d: 'Flotation',
  },
  {
    c: 'B03D 3',
    d: 'Differential sedimentation',
  },
  {
    c: 'B03D 101',
    d: 'Indexing scheme associated with group B03D0001001000, relating to the effects produced and the materials treated.',
  },
  {
    c: 'B03D 103',
    d: 'Specified materials treated by the flotation agents',
  },
  {
    c: 'B04',
    d: 'CENTRIFUGAL APPARATUS OR MACHINES FOR CARRYING-OUT PHYSICAL OR CHEMICAL PROCESSES',
  },
  {
    c: 'B04B',
    d: 'CENTRIFUGES',
  },
  {
    c: 'B04B 1',
    d: 'Types of centrifuges; Centrifuges characterised by discharging means',
  },
  {
    c: 'B04B 3',
    d: 'Centrifuges with rotary bowls in which solid particles or bodies become separated by centrifugal force and simultaneously sifting or filtering',
  },
  {
    c: 'B04B 5',
    d: 'Other centrifuges',
  },
  {
    c: 'B04B 7',
    d: 'Elements; Accessories',
  },
  {
    c: 'B04B 9',
    d: 'Drives specially designed for centrifuges; Arrangement or disposition of transmission gearing; Suspending or balancing rotary bowls',
  },
  {
    c: 'B04B 11',
    d: 'Feeding, charging, or discharging bowls',
  },
  {
    c: 'B04B 13',
    d: 'Control arrangements specially designed for centrifuges; Programme control of centrifuges',
  },
  {
    c: 'B04B 15',
    d: 'Other accessories for centrifuges',
  },
  {
    c: 'B04C',
    d: 'APPARATUS USING FREE VORTEX FLOW, e.g. CYCLONES',
  },
  {
    c: 'B04C 1',
    d: 'Apparatus in which the main direction of flow follows a flat spiral',
  },
  {
    c: 'B04C 3',
    d: 'Apparatus in which the axial direction of the vortex remains unchanged',
  },
  {
    c: 'B04C 5',
    d: 'Apparatus in which the axial direction of the vortex is reversed',
  },
  {
    c: 'B04C 7',
    d: 'Apparatus not provided for in group B04C0001000000, B04C0003000000 or B04C0005000000; Multiple arrangements not provided for in one of the groups B04C0001000000, B04C0003000000, or B04C0005000000; Combinations of apparatus covered by two or more of the groups B04C0001000000, B04C0003000000, or B04C0005000000',
  },
  {
    c: 'B04C 9',
    d: 'Combinations with other devices, e.g. fans',
  },
  {
    c: 'B04C 11',
    d: 'Accessories, e.g. safety or control devices, not otherwise provided for',
  },
  {
    c: 'B05',
    d: 'SPRAYING OR ATOMISING IN GENERAL; APPLYING LIQUIDS OR OTHER FLUENT MATERIALS TO SURFACES, IN GENERAL',
  },
  {
    c: 'B05B',
    d: 'SPRAYING APPARATUS; ATOMISING APPARATUS; NOZZLES',
  },
  {
    c: 'B05B 1',
    d: 'Nozzles, spray heads or other outlets, with or without auxiliary devices such as valves, heating means',
  },
  {
    c: 'B05B 3',
    d: 'Spraying or sprinkling apparatus with moving outlet elements or moving deflecting elements',
  },
  {
    c: 'B05B 5',
    d: 'Electrostatic spraying apparatus; Spraying apparatus with means for charging the spray electrically; Apparatus for spraying liquids or other fluent materials by other electric means',
  },
  {
    c: 'B05B 7',
    d: 'Spraying apparatus for discharge of liquids or other fluent materials from two or more sources, e.g. of liquid and air, of powder and gas',
  },
  {
    c: 'B05B 9',
    d: 'Spraying apparatus for discharge of liquid or other fluent material without essentially mixing with gas or vapour',
  },
  {
    c: 'B05B 11',
    d: 'Single-unit, i.e. unitary, hand-held apparatus in which flow of liquid or other fluent material is produced by the operator at the moment of use',
  },
  {
    c: 'B05B 12',
    d: 'Arrangements for controlling delivery; Arrangements for controlling the spray area',
  },
  {
    c: 'B05B 13',
    d: 'Machines or plants for applying liquids or other fluent materials to surfaces of objects or other work by spraying, not covered by groups B05B0001000000-B05B0011000000',
  },
  {
    c: 'B05B 14',
    d: 'Arrangements for collecting, re-using or eliminating excess spraying material',
  },
  {
    c: 'B05B 15',
    d: 'Details of spraying plant or spraying apparatus not otherwise provided for; Accessories',
  },
  {
    c: 'B05B 16',
    d: 'Spray booths',
  },
  {
    c: 'B05B 17',
    d: 'Apparatus for spraying or atomising liquids or other fluent materials, not covered by any other group of this subclass',
  },
  {
    c: 'B05C',
    d: 'APPARATUS FOR APPLYING LIQUIDS OR OTHER FLUENT MATERIALS TO SURFACES, IN GENERAL',
  },
  {
    c: 'B05C 1',
    d: 'Apparatus in which liquid or other fluent material is applied to the surface of the work by contact with a member carrying the liquid or other fluent material, e.g. a porous member loaded with a liquid to be applied as a coating',
  },
  {
    c: 'B05C 3',
    d: 'Apparatus in which the work is brought into contact with a bulk quantity of liquid or other fluent material',
  },
  {
    c: 'B05C 5',
    d: 'Apparatus in which liquid or other fluent material is projected, poured or allowed to flow on to the surface of the work',
  },
  {
    c: 'B05C 7',
    d: 'Apparatus specially designed for applying liquid or other fluent material to the inside of hollow work',
  },
  {
    c: 'B05C 9',
    d: 'Apparatus or plant for applying liquid or other fluent material to surfaces by means not covered by groups B05C0001000000-B05C0007000000, or in which the means of applying the liquid or other fluent material is not important',
  },
  {
    c: 'B05C 11',
    d: 'Component parts, details or accessories not specifically provided for in groups B05C0001000000-B05C0009000000',
  },
  {
    c: 'B05C 13',
    d: 'Means for manipulating or holding work, e.g. for separate articles',
  },
  {
    c: 'B05C 15',
    d: 'Enclosures for apparatus; Booths',
  },
  {
    c: 'B05C 17',
    d: 'Hand tools or apparatus using hand-held tools, for applying liquids or other fluent materials to, for spreading applied liquids or other fluent materials on, or for partially removing applied liquids or other fluent materials from, surfaces',
  },
  {
    c: 'B05C 19',
    d: 'Apparatus specially adapted for applying particulate materials to surfaces',
  },
  {
    c: 'B05C 21',
    d: 'Accessories or implements for use in connection with applying liquids or other fluent materials to surfaces, not provided for in groups B05C0001000000-B05C0019000000',
  },
  {
    c: 'B05D',
    d: 'PROCESSES FOR APPLYING LIQUIDS OR OTHER FLUENT MATERIALS TO SURFACES, IN GENERAL',
  },
  {
    c: 'B05D 1',
    d: 'Processes for applying liquids or other fluent materials',
  },
  {
    c: 'B05D 3',
    d: 'Pretreatment of surfaces to which liquids or other fluent materials are to be applied; After-treatment of applied coatings, e.g. intermediate treating of an applied coating preparatory to subsequent applications of liquids or other fluent materials',
  },
  {
    c: 'B05D 5',
    d: 'Processes for applying liquids or other fluent materials to surfaces to obtain special surface effects, finishes or structures',
  },
  {
    c: 'B05D 7',
    d: 'Processes, other than flocking, specially adapted for applying liquids or other fluent materials to particular surfaces or for applying particular liquids or other fluent materials',
  },
  {
    c: 'B06',
    d: 'GENERATING OR TRANSMITTING MECHANICAL VIBRATIONS IN GENERAL',
  },
  {
    c: 'B06B',
    d: 'GENERATING OR TRANSMITTING MECHANICAL VIBRATIONS IN GENERAL',
  },
  {
    c: 'B06B 1',
    d: 'Processes or apparatus for generating mechanical vibrations of infrasonic, sonic or ultrasonic frequency',
  },
  {
    c: 'B06B 3',
    d: 'Processes or apparatus specially adapted for transmitting mechanical vibrations of infrasonic, sonic or ultrasonic frequency',
  },
  {
    c: 'B07',
    d: 'SEPARATING SOLIDS FROM SOLIDS; SORTING',
  },
  {
    c: 'B07B',
    d: 'SEPARATING SOLIDS FROM SOLIDS BY SIEVING, SCREENING, OR SIFTING OR BY USING GAS CURRENTS; OTHER SEPARATING BY DRY METHODS APPLICABLE TO BULK MATERIAL, e.g. LOOSE ARTICLES FIT TO BE HANDLED LIKE BULK MATERIAL',
  },
  {
    c: 'B07B 1',
    d: 'Sieving, screening, sifting, or sorting solid materials using networks, gratings, grids, or the like',
  },
  {
    c: 'B07B 4',
    d: 'Separating solids from solids using gas currents',
  },
  {
    c: 'B07B 7',
    d: 'Selective separation of solid materials carried by, or dispersed in, gas currents',
  },
  {
    c: 'B07B 9',
    d: 'Combinations of apparatus for screening or sifting or for separating solids from solids using gas currents; General arrangement of plant, e.g. flow sheets',
  },
  {
    c: 'B07B 11',
    d: 'Arrangement of accessories in apparatus for separating solids from solids using gas currents',
  },
  {
    c: 'B07B 13',
    d: 'Other separating, e.g. grading, resulting automatically from the construction of the apparatus used and properties of the material concerned; Combinations',
  },
  {
    c: 'B07B 15',
    d: 'Combinations of apparatus for separating solids from solids by dry methods applicable to bulk material, e.g. loose articles fit to be handled like bulk material',
  },
  {
    c: 'B07C',
    d: 'POSTAL SORTING; SORTING INDIVIDUAL ARTICLES, OR BULK MATERIAL FIT TO BE SORTED PIECE-MEAL, e.g. BY PICKING',
  },
  {
    c: 'B07C 1',
    d: 'Postal sorting; Similar sorting of documents, e.g. cheques',
  },
  {
    c: 'B07C 3',
    d: 'Sorting of mail or documents according to destination',
  },
  {
    c: 'B07C 5',
    d: 'Sorting according to a characteristic or feature of the articles or material being sorted, e.g. by control effected by devices which detect or measure such characteristic or feature; Sorting by manually actuated devices, e.g. switches',
  },
  {
    c: 'B07C 7',
    d: 'Sorting by hand only',
  },
  {
    c: 'B07C 99',
    d: 'n.a.',
  },
  {
    c: 'B08',
    d: 'CLEANING',
  },
  {
    c: 'B08B',
    d: 'CLEANING IN GENERAL; PREVENTION OF FOULING IN GENERAL',
  },
  {
    c: 'B08B 1',
    d: 'Cleaning by methods involving the use of tools, brushes, or analogous members',
  },
  {
    c: 'B08B 3',
    d: 'Cleaning by methods involving the use or presence of liquid or steam',
  },
  {
    c: 'B08B 5',
    d: 'Cleaning by methods involving the use of air flow or gas flow',
  },
  {
    c: 'B08B 6',
    d: 'Cleaning by electrostatic means',
  },
  {
    c: 'B08B 7',
    d: 'Cleaning by methods not provided for in a single other subclass or a single group in this subclass',
  },
  {
    c: 'B08B 9',
    d: 'Cleaning hollow articles by methods or apparatus specially adapted thereto',
  },
  {
    c: 'B08B 11',
    d: 'Cleaning flexible or delicate articles by methods or apparatus specially adapted thereto',
  },
  {
    c: 'B08B 13',
    d: 'Accessories or details of general applicability for machines or apparatus for cleaning',
  },
  {
    c: 'B08B 15',
    d: 'Preventing escape of dirt or fumes from the area where they are produced; Collecting or removing dirt or fumes from that area',
  },
  {
    c: 'B08B 17',
    d: 'Methods preventing fouling',
  },
  {
    c: 'B09',
    d: 'DISPOSAL OF SOLID WASTE; RECLAMATION OF CONTAMINATED SOIL',
  },
  {
    c: 'B09B',
    d: 'DISPOSAL OF SOLID WASTE',
  },
  {
    c: 'B09B 1',
    d: 'Dumping solid waste',
  },
  {
    c: 'B09B 3',
    d: 'Destroying solid waste or transforming solid waste into something useful or harmless',
  },
  {
    c: 'B09B 5',
    d: 'Operations not covered by a single other subclass or by a single other group in this subclass',
  },
  {
    c: 'B09C',
    d: 'RECLAMATION OF CONTAMINATED SOIL',
  },
  {
    c: 'B09C 1',
    d: 'Reclamation of contaminated soil',
  },
  {
    c: 'B21',
    d: 'MECHANICAL METAL-WORKING WITHOUT ESSENTIALLY REMOVING MATERIAL; PUNCHING METAL',
  },
  {
    c: 'B21B',
    d: 'ROLLING OF METAL',
  },
  {
    c: 'B21B 1',
    d: 'Metal rolling methods or mills for making semi-finished products of solid or profiled cross-section',
  },
  {
    c: 'B21B 3',
    d: 'Rolling materials of special alloys so far as the composition of the alloy requires or permits special rolling methods or sequences',
  },
  {
    c: 'B21B 5',
    d: 'Extending closed shapes of metal bands by rolling',
  },
  {
    c: 'B21B 9',
    d: 'Measures for carrying out rolling operations under special conditions, e.g. in vacuum or inert atmosphere to prevent oxidation of work; Special measures for removing fumes from rolling mills',
  },
  {
    c: 'B21B 11',
    d: 'Subsidising the rolling processes by subjecting rollers or work to vibrations',
  },
  {
    c: 'B21B 13',
    d: 'Metal-rolling stands, i.e. an assembly composed of a stand frame, rolls, and accessories',
  },
  {
    c: 'B21B 15',
    d: 'Arrangements for performing additional metal-working operations specially combined with or arranged in, or specially adapted for use in connection with, metal-rolling mills',
  },
  {
    c: 'B21B 17',
    d: 'Rolling methods or mills specially designed for making or processing tubes',
  },
  {
    c: 'B21B 19',
    d: 'Tube-rolling by rollers arranged outside the work and having their axes not perpendicular to the axis of the work',
  },
  {
    c: 'B21B 21',
    d: 'Pilgrim-step tube-rolling',
  },
  {
    c: 'B21B 23',
    d: 'Tube-rolling not restricted to methods provided for in only one of groups B21B0017000000-B21B0021000000, e.g. combined processes',
  },
  {
    c: 'B21B 25',
    d: 'Mandrels for metal tube rolling mills, e.g. mandrels of the types used in the methods covered by group B21B0017000000; Accessories or auxiliary means therefor',
  },
  {
    c: 'B21B 27',
    d: 'Rolls',
  },
  {
    c: 'B21B 28',
    d: 'Maintaining rolls or rolling equipment in effective condition',
  },
  {
    c: 'B21B 29',
    d: 'Counter-pressure devices acting on rolls to inhibit deflection of same under load, e.g. backing rolls',
  },
  {
    c: 'B21B 31',
    d: 'Rolling stand structures; Mounting, adjusting, or interchanging rolls, roll mountings, or stand frames',
  },
  {
    c: 'B21B 33',
    d: 'Safety devices not otherwise provided for',
  },
  {
    c: 'B21B 35',
    d: 'Drives for metal-rolling mills',
  },
  {
    c: 'B21B 37',
    d: 'Control devices or methods specially adapted for metal-rolling mills or the work produced thereby',
  },
  {
    c: 'B21B 38',
    d: 'Methods or devices for measuring specially adapted for metal-rolling mills, e.g. position detection, inspection of the product',
  },
  {
    c: 'B21B 39',
    d: 'Arrangements for moving, supporting, or positioning work, or controlling its movement, combined with or arranged in, or specially adapted for use in connection with, metal-rolling mills',
  },
  {
    c: 'B21B 41',
    d: 'Guiding, conveying, or accumulating easily-flexible work, e.g. wire, sheet metal bands, in loops or curves; Loop lifters',
  },
  {
    c: 'B21B 43',
    d: 'Cooling beds, whether stationary or moving; Means specially associated with cooling beds, e.g. for braking work or for transferring it to or from the bed',
  },
  {
    c: 'B21B 45',
    d: 'Devices for surface treatment of work, specially combined with or arranged in, or specially adapted for use in connection with, metal-rolling mills',
  },
  {
    c: 'B21B 47',
    d: 'Auxiliary arrangements, devices or methods in connection with rolling of multi-layer sheets of metal',
  },
  {
    c: 'B21B 99',
    d: 'n.a.',
  },
  {
    c: 'B21C',
    d: 'MANUFACTURE OF METAL SHEETS, WIRE, RODS, TUBES, PROFILES OR LIKE SEMI-MANUFACTURED PRODUCTS OTHERWISE THAN BY ROLLING; AUXILIARY OPERATIONS USED IN CONNECTION WITH METAL-WORKING WITHOUT ESSENTIALLY REMOVING MATERIAL',
  },
  {
    c: 'B21C 1',
    d: 'Metal drawing',
  },
  {
    c: 'B21C 3',
    d: 'Profiling tools for metal drawing; Combinations of dies and mandrels for metal drawing',
  },
  {
    c: 'B21C 5',
    d: 'Pointing or push-pointing drawn work or drawing material',
  },
  {
    c: 'B21C 9',
    d: 'Cooling, heating or lubricating drawing material',
  },
  {
    c: 'B21C 19',
    d: 'Devices for straightening wire or like work combined with or specially adapted for use in connection with drawing or winding machines or apparatus',
  },
  {
    c: 'B21C 23',
    d: 'Metal extruding',
  },
  {
    c: 'B21C 25',
    d: 'Profiling tools for metal extruding',
  },
  {
    c: 'B21C 26',
    d: 'Rams or plungers for metal extruding; Discs therefor',
  },
  {
    c: 'B21C 27',
    d: 'Containers for metal to be extruded',
  },
  {
    c: 'B21C 29',
    d: 'Cooling or heating extruded work or parts of the extrusion press',
  },
  {
    c: 'B21C 31',
    d: 'Control devices for metal extruding, e.g. for regulating the pressing speed or temperature of metal',
  },
  {
    c: 'B21C 33',
    d: 'Feeding extrusion presses with metal to be extruded',
  },
  {
    c: 'B21C 35',
    d: 'Removing work or waste from extruding presses; Drawing-off extruded work',
  },
  {
    c: 'B21C 37',
    d: 'Manufacture of metal sheets, rods, wire, tubes, profiles or like semi-manufactured products, not otherwise provided for',
  },
  {
    c: 'B21C 43',
    d: 'Devices for cleaning metal products combined with or specially adapted for use with machines or apparatus provided for in this subclass',
  },
  {
    c: 'B21C 45',
    d: 'Auxiliary operations used in connection with metal working without essentially removing material',
  },
  {
    c: 'B21C 47',
    d: 'Winding-up, coiling or winding-off metal wire, metal band or other flexible metal material characterised by features relevant to metal processing only',
  },
  {
    c: 'B21C 49',
    d: 'Devices for temporarily accumulating material',
  },
  {
    c: 'B21C 51',
    d: 'Measuring, gauging, indicating, counting, or marking devices specially adapted for use in the production or manipulation of material in accordance with subclasses B21B-B21F',
  },
  {
    c: 'B21C 99',
    d: 'n.a.',
  },
  {
    c: 'B21D',
    d: 'WORKING OR PROCESSING OF SHEET METAL OR METAL TUBES, RODS OR PROFILES WITHOUT ESSENTIALLY REMOVING MATERIAL; PUNCHING METAL',
  },
  {
    c: 'B21D 1',
    d: 'Straightening; Restoring form; Bending; Corrugating; Flanging',
  },
  {
    c: 'B21D 3',
    d: 'Straightening or restoring form of metal rods, metal tubes, metal profiles, or specific articles made therefrom, whether or not in combination with sheet metal parts',
  },
  {
    c: 'B21D 5',
    d: 'Bending sheet metal along straight lines, e.g. to form simple curves',
  },
  {
    c: 'B21D 7',
    d: 'Bending rods, profiles, or tubes',
  },
  {
    c: 'B21D 9',
    d: 'Bending tubes using mandrels or the like',
  },
  {
    c: 'B21D 11',
    d: 'Bending not restricted to forms of material mentioned in only one of groups B21D0005000000, B21D0007000000, B21D0009000000; Bending not provided for in groups B21D0005000000-B21D0009000000; Twisting',
  },
  {
    c: 'B21D 13',
    d: 'Corrugating sheet metal, rods or profiles; Bending sheet metal, rods or profiles into wave form',
  },
  {
    c: 'B21D 15',
    d: 'Corrugating tubes',
  },
  {
    c: 'B21D 17',
    d: 'Forming single grooves in sheet metal or tubular or hollow articles',
  },
  {
    c: 'B21D 19',
    d: 'Flanging or other edge treatment, e.g. of tubes',
  },
  {
    c: 'B21D 21',
    d: 'Combined processes according to methods covered by groups B21D0001000000-B21D0019000000',
  },
  {
    c: 'B21D 22',
    d: 'Stamping; Spinning; Deep-drawing; Working sheet metal of limited length by stretching; Punching',
  },
  {
    c: 'B21D 24',
    d: 'Special deep-drawing arrangements in, or in connection with, presses',
  },
  {
    c: 'B21D 25',
    d: 'Working sheet metal of limited length by stretching, e.g. for straightening',
  },
  {
    c: 'B21D 26',
    d: 'Shaping without cutting otherwise than by using rigid devices or tools or yieldable or resilient pads, e.g. shaping by applying fluid pressure or magnetic forces',
  },
  {
    c: 'B21D 28',
    d: 'Shaping by press-cutting; Perforating',
  },
  {
    c: 'B21D 31',
    d: 'Other methods for working sheet metal, metal tubes, metal profiles',
  },
  {
    c: 'B21D 33',
    d: 'Special measures in connection with working metal foils, e.g. gold foils',
  },
  {
    c: 'B21D 35',
    d: 'Combined processes according to methods covered by groups B21D0001000000-B21D0031000000',
  },
  {
    c: 'B21D 37',
    d: 'Tools as parts of machines covered by this subclass',
  },
  {
    c: 'B21D 39',
    d: 'Application of procedures in order to connect objects or parts, e.g. coating with sheet metal otherwise than by plating',
  },
  {
    c: 'B21D 41',
    d: 'Application of procedures in order to alter the diameter of tube ends',
  },
  {
    c: 'B21D 43',
    d: 'Feeding, positioning or storing devices combined with, or arranged in, or specially adapted for use in connection with, apparatus for working or processing sheet metal, metal tubes or metal profiles; Associations therewith of cutting devices',
  },
  {
    c: 'B21D 45',
    d: 'Ejecting or stripping-off devices arranged in machines or tools dealt with in this subclass',
  },
  {
    c: 'B21D 47',
    d: 'Processing sheet metal or metal tubes, or processing metal profiles according to any of groups B21D0001000000-B21D0045000000, in the manufacture of finished or semi-finished articles',
  },
  {
    c: 'B21D 49',
    d: 'Sheathing or stiffening objects',
  },
  {
    c: 'B21D 51',
    d: 'Making hollow objects',
  },
  {
    c: 'B21D 53',
    d: 'Making other particular articles',
  },
  {
    c: 'B21D 55',
    d: 'Safety devices protecting the machine or the operator, specially adapted for apparatus or machines dealt with in this subclass',
  },
  {
    c: 'B21F',
    d: 'WORKING OR PROCESSING OF METAL WIRE',
  },
  {
    c: 'B21F 1',
    d: 'Wire working characterised by operations performed',
  },
  {
    c: 'B21F 3',
    d: 'Coiling wire into particular forms',
  },
  {
    c: 'B21F 5',
    d: 'Upsetting wire',
  },
  {
    c: 'B21F 7',
    d: 'Twisting wire; Twisting wire together',
  },
  {
    c: 'B21F 9',
    d: 'Straining wire',
  },
  {
    c: 'B21F 11',
    d: 'Cutting wire',
  },
  {
    c: 'B21F 13',
    d: 'Splitting wire',
  },
  {
    c: 'B21F 15',
    d: 'Connecting wire to wire or other metallic material or objects; Connecting parts by means of wire',
  },
  {
    c: 'B21F 17',
    d: 'Jacketing or reinforcing articles with wire',
  },
  {
    c: 'B21F 19',
    d: 'Metallic coating of wire',
  },
  {
    c: 'B21F 23',
    d: 'Feeding wire in wire-working machines or apparatus',
  },
  {
    c: 'B21F 25',
    d: 'Wire working characterised by the particular articles produced',
  },
  {
    c: 'B21F 27',
    d: 'Making wire network, i.e. wire nets',
  },
  {
    c: 'B21F 29',
    d: 'Making fencing or like material made partly of wire',
  },
  {
    c: 'B21F 31',
    d: 'Making meshed-ring network from wire',
  },
  {
    c: 'B21F 33',
    d: 'Tools or devices specially designed for handling or processing wire fabrics or the like',
  },
  {
    c: 'B21F 35',
    d: 'Making springs from wire',
  },
  {
    c: 'B21F 37',
    d: 'Manufacture of rings from wire',
  },
  {
    c: 'B21F 39',
    d: 'Making wheel spokes from wire',
  },
  {
    c: 'B21F 41',
    d: 'Making umbrella frames or members from wire',
  },
  {
    c: 'B21F 43',
    d: 'Making bands, e.g. bracelets, or wire',
  },
  {
    c: 'B21F 45',
    d: 'Wire-working in the manufacture of other particular articles',
  },
  {
    c: 'B21F 99',
    d: 'n.a.',
  },
  {
    c: 'B21G',
    d: 'MAKING NEEDLES, PINS OR NAILS OF METAL',
  },
  {
    c: 'B21G 1',
    d: 'Making needles used for performing operations',
  },
  {
    c: 'B21G 3',
    d: 'Making pins, nails, or the like',
  },
  {
    c: 'B21G 5',
    d: 'Making pins or nails with attached caps or with coated heads',
  },
  {
    c: 'B21G 7',
    d: 'Making pins of U-like shape or split-pins',
  },
  {
    c: 'B21H',
    d: 'MAKING PARTICULAR METAL OBJECTS BY ROLLING, e.g. SCREWS, WHEELS, RINGS, BARRELS, BALLS',
  },
  {
    c: 'B21H 1',
    d: 'Making articles shaped as bodies of revolution',
  },
  {
    c: 'B21H 3',
    d: 'Making helical bodies or bodies having parts of helical shape',
  },
  {
    c: 'B21H 5',
    d: 'Making gear wheels',
  },
  {
    c: 'B21H 7',
    d: 'Making articles not provided for in groups B21H0001000000-B21H0005000000, e.g. agricultural tools, dinner forks, knives, spoons',
  },
  {
    c: 'B21H 8',
    d: 'Rolling metal of indefinite length in repetitive shapes specially designed for the manufacture of particular objects',
  },
  {
    c: 'B21H 9',
    d: 'Feeding arrangements for rolling machines or apparatus manufacturing articles dealt with in this subclass',
  },
  {
    c: 'B21J',
    d: 'FORGING; HAMMERING; PRESSING METAL; RIVETING; FORGE FURNACES',
  },
  {
    c: 'B21J 1',
    d: 'Preparing metal stock',
  },
  {
    c: 'B21J 3',
    d: 'Lubricating during forging or pressing',
  },
  {
    c: 'B21J 5',
    d: 'Methods for forging, hammering, or pressing',
  },
  {
    c: 'B21J 7',
    d: 'Hammers; Forging machines with hammers or die jaws acting by impact',
  },
  {
    c: 'B21J 9',
    d: 'Forging presses',
  },
  {
    c: 'B21J 11',
    d: 'Forging hammers combined with forging presses; Forging machines with provision for hammering and pressing',
  },
  {
    c: 'B21J 13',
    d: 'Details of machines for forging, pressing, or hammering',
  },
  {
    c: 'B21J 15',
    d: 'Riveting',
  },
  {
    c: 'B21J 17',
    d: 'Forge furnaces',
  },
  {
    c: 'B21J 19',
    d: "Blacksmiths' requisites not otherwise provided for",
  },
  {
    c: 'B21K',
    d: 'MAKING FORGED OR PRESSED METAL PRODUCTS, e.g. HORSE-SHOES, RIVETS, BOLTS OR WHEELS',
  },
  {
    c: 'B21K 1',
    d: 'Making particular articles by forging, pressing, or hammering; Equipment therefor',
  },
  {
    c: 'B21K 3',
    d: 'Making engine or like machine parts not covered by B21K0001000000; Making propellers or the like',
  },
  {
    c: 'B21K 5',
    d: 'Making tools or tool parts, e.g. pliers',
  },
  {
    c: 'B21K 7',
    d: 'Making railway appurtenances; Making vehicle parts',
  },
  {
    c: 'B21K 9',
    d: 'Reconditioning railroad accessories, e.g. rails',
  },
  {
    c: 'B21K 11',
    d: 'Making cutlery wares; Making garden tools or the like',
  },
  {
    c: 'B21K 13',
    d: "Making locksmiths' goods, e.g. handles for cases",
  },
  {
    c: 'B21K 15',
    d: "Making blacksmiths' goods",
  },
  {
    c: 'B21K 17',
    d: 'Making sport articles, e.g. skates',
  },
  {
    c: 'B21K 19',
    d: 'Making articles for agricultural machinery',
  },
  {
    c: 'B21K 21',
    d: 'Making hollow articles not covered by any single one of groups B21K0001000000-B21K0019000000',
  },
  {
    c: 'B21K 23',
    d: 'Making other articles',
  },
  {
    c: 'B21K 25',
    d: 'Uniting components to form integral members, e.g. turbine wheels and shafts, caulks with inserts, with or without shaping of the components',
  },
  {
    c: 'B21K 27',
    d: 'Accessories; Auxiliary devices',
  },
  {
    c: 'B21K 29',
    d: 'Arrangements for heating or cooling during processing',
  },
  {
    c: 'B21K 31',
    d: 'Control devices specially adapted for positioning tool carriers',
  },
  {
    c: 'B21L',
    d: 'MAKING METAL CHAINS',
  },
  {
    c: 'B21L 1',
    d: 'Making chains or chain links by bending workpieces of rod, wire, or strip to form links of oval or other simple shape',
  },
  {
    c: 'B21L 3',
    d: 'Making chains or chain links by bending the chain links or link parts and subsequently welding or soldering the abutting ends',
  },
  {
    c: 'B21L 5',
    d: 'Making chains or chain links by working the starting material in such a way that integral, i.e. jointless, chain links are formed',
  },
  {
    c: 'B21L 7',
    d: 'Making chains or chain links by cutting single loops or loop-parts from coils, assembling the cut parts and subsequently subjecting same to twisting with or without welding',
  },
  {
    c: 'B21L 9',
    d: 'Making chains or chain links, the links being composed of two or more different parts, e.g. drive chains',
  },
  {
    c: 'B21L 11',
    d: 'Making chains or chain links of special shape',
  },
  {
    c: 'B21L 13',
    d: 'Making terminal or intermediate chain links of special shape; Making couplings for chains, e.g. swivels, shackles',
  },
  {
    c: 'B21L 15',
    d: 'Finishing or dressing chains or chain links, e.g. removing burr material, calibrating',
  },
  {
    c: 'B21L 19',
    d: 'Appurtenances for chain-making not restricted to any particular process',
  },
  {
    c: 'B21L 21',
    d: 'Tools or implements for repairing chains using metal-working operations, e.g. for detaching deformed chain links',
  },
  {
    c: 'B21L 99',
    d: 'n.a.',
  },
  {
    c: 'B22',
    d: 'CASTING; POWDER METALLURGY',
  },
  {
    c: 'B22C',
    d: 'FOUNDRY MOULDING',
  },
  {
    c: 'B22C 1',
    d: 'Compositions of refractory mould or core materials; Grain structures thereof',
  },
  {
    c: 'B22C 3',
    d: 'Selection of compositions for coating the surfaces of moulds, cores, or patterns',
  },
  {
    c: 'B22C 5',
    d: 'Machines or devices specially designed for dressing or handling the mould material so far as specially adapted for that purpose',
  },
  {
    c: 'B22C 7',
    d: 'Patterns; Manufacture thereof so far as not provided for in other classes',
  },
  {
    c: 'B22C 9',
    d: 'Moulds or cores',
  },
  {
    c: 'B22C 11',
    d: 'Moulding machines for making moulds or cores',
  },
  {
    c: 'B22C 13',
    d: 'Moulding machines for making moulds or cores of particular shapes',
  },
  {
    c: 'B22C 15',
    d: 'Moulding machines for making moulds or cores, characterised by the compacting mechanism; Accessories therefor',
  },
  {
    c: 'B22C 17',
    d: 'Moulding machines for making moulds or cores, characterised by the mechanism for separating the pattern from the mould or for turning over the flask or the pattern plate',
  },
  {
    c: 'B22C 19',
    d: 'Components or accessories for moulding machines for making moulds or cores',
  },
  {
    c: 'B22C 21',
    d: 'Flasks; Accessories therefor',
  },
  {
    c: 'B22C 23',
    d: 'Tools; Devices not mentioned before for moulding',
  },
  {
    c: 'B22C 25',
    d: 'Foundry moulding plants',
  },
  {
    c: 'B22D',
    d: 'CASTING OF METALS; CASTING OF OTHER SUBSTANCES BY THE SAME PROCESSES OR DEVICES',
  },
  {
    c: 'B22D 1',
    d: 'Treatment of fused masses in the ladle or the supply runners before casting',
  },
  {
    c: 'B22D 2',
    d: 'Arrangement of indicating or measuring devices, e.g. for temperature or viscosity of the fused mass',
  },
  {
    c: 'B22D 3',
    d: 'Casting of pigs, i.e. metal castings suitable for subsequent melting; Similar casting',
  },
  {
    c: 'B22D 5',
    d: 'Machines or plants for pig or like casting',
  },
  {
    c: 'B22D 7',
    d: 'Casting of ingots, i.e. metal castings suitable for subsequent rolling or forging',
  },
  {
    c: 'B22D 9',
    d: 'Machines or plants for casting ingots',
  },
  {
    c: 'B22D 11',
    d: 'Particular casting processes; Machines or apparatus therefor',
  },
  {
    c: 'B22D 13',
    d: 'Centrifugal casting; Casting by using centrifugal force',
  },
  {
    c: 'B22D 15',
    d: 'Casting using a mould or core of which a part significant to the process of high thermal conductivity, e.g. chill casting; Moulds or accessories specially adapted therefor',
  },
  {
    c: 'B22D 17',
    d: 'Pressure die casting or injection die casting, i.e. casting in which the metal is forced into a mould under high pressure',
  },
  {
    c: 'B22D 18',
    d: 'Pressure casting; Vacuum casting',
  },
  {
    c: 'B22D 19',
    d: 'Casting in, on, or around, objects which form part of the product',
  },
  {
    c: 'B22D 21',
    d: 'Casting non-ferrous metals or metallic compounds so far as their metallurgical properties are of importance for the casting procedure',
  },
  {
    c: 'B22D 23',
    d: 'Casting processes not provided for in groups B22D0001000000-B22D0021000000',
  },
  {
    c: 'B22D 25',
    d: 'Special casting characterised by the nature of the product',
  },
  {
    c: 'B22D 27',
    d: 'Treating the metal in the mould while it is molten or ductile',
  },
  {
    c: 'B22D 29',
    d: 'Final measures after casting',
  },
  {
    c: 'B22D 30',
    d: 'Cooling castings, not restricted to casting processes covered by a single main group',
  },
  {
    c: 'B22D 31',
    d: 'Cutting-off surplus material after casting, e.g. gates',
  },
  {
    c: 'B22D 33',
    d: 'Other equipment for casting',
  },
  {
    c: 'B22D 35',
    d: 'Equipment for conveying molten metal into beds or moulds',
  },
  {
    c: 'B22D 37',
    d: 'Controlling or regulating the pouring of molten metal from a casting melt-holding vessel',
  },
  {
    c: 'B22D 39',
    d: 'Equipment for supplying molten metal in rations',
  },
  {
    c: 'B22D 41',
    d: 'Casting melt-holding vessels, e.g. ladles, tundishes, cups or the like',
  },
  {
    c: 'B22D 43',
    d: 'Mechanical cleaning, e.g. skimming of molten metals',
  },
  {
    c: 'B22D 45',
    d: 'Equipment for casting, not otherwise provided for',
  },
  {
    c: 'B22D 46',
    d: 'Controlling, supervising, not restricted to casting covered by a single main group, e.g. for safety reasons',
  },
  {
    c: 'B22D 47',
    d: 'Casting plants',
  },
  {
    c: 'B22F',
    d: 'WORKING METALLIC POWDER; MANUFACTURE OF ARTICLES FROM METALLIC POWDER; MAKING METALLIC POWDER',
  },
  {
    c: 'B22F 1',
    d: 'Special treatment of metallic powder, e.g. to facilitate working, to improve properties; Metallic powders per se, e.g. mixtures of particles of different composition',
  },
  {
    c: 'B22F 3',
    d: 'Manufacture of workpieces or articles from metallic powder characterised by the manner of compacting or sintering; Apparatus specially adapted therefor',
  },
  {
    c: 'B22F 5',
    d: 'Manufacture of workpieces or articles from metallic powder characterised by the special shape of the product',
  },
  {
    c: 'B22F 7',
    d: 'Manufacture of composite layers, workpieces, or articles, comprising metallic powder, by sintering the powder, with or without compacting',
  },
  {
    c: 'B22F 8',
    d: 'Manufacture of articles from scrap or waste metal particles',
  },
  {
    c: 'B22F 9',
    d: 'Making metallic powder or suspensions thereof; Apparatus or devices specially adapted therefor',
  },
  {
    c: 'B23',
    d: 'MACHINE TOOLS; METAL-WORKING NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'B23B',
    d: 'TURNING; BORING',
  },
  {
    c: 'B23B 1',
    d: 'Turning',
  },
  {
    c: 'B23B 3',
    d: 'General-purpose turning-machines or devices, e.g. centre lathes with feed rod and lead screw; Sets of turning-machines',
  },
  {
    c: 'B23B 5',
    d: 'Turning-machines or devices specially adapted for particular work; Accessories specially adapted therefor',
  },
  {
    c: 'B23B 7',
    d: 'Automatic or semi-automatic turning-machines with a single working-spindle, e.g. controlled by cams; Equipment therefor; Features common to automatic and semi-automatic turning-machines with one or more working-spindles',
  },
  {
    c: 'B23B 9',
    d: 'Automatic or semi-automatic turning-machines with a plurality of working-spindles, e.g. automatic multiple-spindle machines with spindles arranged in a drum carrier able to be moved into pre-determined positions; Equipment therefor',
  },
  {
    c: 'B23B 11',
    d: 'Automatic or semi-automatic turning-machines incorporating equipment for performing other working procedures, e.g. slotting, milling, rolling',
  },
  {
    c: 'B23B 13',
    d: 'Arrangements for automatically conveying, chucking or guiding stock for turning machines',
  },
  {
    c: 'B23B 15',
    d: 'Arrangements for conveying, loading, adjusting, reversing, chucking, or discharging workpieces specially designed for automatic or semi-automatic turning-machines',
  },
  {
    c: 'B23B 17',
    d: 'Components or accessories particularly for turning machines',
  },
  {
    c: 'B23B 19',
    d: 'Headstocks; Equivalent parts of any machine tools',
  },
  {
    c: 'B23B 21',
    d: 'Lathe carriages; Cross-slides; Tool posts',
  },
  {
    c: 'B23B 23',
    d: 'Tailstocks; Centres',
  },
  {
    c: 'B23B 25',
    d: 'Accessories or auxiliary equipment for turning-machines',
  },
  {
    c: 'B23B 27',
    d: 'Tools for turning or boring machines',
  },
  {
    c: 'B23B 29',
    d: 'Holders for non-rotary cutting tools',
  },
  {
    c: 'B23B 31',
    d: 'Chucks; Expansion mandrels; Adaptations thereof for remote control',
  },
  {
    c: 'B23B 33',
    d: 'Drivers; Driving centres; Nose clutches, e.g. lathe dogs',
  },
  {
    c: 'B23B 35',
    d: 'Boring; Drilling',
  },
  {
    c: 'B23B 37',
    d: 'Boring by making use of vibrations of ultrasonic frequency',
  },
  {
    c: 'B23B 39',
    d: 'General-purpose boring or drilling machines or devices; Sets of boring or drilling machines',
  },
  {
    c: 'B23B 41',
    d: 'Boring or drilling machines or devices specially adapted for particular work; Accessories specially adapted therefor',
  },
  {
    c: 'B23B 43',
    d: 'Boring or drilling devices able to be attached to a machine tool, whether or not replacing an operative portion of the machine tool',
  },
  {
    c: 'B23B 45',
    d: 'Hand-held or like portable drilling machines, e.g. drill guns; Equipment therefor',
  },
  {
    c: 'B23B 47',
    d: 'Components or accessories for boring or drilling machines',
  },
  {
    c: 'B23B 49',
    d: 'Measuring or gauging equipment on boring machines for positioning or guiding the drill; Devices for indicating failure of drills during boring; Centring devices for holes to be bored',
  },
  {
    c: 'B23B 51',
    d: 'Tools for drilling machines',
  },
  {
    c: 'B23C',
    d: 'MILLING',
  },
  {
    c: 'B23C 1',
    d: 'Milling machines not designed for particular work or special operations',
  },
  {
    c: 'B23C 3',
    d: 'Milling particular work; Special milling operations; Machines therefor',
  },
  {
    c: 'B23C 5',
    d: 'Milling-cutters',
  },
  {
    c: 'B23C 7',
    d: 'Milling devices able to be attached to a machine tool, whether or not replacing an operative portion of the machine tool',
  },
  {
    c: 'B23C 9',
    d: 'Details or accessories so far as specially adapted to milling machines or cutters',
  },
  {
    c: 'B23D',
    d: 'PLANING; SLOTTING; SHEARING; BROACHING; SAWING; FILING; SCRAPING; LIKE OPERATIONS FOR WORKING METAL BY REMOVING MATERIAL, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'B23D 1',
    d: 'Planing; Slotting',
  },
  {
    c: 'B23D 3',
    d: 'Planing or slotting machines cutting by relative movement of the tool and workpiece in a vertical or inclined straight line',
  },
  {
    c: 'B23D 5',
    d: 'Planing or slotting machines cutting otherwise than by relative movement of the tool and workpiece in a straight line',
  },
  {
    c: 'B23D 7',
    d: 'Planing or slotting machines characterised only by constructional features of particular parts',
  },
  {
    c: 'B23D 9',
    d: 'Hand-operated planing devices; Portable planing apparatus',
  },
  {
    c: 'B23D 11',
    d: 'Planing or slotting devices able to be attached to a machine tool, whether or not replacing an operative portion of the machine tool',
  },
  {
    c: 'B23D 13',
    d: 'Tools or tool holders specially designed for planing or slotting machines',
  },
  {
    c: 'B23D 15',
    d: 'Shearing; Similar cutting',
  },
  {
    c: 'B23D 17',
    d: 'Shearing machines or shearing devices cutting by blades pivoted on a single axis',
  },
  {
    c: 'B23D 19',
    d: 'Shearing machines or shearing devices cutting by rotary discs',
  },
  {
    c: 'B23D 21',
    d: 'Machines or devices for shearing or cutting tubes',
  },
  {
    c: 'B23D 23',
    d: 'Machines or devices for shearing or cutting profiled stock',
  },
  {
    c: 'B23D 25',
    d: 'Machines or arrangements for shearing stock while the latter is travelling otherwise than in the direction of the cut',
  },
  {
    c: 'B23D 27',
    d: 'Machines or devices for cutting by a nibbling action',
  },
  {
    c: 'B23D 29',
    d: 'Hand-held metal-shearing or metal-cutting devices',
  },
  {
    c: 'B23D 31',
    d: 'Shearing machines or shearing devices covered by none or more than one of the groups B23D0015000000-B23D0029000000; Combinations of shearing machines',
  },
  {
    c: 'B23D 33',
    d: 'Accessories for shearing machines or shearing devices',
  },
  {
    c: 'B23D 35',
    d: 'Tools for shearing machines or shearing devices; Holders or chucks for shearing tools',
  },
  {
    c: 'B23D 36',
    d: 'Control arrangements specially adapted for machines for shearing or similar cutting, or for sawing, stock while the latter is travelling otherwise than in the direction of the cut',
  },
  {
    c: 'B23D 37',
    d: 'Broaching',
  },
  {
    c: 'B23D 39',
    d: 'Accessories for broaching machines or broaching devices',
  },
  {
    c: 'B23D 41',
    d: 'Broaching machines or broaching devices characterised only by constructional features of particular parts',
  },
  {
    c: 'B23D 43',
    d: 'Broaching tools',
  },
  {
    c: 'B23D 45',
    d: 'Sawing',
  },
  {
    c: 'B23D 47',
    d: 'Sawing machines or sawing devices working with circular saw blades, characterised only by constructional features of particular parts',
  },
  {
    c: 'B23D 49',
    d: 'Machines or devices for sawing with straight reciprocating saw blades, e.g. hacksaws',
  },
  {
    c: 'B23D 51',
    d: 'Sawing machines or sawing devices working with straight blades, characterised only by constructional features of particular parts',
  },
  {
    c: 'B23D 53',
    d: 'Machines or devices for sawing with strap saw blades which are effectively endless in use, e.g. for contour cutting',
  },
  {
    c: 'B23D 55',
    d: 'Sawing machines or sawing devices working with strap saw blades, characterised only by constructional features of particular parts',
  },
  {
    c: 'B23D 57',
    d: 'Sawing machines or sawing devices not covered by one of groups B23D0045000000-B23D0055000000',
  },
  {
    c: 'B23D 59',
    d: 'Accessories specially designed for sawing machines or sawing devices',
  },
  {
    c: 'B23D 61',
    d: 'Tools for sawing machines or sawing devices',
  },
  {
    c: 'B23D 63',
    d: 'Dressing the tools of sawing machines or sawing devices for use in cutting any kind of material, e.g. in the manufacture of sawing tools',
  },
  {
    c: 'B23D 65',
    d: 'Making tools for sawing machines or sawing devices for use in cutting any kind of material',
  },
  {
    c: 'B23D 67',
    d: 'Filing or rasping',
  },
  {
    c: 'B23D 69',
    d: 'Filing or rasping machines or devices, characterised only by constructional features of particular parts, e.g. guiding arrangements, drives',
  },
  {
    c: 'B23D 71',
    d: 'Filing or rasping tools; Securing arrangements therefor',
  },
  {
    c: 'B23D 73',
    d: 'Making files or rasps',
  },
  {
    c: 'B23D 75',
    d: 'Reaming bored holes',
  },
  {
    c: 'B23D 77',
    d: 'Reaming tools',
  },
  {
    c: 'B23D 79',
    d: 'Methods, machines or devices not covered elsewhere, for working metal by removal of material',
  },
  {
    c: 'B23D 81',
    d: 'Methods, machines, or devices for working metal, covered by more than one main group in this subclass',
  },
  {
    c: 'B23F',
    d: 'MAKING GEARS OR TOOTHED RACKS',
  },
  {
    c: 'B23F 1',
    d: 'Making gear teeth by tools of which the profile matches the profile of the required surface',
  },
  {
    c: 'B23F 3',
    d: 'Making gear teeth involving copying operations controlled by templates having a profile which matches that of the required tooth face or part thereof or a copy thereof to a different scale',
  },
  {
    c: 'B23F 5',
    d: 'Making straight gear teeth involving moving a tool relatively to a workpiece with a rolling-off or an enveloping motion with respect to the gear teeth to be made',
  },
  {
    c: 'B23F 7',
    d: 'Making herring-bone gear teeth',
  },
  {
    c: 'B23F 9',
    d: 'Making gears having teeth curved in their longitudinal direction',
  },
  {
    c: 'B23F 11',
    d: 'Making worm wheels, e.g. by hobbing',
  },
  {
    c: 'B23F 13',
    d: 'Making worms by methods essentially requiring the use of machines of the gear-cutting type',
  },
  {
    c: 'B23F 15',
    d: 'Methods or machines for making gear wheels of special kinds, not covered by groups B23F0007000000-B23F0013000000',
  },
  {
    c: 'B23F 17',
    d: 'Special methods or machines for making gear teeth, not covered by groups B23F0001000000-B23F0015000000',
  },
  {
    c: 'B23F 19',
    d: 'Finishing gear teeth by other tools than those used for manufacturing gear teeth',
  },
  {
    c: 'B23F 21',
    d: 'Tools specially adapted for use in machines for manufacturing gear teeth',
  },
  {
    c: 'B23F 23',
    d: 'Accessories or equipment combined with or arranged in, or specially designed to form part of, gear-cutting machines',
  },
  {
    c: 'B23G',
    d: 'THREAD CUTTING; WORKING OF SCREWS, BOLT HEADS OR NUTS, IN CONJUNCTION THEREWITH',
  },
  {
    c: 'B23G 1',
    d: 'Thread cutting; Automatic machines specially designed therefor',
  },
  {
    c: 'B23G 3',
    d: 'Arrangements or accessories for enabling machine tools not specially designed only for thread cutting to be used for this purpose, e.g. arrangements for reversing the working-spindle',
  },
  {
    c: 'B23G 5',
    d: 'Thread-cutting tools; Die-heads',
  },
  {
    c: 'B23G 7',
    d: 'Forming thread by means of tools similar both in form and in manner of use to thread-cutting tools, but without removing any material',
  },
  {
    c: 'B23G 9',
    d: 'Working screws, bolt heads or nuts in conjunction with thread cutting, e.g. slotting screw heads or shanks, removing burrs from screw heads or shanks; Finishing, e.g. polishing, any screw thread',
  },
  {
    c: 'B23G 11',
    d: 'Feeding or discharging mechanisms combined with, or arranged in, or specially adapted for use in connection with, thread-cutting machines',
  },
  {
    c: 'B23H',
    d: 'WORKING OF METAL BY THE ACTION OF A HIGH CONCENTRATION OF ELECTRIC CURRENT ON A WORKPIECE USING AN ELECTRODE WHICH TAKES THE PLACE OF A TOOL; SUCH WORKING COMBINED WITH OTHER FORMS OF WORKING OF METAL',
  },
  {
    c: 'B23H 1',
    d: 'Electrical discharge machining, i.e. removing metal with a series of rapidly recurring electrical discharges between an electrode and a workpiece in the presence of a fluid dielectric',
  },
  {
    c: 'B23H 3',
    d: 'Electrochemical machining, i.e. removing metal by passing current between an electrode and a workpiece in the presence of an electrolyte',
  },
  {
    c: 'B23H 5',
    d: 'Combined machining',
  },
  {
    c: 'B23H 7',
    d: 'Processes or apparatus applicable to both electrical discharge machining and electrochemical machining',
  },
  {
    c: 'B23H 9',
    d: 'Machining specially adapted for treating particular metal objects or for obtaining special effects or results on metal objects',
  },
  {
    c: 'B23H 11',
    d: 'Auxiliary apparatus or details, not otherwise provided for',
  },
  {
    c: 'B23K',
    d: 'SOLDERING OR UNSOLDERING; WELDING; CLADDING OR PLATING BY SOLDERING OR WELDING; CUTTING BY APPLYING HEAT LOCALLY, e.g. FLAME CUTTING; WORKING BY LASER BEAM',
  },
  {
    c: 'B23K 1',
    d: 'Soldering, e.g. brazing, or unsoldering',
  },
  {
    c: 'B23K 3',
    d: 'Tools, devices, or special appurtenances for soldering, e.g. brazing, or unsoldering, not specially adapted for particular methods',
  },
  {
    c: 'B23K 5',
    d: 'Flame welding or cutting',
  },
  {
    c: 'B23K 7',
    d: 'Cutting, scarfing, or desurfacing by applying flames',
  },
  {
    c: 'B23K 9',
    d: 'Electric welding or cutting',
  },
  {
    c: 'B23K 10',
    d: 'Welding or cutting by means of a plasma',
  },
  {
    c: 'B23K 11',
    d: 'Resistance welding; Severing by resistance heating',
  },
  {
    c: 'B23K 13',
    d: 'Welding by high-frequency current heating',
  },
  {
    c: 'B23K 15',
    d: 'Other welding or cutting; Working by laser beam',
  },
  {
    c: 'B23K 17',
    d: 'Use of the energy of nuclear particles in welding or related techniques',
  },
  {
    c: 'B23K 20',
    d: 'Non-electric welding by applying impact or other pressure, with or without the application of heat, e.g. cladding or plating',
  },
  {
    c: 'B23K 23',
    d: 'Alumino-thermic welding',
  },
  {
    c: 'B23K 25',
    d: 'Slag welding, i.e. using a heated layer or mass of powder, slag, or the like in contact with the material to be joined',
  },
  {
    c: 'B23K 26',
    d: 'Working by laser beam, e.g. welding, cutting or boring',
  },
  {
    c: 'B23K 28',
    d: 'Welding or cutting not covered by groups B23K0005000000-B23K0026000000',
  },
  {
    c: 'B23K 31',
    d: 'Processes relevant to this subclass, specially adapted for particular articles or purposes, but not covered by any single one of main groups B23K0001000000-B23K0028000000',
  },
  {
    c: 'B23K 33',
    d: 'Specially-profiled edge portions of workpieces for making soldering or welding connections; Filling the seams formed thereby',
  },
  {
    c: 'B23K 35',
    d: 'Rods, electrodes, materials, or media, for use in soldering, welding, or cutting',
  },
  {
    c: 'B23K 37',
    d: 'Auxiliary devices or processes, not specially adapted to a procedure covered by only one of the other main groups of this subclass',
  },
  {
    c: 'B23K 101',
    d: 'Indexing scheme associated with groups B23K0001000000-B23K0031000000, relating to articles made by soldering, welding or cutting or to materials to be soldered, welded or cut.',
  },
  {
    c: 'B23K 103',
    d: 'Materials to be soldered, welded or cut',
  },
  {
    c: 'B23P',
    d: 'OTHER WORKING OF METAL; COMBINED OPERATIONS; UNIVERSAL MACHINE TOOLS',
  },
  {
    c: 'B23P 5',
    d: 'Setting gems or the like on metal parts, e.g. diamonds on tools',
  },
  {
    c: 'B23P 6',
    d: 'Restoring or reconditioning objects',
  },
  {
    c: 'B23P 9',
    d: 'Treating or finishing surfaces mechanically, with or without calibrating, primarily to resist wear or impact, e.g. smoothing or roughening turbine blades or bearings',
  },
  {
    c: 'B23P 11',
    d: 'Connecting or disconnecting metal parts or objects by metal-working techniques, not otherwise provided for',
  },
  {
    c: 'B23P 13',
    d: 'Making metal objects by operations essentially involving machining but not covered by a single other subclass',
  },
  {
    c: 'B23P 15',
    d: 'Making specific metal objects by operations not covered by a single other subclass or a group in this subclass',
  },
  {
    c: 'B23P 17',
    d: 'Metal-working operations, not covered by a single other subclass or another group in this subclass',
  },
  {
    c: 'B23P 19',
    d: 'Machines for simply fitting together or separating metal parts or objects, or metal and non-metal parts, whether or not involving some deformation; Tools or devices therefor so far as not provided for in other classes',
  },
  {
    c: 'B23P 21',
    d: 'Machines for assembling a multiplicity of different parts to compose units, with or without preceding or subsequent working of such parts, e.g. with programme control',
  },
  {
    c: 'B23P 23',
    d: 'Machines or arrangements of machines for performing specified combinations of different metal-working operations not covered by a single other subclass',
  },
  {
    c: 'B23P 25',
    d: 'Auxiliary treatment of workpieces, before or during machining operations, to facilitate the action of the tool or the attainment of a desired final condition of the work, e.g. relief of internal stress',
  },
  {
    c: 'B23Q',
    d: 'DETAILS, COMPONENTS, OR ACCESSORIES FOR MACHINE TOOLS, e.g. ARRANGEMENTS FOR COPYING OR CONTROLLING',
  },
  {
    c: 'B23Q 1',
    d: 'Members which are comprised in the general build-up of a form of machine, particularly relatively large fixed members',
  },
  {
    c: 'B23Q 3',
    d: 'Devices holding, supporting, or positioning, work or tools, of a kind normally removable from the machine',
  },
  {
    c: 'B23Q 5',
    d: 'Driving or feeding mechanisms; Control arrangements therefor',
  },
  {
    c: 'B23Q 7',
    d: 'Arrangements for handling work specially combined with or arranged in, or specially adapted for use in connection with, machine tools, e.g. for conveying, loading, positioning, discharging, sorting',
  },
  {
    c: 'B23Q 9',
    d: 'Arrangements for supporting or guiding portable metal-working machines or apparatus',
  },
  {
    c: 'B23Q 11',
    d: 'Accessories',
  },
  {
    c: 'B23Q 13',
    d: 'Equipment for use with tools or cutters when not in operation, e.g. protectors for storage',
  },
  {
    c: 'B23Q 15',
    d: 'Measuring; Indicating; Controlling',
  },
  {
    c: 'B23Q 16',
    d: 'Equipment for precise positioning of tool or work into particular locations not otherwise provided for',
  },
  {
    c: 'B23Q 17',
    d: 'Arrangements for indicating or measuring on machine tools',
  },
  {
    c: 'B23Q 23',
    d: 'Arrangements for compensating for irregularities or wear, e.g. of ways, of setting mechanisms',
  },
  {
    c: 'B23Q 27',
    d: 'Geometrical mechanisms for the production of work of particular shapes, not fully provided for in another subclass',
  },
  {
    c: 'B23Q 33',
    d: 'Copying',
  },
  {
    c: 'B23Q 35',
    d: 'Control systems or devices for copying directly from a pattern or a master model; Devices for use in copying manually',
  },
  {
    c: 'B23Q 37',
    d: 'Metal-working machines comprising units or sub-assemblies; Associations of metal-working machines or units',
  },
  {
    c: 'B23Q 39',
    d: 'Metal-working machines incorporating a plurality of sub-assemblies, each capable of performing a metal-working operation',
  },
  {
    c: 'B23Q 41',
    d: 'Combinations or associations of metal-working machines not directed to a particular result according to classes B21, B23 or B24',
  },
  {
    c: 'B24',
    d: 'GRINDING; POLISHING',
  },
  {
    c: 'B24B',
    d: 'MACHINES, DEVICES, OR PROCESSES FOR GRINDING OR POLISHING',
  },
  {
    c: 'B24B 1',
    d: 'Processes of grinding or polishing; Use of auxiliary equipment in connection with such processes',
  },
  {
    c: 'B24B 3',
    d: 'Sharpening cutting edges, e.g. of tools; Accessories therefor, e.g. for holding the tools',
  },
  {
    c: 'B24B 5',
    d: 'Grinding surfaces of particular forms',
  },
  {
    c: 'B24B 7',
    d: 'Machines or devices designed for grinding plane surfaces on work, including polishing plane glass surfaces; Accessories therefor',
  },
  {
    c: 'B24B 9',
    d: 'Machines or devices designed for grinding edges or bevels on work or for removing burrs; Accessories therefor',
  },
  {
    c: 'B24B 11',
    d: 'Machines or devices designed for grinding spherical surfaces or parts of spherical surfaces on work; Accessories therefor',
  },
  {
    c: 'B24B 13',
    d: 'Machines or devices designed for grinding or polishing optical surfaces on lenses or surfaces of similar shape on other work; Accessories therefor',
  },
  {
    c: 'B24B 15',
    d: 'Machines or devices designed for grinding seat surfaces; Accessories therefor',
  },
  {
    c: 'B24B 17',
    d: 'Special adaptations of machines or devices for grinding controlled by patterns, drawings, magnetic tapes or the like; Accessories therefor',
  },
  {
    c: 'B24B 19',
    d: 'Single purpose machines or devices for particular grinding operations not covered by any other main group',
  },
  {
    c: 'B24B 21',
    d: 'Machines or devices using grinding or polishing belts',
  },
  {
    c: 'B24B 23',
    d: 'Portable grinding machines, e.g. hand-guided; Accessories therefor',
  },
  {
    c: 'B24B 25',
    d: 'Grinding machines of universal type',
  },
  {
    c: 'B24B 27',
    d: 'Other grinding machines or devices',
  },
  {
    c: 'B24B 29',
    d: 'Polishing surfaces; Finishing surfaces',
  },
  {
    c: 'B24B 31',
    d: 'Machines or devices designed for polishing or abrading surfaces on work by means of tumbling apparatus or other apparatus in which the work or the abrasive material is loose; Accessories therefor',
  },
  {
    c: 'B24B 33',
    d: 'Honing machines or devices; Accessories therefor',
  },
  {
    c: 'B24B 35',
    d: 'Machines or devices designed for superfinishing surfaces on work, i.e. by means of abrading blocks reciprocating with high frequency',
  },
  {
    c: 'B24B 37',
    d: 'Lapping machines or devices; Accessories',
  },
  {
    c: 'B24B 39',
    d: 'Burnishing machines or devices, i.e. requiring pressure members for compacting the surface zone; Accessories therefor',
  },
  {
    c: 'B24B 41',
    d: 'Component parts of general applicability for grinding machines or devices',
  },
  {
    c: 'B24B 45',
    d: 'Means for securing grinding wheels on rotary arbors',
  },
  {
    c: 'B24B 47',
    d: 'Drives or gearings for grinding machines or devices; Equipment therefor',
  },
  {
    c: 'B24B 49',
    d: 'Measuring; Indicating; Controlling',
  },
  {
    c: 'B24B 51',
    d: 'Arrangements for automatic control of a series of individual steps in grinding a workpiece',
  },
  {
    c: 'B24B 53',
    d: 'Devices or means for dressing or conditioning abrasive surfaces',
  },
  {
    c: 'B24B 55',
    d: 'Safety devices for grinding or polishing machines; Accessories fitted to grinding or polishing machines for keeping tools or parts of the machine in good working condition',
  },
  {
    c: 'B24B 57',
    d: 'Devices for feeding, applying, grading or recovering grinding, polishing or lapping agents',
  },
  {
    c: 'B24C',
    d: 'ABRASIVE OR RELATED BLASTING WITH PARTICULATE MATERIAL',
  },
  {
    c: 'B24C 1',
    d: 'Methods for use of abrasive blasting for producing particular effects; Use of auxiliary equipment in connection with such methods',
  },
  {
    c: 'B24C 3',
    d: 'Abrasive blasting machines or devices; Plants',
  },
  {
    c: 'B24C 5',
    d: 'Accessories or equipment for abrasive blasting machines or devices',
  },
  {
    c: 'B24C 7',
    d: 'Equipment for feeding abrasive material; Controlling the flowability, constitution, or other physical characteristics of abrasive blasts',
  },
  {
    c: 'B24C 9',
    d: 'Appurtenances of abrasive blasting machines or devices, e.g. working chambers, arrangements for handling used abrasive material',
  },
  {
    c: 'B24C 11',
    d: 'Selection of abrasive materials for abrasive blasts',
  },
  {
    c: 'B24D',
    d: 'TOOLS FOR GRINDING, BUFFING OR SHARPENING',
  },
  {
    c: 'B24D 3',
    d: 'Physical features of abrasive bodies, or sheets, e.g. abrasive surfaces of special nature; Abrasive bodies or sheets characterised by their constituents',
  },
  {
    c: 'B24D 5',
    d: 'Bonded abrasive wheels',
  },
  {
    c: 'B24D 7',
    d: 'Bonded abrasive wheels, or wheels with inserted abrasive blocks, designed for acting otherwise than only by their periphery, e.g. by the front face; Bushings or mountings therefor',
  },
  {
    c: 'B24D 9',
    d: 'Wheels or drums supporting in exchangeable arrangement a layer of flexible abrasive material, e.g. sandpaper',
  },
  {
    c: 'B24D 11',
    d: 'Constructional features of flexible abrasive materials; Special features in the manufacture of such materials',
  },
  {
    c: 'B24D 13',
    d: 'Wheels having flexibly-acting working parts, e.g. buffing wheels; Mountings therefor',
  },
  {
    c: 'B24D 15',
    d: 'Hand tools or other devices for non-rotary grinding, polishing, or stropping',
  },
  {
    c: 'B24D 18',
    d: 'Manufacture of grinding tools, e.g. wheels, not otherwise provided for',
  },
  {
    c: 'B24D 99',
    d: 'n.a.',
  },
  {
    c: 'B25',
    d: 'HAND TOOLS; PORTABLE POWER-DRIVEN TOOLS; HANDLES FOR HAND IMPLEMENTS; WORKSHOP EQUIPMENT; MANIPULATORS',
  },
  {
    c: 'B25B',
    d: 'TOOLS OR BENCH DEVICES NOT OTHERWISE PROVIDED FOR, FOR FASTENING, CONNECTING, DISENGAGING, OR HOLDING',
  },
  {
    c: 'B25B 1',
    d: 'Vices',
  },
  {
    c: 'B25B 3',
    d: 'Hand vices, i.e. vices intended to be held by hand; Pin vices',
  },
  {
    c: 'B25B 5',
    d: 'Clamps',
  },
  {
    c: 'B25B 7',
    d: 'Pliers; Other hand-held gripping tools with jaws on pivoted limbs; Details applicable generally to pivoted-limb hand tools',
  },
  {
    c: 'B25B 9',
    d: 'Hand-held gripping tools other than those covered by group B25B0007000000',
  },
  {
    c: 'B25B 11',
    d: 'Work holders or positioners not covered by groups B25B0001000000-B25B0009000000, e.g. magnetic work holders, vacuum work holders',
  },
  {
    c: 'B25B 13',
    d: 'Spanners; Wrenches',
  },
  {
    c: 'B25B 15',
    d: 'Screwdrivers',
  },
  {
    c: 'B25B 17',
    d: 'Hand-driven gear-operated wrenches or screwdrivers',
  },
  {
    c: 'B25B 19',
    d: 'Impact wrenches or screwdrivers',
  },
  {
    c: 'B25B 21',
    d: 'Portable power-driven screw or nut setting or loosening tools',
  },
  {
    c: 'B25B 23',
    d: 'Details of, or accessories for, spanners, wrenches, screwdrivers',
  },
  {
    c: 'B25B 25',
    d: 'Implements for fastening, connecting, or tensioning of wire or strip',
  },
  {
    c: 'B25B 27',
    d: 'Hand tools or bench devices, specially adapted for fitting together or separating parts or objects whether or not involving some deformation, not otherwise provided for',
  },
  {
    c: 'B25B 28',
    d: 'Portable power-driven joining or separation tools',
  },
  {
    c: 'B25B 29',
    d: 'Accessories',
  },
  {
    c: 'B25B 31',
    d: 'Hand tools for applying fasteners',
  },
  {
    c: 'B25B 33',
    d: 'Hand tools not covered by any other group in this subclass',
  },
  {
    c: 'B25C',
    d: 'HAND-HELD NAILING OR STAPLING TOOLS; MANUALLY-OPERATED PORTABLE STAPLING TOOLS',
  },
  {
    c: 'B25C 1',
    d: 'Hand-held nailing tools',
  },
  {
    c: 'B25C 3',
    d: 'Portable devices for holding and guiding nails; Nail dispensers',
  },
  {
    c: 'B25C 5',
    d: 'Manually operated portable stapling tools; Hand-held power-operated stapling tools',
  },
  {
    c: 'B25C 7',
    d: 'Accessories for nailing or stapling tools, e.g. supports',
  },
  {
    c: 'B25C 9',
    d: 'Nail punches',
  },
  {
    c: 'B25C 11',
    d: 'Nail, spike, or staple extractors',
  },
  {
    c: 'B25C 13',
    d: 'Nail straightening devices',
  },
  {
    c: 'B25D',
    d: 'PERCUSSIVE TOOLS',
  },
  {
    c: 'B25D 1',
    d: 'Hand hammers; Hammer heads of special shape or materials',
  },
  {
    c: 'B25D 3',
    d: 'Hand chisels',
  },
  {
    c: 'B25D 5',
    d: 'Centre punches',
  },
  {
    c: 'B25D 7',
    d: 'Picks',
  },
  {
    c: 'B25D 9',
    d: 'Portable percussive tools with fluid-pressure drive, e.g. having several percussive tool bits operated simultaneously',
  },
  {
    c: 'B25D 11',
    d: 'Portable percussive tools with electromotor drive',
  },
  {
    c: 'B25D 13',
    d: 'Portable percussive tools with electromagnetic drive',
  },
  {
    c: 'B25D 15',
    d: 'Portable percussive tools using centrifugal or rotary impact elements',
  },
  {
    c: 'B25D 16',
    d: 'Portable percussive machines with superimposed rotation',
  },
  {
    c: 'B25D 17',
    d: 'Details of, or accessories for, portable power-driven percussive tools',
  },
  {
    c: 'B25F',
    d: 'COMBINATION OR MULTI-PURPOSE TOOLS NOT OTHERWISE PROVIDED FOR; DETAILS OR COMPONENTS OF PORTABLE POWER-DRIVEN TOOLS NOT PARTICULARLY RELATED TO THE OPERATIONS PERFORMED AND NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'B25F 1',
    d: 'Combination or multi-purpose hand tools',
  },
  {
    c: 'B25F 3',
    d: 'Associations of tools for different working operations with one portable power-drive means; Adapters therefor',
  },
  {
    c: 'B25F 5',
    d: 'Details or components of portable power-driven tools not particularly related to the operations performed and not otherwise provided for',
  },
  {
    c: 'B25G',
    d: 'HANDLES FOR HAND IMPLEMENTS',
  },
  {
    c: 'B25G 1',
    d: 'Handle constructions',
  },
  {
    c: 'B25G 3',
    d: 'Attaching handles to the implements',
  },
  {
    c: 'B25H',
    d: 'WORKSHOP EQUIPMENT, e.g. FOR MARKING-OUT WORK; STORAGE MEANS FOR WORKSHOPS',
  },
  {
    c: 'B25H 1',
    d: 'Work benches; Portable stands or supports for positioning portable tools or work to be operated on thereby',
  },
  {
    c: 'B25H 3',
    d: 'Storage means or arrangements for workshops facilitating access to, or handling of, work, tools or instruments',
  },
  {
    c: 'B25H 5',
    d: "Tool, instrument or work supports or storage means used in association with vehicles; Workers' supports, e.g. mechanics' creepers",
  },
  {
    c: 'B25H 7',
    d: 'Marking-out or setting-out work',
  },
  {
    c: 'B25J',
    d: 'MANIPULATORS; CHAMBERS PROVIDED WITH MANIPULATION DEVICES',
  },
  {
    c: 'B25J 1',
    d: 'Manipulators positioned in space by hand',
  },
  {
    c: 'B25J 3',
    d: 'Manipulators of master-slave type, i.e. both controlling unit and controlled unit perform corresponding spatial movements',
  },
  {
    c: 'B25J 5',
    d: 'Manipulators mounted on wheels or on carriages',
  },
  {
    c: 'B25J 7',
    d: 'Micromanipulators',
  },
  {
    c: 'B25J 9',
    d: 'Programme-controlled manipulators',
  },
  {
    c: 'B25J 11',
    d: 'Manipulators not otherwise provided for',
  },
  {
    c: 'B25J 13',
    d: 'Controls for manipulators',
  },
  {
    c: 'B25J 15',
    d: 'Gripping heads',
  },
  {
    c: 'B25J 17',
    d: 'Joints',
  },
  {
    c: 'B25J 18',
    d: 'Arms',
  },
  {
    c: 'B25J 19',
    d: 'Accessories fitted to manipulators, e.g. for monitoring, for viewing; Safety devices combined with or specially adapted for use in connection with manipulators',
  },
  {
    c: 'B25J 21',
    d: 'Chambers provided with manipulation devices',
  },
  {
    c: 'B26',
    d: 'HAND CUTTING TOOLS; CUTTING; SEVERING',
  },
  {
    c: 'B26B',
    d: 'HAND-HELD CUTTING TOOLS NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'B26B 1',
    d: 'Hand knives',
  },
  {
    c: 'B26B 3',
    d: 'Hand knives with fixed blades',
  },
  {
    c: 'B26B 5',
    d: 'Hand knives with one or more detachable blades',
  },
  {
    c: 'B26B 7',
    d: 'Hand knives with reciprocating motor-driven blades',
  },
  {
    c: 'B26B 9',
    d: 'Blades for hand knives',
  },
  {
    c: 'B26B 11',
    d: 'Hand knives combined with other implements, e.g. with corkscrew, with scissors, with writing implement',
  },
  {
    c: 'B26B 13',
    d: 'Hand shears; Scissors',
  },
  {
    c: 'B26B 15',
    d: 'Hand-held shears with motor-driven blades',
  },
  {
    c: 'B26B 17',
    d: 'Hand cutting tools with two jaws which come into abutting contact',
  },
  {
    c: 'B26B 19',
    d: 'Clippers or shavers operating with a plurality of cutting edges, e.g. hair clippers, dry shavers',
  },
  {
    c: 'B26B 21',
    d: 'Razors of the open or knife type; Safety razors or other shaving implements of the planing type; Hair-trimming devices involving a razor-blade; Equipment therefor',
  },
  {
    c: 'B26B 23',
    d: 'Axes; Hatchets',
  },
  {
    c: 'B26B 25',
    d: 'Hand cutting tools involving disc blades, e.g. motor-driven',
  },
  {
    c: 'B26B 27',
    d: 'Hand cutting tools not provided for in groups B26B0001000000-B26B0025000000, e.g. finger rings for cutting string, devices for cutting by means of wires',
  },
  {
    c: 'B26B 29',
    d: 'Guards or sheaths for hand cutting tools; Arrangements for guiding hand cutting tools',
  },
  {
    c: 'B26D',
    d: 'CUTTING; DETAILS COMMON TO MACHINES FOR PERFORATING, PUNCHING, CUTTING-OUT, STAMPING-OUT OR SEVERING',
  },
  {
    c: 'B26D 1',
    d: 'Cutting through work characterised by the nature or movement of the cutting member; Apparatus or machines therefor; Cutting members therefor',
  },
  {
    c: 'B26D 3',
    d: 'Cutting work characterised by the nature of the cut made; Apparatus therefor',
  },
  {
    c: 'B26D 5',
    d: 'Arrangements for operating and controlling machines or devices for cutting, cutting-out, stamping-out, punching, perforating, or severing by means other than cutting',
  },
  {
    c: 'B26D 7',
    d: 'Details of apparatus for cutting, cutting-out, stamping-out, punching, perforating, or severing by means other than cutting',
  },
  {
    c: 'B26D 9',
    d: 'Cutting apparatus combined with punching or perforating apparatus or with dissimilar cutting apparatus',
  },
  {
    c: 'B26D 11',
    d: 'Combinations of several similar cutting apparatus',
  },
  {
    c: 'B26F',
    d: 'PERFORATING; PUNCHING; CUTTING-OUT; STAMPING-OUT; SEVERING BY MEANS OTHER THAN CUTTING',
  },
  {
    c: 'B26F 1',
    d: 'Perforating; Punching; Cutting-out; Stamping-out; Apparatus therefor',
  },
  {
    c: 'B26F 3',
    d: 'Severing by means other than cutting; Apparatus therefor',
  },
  {
    c: 'B27',
    d: 'WORKING OR PRESERVING WOOD OR SIMILAR MATERIAL; NAILING OR STAPLING MACHINES IN GENERAL',
  },
  {
    c: 'B27B',
    d: 'SAWS FOR WOOD OR SIMILAR MATERIAL; COMPONENTS OR ACCESSORIES THEREFOR',
  },
  {
    c: 'B27B 1',
    d: 'Methods for subdividing trunks or logs essentially involving sawing',
  },
  {
    c: 'B27B 3',
    d: 'Gang saw mills; Other sawing machines with reciprocating saw blades, specially designed for length sawing of trunks',
  },
  {
    c: 'B27B 5',
    d: 'Circular saws',
  },
  {
    c: 'B27B 7',
    d: 'Sawing machines working with circular saw blades, specially designed for length sawing of trunks',
  },
  {
    c: 'B27B 9',
    d: 'Portable power-driven circular saws for manual operation',
  },
  {
    c: 'B27B 11',
    d: 'Cross-cut reciprocating saws with power drive; Appurtenances therefor',
  },
  {
    c: 'B27B 13',
    d: 'Band or strap sawing machines',
  },
  {
    c: 'B27B 15',
    d: 'Band or strap sawing machines specially designed for length cutting of trunks',
  },
  {
    c: 'B27B 17',
    d: 'Chain saws; Equipment therefor',
  },
  {
    c: 'B27B 19',
    d: 'Other reciprocating saws with power drive; Fret-saws',
  },
  {
    c: 'B27B 21',
    d: 'Hand saws without power drive',
  },
  {
    c: 'B27B 23',
    d: 'Other cutting of wood by non-rotary toothed tools; Tools therefor',
  },
  {
    c: 'B27B 25',
    d: 'Components or accessories for saws',
  },
  {
    c: 'B27B 27',
    d: 'Guide fences or stops for timber in saw mills or sawing machines; Measuring equipment thereon',
  },
  {
    c: 'B27B 29',
    d: 'Gripping, clamping, or holding devices for the trunk or log in saw mills or sawing machines',
  },
  {
    c: 'B27B 31',
    d: 'Arrangements for conveying, loading, turning, adjusting, or discharging the log or timber, specially designed for saw mills or sawing machines',
  },
  {
    c: 'B27B 33',
    d: 'Sawing tools for saw mills, sawing machines, or sawing devices',
  },
  {
    c: 'B27C',
    d: 'PLANING, DRILLING, MILLING, TURNING OR UNIVERSAL MACHINES FOR WOOD OR SIMILAR MATERIAL',
  },
  {
    c: 'B27C 1',
    d: 'Machines for producing flat surfaces, e.g. by rotary cutters; Equipment therefor',
  },
  {
    c: 'B27C 3',
    d: 'Drilling machines or drilling devices; Equipment therefor',
  },
  {
    c: 'B27C 5',
    d: 'Machines designed for producing special profiles or shaped work, e.g. by rotary cutters; Equipment therefor',
  },
  {
    c: 'B27C 7',
    d: 'Wood-turning machines; Equipment therefor',
  },
  {
    c: 'B27C 9',
    d: 'Multi-purpose machines; Universal machines; Equipment therefor',
  },
  {
    c: 'B27D',
    d: 'WORKING VENEER OR PLYWOOD',
  },
  {
    c: 'B27D 1',
    d: 'Joining wood veneer with any material; Forming articles thereby',
  },
  {
    c: 'B27D 3',
    d: 'Veneer presses; Press plates; Plywood presses',
  },
  {
    c: 'B27D 5',
    d: 'Other working of veneer or plywood specially adapted to veneer or plywood',
  },
  {
    c: 'B27F',
    d: 'DOVETAILED WORK; TENONS; SLOTTING MACHINES FOR WOOD OR SIMILAR MATERIAL; NAILING OR STAPLING MACHINES',
  },
  {
    c: 'B27F 1',
    d: 'Dovetailed work; Tenons; Making tongues or grooves',
  },
  {
    c: 'B27F 4',
    d: 'Machines for inserting dowels, with or without drilling equipment',
  },
  {
    c: 'B27F 5',
    d: 'Slotted or mortised work',
  },
  {
    c: 'B27F 7',
    d: 'Nailing or stapling',
  },
  {
    c: 'B27G',
    d: 'ACCESSORY MACHINES OR APPARATUS FOR WORKING WOOD OR SIMILAR MATERIALS; TOOLS FOR WORKING WOOD OR SIMILAR MATERIALS',
  },
  {
    c: 'B27G 1',
    d: 'Accessory machines or apparatus',
  },
  {
    c: 'B27G 3',
    d: 'Arrangements for removing bark-zones, chips, waste, or dust, specially designed for use in connection with wood-working machine or in wood-working plants',
  },
  {
    c: 'B27G 5',
    d: 'Machines or devices for working mitre joints with even abutting ends',
  },
  {
    c: 'B27G 11',
    d: 'Applying adhesives or glue to surfaces of wood to be joined',
  },
  {
    c: 'B27G 13',
    d: 'Tools specially adapted for working wood or similar materials',
  },
  {
    c: 'B27G 15',
    d: 'Boring or turning tools; Augers',
  },
  {
    c: 'B27G 17',
    d: 'Manually-operated tools',
  },
  {
    c: 'B27G 19',
    d: 'Safety guards or devices',
  },
  {
    c: 'B27G 21',
    d: 'Safety guards or devices specially designed for other wood-working machines',
  },
  {
    c: 'B27G 23',
    d: 'Gauging means specially designed for adjusting of tools or guides, e.g. adjusting cutting blades in cutter blocks',
  },
  {
    c: 'B27H',
    d: 'BENDING WOOD OR SIMILAR MATERIAL; COOPERAGE; MAKING WHEELS FROM WOOD OR SIMILAR MATERIAL',
  },
  {
    c: 'B27H 1',
    d: 'Bending wood, e.g. wood stock',
  },
  {
    c: 'B27H 3',
    d: 'Manufacture of constructional elements of tubs, coops or barrels',
  },
  {
    c: 'B27H 5',
    d: 'Manufacture of tubs, coops or barrels',
  },
  {
    c: 'B27H 7',
    d: 'Manufacture of wheels wholly or predominantly made from wood, e.g. cart wheels; Manufacture of wood-rimmed wheels, e.g. steering wheels',
  },
  {
    c: 'B27J',
    d: 'MECHANICAL WORKING OF CANE, CORK, OR SIMILAR MATERIALS',
  },
  {
    c: 'B27J 1',
    d: 'Mechanical working of cane or the like',
  },
  {
    c: 'B27J 3',
    d: 'Peeling osier rods',
  },
  {
    c: 'B27J 5',
    d: 'Mechanical working of cork',
  },
  {
    c: 'B27J 7',
    d: 'Mechanical working of tree or plant materials not otherwise provided for',
  },
  {
    c: 'B27K',
    d: 'PROCESSES, APPARATUS OR SELECTION OF SUBSTANCES FOR IMPREGNATING, STAINING, DYEING OR BLEACHING OF WOOD, OR FOR TREATING OF WOOD WITH PERMEANT LIQUIDS, NOT OTHERWISE PROVIDED FOR; CHEMICAL OR PHYSICAL TREATMENT OF CORK, CANE, REED, STRAW OR SIMILAR MATERIALS',
  },
  {
    c: 'B27K 1',
    d: 'Dampening wood',
  },
  {
    c: 'B27K 3',
    d: 'Impregnating wood, e.g. for protection',
  },
  {
    c: 'B27K 5',
    d: 'Staining or dyeing wood; Bleaching wood; Treating of wood not provided for in groups B27K0001000000 or  B27K0003000000',
  },
  {
    c: 'B27K 7',
    d: 'Chemical or physical treatment of cork',
  },
  {
    c: 'B27K 9',
    d: 'Chemical or physical treatment of reed, straw, or similar material',
  },
  {
    c: 'B27L',
    d: 'REMOVING BARK OR VESTIGES OF BRANCHES',
  },
  {
    c: 'B27L 1',
    d: 'Debarking or removing vestiges of branches from trees or logs',
  },
  {
    c: 'B27L 3',
    d: 'Debarking by chemical treatment',
  },
  {
    c: 'B27L 5',
    d: 'Manufacture of veneer',
  },
  {
    c: 'B27L 7',
    d: 'Arrangements for splitting wood',
  },
  {
    c: 'B27L 9',
    d: 'Manufacture of wooden sticks, e.g. toothpicks',
  },
  {
    c: 'B27L 11',
    d: 'Manufacture of wood shavings, chips, powder, or the like',
  },
  {
    c: 'B27M',
    d: 'WORKING OF WOOD NOT PROVIDED FOR IN SUBCLASSES B27B-B27L; MANUFACTURE OF SPECIFIC WOODEN ARTICLES',
  },
  {
    c: 'B27M 1',
    d: 'Working of wood not provided for in subclasses B27B-B27L, e.g. by stretching',
  },
  {
    c: 'B27M 3',
    d: 'Manufacture or reconditioning of specific semi-finished or finished articles',
  },
  {
    c: 'B27N',
    d: 'MANUFACTURE BY DRY PROCESSES OF ARTICLES, WITH OR WITHOUT ORGANIC BINDING AGENTS, MADE FROM PARTICLES OR FIBRES CONSISTING OF WOOD OR OTHER LIGNOCELLULOSIC OR LIKE ORGANIC MATERIAL',
  },
  {
    c: 'B27N 1',
    d: 'Pretreatment of moulding material',
  },
  {
    c: 'B27N 3',
    d: 'Manufacture of substantially flat articles, e.g. boards, from particles or fibres',
  },
  {
    c: 'B27N 5',
    d: 'Manufacture of non-flat articles',
  },
  {
    c: 'B27N 7',
    d: 'After-treatment, e.g. reducing swelling or shrinkage, surfacing; Protecting the edges of boards against access of humidity',
  },
  {
    c: 'B27N 9',
    d: 'Arrangements for fireproofing',
  },
  {
    c: 'B28',
    d: 'WORKING CEMENT, CLAY, OR STONE',
  },
  {
    c: 'B28B',
    d: 'SHAPING CLAY OR OTHER CERAMIC COMPOSITIONS, SLAG OR MIXTURES CONTAINING CEMENTITIOUS MATERIAL, e.g. PLASTER',
  },
  {
    c: 'B28B 1',
    d: 'Producing shaped articles from the material',
  },
  {
    c: 'B28B 3',
    d: 'Producing shaped articles from the material by using presses',
  },
  {
    c: 'B28B 5',
    d: 'Producing shaped articles from the material in moulds or on moulding surfaces, carried or formed by, in or on conveyors irrespective of the manner of shaping',
  },
  {
    c: 'B28B 7',
    d: 'Moulds; Cores; Mandrels',
  },
  {
    c: 'B28B 11',
    d: 'Apparatus or processes for treating or working the shaped articles',
  },
  {
    c: 'B28B 13',
    d: 'Feeding the unshaped material to moulds or apparatus for producing shaped articles; Discharging shaped articles from such moulds or apparatus',
  },
  {
    c: 'B28B 15',
    d: 'General arrangement or layout of plant',
  },
  {
    c: 'B28B 17',
    d: 'Details of, or accessories for, apparatus for shaping the material; Auxiliary measures taken in connection with such shaping',
  },
  {
    c: 'B28B 19',
    d: 'Machines or methods for applying the material to surfaces to form a permanent layer thereon',
  },
  {
    c: 'B28B 21',
    d: 'Methods, apparatus, or machines, specially adapted for the production of tubular or reinforced articles',
  },
  {
    c: 'B28B 23',
    d: 'Arrangements specially adapted for the production of shaped articles with elements wholly or partly embedded in the moulding material',
  },
  {
    c: 'B28C',
    d: 'PREPARING CLAY; PRODUCING MIXTURES CONTAINING CLAY OR CEMENTITIOUS MATERIAL, e.g. PLASTER',
  },
  {
    c: 'B28C 1',
    d: 'Apparatus or methods for obtaining or processing clay',
  },
  {
    c: 'B28C 3',
    d: 'Mixing clay or cement with other material',
  },
  {
    c: 'B28C 5',
    d: 'Apparatus or methods for producing mixtures of cement with other substances, e.g. slurries, mortars, porous or fibrous compositions',
  },
  {
    c: 'B28C 7',
    d: 'Controlling the operation of apparatus for producing mixtures of clay or cement with other substances; Supplying or proportioning the ingredients for mixing clay or cement with other substances; Discharging the mixture',
  },
  {
    c: 'B28C 9',
    d: 'General arrangement or layout of plant',
  },
  {
    c: 'B28D',
    d: 'WORKING STONE OR STONE-LIKE MATERIALS',
  },
  {
    c: 'B28D 1',
    d: 'Working stone or stone-like materials, e.g. brick, concrete, not provided for elsewhere; Machines, devices, tools therefor',
  },
  {
    c: 'B28D 3',
    d: 'Dressing mill discs or rollers',
  },
  {
    c: 'B28D 5',
    d: 'Fine working of gems, jewels, crystals, e.g. of semiconductor material; Apparatus therefor',
  },
  {
    c: 'B28D 7',
    d: 'Accessories specially adapted for use with machines or devices of the other groups of this subclass',
  },
  {
    c: 'B29',
    d: 'WORKING OF PLASTICS; WORKING OF SUBSTANCES IN A PLASTIC STATE IN GENERAL',
  },
  {
    c: 'B29B',
    d: 'PREPARATION OR PRETREATMENT OF THE MATERIAL TO BE SHAPED; MAKING GRANULES OR PREFORMS; RECOVERY OF PLASTICS OR OTHER CONSTITUENTS OF WASTE MATERIAL CONTAINING PLASTICS',
  },
  {
    c: 'B29B 7',
    d: 'Mixing; Kneading',
  },
  {
    c: 'B29B 9',
    d: 'Making granules',
  },
  {
    c: 'B29B 11',
    d: 'Making preforms',
  },
  {
    c: 'B29B 13',
    d: 'Conditioning or physical treatment of the material to be shaped',
  },
  {
    c: 'B29B 15',
    d: 'Pretreatment of the material to be shaped, not covered by groups B29B0007000000-B29B0013000000',
  },
  {
    c: 'B29B 17',
    d: 'Recovery of plastics or other constituents of waste material containing plastics',
  },
  {
    c: 'B29C',
    d: 'SHAPING OR JOINING OF PLASTICS; SHAPING OF MATERIAL IN A PLASTIC STATE, NOT OTHERWISE PROVIDED FOR; AFTER-TREATMENT OF THE SHAPED PRODUCTS, e.g. REPAIRING',
  },
  {
    c: 'B29C 31',
    d: 'Component parts, details or accessories; Auxiliary operations',
  },
  {
    c: 'B29C 33',
    d: 'Moulds or cores; Details thereof or accessories therefor',
  },
  {
    c: 'B29C 35',
    d: 'Heating, cooling or curing, e.g. crosslinking or vulcanising; Apparatus therefor',
  },
  {
    c: 'B29C 37',
    d: 'Component parts, details, accessories or auxiliary operations, not covered by group B29C0033000000 or B29C0035000000',
  },
  {
    c: 'B29C 39',
    d: 'Particular shaping techniques, e.g. moulding, joining; Apparatus therefor',
  },
  {
    c: 'B29C 41',
    d: 'Shaping by coating a mould, core or other substrate, i.e. by depositing material and stripping-off the shaped article; Apparatus therefor',
  },
  {
    c: 'B29C 43',
    d: 'Compression moulding, i.e. applying external pressure to flow the moulding material; Apparatus therefor',
  },
  {
    c: 'B29C 44',
    d: 'Shaping by internal pressure generated in the material, e.g. swelling or foaming',
  },
  {
    c: 'B29C 45',
    d: 'Injection moulding, i.e. forcing the required volume of moulding material through a nozzle into a closed mould; Apparatus therefor',
  },
  {
    c: 'B29C 48',
    d: 'Extrusion moulding, i.e. expressing the moulding material through a die or nozzle which imparts the desired form; Apparatus therefor',
  },
  {
    c: 'B29C 49',
    d: 'Blow-moulding, i.e. blowing a preform or parison to a desired shape within a mould; Apparatus therefor',
  },
  {
    c: 'B29C 51',
    d: 'Shaping by thermoforming, e.g. shaping sheets in matched moulds or by deep-drawing; Apparatus therefor',
  },
  {
    c: 'B29C 53',
    d: 'Shaping by bending, folding, twisting, straightening or flattening; Apparatus therefor',
  },
  {
    c: 'B29C 55',
    d: 'Shaping by stretching, e.g. drawing through a die; Apparatus therefor',
  },
  {
    c: 'B29C 57',
    d: 'Shaping of tube ends, e.g. flanging, belling or closing; Apparatus therefor',
  },
  {
    c: 'B29C 59',
    d: 'Surface shaping, e.g. embossing; Apparatus therefor',
  },
  {
    c: 'B29C 61',
    d: 'Shaping by liberation of internal stresses; Making preforms having internal stresses; Apparatus therefor',
  },
  {
    c: 'B29C 63',
    d: 'Lining or sheathing, i.e. applying preformed layers or sheathings of plastics; Apparatus therefor',
  },
  {
    c: 'B29C 64',
    d: 'Additive manufacturing, i.e. manufacturing of three-dimensional [3D] objects by additive deposition, additive agglomeration or additive layering, e.g. by 3D printing, stereolithography or selective laser sintering',
  },
  {
    c: 'B29C 65',
    d: 'Joining of preformed parts; Apparatus therefor',
  },
  {
    c: 'B29C 67',
    d: 'Shaping techniques not covered by groups B29C0039000000-B29C0065000000, B29C0070000000 or B29C0073000000',
  },
  {
    c: 'B29C 69',
    d: 'Combinations of shaping techniques not provided for in a single one of main groups B29C0039000000-B29C0067000000, e.g. associations of moulding and joining techniques; Apparatus therefor',
  },
  {
    c: 'B29C 70',
    d: 'Shaping composites, i.e. plastics material comprising reinforcements, fillers or preformed parts, e.g. inserts',
  },
  {
    c: 'B29C 71',
    d: 'After-treatment of articles without altering their shape; Apparatus therefor',
  },
  {
    c: 'B29C 73',
    d: 'Repairing of articles made from plastics or substances in a plastic state, e.g. of articles shaped or produced by using techniques covered by this subclass or subclass B29D',
  },
  {
    c: 'B29D',
    d: 'PRODUCING PARTICULAR ARTICLES FROM PLASTICS OR FROM SUBSTANCES IN A PLASTIC STATE',
  },
  {
    c: 'B29D 1',
    d: 'Producing articles provided with screw threads',
  },
  {
    c: 'B29D 5',
    d: 'Producing elements of slide fasteners; Combined making and attaching of elements of slide fasteners',
  },
  {
    c: 'B29D 7',
    d: 'Producing flat articles, e.g. films or sheets',
  },
  {
    c: 'B29D 11',
    d: 'Producing optical elements, e.g. lenses or prisms',
  },
  {
    c: 'B29D 12',
    d: 'Producing frames',
  },
  {
    c: 'B29D 15',
    d: 'Producing gear wheels or similar articles with grooves or projections, e.g. control knobs',
  },
  {
    c: 'B29D 16',
    d: 'Producing articles with corrugations',
  },
  {
    c: 'B29D 17',
    d: 'Producing carriers of records containing fine grooves or impressions, e.g. disc records for needle playback or cylinder records; Producing record discs from master stencils',
  },
  {
    c: 'B29D 19',
    d: 'Producing buttons or semi-finished parts of buttons',
  },
  {
    c: 'B29D 21',
    d: 'Producing hair combs or similar toothed or slotted articles',
  },
  {
    c: 'B29D 22',
    d: 'Producing hollow articles',
  },
  {
    c: 'B29D 23',
    d: 'Producing tubular articles',
  },
  {
    c: 'B29D 24',
    d: 'Producing articles with hollow walls',
  },
  {
    c: 'B29D 25',
    d: 'Producing frameless domes',
  },
  {
    c: 'B29D 28',
    d: 'Producing nets or the like',
  },
  {
    c: 'B29D 29',
    d: 'Producing belts or bands',
  },
  {
    c: 'B29D 30',
    d: 'Producing pneumatic or solid tyres or parts thereof',
  },
  {
    c: 'B29D 33',
    d: 'Producing bushes for bearings',
  },
  {
    c: 'B29D 35',
    d: 'Producing footwear',
  },
  {
    c: 'B29D 99',
    d: 'n.a.',
  },
  {
    c: 'B29K',
    d: 'INDEXING SCHEME ASSOCIATED WITH SUBCLASSES B29B, B29C OR B29D, RELATING TO MOULDING MATERIALS OR TO MATERIALS FOR REINFORCEMENTS, FILLERS OR PREFORMED PARTS, e.g. INSERTS',
  },
  {
    c: 'B29K 1',
    d: 'Compositions for moulding materials; Condition, form or state of moulded material',
  },
  {
    c: 'B29K 7',
    d: 'Use of natural rubber as moulding material',
  },
  {
    c: 'B29K 9',
    d: 'Use of rubber derived from conjugated dienes, as moulding material',
  },
  {
    c: 'B29K 19',
    d: 'Use of rubber not provided for in a single one of main groups B29K0007000000-B29K0009000000, as moulding material',
  },
  {
    c: 'B29K 21',
    d: 'Use of unspecified rubbers as moulding material',
  },
  {
    c: 'B29K 23',
    d: 'Use of polyalkenes as moulding material',
  },
  {
    c: 'B29K 25',
    d: 'Use of polymers of vinyl-aromatic compounds as moulding material',
  },
  {
    c: 'B29K 27',
    d: 'Use of polyvinylhalogenides as moulding material',
  },
  {
    c: 'B29K 29',
    d: 'Use of polyvinylalcohols, polyvinylethers, polyvinylaldehydes, polyvinylketones or polyvinylketals as moulding material',
  },
  {
    c: 'B29K 31',
    d: 'Use of polyvinylesters as moulding material',
  },
  {
    c: 'B29K 33',
    d: 'Use of polymers of unsaturated acids or derivatives thereof, as moulding material',
  },
  {
    c: 'B29K 35',
    d: 'Use of polymers of unsaturated polycarboxylic acids as moulding material',
  },
  {
    c: 'B29K 45',
    d: 'Use of polymers of unsaturated cyclic compounds having no unsaturated aliphatic groups in a side-chain, e.g. coumarone-indene resins, as moulding material',
  },
  {
    c: 'B29K 55',
    d: 'Use of specific polymers obtained by polymerisation reactions only involving carbon-to-carbon unsaturated bonds, not provided for in a single one of main groups B29K0023000000-B29K0045000000, as moulding material',
  },
  {
    c: 'B29K 59',
    d: 'Use of polyacetals as moulding material',
  },
  {
    c: 'B29K 61',
    d: 'Use of condensation polymers of aldehydes or ketones, as moulding material',
  },
  {
    c: 'B29K 63',
    d: 'Use of epoxy resins as moulding material',
  },
  {
    c: 'B29K 67',
    d: 'Use of polyesters as moulding material',
  },
  {
    c: 'B29K 69',
    d: 'Use of polycarbonates as moulding material',
  },
  {
    c: 'B29K 71',
    d: 'Use of polyethers as moulding material',
  },
  {
    c: 'B29K 73',
    d: 'Use of other polymers having oxygen as the only hetero atom in the main chain, as moulding material',
  },
  {
    c: 'B29K 75',
    d: 'Use of polyureas or polyurethanes as moulding material',
  },
  {
    c: 'B29K 77',
    d: 'Use of polyamides, e.g. polyesteramides, as moulding material',
  },
  {
    c: 'B29K 79',
    d: 'Use of other polymers having nitrogen, with or without oxygen or carbon only, in the main chain, as moulding material',
  },
  {
    c: 'B29K 81',
    d: 'Use of polymers having sulfur, with or without nitrogen, oxygen or carbon only, in the main chain, as moulding material',
  },
  {
    c: 'B29K 83',
    d: 'Use of polymers having silicon, with or without sulfur, nitrogen, oxygen or carbon only, in the main chain, as moulding material',
  },
  {
    c: 'B29K 85',
    d: 'Use of polymers having elements other than silicon, nitrogen, oxygen or carbon only, in the main chain, as moulding material',
  },
  {
    c: 'B29K 86',
    d: 'Use of specific polymers obtained by polycondensation or polyaddition, not provided for in a single one of main groups B29K0059000000-B29K0085000000, as moulding material',
  },
  {
    c: 'B29K 91',
    d: 'Use of waxes as moulding material',
  },
  {
    c: 'B29K 95',
    d: 'Use of bituminous materials as moulding material',
  },
  {
    c: 'B29K 96',
    d: 'Use of specified macromolecular materials not provided for in a single one of main groups B29K0001000000-B29K0095000000, as moulding material',
  },
  {
    c: 'B29K 101',
    d: 'Use of unspecified macromolecular compounds as moulding material',
  },
  {
    c: 'B29K 103',
    d: 'Use of resin-bonded materials as moulding material',
  },
  {
    c: 'B29K 105',
    d: 'Condition, form or state of moulded material',
  },
  {
    c: 'B29K 201',
    d: 'Compositions for reinforcements',
  },
  {
    c: 'B29K 207',
    d: 'Use of natural rubber as reinforcement',
  },
  {
    c: 'B29K 209',
    d: 'Use of rubber derived from conjugated dienes, as reinforcement',
  },
  {
    c: 'B29K 219',
    d: 'Use of rubber not provided for in a single one of main groups B29K0207000000 or B29K0209000000, as reinforcement',
  },
  {
    c: 'B29K 221',
    d: 'Use of unspecified rubbers as reinforcement',
  },
  {
    c: 'B29K 223',
    d: 'Use of polyalkenes as reinforcement',
  },
  {
    c: 'B29K 225',
    d: 'Use of polymers of vinyl-aromatic compounds as reinforcement',
  },
  {
    c: 'B29K 227',
    d: 'Use of polyvinylhalogenides as reinforcement',
  },
  {
    c: 'B29K 229',
    d: 'Use of polyvinylalcohols, polyvinylethers, polyvinylaldehydes, polyvinylketones or polyvinylketals as reinforcement',
  },
  {
    c: 'B29K 231',
    d: 'Use of polyvinylesters as reinforcement',
  },
  {
    c: 'B29K 233',
    d: 'Use of polymers of unsaturated acids or derivatives thereof, as reinforcement',
  },
  {
    c: 'B29K 235',
    d: 'Use of polymers of unsaturated polycarboxylic acids as reinforcement',
  },
  {
    c: 'B29K 245',
    d: 'Use of polymers of unsaturated cyclic compounds having no unsaturated aliphatic groups in a side-chain, e.g. coumarone-indene resins, as reinforcement',
  },
  {
    c: 'B29K 255',
    d: 'Use of specific polymers obtained by polymerisation reactions only involving carbon-to-carbon unsaturated bonds, not provided for in a single one of main groups B29K0223000000-B29K0245000000, as reinforcement',
  },
  {
    c: 'B29K 259',
    d: 'Use of polyacetals as reinforcement',
  },
  {
    c: 'B29K 261',
    d: 'Use of condensation polymers of aldehydes or ketones, as reinforcement',
  },
  {
    c: 'B29K 263',
    d: 'Use of epoxy resins as reinforcement',
  },
  {
    c: 'B29K 267',
    d: 'Use of polyesters as reinforcement',
  },
  {
    c: 'B29K 269',
    d: 'Use of polycarbonates as reinforcement',
  },
  {
    c: 'B29K 271',
    d: 'Use of polyethers as reinforcement',
  },
  {
    c: 'B29K 273',
    d: 'Use of other polymers having oxygen as the only hetero atom in the main chain, as reinforcement',
  },
  {
    c: 'B29K 275',
    d: 'Use of polyureas or polyurethanes as reinforcement',
  },
  {
    c: 'B29K 277',
    d: 'Use of polyamides, e.g. polyesteramides, as reinforcement',
  },
  {
    c: 'B29K 279',
    d: 'Use of other polymers having nitrogen, with or without oxygen or carbon only, in the main chain, as reinforcement',
  },
  {
    c: 'B29K 281',
    d: 'Use of polymers having sulfur, with or without nitrogen, oxygen or carbon only, in the main chain, as reinforcement',
  },
  {
    c: 'B29K 283',
    d: 'Use of polymers having silicon, with or without sulfur, nitrogen, oxygen or carbon only, in the main chain, as reinforcement',
  },
  {
    c: 'B29K 285',
    d: 'Use of polymers having elements other than silicon, nitrogen, oxygen or carbon only, in the main chain, as reinforcement',
  },
  {
    c: 'B29K 286',
    d: 'Use of specific polymers obtained by polycondensation or polyaddition, not provided for in a single one of main groups B29K0259000000-B29K0285000000, as reinforcement',
  },
  {
    c: 'B29K 295',
    d: 'Use of bituminous materials as reinforcement',
  },
  {
    c: 'B29K 296',
    d: 'Use of specific macromolecular materials not provided for in a single one of main groups B29K0201000000-B29K0295000000, as reinforcement',
  },
  {
    c: 'B29K 301',
    d: 'Use of unspecified macromolecular compounds as reinforcement',
  },
  {
    c: 'B29K 303',
    d: 'Use of resin-bonded materials as reinforcement',
  },
  {
    c: 'B29K 305',
    d: 'Use of metals, their alloys or their compounds, as reinforcement',
  },
  {
    c: 'B29K 307',
    d: 'Use of elements other than metals as reinforcement',
  },
  {
    c: 'B29K 309',
    d: 'Use of inorganic materials not provided for in groups B29K0303000000-B29K0307000000, as reinforcement',
  },
  {
    c: 'B29K 311',
    d: 'Use of natural products or their composites, not provided for in groups B29K0201000000-B29K0309000000, as reinforcement',
  },
  {
    c: 'B29K 401',
    d: 'Compositions for fillers',
  },
  {
    c: 'B29K 407',
    d: 'Use of natural rubber as filler',
  },
  {
    c: 'B29K 409',
    d: 'Use of rubber derived from conjugated dienes, as filler',
  },
  {
    c: 'B29K 419',
    d: 'Use of rubber not provided for in a single one of main groupsB29K0407000000 or B29K0409000000, as filler',
  },
  {
    c: 'B29K 421',
    d: 'Use of unspecified rubbers as filler',
  },
  {
    c: 'B29K 423',
    d: 'Use of polyalkenes as filler',
  },
  {
    c: 'B29K 425',
    d: 'Use of polymers of vinyl-aromatic compounds as filler',
  },
  {
    c: 'B29K 427',
    d: 'Use of polyvinylhalogenides as filler',
  },
  {
    c: 'B29K 429',
    d: 'Use of polyvinylalcohols, polyvinylethers, polyvinylaldehydes, polyvinylketones or polyvinylketals as filler',
  },
  {
    c: 'B29K 431',
    d: 'Use of polyvinylesters as filler',
  },
  {
    c: 'B29K 433',
    d: 'Use of polymers of unsaturated acids or derivatives thereof, as filler',
  },
  {
    c: 'B29K 435',
    d: 'Use of polymers of unsaturated polycarboxylic acids as filler',
  },
  {
    c: 'B29K 445',
    d: 'Use of polymers of unsaturated cyclic compounds having no unsaturated aliphatic groups in a side-chain, e.g. coumarone-indene resins, as filler',
  },
  {
    c: 'B29K 455',
    d: 'Use of specific polymers obtained by polymerisation reactions only involving carbon-to-carbon unsaturated bonds, not provided for in a single one of main groups B29K0423000000-B29K0445000000, as filler',
  },
  {
    c: 'B29K 459',
    d: 'Use of polyacetals as filler',
  },
  {
    c: 'B29K 461',
    d: 'Use of condensation polymers of aldehydes or ketones, as filler',
  },
  {
    c: 'B29K 463',
    d: 'Use of epoxy resins as filler',
  },
  {
    c: 'B29K 467',
    d: 'Use of polyesters as filler',
  },
  {
    c: 'B29K 469',
    d: 'Use of polycarbonates as filler',
  },
  {
    c: 'B29K 471',
    d: 'Use of polyethers as filler',
  },
  {
    c: 'B29K 473',
    d: 'Use of other polymers having oxygen as the only hetero atom in the main chain, as filler',
  },
  {
    c: 'B29K 475',
    d: 'Use of polyureas or polyurethanes as filler',
  },
  {
    c: 'B29K 477',
    d: 'Use of polyamides, e.g. polyesteramides, as filler',
  },
  {
    c: 'B29K 479',
    d: 'Use of other polymers having nitrogen, with or without oxygen or carbon only, in the main chain, as filler',
  },
  {
    c: 'B29K 481',
    d: 'Use of polymers having sulfur, with or without nitrogen, oxygen or carbon only, in the main chain, as filler',
  },
  {
    c: 'B29K 483',
    d: 'Use of polymers having silicon, with or without sulfur, nitrogen, oxygen or carbon only, in the main chain, as filler',
  },
  {
    c: 'B29K 485',
    d: 'Use of polymers having elements other than silicon, nitrogen, oxygen or carbon only, in the main chain, as filler',
  },
  {
    c: 'B29K 486',
    d: 'Use of specific polymers obtained by polycondensation or polyaddition, not provided for in a single one of main groups B29K0459000000-B29K0485000000, as filler',
  },
  {
    c: 'B29K 491',
    d: 'Use of waxes as filler',
  },
  {
    c: 'B29K 495',
    d: 'Use of bituminous materials as filler',
  },
  {
    c: 'B29K 496',
    d: 'Use of specific macromolecular materials not provided for in a single one of main groups B29K0401000000-B29K0495000000, as filler',
  },
  {
    c: 'B29K 501',
    d: 'Use of unspecified macromolecular compounds as filler',
  },
  {
    c: 'B29K 503',
    d: 'Use of resin-bonded materials as filler',
  },
  {
    c: 'B29K 505',
    d: 'Use of metals, their alloys or their compounds, as filler',
  },
  {
    c: 'B29K 507',
    d: 'Use of elements other than metals as filler',
  },
  {
    c: 'B29K 509',
    d: 'Use of inorganic materials not provided for in groups B29K0503000000-B29K0507000000, as filler',
  },
  {
    c: 'B29K 511',
    d: 'Use of natural products or their composites, not provided for in groups B29K0401000000-B29K0509000000, as filler',
  },
  {
    c: 'B29K 601',
    d: 'Compositions for preformed parts, e.g. inserts',
  },
  {
    c: 'B29K 607',
    d: 'Use of natural rubber for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 609',
    d: 'Use of rubber derived from conjugated dienes, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 619',
    d: 'Use of rubber not provided for in a single one of main groups B29K0607000000 or B29K0609000000, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 621',
    d: 'Use of unspecified rubbers for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 623',
    d: 'Use of polyalkenes for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 625',
    d: 'Use of polymers of vinyl-aromatic compounds for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 627',
    d: 'Use of polyvinylhalogenides for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 629',
    d: 'Use of polyvinylalcohols, polyvinylethers, polyvinylaldehydes, polyvinylketones or polyvinylketals for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 631',
    d: 'Use of polyvinylesters for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 633',
    d: 'Use of polymers of unsaturated acids or derivatives thereof, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 635',
    d: 'Use of polymers of unsaturated polycarboxylic acids for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 645',
    d: 'Use of polymers of unsaturated cyclic compounds having no unsaturated aliphatic groups in a side-chain, e.g. coumarone-indene resins, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 655',
    d: 'Use of specific polymers obtained by polymerisation reactions only involving carbon-to-carbon unsaturated bonds, not provided for in a single one of main groups B29K0623000000-B29K0645000000, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 659',
    d: 'Use of polyacetals for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 661',
    d: 'Use of condensation polymers of aldehydes or ketones, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 663',
    d: 'Use of epoxy resins for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 667',
    d: 'Use of polyesters for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 669',
    d: 'Use of polycarbonates for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 671',
    d: 'Use of polyethers for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 673',
    d: 'Use of other polymers having oxygen as the only hetero atom in the main chain, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 675',
    d: 'Use of polyureas or polyurethanes for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 677',
    d: 'Use of polyamides, e.g. polyesteramides, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 679',
    d: 'Use of other polymers having nitrogen, with or without oxygen or carbon only, in the main chain, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 681',
    d: 'Use of polymers having sulfur, with or without nitrogen, oxygen or carbon only, in the main chain, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 683',
    d: 'Use of polymers having silicon, with or without sulfur, nitrogen, oxygen or carbon only, in the main chain, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 685',
    d: 'Use of polymers having elements other than silicon, nitrogen, oxygen or carbon only, in the main chain, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 686',
    d: 'Use of specific polymers obtained by polycondensation or polyaddition, not provided for in a single one of main groups B29K0659000000-B29K0685000000, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 691',
    d: 'Use of waxes for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 695',
    d: 'Use of bituminous materials for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 696',
    d: 'Use of specific macromolecular materials not provided for in a single one of main groups B29K0601000000-B29K0695000000, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 701',
    d: 'Use of unspecified macromolecular compounds for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 703',
    d: 'Use of resin-bonded materials for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 705',
    d: 'Use of metals, their alloys or their compounds, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 707',
    d: 'Use of elements other than metals for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 709',
    d: 'Use of inorganic materials not provided for in groups B29K0703000000-B29K0707000000, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29K 711',
    d: 'Use of natural products or their composites, not provided for in groups B29K0601000000-B29K0709000000, for preformed parts, e.g. for inserts',
  },
  {
    c: 'B29L',
    d: 'INDEXING SCHEME ASSOCIATED WITH SUBCLASS B29C, RELATING TO PARTICULAR ARTICLES',
  },
  {
    c: 'B29L 1',
    d: 'Articles provided with screw threads',
  },
  {
    c: 'B29L 5',
    d: 'Elements of slide fasteners',
  },
  {
    c: 'B29L 7',
    d: 'Flat articles, e.g. films or sheets',
  },
  {
    c: 'B29L 9',
    d: 'Layered products',
  },
  {
    c: 'B29L 11',
    d: 'Optical elements, e.g. lenses, prisms',
  },
  {
    c: 'B29L 12',
    d: 'Frames',
  },
  {
    c: 'B29L 15',
    d: 'Gear wheels or similar articles with grooves or projections, e.g. control knobs',
  },
  {
    c: 'B29L 16',
    d: 'Articles with corrugations',
  },
  {
    c: 'B29L 17',
    d: 'Carriers of records containing fine grooves or impressions, e.g. disc records for needle playback, cylinder records',
  },
  {
    c: 'B29L 19',
    d: 'Buttons or semi-finished parts of buttons',
  },
  {
    c: 'B29L 21',
    d: 'Hair combs or similar toothed or slotted articles',
  },
  {
    c: 'B29L 22',
    d: 'Hollow articles',
  },
  {
    c: 'B29L 23',
    d: 'Tubular articles',
  },
  {
    c: 'B29L 24',
    d: 'Articles with hollow walls',
  },
  {
    c: 'B29L 25',
    d: 'Frameless domes',
  },
  {
    c: 'B29L 28',
    d: 'Nets or the like',
  },
  {
    c: 'B29L 29',
    d: 'Belts or bands',
  },
  {
    c: 'B29L 30',
    d: 'Pneumatic or solid tyres or parts thereof',
  },
  {
    c: 'B29L 31',
    d: 'Other particular articles',
  },
  {
    c: 'B30',
    d: 'PRESSES',
  },
  {
    c: 'B30B',
    d: 'PRESSES IN GENERAL; PRESSES NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'B30B 1',
    d: 'Presses, using a press ram, characterised by the features of the drive therefor, pressure being transmitted directly, or through simple thrust or tension members only, to the press ram or platen',
  },
  {
    c: 'B30B 3',
    d: 'Presses characterised by the use of rotary pressing members, e.g. rollers, rings, discs',
  },
  {
    c: 'B30B 5',
    d: 'Presses characterised by the use of pressing means other than those mentioned in groups B30B0001000000 and B30B0003000000',
  },
  {
    c: 'B30B 7',
    d: 'Presses characterised by a particular arrangement of the pressing members',
  },
  {
    c: 'B30B 9',
    d: 'Presses specially adapted for particular purposes',
  },
  {
    c: 'B30B 11',
    d: 'Presses specially adapted for forming shaped articles from material in particulate or plastic state, e.g. briquetting presses or tabletting presses',
  },
  {
    c: 'B30B 12',
    d: 'Presses not provided for in groups B30B0001000000-B30B0011000000',
  },
  {
    c: 'B30B 13',
    d: 'Methods of pressing not special to the use of presses of any one of the preceding main groups B30B0001000000-B30B0012000000',
  },
  {
    c: 'B30B 15',
    d: 'Details of, or accessories for, presses; Auxiliary measures in connection with pressing',
  },
  {
    c: 'B31',
    d: 'MAKING ARTICLES OF PAPER, CARDBOARD OR MATERIAL WORKED IN A MANNER ANALOGOUS TO PAPER; WORKING PAPER, CARDBOARD OR MATERIAL WORKED IN A MANNER ANALOGOUS TO PAPER',
  },
  {
    c: 'B31B',
    d: 'MAKING CONTAINERS OF PAPER, CARDBOARD OR MATERIAL WORKED IN A MANNER ANALOGOUS TO PAPER',
  },
  {
    c: 'B31B 50',
    d: 'Making rigid or semi-rigid containers, e.g. boxes or cartons',
  },
  {
    c: 'B31B 70',
    d: 'Making flexible containers, e.g. envelopes or bags',
  },
  {
    c: 'B31B 100',
    d: 'Indexing scheme associated with group B31B0050000000, relating to making of rigid or semi-rigid containers',
  },
  {
    c: 'B31B 105',
    d: 'Rigid or semi-rigid containers made by assembling separate sheets, blanks or webs',
  },
  {
    c: 'B31B 110',
    d: 'Shape of rigid or semi-rigid containers',
  },
  {
    c: 'B31B 120',
    d: 'Construction of rigid or semi-rigid containers',
  },
  {
    c: 'B31B 150',
    d: 'Indexing scheme associated with group B31B0070000000, relating to making of flexible containers',
  },
  {
    c: 'B31B 155',
    d: 'Flexible containers made from webs',
  },
  {
    c: 'B31B 160',
    d: 'Shape of flexible containers',
  },
  {
    c: 'B31B 170',
    d: 'Construction of flexible containers',
  },
  {
    c: 'B31C',
    d: 'MAKING WOUND ARTICLES, e.g. WOUND TUBES, OF PAPER, CARDBOARD OR MATERIAL WORDED IN A MANNER ANALOGOUS TO PAPER',
  },
  {
    c: 'B31C 1',
    d: 'Making tubes or pipes by feeding at right angles to the winding mandrel centre line',
  },
  {
    c: 'B31C 3',
    d: 'Making tubes or pipes by feeding obliquely to the winding mandrel centre line',
  },
  {
    c: 'B31C 5',
    d: 'Making tubes or pipes without using mandrels',
  },
  {
    c: 'B31C 7',
    d: 'Making conical articles by winding',
  },
  {
    c: 'B31C 9',
    d: 'Simultaneous forming of cylindrical and conical shapes by winding separate webs, e.g. forming bottles',
  },
  {
    c: 'B31C 11',
    d: 'Machinery for winding combined with other machinery',
  },
  {
    c: 'B31C 99',
    d: 'n.a.',
  },
  {
    c: 'B31D',
    d: 'MAKING ARTICLES OF PAPER, CARDBOARD OR MATERIAL WORKED IN A MANNER ANALOGOUS TO PAPER, NOT PROVIDED FOR IN SUBCLASSES B31B OR B31C',
  },
  {
    c: 'B31D 1',
    d: 'Multiple-step processes for making flat articles',
  },
  {
    c: 'B31D 3',
    d: 'Making articles of cellular structure, e.g. insulating board',
  },
  {
    c: 'B31D 5',
    d: 'Multiple-step processes for making three-dimensional articles',
  },
  {
    c: 'B31D 99',
    d: 'n.a.',
  },
  {
    c: 'B31F',
    d: 'MECHANICAL WORKING OR DEFORMATION OF PAPER, CARDBOARD OR MATERIAL WORKED IN A MANNER ANALOGOUS TO PAPER',
  },
  {
    c: 'B31F 1',
    d: 'Mechanical deformation without removing material, e.g. in combination with laminating',
  },
  {
    c: 'B31F 5',
    d: 'Attaching together sheets, strips or webs; Reinforcing edges',
  },
  {
    c: 'B31F 7',
    d: 'Processes not otherwise provided for',
  },
  {
    c: 'B32',
    d: 'LAYERED PRODUCTS',
  },
  {
    c: 'B32B',
    d: 'LAYERED PRODUCTS, i.e. PRODUCTS BUILT-UP OF STRATA OF FLAT OR NON-FLAT, e.g. CELLULAR OR HONEYCOMB, FORM',
  },
  {
    c: 'B32B 1',
    d: 'Layered products essentially having a general shape other than plane',
  },
  {
    c: 'B32B 3',
    d: 'Layered products essentially comprising a layer with external or internal discontinuities or unevennesses, or a layer of non-planar form',
  },
  {
    c: 'B32B 5',
    d: 'Layered products characterised by the non-homogeneity or physical structure of a layer',
  },
  {
    c: 'B32B 7',
    d: 'Layered products characterised by the relation between layers; Layered products characterised by the relative orientation of features between layers, or by the relative values of a measurable parameter between layers, i.e. products comprising layers having different physical, chemical or physicochemical properties; Layered products characterised by the interconnection of layers',
  },
  {
    c: 'B32B 9',
    d: 'Layered products characterised by particular substances used',
  },
  {
    c: 'B32B 11',
    d: 'Layered products essentially comprising bituminous or tarry substances',
  },
  {
    c: 'B32B 13',
    d: "Layered products essentially comprising a water-setting substance, e.g. concrete, plaster, asbestos cement, or like builders' material",
  },
  {
    c: 'B32B 15',
    d: 'Layered products essentially comprising metal',
  },
  {
    c: 'B32B 17',
    d: 'Layered products essentially comprising sheet glass, or fibres of glass, slag or the like',
  },
  {
    c: 'B32B 18',
    d: 'Layered products essentially comprising ceramics, e.g. refractory products',
  },
  {
    c: 'B32B 19',
    d: 'Layered products essentially comprising natural mineral fibres or particles, e.g. asbestos, mica',
  },
  {
    c: 'B32B 21',
    d: 'Layered products essentially comprising wood, e.g. wood board, veneer, wood particle board',
  },
  {
    c: 'B32B 23',
    d: 'Layered products essentially comprising cellulosic plastic substances',
  },
  {
    c: 'B32B 25',
    d: 'Layered products essentially comprising natural or synthetic rubber',
  },
  {
    c: 'B32B 27',
    d: 'Layered products essentially comprising synthetic resin',
  },
  {
    c: 'B32B 29',
    d: 'Layered products essentially comprising paper or cardboard',
  },
  {
    c: 'B32B 33',
    d: 'Layered products characterised by particular properties or particular surface features, e.g. particular surface coatings',
  },
  {
    c: 'B32B 37',
    d: 'Methods or apparatus for making layered products; Treatment of the layers or of the layered products',
  },
  {
    c: 'B32B 38',
    d: 'Ancillary operations in connection with laminating processes',
  },
  {
    c: 'B32B 39',
    d: 'Layout of apparatus or plants, e.g. modular laminating systems',
  },
  {
    c: 'B32B 41',
    d: 'Arrangements for controlling or monitoring lamination processes; Safety arrangements',
  },
  {
    c: 'B32B 43',
    d: 'Operations specially adapted for layered products and not otherwise provided for, e.g. repairing; Apparatus therefor',
  },
  {
    c: 'B33',
    d: 'ADDITIVE MANUFACTURING TECHNOLOGY',
  },
  {
    c: 'B33Y',
    d: 'ADDITIVE MANUFACTURING, i.e. MANUFACTURING OF THREE-DIMENSIONAL [3D] OBJECTS BY ADDITIVE DEPOSITION, ADDITIVE AGGLOMERATION OR ADDITIVE LAYERING, e.g. BY 3D PRINTING, STEREOLITHOGRAPHY OR SELECTIVE LASER SINTERING',
  },
  {
    c: 'B33Y 10',
    d: 'Processes of additive manufacturing',
  },
  {
    c: 'B33Y 30',
    d: 'Apparatus for additive manufacturing; Details thereof or accessories therefor',
  },
  {
    c: 'B33Y 40',
    d: 'Auxiliary operations or equipment, e.g. for material handling',
  },
  {
    c: 'B33Y 50',
    d: 'Data acquisition or data processing for additive manufacturing',
  },
  {
    c: 'B33Y 70',
    d: 'Materials specially adapted for additive manufacturing',
  },
  {
    c: 'B33Y 80',
    d: 'Products made by additive manufacturing',
  },
  {
    c: 'B33Y 99',
    d: 'n.a.',
  },
  {
    c: 'B41',
    d: 'PRINTING; LINING MACHINES; TYPEWRITERS; STAMPS',
  },
  {
    c: 'B41B',
    d: 'MACHINES OR ACCESSORIES FOR MAKING, SETTING, OR DISTRIBUTING TYPE; TYPE; PHOTOGRAPHIC OR PHOTOELECTRONIC COMPOSING DEVICES',
  },
  {
    c: 'B41B 1',
    d: 'Elements or appliances for hand composition; Chases, quoins, or galleys',
  },
  {
    c: 'B41B 3',
    d: 'Machines or other mechanical apparatus for composing',
  },
  {
    c: 'B41B 5',
    d: 'Devices for making type or lines',
  },
  {
    c: 'B41B 7',
    d: 'Kinds or types of apparatus for mechanical composition in which the type is cast or moulded from matrices',
  },
  {
    c: 'B41B 9',
    d: 'Details of, or accessories for, machines for mechanical composition using fixed combinations of matrices from which type is cast or moulded',
  },
  {
    c: 'B41B 11',
    d: 'Details of, or accessories for, machines for mechanical composition using matrices for individual characters which are selected and assembled for type casting or moulding',
  },
  {
    c: 'B41B 13',
    d: 'Photographic or photoelectronic composing devices',
  },
  {
    c: 'B41B 15',
    d: 'Photographic composing machines with movable character carriers for composing lines of characters prior to photography',
  },
  {
    c: 'B41B 17',
    d: 'Photographic composing machines having fixed or movable character carriers and without means for composing lines prior to photography',
  },
  {
    c: 'B41B 19',
    d: 'Photoelectronic composing machines',
  },
  {
    c: 'B41B 21',
    d: 'Common details of photographic composing machines of the kinds covered in groups B41B0017000000 and B41B0019000000',
  },
  {
    c: 'B41B 23',
    d: 'Auxiliary devices for modifying, mixing, or correcting text or layout in connection with photographic or photoelectronic composing',
  },
  {
    c: 'B41B 25',
    d: 'Marking record carriers used for controlling composing machines; Control, indicating, or safety devices or systems for composing machines of various kinds or types',
  },
  {
    c: 'B41B 27',
    d: 'Control, indicating, or safety devices or systems for composing machines of various kinds or types',
  },
  {
    c: 'B41C',
    d: 'PROCESSES FOR THE MANUFACTURE OR REPRODUCTION OF PRINTING SURFACES',
  },
  {
    c: 'B41C 1',
    d: 'Forme preparation',
  },
  {
    c: 'B41C 3',
    d: 'Reproduction or duplicating of printing formes',
  },
  {
    c: 'B41D',
    d: 'APPARATUS FOR THE MECHANICAL REPRODUCTION OF PRINTING SURFACES FOR STEREOTYPE PRINTING; SHAPING ELASTIC OR DEFORMABLE MATERIAL TO FORM PRINTING SURFACES',
  },
  {
    c: 'B41D 1',
    d: 'Preparing or treating stereotype matrices',
  },
  {
    c: 'B41D 3',
    d: 'Producing printing surfaces',
  },
  {
    c: 'B41D 5',
    d: 'Working, treating, or handling stereotype plates',
  },
  {
    c: 'B41D 7',
    d: 'Shaping elastic or deformable material, e.g. rubber, plastics material, to form printing surfaces',
  },
  {
    c: 'B41D 99',
    d: 'n.a.',
  },
  {
    c: 'B41F',
    d: 'PRINTING MACHINES OR PRESSES',
  },
  {
    c: 'B41F 1',
    d: 'Platen presses, i.e. presses in which printing is effected by at least one essentially-flat pressure-applying member co-operating with a flat type-bed',
  },
  {
    c: 'B41F 3',
    d: 'Cylinder presses, i.e. presses essentially comprising at least one cylinder co-operating with at least one flat type-bed',
  },
  {
    c: 'B41F 5',
    d: 'Rotary machines',
  },
  {
    c: 'B41F 7',
    d: 'Rotary lithographic machines',
  },
  {
    c: 'B41F 9',
    d: 'Rotary intaglio printing presses',
  },
  {
    c: 'B41F 11',
    d: 'Rotary presses or machines having forme cylinders carrying a plurality of printing surfaces, or for performing letterpress, lithographic, or intaglio processes selectively or in combination',
  },
  {
    c: 'B41F 13',
    d: 'Common details of rotary presses or machines',
  },
  {
    c: 'B41F 15',
    d: 'Screen printers',
  },
  {
    c: 'B41F 16',
    d: 'Transfer printing apparatus',
  },
  {
    c: 'B41F 17',
    d: 'Printing apparatus or machines of special types or for particular purposes, not otherwise provided for',
  },
  {
    c: 'B41F 19',
    d: 'Apparatus or machines for carrying out printing operations combined with other operations',
  },
  {
    c: 'B41F 21',
    d: 'Common details of printing machinery',
  },
  {
    c: 'B41F 22',
    d: 'Means preventing smudging of machine parts or printed articles',
  },
  {
    c: 'B41F 23',
    d: 'Devices for treating the surfaces of sheets, webs or other articles in connection with printing',
  },
  {
    c: 'B41F 25',
    d: 'Devices for pressing sheets or webs against cylinders, e.g. for smoothing purposes',
  },
  {
    c: 'B41F 27',
    d: 'Devices for attaching printing elements or formes to supports',
  },
  {
    c: 'B41F 30',
    d: 'Devices for attaching coverings or make-ready devices; Guiding devices for coverings',
  },
  {
    c: 'B41F 31',
    d: 'Inking arrangements or devices',
  },
  {
    c: 'B41F 33',
    d: 'Indicating, counting, warning, control or safety devices',
  },
  {
    c: 'B41F 35',
    d: 'Cleaning arrangements or devices',
  },
  {
    c: 'B41G',
    d: 'APPARATUS FOR BRONZE PRINTING, LINE PRINTING, OR FOR BORDERING OR EDGING SHEETS OR LIKE ARTICLES; AUXILIARY APPARATUS FOR PERFORATING IN CONJUNCTION WITH PRINTING',
  },
  {
    c: 'B41G 1',
    d: 'Apparatus for bronze printing or for like operations',
  },
  {
    c: 'B41G 3',
    d: 'Apparatus for printing lines',
  },
  {
    c: 'B41G 5',
    d: 'Apparatus for bordering or edging sheets or like articles, e.g. for producing black rims on mourning cards',
  },
  {
    c: 'B41G 7',
    d: 'Auxiliary perforating apparatus associated with printing devices',
  },
  {
    c: 'B41J',
    d: 'TYPEWRITERS; SELECTIVE PRINTING MECHANISMS, i.e. MECHANISMS PRINTING OTHERWISE THAN FROM A FORME; CORRECTION OF TYPOGRAPHICAL ERRORS',
  },
  {
    c: 'B41J 1',
    d: 'Kinds of typewriters or of selective printing mechanisms',
  },
  {
    c: 'B41J 2',
    d: 'Typewriters or selective printing mechanisms characterised by the printing or marking process for which they are designed',
  },
  {
    c: 'B41J 3',
    d: 'Typewriters or selective printing or marking mechanisms characterised by the purpose for which they are constructed',
  },
  {
    c: 'B41J 5',
    d: 'Common details or accessories',
  },
  {
    c: 'B41J 7',
    d: 'Type-selecting or type-actuating mechanisms',
  },
  {
    c: 'B41J 9',
    d: 'Hammer-impression mechanisms',
  },
  {
    c: 'B41J 11',
    d: 'Devices or arrangements for supporting or handling copy material in sheet or web form',
  },
  {
    c: 'B41J 13',
    d: 'Devices or arrangements specially adapted for supporting or handling copy material in short lengths, e.g. sheets',
  },
  {
    c: 'B41J 15',
    d: 'Devices or arrangements specially adapted for supporting or handling copy material in continuous form, e.g. webs',
  },
  {
    c: 'B41J 17',
    d: 'Mechanisms for manipulating page-width impression-transfer material, e.g. carbon paper',
  },
  {
    c: 'B41J 19',
    d: 'Character- or line-spacing mechanisms',
  },
  {
    c: 'B41J 21',
    d: 'Column, tabular, or like printing arrangements; Means for centralising short lines',
  },
  {
    c: 'B41J 23',
    d: 'Power drives for actions or mechanisms',
  },
  {
    c: 'B41J 25',
    d: 'Actions or mechanisms not otherwise provided for',
  },
  {
    c: 'B41J 27',
    d: 'Inking apparatus',
  },
  {
    c: 'B41J 29',
    d: 'Details of, or accessories for, typewriters or selective printing mechanisms not otherwise provided for',
  },
  {
    c: 'B41J 31',
    d: 'Ink ribbons; Ink-ribbon mechanisms',
  },
  {
    c: 'B41J 32',
    d: 'Ink-ribbon cartridges',
  },
  {
    c: 'B41J 33',
    d: 'Apparatus or arrangements for feeding ink ribbons or like character-size impression-transfer material',
  },
  {
    c: 'B41J 35',
    d: 'Other apparatus or arrangements associated with, or incorporated in, ink-ribbon mechanisms',
  },
  {
    c: 'B41K',
    d: 'STAMPS; STAMPING OR NUMBERING APPARATUS OR DEVICES',
  },
  {
    c: 'B41K 1',
    d: 'Portable hand-operated devices without means for supporting or locating the articles to be stamped, i.e. hand stamps; Inking devices or other accessories therefor',
  },
  {
    c: 'B41K 3',
    d: 'Apparatus for stamping articles having integral means for supporting the articles to be stamped',
  },
  {
    c: 'B41K 5',
    d: 'Plier-like tools for stamping, or stamping and delivering, tickets or the like',
  },
  {
    c: 'B41K 99',
    d: 'n.a.',
  },
  {
    c: 'B41L',
    d: 'APPARATUS OR DEVICES FOR MANIFOLDING, DUPLICATING, OR PRINTING FOR OFFICE OR OTHER COMMERCIAL PURPOSES; ADDRESSING MACHINES OR LIKE SERIES-PRINTING MACHINES',
  },
  {
    c: 'B41L 1',
    d: 'Manifolding by means of pressure-sensitive layers or intermediaries',
  },
  {
    c: 'B41L 3',
    d: 'Platens or like sheet supports for manifolding using pressure-sensitive layers or intermediaries, e.g. for book-keeping purposes',
  },
  {
    c: 'B41L 5',
    d: 'Autographic registers or like apparatus for manifolding by means of pressure-sensitive layers, using movable strips or webs',
  },
  {
    c: 'B41L 7',
    d: 'Apparatus or machines for duplicating or printing for office or other commercial purposes',
  },
  {
    c: 'B41L 9',
    d: 'Apparatus for indirectly duplicating from hectographic originals by means of hectographic intermediaries or transfer surfaces, i.e. dry duplicators',
  },
  {
    c: 'B41L 11',
    d: 'Apparatus for directly duplicating from hectographic masters in mirror image, i.e. wet duplicators for producing positive copies',
  },
  {
    c: 'B41L 13',
    d: 'Stencilling apparatus for office or other commercial use',
  },
  {
    c: 'B41L 15',
    d: 'Letterpress printing apparatus specially adapted for office or other commercial purposes',
  },
  {
    c: 'B41L 17',
    d: 'Lithographic printing apparatus for office or other commercial purposes',
  },
  {
    c: 'B41L 19',
    d: 'Duplicating or printing apparatus or machines for office or other commercial purposes, of special types or for particular purposes and not otherwise provided for',
  },
  {
    c: 'B41L 21',
    d: 'Common details of, or accessories for, apparatus or machines for manifolding, duplicating, or printing for office or other commercial purposes',
  },
  {
    c: 'B41L 23',
    d: 'Devices for treating the surface of sheets, webs, or other articles in connection with printing',
  },
  {
    c: 'B41L 25',
    d: 'Devices for damping printing surfaces, e.g. moistening printing surfaces in connection with lithographic printing',
  },
  {
    c: 'B41L 27',
    d: 'Inking arrangements or devices',
  },
  {
    c: 'B41L 29',
    d: 'Devices for attaching printing elements or formes to supports',
  },
  {
    c: 'B41L 31',
    d: 'Devices for removing flexible printing formes from forme cylinders',
  },
  {
    c: 'B41L 33',
    d: 'Pressing flexible printing formes or sheets or webs of copy material against cylinders, e.g. for smoothing purposes',
  },
  {
    c: 'B41L 35',
    d: 'Cylinders for apparatus or machines for manifolding, duplicating, or printing for office or other commercial purposes',
  },
  {
    c: 'B41L 38',
    d: 'Devices for attaching coverings or make-ready devices; Guiding devices for coverings',
  },
  {
    c: 'B41L 39',
    d: 'Indicating, counting, warning, control or safety devices',
  },
  {
    c: 'B41L 41',
    d: 'Cleaning arrangements or devices',
  },
  {
    c: 'B41L 43',
    d: 'Auxiliary folding, collecting, or depositing of sheets or webs',
  },
  {
    c: 'B41L 45',
    d: 'Addressing machines or like series-printing machines',
  },
  {
    c: 'B41L 47',
    d: 'Details of addressing machines or like series-printing machines',
  },
  {
    c: 'B41L 49',
    d: 'Accessories or attachments for addressing machines or like series-printing machines',
  },
  {
    c: 'B41M',
    d: 'PRINTING, DUPLICATING, MARKING, OR COPYING PROCESSES; COLOUR PRINTING',
  },
  {
    c: 'B41M 1',
    d: "Inking and printing with a printer's forme",
  },
  {
    c: 'B41M 3',
    d: 'Printing processes to produce particular kinds of printed work, e.g. patterns',
  },
  {
    c: 'B41M 5',
    d: 'Duplicating or marking methods; Sheet materials for use therein',
  },
  {
    c: 'B41M 7',
    d: 'After-treatment of printed works, e.g. heating, irradiating',
  },
  {
    c: 'B41M 9',
    d: 'Processes wherein make-ready devices are used',
  },
  {
    c: 'B41M 99',
    d: 'n.a.',
  },
  {
    c: 'B41N',
    d: 'PRINTING PLATES OR FOILS',
  },
  {
    c: 'B41N 1',
    d: 'Printing plates or foils; Materials therefor',
  },
  {
    c: 'B41N 3',
    d: 'Preparing for use or conserving printing surfaces',
  },
  {
    c: 'B41N 6',
    d: 'Mounting boards; Make-ready devices, e.g. underlays, overlays; Attaching by chemical means, e.g. vulcanising',
  },
  {
    c: 'B41N 7',
    d: 'Shells for rollers of printing machines',
  },
  {
    c: 'B41N 10',
    d: 'Blankets or like coverings; Coverings for wipers for intaglio printing',
  },
  {
    c: 'B41N 11',
    d: 'Stereotype mats',
  },
  {
    c: 'B41N 99',
    d: 'n.a.',
  },
  {
    c: 'B42',
    d: 'BOOKBINDING; ALBUMS; FILES; SPECIAL PRINTED MATTER',
  },
  {
    c: 'B42B',
    d: 'PERMANENTLY ATTACHING TOGETHER SHEETS, QUIRES, OR SIGNATURES, OR PERMANENTLY ATTACHING OBJECTS THERETO',
  },
  {
    c: 'B42B 2',
    d: 'Permanently attaching together sheets, quires, or signatures by stitching with filamentary material, e.g. textile threads',
  },
  {
    c: 'B42B 4',
    d: 'Permanently attaching together sheets, quires or signatures by discontinuous stitching with filamentary material, e.g. wire',
  },
  {
    c: 'B42B 5',
    d: 'Permanently attaching together sheets, quires, or signatures otherwise than by stitching',
  },
  {
    c: 'B42B 7',
    d: 'Permanently attaching objects, e.g. map sections, to sheets',
  },
  {
    c: 'B42B 9',
    d: 'Devices common to machines for carrying out the processes according to more than one of main groups B42B0002000000-B42B0007000000',
  },
  {
    c: 'B42C',
    d: 'BOOKBINDING',
  },
  {
    c: 'B42C 1',
    d: 'Collating or gathering sheets combined with processes permanently attaching together sheets or signatures or for interposing inserts',
  },
  {
    c: 'B42C 3',
    d: 'Making booklets, pads, or form sets from multiple webs',
  },
  {
    c: 'B42C 5',
    d: 'Preparing the edges or backs of leaves or signatures for binding',
  },
  {
    c: 'B42C 7',
    d: 'Manufacturing bookbinding cases or covers of loose-leaf binders',
  },
  {
    c: 'B42C 9',
    d: 'Applying glue or adhesive peculiar to bookbinding',
  },
  {
    c: 'B42C 11',
    d: 'Casing-in',
  },
  {
    c: 'B42C 13',
    d: 'Bookbinding presses',
  },
  {
    c: 'B42C 15',
    d: 'Jacketing books',
  },
  {
    c: 'B42C 17',
    d: 'Rebinding books',
  },
  {
    c: 'B42C 19',
    d: 'Multi-step processes for making books',
  },
  {
    c: 'B42C 99',
    d: 'n.a.',
  },
  {
    c: 'B42D',
    d: 'BOOKS; BOOK COVERS; LOOSE LEAVES; PRINTED MATTER CHARACTERISED BY IDENTIFICATION OR SECURITY FEATURES; PRINTED MATTER OF SPECIAL FORMAT OR STYLE NOT OTHERWISE PROVIDED FOR; DEVICES FOR USE THEREWITH AND NOT OTHERWISE PROVIDED FOR; MOVABLE-STRIP WRITING OR READING APPARATUS',
  },
  {
    c: 'B42D 1',
    d: 'Books or other bound products',
  },
  {
    c: 'B42D 3',
    d: 'Book covers',
  },
  {
    c: 'B42D 5',
    d: 'Sheets united without binding to form pads or blocks',
  },
  {
    c: 'B42D 7',
    d: 'Newspapers or the like',
  },
  {
    c: 'B42D 9',
    d: 'Bookmarkers; Spot indicators; Devices for holding books open',
  },
  {
    c: 'B42D 11',
    d: 'Carrying forward or transferring entries from one page to another, e.g. for book-keeping',
  },
  {
    c: 'B42D 12',
    d: 'Book-keeping books, forms, or arrangements',
  },
  {
    c: 'B42D 13',
    d: 'Loose leaves modified for binding; Inserts',
  },
  {
    c: 'B42D 15',
    d: 'Printed matter of special format or style not otherwise provided for',
  },
  {
    c: 'B42D 17',
    d: 'Hanging or securing devices for books, newspapers, or the like',
  },
  {
    c: 'B42D 19',
    d: 'Movable-strip writing or reading apparatus',
  },
  {
    c: 'B42D 25',
    d: 'Information-bearing cards or sheet-like structures characterised by identification or security features; Manufacture thereof',
  },
  {
    c: 'B42F',
    d: 'SHEETS TEMPORARILY ATTACHED TOGETHER; FILING APPLIANCES; FILE CARDS; INDEXING',
  },
  {
    c: 'B42F 1',
    d: 'Sheets temporarily attached together; Means therefor; Albums',
  },
  {
    c: 'B42F 3',
    d: 'Sheets temporarily attached together involving perforations; Means therefor; Sheet details therefor',
  },
  {
    c: 'B42F 5',
    d: 'Sheets and objects temporarily attached together; Means therefor; Albums',
  },
  {
    c: 'B42F 7',
    d: 'Filing appliances',
  },
  {
    c: 'B42F 9',
    d: 'Filing appliances with devices clamping file edges; Covers with clamping backs',
  },
  {
    c: 'B42F 11',
    d: 'Filing appliances with separate intermediate holding means',
  },
  {
    c: 'B42F 13',
    d: 'Filing appliances with means for engaging perforations or slots',
  },
  {
    c: 'B42F 15',
    d: 'Suspended filing appliances',
  },
  {
    c: 'B42F 17',
    d: 'Card-filing arrangements, e.g. card indexes or catalogues or filing cabinets',
  },
  {
    c: 'B42F 19',
    d: 'File cards',
  },
  {
    c: 'B42F 21',
    d: 'Indexing means; Indexing tabs or protectors therefor',
  },
  {
    c: 'B42F 23',
    d: 'Filing appliances not provided for in other groups of this subclass',
  },
  {
    c: 'B43',
    d: 'WRITING OR DRAWING IMPLEMENTS; BUREAU ACCESSORIES',
  },
  {
    c: 'B43K',
    d: 'IMPLEMENTS FOR WRITING OR DRAWING',
  },
  {
    c: 'B43K 1',
    d: 'Nibs',
  },
  {
    c: 'B43K 3',
    d: 'Nib holders',
  },
  {
    c: 'B43K 5',
    d: 'Pens with ink reservoirs in holders, e.g. fountain-pens',
  },
  {
    c: 'B43K 7',
    d: 'Ball-point pens',
  },
  {
    c: 'B43K 8',
    d: 'Pens with writing-points other than nibs or balls',
  },
  {
    c: 'B43K 11',
    d: 'Filling devices',
  },
  {
    c: 'B43K 13',
    d: 'Devices for removing nibs; Devices for cleaning nibs, e.g. by wiping',
  },
  {
    c: 'B43K 15',
    d: 'Assembling, finishing, or repairing pens',
  },
  {
    c: 'B43K 17',
    d: 'Continuously-adjustable nibs, e.g. for drawing-pens; Holders therefor',
  },
  {
    c: 'B43K 19',
    d: 'Non-propelling pencils; Styles; Crayons; Chalks',
  },
  {
    c: 'B43K 21',
    d: 'Propelling pencils',
  },
  {
    c: 'B43K 23',
    d: 'Holders or connectors for writing implements; Means for protecting the writing-points',
  },
  {
    c: 'B43K 24',
    d: 'Mechanisms for selecting, projecting, retracting or locking writing units',
  },
  {
    c: 'B43K 25',
    d: 'Attaching writing implements to wearing apparel or objects involving constructional changes of the implements',
  },
  {
    c: 'B43K 27',
    d: 'Multiple-point writing implements, e.g. multicolour; Combinations of writing implements',
  },
  {
    c: 'B43K 29',
    d: 'Combinations of writing implements with other articles',
  },
  {
    c: 'B43K 31',
    d: 'Writing implement receptacles functioning as, or combined with, writing implements',
  },
  {
    c: 'B43L',
    d: 'ARTICLES FOR WRITING OR DRAWING UPON; ACCESSORIES FOR WRITING OR DRAWING',
  },
  {
    c: 'B43L 1',
    d: 'Articles for writing or drawing upon',
  },
  {
    c: 'B43L 3',
    d: 'Writing or drawing-underlays, e.g. blotting pads',
  },
  {
    c: 'B43L 5',
    d: 'Drawing-boards',
  },
  {
    c: 'B43L 7',
    d: 'Writing or drawing aids',
  },
  {
    c: 'B43L 9',
    d: 'Circular curve-drawing or like instruments',
  },
  {
    c: 'B43L 11',
    d: 'Non-circular-curve-drawing instruments',
  },
  {
    c: 'B43L 12',
    d: 'Means to prevent slippage',
  },
  {
    c: 'B43L 13',
    d: 'Drawing instruments, or writing or drawing appliances or accessories, not otherwise provided for',
  },
  {
    c: 'B43L 15',
    d: 'Supports for attachment to hands or arms for facilitating writing or drawing',
  },
  {
    c: 'B43L 17',
    d: 'Blotters',
  },
  {
    c: 'B43L 19',
    d: 'Erasers, rubbers, or erasing devices; Holders therefor',
  },
  {
    c: 'B43L 21',
    d: 'Blackboard or slate-cleaning devices',
  },
  {
    c: 'B43L 23',
    d: 'Sharpeners for pencils or leads',
  },
  {
    c: 'B43L 25',
    d: 'Ink receptacles',
  },
  {
    c: 'B43L 27',
    d: 'Ink stands',
  },
  {
    c: 'B43M',
    d: 'BUREAU ACCESSORIES NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'B43M 1',
    d: 'Fixing seals on documents',
  },
  {
    c: 'B43M 3',
    d: 'Devices for inserting documents into envelopes',
  },
  {
    c: 'B43M 5',
    d: 'Devices for closing envelopes',
  },
  {
    c: 'B43M 7',
    d: 'Devices for opening envelopes',
  },
  {
    c: 'B43M 9',
    d: 'Paper-weights',
  },
  {
    c: 'B43M 11',
    d: 'Hand or desk devices of the office or personal type for applying liquid, other than ink, by contact to surfaces, e.g. for applying adhesive',
  },
  {
    c: 'B43M 13',
    d: 'Dispensers without mechanisms for single stamps modified for the application of stamps to articles',
  },
  {
    c: 'B43M 15',
    d: 'Drawing-pins or thumb-tacks',
  },
  {
    c: 'B43M 99',
    d: 'n.a.',
  },
  {
    c: 'B44',
    d: 'DECORATIVE ARTS',
  },
  {
    c: 'B44B',
    d: 'MACHINES, APPARATUS OR TOOLS FOR ARTISTIC WORK, e.g. FOR SCULPTURING, GUILLOCHING, CARVING, BRANDING OR INLAYING',
  },
  {
    c: 'B44B 1',
    d: "Artists' machines or apparatus equipped with tools or work holders moving, or able to be controlled, three-dimensionally for making single sculptures or models",
  },
  {
    c: 'B44B 3',
    d: "Artists' machines or apparatus equipped with tools or work holders moving or able to be controlled substantially two-dimensionally for carving, engraving, or guilloching shallow ornamenting or markings",
  },
  {
    c: 'B44B 5',
    d: 'Machines or apparatus for embossing decorations or marks, e.g. embossing coins',
  },
  {
    c: 'B44B 7',
    d: 'Machines, apparatus, or hand tools for branding',
  },
  {
    c: 'B44B 9',
    d: 'Machines or apparatus for inlaying with ornamental structures, e.g. tarsia or mosaic work',
  },
  {
    c: 'B44B 11',
    d: "Artists' hand tools for sculpturing, kneading, carving, engraving, guilloching, or embossing; Accessories therefor",
  },
  {
    c: 'B44C',
    d: 'PRODUCING DECORATIVE EFFECTS',
  },
  {
    c: 'B44C 1',
    d: 'Processes, not specifically provided for elsewhere, for producing decorative surface effects',
  },
  {
    c: 'B44C 3',
    d: 'Processes, not specifically provided for elsewhere, for producing ornamental structures',
  },
  {
    c: 'B44C 5',
    d: 'Processes for producing special ornamental bodies',
  },
  {
    c: 'B44C 7',
    d: 'Paperhanging',
  },
  {
    c: 'B44D',
    d: 'PAINTING OR ARTISTIC DRAWING, NOT OTHERWISE PROVIDED FOR; PRESERVING PAINTINGS; SURFACE TREATMENT TO OBTAIN SPECIAL ARTISTIC SURFACE EFFECTS OR FINISHES',
  },
  {
    c: 'B44D 2',
    d: 'Special techniques in artistic painting or drawing, e.g. oil painting, water painting, pastel painting, relief painting',
  },
  {
    c: 'B44D 3',
    d: 'Accessories or implements for use in connection with painting or artistic drawing, not otherwise provided for',
  },
  {
    c: 'B44D 5',
    d: 'Surface treatment to obtain special artistic surface effects or finishes',
  },
  {
    c: 'B44D 7',
    d: 'Preserving paintings, e.g. by varnishing',
  },
  {
    c: 'B44F',
    d: 'SPECIAL DESIGNS OR PICTURES',
  },
  {
    c: 'B44F 1',
    d: 'Designs or pictures characterised by special or unusual light effects',
  },
  {
    c: 'B44F 3',
    d: 'Designs characterised by outlines',
  },
  {
    c: 'B44F 5',
    d: 'Designs characterised by irregular areas, e.g. mottled patterns',
  },
  {
    c: 'B44F 7',
    d: 'Designs imitating three-dimensional effects',
  },
  {
    c: 'B44F 9',
    d: 'Designs imitating natural patterns',
  },
  {
    c: 'B44F 11',
    d: 'Designs imitating artistic work',
  },
  {
    c: 'B44F 99',
    d: 'n.a.',
  },
  {
    c: 'B60',
    d: 'VEHICLES IN GENERAL',
  },
  {
    c: 'B60B',
    d: 'VEHICLE WHEELS',
  },
  {
    c: 'B60B 1',
    d: 'Wheels',
  },
  {
    c: 'B60B 3',
    d: 'Disc wheels, i.e. wheels with load-supporting disc body',
  },
  {
    c: 'B60B 5',
    d: 'Wheels, spokes, disc bodies, rims, hubs, wholly or predominantly made of non-metallic material',
  },
  {
    c: 'B60B 7',
    d: 'Wheel cover discs, rings, or the like, for ornamenting, protecting, or obscuring, wholly or in part, the wheel body, rim, hub, or tyre sidewall',
  },
  {
    c: 'B60B 9',
    d: 'Wheels of high resiliency',
  },
  {
    c: 'B60B 11',
    d: 'Units comprising multiple wheels arranged side by side; Wheels having more than one rim or capable of carrying more than one tyre',
  },
  {
    c: 'B60B 15',
    d: 'Wheels or wheel attachments designed for increasing traction',
  },
  {
    c: 'B60B 17',
    d: 'Wheels characterised by rail-engaging elements',
  },
  {
    c: 'B60B 19',
    d: 'Wheels not otherwise provided for or having characteristics specified in one of the subgroups of this group',
  },
  {
    c: 'B60B 21',
    d: 'Rims; Hubs',
  },
  {
    c: 'B60B 23',
    d: 'Attaching rim to wheel body',
  },
  {
    c: 'B60B 25',
    d: 'Rims built-up of several main parts',
  },
  {
    c: 'B60B 27',
    d: 'Hubs',
  },
  {
    c: 'B60B 29',
    d: 'Apparatus or tools for mounting, holding or assembling wheels',
  },
  {
    c: 'B60B 30',
    d: 'Means for holding wheels or parts thereof',
  },
  {
    c: 'B60B 31',
    d: 'Apparatus or tools for assembling or disassembling wheels',
  },
  {
    c: 'B60B 33',
    d: 'Castors in general',
  },
  {
    c: 'B60B 35',
    d: 'Axle units; Parts thereof',
  },
  {
    c: 'B60B 37',
    d: 'Wheel-axle combinations, e.g. wheel sets',
  },
  {
    c: 'B60B 39',
    d: 'Increasing wheel adhesion',
  },
  {
    c: 'B60C',
    d: 'VEHICLE TYRES',
  },
  {
    c: 'B60C 1',
    d: 'Tyres characterised by the chemical composition or the physical arrangement or mixture of the composition',
  },
  {
    c: 'B60C 3',
    d: 'Tyres characterised by transverse section',
  },
  {
    c: 'B60C 5',
    d: 'Inflatable pneumatic tyres or inner tubes',
  },
  {
    c: 'B60C 7',
    d: 'Non-inflatable or solid tyres',
  },
  {
    c: 'B60C 9',
    d: 'Reinforcements or ply arrangement of pneumatic tyres',
  },
  {
    c: 'B60C 11',
    d: 'Tyre tread bands; Tread patterns; Anti-skid inserts',
  },
  {
    c: 'B60C 13',
    d: 'Tyre sidewalls; Protecting, decorating, marking, or the like, thereof',
  },
  {
    c: 'B60C 15',
    d: 'Tyre beads, e.g. ply turn-up or overlap',
  },
  {
    c: 'B60C 17',
    d: 'Tyres characterised by means enabling restricted operation in damaged or deflated condition; Accessories therefor',
  },
  {
    c: 'B60C 19',
    d: 'Tyre parts or constructions not otherwise provided for',
  },
  {
    c: 'B60C 23',
    d: 'Devices for measuring, signalling, controlling, or distributing tyre pressure or temperature, specially adapted for mounting on vehicles',
  },
  {
    c: 'B60C 25',
    d: 'Apparatus or tools adapted for mounting, removing or inspecting tyres',
  },
  {
    c: 'B60C 27',
    d: 'Non-skid devices temporarily attachable to resilient tyres or resiliently-tyred wheels',
  },
  {
    c: 'B60C 29',
    d: 'Arrangements of tyre-inflating valves to tyres or rims; Accessories for tyre-inflating valves, not otherwise provided for',
  },
  {
    c: 'B60C 99',
    d: 'n.a.',
  },
  {
    c: 'B60D',
    d: 'VEHICLE CONNECTIONS',
  },
  {
    c: 'B60D 1',
    d: 'Traction couplings; Hitches; Draw-gear; Towing devices',
  },
  {
    c: 'B60D 3',
    d: 'Fittings to facilitate pushing',
  },
  {
    c: 'B60D 5',
    d: 'Gangways for coupled vehicles, e.g. of concertina type',
  },
  {
    c: 'B60D 99',
    d: 'n.a.',
  },
  {
    c: 'B60F',
    d: 'VEHICLES FOR USE BOTH ON RAIL AND ON ROAD; VEHICLES CAPABLE OF TRAVELLING IN OR ON DIFFERENT MEDIA, e.g. AMPHIBIOUS VEHICLES',
  },
  {
    c: 'B60F 1',
    d: 'Vehicles for use both on rail and on road; Conversions therefor',
  },
  {
    c: 'B60F 3',
    d: 'Amphibious vehicles, i.e. vehicles capable of travelling both on land and on water; Land vehicles capable of travelling under water',
  },
  {
    c: 'B60F 5',
    d: 'Other vehicles capable of travelling in or on different media',
  },
  {
    c: 'B60G',
    d: 'VEHICLE SUSPENSION ARRANGEMENTS',
  },
  {
    c: 'B60G 1',
    d: 'Suspensions with rigid connection between axle and frame',
  },
  {
    c: 'B60G 3',
    d: 'Resilient suspensions for a single wheel',
  },
  {
    c: 'B60G 5',
    d: 'Resilient suspensions for a set of tandem wheels or axles having interrelated movements',
  },
  {
    c: 'B60G 7',
    d: 'Pivoted suspension arms; Accessories thereof',
  },
  {
    c: 'B60G 9',
    d: 'Resilient suspensions for a rigid axle or axle housing for two or more wheels',
  },
  {
    c: 'B60G 11',
    d: 'Resilient suspensions characterised by arrangement, location, or kind of springs',
  },
  {
    c: 'B60G 13',
    d: 'Resilient suspensions characterised by arrangement, location, or type of vibration-dampers',
  },
  {
    c: 'B60G 15',
    d: 'Resilient suspensions characterised by arrangement, location, or type of combined spring and vibration- damper, e.g. telescopic type',
  },
  {
    c: 'B60G 17',
    d: 'Resilient suspensions having means for adjusting the spring or vibration-damper characteristics, for regulating the distance between a supporting surface and a sprung part of vehicle or for locking suspension during use to meet varying vehicular or surface conditions, e.g. due to speed or load',
  },
  {
    c: 'B60G 21',
    d: 'Interconnection systems for two or more resiliently-suspended wheels, e.g. for stabilising a vehicle body with respect to acceleration, deceleration or centrifugal forces',
  },
  {
    c: 'B60G 23',
    d: 'Wheel suspensions with automatic means for sensing unevenness ahead of wheels or for moving wheels up or down in accordance therewith',
  },
  {
    c: 'B60G 99',
    d: 'n.a.',
  },
  {
    c: 'B60H',
    d: 'ARRANGEMENTS OR ADAPTATIONS OF HEATING, COOLING, VENTILATING, OR OTHER AIR-TREATING DEVICES SPECIALLY FOR PASSENGER OR GOODS SPACES OF VEHICLES',
  },
  {
    c: 'B60H 1',
    d: 'Heating, cooling or ventilating devices',
  },
  {
    c: 'B60H 3',
    d: 'Other air-treating devices',
  },
  {
    c: 'B60J',
    d: 'WINDOWS, WINDSCREENS, NON-FIXED ROOFS, DOORS, OR SIMILAR DEVICES FOR VEHICLES; REMOVABLE EXTERNAL PROTECTIVE COVERINGS SPECIALLY ADAPTED FOR VEHICLES',
  },
  {
    c: 'B60J 1',
    d: 'Windows; Windscreens; Accessories therefor',
  },
  {
    c: 'B60J 3',
    d: 'Antiglare equipment associated with windows or windscreens',
  },
  {
    c: 'B60J 5',
    d: 'Doors',
  },
  {
    c: 'B60J 7',
    d: 'Non-fixed roofs; Roofs with movable panels',
  },
  {
    c: 'B60J 9',
    d: 'Devices not provided for in one of main groups B60J0001000000-B60J0007000000',
  },
  {
    c: 'B60J 10',
    d: 'Sealing arrangements',
  },
  {
    c: 'B60J 11',
    d: 'Removable external protective coverings specially adapted for vehicles or parts of vehicles, e.g. parking covers',
  },
  {
    c: 'B60K',
    d: 'ARRANGEMENT OR MOUNTING OF PROPULSION UNITS OR OF TRANSMISSIONS IN VEHICLES; ARRANGEMENT OR MOUNTING OF PLURAL DIVERSE PRIME-MOVERS IN VEHICLES; AUXILIARY DRIVES FOR VEHICLES; INSTRUMENTATION OR DASHBOARDS FOR VEHICLES; ARRANGEMENTS IN CONNECTION WITH COOLING, AIR INTAKE, GAS EXHAUST OR FUEL SUPPLY OF PROPULSION UNITS IN VEHICLES',
  },
  {
    c: 'B60K 1',
    d: 'Arrangement or mounting of propulsion units in vehicles',
  },
  {
    c: 'B60K 3',
    d: 'Arrangement or mounting of steam or gaseous- pressure propulsion units',
  },
  {
    c: 'B60K 5',
    d: 'Arrangement or mounting of internal-combustion or jet-propulsion units',
  },
  {
    c: 'B60K 6',
    d: 'Arrangement or mounting of plural diverse prime-movers for mutual or common propulsion, e.g. hybrid propulsion systems comprising electric motors and internal combustion engines',
  },
  {
    c: 'B60K 7',
    d: 'Disposition of motor in, or adjacent to, traction wheel',
  },
  {
    c: 'B60K 8',
    d: 'Arrangement or mounting of propulsion units not provided for in one of main groups B60K0001000000-B60K0007000000',
  },
  {
    c: 'B60K 11',
    d: 'Arrangements in connection with cooling, air intake, gas exhaust, fuel supply, or power supply of propulsion units in vehicles',
  },
  {
    c: 'B60K 13',
    d: 'Arrangement in connection with combustion air intake or gas exhaust of propulsion units',
  },
  {
    c: 'B60K 15',
    d: 'Arrangement in connection with fuel supply of combustion engines; Mounting or construction of fuel tanks',
  },
  {
    c: 'B60K 16',
    d: 'Arrangements in connection with power supply of propulsion units in vehicles from force of nature, e.g. sun or wind',
  },
  {
    c: 'B60K 17',
    d: 'Arrangement or mounting of transmissions or their control in vehicles',
  },
  {
    c: 'B60K 20',
    d: 'Arrangement or mounting of change-speed gearing control devices in vehicles',
  },
  {
    c: 'B60K 23',
    d: 'Arrangement or mounting of control devices for vehicle transmissions, or parts thereof, not otherwise provided for',
  },
  {
    c: 'B60K 25',
    d: 'Auxiliary drives',
  },
  {
    c: 'B60K 26',
    d: 'Arrangement or mounting of propulsion-unit control devices in vehicles',
  },
  {
    c: 'B60K 28',
    d: 'Safety devices for propulsion-unit control, specially adapted for, or arranged in, vehicles, e.g. preventing fuel supply or ignition in the event of potentially dangerous conditions',
  },
  {
    c: 'B60K 31',
    d: 'Vehicle fittings, acting on a single sub-unit only, for automatically controlling vehicle speed, i.e. preventing speed from exceeding an arbitrarily established velocity or maintaining speed at a particular velocity, as selected by the vehicle operator',
  },
  {
    c: 'B60K 35',
    d: 'Arrangement or adaptations of instruments specially for vehicles; Dashboards',
  },
  {
    c: 'B60K 37',
    d: 'Dashboards',
  },
  {
    c: 'B60L',
    d: 'PROPULSION OF ELECTRICALLY-PROPELLED VEHICLES',
  },
  {
    c: 'B60L 1',
    d: 'Supplying electric power to auxiliary equipment of electrically-propelled vehicles',
  },
  {
    c: 'B60L 3',
    d: 'Electric devices on electrically-propelled vehicles for safety purposes; Monitoring operating variables, e.g. speed, deceleration or energy consumption',
  },
  {
    c: 'B60L 5',
    d: 'Current-collectors for power supply lines of electrically-propelled vehicles',
  },
  {
    c: 'B60L 7',
    d: 'Electrodynamic brake systems for vehicles in general',
  },
  {
    c: 'B60L 8',
    d: 'Electric propulsion with power supply from forces of nature, e.g. sun or wind',
  },
  {
    c: 'B60L 9',
    d: 'Electric propulsion with power supply external to the vehicle',
  },
  {
    c: 'B60L 13',
    d: 'Electric propulsion for monorail vehicles, suspension vehicles or rack railways; Magnetic suspension or levitation for vehicles',
  },
  {
    c: 'B60L 15',
    d: 'Methods, circuits or devices for controlling the propulsion of electrically-propelled vehicles, e.g. their traction-motor speed, to achieve a desired performance; Adaptation of control equipment on electrically-propelled vehicles for remote actuation from a stationary place, from alternative parts of the vehicle or from alternative vehicles of the same vehicle train',
  },
  {
    c: 'B60L 50',
    d: 'Electric propulsion with power supplied within the vehicle',
  },
  {
    c: 'B60L 53',
    d: 'Methods of charging batteries, specially adapted for electric vehicles; Charging stations or on-board charging equipment therefor; Exchange of energy storage elements in electric vehicles',
  },
  {
    c: 'B60L 55',
    d: 'Arrangements for supplying energy stored within a vehicle to a power network, i.e. vehicle-to-grid [V2G] arrangements',
  },
  {
    c: 'B60L 58',
    d: 'Methods or circuit arrangements for monitoring or controlling batteries or fuel cells, specially adapted for electric vehicles',
  },
  {
    c: 'B60M',
    d: 'POWER SUPPLY LINES, OR DEVICES ALONG RAILS, FOR ELECTRICALLY-PROPELLED VEHICLES',
  },
  {
    c: 'B60M 1',
    d: 'Power supply lines for contact with collector on vehicle',
  },
  {
    c: 'B60M 3',
    d: 'Feeding power to the supply lines in contact with collector on vehicles; Arrangements for consuming regenerative power',
  },
  {
    c: 'B60M 5',
    d: 'Arrangements along running rails or at joints thereof for current-conduction or insulation, e.g. safety devices for reducing earth currents',
  },
  {
    c: 'B60M 7',
    d: 'Power lines or rails specially adapted for electrically-propelled vehicles of special types, e.g. suspension tramway, ropeway, underground railway',
  },
  {
    c: 'B60N',
    d: 'SEATS SPECIALLY ADAPTED FOR VEHICLES; VEHICLE PASSENGER ACCOMMODATION NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'B60N 2',
    d: 'Seats specially adapted for vehicles; Arrangement or mounting of seats in vehicles',
  },
  {
    c: 'B60N 3',
    d: 'Arrangements or adaptations of other passenger fittings, not otherwise provided for',
  },
  {
    c: 'B60N 5',
    d: 'Arrangements or devices on vehicles for entrance or exit control of passengers, e.g. turnstiles',
  },
  {
    c: 'B60N 99',
    d: 'n.a.',
  },
  {
    c: 'B60P',
    d: 'VEHICLES ADAPTED FOR LOAD TRANSPORTATION OR TO TRANSPORT, TO CARRY, OR TO COMPRISE SPECIAL LOADS OR OBJECTS',
  },
  {
    c: 'B60P 1',
    d: 'Vehicles predominantly for transporting loads and modified to facilitate loading, consolidating the load, or unloading',
  },
  {
    c: 'B60P 3',
    d: 'Vehicles adapted to transport, to carry or to comprise special loads or objects',
  },
  {
    c: 'B60P 5',
    d: 'Arrangements of weighing machines on vehicles',
  },
  {
    c: 'B60P 7',
    d: 'Securing or covering of load on vehicles',
  },
  {
    c: 'B60P 9',
    d: 'Other vehicles predominantly for carrying loads',
  },
  {
    c: 'B60Q',
    d: 'ARRANGEMENT OF SIGNALLING OR LIGHTING DEVICES, THE MOUNTING OR SUPPORTING THEREOF OR CIRCUITS THEREFOR, FOR VEHICLES IN GENERAL',
  },
  {
    c: 'B60Q 1',
    d: 'Arrangement of optical signalling or lighting devices, the mounting or supporting thereof or circuits therefor',
  },
  {
    c: 'B60Q 3',
    d: 'Arrangement of lighting devices for vehicle interiors; Lighting devices specially adapted for vehicle interiors',
  },
  {
    c: 'B60Q 5',
    d: 'Arrangement or adaptation of acoustic signal devices',
  },
  {
    c: 'B60Q 7',
    d: 'Arrangement or adaptation of portable emergency signal devices on vehicles',
  },
  {
    c: 'B60Q 9',
    d: 'Arrangement or adaptation of signal devices not provided for in one of main groups B60Q0001000000-B60Q0007000000',
  },
  {
    c: 'B60Q 11',
    d: 'Arrangement of monitoring devices for devices provided for in groups B60Q0001000000-B60Q0009000000',
  },
  {
    c: 'B60R',
    d: 'VEHICLES, VEHICLE FITTINGS, OR VEHICLE PARTS, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'B60R 1',
    d: 'Optical viewing arrangements',
  },
  {
    c: 'B60R 3',
    d: 'Arrangements of steps, e.g. running-boards',
  },
  {
    c: 'B60R 5',
    d: 'Compartments within vehicle body primarily intended or sufficiently spacious for trunks, suit-cases, or the like',
  },
  {
    c: 'B60R 7',
    d: 'Stowing or holding appliances inside of vehicle primarily intended for personal property smaller than suit-cases, e.g. travelling articles, or maps',
  },
  {
    c: 'B60R 9',
    d: 'Supplementary fittings on vehicle exterior for carrying loads, e.g. luggage, sports gear or the like',
  },
  {
    c: 'B60R 11',
    d: 'Arrangements for holding or mounting articles, not otherwise provided for',
  },
  {
    c: 'B60R 13',
    d: 'Elements for body-finishing, identifying, or decorating; Arrangements or adaptations for advertising purposes',
  },
  {
    c: 'B60R 15',
    d: 'Arrangements or adaptations of sanitation devices',
  },
  {
    c: 'B60R 16',
    d: 'Electric or fluid circuits specially adapted for vehicles and not otherwise provided for; Arrangement of elements of electric or fluid circuits specially adapted for vehicles and not otherwise provided for',
  },
  {
    c: 'B60R 17',
    d: 'Arrangements or adaptations of lubricating systems or devices',
  },
  {
    c: 'B60R 19',
    d: 'Wheel guards; Radiator guards; Obstruction removers; Fittings damping bouncing force in collisions',
  },
  {
    c: 'B60R 21',
    d: 'Arrangements or fittings on vehicles for protecting or preventing injuries to occupants or pedestrians in case of accidents or other traffic risks',
  },
  {
    c: 'B60R 22',
    d: 'Safety belts or body harnesses in vehicles',
  },
  {
    c: 'B60R 25',
    d: 'Fittings or systems for preventing or indicating unauthorised use or theft of vehicles',
  },
  {
    c: 'B60R 99',
    d: 'n.a.',
  },
  {
    c: 'B60S',
    d: 'SERVICING, CLEANING, REPAIRING, SUPPORTING, LIFTING, OR MANOEUVRING OF VEHICLES, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'B60S 1',
    d: 'Cleaning of vehicles',
  },
  {
    c: 'B60S 3',
    d: 'Vehicle cleaning apparatus not integral with vehicles',
  },
  {
    c: 'B60S 5',
    d: 'Servicing, maintaining, repairing, or refitting of vehicles',
  },
  {
    c: 'B60S 9',
    d: 'Ground-engaging vehicle fittings for supporting, lifting, or manoeuvring the vehicle, wholly or in part, e.g. built-in jacks',
  },
  {
    c: 'B60S 11',
    d: 'Vehicle modifications for receiving separate lifting, supporting, or manoeuvring devices',
  },
  {
    c: 'B60S 13',
    d: 'Vehicle-manoeuvring devices separate from the vehicle',
  },
  {
    c: 'B60T',
    d: 'VEHICLE BRAKE CONTROL SYSTEMS OR PARTS THEREOF; BRAKE CONTROL SYSTEMS OR PARTS THEREOF, IN GENERAL',
  },
  {
    c: 'B60T 1',
    d: 'Arrangements of braking elements, i.e. of those parts where braking effect occurs',
  },
  {
    c: 'B60T 3',
    d: 'Portable devices for preventing unwanted movement of vehicles, e.g. chocks',
  },
  {
    c: 'B60T 5',
    d: 'Vehicle modifications to facilitate cooling of brakes',
  },
  {
    c: 'B60T 7',
    d: 'Brake control systems or parts thereof',
  },
  {
    c: 'B60T 8',
    d: 'Arrangements for adjusting wheel-braking force to meet varying vehicular or ground-surface conditions, e.g. limiting or varying distribution of braking force',
  },
  {
    c: 'B60T 10',
    d: 'Control or regulation for continuous braking making use of fluid or powdered medium, e.g. for use when descending a long slope',
  },
  {
    c: 'B60T 11',
    d: 'Transmitting braking action from initiating means to ultimate brake actuator without power assistance or drive or where such assistance or drive is irrelevant',
  },
  {
    c: 'B60T 13',
    d: 'Transmitting braking action from initiating means to ultimate brake actuator with power assistance or drive; Brake systems incorporating such transmitting means, e.g. air-pressure brake systems',
  },
  {
    c: 'B60T 15',
    d: 'Construction, arrangement, or operation of valves incorporated in power brake systems and not covered by groups B60T0011000000 or B60T0013000000',
  },
  {
    c: 'B60T 17',
    d: 'Component parts, details, or accessories of brake systems not covered by groups B60T0008000000, B60T0013000000 or B60T0015000000, or presenting other characteristic features',
  },
  {
    c: 'B60V',
    d: 'AIR-CUSHION VEHICLES',
  },
  {
    c: 'B60V 1',
    d: 'Air-cushion vehicles',
  },
  {
    c: 'B60V 3',
    d: 'Land vehicles, waterborne vessels, or aircraft, adapted or modified to travel on air cushions',
  },
  {
    c: 'B60W',
    d: 'CONJOINT CONTROL OF VEHICLE SUB-UNITS OF DIFFERENT TYPE OR DIFFERENT FUNCTION; CONTROL SYSTEMS SPECIALLY ADAPTED FOR HYBRID VEHICLES; ROAD VEHICLE DRIVE CONTROL SYSTEMS FOR PURPOSES NOT RELATED TO THE CONTROL OF A PARTICULAR SUB-UNIT',
  },
  {
    c: 'B60W 10',
    d: 'Conjoint control of vehicle sub-units of different type or different function',
  },
  {
    c: 'B60W 20',
    d: 'Control systems specially adapted for hybrid vehicles',
  },
  {
    c: 'B60W 30',
    d: 'Purposes of road vehicle drive control systems not related to the control of a particular sub-unit, e.g. of systems using conjoint control of vehicle sub-units',
  },
  {
    c: 'B60W 40',
    d: 'Estimation or calculation of driving parameters for road vehicle drive control systems not related to the control of a particular sub-unit',
  },
  {
    c: 'B60W 50',
    d: 'Details of control systems for road vehicle drive control not related to the control of a particular sub-unit',
  },
  {
    c: 'B61',
    d: 'RAILWAYS',
  },
  {
    c: 'B61B',
    d: 'RAILWAY SYSTEMS; EQUIPMENT THEREFOR NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'B61B 1',
    d: 'General arrangement of stations, platforms, or sidings; Railway networks; Rail-vehicle marshalling systems',
  },
  {
    c: 'B61B 3',
    d: 'Elevated railways',
  },
  {
    c: 'B61B 5',
    d: 'Elevated railway systems without suspended vehicles',
  },
  {
    c: 'B61B 7',
    d: 'Rope railways; Power-and-free systems',
  },
  {
    c: 'B61B 9',
    d: 'Tramway or funicular systems with rigid track and cable traction',
  },
  {
    c: 'B61B 10',
    d: 'Power-and-free systems',
  },
  {
    c: 'B61B 11',
    d: 'Ski-lift, sleigh-lift or like trackless systems with guided towing cables only',
  },
  {
    c: 'B61B 12',
    d: 'Component parts, details, or accessories for rope railways or power-and-free systems not provided for in groups B61B0007000000-B61B0011000000',
  },
  {
    c: 'B61B 13',
    d: 'Other railway systems; Combinations of systems',
  },
  {
    c: 'B61B 15',
    d: 'Combinations of railway systems',
  },
  {
    c: 'B61C',
    d: 'LOCOMOTIVES; MOTOR RAILCARS',
  },
  {
    c: 'B61C 1',
    d: 'Locomotives or motor railcars in general or characterised by the type of motive power plant used',
  },
  {
    c: 'B61C 3',
    d: 'Electric locomotives or railcars',
  },
  {
    c: 'B61C 5',
    d: 'Locomotives or motor railcars with IC engines or gas turbines',
  },
  {
    c: 'B61C 7',
    d: 'Other locomotives or motor railcars characterised by the type of motive power plant used; Locomotives or motor railcars with two or more different kinds or types of motive power',
  },
  {
    c: 'B61C 8',
    d: 'Filling stations for steam- or pneumatic-accumulator locomotives or motor railcars',
  },
  {
    c: 'B61C 9',
    d: 'Locomotives or motor railcars characterised by the type of transmission system used; Transmission systems specially adapted for locomotives or motor railcars',
  },
  {
    c: 'B61C 11',
    d: 'Locomotives or motor railcars characterised by the type of means applying the tractive effort, or by their application to special railway systems or purposes',
  },
  {
    c: 'B61C 13',
    d: 'Locomotives or motor railcars characterised by their application to special systems or purposes',
  },
  {
    c: 'B61C 15',
    d: 'Details or accessories not otherwise provided for',
  },
  {
    c: 'B61C 17',
    d: 'Arrangement or disposition of parts; Details or accessories not otherwise provided for; Use of control gear and control systems',
  },
  {
    c: 'B61D',
    d: 'BODY DETAILS OR KINDS OF RAILWAY VEHICLES',
  },
  {
    c: 'B61D 1',
    d: 'Kinds of railway or tramway vehicles',
  },
  {
    c: 'B61D 3',
    d: 'Wagons or vans',
  },
  {
    c: 'B61D 5',
    d: 'Tank wagons for carrying fluent materials',
  },
  {
    c: 'B61D 7',
    d: 'Hopper cars',
  },
  {
    c: 'B61D 9',
    d: 'Tipping wagons',
  },
  {
    c: 'B61D 11',
    d: 'Mine cars',
  },
  {
    c: 'B61D 13',
    d: 'Tramway vehicles',
  },
  {
    c: 'B61D 15',
    d: 'Other railway vehicles, e.g. scaffold cars; Adaptations of vehicles for use on railways',
  },
  {
    c: 'B61D 17',
    d: 'Body details of railway or tramway vehicles',
  },
  {
    c: 'B61D 19',
    d: 'Door arrangements specially adapted for rail vehicles',
  },
  {
    c: 'B61D 23',
    d: 'Construction of steps for railway vehicles',
  },
  {
    c: 'B61D 25',
    d: 'Window arrangements peculiar to rail vehicles',
  },
  {
    c: 'B61D 27',
    d: 'Heating, cooling, ventilating, lighting, or air-conditioning, peculiar to rail vehicles',
  },
  {
    c: 'B61D 29',
    d: 'Arrangement of lighting devices for rail vehicles',
  },
  {
    c: 'B61D 31',
    d: 'Furniture or furnishings peculiar to rail vehicles',
  },
  {
    c: 'B61D 33',
    d: 'Seats',
  },
  {
    c: 'B61D 35',
    d: 'Sanitation',
  },
  {
    c: 'B61D 37',
    d: 'Other furniture or furnishings',
  },
  {
    c: 'B61D 39',
    d: 'Other details peculiar to rail vehicles',
  },
  {
    c: 'B61D 41',
    d: 'Indicators for reserved seats; Warning or like signs; Devices or arrangements in connection with tickets, e.g. ticket holders; Holders for cargo tickets or the like',
  },
  {
    c: 'B61D 43',
    d: 'Devices for using the energy of the movements of the vehicles',
  },
  {
    c: 'B61D 45',
    d: 'Means or devices for securing or supporting the cargo, including protection against shocks',
  },
  {
    c: 'B61D 47',
    d: 'Loading or unloading devices combined with vehicles, e.g. loading platforms',
  },
  {
    c: 'B61D 49',
    d: 'Other details',
  },
  {
    c: 'B61F',
    d: 'RAIL VEHICLE SUSPENSIONS, e.g. UNDERFRAMES, BOGIES OR ARRANGEMENTS OF WHEEL AXLES; RAIL VEHICLES FOR USE ON TRACKS OF DIFFERENT WIDTH; PREVENTING DERAILING OF RAIL VEHICLES; WHEEL GUARDS, OBSTRUCTION REMOVERS OR THE LIKE FOR RAIL VEHICLES',
  },
  {
    c: 'B61F 1',
    d: 'Underframes',
  },
  {
    c: 'B61F 3',
    d: 'Types of bogies',
  },
  {
    c: 'B61F 5',
    d: 'Constructional details of bogies; Connections between bogies and vehicle underframes; Arrangements or devices for adjusting or allowing self-adjustment of wheel axles or bogies when rounding curves',
  },
  {
    c: 'B61F 7',
    d: 'Rail vehicles equipped for use on tracks of different width',
  },
  {
    c: 'B61F 9',
    d: 'Rail vehicles characterised by means for preventing derailing, e.g. by use of guide wheels',
  },
  {
    c: 'B61F 11',
    d: 'Rail vehicles characterised by rail-engaging elements other than wheels, e.g. balls',
  },
  {
    c: 'B61F 13',
    d: 'Rail vehicles characterised by wheel arrangements, not otherwise provided for',
  },
  {
    c: 'B61F 15',
    d: 'Axle-boxes',
  },
  {
    c: 'B61F 17',
    d: 'Lubrication specially adapted for axle-boxes of rail vehicles',
  },
  {
    c: 'B61F 19',
    d: 'Wheel guards; Bumpers; Obstruction removers or the like',
  },
  {
    c: 'B61F 99',
    d: 'n.a.',
  },
  {
    c: 'B61G',
    d: 'COUPLINGS SPECIALLY ADAPTED FOR RAILWAY VEHICLES; DRAUGHT OR BUFFING APPLIANCES SPECIALLY ADAPTED FOR RAILWAY VEHICLES',
  },
  {
    c: 'B61G 1',
    d: 'Couplings peculiar to railway vehicles',
  },
  {
    c: 'B61G 3',
    d: 'Couplings comprising mating parts of similar shape or form which can be coupled without the use of any additional element or elements',
  },
  {
    c: 'B61G 5',
    d: 'Couplings not otherwise provided for',
  },
  {
    c: 'B61G 7',
    d: 'Details or accessories',
  },
  {
    c: 'B61G 9',
    d: 'Draught or buffing appliances peculiar to railway or tramway vehicles',
  },
  {
    c: 'B61G 11',
    d: 'Buffers',
  },
  {
    c: 'B61H',
    d: 'BRAKES OR OTHER RETARDING APPARATUS PECULIAR TO RAIL VEHICLES; ARRANGEMENTS OR DISPOSITIONS OF BRAKES OR OTHER RETARDING APPARATUS IN RAIL VEHICLES',
  },
  {
    c: 'B61H 1',
    d: 'Applications or arrangements of brakes with a braking member or members co-operating with the periphery of the wheel rim, a drum, or the like',
  },
  {
    c: 'B61H 3',
    d: 'Applications or arrangements of brakes with an outwardly-movable braking member or members co-operating with the inner surface of a drum or the like',
  },
  {
    c: 'B61H 5',
    d: 'Applications or arrangements of brakes with substantially-radial braking surfaces pressed together in axial direction, e.g. disc brakes',
  },
  {
    c: 'B61H 7',
    d: 'Brakes with braking members co-operating with the track',
  },
  {
    c: 'B61H 9',
    d: 'Brakes characterised by, or modified for, their application to special railway systems or purposes',
  },
  {
    c: 'B61H 11',
    d: 'Applications or arrangements of braking or retarding apparatus not otherwise provided for; Combinations of apparatus of different kinds or types',
  },
  {
    c: 'B61H 13',
    d: 'Actuating rail-vehicle brakes',
  },
  {
    c: 'B61H 15',
    d: 'Wear-compensating mechanisms, e.g. slack adjusters',
  },
  {
    c: 'B61J',
    d: 'SHIFTING OR SHUNTING OF RAIL VEHICLES',
  },
  {
    c: 'B61J 1',
    d: 'Turntables; Traversers; Transporting rail vehicles on other rail vehicles or dollies',
  },
  {
    c: 'B61J 3',
    d: 'Shunting or short-distance haulage devices; Similar devices for hauling trains on steep gradients or as starting aids; Car-propelling devices therefor',
  },
  {
    c: 'B61J 99',
    d: 'n.a.',
  },
  {
    c: 'B61K',
    d: 'OTHER AUXILIARY EQUIPMENT FOR RAILWAYS',
  },
  {
    c: 'B61K 1',
    d: 'Transferring passengers, articles, or freight to and from moving trains; Slipping or coupling vehicles from or to moving trains',
  },
  {
    c: 'B61K 3',
    d: 'Wetting or lubricating rails or wheel flanges',
  },
  {
    c: 'B61K 5',
    d: 'Apparatus for placing vehicles on the track; Derailers; Lifting or lowering rail vehicle axles or wheels',
  },
  {
    c: 'B61K 7',
    d: 'Railway stops fixed to permanent way; Track brakes or retarding apparatus fixed to permanent way; Sand tracks or the like',
  },
  {
    c: 'B61K 9',
    d: 'Railway vehicle profile gauges; Detecting or indicating overheating of components; Apparatus on locomotives or cars to indicate bad track sections; General design of track recording vehicles',
  },
  {
    c: 'B61K 11',
    d: 'Serving peculiar to locomotives, e.g. filling with, or emptying of, water, sand, or the like at the depots',
  },
  {
    c: 'B61K 13',
    d: 'Other auxiliaries or accessories for railways',
  },
  {
    c: 'B61L',
    d: 'GUIDING RAILWAY TRAFFIC; ENSURING THE SAFETY OF RAILWAY TRAFFIC',
  },
  {
    c: 'B61L 1',
    d: 'Devices along the route controlled by interaction with the vehicle or vehicle train',
  },
  {
    c: 'B61L 3',
    d: 'Devices along the route for controlling devices on the vehicle or vehicle train, e.g. to release brake, to operate a warning signal',
  },
  {
    c: 'B61L 5',
    d: 'Local operating mechanisms for points or track-mounted scotch-blocks; Visible or audible signals; Local operating mechanisms for visible or audible signals',
  },
  {
    c: 'B61L 7',
    d: 'Remote control of local operating means for points, signals, or track-mounted scotch-blocks',
  },
  {
    c: 'B61L 9',
    d: 'Illumination specially adapted for points, form signals, or gates',
  },
  {
    c: 'B61L 11',
    d: 'Operation of points from the vehicle or by the passage of the vehicle',
  },
  {
    c: 'B61L 13',
    d: 'Operation of signals from the vehicle or by the passage of the vehicle',
  },
  {
    c: 'B61L 15',
    d: 'Indicators provided on the vehicle or vehicle train for signalling purposes',
  },
  {
    c: 'B61L 17',
    d: 'Switching systems for classification yards',
  },
  {
    c: 'B61L 19',
    d: 'Arrangements for interlocking between points and signals by means of a single interlocking device',
  },
  {
    c: 'B61L 21',
    d: 'Station blocking between signal boxes in one yard',
  },
  {
    c: 'B61L 23',
    d: 'Control, warning or like safety means along the route or between vehicles or vehicle trains',
  },
  {
    c: 'B61L 25',
    d: 'Recording or indicating positions or identities of vehicles or vehicle trains or setting of track apparatus',
  },
  {
    c: 'B61L 27',
    d: 'Central traffic control systems',
  },
  {
    c: 'B61L 29',
    d: 'Safety means for rail/road crossing traffic',
  },
  {
    c: 'B61L 99',
    d: 'n.a.',
  },
  {
    c: 'B62',
    d: 'LAND VEHICLES FOR TRAVELLING OTHERWISE THAN ON RAILS',
  },
  {
    c: 'B62B',
    d: 'HAND-PROPELLED VEHICLES, e.g. HAND CARTS OR PERAMBULATORS; SLEDGES',
  },
  {
    c: 'B62B 1',
    d: 'Hand carts',
  },
  {
    c: 'B62B 3',
    d: 'Hand carts having more than one axis carrying transport wheels; Steering devices therefor; Equipment therefor',
  },
  {
    c: 'B62B 5',
    d: 'Accessories or details specially adapted for hand carts',
  },
  {
    c: 'B62B 7',
    d: 'Carriages for children; Perambulators',
  },
  {
    c: 'B62B 9',
    d: "Accessories or details specially adapted for children's carriages or perambulators",
  },
  {
    c: 'B62B 11',
    d: 'Hand-propelled vehicles not otherwise provided for',
  },
  {
    c: 'B62B 13',
    d: 'Sledges',
  },
  {
    c: 'B62B 15',
    d: 'Other sledges; Ice boats or sailing sledges',
  },
  {
    c: 'B62B 17',
    d: 'Accessories or details of sledges',
  },
  {
    c: 'B62B 19',
    d: 'Runners for carrying wheeled vehicles to facilitate travel on ice or snow',
  },
  {
    c: 'B62C',
    d: 'VEHICLES DRAWN BY ANIMALS',
  },
  {
    c: 'B62C 1',
    d: 'Types of vehicles',
  },
  {
    c: 'B62C 3',
    d: 'Undercarriages or running gear of vehicles; Axle supports',
  },
  {
    c: 'B62C 5',
    d: 'Draught assemblies',
  },
  {
    c: 'B62C 7',
    d: 'Braking mechanisms and brake control devices specially adapted for animal-drawn vehicles',
  },
  {
    c: 'B62C 9',
    d: 'Carriers or holders for whips; Holders for reins forming part of, or attached to, vehicles',
  },
  {
    c: 'B62C 11',
    d: 'Safeguarding appliances not otherwise provided for, e.g. for readily-releasing unmanageable draught animals',
  },
  {
    c: 'B62C 99',
    d: 'n.a.',
  },
  {
    c: 'B62D',
    d: 'MOTOR VEHICLES; TRAILERS',
  },
  {
    c: 'B62D 1',
    d: 'Steering of motor vehicles or trailers',
  },
  {
    c: 'B62D 3',
    d: 'Steering gears',
  },
  {
    c: 'B62D 5',
    d: 'Power-assisted or power-driven steering',
  },
  {
    c: 'B62D 6',
    d: 'Arrangements for automatically controlling steering depending on driving conditions sensed and responded to, e.g. control circuits',
  },
  {
    c: 'B62D 7',
    d: 'Steering linkage; Stub axles or their mountings',
  },
  {
    c: 'B62D 9',
    d: 'Steering deflectable wheels not otherwise provided for',
  },
  {
    c: 'B62D 11',
    d: 'Steering non-deflectable wheels; Steering endless tracks or the like',
  },
  {
    c: 'B62D 12',
    d: 'Steering specially adapted for vehicles operating in tandem or having pivotally connected frames',
  },
  {
    c: 'B62D 13',
    d: 'Steering specially adapted for trailers',
  },
  {
    c: 'B62D 15',
    d: 'Steering not otherwise provided for',
  },
  {
    c: 'B62D 17',
    d: 'Means on vehicle for adjusting camber, castor, or toe-in',
  },
  {
    c: 'B62D 19',
    d: 'Radius rods, i.e. distance members',
  },
  {
    c: 'B62D 21',
    d: 'Understructures; Superstructures; Vehicle bodies',
  },
  {
    c: 'B62D 23',
    d: 'Combined superstructure and frame, i.e. monocoque constructions',
  },
  {
    c: 'B62D 24',
    d: 'Connections between vehicle body and vehicle frame',
  },
  {
    c: 'B62D 25',
    d: 'Superstructure sub-units; Parts or details thereof not otherwise provided for',
  },
  {
    c: 'B62D 27',
    d: 'Connections between superstructure sub-units',
  },
  {
    c: 'B62D 29',
    d: 'Superstructures characterised by material thereof',
  },
  {
    c: 'B62D 31',
    d: 'Superstructures for passenger vehicles',
  },
  {
    c: 'B62D 33',
    d: 'Superstructures for load-carrying vehicles',
  },
  {
    c: 'B62D 35',
    d: 'Vehicle bodies characterised by streamlining',
  },
  {
    c: 'B62D 37',
    d: 'Stabilising vehicle bodies without controlling suspension arrangements',
  },
  {
    c: 'B62D 39',
    d: 'Vehicle bodies not otherwise provided for',
  },
  {
    c: 'B62D 41',
    d: 'Fittings for identifying vehicles in case of collision; Fittings for marking or recording collision areas',
  },
  {
    c: 'B62D 43',
    d: 'Spare wheel stowing, holding, or mounting arrangements',
  },
  {
    c: 'B62D 47',
    d: 'Motor vehicles or trailers classified according to type; Parts or accessories thereof not otherwise provided for',
  },
  {
    c: 'B62D 49',
    d: 'Tractors',
  },
  {
    c: 'B62D 51',
    d: 'Motor vehicles characterised by the driver not being seated',
  },
  {
    c: 'B62D 53',
    d: 'Tractor-trailer combinations; Road trains',
  },
  {
    c: 'B62D 55',
    d: 'Endless-track vehicles',
  },
  {
    c: 'B62D 57',
    d: 'Vehicles characterised by having other propulsion or other ground-engaging means than wheels or endless track, alone or in addition to wheels or endless track',
  },
  {
    c: 'B62D 59',
    d: 'Trailers with driven ground wheels or the like',
  },
  {
    c: 'B62D 61',
    d: 'Motor vehicles or trailers, characterised by the arrangement or number of wheels, not otherwise provided for, e.g. four wheels in diamond pattern',
  },
  {
    c: 'B62D 63',
    d: 'Motor vehicles or trailers not otherwise provided for',
  },
  {
    c: 'B62D 65',
    d: 'Designing, manufacturing, e.g. assembling, facilitating disassembly, or structurally modifying motor vehicles or trailers, not otherwise provided for',
  },
  {
    c: 'B62D 67',
    d: 'Systematic disassembly of vehicles for recovery of salvageable components, e.g. for recycling',
  },
  {
    c: 'B62D 101',
    d: 'Indexing scheme associated with group B62D0006000000, with the exception of groups B62D0006020000-B62D0006100000, relating to driving conditions sensed and responded to.',
  },
  {
    c: 'B62D 103',
    d: 'Acceleration or deceleration in the direction of travel',
  },
  {
    c: 'B62D 105',
    d: 'Loss of traction, e.g. wheel spin or skid',
  },
  {
    c: 'B62D 107',
    d: 'Temperature',
  },
  {
    c: 'B62D 109',
    d: 'Presence, absence or inactivity of driver or operator, e.g. by sensing the operation of the clutch, brake or throttle',
  },
  {
    c: 'B62D 111',
    d: 'Forces disturbing the intended course of the vehicle, e.g. forces acting transversely of the direction of travel',
  },
  {
    c: 'B62D 113',
    d: 'Position of parts of the steering mechanism, e.g. the steered wheels or the steering wheel',
  },
  {
    c: 'B62D 115',
    d: 'Angle of articulation of articulated vehicle; Angle of tow-bar to towing vehicle',
  },
  {
    c: 'B62D 117',
    d: 'Angular velocity of steering wheel',
  },
  {
    c: 'B62D 119',
    d: 'Steering wheel torque',
  },
  {
    c: 'B62D 121',
    d: 'Force applied to the steering linkage',
  },
  {
    c: 'B62D 123',
    d: 'Fluid pressure supply for vehicle equipment, e.g. for power-assisted steering; Presence, failure or threshold values thereof; Lubricating or other fluid capacities',
  },
  {
    c: 'B62D 125',
    d: 'Particular gear ratio selected',
  },
  {
    c: 'B62D 127',
    d: 'Engine speed',
  },
  {
    c: 'B62D 131',
    d: 'Load, including height of vehicle dependent on load; State of vehicle vibration damping means',
  },
  {
    c: 'B62D 133',
    d: 'Trim or inclination, including road gradient',
  },
  {
    c: 'B62D 135',
    d: 'Air moisture content',
  },
  {
    c: 'B62D 137',
    d: 'Conditions not specified in groups B62D0101000000-B62D0135000000',
  },
  {
    c: 'B62H',
    d: 'CYCLE STANDS; SUPPORTS OR HOLDERS FOR PARKING OR STORING CYCLES; APPLIANCES PREVENTING OR INDICATING UNAUTHORISED USE OR THEFT OF CYCLES; LOCKS INTEGRAL WITH CYCLES; DEVICES FOR LEARNING TO RIDE CYCLES',
  },
  {
    c: 'B62H 1',
    d: 'Supports or stands forming part of, or attached to, cycles',
  },
  {
    c: 'B62H 3',
    d: 'Separate supports or holders for parking or storing cycles',
  },
  {
    c: 'B62H 5',
    d: 'Appliances preventing or indicating unauthorised use or theft of cycles; Locks integral with cycles',
  },
  {
    c: 'B62H 7',
    d: 'Devices for learning to ride cycles, not otherwise provided for, e.g. assisting balance',
  },
  {
    c: 'B62J',
    d: 'CYCLE SADDLES OR SEATS; ACCESSORIES PECULIAR TO CYCLES AND NOT OTHERWISE PROVIDED FOR, e.g. ARTICLE CARRIERS OR CYCLE PROTECTORS',
  },
  {
    c: 'B62J 1',
    d: 'Saddles or other seats for cycles; Arrangement thereof; Component parts',
  },
  {
    c: 'B62J 3',
    d: 'Signal or lighting devices specially adapted for cycles',
  },
  {
    c: 'B62J 6',
    d: 'Arrangement of optical signalling or lighting devices on cycles, the mounting or supporting thereof or circuits therefor',
  },
  {
    c: 'B62J 7',
    d: 'Article carriers',
  },
  {
    c: 'B62J 9',
    d: 'Panniers, saddle bags, or other containers specially adapted to be attached to cycles',
  },
  {
    c: 'B62J 11',
    d: 'Supporting devices for attaching articles of definite shape to cycles, e.g. for maps, umbrellas, bottles',
  },
  {
    c: 'B62J 13',
    d: 'Protectors; Fairings or streamlining parts not otherwise provided for',
  },
  {
    c: 'B62J 15',
    d: 'Mud-guards for wheels',
  },
  {
    c: 'B62J 17',
    d: 'Weather guards for riders; Fairings or streamlining parts not otherwise provided for',
  },
  {
    c: 'B62J 19',
    d: 'Parking covers for cycles',
  },
  {
    c: 'B62J 21',
    d: 'Dress protectors, e.g. clips attached to the cycle',
  },
  {
    c: 'B62J 23',
    d: 'Other protectors specially adapted for cycles',
  },
  {
    c: 'B62J 25',
    d: 'Foot-rests; Rigidly-mounted knee grips, e.g. on petrol tank',
  },
  {
    c: 'B62J 27',
    d: 'Safety equipment, e.g. crash bars',
  },
  {
    c: 'B62J 29',
    d: 'Adaptations or arrangements of mirrors for use on cycles',
  },
  {
    c: 'B62J 31',
    d: 'Installations of lubricating devices',
  },
  {
    c: 'B62J 33',
    d: 'Installations peculiar to cycles for warming riders',
  },
  {
    c: 'B62J 35',
    d: 'Fuel tanks specially adapted for motorcycles or engine-assisted cycles; Arrangements thereof',
  },
  {
    c: 'B62J 37',
    d: 'Arrangements of fuel supply lines, taps, or the like, on motorcycles or engine-assisted cycles',
  },
  {
    c: 'B62J 99',
    d: 'n.a.',
  },
  {
    c: 'B62K',
    d: 'CYCLES; CYCLE FRAMES; CYCLE STEERING DEVICES; RIDER-OPERATED TERMINAL CONTROLS SPECIALLY ADAPTED FOR CYCLES; CYCLE AXLE SUSPENSIONS; CYCLE SIDECARS, FORECARS, OR THE LIKE',
  },
  {
    c: 'B62K 1',
    d: 'Unicycles',
  },
  {
    c: 'B62K 3',
    d: 'Bicycles',
  },
  {
    c: 'B62K 5',
    d: 'Cycles with handlebars, equipped with three or more main road wheels',
  },
  {
    c: 'B62K 7',
    d: 'Freight- or passenger-carrying cycles',
  },
  {
    c: 'B62K 9',
    d: "Children's cycles",
  },
  {
    c: 'B62K 11',
    d: 'Motorcycles, engine-assisted cycles or motor scooters with one or two wheels',
  },
  {
    c: 'B62K 13',
    d: 'Cycles convertible to, or transformable into, other types of cycles or land vehicles',
  },
  {
    c: 'B62K 15',
    d: 'Collapsible or foldable cycles',
  },
  {
    c: 'B62K 17',
    d: 'Cycles not otherwise provided for',
  },
  {
    c: 'B62K 19',
    d: 'Cycle frames',
  },
  {
    c: 'B62K 21',
    d: 'Steering devices',
  },
  {
    c: 'B62K 23',
    d: 'Rider-operated controls specially adapted for cycles, i.e. means for initiating control operations, e.g. levers, grips',
  },
  {
    c: 'B62K 25',
    d: 'Axle suspensions',
  },
  {
    c: 'B62K 27',
    d: 'Sidecars; Forecars or the like',
  },
  {
    c: 'B62L',
    d: 'BRAKES SPECIALLY ADAPTED FOR CYCLES',
  },
  {
    c: 'B62L 1',
    d: 'Brakes; Arrangements thereof',
  },
  {
    c: 'B62L 3',
    d: 'Brake-actuating mechanisms',
  },
  {
    c: 'B62L 5',
    d: 'Brakes, or actuating mechanisms therefor, controlled by back-pedalling',
  },
  {
    c: 'B62M',
    d: 'RIDER PROPULSION OF WHEELED VEHICLES OR SLEDGES; POWERED PROPULSION OF SLEDGES OR CYCLES; TRANSMISSIONS SPECIALLY ADAPTED FOR SUCH VEHICLES',
  },
  {
    c: 'B62M 1',
    d: 'Rider propulsion of wheeled vehicles',
  },
  {
    c: 'B62M 3',
    d: 'Construction of cranks operated by hand or foot',
  },
  {
    c: 'B62M 5',
    d: 'Foot-driven levers as pedal cranks which can be immobilised as foot-rests',
  },
  {
    c: 'B62M 6',
    d: 'Rider propulsion of wheeled vehicles with additional source of power, e.g. combustion engine or electric motor',
  },
  {
    c: 'B62M 7',
    d: 'Motorcycles characterised by position of motor or engine',
  },
  {
    c: 'B62M 9',
    d: 'Transmissions',
  },
  {
    c: 'B62M 11',
    d: 'Transmissions characterised by use of interengaging toothed wheels or frictionally-engaging wheels',
  },
  {
    c: 'B62M 13',
    d: 'Transmissions characterised by use of friction rollers engaging the periphery of the ground wheel',
  },
  {
    c: 'B62M 15',
    d: 'Transmission characterised by use of crankshafts and coupling-rods',
  },
  {
    c: 'B62M 17',
    d: 'Transmissions characterised by use of rotary shaft, e.g. cardan shaft',
  },
  {
    c: 'B62M 19',
    d: 'Transmissions characterised by use of non-mechanical gearing, e.g. fluid gearing',
  },
  {
    c: 'B62M 21',
    d: 'Transmissions characterised by use of resilient elements therein',
  },
  {
    c: 'B62M 23',
    d: 'Transmissions characterised by use of other elements; Other transmissions',
  },
  {
    c: 'B62M 25',
    d: 'Actuators for gearing speed-change mechanisms specially adapted for cycles',
  },
  {
    c: 'B62M 27',
    d: 'Propulsion devices for sledges or the like',
  },
  {
    c: 'B62M 29',
    d: 'Ground-engaging propulsion devices for cycles, sledges, or rider-propelled wheeled vehicles, not otherwise provided for',
  },
  {
    c: 'B63',
    d: 'SHIPS OR OTHER WATERBORNE VESSELS; RELATED EQUIPMENT',
  },
  {
    c: 'B63B',
    d: 'SHIPS OR OTHER WATERBORNE VESSELS; EQUIPMENT FOR SHIPPING',
  },
  {
    c: 'B63B 1',
    d: 'Hydrodynamic or hydrostatic features of hulls or of hydrofoils',
  },
  {
    c: 'B63B 3',
    d: 'Constructions of hulls',
  },
  {
    c: 'B63B 5',
    d: 'Hulls characterised by their construction of non-metallic material',
  },
  {
    c: 'B63B 7',
    d: 'Collapsible, foldable, inflatable, or like vessels',
  },
  {
    c: 'B63B 9',
    d: 'Methods of designing, building, maintaining, converting, refitting, repairing, or determining properties of, vessels, not otherwise provided for',
  },
  {
    c: 'B63B 11',
    d: 'Interior subdivision of hulls',
  },
  {
    c: 'B63B 13',
    d: 'Conduits for emptying or ballasting; Self-bailing equipment; Scuppers',
  },
  {
    c: 'B63B 15',
    d: 'Superstructures; Arrangements or adaptations of masts',
  },
  {
    c: 'B63B 17',
    d: 'Vessels parts, details, or accessories, not otherwise provided for',
  },
  {
    c: 'B63B 19',
    d: 'Arrangements or adaptations of ports, doors, windows, port-holes, or other openings or covers',
  },
  {
    c: 'B63B 21',
    d: 'Tying-up; Shifting, towing, or pushing equipment; Anchoring',
  },
  {
    c: 'B63B 22',
    d: 'Buoys',
  },
  {
    c: 'B63B 23',
    d: 'Equipment for handling lifeboats or the like',
  },
  {
    c: 'B63B 25',
    d: 'Load-accommodating arrangements, e.g. stowing or trimming; Vessels characterised thereby',
  },
  {
    c: 'B63B 27',
    d: 'Arrangement of ship-based loading or unloading equipment for cargo or passengers',
  },
  {
    c: 'B63B 29',
    d: 'Accommodation for crew or passengers not otherwise provided for',
  },
  {
    c: 'B63B 35',
    d: 'Vessels or like floating structures adapted for special purposes',
  },
  {
    c: 'B63B 38',
    d: 'Vessels or like floating structures not otherwise provided for',
  },
  {
    c: 'B63B 39',
    d: 'Equipment to decrease pitch, roll, or like unwanted vessel movements; Apparatus for indicating vessel attitude',
  },
  {
    c: 'B63B 41',
    d: 'Drop keels, e.g. centre boards or side boards',
  },
  {
    c: 'B63B 43',
    d: 'Improving safety of vessels, e.g. damage control, not otherwise provided for',
  },
  {
    c: 'B63B 45',
    d: 'Arrangements or adaptations of signalling or lighting devices',
  },
  {
    c: 'B63B 49',
    d: 'Arrangements of nautical instruments or navigational aids',
  },
  {
    c: 'B63B 51',
    d: 'Marking of navigational routes otherwise than with buoys',
  },
  {
    c: 'B63B 57',
    d: 'Tank cleaning specially adapted for vessels',
  },
  {
    c: 'B63B 59',
    d: 'Hull protection specially adapted for vessels; Cleaning devices specially adapted for vessels',
  },
  {
    c: 'B63B 69',
    d: 'Equipment for shipping not otherwise provided for',
  },
  {
    c: 'B63C',
    d: 'LAUNCHING, HAULING-OUT, OR DRY-DOCKING OF VESSELS; LIFE-SAVING IN WATER; EQUIPMENT FOR DWELLING OR WORKING UNDER WATER; MEANS FOR SALVAGING OR SEARCHING FOR UNDERWATER OBJECTS',
  },
  {
    c: 'B63C 1',
    d: 'Dry-docking of vessels or flying-boats',
  },
  {
    c: 'B63C 3',
    d: 'Launching or hauling-out, e.g. by landborne slipways; Slipways',
  },
  {
    c: 'B63C 5',
    d: 'Equipment usable both on slipways and in dry docks',
  },
  {
    c: 'B63C 7',
    d: 'Salvaging of disabled, stranded, or sunken vessels; Salvaging of vessel parts or furnishings, e.g. of safes; Salvaging of other underwater objects',
  },
  {
    c: 'B63C 9',
    d: 'Life-saving in water',
  },
  {
    c: 'B63C 11',
    d: 'Equipment for dwelling or working under water; Means for searching for underwater objects',
  },
  {
    c: 'B63C 13',
    d: 'Equipment forming part of, or attachable to, vessels, facilitating transport over land',
  },
  {
    c: 'B63C 15',
    d: 'Storing of vessels on land otherwise than by dry-docking',
  },
  {
    c: 'B63G',
    d: 'OFFENSIVE OR DEFENSIVE ARRANGEMENTS ON VESSELS; MINE-LAYING; MINE-SWEEPING; SUBMARINES; AIRCRAFT CARRIERS',
  },
  {
    c: 'B63G 1',
    d: 'Arrangements of guns or missile launchers; Vessels characterised thereby',
  },
  {
    c: 'B63G 3',
    d: 'Arrangements of ammunition stores or handlers',
  },
  {
    c: 'B63G 5',
    d: 'Vessels characterised by adaptation to torpedo-launching',
  },
  {
    c: 'B63G 6',
    d: 'Laying of mines or depth charges; Vessels characterised thereby',
  },
  {
    c: 'B63G 7',
    d: 'Mine-sweeping; Vessels characterised thereby',
  },
  {
    c: 'B63G 8',
    d: 'Underwater vessels, e.g. submarines',
  },
  {
    c: 'B63G 9',
    d: 'Other offensive or defensive arrangements on vessels against submarines, torpedoes, or mines',
  },
  {
    c: 'B63G 11',
    d: 'Aircraft carriers, i.e. warships equipped with a flight deck on which airplanes can be launched and landed and with a hangar deck for servicing airplanes',
  },
  {
    c: 'B63G 13',
    d: 'Other offensive or defensive arrangements on vessels; Vessels characterised thereby',
  },
  {
    c: 'B63H',
    d: 'MARINE PROPULSION OR STEERING',
  },
  {
    c: 'B63H 1',
    d: 'Propulsive elements directly acting on water',
  },
  {
    c: 'B63H 3',
    d: 'Propeller-blade pitch changing',
  },
  {
    c: 'B63H 5',
    d: 'Arrangements on vessels of propulsion elements directly acting on water',
  },
  {
    c: 'B63H 7',
    d: 'Arrangements of propulsive devices directly acting on air',
  },
  {
    c: 'B63H 9',
    d: 'Propulsive devices directly acted on by wind; Arrangements thereof',
  },
  {
    c: 'B63H 11',
    d: 'Effecting propulsion by jets, i.e. reaction principle',
  },
  {
    c: 'B63H 13',
    d: 'Effecting propulsion by wind motors driving water-engaging propulsive elements',
  },
  {
    c: 'B63H 15',
    d: 'Effecting propulsion by use of vessel-mounted driving mechanisms co-operating with anchored chains or the like',
  },
  {
    c: 'B63H 16',
    d: 'Effecting propulsion by muscle power',
  },
  {
    c: 'B63H 19',
    d: 'Effecting propulsion of vessels, not otherwise provided for',
  },
  {
    c: 'B63H 20',
    d: 'Outboard propulsion units, i.e. propulsion units having a substantially vertical power leg mounted outboard of a hull and terminating in a propulsion element, e.g. outboard motors, Z-drives',
  },
  {
    c: 'B63H 21',
    d: 'Use of propulsion power plant or units on vessels',
  },
  {
    c: 'B63H 23',
    d: 'Transmitting power from propulsion power plant to propulsive elements',
  },
  {
    c: 'B63H 25',
    d: 'Steering; Slowing-down otherwise than by use of propulsive elements',
  },
  {
    c: 'B63J',
    d: 'AUXILIARIES ON VESSELS',
  },
  {
    c: 'B63J 1',
    d: 'Arrangements of installations for producing fresh water, e.g. by evaporation and condensation of sea water',
  },
  {
    c: 'B63J 2',
    d: 'Arrangements of ventilation, heating, cooling, or air-conditioning',
  },
  {
    c: 'B63J 3',
    d: 'Driving of auxiliaries',
  },
  {
    c: 'B63J 4',
    d: 'Arrangements of installations for treating waste-water or sewage',
  },
  {
    c: 'B63J 99',
    d: 'n.a.',
  },
  {
    c: 'B64',
    d: 'AIRCRAFT; AVIATION; COSMONAUTICS',
  },
  {
    c: 'B64B',
    d: 'LIGHTER-THAN-AIR AIRCRAFT',
  },
  {
    c: 'B64B 1',
    d: 'Lighter-than-air aircraft',
  },
  {
    c: 'B64C',
    d: 'AEROPLANES; HELICOPTERS',
  },
  {
    c: 'B64C 1',
    d: 'Aircraft structures or fairings',
  },
  {
    c: 'B64C 3',
    d: 'Wings',
  },
  {
    c: 'B64C 5',
    d: 'Stabilising surfaces',
  },
  {
    c: 'B64C 7',
    d: 'Structures or fairings not otherwise provided for',
  },
  {
    c: 'B64C 9',
    d: 'Adjustable control surfaces or members, e.g. rudders',
  },
  {
    c: 'B64C 11',
    d: 'Propellers, e.g. of ducted type; Features common to propellers and rotors for rotorcraft',
  },
  {
    c: 'B64C 13',
    d: 'Control systems or transmitting systems for actuating flying-control surfaces, lift-increasing flaps, air brakes, or spoilers',
  },
  {
    c: 'B64C 15',
    d: 'Attitude, flight direction, or altitude control by jet reaction',
  },
  {
    c: 'B64C 17',
    d: 'Aircraft stabilisation not otherwise provided for',
  },
  {
    c: 'B64C 19',
    d: 'Aircraft control not otherwise provided for',
  },
  {
    c: 'B64C 21',
    d: 'Influencing air-flow over aircraft surfaces, not otherwise provided for',
  },
  {
    c: 'B64C 23',
    d: 'Influencing air-flow over aircraft surfaces, not otherwise provided for',
  },
  {
    c: 'B64C 25',
    d: 'Alighting gear',
  },
  {
    c: 'B64C 27',
    d: 'Aircraft kinds or components not otherwise provided for',
  },
  {
    c: 'B64C 29',
    d: 'Aircraft capable of landing or taking-off vertically',
  },
  {
    c: 'B64C 30',
    d: 'Supersonic type aircraft',
  },
  {
    c: 'B64C 31',
    d: 'Aircraft intended to be sustained without power plant; Powered hang-glider-type aircraft; Microlight-type aircraft',
  },
  {
    c: 'B64C 33',
    d: 'Ornithopters',
  },
  {
    c: 'B64C 35',
    d: 'Flying-boats; Seaplanes',
  },
  {
    c: 'B64C 37',
    d: 'Convertible aircraft',
  },
  {
    c: 'B64C 39',
    d: 'Aircraft not otherwise provided for',
  },
  {
    c: 'B64C 99',
    d: 'n.a.',
  },
  {
    c: 'B64D',
    d: 'EQUIPMENT FOR FITTING IN OR TO AIRCRAFT; FLYING SUITS; PARACHUTES; ARRANGEMENTS OR MOUNTING OF POWER PLANTS OR PROPULSION TRANSMISSIONS IN AIRCRAFT',
  },
  {
    c: 'B64D 1',
    d: 'Dropping, ejecting, releasing, or receiving articles, liquids, or the like, in flight',
  },
  {
    c: 'B64D 3',
    d: 'Aircraft adaptations to facilitate towing or being towed',
  },
  {
    c: 'B64D 5',
    d: 'Aircraft transported by aircraft, e.g. for release or reberthing during flight',
  },
  {
    c: 'B64D 7',
    d: 'Arrangement of military equipment, e.g. armaments, armament accessories, or military shielding, in aircraft; Adaptations of armament mountings for aircraft',
  },
  {
    c: 'B64D 9',
    d: 'Equipment for handling freight; Equipment for facilitating passenger embarkation or the like',
  },
  {
    c: 'B64D 10',
    d: 'Flying suits',
  },
  {
    c: 'B64D 11',
    d: 'Passenger or crew accommodation; Flight-deck installations not otherwise provided for',
  },
  {
    c: 'B64D 13',
    d: 'Arrangements or adaptations of air-treatment apparatus for aircraft crew or passengers, or freight space',
  },
  {
    c: 'B64D 15',
    d: 'De-icing or preventing icing on exterior surfaces of aircraft',
  },
  {
    c: 'B64D 17',
    d: 'Parachutes',
  },
  {
    c: 'B64D 19',
    d: 'Non-canopied parachutes',
  },
  {
    c: 'B64D 21',
    d: 'Testing of parachutes',
  },
  {
    c: 'B64D 23',
    d: 'Training of parachutists',
  },
  {
    c: 'B64D 25',
    d: 'Emergency apparatus or devices, not otherwise provided for',
  },
  {
    c: 'B64D 27',
    d: 'Arrangement or mounting of power plant in aircraft; Aircraft characterised thereby',
  },
  {
    c: 'B64D 29',
    d: 'Power-plant nacelles, fairings, or cowlings',
  },
  {
    c: 'B64D 31',
    d: 'Power plant control; Arrangement thereof',
  },
  {
    c: 'B64D 33',
    d: 'Arrangement in aircraft of power plant parts or auxiliaries not otherwise provided for',
  },
  {
    c: 'B64D 35',
    d: 'Transmitting power from power plant to propellers or rotors; Arrangements of transmissions',
  },
  {
    c: 'B64D 37',
    d: 'Arrangements in connection with fuel supply for power plant',
  },
  {
    c: 'B64D 39',
    d: 'Refuelling during flight',
  },
  {
    c: 'B64D 41',
    d: 'Power installations for auxiliary purposes',
  },
  {
    c: 'B64D 43',
    d: 'Arrangements or adaptations of instruments',
  },
  {
    c: 'B64D 45',
    d: 'Aircraft indicators or protectors not otherwise provided for',
  },
  {
    c: 'B64D 47',
    d: 'Equipment not otherwise provided for',
  },
  {
    c: 'B64F',
    d: 'GROUND OR AIRCRAFT-CARRIER-DECK INSTALLATIONS SPECIALLY ADAPTED FOR USE IN CONNECTION WITH AIRCRAFT; DESIGNING, MANUFACTURING, ASSEMBLING, CLEANING, MAINTAINING OR REPAIRING AIRCRAFT, NOT OTHERWISE PROVIDED FOR; HANDLING, TRANSPORTING, TESTING OR INSPECTING AIRCRAFT COMPONENTS, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'B64F 1',
    d: 'Ground or aircraft-carrier-deck installations',
  },
  {
    c: 'B64F 3',
    d: 'Ground installations specially adapted for captive aircraft',
  },
  {
    c: 'B64F 5',
    d: 'Designing, manufacturing, assembling, cleaning, maintaining or repairing aircraft, not otherwise provided for; Handling, transporting, testing or inspecting aircraft components, not otherwise provided for',
  },
  {
    c: 'B64G',
    d: 'COSMONAUTICS; VEHICLES OR EQUIPMENT THEREFOR',
  },
  {
    c: 'B64G 1',
    d: 'Cosmonautic vehicles',
  },
  {
    c: 'B64G 3',
    d: 'Observing or tracking cosmonautic vehicles',
  },
  {
    c: 'B64G 4',
    d: 'Tools specially adapted for use in space',
  },
  {
    c: 'B64G 5',
    d: 'Ground equipment for vehicles, e.g. starting towers, fuelling arrangements',
  },
  {
    c: 'B64G 6',
    d: 'Space suits',
  },
  {
    c: 'B64G 7',
    d: 'Simulating cosmonautic conditions, e.g. for conditioning crews',
  },
  {
    c: 'B64G 99',
    d: 'n.a.',
  },
  {
    c: 'B65',
    d: 'CONVEYING; PACKING; STORING; HANDLING THIN OR FILAMENTARY MATERIAL',
  },
  {
    c: 'B65B',
    d: 'MACHINES, APPARATUS OR DEVICES FOR, OR METHODS OF, PACKAGING ARTICLES OR MATERIALS; UNPACKING',
  },
  {
    c: 'B65B 1',
    d: 'Machines, apparatus, or methods of general application for packaging articles or materials',
  },
  {
    c: 'B65B 3',
    d: 'Packaging plastic material, semiliquids, liquids or mixed solids and liquids, in individual containers or receptacles, e.g. bags, sacks, boxes, cartons, cans or jars',
  },
  {
    c: 'B65B 5',
    d: 'Packaging individual articles in containers or receptacles, e.g. bags, sacks, boxes, cartons, cans, jars',
  },
  {
    c: 'B65B 7',
    d: 'Closing containers or receptacles after filling',
  },
  {
    c: 'B65B 9',
    d: 'Enclosing successive articles, or quantities of material, e.g. liquids or semiliquids, in flat, folded, or tubular webs of flexible sheet material; Subdividing filled flexible tubes to form packages',
  },
  {
    c: 'B65B 11',
    d: 'Wrapping, e.g. partially or wholly enclosing, articles or quantities of material, in strips, sheets or blanks, of flexible material',
  },
  {
    c: 'B65B 13',
    d: 'Bundling articles',
  },
  {
    c: 'B65B 15',
    d: 'Attaching articles to cards, sheets, strings, webs, or other carriers',
  },
  {
    c: 'B65B 17',
    d: 'Other machines, apparatus, or methods for packaging articles or materials',
  },
  {
    c: 'B65B 19',
    d: 'Machines, apparatus, or methods adapted for packaging articles or materials presenting special problems, or for special packaging operations; Unpacking bottles or eggs',
  },
  {
    c: 'B65B 21',
    d: 'Packaging or unpacking of bottles',
  },
  {
    c: 'B65B 23',
    d: 'Packaging fragile or shock-sensitive articles other than bottles; Unpacking eggs',
  },
  {
    c: 'B65B 25',
    d: 'Packaging other articles presenting special problems',
  },
  {
    c: 'B65B 27',
    d: 'Bundling particular articles presenting special problems using string, wire, or narrow tape or band; Baling fibrous material, e.g. peat, not otherwise provided for',
  },
  {
    c: 'B65B 29',
    d: 'Packaging of materials presenting special problems',
  },
  {
    c: 'B65B 31',
    d: 'Packaging articles or materials under special atmospheric or gaseous conditions; Adding propellants to aerosol containers',
  },
  {
    c: 'B65B 33',
    d: 'Packaging articles by applying removable, e.g. strippable, coatings',
  },
  {
    c: 'B65B 35',
    d: 'Details of, auxiliary devices applied to, or auxiliary measures taken in, machines, apparatus, or methods, not otherwise provided for',
  },
  {
    c: 'B65B 37',
    d: 'Supplying or feeding fluent-solid, plastic, or liquid material, or loose masses of small articles, to be packaged',
  },
  {
    c: 'B65B 39',
    d: 'Nozzles, funnels or guides for introducing articles or materials into containers or wrappers',
  },
  {
    c: 'B65B 41',
    d: 'Supplying or feeding container-forming sheets or wrapping material',
  },
  {
    c: 'B65B 43',
    d: 'Forming, feeding, opening, or setting-up containers or receptacles in association with packaging',
  },
  {
    c: 'B65B 45',
    d: 'Apparatus or devices for supporting or holding wrappers during wrapping operation',
  },
  {
    c: 'B65B 47',
    d: 'Apparatus or devices for forming pockets or receptacles in or from sheets, blanks, or webs, comprising essentially a die into which the material is pressed or a folding die through which the material is moved',
  },
  {
    c: 'B65B 49',
    d: 'Devices for folding or bending wrappers around contents',
  },
  {
    c: 'B65B 51',
    d: 'Devices for, or methods of, sealing or securing package folds or closures, e.g. twisted bag necks',
  },
  {
    c: 'B65B 53',
    d: 'Shrinking wrappers, containers, container covers or container cover securing members during or after packaging',
  },
  {
    c: 'B65B 55',
    d: 'Preserving, protecting or purifying packages or package contents in association with packaging',
  },
  {
    c: 'B65B 57',
    d: 'Automatic control, checking, warning or safety devices',
  },
  {
    c: 'B65B 59',
    d: 'Arrangements to enable machines to handle articles of different sizes, to produce packages of different sizes, to vary the contents of packages or to give access for cleaning or maintenance purposes',
  },
  {
    c: 'B65B 61',
    d: 'Auxiliary devices, not otherwise provided for, for operating on sheets, blanks, webs, binding material, containers or packages',
  },
  {
    c: 'B65B 63',
    d: 'Auxiliary devices, not otherwise provided for, for operating on articles or materials to be packaged',
  },
  {
    c: 'B65B 65',
    d: 'Details peculiar to packaging machines and not otherwise provided for; Arrangements of such details',
  },
  {
    c: 'B65B 67',
    d: 'Apparatus or devices facilitating manual packaging operations; Sack holders',
  },
  {
    c: 'B65B 69',
    d: 'Unpacking of articles or materials, not otherwise provided for',
  },
  {
    c: 'B65C',
    d: 'LABELLING OR TAGGING MACHINES, APPARATUS, OR PROCESSES',
  },
  {
    c: 'B65C 1',
    d: 'Labelling flat essentially-rigid surfaces',
  },
  {
    c: 'B65C 3',
    d: 'Labelling other than flat surfaces',
  },
  {
    c: 'B65C 5',
    d: 'Labelling fabrics or comparable materials or articles with deformable surface, e.g. paper, fabric rolls, stockings, shoes',
  },
  {
    c: 'B65C 7',
    d: 'Affixing tags',
  },
  {
    c: 'B65C 9',
    d: 'Details of labelling machines or apparatus',
  },
  {
    c: 'B65C 11',
    d: 'Manually-controlled or manually-operable label dispensers, e.g. modified for the application of labels to articles',
  },
  {
    c: 'B65D',
    d: 'CONTAINERS FOR STORAGE OR TRANSPORT OF ARTICLES OR MATERIALS, e.g. BAGS, BARRELS, BOTTLES, BOXES, CANS, CARTONS, CRATES, DRUMS, JARS, TANKS, HOPPERS, FORWARDING CONTAINERS; ACCESSORIES, CLOSURES, OR FITTINGS THEREFOR; PACKAGING ELEMENTS; PACKAGES',
  },
  {
    c: 'B65D 1',
    d: 'General kinds of rigid or semi-rigid containers',
  },
  {
    c: 'B65D 3',
    d: 'Rigid or semi-rigid containers having bodies or peripheral walls of curved or partially-curved cross-section made by winding or bending paper without folding along defined lines',
  },
  {
    c: 'B65D 5',
    d: 'Rigid or semi-rigid containers of polygonal cross-section, e.g. boxes, cartons or trays, formed by folding or erecting one or more blanks made of paper',
  },
  {
    c: 'B65D 6',
    d: 'Containers having bodies formed by interconnecting or uniting two or more rigid, or substantially rigid, components made wholly or mainly of metal, plastics, wood or substitutes therefor',
  },
  {
    c: 'B65D 8',
    d: 'Containers having a curved cross-section formed by interconnecting or uniting two or more rigid, or substantially rigid, components made wholly or mainly of metal, plastics, wood or substitutes therefor',
  },
  {
    c: 'B65D 13',
    d: 'Containers having bodies formed by interconnecting two or more rigid, or substantially rigid, components made wholly or mainly of the same material, other than metal, plastics, wood, or substitutes therefor',
  },
  {
    c: 'B65D 17',
    d: 'Rigid or semi-rigid containers specially constructed to be opened by cutting or piercing, or by tearing of frangible members or portions',
  },
  {
    c: 'B65D 19',
    d: 'Pallets or like platforms, with or without side walls, for supporting loads to be lifted or lowered',
  },
  {
    c: 'B65D 21',
    d: 'Nestable, stackable or joinable containers; Containers of variable capacity',
  },
  {
    c: 'B65D 23',
    d: 'Details of rigid or semi-rigid containers not otherwise provided for',
  },
  {
    c: 'B65D 25',
    d: 'Details of other kinds or types of rigid or semi-rigid containers',
  },
  {
    c: 'B65D 27',
    d: 'General kinds of flexible containers',
  },
  {
    c: 'B65D 30',
    d: 'Sacks, bags or like containers',
  },
  {
    c: 'B65D 33',
    d: 'Details of, or accessories for, sacks or bags',
  },
  {
    c: 'B65D 35',
    d: 'Pliable tubular containers adapted to be permanently deformed to expel contents, e.g. collapsible tubes for toothpaste or other plastic or semi-liquid material; Holders therefor',
  },
  {
    c: 'B65D 37',
    d: 'Portable flexible containers not otherwise provided for',
  },
  {
    c: 'B65D 39',
    d: 'Closure members, other than those folded of paper and incorporated in or attached to the container, for opening of rigid or semi-rigid containers without destroying outer wall portions of the container itself, or of flexible containers presenting similar closing problems; Arrangements or devices for preventing refilling of containers; Parts of containers co-operating with closure members or characterised by the form of closure members',
  },
  {
    c: 'B65D 41',
    d: 'Caps, e.g. crown caps or crown seals, i.e. members having parts arranged for engagement with the external periphery of a neck or wall defining a pouring opening or discharge aperture; Protective cap-like covers for closure members, e.g. decorative covers of metal foil or paper',
  },
  {
    c: 'B65D 43',
    d: 'Lids or covers for rigid or semi-rigid containers',
  },
  {
    c: 'B65D 45',
    d: 'Clamping or other pressure-applying devices for securing or retaining closure members',
  },
  {
    c: 'B65D 47',
    d: 'Closures with filling and discharging, or with discharging, devices',
  },
  {
    c: 'B65D 49',
    d: 'Arrangements or devices for preventing refilling of containers',
  },
  {
    c: 'B65D 50',
    d: 'Closures with means for discouraging unauthorised opening or removal thereof, with or without indicating means, e.g. child-proof closures',
  },
  {
    c: 'B65D 51',
    d: 'Closures not otherwise provided for',
  },
  {
    c: 'B65D 53',
    d: 'Sealing or packing elements; Sealings formed by liquid or plastic material',
  },
  {
    c: 'B65D 55',
    d: 'Accessories for container closures not otherwise provided for',
  },
  {
    c: 'B65D 57',
    d: 'Kinds or types of packaging elements',
  },
  {
    c: 'B65D 59',
    d: 'Plugs, sleeves, caps, or like rigid or semi-rigid elements for protecting parts of articles or for bundling articles, e.g. protectors for screw-threads, corner protectors, end caps for tubes or for bundling rod-shaped articles',
  },
  {
    c: 'B65D 61',
    d: 'External frames or supports adapted to be assembled around, or applied to, articles',
  },
  {
    c: 'B65D 63',
    d: 'Flexible elongated elements, e.g. straps, for bundling or supporting articles',
  },
  {
    c: 'B65D 65',
    d: 'Wrappers or flexible covers; Packaging materials of special type or form',
  },
  {
    c: 'B65D 67',
    d: 'Kinds or types of packaging elements not otherwise provided for',
  },
  {
    c: 'B65D 69',
    d: 'Kinds or types of packages',
  },
  {
    c: 'B65D 71',
    d: 'Bundles of articles held together by packaging elements for convenience of storage or transport, e.g. portable segregating carrier for plural receptacles such as beer cans or pop bottles; Bales of material',
  },
  {
    c: 'B65D 73',
    d: 'Packages comprising articles attached to cards, sheets or webs',
  },
  {
    c: 'B65D 75',
    d: 'Packages comprising articles or materials partially or wholly enclosed in strips, sheets, blanks, tubes, or webs of flexible sheet material, e.g. in folded wrappers',
  },
  {
    c: 'B65D 77',
    d: 'Packages formed by enclosing articles or materials in preformed containers, e.g. boxes, cartons, sacks or bags',
  },
  {
    c: 'B65D 79',
    d: 'Kinds or details of packages, not otherwise provided for',
  },
  {
    c: 'B65D 81',
    d: 'Containers, packaging elements, or packages, of special types or forms or specially adapted for organisms, articles, or materials presenting particular transport, storage, or dispensing problems',
  },
  {
    c: 'B65D 83',
    d: 'Containers or packages with special means for dispensing contents',
  },
  {
    c: 'B65D 85',
    d: 'Containers, packaging elements or packages, specially adapted for particular articles or materials',
  },
  {
    c: 'B65D 88',
    d: 'Large containers',
  },
  {
    c: 'B65D 90',
    d: 'Component parts, details or accessories for large containers',
  },
  {
    c: 'B65F',
    d: 'GATHERING OR REMOVAL OF DOMESTIC OR LIKE REFUSE',
  },
  {
    c: 'B65F 1',
    d: 'Refuse receptacles',
  },
  {
    c: 'B65F 3',
    d: 'Vehicles particularly adapted for collecting refuse',
  },
  {
    c: 'B65F 5',
    d: 'Gathering or removal of refuse otherwise than by receptacles or vehicles',
  },
  {
    c: 'B65F 7',
    d: 'Cleaning or disinfecting devices combined with refuse receptacles or refuse vehicles',
  },
  {
    c: 'B65F 9',
    d: 'Transferring of refuse between vehicles or containers with intermediate storage or pressing',
  },
  {
    c: 'B65G',
    d: 'TRANSPORT OR STORAGE DEVICES, e.g. CONVEYORS FOR LOADING OR TIPPING, SHOP CONVEYOR SYSTEMS OR PNEUMATIC TUBE CONVEYORS',
  },
  {
    c: 'B65G 1',
    d: 'Storing; Storage devices',
  },
  {
    c: 'B65G 3',
    d: 'Storing bulk material or loose, i.e. disorderly, articles',
  },
  {
    c: 'B65G 5',
    d: 'Storing fluids in natural or artificial cavities or chambers in the earth',
  },
  {
    c: 'B65G 7',
    d: 'Devices assisting manual conveyance of articles over short distances, e.g. in storage depots, warehouses or factories',
  },
  {
    c: 'B65G 9',
    d: 'Apparatus for assisting manual handling having suspended load-carriers movable by hand or gravity',
  },
  {
    c: 'B65G 11',
    d: 'Chutes; Kinds or types of conveyors; Constructional features, details or auxiliary devices peculiar to conveyors of particular types',
  },
  {
    c: 'B65G 13',
    d: 'Roller-ways',
  },
  {
    c: 'B65G 15',
    d: 'Conveyors having endless load-conveying surfaces, i.e. belts and like continuous members, to which tractive effort is transmitted by means other than endless driving elements of similar configuration',
  },
  {
    c: 'B65G 17',
    d: 'Conveyors having an endless traction element, e.g. a chain, transmitting movement to a continuous or substantially-continuous load-carrying surface or to a series of individual load-carriers; Endless-chain conveyors in which the chains form the load-carrying surface',
  },
  {
    c: 'B65G 19',
    d: 'Conveyors comprising an impeller or a series of impellers carried by an endless traction element and arranged to move articles or materials over a supporting surface or underlying material, e.g. endless scraper conveyors',
  },
  {
    c: 'B65G 21',
    d: 'Supporting or protective framework or housings for endless load-carriers or traction elements of belt or chain conveyors',
  },
  {
    c: 'B65G 23',
    d: 'Driving gear for endless conveyors; Belt- or chain-tensioning arrangements',
  },
  {
    c: 'B65G 25',
    d: 'Conveyors comprising a cyclically-moving, e.g. reciprocating, carrier or impeller which is disengaged from the load during the return part of its movement',
  },
  {
    c: 'B65G 27',
    d: 'Jigging conveyors',
  },
  {
    c: 'B65G 29',
    d: 'Rotary conveyors, e.g. rotating discs, arms, star-wheels or cones',
  },
  {
    c: 'B65G 31',
    d: 'Mechanical throwing machines for articles or solid materials',
  },
  {
    c: 'B65G 33',
    d: 'Screw or rotary spiral conveyors',
  },
  {
    c: 'B65G 35',
    d: 'Mechanical conveyors not otherwise provided for',
  },
  {
    c: 'B65G 37',
    d: 'Combinations of mechanical conveyors of the same kind, or of different kinds, of interest apart from their application in particular machines or use in particular manufacturing processes',
  },
  {
    c: 'B65G 39',
    d: 'Common features or details of, or auxiliary devices applicable to, conveyors of different kinds or types; Feeding or discharging devices incorporated in, or operatively associated with, conveyors',
  },
  {
    c: 'B65G 41',
    d: 'Supporting frames or bases for conveyors as a whole, e.g. transportable conveyor frames',
  },
  {
    c: 'B65G 43',
    d: 'Control devices, e.g. for safety, warning or fault-correcting',
  },
  {
    c: 'B65G 45',
    d: 'Lubricating, cleaning, or clearing devices',
  },
  {
    c: 'B65G 47',
    d: 'Article or material-handling devices associated with conveyors; Methods employing such devices',
  },
  {
    c: 'B65G 49',
    d: 'Conveying systems characterised by their application for specified purposes not otherwise provided for',
  },
  {
    c: 'B65G 51',
    d: 'Non-mechanical conveying through pipes or tubes; Floating in troughs',
  },
  {
    c: 'B65G 53',
    d: 'Conveying materials in bulk through troughs, pipes or tubes by floating the materials or by flow of gas, liquid or foam',
  },
  {
    c: 'B65G 54',
    d: 'Non-mechanical conveyors not otherwise provided for',
  },
  {
    c: 'B65G 57',
    d: 'Stacking or de-stacking; Loading or unloading',
  },
  {
    c: 'B65G 59',
    d: 'De-stacking of articles',
  },
  {
    c: 'B65G 60',
    d: 'Simultaneously or alternatively stacking and de-stacking of articles',
  },
  {
    c: 'B65G 61',
    d: 'Use of pick-up or transfer devices or of manipulators for stacking or de-stacking articles not otherwise provided for',
  },
  {
    c: 'B65G 63',
    d: 'Transferring or trans-shipping at storage areas, railway yards or harbours; Marshalling yard installations',
  },
  {
    c: 'B65G 65',
    d: 'Loading or unloading',
  },
  {
    c: 'B65G 67',
    d: 'Loading or unloading vehicles',
  },
  {
    c: 'B65G 69',
    d: 'Auxiliary measures taken, or devices used, in connection with loading or unloading',
  },
  {
    c: 'B65H',
    d: 'HANDLING THIN OR FILAMENTARY MATERIAL, e.g. SHEETS, WEBS, CABLES',
  },
  {
    c: 'B65H 1',
    d: 'Feeding articles to machines; Separating articles from piles; Pile supports',
  },
  {
    c: 'B65H 3',
    d: 'Separating articles from piles',
  },
  {
    c: 'B65H 5',
    d: 'Feeding articles separated from piles; Feeding articles to machines',
  },
  {
    c: 'B65H 7',
    d: 'Controlling article feeding, separating, pile-advancing, or associated apparatus, to take account of incorrect feeding, absence of articles, or presence of faulty articles',
  },
  {
    c: 'B65H 9',
    d: 'Registering, e.g. orientating, articles; Devices therefor',
  },
  {
    c: 'B65H 11',
    d: 'Feed tables',
  },
  {
    c: 'B65H 13',
    d: 'Lifting the ends of piles to facilitate the formation of overlapped piles',
  },
  {
    c: 'B65H 15',
    d: 'Overturning articles',
  },
  {
    c: 'B65H 16',
    d: 'Feeding webs to or from machines; Winding or unwinding webs; Splicing webs',
  },
  {
    c: 'B65H 18',
    d: 'Winding webs',
  },
  {
    c: 'B65H 19',
    d: 'Changing the web roll',
  },
  {
    c: 'B65H 20',
    d: 'Advancing webs',
  },
  {
    c: 'B65H 21',
    d: 'Apparatus for splicing webs',
  },
  {
    c: 'B65H 23',
    d: 'Registering, tensioning, smoothing, or guiding webs',
  },
  {
    c: 'B65H 26',
    d: 'Warning or safety devices, e.g. automatic fault detectors, stop-motions, for web-advancing mechanisms',
  },
  {
    c: 'B65H 27',
    d: 'Special constructions, e.g. surface features, of feed or guide rollers for webs',
  },
  {
    c: 'B65H 29',
    d: 'Delivering articles from machines; Piling articles; Article or web delivery apparatus incorporating devices for performing specified auxiliary operations; Associating or gathering articles or webs; Machines for separating superposed webs',
  },
  {
    c: 'B65H 31',
    d: 'Pile receivers',
  },
  {
    c: 'B65H 33',
    d: 'Forming counted batches in delivery pile or stream of articles',
  },
  {
    c: 'B65H 35',
    d: 'Delivering articles from cutting or line-perforating machines; Article or web delivery apparatus incorporating cutting or line-perforating devices, e.g. adhesive tape dispensers',
  },
  {
    c: 'B65H 37',
    d: 'Article or web delivery apparatus incorporating devices for performing specified auxiliary operations',
  },
  {
    c: 'B65H 39',
    d: 'Associating, collating, or gathering articles or webs',
  },
  {
    c: 'B65H 41',
    d: 'Machines for separating superposed webs',
  },
  {
    c: 'B65H 43',
    d: 'Use of control, checking, or safety devices, e.g. automatic devices comprising an element for sensing a variable',
  },
  {
    c: 'B65H 45',
    d: 'Folding or unfolding thin material',
  },
  {
    c: 'B65H 47',
    d: 'Unfolding thin limp material',
  },
  {
    c: 'B65H 49',
    d: 'Unwinding, paying-out, forwarding, winding, coiling, or depositing filamentary material',
  },
  {
    c: 'B65H 51',
    d: 'Forwarding filamentary material',
  },
  {
    c: 'B65H 54',
    d: 'Winding, coiling, or depositing filamentary material',
  },
  {
    c: 'B65H 55',
    d: 'Wound packages of filamentary material',
  },
  {
    c: 'B65H 57',
    d: 'Guides for filamentary materials; Supports therefor',
  },
  {
    c: 'B65H 59',
    d: 'Adjusting or controlling tension in filamentary material, e.g. for preventing snarling; Applications of tension indicators',
  },
  {
    c: 'B65H 61',
    d: 'Applications of devices for metering predetermined lengths of running material',
  },
  {
    c: 'B65H 63',
    d: 'Warning or safety devices for use when unwinding, paying-out, forwarding, winding, coiling, or depositing filamentary material, e.g. automatic fault detectors or stop-motions',
  },
  {
    c: 'B65H 65',
    d: 'Securing material to cores or formers',
  },
  {
    c: 'B65H 67',
    d: 'Replacing or removing cores, receptacles, or completed packages at paying-out, winding, or depositing stations',
  },
  {
    c: 'B65H 69',
    d: 'Methods of, or devices for, interconnecting successive lengths of material; Knot-tying devices',
  },
  {
    c: 'B65H 71',
    d: 'Moistening, sizing, oiling, waxing, colouring, or drying filamentary material as additional measures during package formation',
  },
  {
    c: 'B65H 73',
    d: 'Stripping waste material from cores or formers, e.g. to permit their re-use',
  },
  {
    c: 'B65H 75',
    d: 'Methods, apparatus or devices of general interest or not otherwise provided for in connection with the handling of webs, tapes or filamentary materials',
  },
  {
    c: 'B65H 77',
    d: 'Adjusting or controlling tension in material',
  },
  {
    c: 'B65H 79',
    d: 'Driving gear for devices for forwarding, winding, unwinding, or depositing material, not otherwise provided for',
  },
  {
    c: 'B65H 81',
    d: 'Methods, apparatus, or devices for covering or wrapping cores by winding webs, tapes, or filamentary material, not otherwise provided for',
  },
  {
    c: 'B65H 83',
    d: 'Combinations of piling and depiling operations, e.g. performed simultaneously, of interest apart from the single operation of piling or depiling',
  },
  {
    c: 'B65H 85',
    d: 'Recirculating articles, i.e. feeding each article to, and delivering it from, the same machine work-station more than once',
  },
  {
    c: 'B65H 99',
    d: 'n.a.',
  },
  {
    c: 'B66',
    d: 'HOISTING; LIFTING; HAULING',
  },
  {
    c: 'B66B',
    d: 'ELEVATORS; ESCALATORS OR MOVING WALKWAYS',
  },
  {
    c: 'B66B 1',
    d: 'Common features of elevators',
  },
  {
    c: 'B66B 3',
    d: 'Applications of devices for indicating or signalling operating conditions  of elevators',
  },
  {
    c: 'B66B 5',
    d: 'Applications of checking, fault-correcting or safety devices in elevators',
  },
  {
    c: 'B66B 7',
    d: 'Other common features of elevators',
  },
  {
    c: 'B66B 9',
    d: 'Lifts in, or associated with, buildings',
  },
  {
    c: 'B66B 11',
    d: 'Main component parts of lifts in, or associated with, buildings or other structures',
  },
  {
    c: 'B66B 13',
    d: 'Doors, gates, or other apparatus controlling access to, or exit from, cages or lift-well landings',
  },
  {
    c: 'B66B 15',
    d: 'Lifts in hoistways of mines',
  },
  {
    c: 'B66B 17',
    d: 'Hoistway equipment',
  },
  {
    c: 'B66B 19',
    d: 'Mining-hoist operation',
  },
  {
    c: 'B66B 20',
    d: 'Elevators not provided for in groups B66B0001000000-B66B0019000000',
  },
  {
    c: 'B66B 21',
    d: 'Escalators or moving walkways',
  },
  {
    c: 'B66B 23',
    d: 'Component parts of escalators or moving walkways',
  },
  {
    c: 'B66B 25',
    d: 'Control of escalators or moving walkways',
  },
  {
    c: 'B66B 27',
    d: 'Indicating operating conditions of escalators or moving walkways',
  },
  {
    c: 'B66B 29',
    d: 'Safety devices of escalators or moving walkways',
  },
  {
    c: 'B66B 31',
    d: 'Accessories for escalators, or moving walkways, e.g. for sterilising or cleaning',
  },
  {
    c: 'B66C',
    d: 'CRANES; LOAD-ENGAGING ELEMENTS OR DEVICES FOR CRANES, CAPSTANS, WINCHES, OR TACKLES',
  },
  {
    c: 'B66C 1',
    d: 'Load-engaging elements or devices attached to lifting, lowering, or hauling gear of cranes or adapted for connection therewith',
  },
  {
    c: 'B66C 3',
    d: 'Load-engaging elements or devices attached to lifting or lowering gear of cranes or adapted for connection therewith and intended primarily for transmitting lifting forces to loose materials; Grabs',
  },
  {
    c: 'B66C 5',
    d: 'Other common features; Details',
  },
  {
    c: 'B66C 6',
    d: 'Girders, or track-supporting structures, specially adapted for cranes',
  },
  {
    c: 'B66C 7',
    d: 'Runways, tracks, or trackways for trolleys or cranes',
  },
  {
    c: 'B66C 9',
    d: 'Travelling gear incorporated in, or fitted to, trolleys or cranes',
  },
  {
    c: 'B66C 11',
    d: 'Trolleys or crabs, e.g. operating above runways',
  },
  {
    c: 'B66C 13',
    d: 'Other constructional features or details',
  },
  {
    c: 'B66C 15',
    d: 'Safety gear',
  },
  {
    c: 'B66C 17',
    d: 'Kinds or types of cranes',
  },
  {
    c: 'B66C 19',
    d: 'Cranes comprising trolleys or crabs running on fixed or movable bridges or gantries',
  },
  {
    c: 'B66C 21',
    d: 'Cable cranes, i.e. comprising hoisting devices running on aerial cable-ways',
  },
  {
    c: 'B66C 23',
    d: 'Cranes comprising essentially a beam, boom or triangular structure acting as a cantilever and mounted for translatory or swinging movements in vertical or horizontal planes or a combination of such movements, e.g. jib cranes, derricks or tower cranes',
  },
  {
    c: 'B66C 25',
    d: 'Cranes not provided for in groups B66C0017000000-B66C0023000000',
  },
  {
    c: 'B66D',
    d: 'CAPSTANS; WINCHES; TACKLES, e.g. PULLEY BLOCKS; HOISTS',
  },
  {
    c: 'B66D 1',
    d: 'Rope, cable, or chain winding mechanisms; Capstans',
  },
  {
    c: 'B66D 3',
    d: 'Portable or mobile lifting or hauling appliances',
  },
  {
    c: 'B66D 5',
    d: 'Braking or detent devices characterised by their application to lifting or hoisting gear, e.g. for controlling the lowering of loads',
  },
  {
    c: 'B66F',
    d: 'HOISTING, LIFTING, HAULING, OR PUSHING, NOT OTHERWISE PROVIDED FOR, e.g. DEVICES WHICH APPLY A LIFTING OR PUSHING FORCE DIRECTLY TO THE SURFACE OF A LOAD',
  },
  {
    c: 'B66F 1',
    d: 'Devices, e.g. jacks, for lifting loads in predetermined steps',
  },
  {
    c: 'B66F 3',
    d: 'Devices, e.g. jacks, adapted for uninterrupted lifting of loads',
  },
  {
    c: 'B66F 5',
    d: 'Mobile jacks of the garage type mounted on wheels or rollers',
  },
  {
    c: 'B66F 7',
    d: 'Lifting frames, e.g. for lifting vehicles; Platform lifts',
  },
  {
    c: 'B66F 9',
    d: 'Devices for lifting or lowering bulky or heavy goods for loading or unloading purposes',
  },
  {
    c: 'B66F 11',
    d: 'Lifting devices specially adapted for particular uses not otherwise provided for',
  },
  {
    c: 'B66F 13',
    d: 'Common constructional features or accessories',
  },
  {
    c: 'B66F 15',
    d: 'Crowbars or levers',
  },
  {
    c: 'B66F 17',
    d: 'Safety devices, e.g. for limiting or indicating lifting force',
  },
  {
    c: 'B66F 19',
    d: 'Hoisting, lifting, hauling, or pushing, not otherwise provided for',
  },
  {
    c: 'B67',
    d: 'OPENING OR CLOSING BOTTLES, JARS OR SIMILAR CONTAINERS; LIQUID HANDLING',
  },
  {
    c: 'B67B',
    d: 'APPLYING CLOSURE MEMBERS TO BOTTLES, JARS, OR SIMILAR CONTAINERS; OPENING CLOSED CONTAINERS',
  },
  {
    c: 'B67B 1',
    d: 'Closing bottles, jars, or similar containers by applying stoppers',
  },
  {
    c: 'B67B 3',
    d: 'Closing bottles, jars, or similar containers by applying caps',
  },
  {
    c: 'B67B 5',
    d: 'Applying protective or decorative covers to closures; Devices for securing bottle closures with wire',
  },
  {
    c: 'B67B 6',
    d: 'Closing bottles, jars or similar containers by applying closure members, not provided for in groups B67B0001000000-B67B0005000000',
  },
  {
    c: 'B67B 7',
    d: 'Hand- or power-operated devices for opening closed containers',
  },
  {
    c: 'B67C',
    d: 'FILLING WITH LIQUIDS OR SEMILIQUIDS, OR EMPTYING, OF BOTTLES, JARS, CANS, CASKS, BARRELS, OR SIMILAR CONTAINERS, NOT OTHERWISE PROVIDED FOR; FUNNELS',
  },
  {
    c: 'B67C 3',
    d: 'Bottling liquids or semiliquids; Filling jars or cans with liquids or semiliquids using bottling or like apparatus; Filling casks or barrels with liquids or semiliquids',
  },
  {
    c: 'B67C 7',
    d: 'Concurrent cleaning, filling, and closing of bottles; Processes or devices for at least two of these operations',
  },
  {
    c: 'B67C 9',
    d: 'Emptying bottles, jars, cans, casks, barrels, or similar containers, not otherwise provided for',
  },
  {
    c: 'B67C 11',
    d: 'Funnels, e.g. for liquids',
  },
  {
    c: 'B67D',
    d: 'DISPENSING, DELIVERING, OR TRANSFERRING LIQUIDS, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'B67D 1',
    d: 'Apparatus or devices for dispensing beverages on draught',
  },
  {
    c: 'B67D 3',
    d: 'Apparatus or devices for controlling flow of liquids under gravity from storage containers for dispensing purposes',
  },
  {
    c: 'B67D 7',
    d: 'Apparatus or devices for transferring liquids from bulk storage containers or reservoirs into vehicles or into portable containers, e.g. for retail sale purposes',
  },
  {
    c: 'B67D 9',
    d: 'Apparatus or devices for transferring liquids when loading or unloading ships',
  },
  {
    c: 'B67D 99',
    d: 'n.a.',
  },
  {
    c: 'B68',
    d: 'SADDLERY; UPHOLSTERY',
  },
  {
    c: 'B68B',
    d: 'HARNESS; DEVICES USED IN CONNECTION THEREWITH; WHIPS OR THE LIKE',
  },
  {
    c: 'B68B 1',
    d: 'Devices in connection with harness, for hitching, reining, training, breaking, or quietening horses or other traction animals',
  },
  {
    c: 'B68B 3',
    d: 'Traction harnesses; Traction harnesses combined with devices referred to in group B68B0001000000',
  },
  {
    c: 'B68B 5',
    d: 'Details or accessories',
  },
  {
    c: 'B68B 7',
    d: 'Horse protectors, e.g. resilient attachments',
  },
  {
    c: 'B68B 9',
    d: 'Devices specially adapted for supporting harnesses when not in use',
  },
  {
    c: 'B68B 11',
    d: 'Whips or the like',
  },
  {
    c: 'B68B 99',
    d: 'n.a.',
  },
  {
    c: 'B68C',
    d: 'SADDLES; STIRRUPS',
  },
  {
    c: 'B68C 1',
    d: 'Saddling equipment for riding- or pack-animals',
  },
  {
    c: 'B68C 3',
    d: 'Stirrups',
  },
  {
    c: 'B68C 5',
    d: 'Covers for animals when working, e.g. for protection',
  },
  {
    c: 'B68F',
    d: 'MAKING ARTICLES FROM LEATHER, CANVAS, OR THE LIKE',
  },
  {
    c: 'B68F 1',
    d: 'Making articles from leather, canvas, or the like',
  },
  {
    c: 'B68F 3',
    d: 'Machines or equipment specially adapted for saddle-making',
  },
  {
    c: 'B68G',
    d: 'METHODS, EQUIPMENT, OR MACHINES FOR USE IN UPHOLSTERING; UPHOLSTERY NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'B68G 1',
    d: 'Loose filling materials for upholstery',
  },
  {
    c: 'B68G 3',
    d: 'Treating materials to adapt them specially as upholstery filling',
  },
  {
    c: 'B68G 5',
    d: 'Resilient upholstery pads',
  },
  {
    c: 'B68G 7',
    d: 'Making upholstery',
  },
  {
    c: 'B68G 9',
    d: 'Placing upholstery springs in pockets; Fitting springs in upholstery',
  },
  {
    c: 'B68G 11',
    d: 'Finished upholstery not provided for in other classes',
  },
  {
    c: 'B68G 13',
    d: 'Upholstered panels',
  },
  {
    c: 'B68G 15',
    d: 'Auxiliary devices or tools specially for upholstery',
  },
  {
    c: 'B68G 99',
    d: 'n.a.',
  },
  {
    c: 'B81',
    d: 'MICROSTRUCTURAL TECHNOLOGY',
  },
  {
    c: 'B81B',
    d: 'MICROSTRUCTURAL DEVICES OR SYSTEMS, e.g. MICROMECHANICAL DEVICES',
  },
  {
    c: 'B81B 1',
    d: 'Devices without movable or flexible elements, e.g. microcapillary devices',
  },
  {
    c: 'B81B 3',
    d: 'Devices comprising flexible or deformable elements, e.g. comprising elastic tongues or membranes',
  },
  {
    c: 'B81B 5',
    d: 'Devices comprising elements which are movable in relation to each other, e.g. comprising slidable or rotatable elements',
  },
  {
    c: 'B81B 7',
    d: 'Microstructural systems',
  },
  {
    c: 'B81C',
    d: 'PROCESSES OR APPARATUS SPECIALLY ADAPTED FOR THE MANUFACTURE OR TREATMENT OF MICROSTRUCTURAL DEVICES OR SYSTEMS',
  },
  {
    c: 'B81C 1',
    d: 'Manufacture or treatment of devices or systems in or on a substrate',
  },
  {
    c: 'B81C 3',
    d: 'Assembling of devices or systems from individually processed components',
  },
  {
    c: 'B81C 99',
    d: 'n.a.',
  },
  {
    c: 'B82',
    d: 'NANOTECHNOLOGY',
  },
  {
    c: 'B82B',
    d: 'NANOSTRUCTURES FORMED BY MANIPULATION OF INDIVIDUAL ATOMS, MOLECULES, OR LIMITED COLLECTIONS OF ATOMS OR MOLECULES AS DISCRETE UNITS; MANUFACTURE OR TREATMENT THEREOF',
  },
  {
    c: 'B82B 1',
    d: 'Nanostructures formed by manipulation of individual atoms or molecules, or limited collections of atoms or molecules as discrete units',
  },
  {
    c: 'B82B 3',
    d: 'Manufacture or treatment of nanostructures by manipulation of individual atoms or molecules, or limited collections of atoms or molecules as discrete units',
  },
  {
    c: 'B82Y',
    d: 'SPECIFIC USES OR APPLICATIONS OF NANOSTRUCTURES; MEASUREMENT OR ANALYSIS OF NANOSTRUCTURES; MANUFACTURE  OR TREATMENT OF NANOSTRUCTURES',
  },
  {
    c: 'B82Y 5',
    d: 'Nanobiotechnology or nanomedicine, e.g. protein engineering or drug delivery',
  },
  {
    c: 'B82Y 10',
    d: 'Nanotechnology for information processing, storage or transmission, e.g. quantum computing or single electron logic',
  },
  {
    c: 'B82Y 15',
    d: 'Nanotechnology for interacting, sensing or actuating, e.g. quantum dots as markers in protein assays or molecular motors',
  },
  {
    c: 'B82Y 20',
    d: 'Nanooptics, e.g. quantum optics or photonic crystals',
  },
  {
    c: 'B82Y 25',
    d: 'Nanomagnetism, e.g. magnetoimpedance, anisotropic magnetoresistance, giant magnetoresistance or tunneling magnetoresistance',
  },
  {
    c: 'B82Y 30',
    d: 'Nanotechnology for materials or surface science, e.g. nanocomposites',
  },
  {
    c: 'B82Y 35',
    d: 'Methods or apparatus for measurement or analysis of nanostructures',
  },
  {
    c: 'B82Y 40',
    d: 'Manufacture or treatment of nanostructures',
  },
  {
    c: 'B82Y 99',
    d: 'n.a.',
  },
  {
    c: 'B99',
    d: 'n.a.',
  },
  {
    c: 'B99Z',
    d: 'n.a.',
  },
  {
    c: 'B99Z 99',
    d: 'n.a.',
  },
  {
    c: 'C',
    d: 'CHEMISTRY; METALLURGY',
  },
  {
    c: 'C01',
    d: 'INORGANIC CHEMISTRY',
  },
  {
    c: 'C01B',
    d: 'NON-METALLIC ELEMENTS; COMPOUNDS THEREOF',
  },
  {
    c: 'C01B 3',
    d: 'Hydrogen; Hydrides; Water; Synthesis gas from hydrocarbons',
  },
  {
    c: 'C01B 4',
    d: 'Hydrogen isotopes; Inorganic compounds thereof prepared by isotope exchange, e.g. NH3 + D2',
  },
  {
    c: 'C01B 5',
    d: 'Water',
  },
  {
    c: 'C01B 6',
    d: 'Hydrides of metals; Monoborane or diborane; Addition complexes thereof',
  },
  {
    c: 'C01B 7',
    d: 'Halogens; Compounds thereof',
  },
  {
    c: 'C01B 9',
    d: 'General methods of preparing halides',
  },
  {
    c: 'C01B 11',
    d: 'Oxides or oxyacids of halogens; Salts thereof',
  },
  {
    c: 'C01B 13',
    d: 'Oxygen; Oxides or hydroxides in general; Per-compounds',
  },
  {
    c: 'C01B 15',
    d: 'Peroxides; Peroxyhydrates; Peroxyacids or salts thereof; Superoxides; Ozonides',
  },
  {
    c: 'C01B 17',
    d: 'Sulfur; Compounds thereof',
  },
  {
    c: 'C01B 19',
    d: 'Selenium; Tellurium; Compounds thereof',
  },
  {
    c: 'C01B 21',
    d: 'Nitrogen; Compounds thereof',
  },
  {
    c: 'C01B 23',
    d: 'Noble gases; Compounds thereof',
  },
  {
    c: 'C01B 25',
    d: 'Phosphorus; Compounds thereof',
  },
  {
    c: 'C01B 32',
    d: 'Carbon; Compounds thereof',
  },
  {
    c: 'C01B 33',
    d: 'Silicon; Compounds thereof',
  },
  {
    c: 'C01B 35',
    d: 'Boron; Compounds thereof',
  },
  {
    c: 'C01B 37',
    d: 'Compounds characterised primarily by their physical or chemical properties, rather than by their chemical constitution',
  },
  {
    c: 'C01B 39',
    d: 'Compounds having molecular sieve and base-exchange properties, e.g. crystalline zeolites; Their preparation; After-treatment, e.g. ion-exchange or dealumination',
  },
  {
    c: 'C01C',
    d: 'AMMONIA; CYANOGEN; COMPOUNDS THEREOF',
  },
  {
    c: 'C01C 1',
    d: 'Ammonia; Compounds thereof',
  },
  {
    c: 'C01C 3',
    d: 'Cyanogen; Compounds thereof',
  },
  {
    c: 'C01D',
    d: 'COMPOUNDS OF ALKALI METALS, i.e. LITHIUM, SODIUM, POTASSIUM, RUBIDIUM, CAESIUM, OR FRANCIUM',
  },
  {
    c: 'C01D 1',
    d: 'Oxides or hydroxides of sodium, potassium, or alkali metals in general',
  },
  {
    c: 'C01D 3',
    d: 'Halides of sodium, potassium, or alkali metals in general',
  },
  {
    c: 'C01D 5',
    d: 'Sulfates or sulfites of sodium, potassium, or alkali metals in general',
  },
  {
    c: 'C01D 7',
    d: 'Carbonates of sodium, potassium, or alkali metals in general',
  },
  {
    c: 'C01D 9',
    d: 'Nitrates of sodium, potassium, or alkali metals in general',
  },
  {
    c: 'C01D 13',
    d: 'Compounds of sodium or potassium not provided for elsewhere',
  },
  {
    c: 'C01D 15',
    d: 'Lithium compounds',
  },
  {
    c: 'C01D 17',
    d: 'Rubidium, caesium, or francium compounds',
  },
  {
    c: 'C01F',
    d: 'COMPOUNDS OF THE METALS BERYLLIUM, MAGNESIUM, ALUMINIUM, CALCIUM, STRONTIUM, BARIUM, RADIUM, THORIUM, OR OF THE RARE-EARTH METALS',
  },
  {
    c: 'C01F 1',
    d: 'Methods of preparing compounds of the metals beryllium, magnesium, aluminium, calcium, strontium, barium, radium, thorium, or the rare earths, in general',
  },
  {
    c: 'C01F 3',
    d: 'Compounds of beryllium',
  },
  {
    c: 'C01F 5',
    d: 'Compounds of magnesium',
  },
  {
    c: 'C01F 7',
    d: 'Compounds of aluminium',
  },
  {
    c: 'C01F 11',
    d: 'Compounds of calcium, strontium, or barium',
  },
  {
    c: 'C01F 13',
    d: 'Compounds of radium',
  },
  {
    c: 'C01F 15',
    d: 'Compounds of thorium',
  },
  {
    c: 'C01F 17',
    d: 'Compounds of the rare-earth metals, i.e. scandium, yttrium, lanthanum, or the group of the lanthanides',
  },
  {
    c: 'C01G',
    d: 'COMPOUNDS CONTAINING METALS NOT COVERED BY SUBCLASSES C01D OR C01F',
  },
  {
    c: 'C01G 1',
    d: 'Methods of preparing compounds of metals not covered by subclasses C01B, C01C, C01D, C01F, in general',
  },
  {
    c: 'C01G 3',
    d: 'Compounds of copper',
  },
  {
    c: 'C01G 5',
    d: 'Compounds of silver',
  },
  {
    c: 'C01G 7',
    d: 'Compounds of gold',
  },
  {
    c: 'C01G 9',
    d: 'Compounds of zinc',
  },
  {
    c: 'C01G 11',
    d: 'Compounds of cadmium',
  },
  {
    c: 'C01G 13',
    d: 'Compounds of mercury',
  },
  {
    c: 'C01G 15',
    d: 'Compounds of gallium, indium, or thallium',
  },
  {
    c: 'C01G 17',
    d: 'Compounds of germanium',
  },
  {
    c: 'C01G 19',
    d: 'Compounds of tin',
  },
  {
    c: 'C01G 21',
    d: 'Compounds of lead',
  },
  {
    c: 'C01G 23',
    d: 'Compounds of titanium',
  },
  {
    c: 'C01G 25',
    d: 'Compounds of zirconium',
  },
  {
    c: 'C01G 27',
    d: 'Compounds of hafnium',
  },
  {
    c: 'C01G 28',
    d: 'Compounds of arsenic',
  },
  {
    c: 'C01G 29',
    d: 'Compounds of bismuth',
  },
  {
    c: 'C01G 30',
    d: 'Compounds of antimony',
  },
  {
    c: 'C01G 31',
    d: 'Compounds of vanadium',
  },
  {
    c: 'C01G 33',
    d: 'Compounds of niobium',
  },
  {
    c: 'C01G 35',
    d: 'Compounds of tantalum',
  },
  {
    c: 'C01G 37',
    d: 'Compounds of chromium',
  },
  {
    c: 'C01G 39',
    d: 'Compounds of molybdenum',
  },
  {
    c: 'C01G 41',
    d: 'Compounds of tungsten',
  },
  {
    c: 'C01G 43',
    d: 'Compounds of uranium',
  },
  {
    c: 'C01G 45',
    d: 'Compounds of manganese',
  },
  {
    c: 'C01G 47',
    d: 'Compounds of rhenium',
  },
  {
    c: 'C01G 49',
    d: 'Compounds of iron',
  },
  {
    c: 'C01G 51',
    d: 'Compounds of cobalt',
  },
  {
    c: 'C01G 53',
    d: 'Compounds of nickel',
  },
  {
    c: 'C01G 55',
    d: 'Compounds of ruthenium, rhodium, palladium, osmium, iridium, or platinum',
  },
  {
    c: 'C01G 56',
    d: 'Compounds of transuranic elements',
  },
  {
    c: 'C01G 99',
    d: 'n.a.',
  },
  {
    c: 'C02',
    d: 'TREATMENT OF WATER, WASTE WATER, SEWAGE, OR SLUDGE',
  },
  {
    c: 'C02F',
    d: 'TREATMENT OF WATER, WASTE WATER, SEWAGE, OR SLUDGE',
  },
  {
    c: 'C02F 1',
    d: 'Treatment of water, waste water, or sewage',
  },
  {
    c: 'C02F 3',
    d: 'Biological treatment of water, waste water, or sewage',
  },
  {
    c: 'C02F 5',
    d: 'Softening water; Preventing scale; Adding scale preventatives or scale removers to water, e.g. adding sequestering agents',
  },
  {
    c: 'C02F 7',
    d: 'Aeration of stretches of water',
  },
  {
    c: 'C02F 9',
    d: 'Multistep treatment of water, waste water or sewage',
  },
  {
    c: 'C02F 11',
    d: 'Treatment of sludge; Devices therefor',
  },
  {
    c: 'C02F 101',
    d: 'Indexing scheme associated with groups C02F0001000000-C02F0011000000 relating to the nature of the contaminant in the water, waste water, sewage or sludge.',
  },
  {
    c: 'C02F 103',
    d: 'Indexing scheme associated with groups C02F0001000000-C02F0011000000, relating to the nature of the water, waste water, sewage or sludge to be treated.',
  },
  {
    c: 'C03',
    d: 'GLASS; MINERAL OR SLAG WOOL',
  },
  {
    c: 'C03B',
    d: 'MANUFACTURE OR SHAPING OF GLASS, OR OF MINERAL OR SLAG WOOL; SUPPLEMENTARY PROCESSES IN THE MANUFACTURE OR SHAPING OF GLASS, OR OF MINERAL OR SLAG WOOL',
  },
  {
    c: 'C03B 1',
    d: 'Melting the raw material',
  },
  {
    c: 'C03B 3',
    d: 'Charging the melting furnaces',
  },
  {
    c: 'C03B 5',
    d: 'Melting in furnaces; Furnaces so far as specially adapted for glass manufacture',
  },
  {
    c: 'C03B 7',
    d: 'Distributors for the molten glass; Means for taking-off charges of molten glass; Producing the gob',
  },
  {
    c: 'C03B 8',
    d: 'Production of glass by other processes than melting processes',
  },
  {
    c: 'C03B 9',
    d: 'Shaping of glass',
  },
  {
    c: 'C03B 11',
    d: 'Pressing glass',
  },
  {
    c: 'C03B 13',
    d: 'Rolling glass',
  },
  {
    c: 'C03B 15',
    d: 'Drawing glass upwardly from the melt',
  },
  {
    c: 'C03B 17',
    d: 'Forming glass by flowing out, pushing-out, or drawing downwardly or laterally from forming slits or by overflowing over lips',
  },
  {
    c: 'C03B 18',
    d: 'Shaping glass in contact with the surface of a liquid',
  },
  {
    c: 'C03B 19',
    d: 'Other methods of shaping glass',
  },
  {
    c: 'C03B 20',
    d: 'Processes specially adapted for the production of quartz or fused silica articles',
  },
  {
    c: 'C03B 21',
    d: 'Severing glass sheets, tubes, or rods while still plastic',
  },
  {
    c: 'C03B 23',
    d: 'Re-forming shaped glass',
  },
  {
    c: 'C03B 25',
    d: 'After-treatment of glass product',
  },
  {
    c: 'C03B 27',
    d: 'Tempering glass products',
  },
  {
    c: 'C03B 29',
    d: 'Reheating glass products for softening or fusing their surfaces; Fire-polishing; Fusing of margins',
  },
  {
    c: 'C03B 31',
    d: 'Manufacture of rippled or crackled glass',
  },
  {
    c: 'C03B 32',
    d: 'Thermal after-treatment of glass products not provided for in groups C03B0025000000-C03B0031000000, e.g. crystallisation, eliminating gas inclusions or other impurities',
  },
  {
    c: 'C03B 33',
    d: 'Severing cooled glass',
  },
  {
    c: 'C03B 35',
    d: 'Transporting of glass products during their manufacture',
  },
  {
    c: 'C03B 37',
    d: 'Manufacture or treatment of flakes, fibres, or filaments from softened glass, minerals, or slags',
  },
  {
    c: 'C03B 40',
    d: 'Preventing adhesion between glass and glass or between glass and the means used to shape it',
  },
  {
    c: 'C03C',
    d: 'CHEMICAL COMPOSITION OF GLASSES, GLAZES, OR VITREOUS ENAMELS; SURFACE TREATMENT OF GLASS; SURFACE TREATMENT OF FIBRES OR FILAMENTS MADE FROM GLASS, MINERALS OR SLAGS; JOINING GLASS TO GLASS OR OTHER MATERIALS',
  },
  {
    c: 'C03C 1',
    d: 'Chemical composition of glasses, glazes, or vitreous enamels',
  },
  {
    c: 'C03C 3',
    d: 'Glass compositions',
  },
  {
    c: 'C03C 4',
    d: 'Compositions for glass with special properties',
  },
  {
    c: 'C03C 6',
    d: 'Glass batch compositions',
  },
  {
    c: 'C03C 8',
    d: 'Enamels; Glazes',
  },
  {
    c: 'C03C 10',
    d: 'Devitrified glass ceramics, i.e. glass ceramics having a crystalline phase dispersed in a glassy phase and constituting at least 50% by weight of the total composition',
  },
  {
    c: 'C03C 11',
    d: 'Multi-cellular glass',
  },
  {
    c: 'C03C 12',
    d: 'Powdered glass',
  },
  {
    c: 'C03C 13',
    d: 'Fibre or filament compositions',
  },
  {
    c: 'C03C 14',
    d: 'Glass compositions containing a non-glass component, e.g. compositions containing fibres, filaments, whiskers, platelets, or the like, dispersed in a glass matrix',
  },
  {
    c: 'C03C 15',
    d: 'Surface treatment of glass; Surface treatment of fibres or filaments made from glass, minerals or slags',
  },
  {
    c: 'C03C 17',
    d: 'Surface treatment of glass, e.g. of devitrified glass, not in the form of fibres or filaments, by coating',
  },
  {
    c: 'C03C 19',
    d: 'Surface treatment of glass, not in the form of fibres or filaments, by mechanical means',
  },
  {
    c: 'C03C 21',
    d: 'Treatment of glass, not in the form of fibres or filaments, by diffusing ions or metals into the surface',
  },
  {
    c: 'C03C 23',
    d: 'Other surface treatment of glass not in the form of fibres or filaments',
  },
  {
    c: 'C03C 25',
    d: 'Surface treatment of fibres or filaments made from glass, minerals or slags',
  },
  {
    c: 'C03C 27',
    d: 'Joining glass to glass or to other materials',
  },
  {
    c: 'C03C 29',
    d: 'Joining metals with the aid of glass',
  },
  {
    c: 'C04',
    d: 'CEMENTS; CONCRETE; ARTIFICIAL STONE; CERAMICS; REFRACTORIES',
  },
  {
    c: 'C04B',
    d: 'LIME; MAGNESIA; SLAG; CEMENTS; COMPOSITIONS THEREOF, e.g. MORTARS, CONCRETE OR LIKE BUILDING MATERIALS; ARTIFICIAL STONE; CERAMICS',
  },
  {
    c: 'C04B 2',
    d: 'Lime; Magnesia; Slag',
  },
  {
    c: 'C04B 5',
    d: 'Treatment of molten slag',
  },
  {
    c: 'C04B 7',
    d: 'Cements',
  },
  {
    c: 'C04B 9',
    d: 'Magnesium cements or silimar cements',
  },
  {
    c: 'C04B 11',
    d: 'Calcium sulfate cements',
  },
  {
    c: 'C04B 12',
    d: 'Cements not provided for in groups C04B0007000000-C04B0011000000',
  },
  {
    c: 'C04B 14',
    d: 'Use of materials as fillers for mortars, concrete or artificial stone',
  },
  {
    c: 'C04B 16',
    d: 'Use of organic materials as fillers, e.g. pigments, for mortars, concrete or artificial stone; Treatment of organic materials specially adapted to enhance their filling properties in mortars, concrete or artificial stone',
  },
  {
    c: 'C04B 18',
    d: 'Use of agglomerated or waste materials or refuse as fillers for mortars, concrete or artificial stone; Treatment of agglomerated or waste materials or refuse, specially adapted to enhance their filling properties in mortars, concrete or artificial stone',
  },
  {
    c: 'C04B 20',
    d: 'Use of materials as fillers for mortars, concrete or artificial stone according to more than one of groups C04B0014000000-C04B0018000000 and characterised by shape or grain distribution; Treatment of materials according to more than one of the groups C04B0014000000-C04B0018000000 specially adapted to enhance their filling properties in mortars, concrete or artificial stone; Expanding or defibrillating materials',
  },
  {
    c: 'C04B 22',
    d: 'Use of materials as active ingredients',
  },
  {
    c: 'C04B 24',
    d: 'Use of organic materials as active ingredients for mortars, concrete or artificial stone, e.g. plasticisers',
  },
  {
    c: 'C04B 26',
    d: 'Compositions of mortars, concrete or artificial stone',
  },
  {
    c: 'C04B 28',
    d: 'Compositions of mortars, concrete or artificial stone, containing inorganic binders or the reaction product of an inorganic and an organic binder, e.g. polycarboxylate cements',
  },
  {
    c: 'C04B 30',
    d: 'Compositions for artificial stone, not containing binders',
  },
  {
    c: 'C04B 32',
    d: 'Artificial stone not provided for in other groups of this subclass',
  },
  {
    c: 'C04B 33',
    d: 'Ceramics',
  },
  {
    c: 'C04B 35',
    d: 'Shaped ceramic products characterised by their composition; Ceramic compositions',
  },
  {
    c: 'C04B 37',
    d: 'Joining burned ceramic articles with other burned ceramic articles or other articles by heating',
  },
  {
    c: 'C04B 38',
    d: 'Porous mortars, concrete, artificial stone or ceramic ware; Preparation thereof',
  },
  {
    c: 'C04B 40',
    d: 'Processes, in general, for influencing or modifying the properties of mortars, concrete or artificial stone compositions, e.g. their setting or hardening ability',
  },
  {
    c: 'C04B 41',
    d: 'After-treatment of mortars, concrete, artificial stone or ceramics; Treatment of natural stone',
  },
  {
    c: 'C04B 103',
    d: 'Indexing scheme associated with groups C04B0022000000 and C04B0024000000, relating to the function or property of the active ingredients.',
  },
  {
    c: 'C04B 111',
    d: 'Indexing scheme associated with groups C04B0026000000-C04B0032000000, relating to the function, property or use of the mortars, concrete or artificial stone.',
  },
  {
    c: 'C05',
    d: 'FERTILISERS; MANUFACTURE THEREOF',
  },
  {
    c: 'C05B',
    d: 'PHOSPHATIC FERTILISERS',
  },
  {
    c: 'C05B 1',
    d: 'Superphosphates, i.e. fertilisers produced by reacting rock or bone phosphates with sulfuric or phosphoric acid in such amounts and concentrations as to yield solid products directly',
  },
  {
    c: 'C05B 3',
    d: 'Fertilisers based essentially on di-calcium phosphate',
  },
  {
    c: 'C05B 5',
    d: 'Thomas phosphate; Other slag phosphates',
  },
  {
    c: 'C05B 7',
    d: 'Fertilisers based essentially on alkali or ammonium orthophosphates',
  },
  {
    c: 'C05B 9',
    d: 'Fertilisers based essentially on phosphates or double phosphates of magnesium',
  },
  {
    c: 'C05B 11',
    d: 'Fertilisers produced by wet-treating or leaching raw materials either with acids in such amounts and concentrations as to yield solutions followed by neutralisation, or with alkaline lyes',
  },
  {
    c: 'C05B 13',
    d: 'Fertilisers produced by pyrogenic processes from phosphatic materials',
  },
  {
    c: 'C05B 15',
    d: 'Organic phosphatic fertilisers',
  },
  {
    c: 'C05B 17',
    d: 'Other phosphatic fertilisers, e.g. soft rock phosphates, bone meal',
  },
  {
    c: 'C05B 19',
    d: 'Granulation or pelletisation of phosphatic fertilisers other than slag',
  },
  {
    c: 'C05B 21',
    d: 'Mixtures of phosphatic fertilisers covered by more than one of main groups C05B0001000000-C05B0019000000',
  },
  {
    c: 'C05C',
    d: 'NITROGENOUS FERTILISERS',
  },
  {
    c: 'C05C 1',
    d: 'Ammonium nitrate fertilisers',
  },
  {
    c: 'C05C 3',
    d: 'Fertilisers containing other salts of ammonia or ammonia itself, e.g. gas liquor',
  },
  {
    c: 'C05C 5',
    d: 'Fertilisers containing other nitrates',
  },
  {
    c: 'C05C 7',
    d: 'Fertilisers containing calcium or other cyanamides',
  },
  {
    c: 'C05C 9',
    d: 'Fertilisers containing urea or urea compounds',
  },
  {
    c: 'C05C 11',
    d: 'Other nitrogenous fertilisers',
  },
  {
    c: 'C05C 13',
    d: 'Mixtures of nitrogenous fertilisers covered by more than one of main groups C05C0001000000-C05C0011000000',
  },
  {
    c: 'C05D',
    d: 'INORGANIC FERTILISERS NOT COVERED BY SUBCLASSES C05B, C05C; FERTILISERS PRODUCING CARBON DIOXIDE',
  },
  {
    c: 'C05D 1',
    d: 'Fertilisers containing potassium',
  },
  {
    c: 'C05D 3',
    d: 'Calcareous fertilisers',
  },
  {
    c: 'C05D 5',
    d: 'Fertilisers containing magnesium',
  },
  {
    c: 'C05D 7',
    d: 'Fertilisers producing carbon dioxide',
  },
  {
    c: 'C05D 9',
    d: 'Other inorganic fertilisers',
  },
  {
    c: 'C05D 11',
    d: 'Mixtures of fertilisers covered by more than one of main groups C05D0001000000-C05D0009000000',
  },
  {
    c: 'C05F',
    d: 'ORGANIC FERTILISERS NOT COVERED BY SUBCLASSES C05B, C05C, e.g. FERTILISERS FROM WASTE OR REFUSE',
  },
  {
    c: 'C05F 1',
    d: 'Fertilisers made from animal corpses, or parts thereof',
  },
  {
    c: 'C05F 3',
    d: 'Fertilisers from human or animal excrements, e.g. manure',
  },
  {
    c: 'C05F 5',
    d: 'Fertilisers from distillery wastes, molasses, vinasses, sugar plant, or similar wastes or residues',
  },
  {
    c: 'C05F 7',
    d: 'Fertilisers from waste water, sewage sludge, sea slime, ooze or similar masses',
  },
  {
    c: 'C05F 9',
    d: 'Fertilisers from household or town refuse',
  },
  {
    c: 'C05F 11',
    d: 'Other organic fertilisers',
  },
  {
    c: 'C05F 15',
    d: 'Mixtures of fertilisers covered by more than one of main groups C05F0001000000-C05F0011000000; Fertilisers from mixtures of starting materials, all the starting materials being covered by this subclass but not by the same main group',
  },
  {
    c: 'C05F 17',
    d: 'Preparation of fertilisers characterised by the composting step',
  },
  {
    c: 'C05G',
    d: 'MIXTURES OF FERTILISERS COVERED INDIVIDUALLY BY DIFFERENT SUBCLASSES OF CLASS C05; MIXTURES OF ONE OR MORE FERTILISERS WITH MATERIALS NOT HAVING A SPECIFIC FERTILISING ACTIVITY, e.g. PESTICIDES, SOIL-CONDITIONERS, WETTING AGENTS',
  },
  {
    c: 'C05G 1',
    d: 'Mixtures of fertilisers covered individually by different subclasses of class C05',
  },
  {
    c: 'C05G 3',
    d: 'Mixtures of one or more fertilisers with materials not having a specifically fertilising activity',
  },
  {
    c: 'C05G 5',
    d: 'Fertilisers characterised by their form',
  },
  {
    c: 'C06',
    d: 'EXPLOSIVES; MATCHES',
  },
  {
    c: 'C06B',
    d: 'EXPLOSIVE OR THERMIC COMPOSITIONS',
  },
  {
    c: 'C06B 21',
    d: 'Apparatus or methods for working-up explosives, e.g. forming, cutting, drying',
  },
  {
    c: 'C06B 23',
    d: 'Compositions characterised by non-explosive or non-thermic constituents',
  },
  {
    c: 'C06B 25',
    d: 'Compositions containing a nitrated organic compound',
  },
  {
    c: 'C06B 27',
    d: 'Compositions containing a metal, boron, silicon, selenium or tellurium or mixtures, intercompounds or hydrides thereof, and hydrocarbons or halogenated hydrocarbons',
  },
  {
    c: 'C06B 29',
    d: 'Compositions containing an inorganic oxygen-halogen salt, e.g. chlorate, perchlorate',
  },
  {
    c: 'C06B 31',
    d: 'Compositions containing an inorganic nitrogen-oxygen salt',
  },
  {
    c: 'C06B 33',
    d: 'Compositions containing particulate metal, alloy, boron, silicon, selenium or tellurium with at least one oxygen supplying material which is either a metal oxide or a salt, organic or inorganic, capable of yielding a metal oxide',
  },
  {
    c: 'C06B 35',
    d: 'Compositions containing a metal azide',
  },
  {
    c: 'C06B 37',
    d: 'Compositions containing a metal fulminate',
  },
  {
    c: 'C06B 39',
    d: 'Compositions containing free phosphorus or a binary compound of phosphorus, except with oxygen',
  },
  {
    c: 'C06B 41',
    d: 'Compositions containing a nitrated metallo-organic compound',
  },
  {
    c: 'C06B 43',
    d: 'Compositions characterised by explosive or thermic constituents not provided for in groups C06B0025000000-C06B0041000000',
  },
  {
    c: 'C06B 45',
    d: 'Compositions or products which are defined by structure or arrangement of component or product',
  },
  {
    c: 'C06B 47',
    d: 'Compositions in which the components are separately stored until the moment of burning or explosion, e.g. Sprengel-type explosives; Suspensions of solid component in a normally non-explosive liquid phase, including a thickened aqueous phase',
  },
  {
    c: 'C06B 49',
    d: 'Use of single substances as explosives',
  },
  {
    c: 'C06C',
    d: 'DETONATING OR PRIMING DEVICES; FUSES; CHEMICAL LIGHTERS; PYROPHORIC COMPOSITIONS',
  },
  {
    c: 'C06C 5',
    d: 'Fuses, e.g. fuse cords',
  },
  {
    c: 'C06C 7',
    d: 'Non-electric detonators; Blasting caps; Primers',
  },
  {
    c: 'C06C 9',
    d: 'Chemical contact igniters; Chemical lighters',
  },
  {
    c: 'C06C 15',
    d: 'Pyrophoric compositions; Flints',
  },
  {
    c: 'C06D',
    d: 'MEANS FOR GENERATING SMOKE OR MIST; GAS-ATTACK COMPOSITIONS; GENERATION OF GAS FOR BLASTING OR PROPULSION',
  },
  {
    c: 'C06D 3',
    d: 'Generation of smoke or mist',
  },
  {
    c: 'C06D 5',
    d: 'Generation of pressure gas, e.g. for blasting cartridges, starting cartridges, rockets',
  },
  {
    c: 'C06D 7',
    d: 'Compositions for gas-attacks',
  },
  {
    c: 'C06F',
    d: 'MATCHES; MANUFACTURE OF MATCHES',
  },
  {
    c: 'C06F 1',
    d: 'Mechanical manufacture of matches',
  },
  {
    c: 'C06F 3',
    d: 'Chemical features in the manufacture of matches',
  },
  {
    c: 'C06F 5',
    d: 'Matches',
  },
  {
    c: 'C07',
    d: 'ORGANIC CHEMISTRY',
  },
  {
    c: 'C07B',
    d: 'GENERAL METHODS OF ORGANIC CHEMISTRY; APPARATUS THEREFOR',
  },
  {
    c: 'C07B 31',
    d: 'Reduction in general',
  },
  {
    c: 'C07B 33',
    d: 'Oxidation in general',
  },
  {
    c: 'C07B 35',
    d: 'Reactions without formation or introduction of functional groups containing hetero atoms',
  },
  {
    c: 'C07B 37',
    d: 'Reactions without formation or introduction of functional groups containing hetero atoms, involving either the formation of a carbon-to-carbon bond between two carbon atoms not directly linked already or the disconnection of two directly linked carbon atoms',
  },
  {
    c: 'C07B 39',
    d: 'Reactions with formation or introduction of functional groups containing hetero atoms',
  },
  {
    c: 'C07B 41',
    d: 'Formation or introduction of functional groups containing oxygen',
  },
  {
    c: 'C07B 43',
    d: 'Formation or introduction of functional groups containing nitrogen',
  },
  {
    c: 'C07B 45',
    d: 'Formation or introduction of functional groups containing sulfur',
  },
  {
    c: 'C07B 47',
    d: 'Formation or introduction of functional groups not provided for in groups C07B0039000000-C07B0045000000',
  },
  {
    c: 'C07B 49',
    d: 'Grignard reactions',
  },
  {
    c: 'C07B 51',
    d: 'Introduction of protecting groups or activating groups, not provided for in groups C07B0031000000-C07B0049000000',
  },
  {
    c: 'C07B 53',
    d: 'Asymmetric syntheses',
  },
  {
    c: 'C07B 55',
    d: 'Racemisation; Complete or partial inversion',
  },
  {
    c: 'C07B 57',
    d: 'Separation of optically-active organic compounds',
  },
  {
    c: 'C07B 59',
    d: 'Introduction of isotopes of elements into organic compounds',
  },
  {
    c: 'C07B 60',
    d: 'Generation of organic free radicals',
  },
  {
    c: 'C07B 61',
    d: 'Other general methods',
  },
  {
    c: 'C07B 63',
    d: 'Purification; Separation; Stabilisation',
  },
  {
    c: 'C07C',
    d: 'ACYCLIC OR CARBOCYCLIC COMPOUNDS',
  },
  {
    c: 'C07C 1',
    d: 'Hydrocarbons',
  },
  {
    c: 'C07C 2',
    d: 'Preparation of hydrocarbons from hydrocarbons containing a smaller number of carbon atoms',
  },
  {
    c: 'C07C 4',
    d: 'Preparation of hydrocarbons from hydrocarbons containing a larger number of carbon atoms',
  },
  {
    c: 'C07C 5',
    d: 'Preparation of hydrocarbons from hydrocarbons containing the same number of carbon atoms',
  },
  {
    c: 'C07C 6',
    d: 'Preparation of hydrocarbons from hydrocarbons containing a different number of carbon atoms by redistribution reactions',
  },
  {
    c: 'C07C 7',
    d: 'Purification, separation or stabilisation of hydrocarbons; Use of additives',
  },
  {
    c: 'C07C 9',
    d: 'Acyclic saturated hydrocarbons',
  },
  {
    c: 'C07C 11',
    d: 'Acyclic unsaturated hydrocarbons',
  },
  {
    c: 'C07C 13',
    d: 'Cyclic hydrocarbons containing rings other than, or in addition to, six-membered aromatic rings',
  },
  {
    c: 'C07C 15',
    d: 'Cyclic hydrocarbons containing only six-membered aromatic rings as cyclic part',
  },
  {
    c: 'C07C 17',
    d: 'Compounds containing carbon and halogens with or without hydrogen',
  },
  {
    c: 'C07C 19',
    d: 'Acyclic saturated compounds containing halogen atoms',
  },
  {
    c: 'C07C 21',
    d: 'Acyclic unsaturated compounds containing halogen atoms',
  },
  {
    c: 'C07C 22',
    d: 'Cyclic compounds containing halogen atoms bound to an acyclic carbon atom',
  },
  {
    c: 'C07C 23',
    d: 'Compounds containing at least one halogen atom bound to a ring other than a six-membered aromatic ring',
  },
  {
    c: 'C07C 25',
    d: 'Compounds containing at least one halogen atom bound to a six-membered aromatic ring',
  },
  {
    c: 'C07C 27',
    d: 'Compounds containing carbon and oxygen, with or without hydrogen or halogens',
  },
  {
    c: 'C07C 29',
    d: 'Preparation of compounds having hydroxy or O-metal groups bound to a carbon atom not belonging to a six-membered aromatic ring',
  },
  {
    c: 'C07C 31',
    d: 'Saturated compounds having hydroxy or O-metal groups bound to acyclic carbon atoms',
  },
  {
    c: 'C07C 33',
    d: 'Unsaturated compounds having hydroxy or O-metal groups bound to acyclic carbon atoms',
  },
  {
    c: 'C07C 35',
    d: 'Compounds having at least one hydroxy or O-metal group bound to a carbon atom of a ring other than a six-membered aromatic ring',
  },
  {
    c: 'C07C 37',
    d: 'Preparation of compounds having hydroxy or O-metal groups bound to a carbon atom of a six-membered aromatic ring',
  },
  {
    c: 'C07C 39',
    d: 'Compounds having at least one hydroxy or O-metal group bound to a carbon atom of a six-membered aromatic ring',
  },
  {
    c: 'C07C 41',
    d: 'Preparation of ethers; Preparation of compounds having groups, groups or groups',
  },
  {
    c: 'C07C 43',
    d: 'Ethers; Compounds having groups, groups or groups',
  },
  {
    c: 'C07C 45',
    d: 'Preparation of compounds having C=O groups bound only to carbon or hydrogen atoms; Preparation of chelates of such compounds',
  },
  {
    c: 'C07C 46',
    d: 'Preparation of quinones',
  },
  {
    c: 'C07C 47',
    d: 'Compounds having —CHO groups',
  },
  {
    c: 'C07C 49',
    d: 'Ketones; Ketenes; Dimeric ketenes; Ketonic chelates',
  },
  {
    c: 'C07C 50',
    d: 'Quinones',
  },
  {
    c: 'C07C 51',
    d: 'Preparation of carboxylic acids or their salts, halides, or anhydrides',
  },
  {
    c: 'C07C 53',
    d: 'Saturated compounds having only one carboxyl group bound to an acyclic carbon atom or hydrogen',
  },
  {
    c: 'C07C 55',
    d: 'Saturated compounds having more than one carboxyl group bound to acyclic carbon atoms',
  },
  {
    c: 'C07C 57',
    d: 'Unsaturated compounds having carboxyl groups bound to acyclic carbon atoms',
  },
  {
    c: 'C07C 59',
    d: 'Compounds having carboxyl groups bound to acyclic carbon atoms and containing any of the groups OH, O-metal, —CHO, keto, ether, groups, groups, or groups',
  },
  {
    c: 'C07C 61',
    d: 'Compounds having carboxyl groups bound to carbon atoms of rings other than six-membered aromatic rings',
  },
  {
    c: 'C07C 62',
    d: 'Compounds having carboxyl groups bound to carbon atoms of rings other than six-membered aromatic rings and containing any of the groups OH, O-metal, —CHO, keto, ether, groups, groups, or groups',
  },
  {
    c: 'C07C 63',
    d: 'Compounds having carboxyl groups bound to carbon atoms of six-membered aromatic rings',
  },
  {
    c: 'C07C 65',
    d: 'Compounds having carboxyl groups bound to carbon atoms of six-membered aromatic rings and containing any of the groups OH, O-metal, —CHO, keto, ether, groups, groups, or groups',
  },
  {
    c: 'C07C 66',
    d: 'Quinone carboxylic acids',
  },
  {
    c: 'C07C 67',
    d: 'Preparation of carboxylic acid esters',
  },
  {
    c: 'C07C 68',
    d: 'Preparation of esters of carbonic or haloformic acids',
  },
  {
    c: 'C07C 69',
    d: 'Esters of carboxylic acids; Esters of carbonic or haloformic acids',
  },
  {
    c: 'C07C 71',
    d: 'Esters of oxyacids of halogens',
  },
  {
    c: 'C07C 201',
    d: 'Compounds containing carbon and nitrogen with or without hydrogen, halogens or oxygen',
  },
  {
    c: 'C07C 203',
    d: 'Esters of nitric or nitrous acid',
  },
  {
    c: 'C07C 205',
    d: 'Compounds containing nitro groups bound to a carbon skeleton',
  },
  {
    c: 'C07C 207',
    d: 'Compounds containing nitroso groups bound to a carbon skeleton',
  },
  {
    c: 'C07C 209',
    d: 'Preparation of compounds containing amino groups bound to a carbon skeleton',
  },
  {
    c: 'C07C 211',
    d: 'Compounds containing amino groups bound to a carbon skeleton',
  },
  {
    c: 'C07C 213',
    d: 'Preparation of compounds containing amino and hydroxy, amino and etherified hydroxy or amino and esterified hydroxy groups bound to the same carbon skeleton',
  },
  {
    c: 'C07C 215',
    d: 'Compounds containing amino and hydroxy groups bound to the same carbon skeleton',
  },
  {
    c: 'C07C 217',
    d: 'Compounds containing amino and etherified hydroxy groups bound to the same carbon skeleton',
  },
  {
    c: 'C07C 219',
    d: 'Compounds containing amino and esterified hydroxy groups bound to the same carbon skeleton',
  },
  {
    c: 'C07C 221',
    d: 'Preparation of compounds containing amino groups and doubly-bound oxygen atoms bound to the same carbon skeleton',
  },
  {
    c: 'C07C 223',
    d: 'Compounds containing amino and —CHO groups bound to the same carbon skeleton',
  },
  {
    c: 'C07C 225',
    d: 'Compounds containing amino groups and doubly-bound oxygen atoms bound to the same carbon skeleton, at least one of the doubly-bound oxygen atoms not being part of a —CHO group, e.g. amino ketones',
  },
  {
    c: 'C07C 227',
    d: 'Preparation of compounds containing amino and carboxyl groups bound to the same carbon skeleton',
  },
  {
    c: 'C07C 229',
    d: 'Compounds containing amino and carboxyl groups bound to the same carbon skeleton',
  },
  {
    c: 'C07C 231',
    d: 'Preparation of carboxylic acid amides',
  },
  {
    c: 'C07C 233',
    d: 'Carboxylic acid amides',
  },
  {
    c: 'C07C 235',
    d: 'Carboxylic acid amides, the carbon skeleton of the acid part being further substituted by oxygen atoms',
  },
  {
    c: 'C07C 237',
    d: 'Carboxylic acid amides, the carbon skeleton of the acid part being further substituted by amino groups',
  },
  {
    c: 'C07C 239',
    d: 'Compounds containing nitrogen-to-halogen bonds; Hydroxylamino compounds or ethers or esters thereof',
  },
  {
    c: 'C07C 241',
    d: 'Preparation of compounds containing chains of nitrogen atoms singly-bound to each other, e.g. hydrazines, triazanes',
  },
  {
    c: 'C07C 243',
    d: 'Compounds containing chains of nitrogen atoms singly-bound to each other, e.g. hydrazines, triazanes',
  },
  {
    c: 'C07C 245',
    d: 'Compounds containing chains of at least two nitrogen atoms with at least one nitrogen-to-nitrogen multiple bond',
  },
  {
    c: 'C07C 247',
    d: 'Compounds containing azido groups',
  },
  {
    c: 'C07C 249',
    d: 'Preparation of compounds containing nitrogen atoms doubly-bound to a carbon skeleton',
  },
  {
    c: 'C07C 251',
    d: 'Compounds containing nitrogen atoms doubly- bound to a carbon skeleton',
  },
  {
    c: 'C07C 253',
    d: 'Preparation of carboxylic acid nitriles',
  },
  {
    c: 'C07C 255',
    d: 'Carboxylic acid nitriles',
  },
  {
    c: 'C07C 257',
    d: 'Compounds containing carboxyl groups, the doubly-bound oxygen atom of a carboxyl group being replaced by a doubly-bound nitrogen atom, this nitrogen atom not being further bound to an oxygen atom, e.g. imino-ethers, amidines',
  },
  {
    c: 'C07C 259',
    d: 'Compounds containing carboxyl groups, an oxygen atom of a carboxyl group being replaced by a nitrogen atom, this nitrogen atom being further bound to an oxygen atom and not being part of nitro or nitroso groups',
  },
  {
    c: 'C07C 261',
    d: 'Derivatives of cyanic acid',
  },
  {
    c: 'C07C 263',
    d: 'Preparation of derivatives of isocyanic acid',
  },
  {
    c: 'C07C 265',
    d: 'Derivatives of isocyanic acid',
  },
  {
    c: 'C07C 267',
    d: 'Carbodiimides',
  },
  {
    c: 'C07C 269',
    d: 'Preparation of derivatives of carbamic acid, i.e. compounds containing any of the groups the nitrogen atom not being part of nitro or nitroso groups',
  },
  {
    c: 'C07C 271',
    d: 'Derivatives of carbamic acid, i.e. compounds containing any of the groups the nitrogen atom not being part of nitro or nitroso groups',
  },
  {
    c: 'C07C 273',
    d: 'Preparation of urea or its derivatives, i.e. compounds containing any of the groups the nitrogen atoms not being part of nitro or nitroso groups',
  },
  {
    c: 'C07C 275',
    d: 'Derivatives of urea, i.e. compounds containing any of the groups the nitrogen atoms not being part of nitro or nitroso groups',
  },
  {
    c: 'C07C 277',
    d: 'Preparation of guanidine or its derivatives, i.e. compounds containing the group the singly-bound nitrogen atoms not being part of nitro or nitroso groups',
  },
  {
    c: 'C07C 279',
    d: 'Derivatives of guanidine, i.e. compounds containing the group the singly-bound nitrogen atoms not being part of nitro or nitroso groups',
  },
  {
    c: 'C07C 281',
    d: 'Derivatives of carbonic acid containing functional groups covered by groups C07C0269000000-C07C0279000000 in which at least one nitrogen atom of these functional groups is further bound to another nitrogen atom not being part of a nitro or nitroso group',
  },
  {
    c: 'C07C 291',
    d: 'Compounds containing carbon and nitrogen and having functional groups not covered by groups C07C0201000000-C07C0281000000',
  },
  {
    c: 'C07C 301',
    d: 'Compounds containing carbon together with sulfur, selenium or tellurium, with or without hydrogen, halogens, oxygen or nitrogen',
  },
  {
    c: 'C07C 303',
    d: 'Preparation of esters or amides of sulfuric acids; Preparation of sulfonic acids or of their esters, halides, anhydrides or amides',
  },
  {
    c: 'C07C 305',
    d: 'Esters of sulfuric acids',
  },
  {
    c: 'C07C 307',
    d: 'Amides of sulfuric acids, i.e. compounds having singly-bound oxygen atoms of sulfate groups replaced by nitrogen atoms, not being part of nitro or nitroso groups',
  },
  {
    c: 'C07C 309',
    d: 'Sulfonic acids; Halides, esters, or anhydrides thereof',
  },
  {
    c: 'C07C 311',
    d: 'Amides of sulfonic acids, i.e. compounds having singly-bound oxygen atoms of sulfo groups replaced by nitrogen atoms, not being part of nitro or nitroso groups',
  },
  {
    c: 'C07C 313',
    d: 'Sulfinic acids; Sulfenic acids; Halides, esters or anhydrides thereof; Amides of sulfinic or sulfenic acids, i.e. compounds having singly-bound oxygen atoms of sulfinic or sulfenic groups replaced by nitrogen atoms, not being part of nitro or nitroso groups',
  },
  {
    c: 'C07C 315',
    d: 'Preparation of sulfones; Preparation of sulfoxides',
  },
  {
    c: 'C07C 317',
    d: 'Sulfones; Sulfoxides',
  },
  {
    c: 'C07C 319',
    d: 'Preparation of thiols, sulfides, hydropolysulfides or polysulfides',
  },
  {
    c: 'C07C 321',
    d: 'Thiols, sulfides, hydropolysulfides or polysulfides',
  },
  {
    c: 'C07C 323',
    d: 'Thiols, sulfides, hydropolysulfides or polysulfides substituted by halogen, oxygen or nitrogen atoms, or by sulfur atoms not being part of thio groups',
  },
  {
    c: 'C07C 325',
    d: 'Thioaldehydes; Thioketones; Thioquinones; Oxides thereof',
  },
  {
    c: 'C07C 327',
    d: 'Thiocarboxylic acids',
  },
  {
    c: 'C07C 329',
    d: 'Thiocarbonic acids; Halides, esters or anhydrides thereof',
  },
  {
    c: 'C07C 331',
    d: 'Derivatives of thiocyanic acid or of isothiocyanic acid',
  },
  {
    c: 'C07C 333',
    d: 'Derivatives of thiocarbamic acids, i.e. compounds containing any of the groups the nitrogen atom not being part of nitro or nitroso groups',
  },
  {
    c: 'C07C 335',
    d: 'Thioureas, i.e. compounds containing any of the groups the nitrogen atoms not being part of nitro or nitroso groups',
  },
  {
    c: 'C07C 337',
    d: 'Derivatives of thiocarbonic acids containing functional groups covered by groups C07C0333000000 or C07C0335000000 in which at least one nitrogen atom of these functional groups is further bound to another nitrogen atom not being part of a nitro or nitroso group',
  },
  {
    c: 'C07C 381',
    d: 'Compounds containing carbon and sulfur and having functional groups not covered by groups C07C0301000000-C07C0337000000',
  },
  {
    c: 'C07C 391',
    d: 'Compounds containing selenium',
  },
  {
    c: 'C07C 395',
    d: 'Compounds containing tellurium',
  },
  {
    c: 'C07C 401',
    d: 'Irradiation products of cholesterol or its derivatives; Vitamin D derivatives, 9,10-seco cyclopenta[a]phenanthrene or analogues obtained by chemical preparation without irradiation',
  },
  {
    c: 'C07C 403',
    d: 'Derivatives of cyclohexane or of a cyclohexene, having a side-chain containing an acyclic unsaturated part of at least four carbon atoms, this part being directly attached to the cyclohexane or cyclohexene rings, e.g. vitamin A, beta-carotene, beta-ionone',
  },
  {
    c: 'C07C 405',
    d: 'Compounds containing a five-membered ring having two side-chains in ortho position to each other, and having oxygen atoms directly attached to the ring in ortho position to one of the side-chains, one side-chain containing, not directly attached to the ring, a carbon atom having three bonds to hetero atoms with at the most one bond to halogen, and the other side-chain having oxygen atoms attached in gamma-position to the ring, e.g. prostaglandins',
  },
  {
    c: 'C07C 407',
    d: 'Preparation of peroxy compounds',
  },
  {
    c: 'C07C 409',
    d: 'Peroxy compounds',
  },
  {
    c: 'C07D',
    d: 'HETEROCYCLIC COMPOUNDS',
  },
  {
    c: 'C07D 201',
    d: 'Heterocyclic compounds having only nitrogen as ring hetero atom',
  },
  {
    c: 'C07D 203',
    d: 'Heterocyclic compounds containing three-membered rings with one nitrogen atom as the only ring hetero atom',
  },
  {
    c: 'C07D 205',
    d: 'Heterocyclic compounds containing four-membered rings with one nitrogen atom as the only ring hetero atom',
  },
  {
    c: 'C07D 207',
    d: 'Heterocyclic compounds containing five-membered rings not condensed with other rings, with one nitrogen atom as the only ring hetero atom',
  },
  {
    c: 'C07D 209',
    d: 'Heterocyclic compounds containing five-membered rings, condensed with other rings, with one nitrogen atom as the only ring hetero atom',
  },
  {
    c: 'C07D 211',
    d: 'Heterocyclic compounds containing hydrogenated pyridine rings, not condensed with other rings',
  },
  {
    c: 'C07D 213',
    d: 'Heterocyclic compounds containing six-membered rings, not condensed with other rings, with one nitrogen atom as the only ring hetero atom and three or more double bonds between ring members or between ring members and non-ring members',
  },
  {
    c: 'C07D 215',
    d: 'Heterocyclic compounds containing quinoline or hydrogenated quinoline ring systems',
  },
  {
    c: 'C07D 217',
    d: 'Heterocyclic compounds containing isoquinoline or hydrogenated isoquinoline ring systems',
  },
  {
    c: 'C07D 219',
    d: 'Heterocyclic compounds containing acridine or hydrogenated acridine ring systems',
  },
  {
    c: 'C07D 221',
    d: 'Heterocyclic compounds containing six-membered rings having one nitrogen atom as the only ring hetero atom, not provided for by groups C07D0211000000-C07D0219000000',
  },
  {
    c: 'C07D 223',
    d: 'Heterocyclic compounds containing seven-membered rings having one nitrogen atom as the only ring hetero atom',
  },
  {
    c: 'C07D 225',
    d: 'Heterocyclic compounds containing rings of more than seven members having one nitrogen atom as the only ring hetero atom',
  },
  {
    c: 'C07D 227',
    d: 'Heterocyclic compounds containing rings having one nitrogen atom as the only ring hetero atom, according to more than one of groups C07D0203000000-C07D0225000000',
  },
  {
    c: 'C07D 229',
    d: 'Heterocyclic compounds containing rings of less than five members having two nitrogen atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 231',
    d: 'Heterocyclic compounds containing 1,2-diazole or hydrogenated 1,2-diazole rings',
  },
  {
    c: 'C07D 233',
    d: 'Heterocyclic compounds containing 1,3-diazole or hydrogenated 1,3-diazole rings, not condensed with other rings',
  },
  {
    c: 'C07D 235',
    d: 'Heterocyclic compounds containing 1,3-diazole or hydrogenated 1,3-diazole rings, condensed with other rings',
  },
  {
    c: 'C07D 237',
    d: 'Heterocyclic compounds containing 1,2-diazine or hydrogenated 1,2-diazine rings',
  },
  {
    c: 'C07D 239',
    d: 'Heterocyclic compounds containing 1,3-diazine or hydrogenated 1,3-diazine rings',
  },
  {
    c: 'C07D 241',
    d: 'Heterocyclic compounds containing 1,4-diazine or hydrogenated 1,4-diazine rings',
  },
  {
    c: 'C07D 243',
    d: 'Heterocyclic compounds containing seven-membered rings having two nitrogen atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 245',
    d: 'Heterocyclic compounds containing rings of more than seven members having two nitrogen atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 247',
    d: 'Heterocyclic compounds containing rings having two nitrogen atoms as the only ring hetero atoms, according to more than one of groups C07D0229000000-C07D0245000000',
  },
  {
    c: 'C07D 249',
    d: 'Heterocyclic compounds containing five-membered rings having three nitrogen atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 251',
    d: 'Heterocyclic compounds containing 1,3,5-triazine rings',
  },
  {
    c: 'C07D 253',
    d: 'Heterocyclic compounds containing six-membered rings having three nitrogen atoms as the only ring hetero atoms, not provided for by group C07D0251000000',
  },
  {
    c: 'C07D 255',
    d: 'Heterocyclic compounds containing rings having three nitrogen atoms as the only ring hetero atoms, not provided for by groups C07D0249000000-C07D0253000000',
  },
  {
    c: 'C07D 257',
    d: 'Heterocyclic compounds containing rings having four nitrogen atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 259',
    d: 'Heterocyclic compounds containing rings having more than four nitrogen atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 261',
    d: 'Heterocyclic compounds having nitrogen and oxygen as the only ring hetero atoms',
  },
  {
    c: 'C07D 263',
    d: 'Heterocyclic compounds containing 1,3-oxazole or hydrogenated 1,3-oxazole rings',
  },
  {
    c: 'C07D 265',
    d: 'Heterocyclic compounds containing six-membered rings having one nitrogen atom and one oxygen atom as the only ring hetero atoms',
  },
  {
    c: 'C07D 267',
    d: 'Heterocyclic compounds containing rings of more than six members having one nitrogen atom and one oxygen atom as the only ring hetero atoms',
  },
  {
    c: 'C07D 269',
    d: 'Heterocyclic compounds containing rings having one nitrogen atom and one oxygen atom as the only ring hetero atoms according to more than one of groups C07D0261000000-C07D0267000000',
  },
  {
    c: 'C07D 271',
    d: 'Heterocyclic compounds containing five-membered rings having two nitrogen atoms and one oxygen atom as the only ring hetero atoms',
  },
  {
    c: 'C07D 273',
    d: 'Heterocyclic compounds containing rings having nitrogen and oxygen atoms as the only ring hetero atoms, not provided for by groups C07D0261000000-C07D0271000000',
  },
  {
    c: 'C07D 275',
    d: 'Heterocyclic compounds having nitrogen and sulfur as the only ring hetero atoms',
  },
  {
    c: 'C07D 277',
    d: 'Heterocyclic compounds containing 1,3-thiazole or hydrogenated 1,3-thiazole rings',
  },
  {
    c: 'C07D 279',
    d: 'Heterocyclic compounds containing six-membered rings having one nitrogen atom and one sulfur atom as the only ring hetero atoms',
  },
  {
    c: 'C07D 281',
    d: 'Heterocyclic compounds containing rings of more than six members having one nitrogen atom and one sulfur atom as the only ring hetero atoms',
  },
  {
    c: 'C07D 283',
    d: 'Heterocyclic compounds containing rings having one nitrogen atom and one sulfur atom as the only ring hetero atoms, according to more than one of groups C07D0275000000-C07D0281000000',
  },
  {
    c: 'C07D 285',
    d: 'Heterocyclic compounds containing rings having nitrogen and sulfur atoms as the only ring hetero atoms, not provided for by groups C07D0275000000-C07D0283000000',
  },
  {
    c: 'C07D 291',
    d: 'Heterocyclic compounds containing rings having nitrogen, oxygen and sulfur atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 293',
    d: 'Heterocyclic compounds containing rings having nitrogen and selenium or nitrogen and tellurium, with or without oxygen or sulfur atoms, as the ring hetero atoms',
  },
  {
    c: 'C07D 295',
    d: 'Heterocyclic compounds containing polymethylene-imine rings with at least five ring members, 3-azabicyclo [3.2.2] nonane, piperazine, morpholine or thiomorpholine rings, having only hydrogen atoms directly attached to the ring carbon atoms',
  },
  {
    c: 'C07D 301',
    d: 'Heterocyclic compounds having oxygen atoms, with or without sulfur, selenium, or tellurium atoms, as ring hetero atoms',
  },
  {
    c: 'C07D 303',
    d: 'Compounds containing three-membered rings having one oxygen atom as the only ring hetero atom',
  },
  {
    c: 'C07D 305',
    d: 'Heterocyclic compounds containing four-membered rings having one oxygen atom as the only ring hetero atoms',
  },
  {
    c: 'C07D 307',
    d: 'Heterocyclic compounds containing five-membered rings having one oxygen atom as the only ring hetero atom',
  },
  {
    c: 'C07D 309',
    d: 'Heterocyclic compounds containing six-membered rings having one oxygen atom as the only ring hetero atom, not condensed with other rings',
  },
  {
    c: 'C07D 311',
    d: 'Heterocyclic compounds containing six-membered rings having one oxygen atom as the only hetero atom, condensed with other rings',
  },
  {
    c: 'C07D 313',
    d: 'Heterocyclic compounds containing rings of more than six members having one oxygen atom as the only ring hetero atom',
  },
  {
    c: 'C07D 315',
    d: 'Heterocyclic compounds containing rings having one oxygen atom as the only ring hetero atom according to more than one of groups C07D0303000000-C07D0313000000',
  },
  {
    c: 'C07D 317',
    d: 'Heterocyclic compounds containing five-membered rings having two oxygen atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 319',
    d: 'Heterocyclic compounds containing six-membered rings having two oxygen atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 321',
    d: 'Heterocyclic compounds containing rings having two oxygen atoms as the only ring hetero atoms, not provided for by groups C07D0317000000-C07D0319000000',
  },
  {
    c: 'C07D 323',
    d: 'Heterocyclic compounds containing more than two oxygen atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 325',
    d: 'Heterocyclic compounds containing rings having oxygen as the only ring hetero atom according to more than one of groups C07D0303000000-C07D0323000000',
  },
  {
    c: 'C07D 327',
    d: 'Heterocyclic compounds containing rings having oxygen and sulfur atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 329',
    d: 'Heterocyclic compounds containing rings having oxygen and selenium or oxygen and tellurium atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 331',
    d: 'Heterocyclic compounds having sulfur, selenium, or tellurium atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 333',
    d: 'Heterocyclic compounds containing five-membered rings having one sulfur atom as the only ring hetero atom',
  },
  {
    c: 'C07D 335',
    d: 'Heterocyclic compounds containing six-membered rings having one sulfur atom as the only ring hetero atom',
  },
  {
    c: 'C07D 337',
    d: 'Heterocyclic compounds containing rings of more than six members having one sulfur atom as the only ring hetero atom',
  },
  {
    c: 'C07D 339',
    d: 'Heterocyclic compounds containing rings having two sulfur atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 341',
    d: 'Heterocyclic compounds containing rings having three or more sulfur atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 343',
    d: 'Heterocyclic compounds containing rings having sulfur and selenium or sulfur and tellurium atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 345',
    d: 'Heterocyclic compounds containing rings having selenium or tellurium atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 347',
    d: 'Heterocyclic compounds containing rings having halogen atoms as ring hetero atoms',
  },
  {
    c: 'C07D 401',
    d: 'Heterocyclic compounds containing two or more hetero rings',
  },
  {
    c: 'C07D 403',
    d: 'Heterocyclic compounds containing two or more hetero rings, having nitrogen atoms as the only ring hetero atoms, not provided for by group C07D0401000000',
  },
  {
    c: 'C07D 405',
    d: 'Heterocyclic compounds containing both one or more hetero rings having oxygen atoms as the only ring hetero atoms, and one or more rings having nitrogen as the only ring hetero atom',
  },
  {
    c: 'C07D 407',
    d: 'Heterocyclic compounds containing two or more hetero rings, at least one ring having oxygen atoms as the only ring hetero atoms, not provided for by group C07D0405000000',
  },
  {
    c: 'C07D 409',
    d: 'Heterocyclic compounds containing two or more hetero rings, at least one ring having sulfur atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 411',
    d: 'Heterocyclic compounds containing two or more hetero rings, at least one ring having oxygen and sulfur atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 413',
    d: 'Heterocyclic compounds containing two or more hetero rings, at least one ring having nitrogen and oxygen atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 415',
    d: 'Heterocyclic compounds containing the thiamine skeleton',
  },
  {
    c: 'C07D 417',
    d: 'Heterocyclic compounds containing two or more hetero rings, at least one ring having nitrogen and sulfur atoms as the only ring hetero atoms, not provided for by group C07D0415000000',
  },
  {
    c: 'C07D 419',
    d: 'Heterocyclic compounds containing two or more hetero rings, at least one ring having nitrogen, oxygen, and sulfur atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 421',
    d: 'Heterocyclic compounds containing two or more hetero rings, at least one ring having selenium, tellurium, or halogen atoms as ring hetero atoms',
  },
  {
    c: 'C07D 451',
    d: 'Heterocyclic compounds containing condensed hetero ring systems',
  },
  {
    c: 'C07D 453',
    d: 'Heterocyclic compounds containing quinuclidine or iso-quinuclidine ring systems, e.g. quinine alkaloids',
  },
  {
    c: 'C07D 455',
    d: 'Heterocyclic compounds containing quinolizine ring systems, e.g. emetine alkaloids, protoberberine; Alkylenedioxy derivatives of dibenzo [a, g] quinolizines, e.g. berberine',
  },
  {
    c: 'C07D 457',
    d: 'Heterocyclic compounds containing indolo [4, 3-f, g] quinoline ring systems, e.g. derivatives of ergoline, of the formula: , e.g. lysergic acid',
  },
  {
    c: 'C07D 459',
    d: 'Heterocyclic compounds containing benz [g] indolo [2, 3-a] quinolizine ring systems, e.g. yohimbine; 16, 18-lactones thereof, e.g. reserpic acid lactone',
  },
  {
    c: 'C07D 461',
    d: 'Heterocyclic compounds containing indolo [3, 2, 1-d, e] pyrido [3, 2, 1-i, j] [1, 5]-naphthyridine ring systems, e.g. vincamine',
  },
  {
    c: 'C07D 463',
    d: 'Heterocyclic compounds containing 1-azabicyclo [4.2.0] octane ring systems, i.e. compounds containing a ring system of the formula: , e.g. carbacephalosporins; Such ring systems being further condensed, e.g. 2,3-condensed with an oxygen-, nitrogen- or sulfur-containing hetero ring',
  },
  {
    c: 'C07D 471',
    d: 'Heterocyclic compounds containing nitrogen atoms as the only ring hetero atoms in the condensed system, at least one ring being a six-membered ring with one nitrogen atom, not provided for by groups C07D0451000000-C07D0463000000',
  },
  {
    c: 'C07D 473',
    d: 'Heterocyclic compounds containing purine ring systems',
  },
  {
    c: 'C07D 475',
    d: 'Heterocyclic compounds containing pteridine ring systems',
  },
  {
    c: 'C07D 477',
    d: 'Heterocyclic compounds containing 1-azabicyclo [3.2.0] heptane ring systems, i.e. compounds containing a ring system of the formula: , e.g. carbapenicillins, thienamycins; Such ring systems being further condensed, e.g. 2,3-condensed with an oxygen-, nitrogen- or sulfur-containing hetero ring',
  },
  {
    c: 'C07D 487',
    d: 'Heterocyclic compounds containing nitrogen atoms as the only ring hetero atoms in the condensed system, not provided for by groups C07D0451000000-C07D0477000000',
  },
  {
    c: 'C07D 489',
    d: 'Heterocyclic compounds containing 4aH-8, 9 c- Iminoethano-phenanthro [4, 5-b, c, d] furan ring systems, e.g. derivatives of [4, 5-epoxy]-morphinan of the formula:',
  },
  {
    c: 'C07D 491',
    d: 'Heterocyclic compounds containing in the condensed ring system both one or more rings having oxygen atoms as the only ring hetero atoms and one or more rings having nitrogen atoms as the only ring hetero atoms, not provided for by groups C07D0451000000-C07D0459000000, C07D0463000000, C07D0477000000 or C07D0489000000',
  },
  {
    c: 'C07D 493',
    d: 'Heterocyclic compounds containing oxygen atoms as the only ring hetero atoms in the condensed system',
  },
  {
    c: 'C07D 495',
    d: 'Heterocyclic compounds containing in the condensed system at least one hetero ring having sulfur atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 497',
    d: 'Heterocyclic compounds containing in the condensed system at least one hetero ring having oxygen and sulfur atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 498',
    d: 'Heterocyclic compounds containing in the condensed system at least one hetero ring having nitrogen and oxygen atoms as the only ring hetero atoms',
  },
  {
    c: 'C07D 499',
    d: 'Heterocyclic compounds containing 4-thia-1-azabicyclo [3.2.0] heptane ring systems, i.e. compounds containing a ring system of the formula: , e.g. penicillins, penems; Such ring systems being further condensed, e.g. 2,3-condensed with an oxygen-, nitrogen- or sulfur-containing hetero ring',
  },
  {
    c: 'C07D 501',
    d: 'Heterocyclic compounds containing 5-thia-1-azabicyclo [4.2.0] octane ring systems, i.e. compounds containing a ring system of the formula: , e.g. cephalosporins; Such ring systems being further condensed, e.g. 2,3-condensed with an oxygen-, nitrogen- or sulfur-containing hetero ring',
  },
  {
    c: 'C07D 503',
    d: 'Heterocyclic compounds containing 4-oxa-1-azabicyclo [3.2.0] heptane ring systems, i.e. compounds containing a ring system of the formula: , e.g. oxapenicillins, clavulanic acid derivatives; Such ring systems being further condensed, e.g. 2,3-condensed with an oxygen-, nitrogen- or sulfur-containing hetero ring',
  },
  {
    c: 'C07D 505',
    d: 'Heterocyclic compounds containing 5-oxa-1-azabicyclo [4.2.0] octane ring systems, i.e. compounds containing a ring system of the formula: , e.g. oxacephalosporins; Such ring systems being further condensed, e.g. 2,3-condensed with an oxygen-, nitrogen- or sulfur-containing hetero ring',
  },
  {
    c: 'C07D 507',
    d: 'Heterocyclic compounds containing a condensed beta-lactam ring system, not provided for by groups C07D0463000000, C07D0477000000 or C07D0499000000-C07D0505000000; Such ring systems being further condensed',
  },
  {
    c: 'C07D 513',
    d: 'Heterocyclic compounds containing in the condensed system at least one hetero ring having nitrogen and sulfur atoms as the only ring hetero atoms, not provided for in groups C07D0463000000, C07D0477000000 or C07D0499000000-C07D0507000000',
  },
  {
    c: 'C07D 515',
    d: 'Heterocyclic compounds containing in the condensed system at least one hetero ring having nitrogen, oxygen, and sulfur atoms as the only ring hetero atoms, not provided for in groups C07D0463000000, C07D0477000000 or C07D0499000000-C07D0507000000',
  },
  {
    c: 'C07D 517',
    d: 'Heterocyclic compounds containing in the condensed system at least one hetero ring having selenium, tellurium, or halogen atoms as ring hetero atoms',
  },
  {
    c: 'C07D 519',
    d: 'Heterocyclic compounds containing more than one system of two or more relevant hetero rings condensed among themselves or condensed with a common carbocyclic ring system not provided for in groups C07D0453000000 or C07D0455000000',
  },
  {
    c: 'C07D 521',
    d: 'Heterocyclic compounds containing unspecified hetero rings',
  },
  {
    c: 'C07F',
    d: 'ACYCLIC, CARBOCYCLIC, OR HETEROCYCLIC COMPOUNDS CONTAINING ELEMENTS OTHER THAN CARBON, HYDROGEN, HALOGEN, OXYGEN, NITROGEN, SULFUR, SELENIUM OR TELLURIUM',
  },
  {
    c: 'C07F 1',
    d: 'Compounds containing elements of Groups 1 or 11 of the Periodic System',
  },
  {
    c: 'C07F 3',
    d: 'Compounds containing elements of Groups 2 or 12 of the Periodic System',
  },
  {
    c: 'C07F 5',
    d: 'Compounds containing elements of Groups 3 or 13 of the Periodic System',
  },
  {
    c: 'C07F 7',
    d: 'Compounds containing elements of Groups 4 or 14 of the Periodic System',
  },
  {
    c: 'C07F 9',
    d: 'Compounds containing elements of Groups 5 or 15 of the Periodic System',
  },
  {
    c: 'C07F 11',
    d: 'Compounds containing elements of Groups 6 or 16 of the Periodic System',
  },
  {
    c: 'C07F 13',
    d: 'Compounds containing elements of Groups 7 or 17 of the Periodic System',
  },
  {
    c: 'C07F 15',
    d: 'Compounds containing elements of Groups 8, 9, 10 or 18 of the Periodic System',
  },
  {
    c: 'C07F 17',
    d: 'Metallocenes',
  },
  {
    c: 'C07F 19',
    d: 'Metal compounds according to more than one of main groups C07F0001000000-C07F0017000000',
  },
  {
    c: 'C07G',
    d: 'COMPOUNDS OF UNKNOWN CONSTITUTION',
  },
  {
    c: 'C07G 1',
    d: 'Low-molecular-weight derivatives of lignin',
  },
  {
    c: 'C07G 3',
    d: 'Glycosides',
  },
  {
    c: 'C07G 5',
    d: 'Alkaloids',
  },
  {
    c: 'C07G 9',
    d: 'Ammonium bituminosulfonate, e.g. Ichthyol',
  },
  {
    c: 'C07G 11',
    d: 'Antibiotics',
  },
  {
    c: 'C07G 13',
    d: 'Vitamins of unknown constitution',
  },
  {
    c: 'C07G 15',
    d: 'Hormones',
  },
  {
    c: 'C07G 99',
    d: 'n.a.',
  },
  {
    c: 'C07H',
    d: 'SUGARS; DERIVATIVES THEREOF; NUCLEOSIDES; NUCLEOTIDES; NUCLEIC ACIDS',
  },
  {
    c: 'C07H 1',
    d: 'Processes for the preparation of sugar derivatives',
  },
  {
    c: 'C07H 3',
    d: 'Compounds containing only hydrogen atoms and saccharide radicals having only carbon, hydrogen, and oxygen atoms',
  },
  {
    c: 'C07H 5',
    d: 'Compounds containing saccharide radicals in which the hetero bonds to oxygen have been replaced by the same number of hetero bonds to halogen, nitrogen, sulfur, selenium, or tellurium',
  },
  {
    c: 'C07H 7',
    d: 'Compounds containing non-saccharide radicals linked to saccharide radicals by a carbon-to-carbon bond',
  },
  {
    c: 'C07H 9',
    d: 'Compounds containing a hetero ring sharing at least two hetero atoms with a saccharide radical',
  },
  {
    c: 'C07H 11',
    d: 'Compounds containing saccharide radicals esterified by inorganic acids; Metal salts thereof',
  },
  {
    c: 'C07H 13',
    d: 'Compounds containing saccharide radicals esterified by carbonic acid or derivatives thereof, or by organic acids, e.g. phosphonic acids',
  },
  {
    c: 'C07H 15',
    d: 'Compounds containing hydrocarbon or substituted hydrocarbon radicals directly attached to hetero atoms of saccharide radicals',
  },
  {
    c: 'C07H 17',
    d: 'Compounds containing heterocyclic radicals directly attached to hetero atoms of saccharide radicals',
  },
  {
    c: 'C07H 19',
    d: 'Compounds containing a hetero ring sharing one ring hetero atom with a saccharide radical; Nucleosides; Mononucleotides; Anhydro derivatives thereof',
  },
  {
    c: 'C07H 21',
    d: 'Compounds containing two or more mononucleotide units having separate phosphate or polyphosphate groups linked by saccharide radicals of nucleoside groups, e.g. nucleic acids',
  },
  {
    c: 'C07H 23',
    d: 'Compounds containing boron, silicon, or a metal, e.g. chelates, vitamin B12',
  },
  {
    c: 'C07H 99',
    d: 'n.a.',
  },
  {
    c: 'C07J',
    d: 'STEROIDS',
  },
  {
    c: 'C07J 1',
    d: 'Normal steroids, i.e. cyclopenta[a]hydrophenanthrenes, containing carbon, hydrogen, halogen, or oxygen',
  },
  {
    c: 'C07J 3',
    d: 'Normal steroids containing carbon, hydrogen, halogen, or oxygen, substituted in position 17 beta by one carbon atom',
  },
  {
    c: 'C07J 5',
    d: 'Normal steroids containing carbon, hydrogen, halogen, or oxygen, substituted in position 17 beta by a chain of two carbon atoms, e.g. pregnane, and substituted in position 21 by only one singly bound oxygen atom',
  },
  {
    c: 'C07J 7',
    d: 'Normal steroids containing carbon, hydrogen, halogen, or oxygen, substituted in position 17 beta by a chain of two carbon atoms',
  },
  {
    c: 'C07J 9',
    d: 'Normal steroids containing carbon, hydrogen, halogen, or oxygen, substituted in position 17 beta by a chain of more than two carbon atoms, e.g. cholane, cholestane, coprostane',
  },
  {
    c: 'C07J 11',
    d: 'Normal steroids containing carbon, hydrogen, halogen, or oxygen, not substituted in position 3',
  },
  {
    c: 'C07J 13',
    d: 'Normal steroids containing carbon, hydrogen, halogen, or oxygen, having a carbon-to-carbon double bond from or to position 17',
  },
  {
    c: 'C07J 15',
    d: 'Stereochemically pure steroids containing carbon, hydrogen, halogen, or oxygen, having a partially or totally inverted skeleton, e.g. retrosteroids, L-isomers',
  },
  {
    c: 'C07J 17',
    d: 'Normal steroids containing carbon, hydrogen, halogen, or oxygen, having an oxygen-containing hetero ring not condensed with the cyclopenta[a]hydrophenanthrene skeleton',
  },
  {
    c: 'C07J 19',
    d: 'Normal steroids containing carbon, hydrogen, halogen, or oxygen, substituted in position 17 by a lactone ring',
  },
  {
    c: 'C07J 21',
    d: 'Normal steroids containing carbon, hydrogen, halogen, or oxygen, having an oxygen-containing hetero ring spiro-condensed with the cyclopenta[a]hydrophenanthrene skeleton',
  },
  {
    c: 'C07J 31',
    d: 'Normal steroids, i.e. cyclopenta[a]hydrophenanthrenes, containing sulfur',
  },
  {
    c: 'C07J 33',
    d: 'Normal steroids having a sulfur-containing hetero ring spiro-condensed or not condensed with the cyclopenta[a]hydrophenanthrene skeleton',
  },
  {
    c: 'C07J 41',
    d: 'Normal steroids, i.e. cyclopenta[a]hydrophenanthrenes, containing nitrogen',
  },
  {
    c: 'C07J 43',
    d: 'Normal steroids having a nitrogen-containing hetero ring spiro-condensed or not condensed with the cyclopenta[a]hydrophenanthrene skeleton',
  },
  {
    c: 'C07J 51',
    d: 'Normal steroids with unmodified cyclopenta[a]hydrophenanthrene skeleton not provided for in groups C07J0001000000-C07J0043000000',
  },
  {
    c: 'C07J 53',
    d: 'Steroids in which the cyclopenta[a]hydrophenanthrene skeleton has been modified by condensation with carbocyclic rings or by formation of an additional ring by means of a direct link between two ring carbon atoms',
  },
  {
    c: 'C07J 61',
    d: 'Nor- or homosteroids',
  },
  {
    c: 'C07J 63',
    d: 'Steroids in which the cyclopenta[a]hydrophenanthrene skeleton has been modified by expansion of only one ring by one or two atoms',
  },
  {
    c: 'C07J 65',
    d: 'Steroids in which the cyclopenta[a]hydrophenanthrene skeleton has been modified by contraction of two rings, each by one atom',
  },
  {
    c: 'C07J 67',
    d: 'Steroids in which the cyclopenta[a]hydrophenanthrene skeleton has been modified by expansion of two rings, each by one atom',
  },
  {
    c: 'C07J 69',
    d: 'Steroids in which the cyclopenta[a]hydrophenanthrene skeleton has been modified by contraction of only one ring by one atom and expansion of only one ring by one atom',
  },
  {
    c: 'C07J 71',
    d: 'Steroids in which the cyclopenta[a]hydrophenanthrene skeleton is condensed with a heterocyclic ring',
  },
  {
    c: 'C07J 73',
    d: 'Steroids in which the cyclopenta[a]hydrophenanthrene skeleton has been modified by substitution of one or two carbon atoms by hetero atoms',
  },
  {
    c: 'C07J 75',
    d: 'Processes for the preparation of steroids, in general',
  },
  {
    c: 'C07K',
    d: 'PEPTIDES',
  },
  {
    c: 'C07K 1',
    d: 'General processes for the preparation of peptides',
  },
  {
    c: 'C07K 2',
    d: 'Peptides of undefined number of amino acids; Derivatives thereof',
  },
  {
    c: 'C07K 4',
    d: 'Peptides having up to 20 amino acids in an undefined or only partially defined sequence; Derivatives thereof',
  },
  {
    c: 'C07K 5',
    d: 'Peptides having up to four amino acids in a fully defined sequence; Derivatives thereof',
  },
  {
    c: 'C07K 7',
    d: 'Peptides having 5 to 20 amino acids in a fully defined sequence; Derivatives thereof',
  },
  {
    c: 'C07K 9',
    d: 'Peptides having up to 20 amino acids, containing saccharide radicals and having a fully defined sequence; Derivatives thereof',
  },
  {
    c: 'C07K 11',
    d: 'Depsipeptides having up to 20 amino acids in a fully defined sequence; Derivatives thereof',
  },
  {
    c: 'C07K 14',
    d: 'Peptides having more than 20 amino acids; Gastrins; Somatostatins; Melanotropins; Derivatives thereof',
  },
  {
    c: 'C07K 16',
    d: 'Immunoglobulins, e.g. monoclonal or polyclonal antibodies',
  },
  {
    c: 'C07K 17',
    d: 'Carrier-bound or immobilised peptides; Preparation thereof',
  },
  {
    c: 'C07K 19',
    d: 'Hybrid peptides',
  },
  {
    c: 'C08',
    d: 'ORGANIC MACROMOLECULAR COMPOUNDS; THEIR PREPARATION OR CHEMICAL WORKING-UP; COMPOSITIONS BASED THEREON',
  },
  {
    c: 'C08B',
    d: 'POLYSACCHARIDES; DERIVATIVES THEREOF',
  },
  {
    c: 'C08B 1',
    d: 'Preparation',
  },
  {
    c: 'C08B 3',
    d: 'Preparation of cellulose esters of organic acids',
  },
  {
    c: 'C08B 5',
    d: 'Preparation of cellulose esters of inorganic acids',
  },
  {
    c: 'C08B 7',
    d: 'Preparation of cellulose esters of both organic and inorganic acids',
  },
  {
    c: 'C08B 9',
    d: 'Preparation of cellulose xanthate or viscose',
  },
  {
    c: 'C08B 11',
    d: 'Preparation of cellulose ethers',
  },
  {
    c: 'C08B 13',
    d: 'Preparation of cellulose ether-esters',
  },
  {
    c: 'C08B 15',
    d: 'Preparation of other cellulose derivatives or modified cellulose',
  },
  {
    c: 'C08B 16',
    d: 'Regeneration of cellulose',
  },
  {
    c: 'C08B 17',
    d: 'Apparatus for esterification or etherification of cellulose',
  },
  {
    c: 'C08B 30',
    d: 'Preparation of starch, degraded or non-chemically modified starch, amylose, or amylopectin',
  },
  {
    c: 'C08B 31',
    d: 'Preparation of chemical derivatives of starch',
  },
  {
    c: 'C08B 33',
    d: 'Preparation of chemical derivatives of amylose',
  },
  {
    c: 'C08B 35',
    d: 'Preparation of chemical derivatives of amylopectin',
  },
  {
    c: 'C08B 37',
    d: 'Preparation of polysaccharides not provided for in groups C08B0001000000-C08B0035000000; Derivatives thereof',
  },
  {
    c: 'C08C',
    d: 'TREATMENT OR CHEMICAL MODIFICATION OF RUBBERS',
  },
  {
    c: 'C08C 1',
    d: 'Preparation',
  },
  {
    c: 'C08C 2',
    d: 'Treatment of rubber solutions',
  },
  {
    c: 'C08C 3',
    d: 'Treatment of coagulated rubber',
  },
  {
    c: 'C08C 4',
    d: 'Treatment of rubber before vulcanisation, not provided for in groups C08C0001000000-C08C0003020000',
  },
  {
    c: 'C08C 19',
    d: 'Chemical modification of rubber',
  },
  {
    c: 'C08F',
    d: 'MACROMOLECULAR COMPOUNDS OBTAINED BY REACTIONS ONLY INVOLVING CARBON-TO-CARBON UNSATURATED BONDS',
  },
  {
    c: 'C08F 2',
    d: 'Processes; Catalysts',
  },
  {
    c: 'C08F 4',
    d: 'Polymerisation catalysts',
  },
  {
    c: 'C08F 6',
    d: 'Post-polymerisation treatments',
  },
  {
    c: 'C08F 8',
    d: 'Chemical modification by after-treatment',
  },
  {
    c: 'C08F 10',
    d: 'Homopolymers or copolymers',
  },
  {
    c: 'C08F 12',
    d: 'Homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an aromatic carbocyclic ring',
  },
  {
    c: 'C08F 14',
    d: 'Homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a halogen',
  },
  {
    c: 'C08F 16',
    d: 'Homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an alcohol, ether, aldehydo, ketonic, acetal, or ketal radical',
  },
  {
    c: 'C08F 18',
    d: 'Homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an acyloxy radical of a saturated carboxylic acid, of carbonic acid, or of a haloformic acid',
  },
  {
    c: 'C08F 20',
    d: 'Homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and only one being terminated by only one carboxyl radical or a salt, anhydride, ester, amide, imide, or nitrile thereof',
  },
  {
    c: 'C08F 22',
    d: 'Homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a carboxyl radical and containing at least one other carboxyl radical in the molecule; Salts, anhydrides, esters, amides, imides, or nitriles thereof',
  },
  {
    c: 'C08F 24',
    d: 'Homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a heterocyclic ring containing oxygen',
  },
  {
    c: 'C08F 26',
    d: 'Homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a single or double bond to nitrogen or by a heterocyclic ring containing nitrogen',
  },
  {
    c: 'C08F 28',
    d: 'Homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a bond to sulfur or by a heterocyclic ring containing sulfur',
  },
  {
    c: 'C08F 30',
    d: 'Homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and containing phosphorus, selenium, tellurium or a metal',
  },
  {
    c: 'C08F 32',
    d: 'Homopolymers or copolymers of cyclic compounds having no unsaturated aliphatic radicals in a side chain, and having one or more carbon-to-carbon double bonds in a carbocyclic ring system',
  },
  {
    c: 'C08F 34',
    d: 'Homopolymers or copolymers of cyclic compounds having no unsaturated aliphatic radicals in a side chain and having one or more carbon-to-carbon double bonds in a heterocyclic ring',
  },
  {
    c: 'C08F 36',
    d: 'Homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, at least one having two or more carbon-to-carbon double bonds',
  },
  {
    c: 'C08F 38',
    d: 'Homopolymers or copolymers of compounds having one or more carbon-to-carbon triple bonds',
  },
  {
    c: 'C08F 110',
    d: 'Homopolymers',
  },
  {
    c: 'C08F 112',
    d: 'Homopolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an aromatic carbocyclic ring',
  },
  {
    c: 'C08F 114',
    d: 'Homopolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a halogen',
  },
  {
    c: 'C08F 116',
    d: 'Homopolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an alcohol, ether, aldehydo, ketonic, acetal, or ketal radical',
  },
  {
    c: 'C08F 118',
    d: 'Homopolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an acyloxy radical of a saturated carboxylic acid, of carbonic acid, or of a haloformic acid',
  },
  {
    c: 'C08F 120',
    d: 'Homopolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and only one being terminated by only one carboxyl radical or a salt, anhydride, ester, amide, imide, or nitrile thereof',
  },
  {
    c: 'C08F 122',
    d: 'Homopolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a carboxyl radical and containing at least one other carboxyl radical in the molecule; Salts, anhydrides, esters, amides, imides, or nitriles thereof',
  },
  {
    c: 'C08F 124',
    d: 'Homopolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a heterocyclic ring containing oxygen',
  },
  {
    c: 'C08F 126',
    d: 'Homopolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a single or double bond to nitrogen or by a heterocyclic ring containing nitrogen',
  },
  {
    c: 'C08F 128',
    d: 'Homopolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon bond, and at least one being terminated by a bond to sulfur or by a heterocyclic ring containing sulfur',
  },
  {
    c: 'C08F 130',
    d: 'Homopolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and containing phosphorus, selenium, tellurium, or a metal',
  },
  {
    c: 'C08F 132',
    d: 'Homopolymers of cyclic compounds containing no unsaturated aliphatic radicals in a side chain, and having one or more carbon-to-carbon double bonds in a carbocyclic ring system',
  },
  {
    c: 'C08F 134',
    d: 'Homopolymers of cyclic compounds having no unsaturated aliphatic radicals in a side chain and having one or more carbon-to-carbon double bonds in a heterocyclic ring',
  },
  {
    c: 'C08F 136',
    d: 'Homopolymers of compounds having one or more unsaturated aliphatic radicals, at least one having two or more carbon-to-carbon double bonds',
  },
  {
    c: 'C08F 138',
    d: 'Homopolymers of compounds having one or more carbon-to-carbon triple bonds',
  },
  {
    c: 'C08F 210',
    d: 'Copolymers',
  },
  {
    c: 'C08F 212',
    d: 'Copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an aromatic carbocyclic ring',
  },
  {
    c: 'C08F 214',
    d: 'Copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a halogen',
  },
  {
    c: 'C08F 216',
    d: 'Copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an alcohol, ether, aldehydo, ketonic, acetal, or ketal radical',
  },
  {
    c: 'C08F 218',
    d: 'Copolymers having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an acyloxy radical of a saturated carboxylic acid, of carbonic acid, or of a haloformic acid',
  },
  {
    c: 'C08F 220',
    d: 'Copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and only one being terminated by only one carboxyl radical or a salt, anhydride, ester, amide, imide, or nitrile thereof',
  },
  {
    c: 'C08F 222',
    d: 'Copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a carboxyl radical and containing at least one other carboxyl radical in the molecule; Salts, anhydrides, esters, amides, imides, or nitriles thereof',
  },
  {
    c: 'C08F 224',
    d: 'Copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a heterocyclic ring containing oxygen',
  },
  {
    c: 'C08F 226',
    d: 'Copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a single or double bond to nitrogen or by a heterocyclic ring containing nitrogen',
  },
  {
    c: 'C08F 228',
    d: 'Copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a bond to sulfur or by a heterocyclic ring containing sulfur',
  },
  {
    c: 'C08F 230',
    d: 'Copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and containing phosphorus, selenium, tellurium, or a metal',
  },
  {
    c: 'C08F 232',
    d: 'Copolymers of cyclic compounds containing no unsaturated aliphatic radicals in a side chain, and having one or more carbon-to-carbon double bonds in a carbocyclic ring system',
  },
  {
    c: 'C08F 234',
    d: 'Copolymers of cyclic compounds having no unsaturated aliphatic radicals in a side chain and having one or more carbon-to-carbon double bonds in a heterocyclic ring',
  },
  {
    c: 'C08F 236',
    d: 'Copolymers of compounds having one or more unsaturated aliphatic radicals, at least one having two or more carbon-to-carbon double bonds',
  },
  {
    c: 'C08F 238',
    d: 'Copolymers of compounds having one or more carbon-to-carbon triple bonds',
  },
  {
    c: 'C08F 240',
    d: 'Copolymers of hydrocarbons and mineral oils, e.g. petroleum resins',
  },
  {
    c: 'C08F 242',
    d: 'Copolymers of drying-oils with other monomers',
  },
  {
    c: 'C08F 244',
    d: 'Coumarone-indene copolymers',
  },
  {
    c: 'C08F 246',
    d: 'Copolymers in which the nature of only the monomers in minority is defined',
  },
  {
    c: 'C08F 251',
    d: 'Graft polymers; Polymers crosslinked with unsaturated monomers',
  },
  {
    c: 'C08F 253',
    d: 'Macromolecular compounds obtained by polymerising monomers on to natural rubbers or derivatives thereof',
  },
  {
    c: 'C08F 255',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of hydrocarbons as defined in group C08F0010000000',
  },
  {
    c: 'C08F 257',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of aromatic monomers as defined in group C08F0012000000',
  },
  {
    c: 'C08F 259',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of halogen containing monomers as defined in group C08F0014000000',
  },
  {
    c: 'C08F 261',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of oxygen-containing monomers as defined in group C08F0016000000',
  },
  {
    c: 'C08F 263',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of esters of unsaturated alcohols with saturated acids as defined in group C08F0018000000',
  },
  {
    c: 'C08F 265',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of unsaturated monocarboxylic acids or derivatives thereof as defined in group C08F0020000000',
  },
  {
    c: 'C08F 267',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of unsaturated polycarboxylic acids or derivatives thereof as defined in group C08F0022000000',
  },
  {
    c: 'C08F 269',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of heterocyclic oxygen-containing monomers as defined in group C08F0024000000',
  },
  {
    c: 'C08F 271',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of nitrogen-containing monomers as defined in group C08F0026000000',
  },
  {
    c: 'C08F 273',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of sulfur-containing monomers as defined in group C08F0028000000',
  },
  {
    c: 'C08F 275',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of monomers containing phosphorus, selenium, tellurium, or a metal as defined in group C08F0030000000',
  },
  {
    c: 'C08F 277',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of carbocyclic or heterocyclic monomers as defined respectively in group C08F0032000000 or in group C08F0034000000',
  },
  {
    c: 'C08F 279',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of monomers having two or more carbon-to-carbon double bonds as defined in group C08F0036000000',
  },
  {
    c: 'C08F 281',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers of monomers having carbon-to-carbon triple bonds as defined in group C08F0038000000',
  },
  {
    c: 'C08F 283',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers provided for in subclass C08G',
  },
  {
    c: 'C08F 285',
    d: 'Macromolecular compounds obtained by polymerising monomers on to preformed graft polymers',
  },
  {
    c: 'C08F 287',
    d: 'Macromolecular compounds obtained by polymerising monomers on to block polymers',
  },
  {
    c: 'C08F 289',
    d: 'Macromolecular compounds obtained by polymerising monomers on to macromolecular compounds not provided for in groups C08F0251000000-C08F0287000000',
  },
  {
    c: 'C08F 290',
    d: 'Macromolecular compounds obtained by polymerising monomers on to polymers modified by introduction of aliphatic unsaturated end or side groups',
  },
  {
    c: 'C08F 291',
    d: 'Macromolecular compounds obtained by polymerising monomers on to macromolecular compounds according to more than one of the groups C08F0251000000-C08F0289000000',
  },
  {
    c: 'C08F 292',
    d: 'Macromolecular compounds obtained by polymerising monomers on to inorganic materials',
  },
  {
    c: 'C08F 293',
    d: 'Block polymers',
  },
  {
    c: 'C08F 295',
    d: 'Macromolecular compounds obtained by polymerisation using successively different catalyst types without deactivating the intermediate polymer',
  },
  {
    c: 'C08F 297',
    d: 'Macromolecular compounds obtained by successively polymerising different monomer systems using a catalyst of the ionic or coordination type without deactivating the intermediate polymer',
  },
  {
    c: 'C08F 299',
    d: 'Macromolecular compounds obtained by interreacting polymers involving only carbon-to-carbon unsaturated bond reactions, in the absence of non-macromolecular monomers',
  },
  {
    c: 'C08F 301',
    d: 'Macromolecular compounds not provided for in groups C08F0010000000-C08F0299000000',
  },
  {
    c: 'C08G',
    d: 'MACROMOLECULAR COMPOUNDS OBTAINED OTHERWISE THAN BY REACTIONS ONLY INVOLVING CARBON-TO-CARBON UNSATURATED BONDS',
  },
  {
    c: 'C08G 2',
    d: 'Addition polymers of aldehydes or cyclic oligomers thereof or of ketones; Addition copolymers thereof with less than 50 molar percent of other substances',
  },
  {
    c: 'C08G 4',
    d: 'Condensation polymers of aldehydes or ketones with polyalcohols; Addition polymers of heterocyclic oxygen compounds containing in the ring at least once the grouping —O—C—O—',
  },
  {
    c: 'C08G 6',
    d: 'Condensation polymers of aldehydes or ketones only',
  },
  {
    c: 'C08G 8',
    d: 'Condensation polymers of aldehydes or ketones with phenols only',
  },
  {
    c: 'C08G 10',
    d: 'Condensation polymers of aldehydes or ketones with aromatic hydrocarbons or halogenated aromatic hydrocarbons only',
  },
  {
    c: 'C08G 12',
    d: 'Condensation polymers of aldehydes or ketones with only compounds containing hydrogen attached to nitrogen',
  },
  {
    c: 'C08G 14',
    d: 'Condensation polymers of aldehydes or ketones with two or more other monomers covered by at least two of the groups C08G0008000000-C08G0012000000',
  },
  {
    c: 'C08G 16',
    d: 'Condensation polymers of aldehydes or ketones with monomers not provided for in the groups C08G0004000000-C08G0014000000',
  },
  {
    c: 'C08G 18',
    d: 'Polymeric products of isocyanates or isothiocyanates',
  },
  {
    c: 'C08G 59',
    d: 'Polycondensates containing more than one epoxy group per molecule; Macromolecules obtained by reaction of epoxy polycondensates with monofunctional low-molecular-weight compounds; Macromolecules obtained by polymerising compounds containing more than one epoxy group per molecule using curing agents or catalysts which react with the epoxy groups',
  },
  {
    c: 'C08G 61',
    d: 'Macromolecular compounds obtained by reactions forming a carbon-to-carbon link in the main chain of the macromolecule',
  },
  {
    c: 'C08G 63',
    d: 'Macromolecular compounds obtained by reactions forming a carboxylic ester link in the main chain of the macromolecule',
  },
  {
    c: 'C08G 64',
    d: 'Macromolecular compounds obtained by reactions forming a carbonic ester link in the main chain of the macromolecule',
  },
  {
    c: 'C08G 65',
    d: 'Macromolecular compounds obtained by reactions forming an ether link in the main chain of the macromolecule',
  },
  {
    c: 'C08G 67',
    d: 'Macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing oxygen or oxygen and carbon, not provided for in groups C08G0002000000-C08G0065000000',
  },
  {
    c: 'C08G 69',
    d: 'Macromolecular compounds obtained by reactions forming a carboxylic amide link in the main chain of the macromolecule',
  },
  {
    c: 'C08G 71',
    d: 'Macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a ureide or urethane link, otherwise than from isocyanate radicals',
  },
  {
    c: 'C08G 73',
    d: 'Macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing nitrogen, with or without oxygen or carbon, not provided for in groups C08G0012000000-C08G0071000000',
  },
  {
    c: 'C08G 75',
    d: 'Macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing sulfur, with or without nitrogen, oxygen, or carbon',
  },
  {
    c: 'C08G 77',
    d: 'Macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing silicon, with or without sulfur, nitrogen, oxygen, or carbon',
  },
  {
    c: 'C08G 79',
    d: 'Macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing atoms other than silicon, sulfur, nitrogen, oxygen, and carbon',
  },
  {
    c: 'C08G 81',
    d: 'Macromolecular compounds obtained by interreacting polymers in the absence of monomers, e.g. block polymers',
  },
  {
    c: 'C08G 83',
    d: 'Macromolecular compounds not provided for in groups C08G0002000000-C08G0081000000',
  },
  {
    c: 'C08G 85',
    d: 'General processes for preparing compounds provided for in this subclass',
  },
  {
    c: 'C08G 101',
    d: 'Indexing scheme associated with group C08G0018000000, relating to cellular products.',
  },
  {
    c: 'C08H',
    d: 'DERIVATIVES OF NATURAL MACROMOLECULAR COMPOUNDS',
  },
  {
    c: 'C08H 1',
    d: 'Macromolecular products derived from proteins',
  },
  {
    c: 'C08H 3',
    d: 'Vulcanised oils, e.g. factice',
  },
  {
    c: 'C08H 7',
    d: 'Lignin; Modified lignin; High-molecular-weight products derived therefrom',
  },
  {
    c: 'C08H 8',
    d: 'Macromolecular compounds derived from lignocellulosic materials',
  },
  {
    c: 'C08H 99',
    d: 'n.a.',
  },
  {
    c: 'C08J',
    d: 'WORKING-UP; GENERAL PROCESSES OF COMPOUNDING; AFTER-TREATMENT NOT COVERED BY SUBCLASSES C08B, C08C, C08F, C08G or C08H',
  },
  {
    c: 'C08J 3',
    d: 'Processes of treating or compounding macromolecular substances',
  },
  {
    c: 'C08J 5',
    d: 'Manufacture of articles or shaped materials containing macromolecular substances',
  },
  {
    c: 'C08J 7',
    d: 'Chemical treatment or coating of shaped articles made of macromolecular substances',
  },
  {
    c: 'C08J 9',
    d: 'Working-up of macromolecular substances to porous or cellular articles or materials; After-treatment thereof',
  },
  {
    c: 'C08J 11',
    d: 'Recovery or working-up of waste materials',
  },
  {
    c: 'C08J 99',
    d: 'n.a.',
  },
  {
    c: 'C08K',
    d: 'USE OF INORGANIC OR NON-MACROMOLECULAR ORGANIC SUBSTANCES AS COMPOUNDING INGREDIENTS',
  },
  {
    c: 'C08K 3',
    d: 'Use of inorganic substances as compounding ingredients',
  },
  {
    c: 'C08K 5',
    d: 'Use of organic ingredients',
  },
  {
    c: 'C08K 7',
    d: 'Use of ingredients characterised by shape',
  },
  {
    c: 'C08K 9',
    d: 'Use of pretreated ingredients',
  },
  {
    c: 'C08K 11',
    d: 'Use of ingredients of unknown constitution, e.g. undefined reaction products',
  },
  {
    c: 'C08K 13',
    d: 'Use of mixtures of ingredients not covered by any single one of main groups C08K0003000000-C08K0011000000, each of these compounds being essential',
  },
  {
    c: 'C08L',
    d: 'COMPOSITIONS OF MACROMOLECULAR COMPOUNDS',
  },
  {
    c: 'C08L 1',
    d: 'Compositions of polysaccharides or of their derivatives',
  },
  {
    c: 'C08L 3',
    d: 'Compositions of starch, amylose or amylopectin or of their derivatives or degradation products',
  },
  {
    c: 'C08L 5',
    d: 'Compositions of polysaccharides or of their derivatives not provided for in group C08L0001000000 or C08L0003000000',
  },
  {
    c: 'C08L 7',
    d: 'Compositions of rubbers or of their derivatives',
  },
  {
    c: 'C08L 9',
    d: 'Compositions of homopolymers or copolymers of conjugated diene hydrocarbons',
  },
  {
    c: 'C08L 11',
    d: 'Compositions of homopolymers or copolymers of chloroprene',
  },
  {
    c: 'C08L 13',
    d: 'Compositions of rubbers containing carboxyl groups',
  },
  {
    c: 'C08L 15',
    d: 'Compositions of rubber derivatives',
  },
  {
    c: 'C08L 17',
    d: 'Compositions of reclaimed rubber',
  },
  {
    c: 'C08L 19',
    d: 'Compositions of rubbers not provided for in groups C08L0007000000-C08L0017000000',
  },
  {
    c: 'C08L 21',
    d: 'Compositions of unspecified rubbers',
  },
  {
    c: 'C08L 23',
    d: 'Compositions of macromolecular compounds obtained by reactions involving only carbon-to-carbon unsaturated bonds',
  },
  {
    c: 'C08L 25',
    d: 'Compositions of homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an aromatic carbocyclic ring; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 27',
    d: 'Compositions of homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a halogen; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 29',
    d: 'Compositions of homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an alcohol, ether, aldehydo, ketonic, acetal, or ketal radical; Compositions of hydrolysed polymers of esters of unsaturated alcohols with saturated carboxylic acids; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 31',
    d: 'Compositions of homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an acyloxy radical of a saturated carboxylic acid, of carbonic acid, or of a haloformic acid',
  },
  {
    c: 'C08L 33',
    d: 'Compositions of homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and only one being terminated by only one carboxyl radical, or of salts, anhydrides, esters, amides, imides, or nitriles thereof; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 35',
    d: 'Compositions of homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a carboxyl radical, and containing at least one other carboxyl radical in the molecule, or of salts, anhydrides, esters, amides, imides or nitriles thereof; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 37',
    d: 'Compositions of homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a heterocyclic ring containing oxygen',
  },
  {
    c: 'C08L 39',
    d: 'Compositions of homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a single or double bond to nitrogen or by a heterocyclic ring containing nitrogen; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 41',
    d: 'Compositions of homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a bond to sulfur or by a heterocyclic ring containing sulfur; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 43',
    d: 'Compositions of homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and containing boron, silicon, phosphorus, selenium, tellurium, or a metal; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 45',
    d: 'Compositions of homopolymers or copolymers of compounds having no unsaturated aliphatic radicals in a side chain, and having one or more carbon-to-carbon double bonds in a carbocyclic or in a heterocyclic ring system; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 47',
    d: 'Compositions of homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, at least one having two or more carbon-to-carbon double bonds; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 49',
    d: 'Compositions of homopolymers or copolymers of compounds having one or more carbon-to-carbon triple bonds; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 51',
    d: 'Compositions of graft polymers in which the grafted component is obtained by reactions only involving carbon-to-carbon unsaturated bonds',
  },
  {
    c: 'C08L 53',
    d: 'Compositions of block copolymers containing at least one sequence of a polymer obtained by reactions only involving carbon-to-carbon unsaturated bonds; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 55',
    d: 'Compositions of homopolymers or copolymers, obtained by polymerisation reactions only involving carbon-to-carbon unsaturated bonds, not provided for in groups C08L0023000000-C08L0053000000',
  },
  {
    c: 'C08L 57',
    d: 'Compositions of unspecified polymers obtained by reactions only involving carbon-to-carbon unsaturated bonds',
  },
  {
    c: 'C08L 59',
    d: 'Compositions of macromolecular compounds obtained otherwise than by reactions only involving carbon-to-carbon unsaturated bonds',
  },
  {
    c: 'C08L 61',
    d: 'Compositions of condensation polymers of aldehydes or ketones',
  },
  {
    c: 'C08L 63',
    d: 'Compositions of epoxy resins; Compositions of derivatives of epoxy resins',
  },
  {
    c: 'C08L 65',
    d: 'Compositions of macromolecular compounds obtained by reactions forming a carbon-to-carbon link in the main chain',
  },
  {
    c: 'C08L 67',
    d: 'Compositions of polyesters obtained by reactions forming a carboxylic ester link in the main chain',
  },
  {
    c: 'C08L 69',
    d: 'Compositions of polycarbonates; Compositions of derivatives of polycarbonates',
  },
  {
    c: 'C08L 71',
    d: 'Compositions of polyethers obtained by reactions forming an ether link in the main chain',
  },
  {
    c: 'C08L 73',
    d: 'Compositions of macromolecular compounds obtained by reactions forming a linkage containing oxygen or oxygen and carbon in the main chain, not provided for in groups C08L0059000000-C08L0071000000; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 75',
    d: 'Compositions of polyureas or polyurethanes; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 77',
    d: 'Compositions of polyamides obtained by reactions forming a carboxylic amide link in the main chain',
  },
  {
    c: 'C08L 79',
    d: 'Compositions of macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing nitrogen with or without oxygen, or carbon only, not provided for in groups C08L0061000000-C08L0077000000',
  },
  {
    c: 'C08L 81',
    d: 'Compositions of macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing sulfur with or without nitrogen, oxygen, or carbon only; Compositions of polysulfones; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 83',
    d: 'Compositions of macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing silicon with or without sulfur, nitrogen, oxygen, or carbon only; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 85',
    d: 'Compositions of macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing atoms other than silicon, sulfur, nitrogen, oxygen, and carbon; Compositions of derivatives of such polymers',
  },
  {
    c: 'C08L 87',
    d: 'Compositions of unspecified macromolecular compounds, obtained otherwise than by polymerisation reactions only involving unsaturated carbon-to-carbon bonds',
  },
  {
    c: 'C08L 89',
    d: 'Compositions of natural macromolecular compounds or of derivatives thereof',
  },
  {
    c: 'C08L 91',
    d: 'Compositions of oils, fats or waxes; Compositions of derivatives thereof',
  },
  {
    c: 'C08L 93',
    d: 'Compositions of natural resins; Compositions of derivatives thereof',
  },
  {
    c: 'C08L 95',
    d: 'Compositions of bituminous materials, e.g. asphalt, tar or pitch',
  },
  {
    c: 'C08L 97',
    d: 'Compositions of lignin-containing materials',
  },
  {
    c: 'C08L 99',
    d: 'Compositions of natural macromolecular compounds or of derivatives thereof not provided for in groups C08L0001000000-C08L0007000000 or C08L0089000000-C08L0097000000',
  },
  {
    c: 'C08L 101',
    d: 'Compositions of unspecified macromolecular compounds',
  },
  {
    c: 'C09',
    d: 'DYES; PAINTS; POLISHES; NATURAL RESINS; ADHESIVES; COMPOSITIONS NOT OTHERWISE PROVIDED FOR; APPLICATIONS OF MATERIALS NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'C09B',
    d: 'ORGANIC DYES OR CLOSELY-RELATED COMPOUNDS FOR PRODUCING DYES; MORDANTS; LAKES',
  },
  {
    c: 'C09B 1',
    d: 'Anthracene dyes',
  },
  {
    c: 'C09B 3',
    d: 'Dyes with anthracene nucleus condensed with one or more carbocyclic rings',
  },
  {
    c: 'C09B 5',
    d: 'Dyes with an anthracene nucleus condensed with one or more heterocyclic rings with or without carbocyclic rings',
  },
  {
    c: 'C09B 6',
    d: 'Anthracene dyes not provided for above',
  },
  {
    c: 'C09B 7',
    d: 'Indigoid dyes',
  },
  {
    c: 'C09B 9',
    d: 'Esters or ester-salts of leuco compounds of vat dyestuffs',
  },
  {
    c: 'C09B 11',
    d: 'Diaryl- or triarylmethane dyes',
  },
  {
    c: 'C09B 13',
    d: 'Oxyketone dyes',
  },
  {
    c: 'C09B 15',
    d: 'Acridine, azine, oxazine, or thiazine dyes',
  },
  {
    c: 'C09B 17',
    d: 'Azine dyes',
  },
  {
    c: 'C09B 19',
    d: 'Oxazine dyes',
  },
  {
    c: 'C09B 21',
    d: 'Thiazine dyes',
  },
  {
    c: 'C09B 23',
    d: 'Quinoline or polymethine dyes',
  },
  {
    c: 'C09B 25',
    d: 'Quinophthalones',
  },
  {
    c: 'C09B 26',
    d: 'Hydrazone dyes; Triazene dyes',
  },
  {
    c: 'C09B 27',
    d: 'Azo dyes',
  },
  {
    c: 'C09B 29',
    d: 'Monoazo dyes prepared by diazotising and coupling',
  },
  {
    c: 'C09B 31',
    d: 'Disazo or polyazo dyes of the type A → B → C, A → B → C → D, or the like, prepared by diazotising and coupling',
  },
  {
    c: 'C09B 33',
    d: 'Disazo or polyazo dyes of the types A → K ← B, A → B → K ← C, or the like, prepared by diazotising and coupling',
  },
  {
    c: 'C09B 35',
    d: 'Disazo or polyazo dyes of the type A ← D → B prepared by diazotising and coupling',
  },
  {
    c: 'C09B 37',
    d: 'Azo dyes prepared by coupling the diazotised amine with itself',
  },
  {
    c: 'C09B 39',
    d: 'Other azo dyes prepared by diazotising and coupling',
  },
  {
    c: 'C09B 41',
    d: 'Special methods of performing the coupling reaction',
  },
  {
    c: 'C09B 43',
    d: 'Preparation of azo dyes from other azo compounds',
  },
  {
    c: 'C09B 44',
    d: 'Azo dyes containing onium groups',
  },
  {
    c: 'C09B 45',
    d: 'Complex metal compounds of azo dyes',
  },
  {
    c: 'C09B 46',
    d: 'Azo dyes not provided for in groups C09B0027000000-C09B0045000000',
  },
  {
    c: 'C09B 47',
    d: 'Porphines; Azaporphines',
  },
  {
    c: 'C09B 48',
    d: 'Quinacridones',
  },
  {
    c: 'C09B 49',
    d: 'Sulfur dyes',
  },
  {
    c: 'C09B 50',
    d: 'Formazane dyes; Tetrazolium dyes',
  },
  {
    c: 'C09B 51',
    d: 'Nitro or nitroso dyes',
  },
  {
    c: 'C09B 53',
    d: 'Quinone imides',
  },
  {
    c: 'C09B 55',
    d: 'Azomethine dyes',
  },
  {
    c: 'C09B 56',
    d: 'Azo dyes containing other chromophoric systems',
  },
  {
    c: 'C09B 57',
    d: 'Other synthetic dyes of known constitution',
  },
  {
    c: 'C09B 59',
    d: 'Artificial dyes of unknown constitution',
  },
  {
    c: 'C09B 61',
    d: 'Dyes of natural origin prepared from natural sources',
  },
  {
    c: 'C09B 62',
    d: 'Reactive dyes, i.e. dyes which form covalent bonds with the substrates or which polymerise with themselves',
  },
  {
    c: 'C09B 63',
    d: 'Lakes; Mordants; Dyestuff preparations',
  },
  {
    c: 'C09B 65',
    d: 'Compositions containing mordants',
  },
  {
    c: 'C09B 67',
    d: 'Influencing the physical, e.g. the dyeing or printing, properties of dyestuffs without chemical reaction, e.g. by treating with solvents; Process features in the making of dyestuff preparations; Dyestuff preparations of a special physical nature, e.g. tablets, films',
  },
  {
    c: 'C09B 69',
    d: 'Dyes not provided for by a single group of this subclass',
  },
  {
    c: 'C09C',
    d: 'TREATMENT OF INORGANIC MATERIALS, OTHER THAN FIBROUS FILLERS, TO ENHANCE THEIR PIGMENTING OR FILLING PROPERTIES',
  },
  {
    c: 'C09C 1',
    d: 'Treatment of specific inorganic materials other than fibrous fillers',
  },
  {
    c: 'C09C 3',
    d: 'Treatment in general of inorganic materials, other than fibrous fillers, to enhance their pigmenting or filling properties',
  },
  {
    c: 'C09D',
    d: 'COATING COMPOSITIONS, e.g. PAINTS, VARNISHES OR LACQUERS; FILLING PASTES; CHEMICAL PAINT OR INK REMOVERS; INKS; CORRECTING FLUIDS; WOODSTAINS; PASTES OR SOLIDS FOR COLOURING OR PRINTING; USE OF MATERIALS THEREFOR',
  },
  {
    c: 'C09D 1',
    d: 'Coating compositions, e.g. paints, varnishes or lacquers, based on inorganic substances',
  },
  {
    c: 'C09D 4',
    d: 'Coating compositions, e.g. paints, varnishes or lacquers, based on organic non-macromolecular compounds having at least one polymerisable carbon-to-carbon unsaturated bond',
  },
  {
    c: 'C09D 5',
    d: 'Coating compositions, e.g. paints, varnishes or lacquers, characterised by their physical nature or the effects produced; Filling pastes',
  },
  {
    c: 'C09D 7',
    d: 'Features of coating compositions, not provided for in group C09D0005000000',
  },
  {
    c: 'C09D 9',
    d: 'Chemical paint or ink removers',
  },
  {
    c: 'C09D 10',
    d: 'Correcting fluids, e.g. fluid media for correction of typographical errors by coating',
  },
  {
    c: 'C09D 11',
    d: 'Inks',
  },
  {
    c: 'C09D 13',
    d: 'Pencil-leads; Crayon compositions; Chalk compositions',
  },
  {
    c: 'C09D 15',
    d: 'Woodstains',
  },
  {
    c: 'C09D 17',
    d: 'Pigment pastes, e.g. for mixing in paints',
  },
  {
    c: 'C09D 101',
    d: 'Coating compositions based on polysaccharides or on their derivatives',
  },
  {
    c: 'C09D 103',
    d: 'Coating compositions based on starch, amylose or amylopectin or on their derivatives or degradation products',
  },
  {
    c: 'C09D 105',
    d: 'Coating compositions based on polysaccharides or on their derivatives, not provided for in groups C09D0101000000 or C09D0103000000',
  },
  {
    c: 'C09D 107',
    d: 'Coating compositions based on rubbers or on their derivatives',
  },
  {
    c: 'C09D 109',
    d: 'Coating compositions based on homopolymers or copolymers of conjugated diene hydrocarbons',
  },
  {
    c: 'C09D 111',
    d: 'Coating compositions based on homopolymers or copolymers of chloroprene',
  },
  {
    c: 'C09D 113',
    d: 'Coating compositions based on rubbers containing carboxyl groups',
  },
  {
    c: 'C09D 115',
    d: 'Coating compositions based on rubber derivatives',
  },
  {
    c: 'C09D 117',
    d: 'Coating compositions based on reclaimed rubber',
  },
  {
    c: 'C09D 119',
    d: 'Coating compositions based on rubbers, not provided for in groups C09D0107000000-C09D0117000000',
  },
  {
    c: 'C09D 121',
    d: 'Coating compositions based on unspecified rubbers',
  },
  {
    c: 'C09D 123',
    d: 'Coating compositions based on organic macromolecular compounds obtained by reactions only involving carbon-to-carbon unsaturated bonds',
  },
  {
    c: 'C09D 125',
    d: 'Coating compositions based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an aromatic carbocyclic ring; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 127',
    d: 'Coating compositions based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a halogen; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 129',
    d: 'Coating compositions based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an alcohol, ether, aldehydo, ketonic, acetal, or ketal radical; Coating compositions based on hydrolysed polymers of esters of unsaturated alcohols with saturated carboxylic acids; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 131',
    d: 'Coating compositions based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an acyloxy radical of a saturated carboxylic acid, of carbonic acid, or of a haloformic acid',
  },
  {
    c: 'C09D 133',
    d: 'Coating compositions based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by only one carboxyl radical, or of salts, anhydrides, esters, amides, imides, or nitriles thereof; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 135',
    d: 'Coating compositions based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a carboxyl radical, and containing at least another carboxyl radical in the molecule, or of salts, anhydrides, esters, amides, imides or nitriles thereof; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 137',
    d: 'Coating compositions based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a heterocyclic ring containing oxygen',
  },
  {
    c: 'C09D 139',
    d: 'Coating compositions based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a single or double bond to nitrogen or by a heterocyclic ring containing nitrogen; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 141',
    d: 'Coating compositions based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a bond to sulfur or by a heterocyclic ring containing sulfur; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 143',
    d: 'Coating compositions based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and containing boron, silicon, phosphorus, selenium, tellurium or a metal; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 145',
    d: 'Coating compositions based on homopolymers or copolymers of compounds having no unsaturated aliphatic radicals in a side chain, and having one or more carbon-to-carbon double bonds in a carbocyclic or in a heterocyclic ring system; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 147',
    d: 'Coating compositions based on homolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, at least one having two or more carbon-to-carbon double bonds; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 149',
    d: 'Coating compositions based on homopolymers or copolymers of compounds having one or more carbon-to-carbon triple bonds; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 151',
    d: 'Coating compositions based on graft polymers in which the grafted component is obtained by reactions only involving carbon-to-carbon unsaturated bonds',
  },
  {
    c: 'C09D 153',
    d: 'Coating compositions based on block copolymers containing at least one sequence of a polymer obtained by reactions only involving carbon-to-carbon unsaturated bonds; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 155',
    d: 'Coating composition based on homopolymers or copolymers, obtained by polymerisation reactions only involving carbon-to-carbon unsaturated bonds, not provided for in groups C09D0123000000-C09D0153000000',
  },
  {
    c: 'C09D 157',
    d: 'Coating compositions based on unspecified polymers obtained by reactions only involving carbon-to-carbon unsaturated bonds',
  },
  {
    c: 'C09D 159',
    d: 'Coating compositions based on organic macromolecular compounds obtained otherwise than by reactions only involving carbon-to-carbon unsaturated bonds',
  },
  {
    c: 'C09D 161',
    d: 'Coating compositions based on condensation polymers of aldehydes or ketones',
  },
  {
    c: 'C09D 163',
    d: 'Coating compositions based on epoxy resins; Coating compositions based on derivatives of epoxy resins',
  },
  {
    c: 'C09D 165',
    d: 'Coating compositions based on macromolecular compounds obtained by reactions forming a carbon-to-carbon link in the main chain',
  },
  {
    c: 'C09D 167',
    d: 'Coating compositions based on polyesters obtained by reactions forming a carboxylic ester link in the main chain',
  },
  {
    c: 'C09D 169',
    d: 'Coating compositions based on polycarbonates; Coating compositions based on derivatives of polycarbonates',
  },
  {
    c: 'C09D 171',
    d: 'Coating compositions based on polyethers obtained by reactions forming an ether link in the main chain',
  },
  {
    c: 'C09D 173',
    d: 'Coating compositions based on macromolecular compounds obtained by reactions forming a linkage containing oxygen or oxygen and carbon in the main chain, not provided for in groups C09D0159000000-C09D0171000000; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 175',
    d: 'Coating compositions based on polyureas or polyurethanes; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 177',
    d: 'Coating compositions based on polyamides obtained by reactions forming a carboxylic amide link in the main chain',
  },
  {
    c: 'C09D 179',
    d: 'Coating compositions based on macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing nitrogen, with or without oxygen, or carbon only, not provided for in groups C09D0161000000-C09D0177000000',
  },
  {
    c: 'C09D 181',
    d: 'Coating compositions based on macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing sulfur, with or without nitrogen, oxygen, or carbon only; Coating compositions based on polysulfones; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 183',
    d: 'Coating compositions based on macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing silicon, with or without sulfur, nitrogen, oxygen, or carbon only; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 185',
    d: 'Coating compositions based on macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing atoms other than silicon, sulfur, nitrogen, oxygen, and carbon; Coating compositions based on derivatives of such polymers',
  },
  {
    c: 'C09D 187',
    d: 'Coating compositions based on unspecified macromolecular compounds, obtained otherwise than by polymerisation reactions only involving unsaturated carbon-to-carbon bonds',
  },
  {
    c: 'C09D 189',
    d: 'Coating compositions based on natural macromolecular compounds or on derivatives thereof',
  },
  {
    c: 'C09D 191',
    d: 'Coating compositions based on oils, fats or waxes; Coating compositions based on derivatives thereof',
  },
  {
    c: 'C09D 193',
    d: 'Coating compositions based on natural resins; Coating compositions based on derivatives thereof',
  },
  {
    c: 'C09D 195',
    d: 'Coating compositions based on bituminous materials, e.g. asphalt, tar or pitch',
  },
  {
    c: 'C09D 197',
    d: 'Coating compositions based on lignin-containing materials',
  },
  {
    c: 'C09D 199',
    d: 'Coating compositions based on natural macromolecular compounds or on derivatives thereof, not provided for in groups C09D0101000000-C09D0107000000 or C09D0189000000-C09D0197000000',
  },
  {
    c: 'C09D 201',
    d: 'Coating compositions based on unspecified macromolecular compounds',
  },
  {
    c: 'C09F',
    d: 'NATURAL RESINS; FRENCH POLISH; DRYING-OILS; DRIERS',
  },
  {
    c: 'C09F 1',
    d: 'Obtaining, purification, or chemical modification of natural resins, e.g. oleo-resins',
  },
  {
    c: 'C09F 3',
    d: 'Obtaining spirits of turpentine',
  },
  {
    c: 'C09F 5',
    d: 'Obtaining drying-oils',
  },
  {
    c: 'C09F 7',
    d: 'Chemical modification of drying-oils',
  },
  {
    c: 'C09F 9',
    d: 'Compounds to be used as driers',
  },
  {
    c: 'C09F 11',
    d: 'Preparation of French polish',
  },
  {
    c: 'C09G',
    d: 'POLISHING COMPOSITIONS OTHER THAN FRENCH POLISH; SKI WAXES',
  },
  {
    c: 'C09G 1',
    d: 'Polishing compositions',
  },
  {
    c: 'C09G 3',
    d: 'Ski waxes',
  },
  {
    c: 'C09H',
    d: 'PREPARATION OF GLUE OR GELATINE',
  },
  {
    c: 'C09H 1',
    d: 'Pretreatment of collagen-containing raw materials for the manufacture of glue',
  },
  {
    c: 'C09H 3',
    d: 'Isolation of glue or gelatine from raw materials, e.g. by extracting, by heating',
  },
  {
    c: 'C09H 5',
    d: 'Stabilisation of solutions of glue or gelatine',
  },
  {
    c: 'C09H 7',
    d: 'Preparation of water-insoluble gelatine',
  },
  {
    c: 'C09H 9',
    d: 'Drying of glue or gelatine',
  },
  {
    c: 'C09J',
    d: 'ADHESIVES; NON-MECHANICAL ASPECTS OF ADHESIVE PROCESSES IN GENERAL; ADHESIVE PROCESSES NOT PROVIDED FOR ELSEWHERE; USE OF MATERIALS AS ADHESIVES',
  },
  {
    c: 'C09J 1',
    d: 'Adhesives based on inorganic constituents',
  },
  {
    c: 'C09J 4',
    d: 'Adhesives based on organic non-macromolecular compounds having at least one polymerisable carbon-to-carbon unsaturated bond',
  },
  {
    c: 'C09J 5',
    d: 'Adhesive processes in general; Adhesive processes not provided for elsewhere, e.g. relating to primers',
  },
  {
    c: 'C09J 7',
    d: 'Adhesives in the form of films or foils',
  },
  {
    c: 'C09J 9',
    d: 'Adhesives characterised by their physical nature or the effects produced, e.g. glue sticks',
  },
  {
    c: 'C09J 11',
    d: 'Features of adhesives not provided for in group C09J0009000000, e.g. additives',
  },
  {
    c: 'C09J 101',
    d: 'Adhesives based on polysaccharides or on their derivatives',
  },
  {
    c: 'C09J 103',
    d: 'Adhesives based on starch, amylose or amylopectin or on their derivatives or degradation products',
  },
  {
    c: 'C09J 105',
    d: 'Adhesives based on polysaccharides or on their derivatives, not provided for in groups C09J0101000000 or C09J0103000000',
  },
  {
    c: 'C09J 107',
    d: 'Adhesives based on rubbers or on their derivatives',
  },
  {
    c: 'C09J 109',
    d: 'Adhesives based on homopolymers or copolymers of conjugated diene hydrocarbons',
  },
  {
    c: 'C09J 111',
    d: 'Adhesives based on homopolymers or copolymers of chloroprene',
  },
  {
    c: 'C09J 113',
    d: 'Adhesives based on rubbers containing carboxyl groups',
  },
  {
    c: 'C09J 115',
    d: 'Adhesives based on rubber derivatives',
  },
  {
    c: 'C09J 117',
    d: 'Adhesives based on reclaimed rubber',
  },
  {
    c: 'C09J 119',
    d: 'Adhesives based on rubbers, not provided for in groups C09J0107000000-C09J0117000000',
  },
  {
    c: 'C09J 121',
    d: 'Adhesives based on unspecified rubbers',
  },
  {
    c: 'C09J 123',
    d: 'Adhesives based on organic macromolecular compounds obtained by reactions only involving carbon-to-carbon unsaturated bonds',
  },
  {
    c: 'C09J 125',
    d: 'Adhesives based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an aromatic carbocyclic ring; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 127',
    d: 'Adhesives based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a halogen; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 129',
    d: 'Adhesives based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an alcohol, ether, aldehydo, ketonic, acetal, or ketal radical; Adhesives based on hydrolysed polymers of esters of unsaturated alcohols with saturated carboxylic acids; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 131',
    d: 'Adhesives based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by an acyloxy radical of a saturated carboxylic acid, of carbonic acid, or of a haloformic acid',
  },
  {
    c: 'C09J 133',
    d: 'Adhesives based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by only one carboxyl radical, or of salts, anhydrides, esters, amides, imides, or nitriles thereof; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 135',
    d: 'Adhesives based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a carboxyl radical, and containing at least another carboxyl radical in the molecule, or of salts, anhydrides, esters, amides, imides or nitriles thereof; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 137',
    d: 'Adhesives based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a heterocyclic ring containing oxygen',
  },
  {
    c: 'C09J 139',
    d: 'Adhesives based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a single or double bond to nitrogen or by a heterocyclic ring containing nitrogen; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 141',
    d: 'Adhesives based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and at least one being terminated by a bond to sulfur or by a heterocyclic ring containing sulfur; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 143',
    d: 'Adhesives based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, each having only one carbon-to-carbon double bond, and containing boron, silicon, phosphorus, selenium, tellurium, or a metal; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 145',
    d: 'Adhesives based on homopolymers or copolymers of compounds having no unsaturated aliphatic radicals in a side chain, and having one or more carbon-to-carbon double bonds in a carbocyclic or in a heterocyclic ring system; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 147',
    d: 'Adhesives based on homopolymers or copolymers of compounds having one or more unsaturated aliphatic radicals, at least one having two or more carbon-to-carbon double bonds; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 149',
    d: 'Adhesives based on homopolymers or copolymers of compounds having one or more carbon-to-carbon triple bonds; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 151',
    d: 'Adhesives based on graft polymers in which the grafted component is obtained by reactions only involving carbon-to-carbon unsaturated bonds',
  },
  {
    c: 'C09J 153',
    d: 'Adhesives based on block copolymers containing at least one sequence of a polymer obtained by reactions only involving carbon-to-carbon unsaturated bonds; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 155',
    d: 'Adhesives based on homopolymers or copolymers, obtained by polymerisation reactions only involving carbon-to-carbon unsaturated bonds, not provided for in groups C09J0123000000-C09J0153000000',
  },
  {
    c: 'C09J 157',
    d: 'Adhesives based on unspecified polymers obtained by reactions only involving carbon-to-carbon unsaturated bonds',
  },
  {
    c: 'C09J 159',
    d: 'Adhesives based on organic macromolecular compounds obtained otherwise than by reactions only involving carbon-to-carbon unsaturated bonds',
  },
  {
    c: 'C09J 161',
    d: 'Adhesives based on condensation polymers of aldehydes or ketones',
  },
  {
    c: 'C09J 163',
    d: 'Adhesives based on epoxy resins; Adhesives based on derivatives of epoxy resins',
  },
  {
    c: 'C09J 165',
    d: 'Adhesives based on macromolecular compounds obtained by reactions forming a carbon-to-carbon link in the main chain',
  },
  {
    c: 'C09J 167',
    d: 'Adhesives based on polyesters obtained by reactions forming a carboxylic ester link in the main chain',
  },
  {
    c: 'C09J 169',
    d: 'Adhesives based on polycarbonates; Adhesives based on derivatives of polycarbonates',
  },
  {
    c: 'C09J 171',
    d: 'Adhesives based on polyethers obtained by reactions forming an ether link in the main chain',
  },
  {
    c: 'C09J 173',
    d: 'Adhesives based on macromolecular compounds obtained by reactions forming a linkage containing oxygen or oxygen and carbon in the main chain, not provided for in groups C09J0159000000-C09J0171000000; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 175',
    d: 'Adhesives based on polyureas or polyurethanes; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 177',
    d: 'Adhesives based on polyamides obtained by reactions forming a carboxylic amide link in the main chain',
  },
  {
    c: 'C09J 179',
    d: 'Adhesives based on macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing nitrogen, with or without oxygen, or carbon only, not provided for in groups C09J0161000000-C09J0177000000',
  },
  {
    c: 'C09J 181',
    d: 'Adhesives based on macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing sulfur, with or without nitrogen, oxygen, or carbon only; Adhesives based on polysulfones; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 183',
    d: 'Adhesives based on macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing silicon, with or without sulfur, nitrogen, oxygen, or carbon only; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 185',
    d: 'Adhesives based on macromolecular compounds obtained by reactions forming in the main chain of the macromolecule a linkage containing atoms other than silicon, sulfur, nitrogen, oxygen, and carbon; Adhesives based on derivatives of such polymers',
  },
  {
    c: 'C09J 187',
    d: 'Adhesives based on unspecified macromolecular compounds, obtained otherwise than by polymerisation reactions only involving unsaturated carbon-to-carbon-bonds',
  },
  {
    c: 'C09J 189',
    d: 'Adhesives based on natural macromolecular compounds or on derivatives thereof',
  },
  {
    c: 'C09J 191',
    d: 'Adhesives based on oils, fats or waxes; Adhesives based on derivatives thereof',
  },
  {
    c: 'C09J 193',
    d: 'Adhesives based on natural resins; Adhesives based on derivatives thereof',
  },
  {
    c: 'C09J 195',
    d: 'Adhesives based on bituminous materials, e.g. asphalt, tar or pitch',
  },
  {
    c: 'C09J 197',
    d: 'Adhesives based on lignin-containing materials',
  },
  {
    c: 'C09J 199',
    d: 'Adhesives based on natural macromolecular compounds or on derivatives thereof, not provided for in groups C09J0101000000-C09J0107000000 or C09J0189000000-C09J0197000000',
  },
  {
    c: 'C09J 201',
    d: 'Adhesives based on unspecified macromolecular compounds',
  },
  {
    c: 'C09K',
    d: 'MATERIALS FOR APPLICATIONS NOT OTHERWISE PROVIDED FOR; APPLICATIONS OF MATERIALS NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'C09K 3',
    d: 'Materials not provided for elsewhere',
  },
  {
    c: 'C09K 5',
    d: 'Heat-transfer, heat-exchange or heat-storage materials, e.g. refrigerants; Materials for the production of heat or cold by chemical reactions other than by combustion',
  },
  {
    c: 'C09K 8',
    d: 'Compositions for drilling of boreholes or wells; Compositions for treating boreholes or wells, e.g. for completion or for remedial operations',
  },
  {
    c: 'C09K 9',
    d: 'Tenebrescent materials, i.e. materials for which the range of wavelengths for energy adsorption is changed as a result of excitation by some form of energy',
  },
  {
    c: 'C09K 11',
    d: 'Luminescent, e.g. electroluminescent, chemiluminescent, materials',
  },
  {
    c: 'C09K 13',
    d: 'Etching, surface-brightening or pickling compositions',
  },
  {
    c: 'C09K 15',
    d: 'Anti-oxidant compositions; Compositions inhibiting chemical change',
  },
  {
    c: 'C09K 17',
    d: 'Soil-conditioning materials or soil-stabilising materials',
  },
  {
    c: 'C09K 19',
    d: 'Liquid crystal materials',
  },
  {
    c: 'C09K 21',
    d: 'Fireproofing materials',
  },
  {
    c: 'C09K 101',
    d: 'Indexing scheme associated with group C09K0017000000, relating to the use or the intended effect of the soil-conditioning or soil-stabilising materials.',
  },
  {
    c: 'C09K 103',
    d: 'Civil engineering use',
  },
  {
    c: 'C09K 105',
    d: 'Erosion prevention',
  },
  {
    c: 'C09K 107',
    d: 'Impermeabilisation',
  },
  {
    c: 'C09K 109',
    d: 'pH regulation',
  },
  {
    c: 'C10',
    d: 'PETROLEUM, GAS OR COKE INDUSTRIES; TECHNICAL GASES CONTAINING CARBON MONOXIDE; FUELS; LUBRICANTS; PEAT',
  },
  {
    c: 'C10B',
    d: 'DESTRUCTIVE DISTILLATION OF CARBONACEOUS MATERIALS FOR PRODUCTION OF GAS, COKE, TAR, OR SIMILAR MATERIALS',
  },
  {
    c: 'C10B 1',
    d: 'Retorts or coke ovens',
  },
  {
    c: 'C10B 3',
    d: 'Coke ovens with vertical chambers',
  },
  {
    c: 'C10B 5',
    d: 'Coke ovens with horizontal chambers',
  },
  {
    c: 'C10B 7',
    d: 'Coke ovens with mechanical conveying means for the raw material inside the oven',
  },
  {
    c: 'C10B 9',
    d: 'Beehive ovens',
  },
  {
    c: 'C10B 11',
    d: 'Coke ovens with inclined chambers',
  },
  {
    c: 'C10B 13',
    d: 'Coke ovens with means for bringing and keeping the charge under mechanical pressure',
  },
  {
    c: 'C10B 15',
    d: 'Other coke ovens',
  },
  {
    c: 'C10B 17',
    d: 'Heating of coke ovens',
  },
  {
    c: 'C10B 19',
    d: 'Heating of coke ovens by electrical means',
  },
  {
    c: 'C10B 21',
    d: 'Heating of coke ovens with combustible gases',
  },
  {
    c: 'C10B 23',
    d: 'Other methods of heating coke ovens',
  },
  {
    c: 'C10B 25',
    d: 'Doors or closures for coke ovens',
  },
  {
    c: 'C10B 27',
    d: 'Arrangements for withdrawal of the distillation gases',
  },
  {
    c: 'C10B 29',
    d: 'Other details of coke ovens',
  },
  {
    c: 'C10B 31',
    d: 'Devices for charging or discharging coke ovens; Mechanical treatments of coal charges',
  },
  {
    c: 'C10B 33',
    d: 'Discharging devices for coke ovens; Coke guides',
  },
  {
    c: 'C10B 35',
    d: 'Combined charging and discharging devices for coke ovens',
  },
  {
    c: 'C10B 37',
    d: 'Mechanical treatments of coal charges in the oven',
  },
  {
    c: 'C10B 39',
    d: 'Cooling or quenching coke',
  },
  {
    c: 'C10B 41',
    d: 'Safety devices, e.g. signalling or controlling devices for use in the discharge of coke',
  },
  {
    c: 'C10B 43',
    d: 'Preventing or removing incrustations',
  },
  {
    c: 'C10B 45',
    d: 'Other details',
  },
  {
    c: 'C10B 47',
    d: 'Carbonising or coking processes',
  },
  {
    c: 'C10B 49',
    d: 'Destructive distillation of solid carbonaceous materials by direct heating with heat-carrying agents including the partial combustion of the solid material to be treated',
  },
  {
    c: 'C10B 51',
    d: 'Destructive distillation of solid carbonaceous materials by combined direct and indirect heating',
  },
  {
    c: 'C10B 53',
    d: 'Destructive distillation, specially adapted for particular solid raw materials or solid raw materials in special form',
  },
  {
    c: 'C10B 55',
    d: 'Coking mineral oils, bitumen, tar or the like, or mixtures thereof, with solid carbonaceous materials',
  },
  {
    c: 'C10B 57',
    d: 'Other carbonising or coking processes; Features of destructive distillation processes in general',
  },
  {
    c: 'C10C',
    d: 'WORKING-UP TAR, PITCH, ASPHALT, BITUMEN; PYROLIGNEOUS ACID',
  },
  {
    c: 'C10C 1',
    d: 'Working-up tar',
  },
  {
    c: 'C10C 3',
    d: 'Working-up pitch, asphalt, bitumen',
  },
  {
    c: 'C10C 5',
    d: 'Production of pyroligneous acid',
  },
  {
    c: 'C10F',
    d: 'DRYING OR WORKING-UP OF PEAT',
  },
  {
    c: 'C10F 5',
    d: 'Drying or de-watering peat',
  },
  {
    c: 'C10F 7',
    d: 'Working-up peat',
  },
  {
    c: 'C10G',
    d: 'CRACKING HYDROCARBON OILS; PRODUCTION OF LIQUID HYDROCARBON MIXTURES, e.g. BY DESTRUCTIVE HYDROGENATION, OLIGOMERISATION, POLYMERISATION',
  },
  {
    c: 'C10G 1',
    d: 'Production of liquid hydrocarbon mixtures from oil shale, oil-sand, or non-melting solid carbonaceous or similar materials, e.g. wood, coal',
  },
  {
    c: 'C10G 2',
    d: 'Production of liquid hydrocarbon mixtures of undefined composition from oxides of carbon',
  },
  {
    c: 'C10G 3',
    d: 'Production of liquid hydrocarbon mixtures from oxygen-containing organic materials, e.g. fatty oils, fatty acids',
  },
  {
    c: 'C10G 5',
    d: 'Recovery of liquid hydrocarbon mixtures from gases, e.g. natural gas',
  },
  {
    c: 'C10G 7',
    d: 'Distillation of hydrocarbon oils',
  },
  {
    c: 'C10G 9',
    d: 'Cracking in the absence of hydrogen',
  },
  {
    c: 'C10G 11',
    d: 'Catalytic cracking, in the absence of hydrogen, of hydrocarbon oils',
  },
  {
    c: 'C10G 15',
    d: 'Cracking of hydrocarbon oils by electric means, electromagnetic or mechanical vibrations, by particle radiation or with gases superheated in electric arcs',
  },
  {
    c: 'C10G 17',
    d: 'Refining in the absence of hydrogen',
  },
  {
    c: 'C10G 19',
    d: 'Refining hydrocarbon oils, in the absence of hydrogen, by alkaline treatment',
  },
  {
    c: 'C10G 21',
    d: 'Refining of hydrocarbon oils, in the absence of hydrogen, by extraction with selective solvents',
  },
  {
    c: 'C10G 25',
    d: 'Refining of hydrocarbon oils, in the absence of hydrogen, with solid sorbents',
  },
  {
    c: 'C10G 27',
    d: 'Refining of hydrocarbon oils, in the absence of hydrogen, by oxidation',
  },
  {
    c: 'C10G 29',
    d: 'Refining of hydrocarbon oils, in the absence of hydrogen, with other chemicals',
  },
  {
    c: 'C10G 31',
    d: 'Refining of hydrocarbon oils, in the absence of hydrogen, by methods not otherwise provided for',
  },
  {
    c: 'C10G 32',
    d: 'Refining of hydrocarbon oils by electric or magnetic means, by irradiation, or by using microorganisms',
  },
  {
    c: 'C10G 33',
    d: 'De-watering or demulsification of hydrocarbon oils',
  },
  {
    c: 'C10G 35',
    d: 'Reforming naphtha',
  },
  {
    c: 'C10G 45',
    d: 'Hydrotreatment processes',
  },
  {
    c: 'C10G 47',
    d: 'Cracking of hydrocarbon oils, in the presence of hydrogen or hydrogen-generating compounds, to obtain lower boiling fractions',
  },
  {
    c: 'C10G 49',
    d: 'Treatment of hydrocarbon oils, in the presence of hydrogen or hydrogen-generating compounds, not provided for in a single one of groups C10G0045020000, C10G0045320000, C10G0045440000, C10G0045580000, or C10G0047000000',
  },
  {
    c: 'C10G 50',
    d: 'Production of liquid hydrocarbon mixtures from lower carbon number hydrocarbons, e.g. by oligomerisation',
  },
  {
    c: 'C10G 51',
    d: 'Multi-step processes',
  },
  {
    c: 'C10G 53',
    d: 'Treatment of hydrocarbon oils, in the absence of hydrogen, by two or more refining processes',
  },
  {
    c: 'C10G 55',
    d: 'Treatment of hydrocarbon oils, in the absence of hydrogen, by at least one refining process and at least one cracking process',
  },
  {
    c: 'C10G 57',
    d: 'Treatment of hydrocarbon oils, in the absence of hydrogen, by at least one cracking process or refining process and at least one other conversion process',
  },
  {
    c: 'C10G 59',
    d: 'Treatment of naphtha by two or more reforming processes only or by at least one reforming process and at least one process which does not substantially change the boiling range of the naphtha',
  },
  {
    c: 'C10G 61',
    d: 'Treatment of naphtha by at least one reforming process and at least one process of refining in the absence of hydrogen',
  },
  {
    c: 'C10G 63',
    d: 'Treatment of naphtha by at least one reforming process and at least one other conversion process',
  },
  {
    c: 'C10G 65',
    d: 'Treatment of hydrocarbon oils by two or more hydrotreatment processes only',
  },
  {
    c: 'C10G 67',
    d: 'Treatment of hydrocarbon oils by at least one hydrotreatment process and at least one process for refining in the absence of hydrogen only',
  },
  {
    c: 'C10G 69',
    d: 'Treatment of hydrocarbon oils by at least one hydrotreatment process and at least one other conversion process',
  },
  {
    c: 'C10G 70',
    d: 'Working-up undefined normally gaseous mixtures obtained by processes covered by groups C10G0009000000, C10G0011000000, C10G0015000000, C10G0047000000, C10G0051000000',
  },
  {
    c: 'C10G 71',
    d: 'Treatment by methods not otherwise provided for of hydrocarbon oils or fatty oils for lubricating purposes',
  },
  {
    c: 'C10G 73',
    d: 'Recovery or refining of mineral waxes, e.g. montan wax',
  },
  {
    c: 'C10G 75',
    d: 'Inhibiting corrosion or fouling in apparatus for treatment or conversion of hydrocarbon oils, in general',
  },
  {
    c: 'C10G 99',
    d: 'n.a.',
  },
  {
    c: 'C10H',
    d: 'PRODUCTION OF ACETYLENE BY WET METHODS',
  },
  {
    c: 'C10H 1',
    d: 'Acetylene gas generators with dropwise, gravity, non-automatic water feed',
  },
  {
    c: 'C10H 3',
    d: 'Acetylene gas generators with automatic water feed regulation by means independent of the gas-holder',
  },
  {
    c: 'C10H 5',
    d: 'Acetylene gas generators with automatic water feed regulation by the gas-holder',
  },
  {
    c: 'C10H 7',
    d: "Acetylene gas generators with water feed by Kipp's principle",
  },
  {
    c: 'C10H 9',
    d: "Acetylene gas generators according to Dobereiner's principle with fixed carbide bell",
  },
  {
    c: 'C10H 11',
    d: 'Acetylene gas generators with submersion of the carbide in water',
  },
  {
    c: 'C10H 13',
    d: 'Acetylene gas generators with combined dipping and drop-by-drop system',
  },
  {
    c: 'C10H 15',
    d: 'Acetylene gas generators with carbide feed, with or without regulation by the gas pressure',
  },
  {
    c: 'C10H 17',
    d: 'High-pressure acetylene gas generators',
  },
  {
    c: 'C10H 19',
    d: 'Other acetylene gas generators',
  },
  {
    c: 'C10H 21',
    d: 'Details of acetylene generators; Accessory equipment for, or features of, the wet production of acetylene',
  },
  {
    c: 'C10J',
    d: 'PRODUCTION OF GASES CONTAINING CARBON MONOXIDE AND HYDROGEN FROM SOLID CARBONACEOUS MATERIALS BY PARTIAL OXIDATION PROCESSES INVOLVING OXYGEN OR STEAM',
  },
  {
    c: 'C10J 1',
    d: 'Production of fuel gases by carburetting air or other gases',
  },
  {
    c: 'C10J 3',
    d: 'Production of gases containing carbon monoxide and hydrogen, e.g. synthesis gas or town gas, from solid carbonaceous materials by partial oxidation processes involving oxygen or steam',
  },
  {
    c: 'C10K',
    d: 'PURIFYING OR MODIFYING THE CHEMICAL COMPOSITION OF COMBUSTIBLE GASES CONTAINING CARBON MONOXIDE',
  },
  {
    c: 'C10K 1',
    d: 'Purifying combustible gases containing carbon monoxide',
  },
  {
    c: 'C10K 3',
    d: 'Modifying the chemical composition of combustible gases containing carbon monoxide to produce an improved fuel, e.g. one of different calorific value, which may be free from carbon monoxide',
  },
  {
    c: 'C10L',
    d: 'FUELS NOT OTHERWISE PROVIDED FOR; NATURAL GAS; SYNTHETIC NATURAL GAS OBTAINED BY PROCESSES NOT COVERED BY SUBCLASSES C10G OR  C10K; LIQUEFIED PETROLEUM GAS;  USE OF ADDITIVES TO FUELS OR FIRES;  FIRE-LIGHTERS',
  },
  {
    c: 'C10L 1',
    d: 'Liquid carbonaceous fuels',
  },
  {
    c: 'C10L 3',
    d: 'Gaseous fuels; Natural gas; Synthetic natural gas obtained by processes not covered by subclasses C10G, C10K; Liquefied petroleum gas',
  },
  {
    c: 'C10L 5',
    d: 'Solid fuels',
  },
  {
    c: 'C10L 7',
    d: 'Fuels produced by solidifying fluid fuels',
  },
  {
    c: 'C10L 8',
    d: 'Fuels not provided for in other groups of this subclass',
  },
  {
    c: 'C10L 9',
    d: 'Treating solid fuels to improve their combustion',
  },
  {
    c: 'C10L 10',
    d: 'Use of additives to fuels or fires for particular purposes',
  },
  {
    c: 'C10L 11',
    d: 'Fire-lighters',
  },
  {
    c: 'C10M',
    d: 'LUBRICATING COMPOSITIONS',
  },
  {
    c: 'C10M 101',
    d: 'Base-materials',
  },
  {
    c: 'C10M 103',
    d: 'Lubricating compositions characterised by the base-material being an inorganic material',
  },
  {
    c: 'C10M 105',
    d: 'Lubricating compositions characterised by the base-material being a non-macromolecular organic compound',
  },
  {
    c: 'C10M 107',
    d: 'Lubricating compositions characterised by the base-material being a macromolecular compound',
  },
  {
    c: 'C10M 109',
    d: 'Lubricating compositions characterised by the base-material being a compound of unknown or incompletely defined constitution',
  },
  {
    c: 'C10M 111',
    d: 'Lubricating compositions characterised by the base-material being a mixture of two or more compounds covered by more than one of the main groups C10M0101000000-C10M0109000000, each of these compounds being essential',
  },
  {
    c: 'C10M 113',
    d: 'Thickeners',
  },
  {
    c: 'C10M 115',
    d: 'Lubricating compositions characterised by the thickener being a non-macromolecular organic compound other than a carboxylic acid or salt thereof',
  },
  {
    c: 'C10M 117',
    d: 'Lubricating compositions characterised by the thickener being a non-macromolecular carboxylic acid or salt thereof',
  },
  {
    c: 'C10M 119',
    d: 'Lubricating compositions characterised by the thickener being a macromolecular compound',
  },
  {
    c: 'C10M 121',
    d: 'Lubricating compositions characterised by the thickener being a compound of unknown or incompletely defined constitution',
  },
  {
    c: 'C10M 123',
    d: 'Lubricating compositions characterised by the thickener being a mixture of two or more compounds covered by more than one of the main groups C10M0113000000-C10M0121000000, each of these compounds being essential',
  },
  {
    c: 'C10M 125',
    d: 'Additives',
  },
  {
    c: 'C10M 127',
    d: 'Lubricating compositions characterised by the additive being a non-macromolecular hydrocarbon',
  },
  {
    c: 'C10M 129',
    d: 'Lubricating compositions characterised by the additive being an organic non-macromolecular compound containing oxygen',
  },
  {
    c: 'C10M 131',
    d: 'Lubricating compositions characterised by the additive being an organic non-macromolecular compound containing halogen',
  },
  {
    c: 'C10M 133',
    d: 'Lubricating compositions characterised by the additive being an organic non-macromolecular compound containing nitrogen',
  },
  {
    c: 'C10M 135',
    d: 'Lubricating compositions characterised by the additive being an organic non-macromolecular compound containing sulfur, selenium or tellurium',
  },
  {
    c: 'C10M 137',
    d: 'Lubricating compositions characterised by the additive being an organic non-macromolecular compound containing phosphorus',
  },
  {
    c: 'C10M 139',
    d: 'Lubricating compositions characterised by the additive being an organic non-macromolecular compound containing atoms of elements not provided for in groups C10M0127000000-C10M0137000000',
  },
  {
    c: 'C10M 141',
    d: 'Lubricating compositions characterised by the additive being a mixture of two or more compounds covered by more than one of the main groups C10M0125000000-C10M0139000000, each of these compounds being essential',
  },
  {
    c: 'C10M 143',
    d: 'Lubricating composition characterised by the additive being a macromolecular hydrocarbon or such hydrocarbon modified by oxidation',
  },
  {
    c: 'C10M 145',
    d: 'Lubricating compositions characterised by the additive being a macromolecular compound containing oxygen',
  },
  {
    c: 'C10M 147',
    d: 'Lubricating compositions characterised by the additive being a macromolecular compound containing halogen',
  },
  {
    c: 'C10M 149',
    d: 'Lubricating compositions characterised by the additive being a macromolecular compound containing nitrogen',
  },
  {
    c: 'C10M 151',
    d: 'Lubricating compositions characterised by the additive being a macromolecular compound containing sulfur, selenium or tellurium',
  },
  {
    c: 'C10M 153',
    d: 'Lubricating compositions characterised by the additive being a macromolecular compound containing phosphorus',
  },
  {
    c: 'C10M 155',
    d: 'Lubricating compositions characterised by the additive being a macromolecular compound containing atoms of elements not provided for in groups C10M0143000000-C10M0153000000',
  },
  {
    c: 'C10M 157',
    d: 'Lubricating compositions characterised by the additive being a mixture of two or more macromolecular compounds covered by more than one of the main groups C10M0143000000-C10M0155000000, each of these compounds being essential',
  },
  {
    c: 'C10M 159',
    d: 'Lubricating compositions characterised by the additive being of unknown or incompletely defined constitution',
  },
  {
    c: 'C10M 161',
    d: 'Lubricating compositions characterised by the additive being a mixture of a macromolecular compound and a non-macromolecular compound, each of these compounds being essential',
  },
  {
    c: 'C10M 163',
    d: 'Lubricating compositions characterised by the additive being a mixture of a compound of unknown or incompletely defined constitution and a non-macromolecular compound, each of these compounds being essential',
  },
  {
    c: 'C10M 165',
    d: 'Lubricating compositions characterised by the additive being a mixture of a macromolecular compound and a compound of unknown or incompletely defined constitution, each of these compounds being essential',
  },
  {
    c: 'C10M 167',
    d: 'Lubricating compositions characterised by the additive being a mixture of a macromolecular compound, a non-macromolecular compound and a compound of unknown or incompletely defined constitution, each of these compounds being essential',
  },
  {
    c: 'C10M 169',
    d: 'Mixtures of base-materials, thickeners and additives',
  },
  {
    c: 'C10M 171',
    d: 'Compositions characterised by physical properties',
  },
  {
    c: 'C10M 173',
    d: 'Aqueous lubricating compositions',
  },
  {
    c: 'C10M 175',
    d: 'Working-up',
  },
  {
    c: 'C10M 177',
    d: 'Preparation or after-treatment',
  },
  {
    c: 'C10N',
    d: 'INDEXING SCHEME ASSOCIATED WITH SUBCLASS C10M',
  },
  {
    c: 'C10N 10',
    d: 'Metal present as such or in compounds',
  },
  {
    c: 'C10N 20',
    d: 'Specified physical properties of component of lubricating compositions',
  },
  {
    c: 'C10N 30',
    d: 'Specified physical or chemical property which is improved by the additive characterising the lubricating composition, e.g. multifunctional additives',
  },
  {
    c: 'C10N 40',
    d: 'Specified use or application for which the lubricating composition is intended',
  },
  {
    c: 'C10N 50',
    d: 'Form in which the lubricant is applied to the material being lubricated',
  },
  {
    c: 'C10N 60',
    d: 'Chemical after-treatment of the constituents of the lubricating composition',
  },
  {
    c: 'C10N 70',
    d: 'Special methods of preparation',
  },
  {
    c: 'C10N 80',
    d: 'Special pretreatment of the material to be lubricated, e.g. phosphatising or chromatising of a metal',
  },
  {
    c: 'C11',
    d: 'ANIMAL OR VEGETABLE OILS, FATS, FATTY SUBSTANCES OR WAXES; FATTY ACIDS THEREFROM; DETERGENTS; CANDLES',
  },
  {
    c: 'C11B',
    d: 'PRODUCING, e.g. BY PRESSING RAW MATERIALS OR BY EXTRACTION FROM WASTE MATERIALS, REFINING OR PRESERVING FATS, FATTY SUBSTANCES, e.g. LANOLIN, FATTY OILS OR WAXES; ESSENTIAL OILS; PERFUMES',
  },
  {
    c: 'C11B 1',
    d: 'Production of fats or fatty oils from raw materials',
  },
  {
    c: 'C11B 3',
    d: 'Refining fats or fatty oils',
  },
  {
    c: 'C11B 5',
    d: 'Preserving by using additives, e.g. anti-oxidants',
  },
  {
    c: 'C11B 7',
    d: 'Separation of mixtures of fats or fatty oils into their constituents, e.g. saturated oils from unsaturated oils',
  },
  {
    c: 'C11B 9',
    d: 'Essential oils; Perfumes',
  },
  {
    c: 'C11B 11',
    d: 'Recovery or refining of other fatty substances, e.g. lanolin, waxes',
  },
  {
    c: 'C11B 13',
    d: 'Recovery of fats, fatty oils, or fatty acids from waste materials',
  },
  {
    c: 'C11B 15',
    d: 'Solidifying fatty oils, fats, or waxes by physical processes',
  },
  {
    c: 'C11C',
    d: 'FATTY ACIDS OBTAINED FROM FATS, OILS OR WAXES; CANDLES; FATS, OILS OR FATTY ACIDS OBTAINED BY CHEMICAL MODIFICATION OF FATS, OILS OR FATTY ACIDS',
  },
  {
    c: 'C11C 1',
    d: 'Preparation of fatty acids from fats, fatty oils, or waxes; Refining the fatty acids',
  },
  {
    c: 'C11C 3',
    d: 'Fats, oils or fatty acids obtained by chemical modification of fats, oils or fatty acids, e.g. by ozonolysis',
  },
  {
    c: 'C11C 5',
    d: 'Candles',
  },
  {
    c: 'C11D',
    d: 'DETERGENT COMPOSITIONS; USE OF SINGLE SUBSTANCES AS DETERGENTS; SOAP OR SOAP-MAKING; RESIN SOAPS; RECOVERY OF GLYCEROL',
  },
  {
    c: 'C11D 1',
    d: 'Surface-active non-soap detergents',
  },
  {
    c: 'C11D 3',
    d: 'Other compounding ingredients of detergent compositions covered in group C11D0001000000',
  },
  {
    c: 'C11D 7',
    d: 'Compositions of detergents based essentially on non-surface-active compounds',
  },
  {
    c: 'C11D 9',
    d: 'Soap Detergents',
  },
  {
    c: 'C11D 10',
    d: 'Compositions of detergents, not provided for by any single one of main groups C11D0001000000-C11D0009000000',
  },
  {
    c: 'C11D 11',
    d: 'Special methods for preparing compositions containing mixtures of detergents',
  },
  {
    c: 'C11D 13',
    d: 'Soap or soap-making; Resin soaps',
  },
  {
    c: 'C11D 15',
    d: 'Manufacture of resin soap or soaps derived from naphthenic acids; Compositions',
  },
  {
    c: 'C11D 17',
    d: 'Detergent materials or soaps characterised by their shape or physical properties',
  },
  {
    c: 'C11D 19',
    d: 'Recovery of glycerol from a saponification liquor',
  },
  {
    c: 'C12',
    d: 'BIOCHEMISTRY; BEER; SPIRITS; WINE; VINEGAR; MICROBIOLOGY; ENZYMOLOGY; MUTATION OR GENETIC ENGINEERING',
  },
  {
    c: 'C12C',
    d: 'BREWING OF BEER',
  },
  {
    c: 'C12C 1',
    d: 'Preparation of malt',
  },
  {
    c: 'C12C 3',
    d: 'Treatment of hops',
  },
  {
    c: 'C12C 5',
    d: 'Other raw materials for the preparation of beer',
  },
  {
    c: 'C12C 7',
    d: 'Preparation of wort',
  },
  {
    c: 'C12C 11',
    d: 'Fermentation processes for beer',
  },
  {
    c: 'C12C 12',
    d: 'Processes specially adapted for making special kinds of beer',
  },
  {
    c: 'C12C 13',
    d: 'Brewing devices, not covered by a single group of C12C0001000000-C12C0012040000',
  },
  {
    c: 'C12F',
    d: 'RECOVERY OF BY-PRODUCTS OF FERMENTED SOLUTIONS; DENATURING OF, OR DENATURED, ALCOHOL',
  },
  {
    c: 'C12F 3',
    d: 'Recovery of by-products',
  },
  {
    c: 'C12F 5',
    d: 'Preparation of denatured alcohol',
  },
  {
    c: 'C12G',
    d: 'WINE; OTHER ALCOHOLIC BEVERAGES; PREPARATION THEREOF',
  },
  {
    c: 'C12G 1',
    d: 'Preparation of wine or sparkling wine',
  },
  {
    c: 'C12G 3',
    d: 'Preparation of other alcoholic beverages',
  },
  {
    c: 'C12H',
    d: 'PASTEURISATION, STERILISATION, PRESERVATION, PURIFICATION, CLARIFICATION OR AGEING OF ALCOHOLIC BEVERAGES; METHODS FOR ALTERING THE ALCOHOL CONTENT OF FERMENTED SOLUTIONS OR ALCOHOLIC BEVERAGES',
  },
  {
    c: 'C12H 1',
    d: 'Pasteurisation, sterilisation, preservation, purification, clarification, or ageing of alcoholic beverages',
  },
  {
    c: 'C12H 3',
    d: 'Methods for reducing the alcohol content of fermented solutions or alcoholic beverages to obtain low-alcohol or non-alcoholic beverages',
  },
  {
    c: 'C12H 6',
    d: 'Methods for increasing the alcohol content of fermented solutions or alcoholic beverages',
  },
  {
    c: 'C12J',
    d: 'VINEGAR; ITS PREPARATION',
  },
  {
    c: 'C12J 1',
    d: 'Vinegar; Preparation; Purification',
  },
  {
    c: 'C12L',
    d: 'PITCHING OR DEPITCHING MACHINES; CELLAR TOOLS',
  },
  {
    c: 'C12L 3',
    d: 'Pitching or depitching machines',
  },
  {
    c: 'C12L 9',
    d: 'Venting devices for casks, barrels, or the like',
  },
  {
    c: 'C12L 11',
    d: 'Cellar tools',
  },
  {
    c: 'C12M',
    d: 'APPARATUS FOR ENZYMOLOGY OR MICROBIOLOGY',
  },
  {
    c: 'C12M 1',
    d: 'Apparatus for enzymology or microbiology',
  },
  {
    c: 'C12M 3',
    d: 'Tissue, human, animal or plant cell, or virus culture apparatus',
  },
  {
    c: 'C12N',
    d: 'MICROORGANISMS OR ENZYMES; COMPOSITIONS THEREOF',
  },
  {
    c: 'C12N 1',
    d: 'Microorganisms, e.g. protozoa; Compositions thereof',
  },
  {
    c: 'C12N 3',
    d: 'Spore-forming or isolating processes',
  },
  {
    c: 'C12N 5',
    d: 'Undifferentiated human, animal or plant cells, e.g. cell lines; Tissues; Cultivation or maintenance thereof; Culture media therefor',
  },
  {
    c: 'C12N 7',
    d: 'Viruses, e.g. bacteriophages; Compositions thereof; Preparation or purification thereof',
  },
  {
    c: 'C12N 9',
    d: 'Enzymes, e.g. ligases',
  },
  {
    c: 'C12N 11',
    d: 'Carrier-bound or immobilised enzymes; Carrier-bound or immobilised microbial cells; Preparation thereof',
  },
  {
    c: 'C12N 13',
    d: 'Treatment of microorganisms or enzymes with electrical or wave energy, e.g. magnetism, sonic waves',
  },
  {
    c: 'C12N 15',
    d: 'Mutation or genetic engineering; DNA or RNA concerning genetic engineering, vectors, e.g. plasmids, or their isolation, preparation or purification; Use of hosts therefor',
  },
  {
    c: 'C12P',
    d: 'FERMENTATION OR ENZYME-USING PROCESSES TO SYNTHESISE A DESIRED CHEMICAL COMPOUND OR COMPOSITION OR TO SEPARATE OPTICAL ISOMERS FROM A RACEMIC MIXTURE',
  },
  {
    c: 'C12P 1',
    d: 'Preparation of compounds or compositions, not provided for in groups C12P0003000000-C12P0039000000, by using microorganisms or enzymes; General processes for the preparation of compounds or compositions by using microorganisms or enzymes',
  },
  {
    c: 'C12P 3',
    d: 'Preparation of elements or inorganic compounds except carbon dioxide',
  },
  {
    c: 'C12P 5',
    d: 'Preparation of hydrocarbons',
  },
  {
    c: 'C12P 7',
    d: 'Preparation of oxygen-containing organic compounds',
  },
  {
    c: 'C12P 9',
    d: 'Preparation of organic compounds containing a metal or atom other than H, N, C, O, S, or halogen',
  },
  {
    c: 'C12P 11',
    d: 'Preparation of sulfur-containing organic compounds',
  },
  {
    c: 'C12P 13',
    d: 'Preparation of nitrogen-containing organic compounds',
  },
  {
    c: 'C12P 15',
    d: 'Preparation of compounds containing at least three condensed carbocyclic rings',
  },
  {
    c: 'C12P 17',
    d: 'Preparation of heterocyclic carbon compounds with only O, N, S, Se, or Te as ring hetero atoms',
  },
  {
    c: 'C12P 19',
    d: 'Preparation of compounds containing saccharide radicals',
  },
  {
    c: 'C12P 21',
    d: 'Preparation of peptides or proteins',
  },
  {
    c: 'C12P 23',
    d: 'Preparation of compounds containing a cyclohexene ring having an unsaturated side chain containing at least ten carbon atoms bound by conjugated double bonds, e.g. carotenes',
  },
  {
    c: 'C12P 25',
    d: 'Preparation of compounds containing alloxazine or isoalloxazine nucleus, e.g. riboflavin',
  },
  {
    c: 'C12P 27',
    d: 'Preparation of compounds containing a gibbane ring system, e.g. gibberellin',
  },
  {
    c: 'C12P 29',
    d: 'Preparation of compounds containing a naphthacene ring system, e.g. tetracycline',
  },
  {
    c: 'C12P 31',
    d: 'Preparation of compounds containing a five-membered ring having two side-chains in ortho position to each other, and having at least one oxygen atom directly bound to the ring in ortho position to one of the side-chains, one side-chain containing, not directly bound to the ring, a carbon atom having three bonds to hetero atoms with at the most one bond to halogen, and the other side-chain having at least one oxygen atom bound in gamma-position to the ring, e.g. prostaglandins',
  },
  {
    c: 'C12P 33',
    d: 'Preparation of steroids',
  },
  {
    c: 'C12P 35',
    d: 'Preparation of compounds having a 5-thia-1-azabicyclo [4.2.0] octane ring system, e.g. cephalosporin',
  },
  {
    c: 'C12P 37',
    d: 'Preparation of compounds having a 4-thia-1-azabicyclo [3.2.0] heptane ring system, e.g. penicillin',
  },
  {
    c: 'C12P 39',
    d: 'Processes involving microorganisms of different genera in the same process, simultaneously',
  },
  {
    c: 'C12P 41',
    d: 'Processes using enzymes or microorganisms to separate optical isomers from a racemic mixture',
  },
  {
    c: 'C12Q',
    d: 'MEASURING OR TESTING PROCESSES INVOLVING ENZYMES, NUCLEIC ACIDS OR MICROORGANISMS',
  },
  {
    c: 'C12Q 1',
    d: 'Measuring or testing processes involving enzymes, nucleic acids or microorganisms',
  },
  {
    c: 'C12Q 3',
    d: 'Condition-responsive control processes',
  },
  {
    c: 'C12R',
    d: 'INDEXING SCHEME ASSOCIATED WITH SUBCLASSES C12C-C12Q, RELATING TO MICROORGANISMS',
  },
  {
    c: 'C12R 1',
    d: 'Microorganisms',
  },
  {
    c: 'C13',
    d: 'SUGAR INDUSTRY',
  },
  {
    c: 'C13B',
    d: 'PRODUCTION OF SUCROSE; APPARATUS SPECIALLY ADAPTED THEREFOR',
  },
  {
    c: 'C13B 5',
    d: 'Reducing the size of material from which sugar is to be extracted',
  },
  {
    c: 'C13B 10',
    d: 'Production of sugar juices',
  },
  {
    c: 'C13B 15',
    d: 'Expressing water from material from which sugar has been extracted',
  },
  {
    c: 'C13B 20',
    d: 'Purification of sugar juices',
  },
  {
    c: 'C13B 25',
    d: 'Evaporators or boiling pans specially adapted for sugar juices;  Evaporating or boiling sugar juices',
  },
  {
    c: 'C13B 30',
    d: 'Crystallisation; Crystallising apparatus; Separating crystals from mother liquors',
  },
  {
    c: 'C13B 35',
    d: 'Extraction of sucrose from molasses',
  },
  {
    c: 'C13B 40',
    d: 'Drying sugar',
  },
  {
    c: 'C13B 45',
    d: 'Cutting machines specially adapted for sugar',
  },
  {
    c: 'C13B 50',
    d: 'Sugar products, e.g. powdered, lump or liquid sugar; Working-up of sugar',
  },
  {
    c: 'C13B 99',
    d: 'n.a.',
  },
  {
    c: 'C13K',
    d: 'SACCHARIDES, OTHER THAN SUCROSE, OBTAINED FROM NATURAL SOURCES OR BY HYDROLYSIS OF NATURALLY OCCURRING DI-, OLIGO- OR POLYSACCHARIDES',
  },
  {
    c: 'C13K 1',
    d: 'Glucose',
  },
  {
    c: 'C13K 3',
    d: 'Invert sugar; Separation of glucose or fructose from invert sugar',
  },
  {
    c: 'C13K 5',
    d: 'Lactose',
  },
  {
    c: 'C13K 7',
    d: 'Maltose',
  },
  {
    c: 'C13K 11',
    d: 'Fructose',
  },
  {
    c: 'C13K 13',
    d: 'Sugars not otherwise provided for in this class',
  },
  {
    c: 'C14',
    d: 'SKINS; HIDES; PELTS; LEATHER',
  },
  {
    c: 'C14B',
    d: 'MECHANICAL TREATMENT OR PROCESSING OF SKINS, HIDES, OR LEATHER IN GENERAL; PELT-SHEARING MACHINES; INTESTINE-SPLITTING MACHINES',
  },
  {
    c: 'C14B 1',
    d: 'Manufacture of leather; Machines or devices therefor',
  },
  {
    c: 'C14B 3',
    d: 'Milling leather',
  },
  {
    c: 'C14B 5',
    d: 'Clicking, perforating, or cutting leather',
  },
  {
    c: 'C14B 7',
    d: 'Special leathers or their manufacture',
  },
  {
    c: 'C14B 9',
    d: 'Making driving belts or other leather belts or strips',
  },
  {
    c: 'C14B 11',
    d: 'Finishing the edges of leather pieces, e.g. by folding, by burning',
  },
  {
    c: 'C14B 13',
    d: 'Shredding hides or leather',
  },
  {
    c: 'C14B 15',
    d: 'Mechanical treatment of furs',
  },
  {
    c: 'C14B 17',
    d: 'Details of apparatus or machines for manufacturing or treating skins, hides, leather, or furs',
  },
  {
    c: 'C14B 19',
    d: 'Hand tools specially adapted for the treatment of hides, skins, or leather in the manufacture of leather or furs',
  },
  {
    c: 'C14B 21',
    d: 'Splitting intestines; Cutting intestines longitudinally',
  },
  {
    c: 'C14B 99',
    d: 'n.a.',
  },
  {
    c: 'C14C',
    d: 'TREATING SKINS, HIDES OR LEATHER WITH CHEMICALS, ENZYMES OR MICROORGANISMS, e.g. TANNING, IMPREGNATING OR FINISHING; APPARATUS THEREFOR; COMPOSITIONS FOR TANNING',
  },
  {
    c: 'C14C 1',
    d: 'Treating skins or hides with chemicals, enzymes or microorganisms prior to tanning',
  },
  {
    c: 'C14C 3',
    d: 'Tanning; Compositions for tanning',
  },
  {
    c: 'C14C 5',
    d: 'Degreasing leather',
  },
  {
    c: 'C14C 7',
    d: 'Chemical aspects of pasting processes',
  },
  {
    c: 'C14C 9',
    d: 'Impregnating leather for preserving, waterproofing, making resistant to heat or similar purposes',
  },
  {
    c: 'C14C 11',
    d: 'Surface finishing of leather',
  },
  {
    c: 'C14C 13',
    d: 'Manufacture of special kinds of leather, e.g. vellum',
  },
  {
    c: 'C14C 15',
    d: 'Apparatus for chemical treatment or washing of hides, skins, or leather',
  },
  {
    c: 'C14C 99',
    d: 'n.a.',
  },
  {
    c: 'C21',
    d: 'METALLURGY OF IRON',
  },
  {
    c: 'C21B',
    d: 'MANUFACTURE OF IRON OR STEEL',
  },
  {
    c: 'C21B 3',
    d: 'General features in the manufacture of pig-iron',
  },
  {
    c: 'C21B 5',
    d: 'Making pig-iron in the blast furnace',
  },
  {
    c: 'C21B 7',
    d: 'Blast furnaces',
  },
  {
    c: 'C21B 9',
    d: 'Stoves for heating the blast in blast furnaces',
  },
  {
    c: 'C21B 11',
    d: 'Making pig-iron other than in blast furnaces',
  },
  {
    c: 'C21B 13',
    d: 'Making spongy iron or liquid steel, by direct processes',
  },
  {
    c: 'C21B 15',
    d: 'Other processes for the manufacture of iron from iron compounds',
  },
  {
    c: 'C21C',
    d: 'PROCESSING OF PIG-IRON, e.g. REFINING, MANUFACTURE OF WROUGHT-IRON OR STEEL; TREATMENT IN MOLTEN STATE OF FERROUS ALLOYS',
  },
  {
    c: 'C21C 1',
    d: 'Refining of pig-iron; Cast iron',
  },
  {
    c: 'C21C 3',
    d: 'Manufacture of wrought-iron or wrought-steel',
  },
  {
    c: 'C21C 5',
    d: 'Manufacture of carbon steel, e.g. plain mild steel, medium carbon steel, or cast-steel',
  },
  {
    c: 'C21C 7',
    d: 'Treating molten ferrous alloys, e.g. steel, not covered by groups C21C0001000000-C21C0005000000',
  },
  {
    c: 'C21D',
    d: 'MODIFYING THE PHYSICAL STRUCTURE OF FERROUS METALS; GENERAL DEVICES FOR HEAT TREATMENT OF FERROUS OR NON-FERROUS METALS OR ALLOYS; MAKING METAL MALLEABLE BY DECARBURISATION, TEMPERING, OR OTHER TREATMENTS',
  },
  {
    c: 'C21D 1',
    d: 'General methods or devices for heat treatment, e.g. annealing, hardening, quenching or tempering',
  },
  {
    c: 'C21D 3',
    d: 'Diffusion processes for extraction of non-metals; Furnaces therefor',
  },
  {
    c: 'C21D 5',
    d: 'Heat treatment of cast-iron',
  },
  {
    c: 'C21D 6',
    d: 'Heat treatment of ferrous alloys',
  },
  {
    c: 'C21D 7',
    d: 'Modifying the physical properties of iron or steel by deformation',
  },
  {
    c: 'C21D 8',
    d: 'Modifying the physical properties by deformation combined with, or followed by, heat treatment',
  },
  {
    c: 'C21D 9',
    d: 'Heat treatment, e.g. annealing, hardening, quenching or tempering, adapted for particular articles; Furnaces therefor',
  },
  {
    c: 'C21D 10',
    d: 'Modifying the physical properties by methods other than heat treatment or deformation',
  },
  {
    c: 'C21D 11',
    d: 'Process control or regulation for heat treatments',
  },
  {
    c: 'C22',
    d: 'METALLURGY; FERROUS OR NON-FERROUS ALLOYS; TREATMENT OF ALLOYS OR NON-FERROUS METALS',
  },
  {
    c: 'C22B',
    d: 'PRODUCTION OR REFINING OF METALS',
  },
  {
    c: 'C22B 1',
    d: 'Preliminary treatment of ores or scrap',
  },
  {
    c: 'C22B 3',
    d: 'Extraction of metal compounds from ores or concentrates by wet processes',
  },
  {
    c: 'C22B 4',
    d: 'Electrothermal treatment of ores or metallurgical products for obtaining metals or alloys',
  },
  {
    c: 'C22B 5',
    d: 'General processes of reducing to metals',
  },
  {
    c: 'C22B 7',
    d: 'Working-up raw materials other than ores, e.g. scrap, to produce non-ferrous metals or compounds thereof',
  },
  {
    c: 'C22B 9',
    d: 'General processes of refining or remelting of metals; Apparatus for electroslag or arc remelting of metals',
  },
  {
    c: 'C22B 11',
    d: 'Obtaining noble metals',
  },
  {
    c: 'C22B 13',
    d: 'Obtaining lead',
  },
  {
    c: 'C22B 15',
    d: 'Obtaining copper',
  },
  {
    c: 'C22B 17',
    d: 'Obtaining cadmium',
  },
  {
    c: 'C22B 19',
    d: 'Obtaining zinc or zinc oxide',
  },
  {
    c: 'C22B 21',
    d: 'Obtaining aluminium',
  },
  {
    c: 'C22B 23',
    d: 'Obtaining nickel or cobalt',
  },
  {
    c: 'C22B 25',
    d: 'Obtaining tin',
  },
  {
    c: 'C22B 26',
    d: 'Obtaining alkali, alkaline earth metals or magnesium',
  },
  {
    c: 'C22B 30',
    d: 'Obtaining antimony, arsenic or bismuth',
  },
  {
    c: 'C22B 34',
    d: 'Obtaining refractory metals',
  },
  {
    c: 'C22B 35',
    d: 'Obtaining beryllium',
  },
  {
    c: 'C22B 41',
    d: 'Obtaining germanium',
  },
  {
    c: 'C22B 43',
    d: 'Obtaining mercury',
  },
  {
    c: 'C22B 47',
    d: 'Obtaining manganese',
  },
  {
    c: 'C22B 58',
    d: 'Obtaining gallium or indium',
  },
  {
    c: 'C22B 59',
    d: 'Obtaining rare earth metals',
  },
  {
    c: 'C22B 60',
    d: 'Obtaining metals of atomic number 87 or higher, i.e. radioactive metals',
  },
  {
    c: 'C22B 61',
    d: 'Obtaining metals not elsewhere provided for in this subclass',
  },
  {
    c: 'C22C',
    d: 'ALLOYS',
  },
  {
    c: 'C22C 1',
    d: 'Non-ferrous alloys, i.e. alloys based essentially on metals other than iron',
  },
  {
    c: 'C22C 3',
    d: 'Removing material from non-ferrous alloys to produce alloys of different constitution',
  },
  {
    c: 'C22C 5',
    d: 'Alloys based on noble metals',
  },
  {
    c: 'C22C 7',
    d: 'Alloys based on mercury',
  },
  {
    c: 'C22C 9',
    d: 'Alloys based on copper',
  },
  {
    c: 'C22C 11',
    d: 'Alloys based on lead',
  },
  {
    c: 'C22C 12',
    d: 'Alloys based on antimony or bismuth',
  },
  {
    c: 'C22C 13',
    d: 'Alloys based on tin',
  },
  {
    c: 'C22C 14',
    d: 'Alloys based on titanium',
  },
  {
    c: 'C22C 16',
    d: 'Alloys based on zirconium',
  },
  {
    c: 'C22C 18',
    d: 'Alloys based on zinc',
  },
  {
    c: 'C22C 19',
    d: 'Alloys based on nickel or cobalt',
  },
  {
    c: 'C22C 20',
    d: 'Alloys based on cadmium',
  },
  {
    c: 'C22C 21',
    d: 'Alloys based on aluminium',
  },
  {
    c: 'C22C 22',
    d: 'Alloys based on manganese',
  },
  {
    c: 'C22C 23',
    d: 'Alloys based on magnesium',
  },
  {
    c: 'C22C 24',
    d: 'Alloys based on an alkali or an alkaline earth metal',
  },
  {
    c: 'C22C 25',
    d: 'Alloys based on beryllium',
  },
  {
    c: 'C22C 26',
    d: 'Alloys containing diamond',
  },
  {
    c: 'C22C 27',
    d: 'Alloys based on rhenium or a refractory metal not mentioned in groups C22C0014000000 or C22C0016000000',
  },
  {
    c: 'C22C 28',
    d: 'Alloys based on a metal not provided for in groups C22C0005000000-C22C0027000000',
  },
  {
    c: 'C22C 29',
    d: 'Alloys based on carbides, oxides, borides, nitrides or silicides, e.g. cermets, or other metal compounds, e. g. oxynitrides, sulfides',
  },
  {
    c: 'C22C 30',
    d: 'Alloys containing less than 50% by weight of each constituent',
  },
  {
    c: 'C22C 32',
    d: 'Non-ferrous alloys containing at least 5% by weight but less than 50% by weight of oxides, carbides, borides, nitrides, silicides or other metal compounds, e.g. oxynitrides, sulfides, whether added as such or formed in situ',
  },
  {
    c: 'C22C 33',
    d: 'Ferrous alloys, i.e. alloys based on iron',
  },
  {
    c: 'C22C 35',
    d: 'Master alloys for iron or steel',
  },
  {
    c: 'C22C 37',
    d: 'Cast-iron alloys',
  },
  {
    c: 'C22C 38',
    d: 'Ferrous alloys, e.g. steel alloys',
  },
  {
    c: 'C22C 43',
    d: 'Alloys containing radioactive materials',
  },
  {
    c: 'C22C 45',
    d: 'Amorphous alloys',
  },
  {
    c: 'C22C 47',
    d: 'Alloys containing fibres or filaments',
  },
  {
    c: 'C22C 49',
    d: 'Alloys containing metallic or non-metallic fibres or filaments',
  },
  {
    c: 'C22C 101',
    d: 'Indexing scheme associated with groups C22C0047000000 and C22C0049000000, relating to the nature of the fibrous materials contained in metal-fibrous composites.',
  },
  {
    c: 'C22C 111',
    d: 'Metallic fibres or filaments',
  },
  {
    c: 'C22C 121',
    d: 'Pretreated fibres or filaments',
  },
  {
    c: 'C22F',
    d: 'CHANGING THE PHYSICAL STRUCTURE OF NON-FERROUS METALS OR NON-FERROUS ALLOYS',
  },
  {
    c: 'C22F 1',
    d: 'Changing the physical structure of non-ferrous metals or alloys by heat treatment or by hot or cold working',
  },
  {
    c: 'C22F 3',
    d: 'Changing the physical structure of non-ferrous metals or alloys by special physical methods, e.g. treatment with neutrons',
  },
  {
    c: 'C23',
    d: 'COATING METALLIC MATERIAL; COATING MATERIAL WITH METALLIC MATERIAL; CHEMICAL SURFACE TREATMENT; DIFFUSION TREATMENT OF METALLIC MATERIAL; COATING BY VACUUM EVAPORATION, BY SPUTTERING, BY ION IMPLANTATION OR BY CHEMICAL VAPOUR DEPOSITION, IN GENERAL; INHIBITING CORROSION OF METALLIC MATERIAL OR INCRUSTATION IN GENERAL',
  },
  {
    c: 'C23C',
    d: 'COATING METALLIC MATERIAL; COATING MATERIAL WITH METALLIC MATERIAL; SURFACE TREATMENT OF METALLIC MATERIAL BY DIFFUSION INTO THE SURFACE, BY CHEMICAL CONVERSION OR SUBSTITUTION; COATING BY VACUUM EVAPORATION, BY SPUTTERING, BY ION IMPLANTATION OR BY CHEMICAL VAPOUR DEPOSITION, IN GENERAL',
  },
  {
    c: 'C23C 2',
    d: 'Coating by applying the coating material in the molten state',
  },
  {
    c: 'C23C 4',
    d: 'Coating by spraying the coating material in the molten state, e.g. by flame, plasma or electric discharge',
  },
  {
    c: 'C23C 6',
    d: 'Coating by casting molten material on the substrate',
  },
  {
    c: 'C23C 8',
    d: 'Solid state diffusion into metallic material surfaces',
  },
  {
    c: 'C23C 10',
    d: 'Solid state diffusion of only metal elements or silicon into metallic material surfaces',
  },
  {
    c: 'C23C 12',
    d: 'Solid state diffusion of at least one non-metal element other than silicon and at least one metal element or silicon into metallic material surfaces',
  },
  {
    c: 'C23C 14',
    d: 'Coating by vacuum evaporation, by sputtering or by ion implantation',
  },
  {
    c: 'C23C 16',
    d: 'Chemical deposition or plating by decomposition; Contact plating',
  },
  {
    c: 'C23C 18',
    d: 'Chemical coating by decomposition of either liquid compounds or solutions of the coating forming compounds, without leaving reaction products of surface material in the coating; Contact plating',
  },
  {
    c: 'C23C 20',
    d: 'Chemical coating by decomposition of either solid compounds or suspensions of the coating forming compounds, without leaving reaction products of surface material in the coating',
  },
  {
    c: 'C23C 22',
    d: 'Chemical surface treatment of metallic material by reaction of the surface with a reactive liquid, leaving reaction products of surface material in the coating, e.g. conversion coatings, passivation of metals',
  },
  {
    c: 'C23C 24',
    d: 'Coating starting from inorganic powder',
  },
  {
    c: 'C23C 26',
    d: 'Coating not provided for in groups C23C0002000000-C23C0024000000',
  },
  {
    c: 'C23C 28',
    d: 'Coating for obtaining at least two superposed coatings either by methods not provided for in a single one of main groups C23C0002000000-C23C0026000000, or by combinations of methods provided for in subclasses C23C and C25D',
  },
  {
    c: 'C23C 30',
    d: 'Coating with metallic material characterised only by the composition of the metallic material, i.e. not characterised by the coating process',
  },
  {
    c: 'C23D',
    d: 'ENAMELLING OF, OR APPLYING A VITREOUS LAYER TO, METALS',
  },
  {
    c: 'C23D 1',
    d: 'Melting or fritting the enamels; Apparatus or furnaces therefor',
  },
  {
    c: 'C23D 3',
    d: 'Coating with the enamels',
  },
  {
    c: 'C23D 5',
    d: 'Coating with enamels or vitreous layers',
  },
  {
    c: 'C23D 7',
    d: 'Treating the coatings, e.g. drying before burning',
  },
  {
    c: 'C23D 9',
    d: 'Firing the enamels',
  },
  {
    c: 'C23D 11',
    d: 'Continuous processes for firing enamels; Apparatus therefor',
  },
  {
    c: 'C23D 13',
    d: 'After-treatment',
  },
  {
    c: 'C23D 15',
    d: 'Joining enamelled articles to other enamelled articles by processes involving an enamelling step',
  },
  {
    c: 'C23D 17',
    d: 'De-enamelling',
  },
  {
    c: 'C23F',
    d: 'NON-MECHANICAL REMOVAL OF METALLIC MATERIAL FROM SURFACES',
  },
  {
    c: 'C23F 1',
    d: 'Etching metallic material by chemical means',
  },
  {
    c: 'C23F 3',
    d: 'Brightening metals by chemical means',
  },
  {
    c: 'C23F 4',
    d: 'Processes for removing metallic material from surfaces, not provided for in group C23F0001000000 or C23F0003000000',
  },
  {
    c: 'C23F 11',
    d: 'Inhibiting corrosion of metallic material by applying inhibitors to the surface in danger of corrosion or adding them to the corrosive agent',
  },
  {
    c: 'C23F 13',
    d: 'Inhibiting corrosion of metals by anodic or cathodic protection',
  },
  {
    c: 'C23F 14',
    d: 'Inhibiting incrustation in apparatus for heating liquids for physical or chemical purposes',
  },
  {
    c: 'C23F 15',
    d: 'Other methods of preventing corrosion or incrustation',
  },
  {
    c: 'C23F 17',
    d: 'Multi-step processes for surface treatment of metallic material involving at least one process provided for in class C23 and at least one process covered by subclass C21D or C22F or class C25',
  },
  {
    c: 'C23G',
    d: 'CLEANING OR DE-GREASING OF METALLIC MATERIAL BY CHEMICAL METHODS OTHER THAN ELECTROLYSIS',
  },
  {
    c: 'C23G 1',
    d: 'Cleaning or pickling metallic material with solutions or molten salts',
  },
  {
    c: 'C23G 3',
    d: 'Apparatus for cleaning or pickling metallic material',
  },
  {
    c: 'C23G 5',
    d: 'Cleaning or de-greasing metallic material by other methods; Apparatus for cleaning or de-greasing metallic material with organic solvents',
  },
  {
    c: 'C25',
    d: 'ELECTROLYTIC OR ELECTROPHORETIC PROCESSES; APPARATUS THEREFOR',
  },
  {
    c: 'C25B',
    d: 'ELECTROLYTIC OR ELECTROPHORETIC PROCESSES FOR THE PRODUCTION OF COMPOUNDS OR NON- METALS; APPARATUS THEREFOR',
  },
  {
    c: 'C25B 1',
    d: 'Electrolytic production of inorganic compounds or non-metals',
  },
  {
    c: 'C25B 3',
    d: 'Electrolytic production of organic compounds',
  },
  {
    c: 'C25B 5',
    d: 'Electrogenerative processes, i.e. processes for producing compounds in which simultaneously electricity is generated',
  },
  {
    c: 'C25B 7',
    d: 'Electrophoretic production of compounds or non-metals',
  },
  {
    c: 'C25B 9',
    d: 'Cells or assemblies of cells; Constructional parts of cells; Assemblies of constructional parts, e.g. electrode-diaphragm assemblies',
  },
  {
    c: 'C25B 11',
    d: 'Electrodes; Manufacture thereof not otherwise provided for',
  },
  {
    c: 'C25B 13',
    d: 'Diaphragms; Spacing elements',
  },
  {
    c: 'C25B 15',
    d: 'Operating or servicing of cells',
  },
  {
    c: 'C25C',
    d: 'PROCESSES FOR THE ELECTROLYTIC PRODUCTION, RECOVERY OR REFINING OF METALS; APPARATUS THEREFOR',
  },
  {
    c: 'C25C 1',
    d: 'Electrolytic production, recovery or refining of metals by electrolysis of solutions',
  },
  {
    c: 'C25C 3',
    d: 'Electrolytic production, recovery or refining of metals by electrolysis of melts',
  },
  {
    c: 'C25C 5',
    d: 'Electrolytic production, recovery or refining of metal powders or porous metal masses',
  },
  {
    c: 'C25C 7',
    d: 'Constructional parts, or assemblies thereof, of cells; Servicing or operating of cells',
  },
  {
    c: 'C25D',
    d: 'PROCESSES FOR THE ELECTROLYTIC OR ELECTROPHORETIC PRODUCTION OF COATINGS; ELECTROFORMING',
  },
  {
    c: 'C25D 1',
    d: 'Electroforming',
  },
  {
    c: 'C25D 2',
    d: 'Joining workpieces by electrolysis',
  },
  {
    c: 'C25D 3',
    d: 'Electroplating; Baths therefor',
  },
  {
    c: 'C25D 5',
    d: 'Electroplating characterised by the process; Pretreatment or after-treatment of workpieces',
  },
  {
    c: 'C25D 7',
    d: 'Electroplating characterised by the article coated',
  },
  {
    c: 'C25D 9',
    d: 'Electrolytic coating other than with metals',
  },
  {
    c: 'C25D 11',
    d: 'Electrolytic coating by surface reaction, i.e. forming conversion layers',
  },
  {
    c: 'C25D 13',
    d: 'Electrophoretic coating characterised by the process',
  },
  {
    c: 'C25D 15',
    d: 'Electrolytic or electrophoretic production of coatings containing embedded materials, e.g. particles, whiskers, wires',
  },
  {
    c: 'C25D 17',
    d: 'Constructional parts, or assemblies thereof, of cells for electrolytic coating',
  },
  {
    c: 'C25D 19',
    d: 'Electrolytic coating plants',
  },
  {
    c: 'C25D 21',
    d: 'Processes for servicing or operating cells for electrolytic coating',
  },
  {
    c: 'C25F',
    d: 'PROCESSES FOR THE ELECTROLYTIC REMOVAL OF MATERIALS FROM OBJECTS; APPARATUS THEREFOR',
  },
  {
    c: 'C25F 1',
    d: 'Electrolytic cleaning, degreasing, pickling, or descaling',
  },
  {
    c: 'C25F 3',
    d: 'Electrolytic etching or polishing',
  },
  {
    c: 'C25F 5',
    d: 'Electrolytic stripping of metallic layers or coatings',
  },
  {
    c: 'C25F 7',
    d: 'Constructional parts, or assemblies thereof, of cells for electrolytic removal of material from objects',
  },
  {
    c: 'C30',
    d: 'CRYSTAL GROWTH',
  },
  {
    c: 'C30B',
    d: 'SINGLE-CRYSTAL GROWTH',
  },
  {
    c: 'C30B 1',
    d: 'Single-crystal growth from solids or gels',
  },
  {
    c: 'C30B 3',
    d: 'Unidirectional demixing of eutectoid materials',
  },
  {
    c: 'C30B 5',
    d: 'Single-crystal growth from gels',
  },
  {
    c: 'C30B 7',
    d: 'Single-crystal growth from liquids; Unidirectional solidification of eutectic materials',
  },
  {
    c: 'C30B 9',
    d: 'Single-crystal growth from melt solutions using molten solvents',
  },
  {
    c: 'C30B 11',
    d: 'Single-crystal-growth by normal freezing or freezing under temperature gradient, e.g. Bridgman- Stockbarger method',
  },
  {
    c: 'C30B 13',
    d: 'Single-crystal growth by zone-melting; Refining by zone-melting',
  },
  {
    c: 'C30B 15',
    d: 'Single-crystal growth by pulling from a melt, e.g. Czochralski method',
  },
  {
    c: 'C30B 17',
    d: 'Single-crystal growth on to a seed which remains in the melt during growth, e.g. Nacken-Kyropoulos method',
  },
  {
    c: 'C30B 19',
    d: 'Liquid-phase epitaxial-layer growth',
  },
  {
    c: 'C30B 21',
    d: 'Unidirectional solidification of eutectic materials',
  },
  {
    c: 'C30B 23',
    d: 'Single-crystal growth from vapours',
  },
  {
    c: 'C30B 25',
    d: 'Single-crystal growth by chemical reaction of reactive gases, e.g. chemical vapour deposition growth',
  },
  {
    c: 'C30B 27',
    d: 'Single-crystal growth under a protective fluid',
  },
  {
    c: 'C30B 28',
    d: 'Production of homogeneous polycrystalline material with defined structure',
  },
  {
    c: 'C30B 29',
    d: 'Single crystals or homogeneous polycrystalline material with defined structure characterised by the material or by their shape',
  },
  {
    c: 'C30B 30',
    d: 'Production of single crystals or homogeneous polycrystalline material with defined structure characterised by the action of electric or magnetic fields, wave energy or other specific physical conditions',
  },
  {
    c: 'C30B 31',
    d: 'After-treatment of single crystals or homogeneous polycrystalline material with defined structure',
  },
  {
    c: 'C30B 33',
    d: 'After-treatment of single crystals or homogeneous polycrystalline material with defined structure',
  },
  {
    c: 'C30B 35',
    d: 'Apparatus not otherwise provided for, specially adapted for the growth, production or after-treatment of single crystals or of a homogeneous polycrystalline material with defined structure',
  },
  {
    c: 'C40',
    d: 'COMBINATORIAL TECHNOLOGY',
  },
  {
    c: 'C40B',
    d: 'COMBINATORIAL CHEMISTRY; LIBRARIES, e.g. CHEMICAL LIBRARIES, IN SILICO LIBRARIES',
  },
  {
    c: 'C40B 10',
    d: 'Directed molecular evolution of macromolecules, e.g. RNA, DNA or proteins',
  },
  {
    c: 'C40B 20',
    d: 'Methods specially adapted for identifying library members',
  },
  {
    c: 'C40B 30',
    d: 'Methods of screening libraries',
  },
  {
    c: 'C40B 40',
    d: 'Libraries per se, e.g. arrays, mixtures',
  },
  {
    c: 'C40B 50',
    d: 'Methods of creating libraries, e.g. combinatorial synthesis',
  },
  {
    c: 'C40B 60',
    d: 'Apparatus specially adapted for use in combinatorial chemistry or with libraries',
  },
  {
    c: 'C40B 70',
    d: 'Tags or labels specially adapted for combinatorial chemistry or libraries, e.g. fluorescent tags or barcodes',
  },
  {
    c: 'C40B 80',
    d: 'Linkers or spacers specially adapted for combinatorial chemistry or libraries, e.g. traceless linkers or safety-catch linkers',
  },
  {
    c: 'C40B 99',
    d: 'n.a.',
  },
  {
    c: 'C99',
    d: 'n.a.',
  },
  {
    c: 'C99Z',
    d: 'n.a.',
  },
  {
    c: 'C99Z 99',
    d: 'n.a.',
  },
  {
    c: 'D',
    d: 'TEXTILES; PAPER',
  },
  {
    c: 'D01',
    d: 'NATURAL OR MAN-MADE THREADS OR FIBRES; SPINNING',
  },
  {
    c: 'D01B',
    d: 'MECHANICAL TREATMENT OF NATURAL FIBROUS OR FILAMENTARY MATERIAL TO OBTAIN FIBRES OR FILAMENTS, e.g. FOR SPINNING',
  },
  {
    c: 'D01B 1',
    d: 'Mechanical separation of fibres from plant material, e.g. seeds, leaves, stalks',
  },
  {
    c: 'D01B 3',
    d: 'Mechanical removal of impurities from animal fibres',
  },
  {
    c: 'D01B 5',
    d: 'Hackling or heckling machines',
  },
  {
    c: 'D01B 7',
    d: 'Obtaining silk fibres or filaments',
  },
  {
    c: 'D01B 9',
    d: 'Other mechanical treatment of natural fibrous or filamentary material to obtain fibres or filaments',
  },
  {
    c: 'D01C',
    d: 'CHEMICAL OR BIOLOGICAL TREATMENT OF NATURAL FILAMENTARY OR FIBROUS MATERIAL TO OBTAIN FILAMENTS OR FIBRES FOR SPINNING; CARBONISING RAGS TO RECOVER ANIMAL FIBRES',
  },
  {
    c: 'D01C 1',
    d: 'Treatment of vegetable material',
  },
  {
    c: 'D01C 3',
    d: 'Treatment of animal material, e.g. chemical scouring of wool',
  },
  {
    c: 'D01C 5',
    d: 'Carbonising rags to recover animal fibres',
  },
  {
    c: 'D01D',
    d: 'MECHANICAL METHODS OR APPARATUS IN THE MANUFACTURE OF MAN-MADE FILAMENTS, THREADS, FIBRES, BRISTLES OR RIBBONS',
  },
  {
    c: 'D01D 1',
    d: 'Treatment of filament-forming or like material',
  },
  {
    c: 'D01D 4',
    d: 'Spinnerette packs; Cleaning thereof',
  },
  {
    c: 'D01D 5',
    d: 'Formation of filaments, threads, or the like',
  },
  {
    c: 'D01D 7',
    d: 'Collecting the newly-spun products',
  },
  {
    c: 'D01D 10',
    d: 'Physical treatment of man-made filaments or the like during manufacture, i.e. during a continuous production process before the filaments have been collected',
  },
  {
    c: 'D01D 11',
    d: 'Other features of manufacture',
  },
  {
    c: 'D01D 13',
    d: 'Complete machines for producing man-made threads',
  },
  {
    c: 'D01F',
    d: 'CHEMICAL FEATURES IN THE MANUFACTURE OF MAN-MADE FILAMENTS, THREADS, FIBRES, BRISTLES OR RIBBONS; APPARATUS SPECIALLY ADAPTED FOR THE MANUFACTURE OF CARBON FILAMENTS',
  },
  {
    c: 'D01F 1',
    d: 'General methods for the manufacture of man-made filaments or the like',
  },
  {
    c: 'D01F 2',
    d: 'Monocomponent artificial filaments or the like of cellulose or cellulose derivatives; Manufacture thereof',
  },
  {
    c: 'D01F 4',
    d: 'Monocomponent artificial filaments or the like of proteins; Manufacture thereof',
  },
  {
    c: 'D01F 6',
    d: 'Monocomponent man-made filaments or the like of synthetic polymers; Manufacture thereof',
  },
  {
    c: 'D01F 8',
    d: 'Conjugated, i.e. bi- or multicomponent, man-made filaments or the like; Manufacture thereof',
  },
  {
    c: 'D01F 9',
    d: 'Man-made filaments or the like of other substances; Manufacture thereof; Apparatus specially adapted for the manufacture of carbon filaments',
  },
  {
    c: 'D01F 11',
    d: 'Chemical after-treatment of man-made filaments or the like during manufacture',
  },
  {
    c: 'D01F 13',
    d: 'Recovery of starting material, waste material or solvents during the manufacture of man-made filaments or the like',
  },
  {
    c: 'D01G',
    d: 'PRELIMINARY TREATMENT OF FIBRES, e.g. FOR SPINNING',
  },
  {
    c: 'D01G 1',
    d: 'Severing continuous filaments or long fibres, e.g. stapling',
  },
  {
    c: 'D01G 3',
    d: 'Roughening of fibres',
  },
  {
    c: 'D01G 5',
    d: 'Separating, e.g. sorting, fibres',
  },
  {
    c: 'D01G 7',
    d: 'Breaking or opening fibre bales',
  },
  {
    c: 'D01G 9',
    d: 'Opening or cleaning fibres, e.g. scutching cotton',
  },
  {
    c: 'D01G 11',
    d: 'Disintegrating fibre-containing articles to obtain fibres for re-use',
  },
  {
    c: 'D01G 13',
    d: 'Mixing, e.g. blending, fibres; Mixing non-fibrous materials with fibres',
  },
  {
    c: 'D01G 15',
    d: 'Carding machines or accessories; Card clothing; Burr-crushing or removing arrangements associated with carding or other preliminary-treatment machines',
  },
  {
    c: 'D01G 17',
    d: 'Silk-dressing machines',
  },
  {
    c: 'D01G 19',
    d: 'Combing machines',
  },
  {
    c: 'D01G 21',
    d: 'Combinations of machines, apparatus, or processes, e.g. for continuous processing',
  },
  {
    c: 'D01G 23',
    d: 'Feeding fibres to machines; Conveying fibres between machines',
  },
  {
    c: 'D01G 25',
    d: 'Lap-forming devices not integral with machines specified above',
  },
  {
    c: 'D01G 27',
    d: 'Lap- or sliver-winding devices, e.g. for products of cotton scutchers, jute cards, or worsted gill boxes',
  },
  {
    c: 'D01G 29',
    d: 'Arrangements for lubricating fibres, e.g. in gill boxes',
  },
  {
    c: 'D01G 31',
    d: 'Warning or safety devices, e.g. automatic fault detectors, stop motions',
  },
  {
    c: 'D01G 33',
    d: 'Hand tools for treatment of fibres',
  },
  {
    c: 'D01G 35',
    d: 'Treatment of oakum',
  },
  {
    c: 'D01G 99',
    d: 'n.a.',
  },
  {
    c: 'D01H',
    d: 'SPINNING OR TWISTING',
  },
  {
    c: 'D01H 1',
    d: 'Kinds or types of spinning or twisting machines; Drafting machines or arrangements; Twisting arrangements',
  },
  {
    c: 'D01H 3',
    d: 'Spinning or twisting machines in which the product is wound-up intermittently, e.g. mules',
  },
  {
    c: 'D01H 4',
    d: 'Open-end spinning machines or arrangements for imparting twist to independently moving fibres separated from slivers; Piecing arrangements therefor; Covering endless core threads with fibres by open-end spinning techniques',
  },
  {
    c: 'D01H 5',
    d: 'Drafting machines or arrangements',
  },
  {
    c: 'D01H 7',
    d: 'Spinning or twisting arrangements',
  },
  {
    c: 'D01H 9',
    d: 'Common features or details of, or accessories for, spinning or twisting machines of various kinds or types',
  },
  {
    c: 'D01H 11',
    d: 'Arrangements for confining or removing dust, fly, or the like',
  },
  {
    c: 'D01H 13',
    d: 'Other common constructional features, details, or accessories',
  },
  {
    c: 'D01H 15',
    d: 'Piecing arrangements',
  },
  {
    c: 'D01H 17',
    d: 'Hand tools',
  },
  {
    c: 'D02',
    d: 'YARNS; MECHANICAL FINISHING OF YARNS OR ROPES; WARPING OR BEAMING',
  },
  {
    c: 'D02G',
    d: 'CRIMPING OR CURLING FIBRES, FILAMENTS, YARNS, OR THREADS; YARNS OR THREADS',
  },
  {
    c: 'D02G 1',
    d: 'Producing crimped or curled fibres, filaments, yarns or threads, giving them latent characteristics',
  },
  {
    c: 'D02G 3',
    d: 'Yarns or threads, e.g. fancy yarns; Processes or apparatus for the production thereof, not otherwise provided for',
  },
  {
    c: 'D02H',
    d: 'WARPING, BEAMING, OR LEASING',
  },
  {
    c: 'D02H 1',
    d: 'Creels, i.e. apparatus for supplying a multiplicity of individual threads',
  },
  {
    c: 'D02H 3',
    d: 'Warping machines',
  },
  {
    c: 'D02H 5',
    d: 'Beaming machines',
  },
  {
    c: 'D02H 7',
    d: 'Combined warping and beaming machines',
  },
  {
    c: 'D02H 9',
    d: 'Leasing',
  },
  {
    c: 'D02H 11',
    d: 'Methods or apparatus not provided for in groups D02H0001000000-D02H0009000000, e.g. for cleaning the warp',
  },
  {
    c: 'D02H 13',
    d: 'Details of machines of the other groups of this subclass',
  },
  {
    c: 'D02J',
    d: 'FINISHING OR DRESSING OF FILAMENTS, YARNS, THREADS, CORDS, ROPES, OR THE LIKE',
  },
  {
    c: 'D02J 1',
    d: 'Modifying the structure or properties resulting from a particular structure; Modifying, retaining, or restoring the physical form or cross-sectional shape, e.g. by use of dies or squeeze rollers',
  },
  {
    c: 'D02J 3',
    d: 'Modifying the surface',
  },
  {
    c: 'D02J 7',
    d: 'Cleaning, e.g. removing dust, loose fibres, charred ends',
  },
  {
    c: 'D02J 11',
    d: 'Combinations, not covered by any one of groups D02J0001000000-D02J0007000000, of processes provided for in such groups; Plant for carrying-out such combinations of processes',
  },
  {
    c: 'D02J 13',
    d: 'Heating or cooling the yarn, thread, cord, rope, or the like, not specific to any one of the processes provided for in this subclass',
  },
  {
    c: 'D03',
    d: 'WEAVING',
  },
  {
    c: 'D03C',
    d: 'SHEDDING MECHANISMS; PATTERN CARDS OR CHAINS; PUNCHING OF CARDS; DESIGNING PATTERNS',
  },
  {
    c: 'D03C 1',
    d: 'Dobbies',
  },
  {
    c: 'D03C 3',
    d: 'Jacquards',
  },
  {
    c: 'D03C 5',
    d: 'Cam or other direct-acting shedding mechanisms, i.e. operating heald frames without intervening power-supplying devices',
  },
  {
    c: 'D03C 7',
    d: 'Leno or similar shedding mechanisms',
  },
  {
    c: 'D03C 9',
    d: 'Healds; Heald frames',
  },
  {
    c: 'D03C 11',
    d: 'Selvedge shedding mechanisms not forming part of main shedding mechanism',
  },
  {
    c: 'D03C 13',
    d: 'Shedding mechanisms not otherwise provided for',
  },
  {
    c: 'D03C 15',
    d: 'Pattern cards or chains',
  },
  {
    c: 'D03C 17',
    d: 'Card-punching apparatus',
  },
  {
    c: 'D03C 19',
    d: 'Methods or devices concerned with designing or making patterns, not provided for in other groups of this subclass',
  },
  {
    c: 'D03D',
    d: 'WOVEN FABRICS; METHODS OF WEAVING; LOOMS',
  },
  {
    c: 'D03D 1',
    d: 'Woven fabrics; Methods of weaving other than those characterised by the operation of a particular loom',
  },
  {
    c: 'D03D 3',
    d: 'Woven fabrics characterised by their shape',
  },
  {
    c: 'D03D 5',
    d: 'Selvedges',
  },
  {
    c: 'D03D 7',
    d: 'Woven fabrics designed to be resilient, i.e. to recover from compressive stress',
  },
  {
    c: 'D03D 9',
    d: 'Open-work fabrics',
  },
  {
    c: 'D03D 11',
    d: 'Double or multi-ply fabrics not otherwise provided for',
  },
  {
    c: 'D03D 13',
    d: 'Woven fabrics characterised by the special disposition of the warp or weft threads, e.g. with curved weft threads, with discontinuous warp threads, with diagonal warp or weft',
  },
  {
    c: 'D03D 15',
    d: 'Woven fabrics characterised by the material or construction of the yarn or other warp or weft elements used',
  },
  {
    c: 'D03D 17',
    d: 'Woven fabrics having elastic or stretch properties due to manner of weaving',
  },
  {
    c: 'D03D 19',
    d: 'Gauze or leno-woven fabrics',
  },
  {
    c: 'D03D 21',
    d: 'Lappet- or swivel-woven fabrics',
  },
  {
    c: 'D03D 23',
    d: 'General weaving methods not special to the production of any particular woven fabric or the use of any particular loom; Weaves not provided for in any other single group',
  },
  {
    c: 'D03D 25',
    d: 'Woven fabrics not otherwise provided for',
  },
  {
    c: 'D03D 27',
    d: 'Woven pile fabrics',
  },
  {
    c: 'D03D 29',
    d: 'Looms; Methods of weaving characterised by the operation of particular looms',
  },
  {
    c: 'D03D 31',
    d: 'Lappet, swivel or other looms for forming embroidery-like decoration on fabrics',
  },
  {
    c: 'D03D 33',
    d: 'Multiple looms, i.e. two or more looms assembled together, whether or not they have mechanisms in common',
  },
  {
    c: 'D03D 35',
    d: 'Smallware looms, i.e. looms for weaving ribbons or other narrow fabrics',
  },
  {
    c: 'D03D 37',
    d: 'Circular looms',
  },
  {
    c: 'D03D 39',
    d: 'Pile-fabric looms',
  },
  {
    c: 'D03D 41',
    d: 'Looms not otherwise provided for, e.g. for weaving chenille yarn; Details peculiar to these looms',
  },
  {
    c: 'D03D 43',
    d: 'Looms with change-boxes',
  },
  {
    c: 'D03D 45',
    d: 'Looms with automatic weft replenishment',
  },
  {
    c: 'D03D 47',
    d: 'Looms in which bulk supply of weft does not pass through shed, e.g. shuttleless looms, gripper shuttle looms, dummy shuttle looms',
  },
  {
    c: 'D03D 49',
    d: 'Details or constructional features not peculiar to looms of a particular type',
  },
  {
    c: 'D03D 51',
    d: 'Driving, starting, or stopping arrangements; Automatic stop motions',
  },
  {
    c: 'D03J',
    d: "AUXILIARY WEAVING APPARATUS; WEAVERS' TOOLS; SHUTTLES",
  },
  {
    c: 'D03J 1',
    d: 'Auxiliary apparatus combined with or associated with looms',
  },
  {
    c: 'D03J 3',
    d: "Weavers' tools, e.g. knot-tying tools",
  },
  {
    c: 'D03J 5',
    d: 'Shuttles',
  },
  {
    c: 'D04',
    d: 'BRAIDING; LACE-MAKING; KNITTING; TRIMMINGS; NON-WOVEN FABRICS',
  },
  {
    c: 'D04B',
    d: 'KNITTING',
  },
  {
    c: 'D04B 1',
    d: 'Weft knitting; Machines therefor',
  },
  {
    c: 'D04B 3',
    d: 'Hand tools or implements for weft knitting',
  },
  {
    c: 'D04B 5',
    d: 'Weft knitting apparatus or machines without needles for domestic use',
  },
  {
    c: 'D04B 7',
    d: 'Flat-bed knitting machines with independently-movable needles',
  },
  {
    c: 'D04B 9',
    d: 'Circular weft knitting machines with independently-movable needles',
  },
  {
    c: 'D04B 11',
    d: 'Straight-bar knitting machines with fixed needles',
  },
  {
    c: 'D04B 13',
    d: 'Circular weft knitting machines with fixed spring or bearded needles, e.g. loop-wheel machines',
  },
  {
    c: 'D04B 15',
    d: 'Details of, or auxiliary devices incorporated in, weft knitting machines, restricted to machines of this kind',
  },
  {
    c: 'D04B 17',
    d: 'Repairing or unravelling knitted fabrics',
  },
  {
    c: 'D04B 19',
    d: 'Unravelling knitted fabrics',
  },
  {
    c: 'D04B 21',
    d: 'Warp knitting; Machines therefor',
  },
  {
    c: 'D04B 23',
    d: 'Flat warp knitting machines',
  },
  {
    c: 'D04B 25',
    d: 'Warp knitting machines not otherwise provided for',
  },
  {
    c: 'D04B 27',
    d: 'Details of, or auxiliary devices incorporated in, warp knitting machines, restricted to machines of this kind',
  },
  {
    c: 'D04B 31',
    d: 'Crocheting; Apparatus therefor',
  },
  {
    c: 'D04B 33',
    d: 'Crocheting tools or apparatus',
  },
  {
    c: 'D04B 35',
    d: 'Details of, or auxiliary devices incorporated in, knitting machines, not otherwise provided for',
  },
  {
    c: 'D04B 37',
    d: 'Auxiliary apparatus or devices for use with knitting machines',
  },
  {
    c: 'D04B 39',
    d: 'Knitting processes, apparatus or machines, not otherwise provided for',
  },
  {
    c: 'D04C',
    d: 'BRAIDING OR MANUFACTURE OF LACE, INCLUDING BOBBIN-NET OR CARBONISED LACE; BRAIDING MACHINES; BRAID; LACE',
  },
  {
    c: 'D04C 1',
    d: 'Braid or lace, e.g. pillow-lace; Processes for the manufacture thereof',
  },
  {
    c: 'D04C 3',
    d: 'Braiding or lacing machines',
  },
  {
    c: 'D04C 5',
    d: 'Twist or bobbin-net lace-making machines',
  },
  {
    c: 'D04C 7',
    d: 'Tools or apparatus for braiding or lacing by hand',
  },
  {
    c: 'D04D',
    d: 'TRIMMINGS; RIBBONS, TAPES, OR BANDS, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'D04D 1',
    d: 'Ropes or like decorative or ornamental elongated trimmings made from filamentary material',
  },
  {
    c: 'D04D 3',
    d: 'Chenille trimmings',
  },
  {
    c: 'D04D 5',
    d: 'Fringes',
  },
  {
    c: 'D04D 7',
    d: 'Decorative or ornamental textile articles',
  },
  {
    c: 'D04D 9',
    d: 'Ribbons, tapes, welts, bands, beadings, or other decorative or ornamental strips, not otherwise provided for',
  },
  {
    c: 'D04D 11',
    d: 'Ribbon-threading apparatus or devices',
  },
  {
    c: 'D04G',
    d: 'MAKING NETS BY KNOTTING OF FILAMENTARY MATERIAL; MAKING KNOTTED CARPETS OR TAPESTRIES; KNOTTING NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'D04G 1',
    d: 'Making nets by knotting of filamentary material',
  },
  {
    c: 'D04G 3',
    d: 'Making knotted carpets or tapestries',
  },
  {
    c: 'D04G 5',
    d: 'Knotting not otherwise provided for',
  },
  {
    c: 'D04H',
    d: 'MAKING TEXTILE FABRICS, e.g. FROM FIBRES OR FILAMENTARY MATERIAL',
  },
  {
    c: 'D04H 1',
    d: 'Kinds or types of non-woven fabrics; Apparatus or processes for making such products',
  },
  {
    c: 'D04H 3',
    d: 'Non woven fabrics formed wholly or mainly of yarns or like filamentary material of substantial length',
  },
  {
    c: 'D04H 5',
    d: 'Non woven fabrics formed of mixtures of relatively short fibres and yarns or like filamentary material of substantial length',
  },
  {
    c: 'D04H 11',
    d: 'Non-woven pile fabrics',
  },
  {
    c: 'D04H 13',
    d: 'Other non-woven fabrics',
  },
  {
    c: 'D04H 17',
    d: 'Felting apparatus',
  },
  {
    c: 'D04H 18',
    d: 'Needling machines',
  },
  {
    c: 'D05',
    d: 'SEWING; EMBROIDERING; TUFTING',
  },
  {
    c: 'D05B',
    d: 'SEWING',
  },
  {
    c: 'D05B 1',
    d: 'General types of sewing apparatus or machines without mechanism for lateral movement of the needle or the work or both',
  },
  {
    c: 'D05B 3',
    d: 'Special-purpose or automatic sewing apparatus or machines',
  },
  {
    c: 'D05B 5',
    d: 'Sewing machines for temporarily connecting articles, e.g. pairs of socks',
  },
  {
    c: 'D05B 7',
    d: 'Linking machines, e.g. for joining knitted fabrics',
  },
  {
    c: 'D05B 9',
    d: 'Hem-stitch sewing machines',
  },
  {
    c: 'D05B 11',
    d: 'Machines for sewing quilts or mattresses',
  },
  {
    c: 'D05B 13',
    d: 'Machines for sewing sacks',
  },
  {
    c: 'D05B 15',
    d: 'Machines for sewing leather goods',
  },
  {
    c: 'D05B 17',
    d: 'Sewing machines for concurrently making thread and welded seams',
  },
  {
    c: 'D05B 19',
    d: 'Programme-controlled sewing machines',
  },
  {
    c: 'D05B 21',
    d: 'Sewing machines with devices for automatically controlling movement of work-carrier relative to stitch-forming mechanism in order to obtain particular configuration of seam, e.g. programme-controlled for sewing collars, for attaching pockets',
  },
  {
    c: 'D05B 23',
    d: 'Sewing apparatus or machines not otherwise provided for',
  },
  {
    c: 'D05B 25',
    d: 'Sewing units consisting of combinations of several sewing machines',
  },
  {
    c: 'D05B 27',
    d: 'Sewing-machine elements for feeding, positioning, handling, or treating the work',
  },
  {
    c: 'D05B 29',
    d: 'Pressers or presser feet for sewing machines',
  },
  {
    c: 'D05B 31',
    d: 'Workpiece holders or hold-downs in machines for sewing leather',
  },
  {
    c: 'D05B 33',
    d: 'Devices incorporated in sewing machines for supplying or removing the work',
  },
  {
    c: 'D05B 35',
    d: 'Work-feeding or work-handling elements for sewing machines, not otherwise provided for',
  },
  {
    c: 'D05B 37',
    d: 'Devices incorporated in sewing machines for slitting, grooving, or cutting',
  },
  {
    c: 'D05B 39',
    d: 'Workpiece carriers for sewing machines',
  },
  {
    c: 'D05B 41',
    d: 'Work-collecting devices for sewing machines',
  },
  {
    c: 'D05B 43',
    d: 'Sewing-machine elements for supplying, handling, or treating thread material',
  },
  {
    c: 'D05B 45',
    d: 'Applications of measuring devices for determining the length of threads used in sewing machines',
  },
  {
    c: 'D05B 47',
    d: 'Needle-thread tensioning devices for sewing machines; Applications of tensometers in sewing machines',
  },
  {
    c: 'D05B 49',
    d: 'Take-up devices, e.g. levers, for the needle thread of sewing machines',
  },
  {
    c: 'D05B 51',
    d: 'Applications of needle-thread guards in sewing machines; Thread-break detectors for sewing machines',
  },
  {
    c: 'D05B 53',
    d: 'Thread- or cord-laying mechanisms for sewing machines; Thread fingers for sewing machines',
  },
  {
    c: 'D05B 55',
    d: 'Needle holders for sewing machines; Needle bars for sewing machines',
  },
  {
    c: 'D05B 57',
    d: 'Loop takers, e.g. loopers, for sewing machines',
  },
  {
    c: 'D05B 59',
    d: 'Applications of bobbin-winding or -changing devices in sewing machines; Indicating or control devices associated therewith',
  },
  {
    c: 'D05B 61',
    d: 'Loop holders; Loop spreaders for sewing machines; Stitch-forming fingers for sewing machines',
  },
  {
    c: 'D05B 63',
    d: 'Devices associated with the loop-taker thread of sewing machines, e.g. for tensioning',
  },
  {
    c: 'D05B 65',
    d: 'Devices for severing the needle or lower thread in sewing machines',
  },
  {
    c: 'D05B 67',
    d: 'Devices incorporated in sewing machines for lubricating, waxing, or colouring the threads',
  },
  {
    c: 'D05B 69',
    d: 'Driving-gear, control, lubricating, or cooling devices for sewing machines',
  },
  {
    c: 'D05B 71',
    d: 'Lubricating or cooling devices for sewing machines',
  },
  {
    c: 'D05B 73',
    d: 'Casings, supports, covers, or accessories for sewing machines; Sewing machines combined with auxiliary devices serving purposes other than sewing',
  },
  {
    c: 'D05B 75',
    d: 'Frames, stands, tables, or other furniture adapted to carry sewing machines',
  },
  {
    c: 'D05B 77',
    d: 'Covers, or portable enclosures, for sewing machines',
  },
  {
    c: 'D05B 79',
    d: 'Incorporations or adaptations of lighting equipment for sewing machines',
  },
  {
    c: 'D05B 81',
    d: 'Sewing machines incorporating devices serving purposes other than sewing, e.g. for blowing air, for grinding',
  },
  {
    c: 'D05B 83',
    d: 'Guards or like devices for preventing injury to operator of sewing machines',
  },
  {
    c: 'D05B 85',
    d: 'Needles; Needle threaders; Seam-ripping devices',
  },
  {
    c: 'D05B 87',
    d: 'Needle-threading devices',
  },
  {
    c: 'D05B 89',
    d: 'Seam-ripping devices',
  },
  {
    c: 'D05B 91',
    d: 'Hand sewing',
  },
  {
    c: 'D05B 93',
    d: 'Stitches; Stitch seams for hand sewing',
  },
  {
    c: 'D05B 95',
    d: 'Preparatory or finishing operations in connection with hand sewing',
  },
  {
    c: 'D05B 97',
    d: 'Hand sewing processes or apparatus for special work or not otherwise provided for',
  },
  {
    c: 'D05C',
    d: 'EMBROIDERING',
  },
  {
    c: 'D05C 1',
    d: 'Apparatus, devices, or tools for hand embroidering',
  },
  {
    c: 'D05C 3',
    d: 'Embroidering machines',
  },
  {
    c: 'D05C 5',
    d: 'Embroidering machines with arrangements for automatic control of a series of individual steps',
  },
  {
    c: 'D05C 7',
    d: 'Special-purpose or automatic embroidering machines',
  },
  {
    c: 'D05C 9',
    d: 'Appliances for holding or feeding the base fabric in embroidering machines',
  },
  {
    c: 'D05C 11',
    d: 'Devices for guiding, feeding, handling, or treating the threads in embroidering machines; Machine needles; Operating or control mechanisms therefor',
  },
  {
    c: 'D05C 13',
    d: 'Auxiliary devices incorporated in embroidering machines, not otherwise provided for; Ancillary apparatus for use with embroidering machines',
  },
  {
    c: 'D05C 15',
    d: 'Making pile fabrics or articles having similar surface features by inserting loops into a base material',
  },
  {
    c: 'D05C 17',
    d: 'Embroidered or tufted products; Base fabrics specially adapted for embroidered work; Inserts for producing surface irregularities in embroidered products',
  },
  {
    c: 'D06',
    d: 'TREATMENT OF TEXTILES OR THE LIKE; LAUNDERING; FLEXIBLE MATERIALS NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'D06B',
    d: 'TREATING TEXTILE MATERIALS BY LIQUIDS, GASES, OR VAPOURS',
  },
  {
    c: 'D06B 1',
    d: 'Applying liquids, gases, or vapours on to textile materials to effect treatment, e.g. washing, dyeing, bleaching, sizing, impregnating',
  },
  {
    c: 'D06B 3',
    d: 'Passing of textile materials through liquids, gases, or vapours to effect treatment, e.g. washing, dyeing, bleaching, sizing, impregnating',
  },
  {
    c: 'D06B 5',
    d: 'Forcing liquids, gases, or vapours through textile materials to effect treatment, e.g. washing, dyeing, bleaching, sizing, impregnating',
  },
  {
    c: 'D06B 7',
    d: 'Mercerising, e.g. lustring by mercerising',
  },
  {
    c: 'D06B 9',
    d: 'Solvent-treatment of textile materials',
  },
  {
    c: 'D06B 11',
    d: 'Treatment of selected parts of textile materials, e.g. partial dyeing',
  },
  {
    c: 'D06B 13',
    d: 'Treatment of textile materials with liquids, gases, or vapours with aid of vibration',
  },
  {
    c: 'D06B 15',
    d: 'Removing liquids, gases, or vapours from textile materials in association with treatment of the materials by liquids, gases, or vapours',
  },
  {
    c: 'D06B 17',
    d: 'Storing of textile materials in association with the treatment of the materials by liquids, gases, or vapours',
  },
  {
    c: 'D06B 19',
    d: 'Treatment of textile materials by liquids, gases, or vapours, not provided for in groups D06B0001000000-D06B0017000000',
  },
  {
    c: 'D06B 21',
    d: 'Successive treatments of textile materials by liquids, gases, or vapours',
  },
  {
    c: 'D06B 23',
    d: 'Component parts, details, or accessories of apparatus or machines, specially adapted for the treating of textile materials, not restricted to a particular kind of apparatus, provided for in groups D06B0001000000-D06B0021000000',
  },
  {
    c: 'D06C',
    d: 'FINISHING, DRESSING, TENTERING, OR STRETCHING TEXTILE FABRICS',
  },
  {
    c: 'D06C 3',
    d: 'Stretching, tentering, or spreading textile fabrics; Producing elasticity in textile fabrics',
  },
  {
    c: 'D06C 5',
    d: 'Shaping or stretching of tubular fabrics upon cores or internal frames',
  },
  {
    c: 'D06C 7',
    d: 'Heating or cooling textile fabrics',
  },
  {
    c: 'D06C 9',
    d: 'Singeing',
  },
  {
    c: 'D06C 11',
    d: 'Teasing, napping, or otherwise roughening or raising pile of textile fabrics',
  },
  {
    c: 'D06C 13',
    d: 'Shearing, clipping, or cropping surfaces of textile fabrics; Pile cutting; Trimming seamed edges',
  },
  {
    c: 'D06C 15',
    d: 'Calendering, pressing, ironing, glossing, or glazing textile fabrics',
  },
  {
    c: 'D06C 17',
    d: 'Fulling',
  },
  {
    c: 'D06C 19',
    d: 'Breaking or softening of fabrics',
  },
  {
    c: 'D06C 21',
    d: 'Shrinking by compressing',
  },
  {
    c: 'D06C 23',
    d: 'Making patterns or design on fabrics',
  },
  {
    c: 'D06C 25',
    d: 'Treating selvedges or other edges, e.g. stiffening',
  },
  {
    c: 'D06C 27',
    d: 'Compound processes or apparatus, for finishing or dressing textile fabrics, not otherwise provided for',
  },
  {
    c: 'D06C 29',
    d: 'Finishing or dressing, of textile fabrics, not provided for in the other groups of this subclass',
  },
  {
    c: 'D06F',
    d: 'LAUNDERING, DRYING, IRONING, PRESSING OR FOLDING TEXTILE ARTICLES',
  },
  {
    c: 'D06F 1',
    d: 'Washing; Rinsing; Dry-cleaning',
  },
  {
    c: 'D06F 3',
    d: 'Hand rubbing apparatus',
  },
  {
    c: 'D06F 5',
    d: 'Hand implements for washing purposes, e.g. sticks',
  },
  {
    c: 'D06F 7',
    d: 'Washing devices adapted to be used independently of any particular receptacle, e.g. for removable mounting on wash-tubs, bath-tubs, or the like',
  },
  {
    c: 'D06F 9',
    d: 'Brushing-type washing machines',
  },
  {
    c: 'D06F 11',
    d: 'Washing machines using rollers, e.g. of the mangle type',
  },
  {
    c: 'D06F 13',
    d: 'Washing machines having receptacles, stationary for washing purposes, with agitators therein contacting the articles being washed',
  },
  {
    c: 'D06F 15',
    d: 'Washing machines having beating, rubbing, or squeezing means in receptacles stationary for washing purposes',
  },
  {
    c: 'D06F 17',
    d: 'Washing machines having receptacles, stationary for washing purposes, wherein the washing action is effected solely by circulation or agitation of the washing liquid',
  },
  {
    c: 'D06F 18',
    d: 'Washing machines having receptacles, stationary for washing purposes, and having further drying means',
  },
  {
    c: 'D06F 19',
    d: 'Washing machines using vibrations for washing purposes',
  },
  {
    c: 'D06F 21',
    d: 'Washing machines with receptacles, e.g. perforated, having a rotary movement, e.g. oscillatory movement',
  },
  {
    c: 'D06F 23',
    d: 'Washing machines with receptacles, e.g. perforated, having a rotary movement, e.g. oscillatory movement, the receptacle serving both for washing and centrifugally draining',
  },
  {
    c: 'D06F 25',
    d: 'Washing machines with receptacles, e.g. perforated, having a rotary movement, e.g. oscillatory movement, the receptacle serving both for washing and centrifugally draining and having further drying means, e.g. using hot air',
  },
  {
    c: 'D06F 27',
    d: 'Washing machines with receptacles moving bodily, e.g. reciprocating, swinging',
  },
  {
    c: 'D06F 29',
    d: 'Combinations of a washing machine with other separate apparatus in a common frame or the like, e.g. with rinsing apparatus',
  },
  {
    c: 'D06F 31',
    d: 'Washing installations comprising an assembly of several washing machines or washing units, e.g. continuous flow assemblies',
  },
  {
    c: 'D06F 33',
    d: 'Controlling a series of operations in washing machines, e.g. programme-control arrangements for washing and drying cycles',
  },
  {
    c: 'D06F 35',
    d: 'Washing machines, apparatus, or methods not otherwise provided for',
  },
  {
    c: 'D06F 37',
    d: 'Details of washing machines of kinds covered by groups D06F0021000000-D06F0025000000, restricted to machines of these kinds',
  },
  {
    c: 'D06F 39',
    d: 'Details of washing machines in so far as such details are not special to washing machines of groups D06F0021000000-D06F0025000000 or to any particular type of washing machine defined in groups D06F0009000000-D06F0019000000 or D06F0027000000',
  },
  {
    c: 'D06F 41',
    d: 'Rinsing apparatus',
  },
  {
    c: 'D06F 43',
    d: 'Dry-cleaning apparatus using volatile solvents',
  },
  {
    c: 'D06F 45',
    d: 'Expelling water from the linen; Smoothing by cold rolling',
  },
  {
    c: 'D06F 47',
    d: 'Apparatus of the press type for expelling water from the linen',
  },
  {
    c: 'D06F 49',
    d: 'Domestic spin-dryers or similar spin-dryers not suitable for industrial use',
  },
  {
    c: 'D06F 51',
    d: 'Apparatus for expelling or draining water from the linen, not provided for in groups D06F0045000000-D06F0049000000',
  },
  {
    c: 'D06F 53',
    d: 'Drying; Airing',
  },
  {
    c: 'D06F 55',
    d: 'Clothes-pegs',
  },
  {
    c: 'D06F 57',
    d: 'Supporting means, other than simple clothes-lines, for linen or garments to be dried or aired',
  },
  {
    c: 'D06F 58',
    d: 'Domestic laundry driers',
  },
  {
    c: 'D06F 59',
    d: 'Supports adapted to retain the shape of particular articles being dried, e.g. incorporating heating means',
  },
  {
    c: 'D06F 60',
    d: 'Drying not provided for in groups D06F0053000000-D06F0059000000',
  },
  {
    c: 'D06F 61',
    d: 'Ironing; Pressing',
  },
  {
    c: 'D06F 63',
    d: 'Ironing machines having a roller or rollers coacting with a fixed or moving flat bed or table',
  },
  {
    c: 'D06F 65',
    d: 'Ironing machines with rollers rotating against curved surfaces',
  },
  {
    c: 'D06F 67',
    d: 'Details of ironing machines provided for in groups D06F0061000000, D06F0063000000, or D06F0065000000',
  },
  {
    c: 'D06F 69',
    d: 'Ironing machines not otherwise provided for',
  },
  {
    c: 'D06F 71',
    d: 'Apparatus for hot-pressing clothes, linen, or other textile articles, i.e. wherein there is substantially no relative movement between pressing element and article while pressure is being applied to the article; Similar machines for cold-pressing clothes, linen, or other textile articles',
  },
  {
    c: 'D06F 73',
    d: 'Apparatus for smoothing or removing creases from garments or other textile articles by formers, cores, stretchers, or internal frames, with the application of heat or steam',
  },
  {
    c: 'D06F 75',
    d: 'Hand irons',
  },
  {
    c: 'D06F 77',
    d: 'Arrangements for exerting pressure on, lifting, or guiding hand irons',
  },
  {
    c: 'D06F 79',
    d: 'Accessories for hand irons',
  },
  {
    c: 'D06F 81',
    d: 'Ironing boards',
  },
  {
    c: 'D06F 83',
    d: 'Coverings or pads for ironing or pressing members',
  },
  {
    c: 'D06F 85',
    d: 'Independent elements for interposition between the article and the ironing or pressing surface',
  },
  {
    c: 'D06F 87',
    d: 'Other laundry apparatus or systems',
  },
  {
    c: 'D06F 89',
    d: 'Apparatus for folding textile articles with or without stapling',
  },
  {
    c: 'D06F 91',
    d: 'Starching apparatus',
  },
  {
    c: 'D06F 93',
    d: 'Counting, sorting, or marking arrangements specially adapted for laundry purposes',
  },
  {
    c: 'D06F 95',
    d: 'Laundry systems or arrangements of apparatus or machines; Mobile laundries',
  },
  {
    c: 'D06G',
    d: 'MECHANICAL OR PRESSURE CLEANING OF CARPETS, RUGS, SACKS, HIDES, OR OTHER SKIN OR TEXTILE ARTICLES OR FABRICS; TURNING INSIDE-OUT FLEXIBLE TUBULAR OR OTHER HOLLOW ARTICLES',
  },
  {
    c: 'D06G 1',
    d: 'Beating, brushing, or otherwise mechanically cleaning or pressure cleaning carpets, rugs, sacks, hides, or other skin or textile articles or fabrics',
  },
  {
    c: 'D06G 3',
    d: 'Turning inside-out flexible tubular or other hollow articles',
  },
  {
    c: 'D06G 5',
    d: 'Mechanical, vacuum, or pressure cleaning in combination with the turning inside-out of flexible tubular or other hollow articles',
  },
  {
    c: 'D06H',
    d: 'MARKING, INSPECTING, SEAMING, OR SEVERING TEXTILE MATERIALS',
  },
  {
    c: 'D06H 1',
    d: 'Marking textile materials; Marking in combination with metering or inspecting',
  },
  {
    c: 'D06H 3',
    d: 'Inspecting textile materials',
  },
  {
    c: 'D06H 5',
    d: 'Seaming textile materials',
  },
  {
    c: 'D06H 7',
    d: 'Apparatus or processes for cutting, or otherwise severing, specially adapted for the cutting, or otherwise severing, of textile materials',
  },
  {
    c: 'D06J',
    d: 'PLEATING, KILTING, OR GOFFERING TEXTILE FABRICS OR WEARING APPAREL',
  },
  {
    c: 'D06J 1',
    d: 'Pleating, kilting, or goffering textile fabrics or wearing apparel',
  },
  {
    c: 'D06L',
    d: 'DRY-CLEANING, WASHING OR BLEACHING FIBRES, FILAMENTS, THREADS, YARNS, FABRICS, FEATHERS OR MADE-UP FIBROUS GOODS; BLEACHING LEATHER OR FURS',
  },
  {
    c: 'D06L 1',
    d: 'Dry-cleaning or washing fibres, filaments, threads, yarns, fabrics, feathers or made-up fibrous goods',
  },
  {
    c: 'D06L 4',
    d: 'Bleaching fibres, filaments, threads, yarns, fabrics, feathers or made-up fibrous goods; Bleaching leather or furs',
  },
  {
    c: 'D06M',
    d: 'TREATMENT, NOT PROVIDED FOR ELSEWHERE IN CLASS D06, OF FIBRES, THREADS, YARNS, FABRICS, FEATHERS OR FIBROUS GOODS MADE FROM SUCH MATERIALS',
  },
  {
    c: 'D06M 10',
    d: 'Physical treatment of fibres, threads, yarns, fabrics or fibrous goods made from such materials, e.g. ultrasonic, corona discharge, irradiation, electric currents or magnetic fields; Physical treatment combined with treatment with chemical compounds or elements',
  },
  {
    c: 'D06M 11',
    d: 'Treating fibres, threads, yarns, fabrics or fibrous goods made from such materials, with inorganic substances or complexes thereof; Such treatment combined with mechanical treatment, e.g. mercerising',
  },
  {
    c: 'D06M 13',
    d: 'Treating fibres, threads, yarns, fabrics or fibrous goods made from such materials with non-macromolecular organic compounds',
  },
  {
    c: 'D06M 14',
    d: 'Graft polymerisation of monomers containing carbon-to-carbon unsaturated bonds on to fibres, threads, yarns, fabrics or fibrous goods made from such materials',
  },
  {
    c: 'D06M 15',
    d: 'Treating fibres, threads, yarns, fabrics or fibrous goods made from such materials with macromolecular compounds; Such treatment combined with mechanical treatment',
  },
  {
    c: 'D06M 16',
    d: 'Biochemical treatment of fibres, threads, yarns, fabrics or fibrous goods made from such materials, e.g. enzymatic',
  },
  {
    c: 'D06M 17',
    d: 'Producing multi-layer textile fabrics',
  },
  {
    c: 'D06M 19',
    d: 'Treatment of feathers',
  },
  {
    c: 'D06M 23',
    d: 'Treatment of fibres, threads, yarns, fabrics or fibrous goods made from such materials, characterised by the process',
  },
  {
    c: 'D06M 101',
    d: 'Indexing scheme, associated with groups D06M0011000000, D06M0013000000, D06M0015000000, D06M0016000000 and D06M0023000000, relating to the fibres to be treated.',
  },
  {
    c: 'D06N',
    d: 'WALL, FLOOR, OR LIKE COVERING MATERIALS, e.g. LINOLEUM, OILCLOTH, ARTIFICIAL LEATHER, ROOFING FELT, CONSISTING OF A FIBROUS WEB COATED WITH A LAYER OF MACROMOLECULAR MATERIAL',
  },
  {
    c: 'D06N 1',
    d: 'Linoleum',
  },
  {
    c: 'D06N 3',
    d: 'Artificial leather, oilcloth, or like material obtained by covering fibrous webs with macromolecular material, e.g. resins, rubber or derivatives thereof',
  },
  {
    c: 'D06N 5',
    d: 'Roofing felt, i.e. fibrous webs coated with bitumen',
  },
  {
    c: 'D06N 7',
    d: 'Flexible sheet materials not otherwise provided for, e.g. textile threads, filaments, yarns or tow, glued on macromolecular material',
  },
  {
    c: 'D06P',
    d: 'DYEING OR PRINTING TEXTILES; DYEING LEATHER, FURS OR SOLID MACROMOLECULAR SUBSTANCES IN ANY FORM',
  },
  {
    c: 'D06P 1',
    d: 'General processes of dyeing or printing textiles or general processes of dyeing leather, furs or solid macromolecular substances in any form, classified according to the dyes, pigments or auxiliary substances employed',
  },
  {
    c: 'D06P 3',
    d: 'Special processes of dyeing or printing textiles or dyeing leather, furs or solid macromolecular substances in any form, classified according to the material treated',
  },
  {
    c: 'D06P 5',
    d: 'Other features in dyeing or printing textiles or dyeing leather, furs or solid macromolecular substances in any form',
  },
  {
    c: 'D06P 7',
    d: 'Dyeing or printing processes combined with mechanical treatment',
  },
  {
    c: 'D06Q',
    d: 'DECORATING TEXTILES',
  },
  {
    c: 'D06Q 1',
    d: 'Decorating textiles',
  },
  {
    c: 'D07',
    d: 'ROPES; CABLES OTHER THAN ELECTRIC',
  },
  {
    c: 'D07B',
    d: 'ROPES OR CABLES IN GENERAL',
  },
  {
    c: 'D07B 1',
    d: 'Constructional features of ropes or cables',
  },
  {
    c: 'D07B 3',
    d: 'Manufacture of ropes or cables',
  },
  {
    c: 'D07B 5',
    d: 'Making ropes or cables from special materials or of particular form',
  },
  {
    c: 'D07B 7',
    d: 'Details of, or auxiliary devices incorporated in, rope- or cable-making machines; Auxiliary apparatus associated with such machines',
  },
  {
    c: 'D07B 9',
    d: 'Binding or sealing ends, e.g. to prevent unravelling',
  },
  {
    c: 'D21',
    d: 'PAPER-MAKING; PRODUCTION OF CELLULOSE',
  },
  {
    c: 'D21B',
    d: 'FIBROUS RAW MATERIALS OR THEIR MECHANICAL TREATMENT',
  },
  {
    c: 'D21B 1',
    d: 'Fibrous raw materials or their mechanical treatment',
  },
  {
    c: 'D21C',
    d: 'PRODUCTION OF CELLULOSE BY REMOVING NON-CELLULOSE SUBSTANCES FROM CELLULOSE- CONTAINING MATERIALS; REGENERATION OF PULPING LIQUORS; APPARATUS THEREFOR',
  },
  {
    c: 'D21C 1',
    d: 'Pretreatment of the finely-divided materials before digesting',
  },
  {
    c: 'D21C 3',
    d: 'Pulping cellulose-containing materials',
  },
  {
    c: 'D21C 5',
    d: 'Other processes for obtaining cellulose, e.g. cooking cotton linters',
  },
  {
    c: 'D21C 7',
    d: 'Digesters',
  },
  {
    c: 'D21C 9',
    d: 'After-treatment of cellulose pulp, e.g. of wood pulp, or cotton linters',
  },
  {
    c: 'D21C 11',
    d: 'Regeneration of pulp liquors',
  },
  {
    c: 'D21D',
    d: 'TREATMENT OF THE MATERIALS BEFORE PASSING TO THE PAPER-MAKING MACHINE',
  },
  {
    c: 'D21D 1',
    d: 'Methods of beating or refining; Beaters of the Hollander type',
  },
  {
    c: 'D21D 5',
    d: 'Purification of the pulp suspension by mechanical means; Apparatus therefor',
  },
  {
    c: 'D21D 99',
    d: 'n.a.',
  },
  {
    c: 'D21F',
    d: 'PAPER-MAKING MACHINES; METHODS OF PRODUCING PAPER THEREON',
  },
  {
    c: 'D21F 1',
    d: 'Wet end of machines for making continuous webs of paper',
  },
  {
    c: 'D21F 2',
    d: 'Transferring continuous webs from wet ends to press sections',
  },
  {
    c: 'D21F 3',
    d: 'Press section of machines for making continuous webs of paper',
  },
  {
    c: 'D21F 5',
    d: 'Dryer section of machines for making continuous webs of paper',
  },
  {
    c: 'D21F 7',
    d: 'Other details of machines for making continuous webs of paper',
  },
  {
    c: 'D21F 9',
    d: 'Complete machines for making continuous webs of paper',
  },
  {
    c: 'D21F 11',
    d: 'Processes for making continuous lengths of paper, or of cardboard, or of wet web for fibreboard production, on paper-making machines',
  },
  {
    c: 'D21F 13',
    d: 'Methods or apparatus for making discontinuous sheets of paper, pulpboard, or cardboard, or of wet web, for fibreboard production',
  },
  {
    c: 'D21G',
    d: 'CALENDERS; ACCESSORIES FOR PAPER-MAKING MACHINES',
  },
  {
    c: 'D21G 1',
    d: 'Calenders',
  },
  {
    c: 'D21G 3',
    d: 'Doctors',
  },
  {
    c: 'D21G 5',
    d: 'Safety devices',
  },
  {
    c: 'D21G 7',
    d: 'Damping devices',
  },
  {
    c: 'D21G 9',
    d: 'Other accessories for paper-making machines',
  },
  {
    c: 'D21H',
    d: 'PULP COMPOSITIONS; PREPARATION THEREOF NOT COVERED BY SUBCLASSES D21C, D21D; IMPREGNATING OR COATING OF PAPER; TREATMENT OF FINISHED PAPER NOT COVERED BY CLASS B31 OR SUBCLASS D21G; PAPER NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'D21H 11',
    d: 'Pulp or paper, comprising cellulose or lignocellulose fibres of natural origin only',
  },
  {
    c: 'D21H 13',
    d: 'Pulp or paper, comprising synthetic cellulose or non-cellulose fibres or web-forming material',
  },
  {
    c: 'D21H 15',
    d: 'Pulp or paper, comprising fibres or web-forming material characterised by features other than their chemical constitution',
  },
  {
    c: 'D21H 17',
    d: 'Non-fibrous material added to the pulp, characterised by its constitution; Paper-impregnating material characterised by its constitution',
  },
  {
    c: 'D21H 19',
    d: 'Coated paper',
  },
  {
    c: 'D21H 21',
    d: 'Non-fibrous material added to the pulp, characterised by its function, form or properties; Paper impregnating or coating material, characterised by its function, form or properties',
  },
  {
    c: 'D21H 23',
    d: 'Processes or apparatus for adding material to the pulp or to the paper',
  },
  {
    c: 'D21H 25',
    d: 'After-treatment of paper not provided for in groups D21H0017000000-D21H0023000000',
  },
  {
    c: 'D21H 27',
    d: 'Special paper not otherwise provided for, e.g. made by multi-step processes',
  },
  {
    c: 'D21J',
    d: 'FIBREBOARD; MANUFACTURE OF ARTICLES FROM CELLULOSIC FIBROUS SUSPENSIONS OR FROM PAPIER-MÂCHÉ',
  },
  {
    c: 'D21J 1',
    d: 'Fibreboard',
  },
  {
    c: 'D21J 3',
    d: 'Manufacture of articles by pressing wet fibre pulp, or papier-mâché, between moulds',
  },
  {
    c: 'D21J 5',
    d: 'Manufacture of hollow articles by transferring sheets, produced from fibres suspensions or papier-mâché by suction on wire-net moulds, to couch moulds',
  },
  {
    c: 'D21J 7',
    d: 'Manufacture of hollow articles from fibre suspensions or papier-mâché by deposition of fibres in or on a wire-net mould',
  },
  {
    c: 'D99',
    d: 'n.a.',
  },
  {
    c: 'D99Z',
    d: 'n.a.',
  },
  {
    c: 'D99Z 99',
    d: 'n.a.',
  },
  {
    c: 'E',
    d: 'FIXED CONSTRUCTIONS',
  },
  {
    c: 'E01',
    d: 'CONSTRUCTION OF ROADS, RAILWAYS, OR BRIDGES',
  },
  {
    c: 'E01B',
    d: 'PERMANENT WAY; PERMANENT-WAY TOOLS; MACHINES FOR MAKING RAILWAYS OF ALL KINDS',
  },
  {
    c: 'E01B 1',
    d: 'Structure of the permanent way for railways or tramways',
  },
  {
    c: 'E01B 2',
    d: 'General structure of permanent way',
  },
  {
    c: 'E01B 3',
    d: 'Transverse or longitudinal sleepers',
  },
  {
    c: 'E01B 5',
    d: 'Rails; Guard rails',
  },
  {
    c: 'E01B 7',
    d: 'Switches; Crossings',
  },
  {
    c: 'E01B 9',
    d: 'Fastening rails on sleepers, or the like',
  },
  {
    c: 'E01B 11',
    d: 'Rail joints',
  },
  {
    c: 'E01B 13',
    d: 'Arrangements preventing shifting of the track',
  },
  {
    c: 'E01B 15',
    d: "Guards for preventing a person's foot being trapped in grooved rails",
  },
  {
    c: 'E01B 17',
    d: 'Cattle guards connected to the permanent way',
  },
  {
    c: 'E01B 19',
    d: 'Protection of permanent way against development of dust or against the effect of wind, sun, frost, or corrosion; Means to reduce development of noise',
  },
  {
    c: 'E01B 21',
    d: 'Track superstructure specially adapted for tramways in paved streets',
  },
  {
    c: 'E01B 23',
    d: 'Easily dismountable or movable tracks, e.g. temporary railways; Details specially adapted therefor',
  },
  {
    c: 'E01B 25',
    d: 'Tracks for special kinds of railways',
  },
  {
    c: 'E01B 26',
    d: 'Tracks or track components not covered by any one of main groups E01B0001000000-E01B0025000000',
  },
  {
    c: 'E01B 27',
    d: 'Making, maintaining, renewing, or taking-up the ballastway or the track; Tools or machines specially designed therefor',
  },
  {
    c: 'E01B 29',
    d: 'Laying, rebuilding, or taking-up tracks; Tools or machines therefor',
  },
  {
    c: 'E01B 31',
    d: 'Working rails, sleepers, baseplates, or the like, in or on the line; Machines, tools, or auxiliary devices specially designed therefor',
  },
  {
    c: 'E01B 33',
    d: 'Machines or devices for shifting tracks, with or without lifting, e.g. for aligning track, for shifting excavator track',
  },
  {
    c: 'E01B 35',
    d: 'Applications of measuring apparatus or devices for track-building purposes',
  },
  {
    c: 'E01B 37',
    d: 'Making, maintaining, renewing, or taking-up the ballastway or the track, not provided for in a single one of groups E01B0027000000-E01B0035000000',
  },
  {
    c: 'E01C',
    d: 'CONSTRUCTION OF, OR SURFACES FOR, ROADS, SPORTS GROUNDS, OR THE LIKE; MACHINES OR AUXILIARY TOOLS FOR CONSTRUCTION OR REPAIR',
  },
  {
    c: 'E01C 1',
    d: 'Design or layout of roads, e.g. for noise abatement, for gas absorption',
  },
  {
    c: 'E01C 3',
    d: 'Structure of roads, playgrounds, sports grounds, airfields',
  },
  {
    c: 'E01C 5',
    d: 'Pavings made of prefabricated single units',
  },
  {
    c: 'E01C 7',
    d: 'Coherent pavings made in situ',
  },
  {
    c: 'E01C 9',
    d: 'Special pavings',
  },
  {
    c: 'E01C 11',
    d: 'Details of pavings',
  },
  {
    c: 'E01C 13',
    d: 'Pavings or foundations specially adapted for playgrounds or sports grounds',
  },
  {
    c: 'E01C 15',
    d: 'Pavings specially adapted for footpaths, sidewalks, or cycle tracks',
  },
  {
    c: 'E01C 17',
    d: 'Pavement lights, i.e. translucent constructions forming part of the surface',
  },
  {
    c: 'E01C 19',
    d: 'Machines, tools or auxiliary devices for constructing or repairing the surfacing of roads or like structures',
  },
  {
    c: 'E01C 21',
    d: 'Apparatus or processes for surface stabilisation for road building or like purposes, e.g. mixing borrowed aggregate with binder',
  },
  {
    c: 'E01C 23',
    d: 'Auxiliary devices or arrangements for constructing, repairing, reconditioning, or taking-up road or like surfaces',
  },
  {
    c: 'E01D',
    d: 'BRIDGES',
  },
  {
    c: 'E01D 1',
    d: 'Bridges in general',
  },
  {
    c: 'E01D 2',
    d: 'Bridges characterised by the cross-section of their bearing spanning structure',
  },
  {
    c: 'E01D 4',
    d: 'Arch-type bridges',
  },
  {
    c: 'E01D 6',
    d: 'Truss-type bridges',
  },
  {
    c: 'E01D 11',
    d: 'Suspension or cable-stayed bridges',
  },
  {
    c: 'E01D 12',
    d: 'Bridges characterised by a combination of structures not covered as a whole by a single one of groups E01D0002000000-E01D0011000000',
  },
  {
    c: 'E01D 15',
    d: 'Movable or portable bridges',
  },
  {
    c: 'E01D 18',
    d: 'Bridges specially adapted for particular applications or functions not provided for elsewhere, e.g. aqueducts, bridges for supporting pipe-lines',
  },
  {
    c: 'E01D 19',
    d: 'Details of bridges',
  },
  {
    c: 'E01D 21',
    d: 'Methods or apparatus specially adapted for erecting or assembling bridges',
  },
  {
    c: 'E01D 22',
    d: 'Methods or apparatus for repairing or strengthening existing bridges',
  },
  {
    c: 'E01D 24',
    d: 'Methods or apparatus for dismantling bridges',
  },
  {
    c: 'E01D 101',
    d: 'Indexing scheme associated with groups E01D0001000000-E01D0022000000, relating to the material constitution of bridges.',
  },
  {
    c: 'E01F',
    d: 'ADDITIONAL WORK, SUCH AS EQUIPPING ROADS OR THE CONSTRUCTION OF PLATFORMS, HELICOPTER LANDING STAGES, SIGNS, SNOW FENCES, OR THE LIKE',
  },
  {
    c: 'E01F 1',
    d: 'Arrangement or construction of additional equipment for roads or railways; Landing stages for helicopters',
  },
  {
    c: 'E01F 3',
    d: 'Landing stages for helicopters, e.g. located above buildings',
  },
  {
    c: 'E01F 5',
    d: 'Draining the sub-base of roads or ballastway of railways by trenches, culverts, or conduits',
  },
  {
    c: 'E01F 7',
    d: 'Devices affording protection against snow, sand drifts, side-wind effects, snowslides, avalanches or falling rocks',
  },
  {
    c: 'E01F 8',
    d: 'Arrangements for absorbing or reflecting air transmitted noise from road or railway traffic',
  },
  {
    c: 'E01F 9',
    d: 'Arrangements for facilitating the use of roads',
  },
  {
    c: 'E01F 11',
    d: 'Embedding pads or other sensitive devices in paving or other road surfaces',
  },
  {
    c: 'E01F 13',
    d: 'Arrangements for obstructing or restricting traffic, e.g. gates, barricades',
  },
  {
    c: 'E01F 15',
    d: 'Safety arrangements for slowing, redirecting or stopping errant vehicles, e.g. guard posts or bollards; Arrangements for reducing damage to roadside structures due to vehicular impact',
  },
  {
    c: 'E01H',
    d: 'STREET CLEANING; CLEANING OF PERMANENT WAYS; CLEANING BEACHES; CLEANING LAND; DISPERSING FOG IN GENERAL',
  },
  {
    c: 'E01H 1',
    d: 'Removing undesirable matter from roads or like surfaces, with or without moistening of the surface',
  },
  {
    c: 'E01H 3',
    d: 'Applying liquids to roads or like surfaces, e.g. for dust control; Stationary flushing devices',
  },
  {
    c: 'E01H 4',
    d: 'Working on surfaces of snow or ice in order to make them suitable for traffic or sporting purposes, e.g. by compacting snow',
  },
  {
    c: 'E01H 5',
    d: 'Removing snow or ice from roads or like surfaces; Grading or roughening snow or ice',
  },
  {
    c: 'E01H 6',
    d: 'Apparatus equipped with, or having provisions for equipping with, both elements for removal of refuse or the like and elements for removal of snow or ice',
  },
  {
    c: 'E01H 8',
    d: 'Removing undesirable matter from the permanent way of railways; Removing undesirable matter from tramway rails',
  },
  {
    c: 'E01H 10',
    d: 'Improving gripping of ice-bound or other slippery traffic surfaces, e.g. using gritting or thawing materials',
  },
  {
    c: 'E01H 11',
    d: 'Control of undesirable vegetation on roads or permanent ways of railways',
  },
  {
    c: 'E01H 12',
    d: 'Cleaning beaches',
  },
  {
    c: 'E01H 13',
    d: 'Dispersing fog in general, e.g. on roads, on airfields',
  },
  {
    c: 'E01H 15',
    d: 'Removing undesirable matter, e.g. rubbish, from the land, not otherwise provided for',
  },
  {
    c: 'E02',
    d: 'HYDRAULIC ENGINEERING; FOUNDATIONS; SOIL-SHIFTING',
  },
  {
    c: 'E02B',
    d: 'HYDRAULIC ENGINEERING',
  },
  {
    c: 'E02B 1',
    d: 'Equipment or apparatus for, or methods of, general hydraulic engineering',
  },
  {
    c: 'E02B 3',
    d: 'Engineering work in connection with control or use of streams, rivers, coasts, or other marine sites',
  },
  {
    c: 'E02B 5',
    d: 'Artificial water canals',
  },
  {
    c: 'E02B 7',
    d: 'Barrages or weirs; Layout, construction, methods of, or devices for, making same',
  },
  {
    c: 'E02B 8',
    d: 'Details of barrages or weirs',
  },
  {
    c: 'E02B 9',
    d: 'Water-power plants; Layout, construction or equipment, methods of, or apparatus for, making same',
  },
  {
    c: 'E02B 11',
    d: 'Drainage of soil, e.g. for agricultural purposes',
  },
  {
    c: 'E02B 13',
    d: 'Irrigation ditches, i.e. gravity flow, open channel water distribution systems',
  },
  {
    c: 'E02B 15',
    d: 'Cleaning or keeping clear the surface of open water; Apparatus therefor',
  },
  {
    c: 'E02B 17',
    d: 'Artificial islands mounted on piles or like supports, e.g. platforms on raisable legs; Construction methods therefor',
  },
  {
    c: 'E02C',
    d: 'SHIP-LIFTING DEVICES OR MECHANISMS',
  },
  {
    c: 'E02C 1',
    d: 'Locks; Shaft locks, i.e. locks of which one front side is formed by a solid wall with an opening in the lower part through which the ships pass',
  },
  {
    c: 'E02C 3',
    d: 'Inclined-plane ship-lifting mechanisms',
  },
  {
    c: 'E02C 5',
    d: 'Mechanisms for lifting ships vertically',
  },
  {
    c: 'E02D',
    d: 'FOUNDATIONS; EXCAVATIONS; EMBANKMENTS',
  },
  {
    c: 'E02D 1',
    d: 'Investigation of foundation soil in situ',
  },
  {
    c: 'E02D 3',
    d: 'Improving or preserving soil or rock, e.g. preserving permafrost soil',
  },
  {
    c: 'E02D 5',
    d: 'Bulkheads, piles, or other structural elements specially adapted to foundation engineering',
  },
  {
    c: 'E02D 7',
    d: 'Methods or apparatus for placing sheet pile bulkheads, piles, mould-pipes, or other moulds',
  },
  {
    c: 'E02D 9',
    d: 'Removing sheet pile bulkheads, piles, mould-pipes, or other moulds',
  },
  {
    c: 'E02D 11',
    d: 'Methods or apparatus for both placing and removing sheet pile bulkheads, piles, or mould-pipes',
  },
  {
    c: 'E02D 13',
    d: 'Accessories for placing or removing piles or bulkheads',
  },
  {
    c: 'E02D 15',
    d: 'Handling building or like materials for hydraulic engineering or foundations',
  },
  {
    c: 'E02D 17',
    d: 'Excavations; Bordering of excavations; Making embankments',
  },
  {
    c: 'E02D 19',
    d: 'Keeping dry foundation sites or other areas in the ground',
  },
  {
    c: 'E02D 23',
    d: 'Caissons; Construction or placing of caissons',
  },
  {
    c: 'E02D 25',
    d: 'Joining caissons or like sunk units to each other under water',
  },
  {
    c: 'E02D 27',
    d: 'Foundations as substructures',
  },
  {
    c: 'E02D 29',
    d: 'Underground or underwater structures',
  },
  {
    c: 'E02D 31',
    d: 'Protective arrangements for foundations or foundation structures; Ground foundation measures for protecting the soil or the subsoil water, e.g. preventing or counteracting oil pollution',
  },
  {
    c: 'E02D 33',
    d: 'Testing foundations or foundation structures',
  },
  {
    c: 'E02D 35',
    d: 'Straightening, lifting, or lowering of foundation structures or of constructions erected on foundations',
  },
  {
    c: 'E02D 37',
    d: 'Repair of damaged foundations or foundation structures',
  },
  {
    c: 'E02F',
    d: 'DREDGING; SOIL-SHIFTING',
  },
  {
    c: 'E02F 1',
    d: 'General working methods with dredgers or soil-shifting machines',
  },
  {
    c: 'E02F 3',
    d: 'Dredgers; Soil-shifting machines',
  },
  {
    c: 'E02F 5',
    d: 'Dredgers or soil-shifting machines for special purposes',
  },
  {
    c: 'E02F 7',
    d: 'Equipment for conveying or separating excavated material',
  },
  {
    c: 'E02F 9',
    d: 'Component parts of dredgers or soil-shifting machines, not restricted to one of the kinds covered by groups E02F0003000000-E02F0007000000',
  },
  {
    c: 'E03',
    d: 'WATER SUPPLY; SEWERAGE',
  },
  {
    c: 'E03B',
    d: 'INSTALLATIONS OR METHODS FOR OBTAINING, COLLECTING, OR DISTRIBUTING WATER',
  },
  {
    c: 'E03B 1',
    d: 'Methods or layout of installations for water supply',
  },
  {
    c: 'E03B 3',
    d: 'Methods or installations for obtaining or collecting drinking water or tap water',
  },
  {
    c: 'E03B 5',
    d: 'Use of pumping plants or installations; Layouts thereof',
  },
  {
    c: 'E03B 7',
    d: 'Water main or service pipe systems',
  },
  {
    c: 'E03B 9',
    d: 'Methods or installations for drawing-off water',
  },
  {
    c: 'E03B 11',
    d: 'Arrangements or adaptations of tanks for water supply',
  },
  {
    c: 'E03C',
    d: 'DOMESTIC PLUMBING INSTALLATIONS FOR FRESH WATER OR WASTE WATER',
  },
  {
    c: 'E03C 1',
    d: 'Domestic plumbing installations for fresh water or waste water; Sinks',
  },
  {
    c: 'E03D',
    d: 'WATER-CLOSETS OR URINALS WITH FLUSHING DEVICES; FLUSHING VALVES THEREFOR',
  },
  {
    c: 'E03D 1',
    d: 'Water flushing devices with cisterns',
  },
  {
    c: 'E03D 3',
    d: 'Flushing devices operated by pressure of the water supply system',
  },
  {
    c: 'E03D 5',
    d: 'Special constructions of flushing devices',
  },
  {
    c: 'E03D 7',
    d: 'Wheeled lavatories',
  },
  {
    c: 'E03D 9',
    d: 'Sanitary or other accessories for lavatories',
  },
  {
    c: 'E03D 11',
    d: 'Other component parts of water-closets',
  },
  {
    c: 'E03D 13',
    d: 'Urinals',
  },
  {
    c: 'E03F',
    d: 'SEWERS; CESSPOOLS',
  },
  {
    c: 'E03F 1',
    d: 'Methods, systems, or installations for draining-off sewage or storm water',
  },
  {
    c: 'E03F 3',
    d: 'Sewer pipe-line systems',
  },
  {
    c: 'E03F 5',
    d: 'Sewerage structures',
  },
  {
    c: 'E03F 7',
    d: 'Other installations or implements for operating sewer systems, e.g. for preventing or indicating stoppage; Emptying cesspools',
  },
  {
    c: 'E03F 9',
    d: 'Arrangements or fixed installations for cleaning sewer pipes, e.g. by flushing',
  },
  {
    c: 'E03F 11',
    d: 'Cesspools',
  },
  {
    c: 'E04',
    d: 'BUILDING',
  },
  {
    c: 'E04B',
    d: 'GENERAL BUILDING CONSTRUCTIONS; WALLS, e.g. PARTITIONS; ROOFS; FLOORS; CEILINGS; INSULATION OR OTHER PROTECTION OF BUILDINGS',
  },
  {
    c: 'E04B 1',
    d: 'Constructions in general; Structures which are not restricted either to walls, e.g. partitions, or floors or ceilings or roofs',
  },
  {
    c: 'E04B 2',
    d: 'Walls, e.g. partitions, for buildings; Wall construction with regard to insulation; Connections specially adapted to walls',
  },
  {
    c: 'E04B 5',
    d: 'Floors; Floor construction with regard to insulation; Connections specially adapted therefor',
  },
  {
    c: 'E04B 7',
    d: 'Roofs; Roof construction with regard to insulation',
  },
  {
    c: 'E04B 9',
    d: 'Ceilings; Construction of ceilings, e.g. false ceilings; Ceiling construction with regard to insulation',
  },
  {
    c: 'E04C',
    d: 'STRUCTURAL ELEMENTS; BUILDING MATERIALS',
  },
  {
    c: 'E04C 1',
    d: 'Building elements of block or other shape for the construction of parts of buildings',
  },
  {
    c: 'E04C 2',
    d: 'Building elements of relatively thin form for the construction of parts of buildings, e.g. sheet materials, slabs, or panels',
  },
  {
    c: 'E04C 3',
    d: 'Structural elongated elements designed for load-supporting',
  },
  {
    c: 'E04C 5',
    d: 'Reinforcing elements, e.g. for concrete; Auxiliary elements therefor',
  },
  {
    c: 'E04D',
    d: 'ROOF COVERINGS; SKY-LIGHTS; GUTTERS; ROOF-WORKING TOOLS',
  },
  {
    c: 'E04D 1',
    d: 'Roof covering by making use of tiles, slates, shingles, or other small roofing elements',
  },
  {
    c: 'E04D 3',
    d: 'Roof covering by making use of flat or curved slabs or stiff sheets',
  },
  {
    c: 'E04D 5',
    d: 'Roof covering by making use of flexible material, e.g. supplied in roll form',
  },
  {
    c: 'E04D 7',
    d: 'Roof covering by sealing masses applied in situ; Gravelling of flat roofs',
  },
  {
    c: 'E04D 9',
    d: 'Roof covering by using straw, thatch, or like materials',
  },
  {
    c: 'E04D 11',
    d: 'Roof covering, as far as not restricted to features covered by only one of groups E04D0001000000-E04D0009000000; Roof covering in ways not provided for by groups E04D0001000000-E04D0009000000',
  },
  {
    c: 'E04D 12',
    d: 'Non-structural supports for roofing materials, e.g. battens, boards',
  },
  {
    c: 'E04D 13',
    d: 'Special arrangements or devices in connection with roof coverings; Roof drainage',
  },
  {
    c: 'E04D 15',
    d: 'Apparatus or tools for roof working',
  },
  {
    c: 'E04F',
    d: 'FINISHING WORK ON BUILDINGS, e.g. STAIRS, FLOORS',
  },
  {
    c: 'E04F 10',
    d: 'Sunshades; Awnings',
  },
  {
    c: 'E04F 11',
    d: 'Stairways, ramps, or like structures',
  },
  {
    c: 'E04F 13',
    d: 'Coverings or linings, e.g. for walls or ceilings',
  },
  {
    c: 'E04F 15',
    d: 'Flooring',
  },
  {
    c: 'E04F 17',
    d: 'Vertical ducts; Channels, e.g. for drainage',
  },
  {
    c: 'E04F 19',
    d: 'Other details or constructional parts for finishing work on buildings',
  },
  {
    c: 'E04F 21',
    d: 'Implements for finishing work on buildings',
  },
  {
    c: 'E04G',
    d: 'SCAFFOLDING; FORMS; SHUTTERING; BUILDING IMPLEMENTS OR OTHER BUILDING AIDS, OR THEIR USE; HANDLING BUILDING MATERIALS ON THE SITE; REPAIRING, BREAKING-UP OR OTHER WORK ON EXISTING BUILDINGS',
  },
  {
    c: 'E04G 1',
    d: "Workmen's or safety scaffolds",
  },
  {
    c: 'E04G 3',
    d: 'Scaffolds essentially supported by building constructions, e.g. adjustable in height',
  },
  {
    c: 'E04G 5',
    d: 'Component parts or accessories for scaffolds',
  },
  {
    c: 'E04G 7',
    d: 'Connections between parts of the scaffold',
  },
  {
    c: 'E04G 9',
    d: 'Falsework, forms or shutterings for shaping of building parts in situ',
  },
  {
    c: 'E04G 11',
    d: 'Forms, shutterings, or falsework for making walls, floors, ceilings, or roofs',
  },
  {
    c: 'E04G 13',
    d: 'Falsework, forms or shutterings for particular parts of buildings, e.g. stairs, steps, cornices or balconies',
  },
  {
    c: 'E04G 15',
    d: 'Forms or shutterings for making openings, cavities, slits, or channels',
  },
  {
    c: 'E04G 17',
    d: 'Connecting or other auxiliary members for forms, falsework structures, or shutterings',
  },
  {
    c: 'E04G 19',
    d: 'Auxiliary treatment of forms, e.g. dismantling; Cleaning devices',
  },
  {
    c: 'E04G 21',
    d: 'Preparing, conveying, or working-up building materials or building elements in situ; Other devices or measures for constructional work',
  },
  {
    c: 'E04G 23',
    d: 'Working measures on existing buildings',
  },
  {
    c: 'E04G 25',
    d: 'Shores or struts',
  },
  {
    c: 'E04G 27',
    d: 'Temporary arrangements for giving access from one level to another for men or vehicles, e.g. steps, ramps',
  },
  {
    c: 'E04H',
    d: 'BUILDINGS OR LIKE STRUCTURES FOR PARTICULAR PURPOSES; SWIMMING OR SPLASH BATHS OR POOLS; MASTS; FENCING; TENTS OR CANOPIES, IN GENERAL',
  },
  {
    c: 'E04H 1',
    d: 'Buildings or groups of buildings for dwelling or office purposes; General layout, e.g. modular co-ordination, staggered storeys',
  },
  {
    c: 'E04H 3',
    d: 'Buildings or groups of buildings for public or similar purposes; Institutions, e.g. infirmaries, prisons',
  },
  {
    c: 'E04H 4',
    d: 'Swimming or splash baths or pools',
  },
  {
    c: 'E04H 5',
    d: 'Buildings or groups of buildings for industrial or agricultural purposes',
  },
  {
    c: 'E04H 6',
    d: 'Buildings for parking cars, rolling-stock, aircraft, vessels, or like vehicles, e.g. garages',
  },
  {
    c: 'E04H 7',
    d: 'Construction or assembling of bulk storage containers employing civil engineering techniques in situ or off the site',
  },
  {
    c: 'E04H 9',
    d: 'Buildings, groups of buildings, or shelters, adapted to withstand or provide protection against, abnormal external influences, e.g. war-like action, earthquake, extreme climate',
  },
  {
    c: 'E04H 12',
    d: 'Towers; Masts or poles; Chimney stacks; Water-towers; Methods of erecting such structures',
  },
  {
    c: 'E04H 13',
    d: 'Monuments; Tombs; Burial vaults; Columbaria',
  },
  {
    c: 'E04H 14',
    d: 'Buildings for combinations of different purposes not covered by any single one of main groups E04H0001000000-E04H0013000000 of this subclass, e.g. for double purpose',
  },
  {
    c: 'E04H 15',
    d: 'Tents or canopies, in general',
  },
  {
    c: 'E04H 17',
    d: 'Fencing, e.g. fences, enclosures, corrals',
  },
  {
    c: 'E05',
    d: 'LOCKS; KEYS; WINDOW OR DOOR FITTINGS; SAFES',
  },
  {
    c: 'E05B',
    d: 'LOCKS; ACCESSORIES THEREFOR; HANDCUFFS',
  },
  {
    c: 'E05B 1',
    d: 'Details or accessories of locks or the like; Keys',
  },
  {
    c: 'E05B 3',
    d: 'Fastening handles to lock or latch parts',
  },
  {
    c: 'E05B 5',
    d: 'Handles completely let into the surface of the wing',
  },
  {
    c: 'E05B 7',
    d: 'Handles pivoted about an axis parallel to the wing',
  },
  {
    c: 'E05B 9',
    d: 'Lock casings or latch-mechanism casings',
  },
  {
    c: 'E05B 11',
    d: 'Devices preventing keys from being removed from the lock',
  },
  {
    c: 'E05B 13',
    d: 'Devices preventing the key or the handle or both from being used',
  },
  {
    c: 'E05B 15',
    d: 'Other details of locks; Parts for engagement by bolts of fastening devices',
  },
  {
    c: 'E05B 17',
    d: 'Accessories in connection with locks',
  },
  {
    c: 'E05B 19',
    d: 'Keys; Accessories therefor',
  },
  {
    c: 'E05B 21',
    d: 'Locks with rotary keys moving lamelliform tumblers perpendicular to the key',
  },
  {
    c: 'E05B 23',
    d: 'Locks with rotary keys moving lamelliform tumblers perpendicular to the key, in which the tumblers follow the movement of the bolt',
  },
  {
    c: 'E05B 25',
    d: 'Locks with rotary keys moving lamelliform tumblers perpendicular to the key, characterised by the tumblers',
  },
  {
    c: 'E05B 27',
    d: 'Locks of which the tumblers are set by pushing the key in',
  },
  {
    c: 'E05B 29',
    d: 'Cylinder locks with plate tumblers that are set by pushing the key in',
  },
  {
    c: 'E05B 31',
    d: 'Cylinder locks with both tumbler pins or balls and plate tumblers that are set by pushing the key in',
  },
  {
    c: 'E05B 33',
    d: 'Cylinder locks with tumblers that are set by pushing the key in, in which the bolt is moved by means other than the key',
  },
  {
    c: 'E05B 35',
    d: 'Locks for use with special keys or a plurality of keys',
  },
  {
    c: 'E05B 37',
    d: 'Permutation locks',
  },
  {
    c: 'E05B 39',
    d: 'Locks with indicating or timing devices',
  },
  {
    c: 'E05B 41',
    d: 'Locks with visible indication as to whether the lock is locked or unlocked',
  },
  {
    c: 'E05B 43',
    d: 'Time locks',
  },
  {
    c: 'E05B 45',
    d: 'Alarm locks',
  },
  {
    c: 'E05B 47',
    d: 'Operation or control of locks by non-mechanical means, e.g. from a distance',
  },
  {
    c: 'E05B 49',
    d: 'Electric permutation locks; Circuits therefor',
  },
  {
    c: 'E05B 51',
    d: 'Operating or controlling locks or other fastening devices by other non-mechanical means',
  },
  {
    c: 'E05B 53',
    d: 'Operation or control of locks by mechanical transmissions, e.g. from a distance',
  },
  {
    c: 'E05B 55',
    d: 'Locks with provision for latching',
  },
  {
    c: 'E05B 57',
    d: 'Locks in which a pivoted latch is used also as locking means',
  },
  {
    c: 'E05B 59',
    d: 'Locks with latches separate from the lock-bolts, or with a plurality of latches or lock-bolts',
  },
  {
    c: 'E05B 61',
    d: 'Other locks with provision for latching',
  },
  {
    c: 'E05B 63',
    d: 'Locks with special structural characteristics or for special use',
  },
  {
    c: 'E05B 65',
    d: 'Locks for special use',
  },
  {
    c: 'E05B 67',
    d: 'Padlocks',
  },
  {
    c: 'E05B 69',
    d: 'Locking devices for clothing, sticks, umbrellas, or cycles',
  },
  {
    c: 'E05B 71',
    d: 'Locks specially adapted for bicycles, other than padlocks',
  },
  {
    c: 'E05B 73',
    d: 'Devices for locking portable objects against unauthorised removal; Locking devices not provided for in other groups of this subclass',
  },
  {
    c: 'E05B 75',
    d: 'Handcuffs',
  },
  {
    c: 'E05B 77',
    d: 'Locks for vehicles other than bicycles',
  },
  {
    c: 'E05B 79',
    d: 'Mounting or connecting vehicle locks or parts thereof',
  },
  {
    c: 'E05B 81',
    d: 'Power-actuated vehicle locks',
  },
  {
    c: 'E05B 83',
    d: 'Vehicle locks specially adapted for particular types of wing or vehicle',
  },
  {
    c: 'E05B 85',
    d: 'Details of vehicle locks not provided for in groups E05B0077000000-E05B0083000000',
  },
  {
    c: 'E05C',
    d: 'BOLTS OR FASTENING DEVICES FOR WINGS, SPECIALLY FOR DOORS OR WINDOWS',
  },
  {
    c: 'E05C 1',
    d: 'Bolts, latches or equivalent wing-fastening devices, characterised by special way of movement, e.g. moving rectilinearly, pivotally or rotatively',
  },
  {
    c: 'E05C 3',
    d: 'Fastening devices with bolts moving pivotally or rotatively',
  },
  {
    c: 'E05C 5',
    d: 'Fastening devices with bolts moving otherwise than only rectilinearly and only pivotally or rotatively',
  },
  {
    c: 'E05C 7',
    d: 'Fastening devices specially adapted for two wings',
  },
  {
    c: 'E05C 9',
    d: 'Arrangement of simultaneously-actuated bolts or other securing devices at well-separated positions on the same wing',
  },
  {
    c: 'E05C 17',
    d: 'Devices for holding wings open; Devices for limiting opening of wings or for holding wings open by a movable member extending between frame and wing; Braking devices, stops or buffers, combined therewith',
  },
  {
    c: 'E05C 19',
    d: 'Other devices specially designed for securing wings',
  },
  {
    c: 'E05C 21',
    d: 'Arrangement or combinations of wing fastening, securing, or holding devices, not covered by any single one of main groups E05C0001000000-E05C0019000000',
  },
  {
    c: 'E05D',
    d: 'HINGES OR OTHER SUSPENSION DEVICES FOR DOORS, WINDOWS, OR WINGS',
  },
  {
    c: 'E05D 1',
    d: 'Pinless hinges; Substitutes for hinges',
  },
  {
    c: 'E05D 3',
    d: 'Hinges with pins',
  },
  {
    c: 'E05D 5',
    d: 'Construction of single parts, e.g. the parts for attachment',
  },
  {
    c: 'E05D 7',
    d: 'Hinges or pivots of special construction',
  },
  {
    c: 'E05D 9',
    d: 'Flaps or sleeves specially designed for making from particular material, e.g. hoop-iron, sheet metal, plastics',
  },
  {
    c: 'E05D 11',
    d: 'Additional features or accessories of hinges',
  },
  {
    c: 'E05D 13',
    d: 'Accessories for sliding or lifting wings, e.g. pulleys, safety catches',
  },
  {
    c: 'E05D 15',
    d: 'Suspension arrangements for wings',
  },
  {
    c: 'E05F',
    d: 'DEVICES FOR MOVING WINGS INTO OPEN OR CLOSED POSITION; CHECKS FOR WINGS; WING FITTINGS NOT OTHERWISE PROVIDED FOR, CONCERNED WITH THE FUNCTIONING OF THE WING',
  },
  {
    c: 'E05F 1',
    d: 'Closers or openers for wings, not otherwise provided for in this subclass',
  },
  {
    c: 'E05F 3',
    d: 'Closers or openers with braking devices, e.g. checks; Construction of pneumatic or liquid braking devices',
  },
  {
    c: 'E05F 5',
    d: 'Braking devices, e.g. checks; Stops; Buffers',
  },
  {
    c: 'E05F 7',
    d: 'Accessories for wings not provided for in other groups of this subclass',
  },
  {
    c: 'E05F 9',
    d: 'Operating mechanisms for wings',
  },
  {
    c: 'E05F 11',
    d: 'Man-operated mechanisms for operating wings, including those which also operate the fastening',
  },
  {
    c: 'E05F 13',
    d: 'Operating mechanisms for wings, operated by the movement or weight of a person or vehicle',
  },
  {
    c: 'E05F 15',
    d: 'Power-operated mechanisms for wings',
  },
  {
    c: 'E05F 17',
    d: 'Special devices for shifting a plurality of wings operated simultaneously',
  },
  {
    c: 'E05G',
    d: 'SAFES OR STRONG-ROOMS FOR VALUABLES; BANK PROTECTION DEVICES; SAFETY TRANSACTION PARTITIONS',
  },
  {
    c: 'E05G 1',
    d: 'Safes or strong-rooms for valuables',
  },
  {
    c: 'E05G 5',
    d: 'Bank protection devices',
  },
  {
    c: 'E05G 7',
    d: 'Safety transaction partitions, e.g. movable payplates',
  },
  {
    c: 'E06',
    d: 'DOORS, WINDOWS, SHUTTERS, OR ROLLER BLINDS, IN GENERAL; LADDERS',
  },
  {
    c: 'E06B',
    d: 'FIXED OR MOVABLE CLOSURES FOR OPENINGS IN BUILDINGS, VEHICLES, FENCES, OR LIKE ENCLOSURES, IN GENERAL, e.g. DOORS, WINDOWS, BLINDS, GATES',
  },
  {
    c: 'E06B 1',
    d: 'Border constructions of openings in walls, floors, or ceilings; Frames to be rigidly mounted in such openings',
  },
  {
    c: 'E06B 3',
    d: 'Window sashes, door leaves, or like elements for closing openings; Layout of fixed or moving closures, e.g. windows; Features of rigidly-mounted outer frames relating to the mounting of wing frames',
  },
  {
    c: 'E06B 5',
    d: 'Doors, windows, or like closures for special purposes; Border constructions therefor',
  },
  {
    c: 'E06B 7',
    d: 'Special arrangements or measures in connection with doors or windows',
  },
  {
    c: 'E06B 9',
    d: 'Screening or protective devices for openings, with or without operating or securing mechanisms; Closures of similar construction',
  },
  {
    c: 'E06B 11',
    d: 'Means for allowing passage through fences, barriers, or the like, e.g. stiles',
  },
  {
    c: 'E06C',
    d: 'LADDERS',
  },
  {
    c: 'E06C 1',
    d: 'Ladders in general',
  },
  {
    c: 'E06C 5',
    d: 'Ladders characterised by being mounted on undercarriages or vehicles; Securing ladders on vehicles',
  },
  {
    c: 'E06C 7',
    d: 'Component parts, supporting parts, or accessories',
  },
  {
    c: 'E06C 9',
    d: 'Ladders characterised by being permanently attached to fixed structures, e.g. fire escapes',
  },
  {
    c: 'E21',
    d: 'EARTH OR ROCK DRILLING; MINING',
  },
  {
    c: 'E21B',
    d: 'EARTH OR ROCK DRILLING',
  },
  {
    c: 'E21B 1',
    d: 'Methods or apparatus for drilling',
  },
  {
    c: 'E21B 3',
    d: 'Rotary drilling',
  },
  {
    c: 'E21B 4',
    d: 'Drives for drilling, used in the borehole',
  },
  {
    c: 'E21B 6',
    d: 'Drives for drilling with combined rotary and percussive action',
  },
  {
    c: 'E21B 7',
    d: 'Special methods or apparatus for drilling',
  },
  {
    c: 'E21B 10',
    d: 'Drilling tools',
  },
  {
    c: 'E21B 11',
    d: 'Other drilling tools',
  },
  {
    c: 'E21B 12',
    d: 'Accessories for drilling tools',
  },
  {
    c: 'E21B 15',
    d: 'Other equipment or details for drilling; Well equipment or well maintenance',
  },
  {
    c: 'E21B 17',
    d: 'Drilling rods or pipes; Flexible drill strings; Kellies; Drill collars; Sucker rods; Casings; Tubings',
  },
  {
    c: 'E21B 19',
    d: 'Handling rods, casings, tubes or the like outside the borehole, e.g. in the derrick; Apparatus for feeding the rods or cables',
  },
  {
    c: 'E21B 21',
    d: 'Methods or apparatus for flushing boreholes, e.g. by use of exhaust air from motor',
  },
  {
    c: 'E21B 23',
    d: 'Apparatus for displacing, setting, locking, releasing or removing tools, packers or the like in boreholes or wells',
  },
  {
    c: 'E21B 25',
    d: 'Apparatus for obtaining or removing undisturbed cores, e.g. core barrels, core extractors',
  },
  {
    c: 'E21B 27',
    d: 'Containers for collecting or depositing substances in boreholes or wells, e.g. bailers for collecting mud or sand; Drill bits with means for collecting substances, e.g. valve drill bits',
  },
  {
    c: 'E21B 28',
    d: 'Vibration generating arrangements for boreholes or wells, e.g. for stimulating production',
  },
  {
    c: 'E21B 29',
    d: 'Cutting or destroying pipes, packers, plugs, or wire lines, located in boreholes or wells, e.g. cutting of damaged pipes, of windows',
  },
  {
    c: 'E21B 31',
    d: 'Fishing for or freeing objects in boreholes or wells',
  },
  {
    c: 'E21B 33',
    d: 'Sealing or packing boreholes or wells',
  },
  {
    c: 'E21B 34',
    d: 'Valve arrangements for boreholes or wells',
  },
  {
    c: 'E21B 35',
    d: 'Methods or apparatus for preventing or extinguishing fires',
  },
  {
    c: 'E21B 36',
    d: 'Heating, cooling, or insulating arrangements for boreholes or wells, e.g. for use in permafrost zones',
  },
  {
    c: 'E21B 37',
    d: 'Methods or apparatus for cleaning boreholes or wells',
  },
  {
    c: 'E21B 40',
    d: 'Tubing catchers, automatically arresting the fall of oil-well tubing',
  },
  {
    c: 'E21B 41',
    d: 'Equipment or details not covered by groups E21B0015000000-E21B0040000000',
  },
  {
    c: 'E21B 43',
    d: 'Obtaining fluids from wells',
  },
  {
    c: 'E21B 44',
    d: 'Automatic control',
  },
  {
    c: 'E21B 45',
    d: 'Surveying or testing',
  },
  {
    c: 'E21B 47',
    d: 'Survey of boreholes or wells',
  },
  {
    c: 'E21B 49',
    d: 'Testing the nature of borehole walls; Formation testing; Methods or apparatus for obtaining samples of soil or well fluids, specially adapted to earth drilling or wells',
  },
  {
    c: 'E21C',
    d: 'MINING OR QUARRYING',
  },
  {
    c: 'E21C 25',
    d: 'Cutting; Slitting; Dislodging',
  },
  {
    c: 'E21C 27',
    d: 'Machines which completely free the mineral from the seam',
  },
  {
    c: 'E21C 29',
    d: 'Propulsion of machines for slitting or completely freeing the mineral from the seam',
  },
  {
    c: 'E21C 31',
    d: 'Driving means incorporated in machines for slitting or completely freeing the mineral from the seam',
  },
  {
    c: 'E21C 33',
    d: 'Trucks or other devices for transporting machines for slitting or completely freeing the mineral from the seam',
  },
  {
    c: 'E21C 35',
    d: 'Details of, or accessories for, machines for slitting or completely freeing the mineral from the seam, not provided for in groups E21C0025000000-E21C0033000000, E21C0037000000 or E21C0039000000',
  },
  {
    c: 'E21C 37',
    d: 'Other methods or devices for dislodging with or without loading',
  },
  {
    c: 'E21C 39',
    d: 'Devices for testing in situ the hardness or other properties of minerals, e.g. for giving information as to the selection of suitable mining tools',
  },
  {
    c: 'E21C 41',
    d: 'Methods of mining or quarrying; Open-pit mining; Layouts therefor',
  },
  {
    c: 'E21C 45',
    d: 'Methods of hydraulic mining; Hydraulic monitors',
  },
  {
    c: 'E21C 47',
    d: 'Machines for obtaining, or the removal of, materials in open-pit mines',
  },
  {
    c: 'E21C 49',
    d: 'Obtaining peat; Machines therefor',
  },
  {
    c: 'E21C 50',
    d: 'Obtaining minerals from underwater, not otherwise provided for',
  },
  {
    c: 'E21C 51',
    d: 'Apparatus for, or methods of, winning materials from extraterrestrial sources',
  },
  {
    c: 'E21D',
    d: 'SHAFTS; TUNNELS; GALLERIES; LARGE UNDERGROUND CHAMBERS',
  },
  {
    c: 'E21D 1',
    d: 'Shafts',
  },
  {
    c: 'E21D 3',
    d: 'Raising shafts, i.e. working upwards from the bottom',
  },
  {
    c: 'E21D 5',
    d: 'Lining shafts; Linings therefor',
  },
  {
    c: 'E21D 7',
    d: 'Shaft equipment, e.g. timbering within the shaft',
  },
  {
    c: 'E21D 8',
    d: 'Shafts not provided for in groups E21D0001000000-E21D0007000000',
  },
  {
    c: 'E21D 9',
    d: 'Tunnels; Galleries; Large underground chambers; Linings therefor',
  },
  {
    c: 'E21D 11',
    d: 'Lining tunnels, galleries or other underground cavities, e.g. large underground chambers; Linings therefor; Making such linings in situ, e.g. by assembling',
  },
  {
    c: 'E21D 13',
    d: 'Large underground chambers; Methods or apparatus for making them',
  },
  {
    c: 'E21D 15',
    d: 'Working-face supports',
  },
  {
    c: 'E21D 17',
    d: 'Caps for supporting mine roofs',
  },
  {
    c: 'E21D 19',
    d: 'Provisional protective covers for working space',
  },
  {
    c: 'E21D 20',
    d: 'Setting anchoring-bolts',
  },
  {
    c: 'E21D 21',
    d: 'Anchoring-bolts for roof, floor, or shaft-lining protection',
  },
  {
    c: 'E21D 23',
    d: 'Mine roof supports for step-by-step movement, e.g. in combination with provisions for shifting of conveyors, mining machines or guides therefor',
  },
  {
    c: 'E21F',
    d: 'SAFETY DEVICES, TRANSPORT, FILLING-UP, RESCUE, VENTILATION, OR DRAINAGE IN OR OF MINES OR TUNNELS',
  },
  {
    c: 'E21F 1',
    d: 'Ventilation of mines or tunnels',
  },
  {
    c: 'E21F 3',
    d: 'Cooling or drying of air',
  },
  {
    c: 'E21F 5',
    d: 'Safety devices; Rescue devices',
  },
  {
    c: 'E21F 7',
    d: 'Methods or devices for drawing-off gases with or without subsequent use of the gas for any purpose',
  },
  {
    c: 'E21F 9',
    d: 'Devices preventing sparking of machines or apparatus',
  },
  {
    c: 'E21F 11',
    d: 'Rescue devices or other safety devices, e.g. safety chambers or escape ways',
  },
  {
    c: 'E21F 13',
    d: 'Transport specially adapted to underground conditions',
  },
  {
    c: 'E21F 15',
    d: 'Methods or devices for placing filling-up materials in underground workings',
  },
  {
    c: 'E21F 16',
    d: 'Drainage',
  },
  {
    c: 'E21F 17',
    d: 'Methods or devices for use in mines or tunnels, not covered elsewhere',
  },
  {
    c: 'E99',
    d: 'n.a.',
  },
  {
    c: 'E99Z',
    d: 'n.a.',
  },
  {
    c: 'E99Z 99',
    d: 'n.a.',
  },
  {
    c: 'F',
    d: 'MECHANICAL ENGINEERING; LIGHTING; HEATING; WEAPONS; BLASTING',
  },
  {
    c: 'F01',
    d: 'MACHINES OR ENGINES IN GENERAL; ENGINE PLANTS IN GENERAL; STEAM ENGINES',
  },
  {
    c: 'F01B',
    d: 'MACHINES OR ENGINES, IN GENERAL OR OF POSITIVE-DISPLACEMENT TYPE, e.g. STEAM ENGINES',
  },
  {
    c: 'F01B 1',
    d: 'Reciprocating-piston machines or engines characterised by number or relative disposition of cylinders or by being built-up from separate cylinder-crankcase elements',
  },
  {
    c: 'F01B 3',
    d: 'Reciprocating-piston machines or engines with cylinder axes coaxial with, or parallel or inclined to, main shaft axis',
  },
  {
    c: 'F01B 5',
    d: 'Reciprocating-piston machines or engines with cylinder axes arranged substantially tangentially to a circle centred on main shaft axis',
  },
  {
    c: 'F01B 7',
    d: 'Machines or engines with two or more pistons reciprocating within same cylinder or within essentially coaxial cylinders',
  },
  {
    c: 'F01B 9',
    d: 'Reciprocating-piston machines or engines characterised by connections between pistons and main shafts and not specific to groups F01B0001000000-F01B0007000000',
  },
  {
    c: 'F01B 11',
    d: 'Reciprocating-piston machines or engines without rotary main shaft, e.g. of free-piston type',
  },
  {
    c: 'F01B 13',
    d: 'Reciprocating-piston machines or engines with rotating cylinders in order to obtain the reciprocating-piston motion',
  },
  {
    c: 'F01B 15',
    d: 'Reciprocating-piston machines or engines with movable cylinders other than provided for in group F01B0013000000',
  },
  {
    c: 'F01B 17',
    d: 'Reciprocating-piston machines or engines characterised by use of uniflow principle',
  },
  {
    c: 'F01B 19',
    d: 'Positive-displacement machines or engines of flexible-wall type',
  },
  {
    c: 'F01B 21',
    d: 'Combinations of two or more machines or engines',
  },
  {
    c: 'F01B 23',
    d: 'Adaptations of machines or engines for special use; Combinations of engines with devices driven thereby',
  },
  {
    c: 'F01B 25',
    d: 'Regulating, controlling, or safety means',
  },
  {
    c: 'F01B 27',
    d: 'Starting of machines or engines',
  },
  {
    c: 'F01B 29',
    d: 'Machines or engines with pertinent characteristics other than those provided for in main groups F01B0001000000-F01B0027000000',
  },
  {
    c: 'F01B 31',
    d: 'Component parts, details, or accessories not provided for in, or of interest apart from, other groups',
  },
  {
    c: 'F01C',
    d: 'ROTARY-PISTON OR OSCILLATING-PISTON MACHINES OR ENGINES',
  },
  {
    c: 'F01C 1',
    d: 'Rotary-piston machines or engines',
  },
  {
    c: 'F01C 3',
    d: 'Rotary-piston machines or engines with non-parallel axes of movement of co-operating members',
  },
  {
    c: 'F01C 5',
    d: 'Rotary-piston machines or engines with the working-chamber walls at least partly resiliently deformable',
  },
  {
    c: 'F01C 7',
    d: 'Rotary-piston machines or engines with fluid ring or the like',
  },
  {
    c: 'F01C 9',
    d: 'Oscillating-piston machines or engines',
  },
  {
    c: 'F01C 11',
    d: 'Combinations of two or more machines or engines, each being of rotary-piston or oscillating-piston type',
  },
  {
    c: 'F01C 13',
    d: 'Adaptations of machines or engines for special use; Combinations of engines with devices driven thereby',
  },
  {
    c: 'F01C 17',
    d: 'Arrangements for drive of co-operating members, e.g. for rotary piston and casing',
  },
  {
    c: 'F01C 19',
    d: 'Sealing arrangements in rotary-piston machines or engines',
  },
  {
    c: 'F01C 20',
    d: 'Control of, monitoring of, or safety arrangements for, machines or engines',
  },
  {
    c: 'F01C 21',
    d: 'Component parts, details, or accessories, not provided for in groups F01C0001000000-F01C0020000000',
  },
  {
    c: 'F01D',
    d: 'NON-POSITIVE-DISPLACEMENT MACHINES OR ENGINES, e.g. STEAM TURBINES',
  },
  {
    c: 'F01D 1',
    d: 'Non-positive-displacement machines or engines, e.g. steam turbines',
  },
  {
    c: 'F01D 3',
    d: 'Machines or engines with axial-thrust balancing effected by working fluid',
  },
  {
    c: 'F01D 5',
    d: 'Blades; Blade-carrying members',
  },
  {
    c: 'F01D 7',
    d: 'Rotors with blades adjustable in operation; Control thereof',
  },
  {
    c: 'F01D 9',
    d: 'Stators',
  },
  {
    c: 'F01D 11',
    d: 'Preventing or minimising internal leakage of working fluid, e.g. between stages',
  },
  {
    c: 'F01D 13',
    d: 'Combinations of two or more machines or engines',
  },
  {
    c: 'F01D 15',
    d: 'Adaptations of machines or engines for special use; Combinations of engines with devices driven thereby',
  },
  {
    c: 'F01D 17',
    d: 'Regulating or controlling by varying flow',
  },
  {
    c: 'F01D 19',
    d: 'Starting of machines or engines; Regulating, controlling, or safety means in connection therewith',
  },
  {
    c: 'F01D 21',
    d: 'Shutting-down of machines or engines, e.g. in emergency; Regulating, controlling, or safety means not otherwise provided for',
  },
  {
    c: 'F01D 23',
    d: 'Non-positive-displacement machines or engines with movement other than pure rotation, e.g. of endless-chain type',
  },
  {
    c: 'F01D 25',
    d: 'Component parts, details, or accessories, not provided for in, or of interest apart from, other groups',
  },
  {
    c: 'F01K',
    d: 'STEAM ENGINE PLANTS; STEAM ACCUMULATORS; ENGINE PLANTS NOT OTHERWISE PROVIDED FOR; ENGINES USING SPECIAL WORKING FLUIDS OR CYCLES',
  },
  {
    c: 'F01K 1',
    d: 'Steam accumulators',
  },
  {
    c: 'F01K 3',
    d: 'Steam engine plants',
  },
  {
    c: 'F01K 5',
    d: 'Plants characterised by use of means for storing steam in an alkali to increase steam pressure, e.g. of Honigmann or Koenemann type',
  },
  {
    c: 'F01K 7',
    d: 'Steam engine plants characterised by the use of specific types of engine',
  },
  {
    c: 'F01K 9',
    d: 'Steam engine plants characterised by condensers arranged or modified to co-operate with the engines',
  },
  {
    c: 'F01K 11',
    d: 'Steam engine plants characterised by the engines being structurally combined with boilers or condensers',
  },
  {
    c: 'F01K 13',
    d: 'General layout or general methods of operation, of complete steam engine plants',
  },
  {
    c: 'F01K 15',
    d: 'Adaptations of steam engine plants for special use',
  },
  {
    c: 'F01K 17',
    d: 'Use of steam or condensate extracted or exhausted from steam engine plant',
  },
  {
    c: 'F01K 19',
    d: 'Regenerating or otherwise treating steam exhaust from steam engine plant',
  },
  {
    c: 'F01K 21',
    d: 'Steam engine plants not otherwise provided for',
  },
  {
    c: 'F01K 23',
    d: 'Plants characterised by more than one engine delivering power external to the plant, the engines being driven by different fluids',
  },
  {
    c: 'F01K 25',
    d: 'Plants or engines characterised by use of special working fluids, not otherwise provided for; Plants operating in closed cycles and not otherwise provided for',
  },
  {
    c: 'F01K 27',
    d: 'Plants for converting heat or fluid energy into mechanical energy, not otherwise provided for',
  },
  {
    c: 'F01L',
    d: 'CYCLICALLY OPERATING VALVES FOR MACHINES OR ENGINES',
  },
  {
    c: 'F01L 1',
    d: 'Valve-gear or valve arrangements for positive-displacement machines or engines other than steam engines, e.g. for internal-combustion piston engines, without provision for variable fluid distribution',
  },
  {
    c: 'F01L 3',
    d: 'Lift valves, i.e. cut-off apparatus with closure members having at least a component of their opening and closing motion perpendicular to the closing faces; Parts or accessories thereof',
  },
  {
    c: 'F01L 5',
    d: 'Slide-valve gear or valve arrangements',
  },
  {
    c: 'F01L 7',
    d: 'Rotary or oscillatory slide-valve gear or valve arrangements',
  },
  {
    c: 'F01L 9',
    d: 'Valve-gear or valve arrangements actuated non-mechanically',
  },
  {
    c: 'F01L 11',
    d: 'Valve arrangements in working piston or piston-rod',
  },
  {
    c: 'F01L 13',
    d: 'Modifications of valve-gear to facilitate reversing, braking, starting, changing compression ratio, or other specific operations',
  },
  {
    c: 'F01L 15',
    d: 'Valve-gear or valve arrangements specially adapted for steam engines, or specially adapted for other positive-displacement machines or engines with variable working-fluid distribution',
  },
  {
    c: 'F01L 17',
    d: 'Slide-valve gear or valve arrangements with cylindrical, sleeve, or part-annularly-shaped valves surrounding working cylinder or piston',
  },
  {
    c: 'F01L 19',
    d: 'Slide-valve gear or valve arrangements with reciprocatory and other movement of same valve, other than provided for in group F01L0017000000, e.g. longitudinally and in cross direction of working cylinder',
  },
  {
    c: 'F01L 21',
    d: 'Use of working pistons or piston-rods as fluid-distributing valves or as valve-supporting elements, e.g. in free-piston machines',
  },
  {
    c: 'F01L 23',
    d: 'Valves controlled by impact of piston, e.g. in free-piston machines',
  },
  {
    c: 'F01L 25',
    d: 'Drive, or adjustment during operation, of distribution or expansion valves by non-mechanical means',
  },
  {
    c: 'F01L 27',
    d: 'Distribution or expansion-valve gear peculiar to free-piston machines or engines and not provided for in groups F01L0021000000-F01L0025000000',
  },
  {
    c: 'F01L 29',
    d: 'Reversing-gear',
  },
  {
    c: 'F01L 31',
    d: 'Valve drive, valve adjustment during operation, or other valve control, not provided for in groups F01L0015000000-F01L0029000000',
  },
  {
    c: 'F01L 33',
    d: 'Rotary or oscillatory slide-valve gear or lift-valve gear or such valve arrangements specially adapted for steam engines, or specially adapted for other positive-displacement machines or engines with variable working-fluid distribution',
  },
  {
    c: 'F01L 35',
    d: 'Lift-valve gear or valve arrangements specially adapted for machines or engines with variable fluid distribution',
  },
  {
    c: 'F01M',
    d: 'LUBRICATING OF MACHINES OR ENGINES IN GENERAL; LUBRICATING INTERNAL-COMBUSTION ENGINES; CRANKCASE VENTILATING',
  },
  {
    c: 'F01M 1',
    d: 'Pressure lubrication',
  },
  {
    c: 'F01M 3',
    d: 'Lubrication specially adapted for engines with crankcase compression of fuel-air mixture, or for other engines in which lubricant is contained in fuel, combustion air, or fuel-air mixture',
  },
  {
    c: 'F01M 5',
    d: 'Heating, cooling, or controlling temperature of lubricant',
  },
  {
    c: 'F01M 7',
    d: 'Lubrication means specially adapted for machine or engine running-in',
  },
  {
    c: 'F01M 9',
    d: 'Lubrication means having pertinent characteristics not provided for in, or of interest apart from, groups F01M0001000000-F01M0007000000',
  },
  {
    c: 'F01M 11',
    d: 'Component parts, details, or accessories, not provided for in, or of interest apart from, groups F01M0001000000-F01M0009000000',
  },
  {
    c: 'F01M 13',
    d: 'Crankcase ventilating or breathing',
  },
  {
    c: 'F01N',
    d: 'GAS-FLOW SILENCERS OR EXHAUST APPARATUS FOR MACHINES OR ENGINES IN GENERAL; GAS-FLOW SILENCERS OR EXHAUST APPARATUS FOR INTERNAL-COMBUSTION ENGINES',
  },
  {
    c: 'F01N 1',
    d: 'Silencing apparatus characterised by method of silencing',
  },
  {
    c: 'F01N 3',
    d: 'Exhaust or silencing apparatus having means for purifying, rendering innocuous, or otherwise treating exhaust',
  },
  {
    c: 'F01N 5',
    d: 'Exhaust or silencing apparatus combined or associated with devices profiting by exhaust energy',
  },
  {
    c: 'F01N 9',
    d: 'Electrical control of exhaust gas treating apparatus',
  },
  {
    c: 'F01N 11',
    d: 'Monitoring or diagnostic devices for exhaust-gas treatment apparatus',
  },
  {
    c: 'F01N 13',
    d: 'Exhaust or silencing apparatus characterised by constructional features',
  },
  {
    c: 'F01N 99',
    d: 'n.a.',
  },
  {
    c: 'F01P',
    d: 'COOLING OF MACHINES OR ENGINES IN GENERAL; COOLING OF INTERNAL-COMBUSTION ENGINES',
  },
  {
    c: 'F01P 1',
    d: 'Air cooling; Liquid cooling',
  },
  {
    c: 'F01P 3',
    d: 'Liquid cooling',
  },
  {
    c: 'F01P 5',
    d: 'Pumping cooling-air or liquid coolants; Controlling circulation or supply of coolants',
  },
  {
    c: 'F01P 7',
    d: 'Controlling of coolant flow',
  },
  {
    c: 'F01P 9',
    d: 'Cooling having pertinent characteristics not provided for in, or of interest apart from, groups F01P0001000000-F01P0007000000',
  },
  {
    c: 'F01P 11',
    d: 'Component parts, details, or accessories, not provided for in, or of interest apart from, groups F01P0001000000-F01P0009000000',
  },
  {
    c: 'F02',
    d: 'COMBUSTION ENGINES; HOT-GAS OR COMBUSTION-PRODUCT ENGINE PLANTS',
  },
  {
    c: 'F02B',
    d: 'INTERNAL-COMBUSTION PISTON ENGINES; COMBUSTION ENGINES IN GENERAL',
  },
  {
    c: 'F02B 1',
    d: 'Engines characterised by the working fluid to be compressed or characterised by the type of ignition',
  },
  {
    c: 'F02B 3',
    d: 'Engines characterised by air compression and subsequent fuel addition',
  },
  {
    c: 'F02B 5',
    d: 'Engines characterised by positive ignition',
  },
  {
    c: 'F02B 7',
    d: 'Engines characterised by the fuel-air charge being ignited by compression ignition of an additional fuel',
  },
  {
    c: 'F02B 9',
    d: 'Engines characterised by other types of ignition',
  },
  {
    c: 'F02B 11',
    d: 'Engines characterised by both fuel-air mixture compression and air compression, or characterised by both positive ignition and compression ignition, e.g. in different cylinders',
  },
  {
    c: 'F02B 13',
    d: 'Engines characterised by the method of introducing liquid fuel into cylinders',
  },
  {
    c: 'F02B 15',
    d: 'Engines characterised by the method of introducing liquid fuel into cylinders and not otherwise provided for',
  },
  {
    c: 'F02B 17',
    d: 'Engines characterised by means for effecting stratification of charge in cylinders',
  },
  {
    c: 'F02B 19',
    d: 'Engines characterised by precombustion chambers or air-storage chambers, or characterised by special shape or construction of combustion chambers to improve operation',
  },
  {
    c: 'F02B 21',
    d: 'Engines characterised by air-storage chambers',
  },
  {
    c: 'F02B 23',
    d: 'Other engines characterised by special shape or construction of combustion chambers to improve operation',
  },
  {
    c: 'F02B 25',
    d: 'Engines characterised by provision for charging or scavenging',
  },
  {
    c: 'F02B 27',
    d: 'Use of kinetic or wave energy of charge in induction systems, or of combustion residues in exhaust systems, for improving quantity of charge or for increasing removal of combustion residues',
  },
  {
    c: 'F02B 29',
    d: 'Engines characterised by provision for charging or scavenging not provided for in groups F02B0025000000, F02B0027000000 or F02B0033000000-F02B0039000000; Details thereof',
  },
  {
    c: 'F02B 31',
    d: 'Modifying induction systems for imparting a rotation to the charge in the cylinder',
  },
  {
    c: 'F02B 33',
    d: 'Engines characterised by provision of driven charging or scavenging pumps',
  },
  {
    c: 'F02B 35',
    d: 'Engines characterised by provision of pumps for sucking combustion residues from cylinders',
  },
  {
    c: 'F02B 37',
    d: 'Engines characterised by provision of pumps driven at least for part of the time by exhaust',
  },
  {
    c: 'F02B 39',
    d: 'Component parts, details, or accessories relating to driven charging or scavenging pumps, not provided for in groups F02B0033000000-F02B0037000000',
  },
  {
    c: 'F02B 41',
    d: 'Engines characterised by special means for improving conversion of heat or pressure energy into mechanical power',
  },
  {
    c: 'F02B 43',
    d: 'Engines operating on non-liquid fuels; Plants including such engines, i.e. combinations of the engine with fuel-generating apparatus',
  },
  {
    c: 'F02B 45',
    d: 'Engines characterised by operating on non-liquid fuels other than gas; Plants including such engines',
  },
  {
    c: 'F02B 47',
    d: 'Methods of operating engines involving specific pre-treating of, or adding specific substances to, combustion air, fuel or fuel-air mixture of the engines, and not otherwise provided for',
  },
  {
    c: 'F02B 49',
    d: "Methods of operating air-compressing compression-ignition engines involving introduction of small quantities of fuel in the form of a fine mist into the air in the engine's intake",
  },
  {
    c: 'F02B 51',
    d: 'Other methods of operating engines involving pre-treating of, or adding substances to, combustion air, fuel, or fuel-air mixture of the engines',
  },
  {
    c: 'F02B 53',
    d: 'Internal-combustion aspects of rotary-piston or oscillating-piston engines',
  },
  {
    c: 'F02B 55',
    d: 'Internal-combustion aspects of rotary pistons; Outer members for co-operation with rotary pistons',
  },
  {
    c: 'F02B 57',
    d: 'Internal-combustion aspects of reciprocating-piston engines with movable cylinders',
  },
  {
    c: 'F02B 59',
    d: 'Internal-combustion aspects of other reciprocating-piston engines with movable, e.g. oscillating, cylinders',
  },
  {
    c: 'F02B 61',
    d: 'Adaptations of engines for special use; Combinations of engines with devices other than engine parts or auxiliaries',
  },
  {
    c: 'F02B 63',
    d: 'Adaptations of engines for driving pumps, hand-held tools or electric generators; Portable combinations of engines with engine-driven devices',
  },
  {
    c: 'F02B 65',
    d: 'Adaptations of engines for special uses not provided for in groups F02B0061000000 or F02B0063000000; Combinations of engines with other devices, e.g. with non-driven apparatus',
  },
  {
    c: 'F02B 67',
    d: 'Engines with pertinent characteristics other than those provided for in, or of interest apart from, preceding main groups',
  },
  {
    c: 'F02B 69',
    d: 'Internal-combustion engines convertible into other combustion-engine type, not provided for in group F02B0011000000; Internal-combustion engines of different types characterised by constructions facilitating use of same main engine-parts in different types',
  },
  {
    c: 'F02B 71',
    d: 'Free-piston engines; Engines without rotary main shaft',
  },
  {
    c: 'F02B 73',
    d: 'Combinations of two or more engines, not otherwise provided for',
  },
  {
    c: 'F02B 75',
    d: 'Other engines, e.g. single-cylinder engines',
  },
  {
    c: 'F02B 77',
    d: 'Component parts, details, or accessories, not otherwise provided for',
  },
  {
    c: 'F02B 79',
    d: 'Running-in of internal-combustion engines',
  },
  {
    c: 'F02C',
    d: 'GAS-TURBINE PLANTS; AIR INTAKES FOR JET-PROPULSION PLANTS; CONTROLLING FUEL SUPPLY IN AIR-BREATHING JET-PROPULSION PLANTS',
  },
  {
    c: 'F02C 1',
    d: 'Gas-turbine plants characterised by the use of hot gases or unheated pressurised gases, as the working fluid',
  },
  {
    c: 'F02C 3',
    d: 'Gas-turbine plants characterised by the use of combustion products as the working fluid',
  },
  {
    c: 'F02C 5',
    d: 'Gas-turbine plants characterised by the working fluid being generated by intermittent combustion',
  },
  {
    c: 'F02C 6',
    d: 'Plural gas-turbine plants; Combinations of gas-turbine plants with other apparatus',
  },
  {
    c: 'F02C 7',
    d: 'Features, component parts, details or accessories, not provided for in, or of interest apart from, groups F02C0001000000-F02C0006000000; Air intakes for jet-propulsion plants',
  },
  {
    c: 'F02C 9',
    d: 'Controlling gas-turbine plants; Controlling fuel supply in air-breathing jet-propulsion plants',
  },
  {
    c: 'F02D',
    d: 'CONTROLLING COMBUSTION ENGINES',
  },
  {
    c: 'F02D 1',
    d: 'Controlling, e.g. regulating, fuel injection',
  },
  {
    c: 'F02D 3',
    d: 'Controlling low-pressure fuel injection, i.e. where the air-fuel mixture containing fuel thus injected will be substantially compressed by the compression stroke of the engine, by means other than controlling only an injection pump',
  },
  {
    c: 'F02D 7',
    d: 'Other non-electrical fuel injection control',
  },
  {
    c: 'F02D 9',
    d: 'Controlling engines by throttling air or fuel-and-air induction conduits or exhaust conduits',
  },
  {
    c: 'F02D 11',
    d: 'Arrangements for, or adaptations to, non-automatic engine control initiation means, e.g. operator initiated',
  },
  {
    c: 'F02D 13',
    d: 'Controlling the engine output power by varying inlet or exhaust valve operating characteristics, e.g. timing',
  },
  {
    c: 'F02D 15',
    d: 'Varying compression ratio',
  },
  {
    c: 'F02D 17',
    d: 'Controlling engines by cutting-out individual cylinders; Rendering engines inoperative or idling',
  },
  {
    c: 'F02D 19',
    d: 'Controlling peculiar to specified types or adaptations of engines',
  },
  {
    c: 'F02D 21',
    d: 'Controlling engines characterised by their being supplied with non-airborne oxygen or other non-fuel gas',
  },
  {
    c: 'F02D 23',
    d: 'Controlling engines characterised by their being supercharged',
  },
  {
    c: 'F02D 25',
    d: 'Controlling two or more co-operating engines',
  },
  {
    c: 'F02D 27',
    d: 'Controlling engines characterised by their being reversible',
  },
  {
    c: 'F02D 28',
    d: 'Programme-control of engines',
  },
  {
    c: 'F02D 29',
    d: 'Controlling engines, such controlling being peculiar to the devices driven thereby, the devices being other than parts or accessories essential to engine operation, e.g. controlling of engines by signals external thereto',
  },
  {
    c: 'F02D 31',
    d: 'Other non-electrical control of combustion engines',
  },
  {
    c: 'F02D 33',
    d: 'Non-electrical control of delivery of fuel or combustion-air, not otherwise provided for',
  },
  {
    c: 'F02D 35',
    d: 'Non-electrical control of engines, dependent on conditions exterior or interior to engines, not otherwise provided for',
  },
  {
    c: 'F02D 37',
    d: 'Non-electrical conjoint control of  two or more functions of engines, not otherwise provided for',
  },
  {
    c: 'F02D 39',
    d: 'Other non-electrical control',
  },
  {
    c: 'F02D 41',
    d: 'Electrical control of combustion engines',
  },
  {
    c: 'F02D 43',
    d: 'Conjoint electrical control of two or more functions, e.g. ignition, fuel-air mixture, recirculation, supercharging, exhaust-gas treatment',
  },
  {
    c: 'F02D 45',
    d: 'Electrical control not provided for in groups F02D0041000000-F02D0043000000',
  },
  {
    c: 'F02F',
    d: 'CYLINDERS, PISTONS, OR CASINGS FOR COMBUSTION ENGINES; ARRANGEMENTS OF SEALINGS IN COMBUSTION ENGINES',
  },
  {
    c: 'F02F 1',
    d: 'Cylinders; Cylinder heads',
  },
  {
    c: 'F02F 3',
    d: 'Pistons',
  },
  {
    c: 'F02F 5',
    d: 'Piston rings, e.g. associated with piston crown',
  },
  {
    c: 'F02F 7',
    d: 'Casings, e.g. crankcases',
  },
  {
    c: 'F02F 11',
    d: 'Arrangements of sealings in combustion engines',
  },
  {
    c: 'F02G',
    d: 'HOT-GAS OR COMBUSTION-PRODUCT POSITIVE-DISPLACEMENT ENGINE PLANTS',
  },
  {
    c: 'F02G 1',
    d: 'Hot gas positive-displacement engine plants',
  },
  {
    c: 'F02G 3',
    d: 'Positive-displacement engine plants characterised by the working gas being generated by combustion in the plant',
  },
  {
    c: 'F02G 5',
    d: 'Profiting from waste heat of combustion engines, not otherwise provided for',
  },
  {
    c: 'F02K',
    d: 'JET-PROPULSION PLANTS',
  },
  {
    c: 'F02K 1',
    d: 'Plants characterised by the form or arrangement of the jet pipe or nozzle; Jet pipes or nozzles peculiar thereto',
  },
  {
    c: 'F02K 3',
    d: 'Plants including a gas turbine driving a compressor or a ducted fan',
  },
  {
    c: 'F02K 5',
    d: 'Plants including an engine, other than a gas turbine, driving a compressor or a ducted fan',
  },
  {
    c: 'F02K 7',
    d: 'Plants in which the working-fluid is used in a jet only, i.e. the plants not having a turbine or other engine driving a compressor or a ducted fan; Control thereof',
  },
  {
    c: 'F02K 9',
    d: 'Rocket-engine plants, i.e. plants carrying both fuel and oxidant therefor; Control thereof',
  },
  {
    c: 'F02K 99',
    d: 'n.a.',
  },
  {
    c: 'F02M',
    d: 'SUPPLYING COMBUSTION ENGINES IN GENERAL WITH COMBUSTIBLE MIXTURES OR CONSTITUENTS THEREOF',
  },
  {
    c: 'F02M 1',
    d: 'Carburettors for liquid fuels',
  },
  {
    c: 'F02M 3',
    d: 'Idling devices for carburettors',
  },
  {
    c: 'F02M 5',
    d: 'Float-controlled apparatus for maintaining a constant fuel level in carburettors',
  },
  {
    c: 'F02M 7',
    d: 'Carburettors with means for influencing, e.g. enriching or keeping constant, fuel/air ratio of charge under varying conditions',
  },
  {
    c: 'F02M 9',
    d: 'Carburettors having air or fuel-air mixture passage throttling valves other than of butterfly type',
  },
  {
    c: 'F02M 11',
    d: 'Multi-stage carburettors; Register-type carburettors, i.e. with slidable or rotatable throttling valves in which a plurality of fuel nozzles, other than only an idling nozzle and a main one, are sequentially exposed to air stream by throttling valve',
  },
  {
    c: 'F02M 13',
    d: 'Arrangements of two or more separate carburettors',
  },
  {
    c: 'F02M 15',
    d: 'Carburettors with heating, cooling or thermal insulating means for combustion-air, fuel or fuel-air mixture',
  },
  {
    c: 'F02M 17',
    d: 'Carburettors having pertinent characteristics not provided for in, or of interest apart from, the apparatus of main groups F02M0001000000-F02M0015000000',
  },
  {
    c: 'F02M 19',
    d: 'Details, component parts, or accessories of carburettors, not provided for in, or of interest apart from, the apparatus of groups F02M0001000000-F02M0017000000',
  },
  {
    c: 'F02M 21',
    d: 'Apparatus for supplying engines with non-liquid fuels, e.g. gaseous fuels stored in liquid form',
  },
  {
    c: 'F02M 23',
    d: 'Engine-pertinent apparatus for feeding, or treating before their admission to engine, combustion-air, fuel, or fuel-air mixture',
  },
  {
    c: 'F02M 25',
    d: 'Engine-pertinent apparatus for adding non-fuel substances or small quantities of secondary fuel to combustion-air, main fuel or fuel-air mixture',
  },
  {
    c: 'F02M 26',
    d: 'Engine-pertinent apparatus for adding exhaust gases to combustion-air, main fuel or fuel-air mixture, e.g. by exhaust gas recirculation [EGR] systems',
  },
  {
    c: 'F02M 27',
    d: 'Apparatus for treating combustion-air, fuel, or fuel-air mixture, by catalysts, electric means, magnetism, rays, sonic waves, or the like',
  },
  {
    c: 'F02M 29',
    d: 'Apparatus for re-atomising condensed fuel or homogenising fuel-air mixture',
  },
  {
    c: 'F02M 31',
    d: 'Apparatus for thermally treating combustion-air, fuel, or fuel-air mixture',
  },
  {
    c: 'F02M 33',
    d: 'Other apparatus for treating combustion-air, fuel or fuel-air mixture',
  },
  {
    c: 'F02M 35',
    d: 'Combustion-air cleaners, air intakes, intake silencers, or induction systems specially adapted for, or arranged on, internal-combustion engines',
  },
  {
    c: 'F02M 37',
    d: 'Apparatus or systems for feeding liquid fuel from storage containers to carburettors or fuel-injection apparatus; Arrangements for purifying liquid fuel specially adapted for, or arranged on, internal-combustion engines',
  },
  {
    c: 'F02M 39',
    d: 'Fuel-injection apparatus',
  },
  {
    c: 'F02M 41',
    d: 'Fuel-injection apparatus with two or more injectors fed from a common pressure-source sequentially by means of a distributor',
  },
  {
    c: 'F02M 43',
    d: 'Fuel-injection apparatus operating simultaneously on two or more fuels or on a liquid fuel and another liquid, e.g. the other liquid being an anti-knock additive',
  },
  {
    c: 'F02M 45',
    d: 'Fuel-injection apparatus characterised by having a cyclic delivery of specific time/pressure or time/quantity relationship',
  },
  {
    c: 'F02M 47',
    d: 'Fuel-injection apparatus operated cyclically with fuel-injection valves actuated by fluid pressure',
  },
  {
    c: 'F02M 49',
    d: 'Fuel-injection apparatus in which injection pumps are driven, or injectors are actuated, by the pressure in engine working cylinders, or by impact of engine working piston',
  },
  {
    c: 'F02M 51',
    d: 'Fuel-injection apparatus characterised by being operated electrically',
  },
  {
    c: 'F02M 53',
    d: 'Fuel-injection apparatus characterised by having heating, cooling, or thermally-insulating means',
  },
  {
    c: 'F02M 55',
    d: 'Fuel-injection apparatus characterised by their fuel conduits or their venting means',
  },
  {
    c: 'F02M 57',
    d: 'Fuel injectors combined or associated with other devices',
  },
  {
    c: 'F02M 59',
    d: 'Pumps specially adapted for fuel-injection and not provided for in groups F02M0039000000-F02M0057000000',
  },
  {
    c: 'F02M 61',
    d: 'Fuel injectors not provided for in groups F02M0039000000-F02M0057000000 or F02M0067000000',
  },
  {
    c: 'F02M 63',
    d: 'Other fuel-injection apparatus having pertinent characteristics not provided for in groups F02M0039000000-F02M0057000000 or F02M0067000000; Details, component parts or accessories of fuel-injection apparatus, not provided for in, or of interest apart from, the apparatus of groups F02M0039000000-F02M0061000000 or F02M0067000000',
  },
  {
    c: 'F02M 65',
    d: 'Testing fuel-injection apparatus, e.g. testing injection timing',
  },
  {
    c: 'F02M 67',
    d: 'Apparatus in which fuel-injection is effected by means of high-pressure gas, the gas carrying the fuel into working cylinders of the engine, e.g. air-injection type',
  },
  {
    c: 'F02M 69',
    d: 'Low-pressure fuel-injection apparatus',
  },
  {
    c: 'F02M 71',
    d: 'Combinations of carburettors and low-pressure fuel-injection apparatus',
  },
  {
    c: 'F02M 99',
    d: 'n.a.',
  },
  {
    c: 'F02N',
    d: 'STARTING OF COMBUSTION ENGINES',
  },
  {
    c: 'F02N 1',
    d: 'Muscle-operated starting apparatus',
  },
  {
    c: 'F02N 3',
    d: 'Other muscle-operated starting apparatus',
  },
  {
    c: 'F02N 5',
    d: 'Power-operated starting apparatus; Muscle-operated starting apparatus with intermediate power storage',
  },
  {
    c: 'F02N 7',
    d: 'Starting apparatus having fluid-driven auxiliary engines or apparatus',
  },
  {
    c: 'F02N 9',
    d: 'Starting of engines by supplying auxiliary pressure fluid to their working chambers',
  },
  {
    c: 'F02N 11',
    d: 'Starting of engines by means of electric motors',
  },
  {
    c: 'F02N 13',
    d: 'Starting of engines, or driving of starting apparatus by use of explosives, e.g. stored in cartridges',
  },
  {
    c: 'F02N 15',
    d: 'Other power-operated starting apparatus; Component parts, details, or accessories, not provided for in, or of interest apart from, groups F02N0005000000-F02N0013000000',
  },
  {
    c: 'F02N 19',
    d: 'Starting aids for combustion engines, not otherwise provided for',
  },
  {
    c: 'F02N 99',
    d: 'n.a.',
  },
  {
    c: 'F02P',
    d: 'IGNITION, OTHER THAN COMPRESSION IGNITION, FOR INTERNAL-COMBUSTION ENGINES; TESTING OF IGNITION TIMING IN COMPRESSION-IGNITION ENGINES',
  },
  {
    c: 'F02P 1',
    d: 'Electric spark ignition installations characterised by the type of ignition power generation or storage',
  },
  {
    c: 'F02P 3',
    d: 'Other electric spark ignition installations characterised by the type of ignition power generation storage',
  },
  {
    c: 'F02P 5',
    d: 'Advancing or retarding electric ignition spark; Arrangements of distributors or of circuit-makers or -breakers for electric spark ignition; Electric spark ignition control or safety means, not otherwise provided for',
  },
  {
    c: 'F02P 7',
    d: 'Arrangement of distributors, circuit-makers, circuit-breakers or pick-up devices for electric spark ignition',
  },
  {
    c: 'F02P 9',
    d: 'Electric spark ignition control, not otherwise provided for',
  },
  {
    c: 'F02P 11',
    d: 'Safety means for electric spark ignition, not otherwise provided for',
  },
  {
    c: 'F02P 13',
    d: 'Sparking plugs structurally combined with other parts of internal-combustion engines',
  },
  {
    c: 'F02P 15',
    d: 'Electric spark ignition having characteristics not provided for in, or of interest apart from, groups F02P0001000000-F02P0013000000',
  },
  {
    c: 'F02P 17',
    d: 'Testing of ignition installations, e.g. in combination with adjusting',
  },
  {
    c: 'F02P 19',
    d: 'Other ignition',
  },
  {
    c: 'F02P 21',
    d: 'Direct use of flames or burners for ignition',
  },
  {
    c: 'F02P 23',
    d: 'Other ignition',
  },
  {
    c: 'F03',
    d: 'MACHINES OR ENGINES FOR LIQUIDS; WIND, SPRING, OR WEIGHT MOTORS; PRODUCING MECHANICAL POWER OR A REACTIVE PROPULSIVE THRUST, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'F03B',
    d: 'MACHINES OR ENGINES FOR LIQUIDS',
  },
  {
    c: 'F03B 1',
    d: 'Engines of impulse type, i.e. turbines with jets of high-velocity liquid impinging on bladed or like rotors, e.g. Pelton wheels; Parts or details peculiar thereto',
  },
  {
    c: 'F03B 3',
    d: 'Machines or engines of reaction type; Parts or details peculiar thereto',
  },
  {
    c: 'F03B 5',
    d: 'Machines or engines characterised by non-bladed rotors, e.g. serrated, using friction',
  },
  {
    c: 'F03B 7',
    d: 'Water wheels',
  },
  {
    c: 'F03B 9',
    d: 'Endless-chain type machines or engines',
  },
  {
    c: 'F03B 11',
    d: 'Parts or details not provided for in, or of interest apart from, groups F03B0001000000-F03B0009000000',
  },
  {
    c: 'F03B 13',
    d: 'Adaptations of machines or engines for special use; Combinations of machines or engines with driving or driven apparatus',
  },
  {
    c: 'F03B 15',
    d: 'Controlling',
  },
  {
    c: 'F03B 17',
    d: 'Other machines or engines',
  },
  {
    c: 'F03C',
    d: 'POSITIVE-DISPLACEMENT ENGINES DRIVEN BY LIQUIDS',
  },
  {
    c: 'F03C 1',
    d: 'Reciprocating-piston liquid engines',
  },
  {
    c: 'F03C 2',
    d: 'Rotary-piston engines',
  },
  {
    c: 'F03C 4',
    d: 'Oscillating-piston engines',
  },
  {
    c: 'F03C 7',
    d: 'Engines of flexible-wall type',
  },
  {
    c: 'F03C 99',
    d: 'n.a.',
  },
  {
    c: 'F03D',
    d: 'WIND MOTORS',
  },
  {
    c: 'F03D 1',
    d: 'Wind motors with rotation axis substantially parallel to the air flow entering the rotor',
  },
  {
    c: 'F03D 3',
    d: 'Wind motors with rotation axis substantially perpendicular to the air flow entering the rotor',
  },
  {
    c: 'F03D 5',
    d: 'Other wind motors',
  },
  {
    c: 'F03D 7',
    d: 'Controlling wind motors',
  },
  {
    c: 'F03D 9',
    d: 'Adaptations of wind motors for special use; Combinations of wind motors with apparatus driven thereby; Wind motors specially adapted for installation in particular locations',
  },
  {
    c: 'F03D 13',
    d: 'Assembly, mounting or commissioning of wind motors; Arrangements specially adapted for transporting wind motor components',
  },
  {
    c: 'F03D 15',
    d: 'Transmission of mechanical power',
  },
  {
    c: 'F03D 17',
    d: 'Monitoring or testing of wind motors, e.g. diagnostics',
  },
  {
    c: 'F03D 80',
    d: 'Details, components or accessories not provided for in groups F03D0001000000-F03D0017000000',
  },
  {
    c: 'F03G',
    d: 'SPRING, WEIGHT, INERTIA, OR LIKE MOTORS; MECHANICAL-POWER-PRODUCING DEVICES OR MECHANISMS, NOT OTHERWISE PROVIDED FOR OR USING ENERGY SOURCES NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'F03G 1',
    d: 'Spring motors',
  },
  {
    c: 'F03G 3',
    d: 'Other motors, e.g. gravity or inertia motors',
  },
  {
    c: 'F03G 4',
    d: 'Devices for producing mechanical power from geothermal energy',
  },
  {
    c: 'F03G 5',
    d: 'Devices for producing mechanical power from muscle energy',
  },
  {
    c: 'F03G 6',
    d: 'Devices for producing mechanical power from solar energy',
  },
  {
    c: 'F03G 7',
    d: 'Mechanical-power-producing mechanisms, not otherwise provided for or using energy sources not otherwise provided for',
  },
  {
    c: 'F03H',
    d: 'PRODUCING A REACTIVE PROPULSIVE THRUST, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'F03H 1',
    d: 'Use of plasma to produce a reactive propulsive thrust',
  },
  {
    c: 'F03H 3',
    d: 'Use of photons to produce a reactive propulsive thrust',
  },
  {
    c: 'F03H 99',
    d: 'n.a.',
  },
  {
    c: 'F04',
    d: 'POSITIVE-DISPLACEMENT MACHINES FOR LIQUIDS; PUMPS FOR LIQUIDS OR ELASTIC FLUIDS',
  },
  {
    c: 'F04B',
    d: 'POSITIVE-DISPLACEMENT MACHINES FOR LIQUIDS; PUMPS',
  },
  {
    c: 'F04B 1',
    d: 'Pumps for liquids or for liquid and elastic fluids; Positive-displacement machines for liquids',
  },
  {
    c: 'F04B 3',
    d: 'Machines or pumps with pistons coacting within one cylinder, e.g. multi-stage',
  },
  {
    c: 'F04B 5',
    d: 'Machines or pumps with differential-surface pistons',
  },
  {
    c: 'F04B 7',
    d: 'Piston machines or pumps characterised by having positively-driven valving',
  },
  {
    c: 'F04B 9',
    d: 'Piston machines or pumps characterised by the driving or driven means to or from their working members',
  },
  {
    c: 'F04B 11',
    d: 'Equalisation of pulses, e.g. by use of air vessels; Counteracting cavitation',
  },
  {
    c: 'F04B 13',
    d: 'Pumps specially modified to deliver fixed or variable measured quantities',
  },
  {
    c: 'F04B 15',
    d: 'Pumps adapted to handle specific fluids, e.g. by selection of specific materials for pumps or pump parts',
  },
  {
    c: 'F04B 17',
    d: 'Pumps characterised by combination with, or adaptation to, specific driving engines or motors',
  },
  {
    c: 'F04B 19',
    d: 'Machines or pumps having pertinent characteristics not provided for in, or of interest apart from, groups F04B0001000000-F04B0017000000',
  },
  {
    c: 'F04B 23',
    d: 'Pumping installations or systems',
  },
  {
    c: 'F04B 25',
    d: 'Pumps specially adapted for elastic fluids',
  },
  {
    c: 'F04B 27',
    d: 'Multi-cylinder pumps specially adapted for elastic fluids and characterised by number or arrangement of cylinders',
  },
  {
    c: 'F04B 31',
    d: 'Free-piston pumps specially adapted for elastic fluids; Systems incorporating such pumps',
  },
  {
    c: 'F04B 33',
    d: 'Pumps specially adapted for elastic fluids actuated by muscle power, e.g. for inflating',
  },
  {
    c: 'F04B 35',
    d: 'Piston pumps specially adapted for elastic fluids and characterised by the driving means to their working members, or by combination with, or adaptation to, specific driving engines or motors, not otherwise provided for',
  },
  {
    c: 'F04B 37',
    d: 'Pumps specially adapted for elastic fluids and having pertinent characteristics not provided for in, or of interest apart from, groups F04B0025000000-F04B0035000000',
  },
  {
    c: 'F04B 39',
    d: 'Component parts, details, or accessories, of pumps or pumping systems specially adapted for elastic fluids, not otherwise provided for in, or of interest apart from, groups F04B0025000000-F04B0037000000',
  },
  {
    c: 'F04B 41',
    d: 'Pumping installations or systems specially adapted for elastic fluids',
  },
  {
    c: 'F04B 43',
    d: 'Machines or pumps having flexible working members',
  },
  {
    c: 'F04B 45',
    d: 'Pumps or pumping installations having flexible working members and specially adapted for elastic fluids',
  },
  {
    c: 'F04B 47',
    d: 'Pumps or pumping installations specially adapted for raising fluids from great depths, e.g. well pumps',
  },
  {
    c: 'F04B 49',
    d: 'Control of, or safety measures for, machines, pumps, or pumping installations, not otherwise provided for in, or of interest apart from, groups F04B0001000000-F04B0047000000',
  },
  {
    c: 'F04B 51',
    d: 'Testing machines, pumps, or pumping installations',
  },
  {
    c: 'F04B 53',
    d: 'Component parts, details or accessories not provided for in, or of interest apart from, groups F04B0001000000-F04B0023000000 or F04B0039000000-F04B0047000000',
  },
  {
    c: 'F04C',
    d: 'ROTARY-PISTON, OR OSCILLATING-PISTON, POSITIVE-DISPLACEMENT MACHINES FOR LIQUIDS',
  },
  {
    c: 'F04C 2',
    d: 'Machines for liquids; Pumps for liquids or for liquids and elastic fluids',
  },
  {
    c: 'F04C 3',
    d: 'Rotary-piston machines or pumps, with non-parallel axes of movement of co-operating members, e.g. of screw type',
  },
  {
    c: 'F04C 5',
    d: 'Rotary-piston machines or pumps with the working-chamber walls at least partly resiliently deformable',
  },
  {
    c: 'F04C 7',
    d: 'Rotary-piston machines or pumps with fluid ring or the like',
  },
  {
    c: 'F04C 9',
    d: 'Oscillating-piston machines or pumps',
  },
  {
    c: 'F04C 11',
    d: 'Combinations of two or more machines or pumps, each being of rotary-piston or oscillating-piston type',
  },
  {
    c: 'F04C 13',
    d: 'Adaptations of machines or pumps for special use, e.g. for extremely high pressures',
  },
  {
    c: 'F04C 14',
    d: 'Control of, monitoring of, or safety arrangements for, machines, pumps or pumping installations',
  },
  {
    c: 'F04C 15',
    d: 'Component parts, details or accessories of machines, pumps or pumping installations, not provided for in groups F04C0002000000-F04C0014000000',
  },
  {
    c: 'F04C 18',
    d: 'Pumps specially adapted for elastic fluids',
  },
  {
    c: 'F04C 19',
    d: 'Rotary-piston pumps with fluid ring or the like, specially adapted for elastic fluids',
  },
  {
    c: 'F04C 21',
    d: 'Oscillating-piston pumps specially adapted for elastic fluids',
  },
  {
    c: 'F04C 23',
    d: 'Combinations of two or more pumps, each being of rotary-piston or oscillating-piston type, specially adapted for elastic fluids; Pumping installations specially adapted for elastic fluids; Multi-stage pumps specially adapted for elastic fluids',
  },
  {
    c: 'F04C 25',
    d: 'Adaptations for special use of pumps for elastic fluids',
  },
  {
    c: 'F04C 27',
    d: 'Sealing arrangements in rotary-piston pumps specially adapted for elastic fluids',
  },
  {
    c: 'F04C 28',
    d: 'Control of, monitoring of, or safety arrangements for, pumps or pumping installations specially adapted for elastic fluids',
  },
  {
    c: 'F04C 29',
    d: 'Component parts, details, or accessories, of pumps or pumping installations specially adapted for elastic fluids, not provided for in groups F04C0018000000-F04C0028000000',
  },
  {
    c: 'F04D',
    d: 'NON-POSITIVE-DISPLACEMENT PUMPS',
  },
  {
    c: 'F04D 1',
    d: 'Radial-flow pumps, e.g. centrifugal pumps; Helico-centrifugal pumps',
  },
  {
    c: 'F04D 3',
    d: 'Axial-flow pumps',
  },
  {
    c: 'F04D 5',
    d: 'Pumps with circumferential or transverse flow',
  },
  {
    c: 'F04D 7',
    d: 'Pumps adapted for handling specific fluids, e.g. by selection of specific materials for pumps or pump parts',
  },
  {
    c: 'F04D 9',
    d: 'Priming; Preventing vapour lock',
  },
  {
    c: 'F04D 11',
    d: 'Other rotary non-positive-displacement pumps',
  },
  {
    c: 'F04D 13',
    d: 'Pumping installations or systems',
  },
  {
    c: 'F04D 15',
    d: 'Control, e.g. regulation, of pumps, pumping installations, or systems',
  },
  {
    c: 'F04D 17',
    d: 'Rotary pumps specially adapted for elastic fluids',
  },
  {
    c: 'F04D 19',
    d: 'Axial-flow pumps specially adapted for elastic fluids',
  },
  {
    c: 'F04D 21',
    d: 'Pumps specially adapted for elastic fluids involving supersonic speed of pumped fluids',
  },
  {
    c: 'F04D 23',
    d: 'Other rotary non-positive-displacement pumps specially adapted for elastic fluids',
  },
  {
    c: 'F04D 25',
    d: 'Pumping installations or systems specially adapted for elastic fluids',
  },
  {
    c: 'F04D 27',
    d: 'Control, e.g. regulation, of pumps, pumping nstallations or pumping systems specially adapted for elastic fluids',
  },
  {
    c: 'F04D 29',
    d: 'Details, component parts, or accessories',
  },
  {
    c: 'F04D 31',
    d: 'Other non-positive-displacement pumps',
  },
  {
    c: 'F04D 33',
    d: 'Non-positive-displacement pumps with other than pure rotation, e.g. of oscillating type',
  },
  {
    c: 'F04D 35',
    d: 'Pumps producing waves in liquids, i.e. wave-producers',
  },
  {
    c: 'F04F',
    d: 'PUMPING OF FLUID BY DIRECT CONTACT OF ANOTHER FLUID OR BY USING INERTIA OF FLUID TO BE PUMPED',
  },
  {
    c: 'F04F 1',
    d: 'Pumps using positively or negatively pressurised fluid medium acting directly on the liquid to be pumped',
  },
  {
    c: 'F04F 3',
    d: 'Pumps using negative pressure acting directly on the liquid to be pumped',
  },
  {
    c: 'F04F 5',
    d: 'Jet pumps, i.e. devices in which fluid flow is induced by pressure drop caused by velocity of another fluid flow',
  },
  {
    c: 'F04F 7',
    d: 'Pumps displacing fluids by using inertia thereof, e.g. by generating vibrations therein',
  },
  {
    c: 'F04F 9',
    d: 'Diffusion pumps',
  },
  {
    c: 'F04F 10',
    d: 'Siphons',
  },
  {
    c: 'F04F 13',
    d: 'Pressure exchangers',
  },
  {
    c: 'F04F 99',
    d: 'n.a.',
  },
  {
    c: 'F15',
    d: 'FLUID-PRESSURE ACTUATORS; HYDRAULICS OR PNEUMATICS IN GENERAL',
  },
  {
    c: 'F15B',
    d: 'SYSTEMS ACTING BY MEANS OF FLUIDS IN GENERAL; FLUID-PRESSURE ACTUATORS, e.g. SERVOMOTORS; DETAILS OF FLUID-PRESSURE SYSTEMS, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'F15B 1',
    d: 'Installations or systems with accumulators; Supply reservoir or sump assemblies',
  },
  {
    c: 'F15B 3',
    d: 'Intensifiers or fluid-pressure converters, e.g. pressure exchangers; Conveying pressure from one fluid system to another, without contact between the fluids',
  },
  {
    c: 'F15B 5',
    d: 'Transducers converting variations of physical quantities, e.g. expressed by variations in positions of members, into fluid-pressure variations or vice versa; Varying fluid pressure as a function of variations of a plurality of fluid pressures or variations of other quantities',
  },
  {
    c: 'F15B 7',
    d: 'Fluid-pressure actuator systems',
  },
  {
    c: 'F15B 9',
    d: 'Servomotors with follow-up action, i.e. in which the position of the actuated member conforms with that of the controlling member',
  },
  {
    c: 'F15B 11',
    d: 'Servomotor systems without provision for follow-up action',
  },
  {
    c: 'F15B 13',
    d: 'Details of servomotor systems',
  },
  {
    c: 'F15B 15',
    d: 'Fluid-actuated devices for displacing a member from one position to another; Gearing associated therewith',
  },
  {
    c: 'F15B 17',
    d: 'Combinations of telemotor and servomotor systems',
  },
  {
    c: 'F15B 18',
    d: 'Parallel arrangements of independent servomotor systems',
  },
  {
    c: 'F15B 19',
    d: 'Testing fluid-pressure actuator systems or apparatus, so far as not provided for elsewhere',
  },
  {
    c: 'F15B 20',
    d: 'Safety arrangements for fluid actuator systems; Applications of safety devices in fluid actuator systems; Emergency measures for fluid actuator systems',
  },
  {
    c: 'F15B 21',
    d: 'Common features of fluid actuator systems; Fluid-pressure actuator systems or details thereof, not covered by any other group of this subclass',
  },
  {
    c: 'F15C',
    d: 'FLUID-CIRCUIT ELEMENTS PREDOMINANTLY USED FOR COMPUTING OR CONTROL PURPOSES',
  },
  {
    c: 'F15C 1',
    d: 'Circuit elements having no moving parts',
  },
  {
    c: 'F15C 3',
    d: 'Circuit elements having moving parts',
  },
  {
    c: 'F15C 4',
    d: 'Circuit elements characterised by their special functions',
  },
  {
    c: 'F15C 5',
    d: 'Manufacture of fluid-circuit elements; Manufacture of assemblages of such elements',
  },
  {
    c: 'F15C 7',
    d: 'Hybrid elements, i.e. circuit elements having features according to groups F15C0001000000 and F15C0003000000',
  },
  {
    c: 'F15D',
    d: 'FLUID DYNAMICS, i.e. METHODS OR MEANS FOR INFLUENCING THE FLOW OF GASES OR LIQUIDS',
  },
  {
    c: 'F15D 1',
    d: 'Influencing the flow of fluids',
  },
  {
    c: 'F16',
    d: 'ENGINEERING ELEMENTS OR UNITS; GENERAL MEASURES FOR PRODUCING AND MAINTAINING EFFECTIVE FUNCTIONING OF MACHINES OR INSTALLATIONS; THERMAL INSULATION IN GENERAL',
  },
  {
    c: 'F16B',
    d: 'DEVICES FOR FASTENING OR SECURING CONSTRUCTIONAL ELEMENTS OR MACHINE PARTS TOGETHER, e.g. NAILS, BOLTS, CIRCLIPS, CLAMPS, CLIPS OR WEDGES; JOINTS OR JOINTING',
  },
  {
    c: 'F16B 1',
    d: 'Devices for securing together, or preventing relative movement between, constructional elements or machine parts',
  },
  {
    c: 'F16B 2',
    d: 'Fastenings for constructional elements or machine parts in general',
  },
  {
    c: 'F16B 3',
    d: 'Key-type connections; Keys',
  },
  {
    c: 'F16B 4',
    d: 'Shrinkage connection, e.g. assembled with the parts at different temperature; Force fits',
  },
  {
    c: 'F16B 5',
    d: 'Joining sheets or plates to one another or to strips or bars parallel to them',
  },
  {
    c: 'F16B 7',
    d: 'Connections of rods or tubes, e.g. of non-circular section, mutually, including resilient connections',
  },
  {
    c: 'F16B 9',
    d: 'Connections of rods or tubular parts to flat surfaces at an angle',
  },
  {
    c: 'F16B 11',
    d: 'Connecting constructional elements or machine parts by sticking or pressing them together, e.g. cold pressure welding',
  },
  {
    c: 'F16B 12',
    d: 'Jointing of furniture or the like, e.g. hidden from exterior',
  },
  {
    c: 'F16B 13',
    d: 'Dowels or other devices fastened in walls or the like by inserting them in holes made therein for that purpose',
  },
  {
    c: 'F16B 15',
    d: 'Fastening means without screw-thread',
  },
  {
    c: 'F16B 17',
    d: 'Fastening means without screw-thread for connecting constructional elements or machine parts by a part of or on one member entering a hole in the other',
  },
  {
    c: 'F16B 19',
    d: 'Bolts without screw-thread; Pins, including deformable elements',
  },
  {
    c: 'F16B 21',
    d: 'Means without screw-thread for preventing relative axial movement of a pin, spigot, shaft, or the like and a member surrounding it',
  },
  {
    c: 'F16B 23',
    d: 'Fastening means using screw-thread',
  },
  {
    c: 'F16B 25',
    d: 'Screws that form threads in the body into which they are screwed, e.g. wood screws, self-tapping screws',
  },
  {
    c: 'F16B 27',
    d: 'Bolts, screws, or nuts formed in integral series but easily separable, particularly for use in automatic machines',
  },
  {
    c: 'F16B 29',
    d: 'Screwed connection with deformation of nut or auxiliary member while fastening',
  },
  {
    c: 'F16B 31',
    d: 'Screwed connections specially modified in view of tensile load; Break-bolts',
  },
  {
    c: 'F16B 33',
    d: 'Features common to bolt and nut',
  },
  {
    c: 'F16B 35',
    d: 'Screw-bolts; Stay bolts; Screw-threaded studs; Screws; Set screws',
  },
  {
    c: 'F16B 37',
    d: 'Nuts or like thread-engaging members',
  },
  {
    c: 'F16B 39',
    d: 'Locking of screws, bolts, or nuts',
  },
  {
    c: 'F16B 41',
    d: 'Measures against loss of bolts, nuts, or pins; Measures against unauthorised operation of bolts, nuts, or pins',
  },
  {
    c: 'F16B 43',
    d: 'Washers or equivalent devices; Other devices for supporting bolt-heads or nuts',
  },
  {
    c: 'F16B 45',
    d: 'Hooks; Eyes',
  },
  {
    c: 'F16B 47',
    d: 'Suction cups for attaching purposes; Equivalent means using adhesives',
  },
  {
    c: 'F16C',
    d: 'SHAFTS; FLEXIBLE SHAFTS; MECHANICAL MEANS FOR TRANSMITTING MOVEMENT IN A FLEXIBLE SHEATHING; ELEMENTS OF CRANKSHAFT MECHANISMS; PIVOTS; PIVOTAL CONNECTIONS; ROTARY ENGINEERING ELEMENTS OTHER THAN GEARING, COUPLING, CLUTCH OR BRAKE ELEMENTS; BEARINGS',
  },
  {
    c: 'F16C 1',
    d: 'Flexible shafts',
  },
  {
    c: 'F16C 3',
    d: 'Shafts',
  },
  {
    c: 'F16C 5',
    d: 'Crossheads; Constructions of connecting-rod heads or piston-rod connections rigid with crossheads',
  },
  {
    c: 'F16C 7',
    d: 'Connecting-rods or like links pivoted at both ends',
  },
  {
    c: 'F16C 9',
    d: 'Bearings for crankshafts or connecting-rods; Attachment of connecting-rods',
  },
  {
    c: 'F16C 11',
    d: 'Pivots; Pivotal connections',
  },
  {
    c: 'F16C 13',
    d: 'Rolls, drums, discs, or the like',
  },
  {
    c: 'F16C 15',
    d: 'Construction of rotary bodies to resist centrifugal force',
  },
  {
    c: 'F16C 17',
    d: 'Bearings for rotary parts',
  },
  {
    c: 'F16C 19',
    d: 'Bearings with rolling contact, for exclusively rotary movement',
  },
  {
    c: 'F16C 21',
    d: 'Combinations of sliding-contact bearings with ball or roller bearings, for exclusively rotary movement',
  },
  {
    c: 'F16C 23',
    d: 'Bearings for exclusively rotary movement adjustable for aligning or positioning',
  },
  {
    c: 'F16C 25',
    d: 'Bearings for exclusively rotary movement adjustable for wear or play',
  },
  {
    c: 'F16C 27',
    d: 'Elastic or yielding bearings or bearing supports, for exclusively rotary movement',
  },
  {
    c: 'F16C 29',
    d: 'Bearings for parts moving only linearly',
  },
  {
    c: 'F16C 31',
    d: 'Bearings for parts which both rotate and move linearly',
  },
  {
    c: 'F16C 32',
    d: 'Bearings not otherwise provided for',
  },
  {
    c: 'F16C 33',
    d: 'Details or accessories of bearings',
  },
  {
    c: 'F16C 35',
    d: 'Rigid support of bearing units; Housings, e.g. caps, covers',
  },
  {
    c: 'F16C 37',
    d: 'Cooling of bearings',
  },
  {
    c: 'F16C 39',
    d: 'Relieving load on bearings',
  },
  {
    c: 'F16C 41',
    d: 'Other accessories for bearings',
  },
  {
    c: 'F16C 43',
    d: 'Assembling bearings',
  },
  {
    c: 'F16D',
    d: 'COUPLINGS FOR TRANSMITTING ROTATION',
  },
  {
    c: 'F16D 1',
    d: 'Couplings',
  },
  {
    c: 'F16D 3',
    d: 'Yielding couplings, i.e. with means permitting movement between the connected parts during the drive',
  },
  {
    c: 'F16D 5',
    d: 'Impulse couplings, i.e. couplings that alternately accelerate and decelerate the driven member',
  },
  {
    c: 'F16D 7',
    d: 'Slip couplings, e.g. slipping on overload, for absorbing shock',
  },
  {
    c: 'F16D 9',
    d: 'Couplings with safety member for disconnecting',
  },
  {
    c: 'F16D 11',
    d: 'Clutches with mechanically-actuated clutching members; Synchronisation arrangements for clutches',
  },
  {
    c: 'F16D 13',
    d: 'Friction clutches',
  },
  {
    c: 'F16D 15',
    d: 'Clutches with wedging balls or rollers or with other wedgeable separate clutching members',
  },
  {
    c: 'F16D 17',
    d: 'Clutches in which the drive is transmitted solely by virtue of the eccentricity of the contacting surfaces of clutch members which fit one around the other',
  },
  {
    c: 'F16D 19',
    d: 'Clutches with mechanically-actuated clutching members not otherwise provided for',
  },
  {
    c: 'F16D 21',
    d: 'Systems comprising a plurality of mechanically-actuated clutches',
  },
  {
    c: 'F16D 23',
    d: 'Details of mechanically-actuated clutches not specific for one distinct type; Synchronisation arrangements for clutches',
  },
  {
    c: 'F16D 25',
    d: 'Clutches actuated non-mechanically',
  },
  {
    c: 'F16D 27',
    d: 'Magnetically-actuated clutches; Control or electric circuits therefor',
  },
  {
    c: 'F16D 28',
    d: 'Electrically-actuated clutches',
  },
  {
    c: 'F16D 29',
    d: 'Clutches or systems of clutches involving both fluid and magnetic or both fluid and electric actuation',
  },
  {
    c: 'F16D 31',
    d: 'Couplings or clutches with a fluid or semifluid as power-transmitting means',
  },
  {
    c: 'F16D 33',
    d: 'Rotary fluid couplings or clutches of the hydrokinetic type',
  },
  {
    c: 'F16D 35',
    d: 'Fluid clutches in which the clutching is predominantly obtained by fluid adhesion',
  },
  {
    c: 'F16D 37',
    d: 'Clutches in which the drive is transmitted through a medium consisting of small particles, e.g. centrifugally speed-responsive',
  },
  {
    c: 'F16D 39',
    d: 'Combinations of couplings according to two or more of the groups F16D0031000000-F16D0037000000',
  },
  {
    c: 'F16D 41',
    d: 'Freewheels or freewheel clutches; Automatic clutches',
  },
  {
    c: 'F16D 43',
    d: 'Internally controlled automatic clutches',
  },
  {
    c: 'F16D 45',
    d: 'Freewheels or freewheel clutches combined with automatic clutches',
  },
  {
    c: 'F16D 47',
    d: 'Systems of clutches, or clutches and couplings, comprising devices of types grouped under at least two of the following sets of groups: F16D0001000000-F16D0009000000; F16D0011000000-F16D0023000000; F16D0025000000-F16D0029000000; F16D0031000000-F16D0039000000; F16D0041000000-F16D0045000000',
  },
  {
    c: 'F16D 48',
    d: 'External control of clutches',
  },
  {
    c: 'F16D 49',
    d: 'Brakes',
  },
  {
    c: 'F16D 51',
    d: 'Brakes with outwardly-movable braking members co-operating with the inner surface of a drum or the like',
  },
  {
    c: 'F16D 53',
    d: 'Brakes with braking members co-operating with both the periphery and the inner surface of a drum, wheel-rim, or the like',
  },
  {
    c: 'F16D 55',
    d: 'Brakes with substantially-radial braking surfaces pressed together in axial direction, e.g. disc brakes',
  },
  {
    c: 'F16D 57',
    d: 'Liquid-resistance brakes; Air-resistance brakes',
  },
  {
    c: 'F16D 59',
    d: 'Self-acting brakes, e.g. coming into operation at a predetermined speed',
  },
  {
    c: 'F16D 61',
    d: 'Brakes with means for making the energy absorbed available for use',
  },
  {
    c: 'F16D 63',
    d: 'Brakes not otherwise provided for; Brakes combining more than one of the types of groups F16D0049000000-F16D0061000000',
  },
  {
    c: 'F16D 65',
    d: 'Parts or details of brakes',
  },
  {
    c: 'F16D 66',
    d: 'Arrangements for monitoring working conditions of brakes, e.g. wear or temperature',
  },
  {
    c: 'F16D 67',
    d: 'Combinations of couplings and brakes; Combinations of clutches and brakes',
  },
  {
    c: 'F16D 69',
    d: 'Friction linings; Attachment thereof; Selection of coacting friction substances or surfaces',
  },
  {
    c: 'F16D 71',
    d: 'Mechanisms for bringing members to rest in a predetermined position',
  },
  {
    c: 'F16D 121',
    d: 'Indexing scheme associated with groups F16D0065140000-F16D0065280000 relating to actuators',
  },
  {
    c: 'F16D 123',
    d: 'Multiple operation forces',
  },
  {
    c: 'F16D 125',
    d: 'Components of actuators',
  },
  {
    c: 'F16D 127',
    d: 'Auxiliary mechanisms',
  },
  {
    c: 'F16D 129',
    d: 'Type of operation source for auxiliary mechanisms',
  },
  {
    c: 'F16D 131',
    d: 'Overall arrangement of the actuators or their elements, e.g. modular construction',
  },
  {
    c: 'F16F',
    d: 'SPRINGS; SHOCK-ABSORBERS; MEANS FOR DAMPING VIBRATION',
  },
  {
    c: 'F16F 1',
    d: 'Springs',
  },
  {
    c: 'F16F 3',
    d: 'Spring units consisting of several springs, e.g. for obtaining a desired spring characteristic',
  },
  {
    c: 'F16F 5',
    d: 'Liquid springs in which the liquid works as a spring by compression, e.g. combined with throttling action; Combinations of devices including liquid springs',
  },
  {
    c: 'F16F 6',
    d: 'Magnetic springs; Fluid magnetic springs',
  },
  {
    c: 'F16F 7',
    d: 'Vibration-dampers; Shock-absorbers',
  },
  {
    c: 'F16F 9',
    d: 'Springs, vibration-dampers, shock-absorbers, or similarly-constructed movement-dampers using a fluid or the equivalent as damping medium',
  },
  {
    c: 'F16F 11',
    d: 'Vibration-dampers or shock-absorbers working with both friction and a damping fluid',
  },
  {
    c: 'F16F 13',
    d: 'Units comprising springs of the non-fluid type as well as vibration-dampers, shock-absorbers, or fluid springs',
  },
  {
    c: 'F16F 15',
    d: 'Suppression of vibrations in systems',
  },
  {
    c: 'F16G',
    d: 'BELTS, CABLES, OR ROPES, PREDOMINANTLY USED FOR DRIVING PURPOSES; CHAINS; FITTINGS PREDOMINANTLY USED THEREFOR',
  },
  {
    c: 'F16G 1',
    d: 'Driving-belts',
  },
  {
    c: 'F16G 3',
    d: 'Belt fastenings, e.g. for conveyor belts',
  },
  {
    c: 'F16G 5',
    d: 'V-belts, i.e. belts of tapered cross-section',
  },
  {
    c: 'F16G 7',
    d: 'V-belt fastenings',
  },
  {
    c: 'F16G 9',
    d: 'Ropes or cables specially adapted for driving, or for being driven by, pulleys or other gearing elements',
  },
  {
    c: 'F16G 11',
    d: 'Means for fastening cables or ropes to one another or to other objects',
  },
  {
    c: 'F16G 13',
    d: 'Chains',
  },
  {
    c: 'F16G 15',
    d: 'Chain couplings; Shackles; Chain joints; Chain links; Chain bushes',
  },
  {
    c: 'F16G 17',
    d: 'Hooks as integral parts of chains',
  },
  {
    c: 'F16H',
    d: 'GEARING',
  },
  {
    c: 'F16H 1',
    d: 'Toothed gearings for conveying rotary motion',
  },
  {
    c: 'F16H 3',
    d: 'Toothed gearings for conveying rotary motion with variable gear ratio or for reversing rotary motion',
  },
  {
    c: 'F16H 7',
    d: 'Gearing for conveying rotary motion by endless flexible members',
  },
  {
    c: 'F16H 9',
    d: 'Gearings for conveying rotary motion with variable gear ratio, or for reversing rotary motion, by endless flexible members',
  },
  {
    c: 'F16H 13',
    d: 'Other friction gearing for conveying rotary motion',
  },
  {
    c: 'F16H 15',
    d: 'Gearings for conveying rotary motion with variable gear ratio, or for reversing rotary motion, by friction between rotary members',
  },
  {
    c: 'F16H 19',
    d: 'Gearings comprising essentially only toothed gears or friction members and not capable of conveying indefinitely-continuing rotary motion',
  },
  {
    c: 'F16H 21',
    d: 'Gearing for conveying or converting motion by means of levers, links, cams or screw-and-nut mechanisms',
  },
  {
    c: 'F16H 23',
    d: 'Wobble-plate gearings; Oblique-crank gearings',
  },
  {
    c: 'F16H 25',
    d: 'Gearings comprising primarily only cams, cam-followers and screw-and-nut mechanisms',
  },
  {
    c: 'F16H 27',
    d: 'Gearings with intermittently-driving members',
  },
  {
    c: 'F16H 29',
    d: 'Gearings for conveying rotary motion with intermittently-driving members, e.g. with freewheel action',
  },
  {
    c: 'F16H 31',
    d: 'Other gearings with freewheeling members or other intermittently-driving members',
  },
  {
    c: 'F16H 33',
    d: 'Gearings based on repeated accumulation and delivery of energy',
  },
  {
    c: 'F16H 35',
    d: 'Gearings or mechanisms with other special functional features',
  },
  {
    c: 'F16H 37',
    d: 'Combinations of mechanical gearings, not provided for in groups F16H0001000000-F16H0035000000',
  },
  {
    c: 'F16H 39',
    d: 'Fluid gearing',
  },
  {
    c: 'F16H 41',
    d: 'Rotary fluid gearing of the hydrokinetic type',
  },
  {
    c: 'F16H 43',
    d: 'Other fluid gearing, e.g. with oscillating input or output',
  },
  {
    c: 'F16H 45',
    d: 'Combinations of fluid gearings for conveying rotary motion with couplings or clutches',
  },
  {
    c: 'F16H 47',
    d: 'Combinations of mechanical gearing with fluid clutches or fluid gearing',
  },
  {
    c: 'F16H 48',
    d: 'Differential gearings',
  },
  {
    c: 'F16H 49',
    d: 'Other gearing',
  },
  {
    c: 'F16H 51',
    d: 'Details of gearing or mechanisms',
  },
  {
    c: 'F16H 53',
    d: 'Cams or cam-followers, e.g. rollers for gearing mechanisms',
  },
  {
    c: 'F16H 55',
    d: 'Elements with teeth or friction surfaces for conveying motion; Worms, pulleys or sheaves for gearing mechanisms',
  },
  {
    c: 'F16H 57',
    d: 'General details of gearing',
  },
  {
    c: 'F16H 59',
    d: 'Control of gearing conveying rotary motion',
  },
  {
    c: 'F16H 61',
    d: 'Control functions within change-speed- or reversing-gearings for conveying rotary motion',
  },
  {
    c: 'F16H 63',
    d: 'Control outputs to change-speed- or reversing-gearings for conveying rotary motion',
  },
  {
    c: 'F16J',
    d: 'PISTONS; CYLINDERS; PRESSURE VESSELS IN GENERAL; SEALINGS',
  },
  {
    c: 'F16J 1',
    d: 'Pistons; Trunk pistons; Plungers',
  },
  {
    c: 'F16J 3',
    d: 'Diaphragms; Bellows; Bellows pistons',
  },
  {
    c: 'F16J 7',
    d: 'Piston-rods, i.e. rods rigidly connected to the piston',
  },
  {
    c: 'F16J 9',
    d: 'Piston-rings, seats therefor; Ring sealings of similar construction in general',
  },
  {
    c: 'F16J 10',
    d: 'Engine or like cylinders',
  },
  {
    c: 'F16J 12',
    d: 'Pressure vessels in general',
  },
  {
    c: 'F16J 13',
    d: 'Covers or similar closure members for pressure vessels in general',
  },
  {
    c: 'F16J 15',
    d: 'Sealings',
  },
  {
    c: 'F16K',
    d: 'VALVES; TAPS; COCKS; ACTUATING-FLOATS; DEVICES FOR VENTING OR AERATING',
  },
  {
    c: 'F16K 1',
    d: 'Constructional types',
  },
  {
    c: 'F16K 3',
    d: 'Gate valves or sliding valves, i.e. cut-off apparatus with closing members having a sliding movement along the seat for opening and closing',
  },
  {
    c: 'F16K 5',
    d: 'Taps or cocks comprising only cut-off apparatus having at least one of the sealing faces shaped as a more or less complete surface of a solid of revolution, the opening and closing movement being predominantly rotary',
  },
  {
    c: 'F16K 7',
    d: 'Diaphragm cut-off apparatus, e.g. with a member deformed, but not moved bodily, to close the passage',
  },
  {
    c: 'F16K 11',
    d: 'Multiple-way valves, e.g. mixing valves; Pipe fittings incorporating such valves; Arrangement of valves and flow lines specially adapted for mixing fluid',
  },
  {
    c: 'F16K 13',
    d: 'Other constructional types of cut-off apparatus',
  },
  {
    c: 'F16K 15',
    d: 'Functional types',
  },
  {
    c: 'F16K 17',
    d: 'Safety valves; Equalising valves',
  },
  {
    c: 'F16K 21',
    d: 'Fluid-delivery valves',
  },
  {
    c: 'F16K 23',
    d: 'Valves for preventing drip from nozzles',
  },
  {
    c: 'F16K 24',
    d: 'Devices, e.g. valves, for venting or aerating enclosures',
  },
  {
    c: 'F16K 25',
    d: 'Details',
  },
  {
    c: 'F16K 27',
    d: 'Construction of housings',
  },
  {
    c: 'F16K 29',
    d: 'Arrangements for movement of valve members other than for opening or closing the valve, e.g. for grinding-in, for preventing sticking',
  },
  {
    c: 'F16K 31',
    d: 'Operating means; Releasing devices',
  },
  {
    c: 'F16K 33',
    d: 'Floats for actuation of valves or other apparatus',
  },
  {
    c: 'F16K 35',
    d: 'Means to prevent accidental or unauthorised actuation',
  },
  {
    c: 'F16K 37',
    d: 'Special means in or on valves or other cut-off apparatus for indicating or recording operation thereof, or for enabling an alarm to be given',
  },
  {
    c: 'F16K 39',
    d: 'Devices for relieving the pressure on the sealing faces',
  },
  {
    c: 'F16K 41',
    d: 'Spindle sealings',
  },
  {
    c: 'F16K 43',
    d: 'Auxiliary closure means in valves, which in case of repair, e.g. rewashering, of the valve, can take over the function of the normal closure means; Devices for temporary replacement of parts of valves for the same purpose',
  },
  {
    c: 'F16K 47',
    d: 'Means in valves for absorbing fluid energy',
  },
  {
    c: 'F16K 49',
    d: 'Means in or on valves for heating or cooling',
  },
  {
    c: 'F16K 51',
    d: 'Other details not peculiar to particular types of valves or cut-off apparatus',
  },
  {
    c: 'F16K 99',
    d: 'n.a.',
  },
  {
    c: 'F16L',
    d: 'PIPES; JOINTS OR FITTINGS FOR PIPES; SUPPORTS FOR PIPES, CABLES OR PROTECTIVE TUBING; MEANS FOR THERMAL INSULATION IN GENERAL',
  },
  {
    c: 'F16L 1',
    d: 'Laying or reclaiming pipes; Repairing or joining pipes on or under water',
  },
  {
    c: 'F16L 3',
    d: 'Supports for pipes, cables or protective tubing, e.g. hangers, holders, clamps, cleats, clips, brackets',
  },
  {
    c: 'F16L 5',
    d: 'Devices for use where pipes, cables or protective tubing pass through walls or partitions',
  },
  {
    c: 'F16L 7',
    d: 'Supporting pipes or cables inside other pipes or sleeves, e.g. for enabling pipes or cables to be inserted or withdrawn from under roads or railways without interruption of traffic',
  },
  {
    c: 'F16L 9',
    d: 'Pipes',
  },
  {
    c: 'F16L 11',
    d: 'Hoses, i.e. flexible pipes',
  },
  {
    c: 'F16L 13',
    d: 'Pipe joints; Hose nipples',
  },
  {
    c: 'F16L 15',
    d: 'Screw-threaded joints; Forms of screw-threads for such joints',
  },
  {
    c: 'F16L 17',
    d: 'Joints with packing adapted to sealing by fluid pressure',
  },
  {
    c: 'F16L 19',
    d: 'Joints in which sealing surfaces are pressed together by means of a member, e.g. a swivel nut, screwed on, or into, one of the joint parts',
  },
  {
    c: 'F16L 21',
    d: 'Joints with sleeve or socket',
  },
  {
    c: 'F16L 23',
    d: 'Flanged joints',
  },
  {
    c: 'F16L 25',
    d: 'Construction or details of pipe joints not provided for in, or of interest apart from, groups F16L0013000000-F16L0023000000',
  },
  {
    c: 'F16L 27',
    d: 'Adjustable joints; Joints allowing movement',
  },
  {
    c: 'F16L 29',
    d: 'Joints with fluid cut-off means',
  },
  {
    c: 'F16L 31',
    d: 'Arrangements for connecting hoses to one another or to flexible sleeves',
  },
  {
    c: 'F16L 33',
    d: 'Arrangements for connecting hoses to rigid members; Rigid hose-connectors, i.e. single members engaging both hoses',
  },
  {
    c: 'F16L 35',
    d: 'Special arrangements used in connection with end fittings of hoses, e.g. safety or protecting devices',
  },
  {
    c: 'F16L 37',
    d: 'Couplings of the quick-acting type',
  },
  {
    c: 'F16L 39',
    d: 'Joints or fittings for double-walled or multi-channel pipes or pipe assemblies',
  },
  {
    c: 'F16L 41',
    d: 'Branching pipes; Joining pipes to walls',
  },
  {
    c: 'F16L 43',
    d: 'Bends; Siphons',
  },
  {
    c: 'F16L 45',
    d: 'Pipe units with cleaning aperture and closure therefor',
  },
  {
    c: 'F16L 47',
    d: 'Connecting arrangements or other fittings specially adapted to be made of plastics or to be used with pipes made of plastics',
  },
  {
    c: 'F16L 49',
    d: 'Connecting arrangements, e.g. joints, specially adapted for pipes of brittle material, e.g. glass, earthenware',
  },
  {
    c: 'F16L 51',
    d: 'Expansion-compensation arrangements for pipe-lines',
  },
  {
    c: 'F16L 53',
    d: 'Heating of pipes or pipe systems; Cooling of pipes or pipe systems',
  },
  {
    c: 'F16L 55',
    d: 'Devices or appurtenances for use in, or in connection with, pipes or pipe systems',
  },
  {
    c: 'F16L 57',
    d: 'Protection of pipes or objects of similar shape against external or internal damage or wear',
  },
  {
    c: 'F16L 58',
    d: 'Protection of pipes or pipe fittings against corrosion or incrustation',
  },
  {
    c: 'F16L 59',
    d: 'Thermal insulation in general',
  },
  {
    c: 'F16L 101',
    d: 'Indexing scheme associated with groups F16L0055260000-F16L0055480000, relating to uses and applications of pigs or moles.',
  },
  {
    c: 'F16M',
    d: 'FRAMES, CASINGS, OR BEDS, OF ENGINES OR OTHER MACHINES OR APPARATUS, NOT SPECIFIC TO AN ENGINE, MACHINE, OR APPARATUS PROVIDED FOR ELSEWHERE; STANDS OR SUPPORTS',
  },
  {
    c: 'F16M 1',
    d: 'Frames or casings of engines, machines, or apparatus; Frames serving as machinery beds',
  },
  {
    c: 'F16M 3',
    d: 'Portable or wheeled frames or beds, e.g. for emergency power-supply aggregates, compressor sets',
  },
  {
    c: 'F16M 5',
    d: 'Engine beds, i.e. means for supporting engines or machines on foundations',
  },
  {
    c: 'F16M 7',
    d: 'Details of attaching or adjusting engine beds, frames, or supporting-legs on foundation or base; Attaching non-moving engine parts, e.g. cylinder blocks',
  },
  {
    c: 'F16M 9',
    d: 'Special layout of foundations with respect to machinery to be supported',
  },
  {
    c: 'F16M 11',
    d: 'Stands or trestles as supports for apparatus or articles placed thereon',
  },
  {
    c: 'F16M 13',
    d: 'Other supports for positioning apparatus or articles',
  },
  {
    c: 'F16N',
    d: 'LUBRICATING',
  },
  {
    c: 'F16N 1',
    d: 'Lubrication devices or arrangements for oil or grease',
  },
  {
    c: 'F16N 3',
    d: 'Devices for supplying lubricant by manual action',
  },
  {
    c: 'F16N 5',
    d: 'Apparatus with hand-positioned nozzle supplied with lubricant under pressure',
  },
  {
    c: 'F16N 7',
    d: 'Arrangements for supplying oil or unspecified lubricant from a stationary reservoir or the equivalent in or on the machine or member to be lubricated',
  },
  {
    c: 'F16N 9',
    d: 'Arrangements for supplying oil or unspecified lubricant from a moving reservoir or the equivalent',
  },
  {
    c: 'F16N 11',
    d: 'Arrangements for supplying grease from a stationary reservoir or the equivalent in or on the machine or member to be lubricated; Grease cups',
  },
  {
    c: 'F16N 13',
    d: 'Lubricating-pumps',
  },
  {
    c: 'F16N 15',
    d: 'Lubrication with substances other than oil or grease; Lubrication characterised by the use of particular lubricants in particular apparatus or conditions',
  },
  {
    c: 'F16N 17',
    d: 'Lubrication of machines or apparatus working under extreme conditions',
  },
  {
    c: 'F16N 19',
    d: 'Details of lubricators or lubrication systems',
  },
  {
    c: 'F16N 21',
    d: 'Conduits; Junctions; Fittings for lubrication apertures',
  },
  {
    c: 'F16N 23',
    d: 'Special adaptations of check valves',
  },
  {
    c: 'F16N 25',
    d: 'Distributing equipment',
  },
  {
    c: 'F16N 27',
    d: 'Proportioning devices',
  },
  {
    c: 'F16N 29',
    d: 'Special means in lubricating arrangements or systems providing for the indication or detection of undesired conditions; Use of devices responsive to conditions in lubricating arrangements or systems',
  },
  {
    c: 'F16N 31',
    d: 'Means for collecting, retaining, or draining-off lubricant in or on machines or apparatus',
  },
  {
    c: 'F16N 33',
    d: 'Mechanical arrangements for cleaning lubricating equipment; Special racks or the like for use in draining lubricant from machine parts',
  },
  {
    c: 'F16N 35',
    d: 'Care of lubricants',
  },
  {
    c: 'F16N 37',
    d: 'Equipment for transferring lubricant from one container to another',
  },
  {
    c: 'F16N 39',
    d: 'Arrangements for conditioning of lubricants in the lubricating system',
  },
  {
    c: 'F16N 99',
    d: 'n.a.',
  },
  {
    c: 'F16P',
    d: 'SAFETY DEVICES IN GENERAL',
  },
  {
    c: 'F16P 1',
    d: 'Devices protecting or preventing injuries to people',
  },
  {
    c: 'F16P 3',
    d: 'Safety devices acting in conjunction with the control or operation of a machine; Control arrangements requiring the simultaneous use of two or more parts of the body',
  },
  {
    c: 'F16P 5',
    d: 'Emergency means for rendering ineffective a coupling conveying reciprocating movement if the motion of the driven part is prematurely resisted',
  },
  {
    c: 'F16P 7',
    d: 'Emergency devices preventing damage to a machine or apparatus',
  },
  {
    c: 'F16S',
    d: 'CONSTRUCTIONAL ELEMENTS IN GENERAL; STRUCTURES BUILT-UP FROM SUCH ELEMENTS, IN GENERAL',
  },
  {
    c: 'F16S 1',
    d: 'Sheets, panels, or other members of similar proportions; Constructions comprising assemblies of such members',
  },
  {
    c: 'F16S 3',
    d: 'Elongated members, e.g. profiled members; Assemblies thereof; Gratings or grilles',
  },
  {
    c: 'F16S 5',
    d: 'Other constructional members not restricted to an application fully provided for in a single class',
  },
  {
    c: 'F16T',
    d: 'STEAM TRAPS OR LIKE APPARATUS FOR DRAINING-OFF LIQUIDS FROM ENCLOSURES PREDOMINANTLY CONTAINING GASES OR VAPOURS',
  },
  {
    c: 'F16T 1',
    d: 'Steam traps or like apparatus for draining-off liquids from enclosures predominantly containing gases or vapours, e.g. gas lines, steam lines, containers',
  },
  {
    c: 'F17',
    d: 'STORING OR DISTRIBUTING GASES OR LIQUIDS',
  },
  {
    c: 'F17B',
    d: 'GAS-HOLDERS OF VARIABLE CAPACITY',
  },
  {
    c: 'F17B 1',
    d: 'Gas-holders of variable capacity',
  },
  {
    c: 'F17C',
    d: 'VESSELS FOR CONTAINING OR STORING COMPRESSED, LIQUEFIED, OR SOLIDIFIED GASES; FIXED-CAPACITY GAS-HOLDERS; FILLING VESSELS WITH, OR DISCHARGING FROM VESSELS, COMPRESSED, LIQUEFIED, OR SOLIDIFIED GASES',
  },
  {
    c: 'F17C 1',
    d: 'Pressure vessels, e.g. gas cylinder, gas tank, replaceable cartridge',
  },
  {
    c: 'F17C 3',
    d: 'Vessels not under pressure',
  },
  {
    c: 'F17C 5',
    d: 'Methods or apparatus for filling pressure vessels with liquefied, solidified, or compressed gases',
  },
  {
    c: 'F17C 6',
    d: 'Methods or apparatus for filling vessels not under pressure with liquefied or solidified gases',
  },
  {
    c: 'F17C 7',
    d: 'Methods or apparatus for discharging liquefied, solidified, or compressed gases from pressure vessels, not covered by another subclass',
  },
  {
    c: 'F17C 9',
    d: 'Methods or apparatus for discharging liquefied or solidified gases from vessels not under pressure',
  },
  {
    c: 'F17C 11',
    d: 'Use of gas-solvents or gas-sorbents in vessels',
  },
  {
    c: 'F17C 13',
    d: 'Details of vessels or of the filling or discharging of vessels',
  },
  {
    c: 'F17D',
    d: 'PIPE-LINE SYSTEMS; PIPE-LINES',
  },
  {
    c: 'F17D 1',
    d: 'Pipe-line systems',
  },
  {
    c: 'F17D 3',
    d: 'Arrangements for supervising or controlling working operations',
  },
  {
    c: 'F17D 5',
    d: 'Protection or supervision of installations',
  },
  {
    c: 'F21',
    d: 'LIGHTING',
  },
  {
    c: 'F21H',
    d: 'INCANDESCENT MANTLES; OTHER INCANDESCENT BODIES HEATED BY COMBUSTION',
  },
  {
    c: 'F21H 1',
    d: 'Incandescent mantles; Selection of imbibition liquids therefor',
  },
  {
    c: 'F21H 3',
    d: 'Manufacturing incandescent mantles; Treatment prior to use, e.g. burning-off; Machines for manufacturing',
  },
  {
    c: 'F21H 5',
    d: 'Solid incandescent bodies',
  },
  {
    c: 'F21H 7',
    d: 'Other incandescent bodies',
  },
  {
    c: 'F21K',
    d: 'NON-ELECTRIC LIGHT SOURCES USING LUMINESCENCE; LIGHT SOURCES USING ELECTROCHEMILUMINESCENCE; LIGHT SOURCES USING CHARGES OF COMBUSTIBLE MATERIAL; LIGHT SOURCES USING SEMICONDUCTOR DEVICES AS LIGHT-GENERATING ELEMENTS; LIGHT SOURCES NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'F21K 2',
    d: 'Non-electric light sources using luminescence',
  },
  {
    c: 'F21K 5',
    d: 'Light sources using charges of combustible material, e.g. illuminating flash devices',
  },
  {
    c: 'F21K 9',
    d: 'Light sources using semiconductor devices as light-generating elements, e.g. using light-emitting diodes [LED] or lasers',
  },
  {
    c: 'F21K 99',
    d: 'n.a.',
  },
  {
    c: 'F21L',
    d: 'LIGHTING DEVICES OR SYSTEMS THEREOF, BEING PORTABLE OR SPECIALLY ADAPTED FOR TRANSPORTATION',
  },
  {
    c: 'F21L 2',
    d: 'Systems of electric lighting devices',
  },
  {
    c: 'F21L 4',
    d: 'Electric lighting devices with self-contained electric batteries or cells',
  },
  {
    c: 'F21L 13',
    d: 'Electric lighting devices with built-in electric generators',
  },
  {
    c: 'F21L 14',
    d: 'Electric lighting devices without a self-contained power source, e.g. for mains connection',
  },
  {
    c: 'F21L 17',
    d: 'Non-electric torches; Non-electric flares',
  },
  {
    c: 'F21L 19',
    d: 'Lanterns, e.g. hurricane lamps or candle lamps',
  },
  {
    c: 'F21L 21',
    d: 'Non-electric pocket-lamps, e.g. lamps producing sparks',
  },
  {
    c: 'F21L 23',
    d: 'Non-electric hand-lamps for miners',
  },
  {
    c: 'F21L 26',
    d: 'Non-electric portable lighting devices, or systems thereof, not provided for in groups F21L0017000000-F21L0023000000',
  },
  {
    c: 'F21L 27',
    d: 'Lighting devices or systems, employing combinations of electric and non-electric light sources; Replacing or exchanging electric light sources with non-electric light sources or vice versa in lighting devices or systems',
  },
  {
    c: 'F21S',
    d: 'NON-PORTABLE LIGHTING DEVICES; SYSTEMS THEREOF; VEHICLE LIGHTING DEVICES SPECIALLY ADAPTED FOR VEHICLE EXTERIORS',
  },
  {
    c: 'F21S 2',
    d: 'Systems of lighting devices, not provided for in main groups F21S0004000000-F21S0010000000 or F21S0019000000, e.g. of modular construction',
  },
  {
    c: 'F21S 4',
    d: 'Lighting devices or systems using a string or strip of light sources',
  },
  {
    c: 'F21S 6',
    d: 'Lighting devices intended to be free-standing',
  },
  {
    c: 'F21S 8',
    d: 'Lighting devices intended for fixed installation',
  },
  {
    c: 'F21S 9',
    d: 'Lighting devices with a built-in power supply; Systems employing lighting devices with a built-in power supply',
  },
  {
    c: 'F21S 10',
    d: 'Lighting devices or systems producing a varying lighting effect',
  },
  {
    c: 'F21S 11',
    d: 'Non-electric lighting devices or systems using daylight',
  },
  {
    c: 'F21S 13',
    d: 'Non-electric lighting devices or systems employing a point-like light source; Non-electric lighting devices or systems employing a light source of unspecified shape',
  },
  {
    c: 'F21S 15',
    d: 'Non-electric lighting devices or systems employing light sources not covered by main groups F21S0011000000, F21S0013000000 or F21S0019000000',
  },
  {
    c: 'F21S 19',
    d: 'Lighting devices or systems employing combinations of electric and non-electric light sources; Replacing or exchanging electric light sources with non-electric light sources or vice versa',
  },
  {
    c: 'F21S 41',
    d: 'Vehicle lighting devices specially adapted for vehicle exteriors',
  },
  {
    c: 'F21S 43',
    d: 'Signalling devices specially adapted for vehicle exteriors, e.g. brake lamps, direction indicator lights or reversing lights',
  },
  {
    c: 'F21S 45',
    d: 'Arrangements within vehicle lighting devices specially adapted for vehicle exteriors, for purposes other than emission or distribution of light',
  },
  {
    c: 'F21V',
    d: 'FUNCTIONAL FEATURES OR DETAILS OF LIGHTING DEVICES OR SYSTEMS THEREOF; STRUCTURAL COMBINATIONS OF LIGHTING DEVICES WITH OTHER ARTICLES, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'F21V 1',
    d: 'Shades for light sources',
  },
  {
    c: 'F21V 3',
    d: 'Globes; Bowls; Cover glasses',
  },
  {
    c: 'F21V 5',
    d: 'Refractors for light sources',
  },
  {
    c: 'F21V 7',
    d: 'Reflectors for light sources',
  },
  {
    c: 'F21V 8',
    d: 'Use of light guides, e.g. fibre optic devices, in lighting devices or systems',
  },
  {
    c: 'F21V 9',
    d: 'Elements for modifying spectral properties, polarisation or intensity of the light emitted, e.g. filters',
  },
  {
    c: 'F21V 11',
    d: 'Screens not covered by groups F21V0001000000, F21V0003000000, F21V0007000000 or F21V0009000000',
  },
  {
    c: 'F21V 13',
    d: 'Producing particular characteristics or distribution of the light emitted by means of a combination of elements specified in two or more of main groups F21V0001000000-F21V0011000000',
  },
  {
    c: 'F21V 14',
    d: 'Controlling the distribution of the light emitted by adjustment of elements',
  },
  {
    c: 'F21V 15',
    d: 'Protecting lighting devices from damage',
  },
  {
    c: 'F21V 17',
    d: 'Fastening of component parts of lighting devices, e.g. shades, globes, refractors, reflectors, filters, screens, grids or protective cages',
  },
  {
    c: 'F21V 19',
    d: 'Fastening of light sources or lamp holders',
  },
  {
    c: 'F21V 21',
    d: 'Supporting, suspending, or attaching arrangements for lighting devices',
  },
  {
    c: 'F21V 23',
    d: 'Arrangement of electric circuit elements in or on lighting devices',
  },
  {
    c: 'F21V 25',
    d: 'Safety devices structurally associated with lighting devices',
  },
  {
    c: 'F21V 27',
    d: 'Cable-stowing arrangements structurally associated with lighting devices, e.g. reels',
  },
  {
    c: 'F21V 29',
    d: 'Protecting lighting devices from thermal damage; Cooling or heating arrangements specially adapted for lighting devices or systems',
  },
  {
    c: 'F21V 31',
    d: 'Gas-tight or water-tight arrangements',
  },
  {
    c: 'F21V 33',
    d: 'Structural combinations of lighting devices with other articles, not otherwise provided for',
  },
  {
    c: 'F21V 35',
    d: 'Candle holders',
  },
  {
    c: 'F21V 36',
    d: 'Arrangements of mantles or other incandescent bodies on burners',
  },
  {
    c: 'F21V 37',
    d: 'Details of lighting devices employing combustion as light source, not otherwise provided for',
  },
  {
    c: 'F21V 99',
    d: 'n.a.',
  },
  {
    c: 'F21W',
    d: 'INDEXING SCHEME ASSOCIATED WITH SUBCLASSES F21K, F21L, F21S and F21V, RELATING TO USES OR APPLICATIONS OF LIGHTING DEVICES OR SYSTEMS',
  },
  {
    c: 'F21W 102',
    d: 'Exterior vehicle lighting devices for illuminating purposes',
  },
  {
    c: 'F21W 103',
    d: 'Exterior vehicle lighting devices for signalling purposes',
  },
  {
    c: 'F21W 104',
    d: 'Exterior vehicle lighting devices for decorative purposes',
  },
  {
    c: 'F21W 105',
    d: 'Combinations of lighting devices covered by groups F21W0102000000-F21W0104000000',
  },
  {
    c: 'F21W 106',
    d: 'Interior vehicle lighting devices',
  },
  {
    c: 'F21W 107',
    d: 'Use or application of lighting devices on or in particular types of vehicles',
  },
  {
    c: 'F21W 111',
    d: 'Use or application of lighting devices or systems for signalling, marking or indicating, not provided for in groups F21W0102000000-F21W0107000000',
  },
  {
    c: 'F21W 121',
    d: 'Use or application of lighting devices or systems for decorative purposes, not provided for in groups F21W0102000000-F21W0107000000',
  },
  {
    c: 'F21W 131',
    d: 'Use or application of lighting devices or systems not provided for in groups F21W0102000000-F21W0121000000',
  },
  {
    c: 'F21Y',
    d: 'INDEXING SCHEME ASSOCIATED WITH SUBCLASSES F21K, F21L, F21S and F21V, RELATING TO THE FORM OR THE KIND OF THE LIGHT SOURCES OR OF THE COLOUR OF THE LIGHT EMITTED',
  },
  {
    c: 'F21Y 101',
    d: 'Point-like light sources',
  },
  {
    c: 'F21Y 103',
    d: 'Elongate light sources, e.g. fluorescent tubes',
  },
  {
    c: 'F21Y 105',
    d: 'Planar light sources',
  },
  {
    c: 'F21Y 107',
    d: 'Light sources with three-dimensionally disposed light-generating elements',
  },
  {
    c: 'F21Y 109',
    d: 'Light sources with light-generating elements disposed on transparent or translucent supports or substrates',
  },
  {
    c: 'F21Y 111',
    d: 'Light sources of a form not covered by groups F21Y0101000000-F21Y0107000000',
  },
  {
    c: 'F21Y 113',
    d: 'Combination of light sources',
  },
  {
    c: 'F21Y 115',
    d: 'Light-generating elements of semiconductor light sources',
  },
  {
    c: 'F22',
    d: 'STEAM GENERATION',
  },
  {
    c: 'F22B',
    d: 'METHODS OF STEAM GENERATION; STEAM BOILERS',
  },
  {
    c: 'F22B 1',
    d: 'Methods of steam generation characterised by form of heating method',
  },
  {
    c: 'F22B 3',
    d: 'Other methods of steam generation; Steam boilers not provided for in other groups of this subclass',
  },
  {
    c: 'F22B 5',
    d: 'Steam boilers of drum type, i.e. without internal furnace or fire tubes, the boiler body being contacted externally by flue gas',
  },
  {
    c: 'F22B 7',
    d: 'Steam boilers of furnace-tube type, i.e. the combustion of fuel being performed inside one or more furnace tubes built-in in the boiler body',
  },
  {
    c: 'F22B 9',
    d: 'Steam boilers of fire-tube type, i.e. the flue gas from a combustion chamber outside the boiler body flowing through tubes built-in in the boiler body',
  },
  {
    c: 'F22B 11',
    d: 'Steam boilers of combined fire-tube type and water-tube type, i.e. steam boilers of fire-tube type having auxiliary water tubes',
  },
  {
    c: 'F22B 13',
    d: 'Steam boilers of fire-box type, i.e. the combustion of fuel being performed in a chamber or fire-box with subsequent flue',
  },
  {
    c: 'F22B 15',
    d: 'Water-tube boilers of horizontal type, i.e. the water-tube sets being arranged horizontally',
  },
  {
    c: 'F22B 17',
    d: 'Water-tube boilers of horizontally-inclined type, i.e. the water-tube sets being inclined slightly with respect to the horizontal plane',
  },
  {
    c: 'F22B 19',
    d: 'Water-tube boilers of combined horizontally-inclined type and vertical type, i.e. water-tube boilers of horizontally-inclined type having auxiliary water-tube sets in vertical or substantially-vertical arrangement',
  },
  {
    c: 'F22B 21',
    d: 'Water-tube boilers of vertical or steeply-inclined type, i.e. the water-tube sets being arranged vertically or substantially vertically',
  },
  {
    c: 'F22B 23',
    d: 'Water-tube boilers built-up from sets of spaced double-walled water tubes of return type in unilaterial abutting connection with a boiler drum or with a header box, i.e. built-up from Field water tubes comprising an inner tube arranged within an outer unilaterally-closed tube',
  },
  {
    c: 'F22B 25',
    d: 'Water-tube boilers built-up from sets of water tubes with internally-arranged flue tubes, or fire tubes, extending through the water tubes',
  },
  {
    c: 'F22B 27',
    d: 'Instantaneous or flash steam boilers',
  },
  {
    c: 'F22B 29',
    d: 'Steam boilers of forced-flow type',
  },
  {
    c: 'F22B 31',
    d: 'Modifications of boiler construction, or of tube systems, dependent on installation of combustion apparatus; Arrangements or dispositions of combustion apparatus',
  },
  {
    c: 'F22B 33',
    d: 'Steam-generation plants; Control systems',
  },
  {
    c: 'F22B 35',
    d: 'Control systems for steam boilers',
  },
  {
    c: 'F22B 37',
    d: 'Component parts or details of steam boilers',
  },
  {
    c: 'F22D',
    d: 'PREHEATING, OR ACCUMULATING PREHEATED, FEED-WATER FOR STEAM GENERATION; FEED-WATER SUPPLY FOR STEAM GENERATION; CONTROLLING WATER LEVEL FOR STEAM GENERATION; AUXILIARY DEVICES FOR PROMOTING WATER CIRCULATION WITHIN STEAM BOILERS',
  },
  {
    c: 'F22D 1',
    d: 'Feed-water heaters, e.g. preheaters',
  },
  {
    c: 'F22D 3',
    d: 'Accumulators for preheated water',
  },
  {
    c: 'F22D 5',
    d: 'Controlling water feed or water level; Automatic water feeding or water-level regulators',
  },
  {
    c: 'F22D 7',
    d: 'Auxiliary devices for promoting water circulation',
  },
  {
    c: 'F22D 11',
    d: 'Feed-water supply not provided for in other main groups',
  },
  {
    c: 'F22G',
    d: 'SUPERHEATING OF STEAM',
  },
  {
    c: 'F22G 1',
    d: 'Steam superheating characterised by heating method',
  },
  {
    c: 'F22G 3',
    d: 'Steam superheaters characterised by constructional features; Details or component parts thereof',
  },
  {
    c: 'F22G 5',
    d: 'Controlling superheat temperature',
  },
  {
    c: 'F22G 7',
    d: 'Steam superheaters characterised by location, arrangement, or disposition',
  },
  {
    c: 'F23',
    d: 'COMBUSTION APPARATUS; COMBUSTION PROCESSES',
  },
  {
    c: 'F23B',
    d: 'METHODS OR APPARATUS FOR COMBUSTION USING ONLY SOLID FUEL',
  },
  {
    c: 'F23B 10',
    d: 'Combustion apparatus characterised by the combination of two or more combustion chambers',
  },
  {
    c: 'F23B 20',
    d: 'Combustion apparatus specially adapted for portability or transportability',
  },
  {
    c: 'F23B 30',
    d: 'Combustion apparatus with driven means for agitating the burning fuel; Combustion apparatus with driven means for advancing the burning fuel through the combustion chamber',
  },
  {
    c: 'F23B 40',
    d: 'Combustion apparatus with driven means for feeding fuel into the combustion chamber',
  },
  {
    c: 'F23B 50',
    d: 'Combustion apparatus in which the fuel is fed into or through the combustion zone by gravity, e.g. from a fuel storage situated above the combustion zone',
  },
  {
    c: 'F23B 60',
    d: 'Combustion apparatus in which the fuel burns essentially without moving',
  },
  {
    c: 'F23B 70',
    d: 'Combustion apparatus characterised by means for returning solid combustion residues to the combustion chamber',
  },
  {
    c: 'F23B 80',
    d: 'Combustion apparatus characterised by means creating a distinct flow path for flue gases or for non-combusted gases given off by the fuel',
  },
  {
    c: 'F23B 90',
    d: 'Combustion methods not related to a particular type of apparatus',
  },
  {
    c: 'F23B 99',
    d: 'n.a.',
  },
  {
    c: 'F23B 101',
    d: 'Indexing scheme related to adaptation of combustion apparatus to boilers',
  },
  {
    c: 'F23B 103',
    d: 'Adaptation of combustion apparatus for placement in or against an opening of a boiler, e.g. for replacing an oil burner',
  },
  {
    c: 'F23C',
    d: 'METHODS OR APPARATUS FOR COMBUSTION USING FLUID FUEL OR SOLID FUEL SUSPENDED IN AIR',
  },
  {
    c: 'F23C 1',
    d: 'Combustion apparatus specially adapted for combustion of two or more kinds of fuel simultaneously or alternately, at least one kind of fuel being either a fluid fuel or a solid fuel suspended in air',
  },
  {
    c: 'F23C 3',
    d: 'Combustion apparatus characterised by the shape of the combustion chamber',
  },
  {
    c: 'F23C 5',
    d: 'Combustion apparatus characterised by the arrangement or mounting of burners',
  },
  {
    c: 'F23C 6',
    d: 'Combustion apparatus characterised by the combination of two or more combustion chambers',
  },
  {
    c: 'F23C 7',
    d: 'Combustion apparatus characterised by arrangements for air supply',
  },
  {
    c: 'F23C 9',
    d: 'Combustion apparatus characterised by arrangements for returning combustion products or flue gases to the combustion chamber',
  },
  {
    c: 'F23C 10',
    d: 'Apparatus in which combustion takes place in a fluidised bed of fuel or other particles',
  },
  {
    c: 'F23C 13',
    d: 'Apparatus in which combustion takes place in the presence of catalytic material',
  },
  {
    c: 'F23C 15',
    d: 'Apparatus in which combustion takes place in pulses influenced by acoustic resonance in a gas mass',
  },
  {
    c: 'F23C 99',
    d: 'n.a.',
  },
  {
    c: 'F23C 101',
    d: 'Indexing scheme associated with group F23C0010000000, relating to combustion in entrained fluidised beds.',
  },
  {
    c: 'F23D',
    d: 'BURNERS',
  },
  {
    c: 'F23D 1',
    d: 'Burners for combustion of pulverulent fuel',
  },
  {
    c: 'F23D 3',
    d: 'Combustion of a liquid',
  },
  {
    c: 'F23D 5',
    d: 'Burners in which liquid fuel evaporates in the combustion space, with or without chemical conversion of evaporated fuel',
  },
  {
    c: 'F23D 7',
    d: 'Burners in which drops of liquid fuel impinge on a surface',
  },
  {
    c: 'F23D 9',
    d: 'Burners in which a stream of liquid fuel impinges intermittently on a hot surface',
  },
  {
    c: 'F23D 11',
    d: 'Burners using a direct spraying action of liquid droplets or vaporised liquid into the combustion space',
  },
  {
    c: 'F23D 14',
    d: 'Burners for combustion of a gas, e.g. of a gas stored under pressure as a liquid',
  },
  {
    c: 'F23D 17',
    d: 'Other burners',
  },
  {
    c: 'F23D 23',
    d: 'Assemblies of two or more burners',
  },
  {
    c: 'F23D 99',
    d: 'n.a.',
  },
  {
    c: 'F23G',
    d: 'CREMATION FURNACES; CONSUMING WASTE OR LOW GRADE FUELS BY COMBUSTION',
  },
  {
    c: 'F23G 1',
    d: 'Methods or apparatus specially adapted for cremation of human or animal carcasses',
  },
  {
    c: 'F23G 5',
    d: 'Methods or apparatus, e.g. incinerators, specially adapted for combustion of waste or low-grade fuels',
  },
  {
    c: 'F23G 7',
    d: 'Methods or apparatus, e.g. incinerators, specially adapted for combustion of specific waste or low grade fuels, e.g. chemicals',
  },
  {
    c: 'F23H',
    d: 'GRATES',
  },
  {
    c: 'F23H 1',
    d: 'Grates with solid bars',
  },
  {
    c: 'F23H 3',
    d: 'Grates with hollow bars',
  },
  {
    c: 'F23H 5',
    d: 'Double grates',
  },
  {
    c: 'F23H 7',
    d: 'Inclined grates',
  },
  {
    c: 'F23H 9',
    d: 'Revolving grates; Rocking grates',
  },
  {
    c: 'F23H 11',
    d: 'Travelling grates',
  },
  {
    c: 'F23H 13',
    d: 'Grates not covered by any of groups F23H0001000000-F23H0011000000',
  },
  {
    c: 'F23H 15',
    d: 'Cleaning arrangements for grates',
  },
  {
    c: 'F23H 17',
    d: 'Details of grates',
  },
  {
    c: 'F23J',
    d: 'REMOVAL OR TREATMENT OF COMBUSTION PRODUCTS OR COMBUSTION RESIDUES; FLUES',
  },
  {
    c: 'F23J 1',
    d: 'Removing ash, clinker, or slag from combustion chambers',
  },
  {
    c: 'F23J 3',
    d: 'Removing solid residues from passages or chambers beyond the fire, e.g. from flues by soot blowers',
  },
  {
    c: 'F23J 7',
    d: 'Arrangement of devices for supplying chemicals to fire',
  },
  {
    c: 'F23J 9',
    d: 'Preventing premature solidification of molten combustion residues',
  },
  {
    c: 'F23J 11',
    d: 'Devices for conducting smoke or fumes, e.g. flues',
  },
  {
    c: 'F23J 13',
    d: 'Fittings for chimneys or flues',
  },
  {
    c: 'F23J 15',
    d: 'Arrangements of devices for treating smoke or fumes',
  },
  {
    c: 'F23J 99',
    d: 'n.a.',
  },
  {
    c: 'F23K',
    d: 'FEEDING FUEL TO COMBUSTION APPARATUS',
  },
  {
    c: 'F23K 1',
    d: 'Preparation of lump or pulverulent fuel in readiness for delivery to combustion apparatus',
  },
  {
    c: 'F23K 3',
    d: 'Feeding or distributing of lump or pulverulent fuel to combustion apparatus',
  },
  {
    c: 'F23K 5',
    d: 'Feeding or distributing other fuel to combustion apparatus',
  },
  {
    c: 'F23L',
    d: 'SUPPLYING AIR OR NON-COMBUSTIBLE LIQUIDS OR GASES TO COMBUSTION APPARATUS IN GENERAL',
  },
  {
    c: 'F23L 1',
    d: 'Passages or apertures for delivering primary air for combustion',
  },
  {
    c: 'F23L 3',
    d: 'Arrangements of valves or dampers before the fire',
  },
  {
    c: 'F23L 5',
    d: 'Blast-producing apparatus before the fire',
  },
  {
    c: 'F23L 7',
    d: 'Supplying non-combustible liquids or gases, other than air, to the fire, e.g. oxygen, steam',
  },
  {
    c: 'F23L 9',
    d: 'Passages or apertures for delivering secondary air for completing combustion of fuel',
  },
  {
    c: 'F23L 11',
    d: 'Arrangements of valves or dampers after the fire',
  },
  {
    c: 'F23L 13',
    d: 'Construction of valves or dampers for controlling air supply or draught',
  },
  {
    c: 'F23L 15',
    d: 'Heating of air supplied for combustion',
  },
  {
    c: 'F23L 17',
    d: 'Inducing draught; Tops for chimneys or ventilating shafts; Terminals for flues',
  },
  {
    c: 'F23L 99',
    d: 'n.a.',
  },
  {
    c: 'F23M',
    d: 'CASINGS, LININGS, WALLS OR DOORS SPECIALLY ADAPTED FOR COMBUSTION CHAMBERS, e.g. FIREBRIDGES; DEVICES FOR DEFLECTING AIR, FLAMES OR COMBUSTION PRODUCTS IN COMBUSTION CHAMBERS; SAFETY ARRANGEMENTS SPECIALLY ADAPTED FOR COMBUSTION APPARATUS; DETAILS OF COMBUSTION CHAMBERS, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'F23M 3',
    d: 'Firebridges',
  },
  {
    c: 'F23M 5',
    d: 'Casings; Linings; Walls',
  },
  {
    c: 'F23M 7',
    d: 'Doors',
  },
  {
    c: 'F23M 9',
    d: 'Baffles or deflectors for air or combustion products',
  },
  {
    c: 'F23M 11',
    d: 'Safety arrangements',
  },
  {
    c: 'F23M 20',
    d: 'Details of combustion chambers, not otherwise provided for',
  },
  {
    c: 'F23N',
    d: 'REGULATING OR CONTROLLING COMBUSTION',
  },
  {
    c: 'F23N 1',
    d: 'Regulating fuel supply',
  },
  {
    c: 'F23N 3',
    d: 'Regulating air supply or draught',
  },
  {
    c: 'F23N 5',
    d: 'Systems for controlling combustion',
  },
  {
    c: 'F23Q',
    d: 'IGNITION',
  },
  {
    c: 'F23Q 1',
    d: 'Mechanical ignition',
  },
  {
    c: 'F23Q 2',
    d: 'Lighters containing fuel, e.g. for cigarettes',
  },
  {
    c: 'F23Q 3',
    d: 'Ignition using electrically-produced sparks',
  },
  {
    c: 'F23Q 5',
    d: 'Make-and-break ignition, i.e. with spark generated between electrodes by breaking contact therebetween',
  },
  {
    c: 'F23Q 7',
    d: 'Incandescent ignition; Ignition using electrically-produced heat, e.g. lighters for cigarettes; Electrically-heated glowing plugs',
  },
  {
    c: 'F23Q 9',
    d: 'Ignition by a pilot flame',
  },
  {
    c: 'F23Q 11',
    d: 'Arrangement of catalytic igniters',
  },
  {
    c: 'F23Q 13',
    d: 'Ignition not otherwise provided for',
  },
  {
    c: 'F23Q 21',
    d: 'Devices for effecting ignition from a remote location',
  },
  {
    c: 'F23Q 23',
    d: 'Testing of ignition installations',
  },
  {
    c: 'F23Q 25',
    d: 'Extinguishing devices, e.g. for blowing-out or snuffing candle flames',
  },
  {
    c: 'F23R',
    d: 'GENERATING COMBUSTION PRODUCTS OF HIGH PRESSURE OR HIGH VELOCITY, e.g. GAS-TURBINE COMBUSTION CHAMBERS',
  },
  {
    c: 'F23R 3',
    d: 'Continuous combustion chambers using liquid or gaseous fuel',
  },
  {
    c: 'F23R 5',
    d: 'Continuous combustion chambers using solid or pulverulent fuel',
  },
  {
    c: 'F23R 7',
    d: 'Intermittent or explosive combustion chambers',
  },
  {
    c: 'F24',
    d: 'HEATING; RANGES; VENTILATING',
  },
  {
    c: 'F24B',
    d: 'DOMESTIC STOVES OR RANGES FOR SOLID FUELS; IMPLEMENTS FOR USE IN CONNECTION WITH STOVES OR RANGES',
  },
  {
    c: 'F24B 1',
    d: 'Stoves or ranges',
  },
  {
    c: 'F24B 3',
    d: 'Heaters not covered by group F24B0001000000, e.g. charcoal brazier',
  },
  {
    c: 'F24B 5',
    d: 'Combustion-air or flue-gas circulation in or around stoves or ranges',
  },
  {
    c: 'F24B 7',
    d: 'Stoves, ranges, or flue-gas ducts, with additional provisions for convection heating',
  },
  {
    c: 'F24B 9',
    d: 'Stoves, ranges, or flue-gas ducts, with additional provisions for heating water',
  },
  {
    c: 'F24B 13',
    d: 'Details solely applicable to stoves or ranges burning solid fuels',
  },
  {
    c: 'F24B 15',
    d: 'Implements for use in connection with stoves or ranges',
  },
  {
    c: 'F24C',
    d: 'OTHER DOMESTIC STOVES OR RANGES; DETAILS OF DOMESTIC STOVES OR RANGES, OF GENERAL APPLICATION',
  },
  {
    c: 'F24C 1',
    d: 'Stoves or ranges in which the fuel or energy supply is not restricted to solid fuel or to a type covered by a single one of groups F24C0003000000-F24C0009000000; Stoves or ranges in which the type of fuel or energy supply is not specified',
  },
  {
    c: 'F24C 3',
    d: 'Stoves or ranges for gaseous fuels',
  },
  {
    c: 'F24C 5',
    d: 'Stoves or ranges for liquid fuels',
  },
  {
    c: 'F24C 7',
    d: 'Stoves or ranges heated by electric energy',
  },
  {
    c: 'F24C 9',
    d: 'Stoves or ranges heated by a single type of energy supply not covered by groups F24C0003000000-F24C0007000000 or subclass F24B',
  },
  {
    c: 'F24C 11',
    d: 'Combinations of two or more stoves or ranges, e.g. each having a different kind of energy supply',
  },
  {
    c: 'F24C 13',
    d: 'Stoves or ranges with additional provisions for heating water',
  },
  {
    c: 'F24C 14',
    d: 'Stoves or ranges having self-cleaning provisions, e.g. continuous or catalytic cleaning, electrostatic cleaning',
  },
  {
    c: 'F24C 15',
    d: 'Details',
  },
  {
    c: 'F24D',
    d: 'DOMESTIC- OR SPACE-HEATING SYSTEMS, e.g. CENTRAL HEATING SYSTEMS; DOMESTIC HOT-WATER SUPPLY SYSTEMS; ELEMENTS OR COMPONENTS THEREFOR',
  },
  {
    c: 'F24D 1',
    d: 'Central heating systems',
  },
  {
    c: 'F24D 3',
    d: 'Hot-water central heating systems',
  },
  {
    c: 'F24D 5',
    d: 'Hot-air central heating systems',
  },
  {
    c: 'F24D 7',
    d: 'Central heating systems employing heat-transfer fluids not covered by groups F24D0001000000-F24D0005000000, e.g. oil, salt, gas',
  },
  {
    c: 'F24D 9',
    d: 'Central heating systems employing combinations of heat-transfer fluids covered by two or more of groups F24D0001000000-F24D0007000000',
  },
  {
    c: 'F24D 10',
    d: 'District heating systems',
  },
  {
    c: 'F24D 11',
    d: 'Central heating systems using heat accumulated in storage masses',
  },
  {
    c: 'F24D 12',
    d: 'Other central heating systems',
  },
  {
    c: 'F24D 13',
    d: 'Other domestic- or space-heating systems',
  },
  {
    c: 'F24D 15',
    d: 'Other domestic- or space-heating systems',
  },
  {
    c: 'F24D 17',
    d: 'Domestic hot-water supply systems',
  },
  {
    c: 'F24D 19',
    d: 'Details',
  },
  {
    c: 'F24F',
    d: 'AIR-CONDITIONING; AIR-HUMIDIFICATION; VENTILATION; USE OF AIR CURRENTS FOR SCREENING',
  },
  {
    c: 'F24F 1',
    d: 'Room units for air-conditioning, e.g. separate or self-contained units or units receiving primary air from a central station',
  },
  {
    c: 'F24F 3',
    d: 'Air-conditioning systems in which conditioned primary air is supplied from one or more central stations to distributing units in the rooms or spaces where it may receive secondary treatment; Apparatus specially designed for such systems',
  },
  {
    c: 'F24F 5',
    d: 'Air-conditioning systems or apparatus not covered by group F24F0001000000 or F24F0003000000',
  },
  {
    c: 'F24F 6',
    d: 'Air-humidification',
  },
  {
    c: 'F24F 7',
    d: 'Ventilation',
  },
  {
    c: 'F24F 9',
    d: 'Use of air currents for screening, e.g. air curtains',
  },
  {
    c: 'F24F 11',
    d: 'Control or safety arrangements',
  },
  {
    c: 'F24F 12',
    d: 'Use of energy recovery systems in air conditioning, ventilation or screening',
  },
  {
    c: 'F24F 13',
    d: 'Details common to, or for air-conditioning, air-humidification, ventilation or use of air currents for screening',
  },
  {
    c: 'F24F 110',
    d: 'Indexing scheme associated with group F24F0011000000, relating to control inputs, e.g. measured or estimated values or parameters',
  },
  {
    c: 'F24F 120',
    d: 'Control inputs relating to users or occupants',
  },
  {
    c: 'F24F 130',
    d: 'Control inputs relating to environmental factors not covered by group F24F0110000000',
  },
  {
    c: 'F24F 140',
    d: 'Control inputs relating to system states',
  },
  {
    c: 'F24H',
    d: 'FLUID HEATERS, e.g. WATER OR AIR HEATERS, HAVING HEAT-GENERATING MEANS, IN GENERAL',
  },
  {
    c: 'F24H 1',
    d: 'Water heaters having heat generating means, e.g. boiler, flow-heater, water-storage heater',
  },
  {
    c: 'F24H 3',
    d: 'Air heaters having heat generating means',
  },
  {
    c: 'F24H 4',
    d: 'Fluid heaters using heat pumps',
  },
  {
    c: 'F24H 6',
    d: 'Combined water and air heaters',
  },
  {
    c: 'F24H 7',
    d: 'Storage heaters, i.e. heaters in which the energy is stored as heat in masses for subsequent release',
  },
  {
    c: 'F24H 8',
    d: 'Fluid heaters having heat-generating means specially adapted for extracting latent heat from flue gases by means of condensation',
  },
  {
    c: 'F24H 9',
    d: 'Details',
  },
  {
    c: 'F24S',
    d: 'SOLAR HEAT COLLECTORS; SOLAR HEAT SYSTEMS',
  },
  {
    c: 'F24S 10',
    d: 'Solar heat collectors using working fluids',
  },
  {
    c: 'F24S 20',
    d: 'Solar heat collectors specially adapted for particular uses or environments',
  },
  {
    c: 'F24S 21',
    d: 'Solar heat collectors not provided for in groups F24S0010000000-F24S0020000000',
  },
  {
    c: 'F24S 23',
    d: 'Arrangements for concentrating solar rays for solar heat collectors',
  },
  {
    c: 'F24S 25',
    d: 'Arrangement of stationary mountings or supports for solar heat collector modules',
  },
  {
    c: 'F24S 30',
    d: 'Arrangements for moving or orienting solar heat collector modules',
  },
  {
    c: 'F24S 40',
    d: 'Safety or protection arrangements of solar heat collectors; Preventing malfunction of solar heat collectors',
  },
  {
    c: 'F24S 50',
    d: 'Arrangements for controlling solar heat collectors',
  },
  {
    c: 'F24S 60',
    d: 'Arrangements for storing heat collected by solar heat collectors',
  },
  {
    c: 'F24S 70',
    d: 'Details of absorbing elements',
  },
  {
    c: 'F24S 80',
    d: 'Details, accessories or component parts of solar heat collectors not provided for in groups F24S0010000000-F24S0070000000',
  },
  {
    c: 'F24S 90',
    d: 'Solar heat systems not otherwise provided for',
  },
  {
    c: 'F24T',
    d: 'GEOTHERMAL COLLECTORS; GEOTHERMAL SYSTEMS',
  },
  {
    c: 'F24T 10',
    d: 'Geothermal collectors',
  },
  {
    c: 'F24T 50',
    d: 'Geothermal systems',
  },
  {
    c: 'F24V',
    d: 'COLLECTION, PRODUCTION OR USE OF HEAT NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'F24V 30',
    d: 'Apparatus or devices using heat produced by exothermal chemical reactions other than by combustion',
  },
  {
    c: 'F24V 40',
    d: 'Production or use of heat resulting from internal friction of moving fluids or from friction between fluids and moving bodies',
  },
  {
    c: 'F24V 50',
    d: 'Use of heat from natural sources, e.g. from the sea',
  },
  {
    c: 'F24V 99',
    d: 'n.a.',
  },
  {
    c: 'F25',
    d: 'REFRIGERATION OR COOLING; COMBINED HEATING AND REFRIGERATION SYSTEMS; HEAT PUMP SYSTEMS; MANUFACTURE OR STORAGE OF ICE; LIQUEFACTION OR SOLIDIFICATION OF GASES',
  },
  {
    c: 'F25B',
    d: 'REFRIGERATION MACHINES, PLANTS, OR SYSTEMS; COMBINED HEATING AND REFRIGERATION SYSTEMS; HEAT PUMP SYSTEMS',
  },
  {
    c: 'F25B 1',
    d: 'Compression machines, plant, or systems',
  },
  {
    c: 'F25B 3',
    d: 'Self-contained rotary compression machines, i.e. with compressor, condenser, and evaporator rotating as a single unit',
  },
  {
    c: 'F25B 5',
    d: 'Compression machines, plant, or systems, with several evaporator circuits, e.g. for varying refrigerating capacity',
  },
  {
    c: 'F25B 6',
    d: 'Compression machines, plant, or systems, with several condenser circuits',
  },
  {
    c: 'F25B 7',
    d: 'Compression machines, plant, or systems, with cascade operation, i.e. with two or more circuits, the heat from the condenser of one circuit being absorbed by the evaporator of the next circuit',
  },
  {
    c: 'F25B 9',
    d: 'Compression machines, plant, or systems, in which the refrigerant is air or other gas of low boiling point',
  },
  {
    c: 'F25B 11',
    d: 'Compression machines, plant, or systems, using turbines, e.g. gas turbines',
  },
  {
    c: 'F25B 13',
    d: 'Compression machines, plant, or systems, with reversible cycle',
  },
  {
    c: 'F25B 15',
    d: 'Sorption machines, plant, or systems',
  },
  {
    c: 'F25B 17',
    d: 'Sorption machines, plant, or systems, operating intermittently, e.g. absorption or adsorption type',
  },
  {
    c: 'F25B 19',
    d: 'Machines, plant, or systems, with a single mode of operation, not covered by groups F25B0001000000-F25B0017000000',
  },
  {
    c: 'F25B 21',
    d: 'Machines, plant, or systems, using electric or magnetic effects',
  },
  {
    c: 'F25B 23',
    d: 'Machines, plant, or systems, with a single mode of operation not covered by groups F25B0001000000-F25B0021000000, e.g. using selective radiation effect',
  },
  {
    c: 'F25B 25',
    d: 'Machines, plant, or systems, using a combination of modes of operation covered by two or more of the groups F25B0001000000-F25B0023000000',
  },
  {
    c: 'F25B 27',
    d: 'Machines, plant, or systems, using particular sources of energy',
  },
  {
    c: 'F25B 29',
    d: 'Combined heating and refrigeration systems, e.g. operating alternately or simultaneously',
  },
  {
    c: 'F25B 30',
    d: 'Heat pumps',
  },
  {
    c: 'F25B 31',
    d: 'Component parts or details',
  },
  {
    c: 'F25B 33',
    d: 'Boilers; Analysers; Rectifiers',
  },
  {
    c: 'F25B 35',
    d: 'Boiler-absorbers, i.e. boilers usable for absorption or adsorption',
  },
  {
    c: 'F25B 37',
    d: 'Absorbers; Adsorbers',
  },
  {
    c: 'F25B 39',
    d: 'Evaporators; Condensers',
  },
  {
    c: 'F25B 40',
    d: 'Subcoolers, desuperheaters or superheaters',
  },
  {
    c: 'F25B 41',
    d: 'Fluid-circulation arrangements, e.g. for transferring liquid from evaporator to boiler',
  },
  {
    c: 'F25B 43',
    d: 'Arrangements for separating or purifying gases or liquids',
  },
  {
    c: 'F25B 45',
    d: 'Arrangements for charging or discharging refrigerant',
  },
  {
    c: 'F25B 47',
    d: 'Arrangements for preventing or removing deposits or corrosion, not provided for in another subclass',
  },
  {
    c: 'F25B 49',
    d: 'Arrangement or mounting of control or safety devices',
  },
  {
    c: 'F25C',
    d: 'PRODUCING, WORKING OR HANDLING ICE',
  },
  {
    c: 'F25C 1',
    d: 'Producing ice',
  },
  {
    c: 'F25C 3',
    d: 'Processes or apparatus specially adapted for producing ice or snow for winter sports or similar recreational purposes, e.g. for sporting installations; Producing artificial snow',
  },
  {
    c: 'F25C 5',
    d: 'Working or handling ice',
  },
  {
    c: 'F25D',
    d: 'REFRIGERATORS; COLD ROOMS; ICE-BOXES; COOLING OR FREEZING APPARATUS NOT COVERED BY ANY OTHER SUBCLASS',
  },
  {
    c: 'F25D 1',
    d: 'Devices not associated with refrigerating machinery',
  },
  {
    c: 'F25D 3',
    d: 'Devices using other cold materials; Devices using cold-storage bodies',
  },
  {
    c: 'F25D 5',
    d: 'Devices using endothermic chemical reactions, e.g. using frigorific mixtures',
  },
  {
    c: 'F25D 7',
    d: 'Devices using evaporation effects without recovery of the vapour',
  },
  {
    c: 'F25D 9',
    d: 'Devices not associated with refrigerating machinery and not covered by groups F25D0001000000-F25D0007000000; Combinations of devices covered by two or more of the groups F25D0001000000-F25D0007000000',
  },
  {
    c: 'F25D 11',
    d: 'Devices associated with refrigerating machinery',
  },
  {
    c: 'F25D 13',
    d: 'Stationary devices associated with refrigerating machinery, e.g. cold rooms',
  },
  {
    c: 'F25D 15',
    d: 'Devices associated with refrigerating machinery not covered by group F25D0011000000 or F25D0013000000, e.g. non-self-contained movable devices',
  },
  {
    c: 'F25D 16',
    d: 'Devices using a combination of a cooling mode associated with refrigerating machinery with a cooling mode not associated with refrigerating machinery',
  },
  {
    c: 'F25D 17',
    d: 'Details or features of the devices covered by groups F25D0001000000-F25D0016000000',
  },
  {
    c: 'F25D 19',
    d: 'Arrangement or mounting of refrigeration units with respect to devices',
  },
  {
    c: 'F25D 21',
    d: 'Defrosting; Preventing frosting; Removing condensed or defrost water',
  },
  {
    c: 'F25D 23',
    d: 'General constructional features',
  },
  {
    c: 'F25D 25',
    d: 'Charging, supporting, or discharging the articles to be cooled',
  },
  {
    c: 'F25D 27',
    d: 'Lighting arrangements',
  },
  {
    c: 'F25D 29',
    d: 'Arrangement or mounting of control or safety devices',
  },
  {
    c: 'F25D 31',
    d: 'Other cooling or freezing apparatus',
  },
  {
    c: 'F25J',
    d: 'LIQUEFACTION, SOLIDIFICATION, OR SEPARATION OF GASES OR GASEOUS MIXTURES BY PRESSURE AND COLD TREATMENT',
  },
  {
    c: 'F25J 1',
    d: 'Processes or apparatus for liquefying or solidifying gases or gaseous mixtures',
  },
  {
    c: 'F25J 3',
    d: 'Processes or apparatus for separating the constituents of gaseous mixtures involving the use of liquefaction or solidification',
  },
  {
    c: 'F25J 5',
    d: 'Arrangements of cold-exchangers or cold-accumulators in separation or liquefaction plants',
  },
  {
    c: 'F26',
    d: 'DRYING',
  },
  {
    c: 'F26B',
    d: 'DRYING SOLID MATERIALS OR OBJECTS BY REMOVING LIQUID THEREFROM',
  },
  {
    c: 'F26B 1',
    d: 'Processes for drying',
  },
  {
    c: 'F26B 3',
    d: 'Drying solid materials or objects by processes involving the application of heat',
  },
  {
    c: 'F26B 5',
    d: 'Drying solid materials or objects by processes not involving the application of heat',
  },
  {
    c: 'F26B 7',
    d: 'Drying solid materials or objects by processes using a combination of processes not covered by a single one of groups F26B0003000000 or F26B0005000000',
  },
  {
    c: 'F26B 9',
    d: 'Machines or apparatus for drying',
  },
  {
    c: 'F26B 11',
    d: 'Machines or apparatus for drying solid materials or objects with movement which is non-progressive',
  },
  {
    c: 'F26B 13',
    d: 'Machines or apparatus for drying fabrics, fibres, yarns, or other materials in long lengths, with progressive movement',
  },
  {
    c: 'F26B 15',
    d: 'Machines or apparatus for drying objects with progressive movement; Machines or apparatus with progressive movement for drying batches of material in compact form',
  },
  {
    c: 'F26B 17',
    d: 'Machines or apparatus for drying materials in loose, plastic, or fluidised form, e.g. granules, staple fibres, with progressive movement',
  },
  {
    c: 'F26B 19',
    d: 'Machines or apparatus for drying solid materials or objects not covered by groups F26B0009000000-F26B0017000000',
  },
  {
    c: 'F26B 20',
    d: 'Combinations of machines or apparatus covered by two or more of groups F26B0009000000-F26B0019000000',
  },
  {
    c: 'F26B 21',
    d: 'Details of general application',
  },
  {
    c: 'F26B 23',
    d: 'Heating arrangements',
  },
  {
    c: 'F26B 25',
    d: 'Details of general application not covered by group F26B0021000000 or F26B0023000000',
  },
  {
    c: 'F27',
    d: 'FURNACES; KILNS; OVENS; RETORTS',
  },
  {
    c: 'F27B',
    d: 'FURNACES, KILNS, OVENS, OR RETORTS IN GENERAL; OPEN SINTERING OR LIKE APPARATUS',
  },
  {
    c: 'F27B 1',
    d: 'Shaft or like vertical or substantially vertical furnaces',
  },
  {
    c: 'F27B 3',
    d: 'Hearth-type furnaces, e.g. of reverberatory type',
  },
  {
    c: 'F27B 5',
    d: 'Muffle furnaces; Retort furnaces; Other furnaces in which the charge is held completely isolated',
  },
  {
    c: 'F27B 7',
    d: 'Rotary-drum furnaces, i.e. horizontal or slightly inclined',
  },
  {
    c: 'F27B 9',
    d: 'Furnaces through which the charge is moved mechanically, e.g. of tunnel type',
  },
  {
    c: 'F27B 11',
    d: 'Bell-type furnaces',
  },
  {
    c: 'F27B 13',
    d: 'Furnaces with both stationary charge and progression of heating, e.g. of ring type, of type in which segmental kiln moves over stationary charge',
  },
  {
    c: 'F27B 14',
    d: 'Crucible or pot furnaces; Tank furnaces',
  },
  {
    c: 'F27B 15',
    d: 'Fluidised-bed furnaces; Other furnaces using or treating finely-divided materials in dispersion',
  },
  {
    c: 'F27B 17',
    d: 'Furnaces of a kind not covered by any of groups F27B0001000000-F27B0015000000',
  },
  {
    c: 'F27B 19',
    d: 'Combinations of different kinds of furnaces that are not all covered by any single one of main groups F27B0001000000-F27B0017000000',
  },
  {
    c: 'F27B 21',
    d: 'Open or uncovered sintering apparatus; Other heat-treatment apparatus of like construction',
  },
  {
    c: 'F27D',
    d: 'DETAILS OR ACCESSORIES OF FURNACES, KILNS, OVENS, OR RETORTS, IN SO FAR AS THEY ARE OF KINDS OCCURRING IN MORE THAN ONE KIND OF FURNACE',
  },
  {
    c: 'F27D 1',
    d: 'Casings; Linings; Walls; Roofs',
  },
  {
    c: 'F27D 3',
    d: 'Charging; Discharging; Manipulation of charge',
  },
  {
    c: 'F27D 5',
    d: 'Supports, screens, or the like for the charge within the furnace',
  },
  {
    c: 'F27D 7',
    d: 'Forming, maintaining, or circulating atmospheres in heating chambers',
  },
  {
    c: 'F27D 9',
    d: 'Cooling of furnaces or of charges therein',
  },
  {
    c: 'F27D 11',
    d: 'Arrangement of elements for electric heating in or on furnaces',
  },
  {
    c: 'F27D 13',
    d: 'Apparatus for preheating charges; Arrangements for preheating charges',
  },
  {
    c: 'F27D 15',
    d: 'Handling or treating discharged material; Supports or receiving chambers therefor',
  },
  {
    c: 'F27D 17',
    d: 'Arrangement for using waste heat',
  },
  {
    c: 'F27D 19',
    d: 'Arrangement of controlling devices',
  },
  {
    c: 'F27D 21',
    d: 'Arrangement of monitoring devices; Arrangements of safety devices',
  },
  {
    c: 'F27D 25',
    d: 'Devices for removing incrustations',
  },
  {
    c: 'F27D 27',
    d: 'Stirring devices for molten material',
  },
  {
    c: 'F27D 99',
    d: 'n.a.',
  },
  {
    c: 'F28',
    d: 'HEAT EXCHANGE IN GENERAL',
  },
  {
    c: 'F28B',
    d: 'STEAM OR VAPOUR CONDENSERS',
  },
  {
    c: 'F28B 1',
    d: 'Condensers in which the steam or vapour is separated from the cooling medium by walls, e.g. surface condenser',
  },
  {
    c: 'F28B 3',
    d: 'Condensers in which the steam or vapour comes into direct contact with the cooling medium',
  },
  {
    c: 'F28B 5',
    d: 'Condensers employing a combination of the methods covered by groups F28B0001000000 and F28B0003000000; Other condensers',
  },
  {
    c: 'F28B 7',
    d: 'Combinations of two or more condensers, e.g. provision of reserve condenser',
  },
  {
    c: 'F28B 9',
    d: 'Auxiliary systems, arrangements, or devices',
  },
  {
    c: 'F28B 11',
    d: 'Controlling arrangements with features specially adapted for condensers',
  },
  {
    c: 'F28C',
    d: 'HEAT-EXCHANGE APPARATUS, NOT PROVIDED FOR IN ANOTHER SUBCLASS, IN WHICH THE HEAT-EXCHANGE MEDIA COME INTO DIRECT CONTACT WITHOUT CHEMICAL INTERACTION',
  },
  {
    c: 'F28C 1',
    d: 'Direct-contact trickle coolers, e.g. cooling towers',
  },
  {
    c: 'F28C 3',
    d: 'Other direct-contact heat-exchange apparatus',
  },
  {
    c: 'F28D',
    d: 'HEAT-EXCHANGE APPARATUS, NOT PROVIDED FOR IN ANOTHER SUBCLASS, IN WHICH THE HEAT-EXCHANGE MEDIA DO NOT COME INTO DIRECT CONTACT',
  },
  {
    c: 'F28D 1',
    d: 'Heat-exchange apparatus having stationary conduit assemblies for one heat-exchange medium only, the media being in contact with different sides of the conduit wall, in which the other heat-exchange medium is a large body of fluid, e.g. domestic or motor car radiators',
  },
  {
    c: 'F28D 3',
    d: 'Heat-exchange apparatus having stationary conduit assemblies for one heat-exchange medium only, the media being in contact with different sides of the conduit wall, in which the other heat-exchange medium flows in a continuous film, or trickles freely, over the conduits',
  },
  {
    c: 'F28D 5',
    d: 'Heat-exchange apparatus having stationary conduit assemblies for one heat-exchange medium only, the media being in contact with different sides of the conduit wall, using the cooling effect of natural or forced evaporation',
  },
  {
    c: 'F28D 7',
    d: 'Heat-exchange apparatus having stationary tubular conduit assemblies for both heat-exchange media, the media being in contact with different sides of a conduit wall',
  },
  {
    c: 'F28D 9',
    d: 'Heat-exchange apparatus having stationary plate-like or laminated conduit assemblies for both heat-exchange media, the media being in contact with different sides of a conduit wall',
  },
  {
    c: 'F28D 11',
    d: 'Heat-exchange apparatus employing moving conduits',
  },
  {
    c: 'F28D 13',
    d: 'Heat-exchange apparatus using a fluidised bed',
  },
  {
    c: 'F28D 15',
    d: 'Heat-exchange apparatus employing intermediate heat-transfer media or bodies',
  },
  {
    c: 'F28D 17',
    d: 'Regenerative heat-exchange apparatus in which a stationary intermediate heat-transfer medium or body is contacted successively by each heat-exchange medium, e.g. using granular particles',
  },
  {
    c: 'F28D 19',
    d: 'Regenerative heat-exchange apparatus in which the intermediate heat-transfer medium or body is moved successively into contact with each heat-exchange medium',
  },
  {
    c: 'F28D 20',
    d: 'Heat storage plants or apparatus in general; Regenerative heat-exchange apparatus not covered by groups F28D0017000000 or F28D0019000000',
  },
  {
    c: 'F28D 21',
    d: 'Heat-exchange apparatus not covered by any of the groups F28D0001000000-F28D0020000000',
  },
  {
    c: 'F28F',
    d: 'DETAILS OF HEAT-EXCHANGE OR HEAT-TRANSFER APPARATUS, OF GENERAL APPLICATION',
  },
  {
    c: 'F28F 1',
    d: 'Tubular elements; Assemblies of tubular elements',
  },
  {
    c: 'F28F 3',
    d: 'Plate-like or laminated elements; Assemblies of plate-like or laminated elements',
  },
  {
    c: 'F28F 5',
    d: 'Elements specially adapted for movement',
  },
  {
    c: 'F28F 7',
    d: 'Elements not covered by group F28F0001000000, F28F0003000000, or F28F0005000000',
  },
  {
    c: 'F28F 9',
    d: 'Casings; Header boxes; Auxiliary supports for elements; Auxiliary members within casings',
  },
  {
    c: 'F28F 11',
    d: 'Arrangements for sealing leaky tubes or conduits',
  },
  {
    c: 'F28F 13',
    d: 'Arrangements for modifying heat transfer, e.g. increasing, decreasing',
  },
  {
    c: 'F28F 17',
    d: 'Removing ice or water from heat-exchange apparatus',
  },
  {
    c: 'F28F 19',
    d: 'Preventing the formation of deposits or corrosion, e.g. by using filters',
  },
  {
    c: 'F28F 21',
    d: 'Constructions of heat-exchange apparatus characterised by the selection of particular materials',
  },
  {
    c: 'F28F 23',
    d: 'Features relating to the use of intermediate heat-exchange materials, e.g. selection of compositions',
  },
  {
    c: 'F28F 25',
    d: 'Component parts of trickle coolers',
  },
  {
    c: 'F28F 27',
    d: 'Control arrangements or safety devices specially adapted for heat-exchange or heat-transfer apparatus',
  },
  {
    c: 'F28F 99',
    d: 'n.a.',
  },
  {
    c: 'F28G',
    d: 'CLEANING OF INTERNAL OR EXTERNAL SURFACES OF HEAT-EXCHANGE OR HEAT-TRANSFER CONDUITS, e.g. WATER TUBES OF BOILERS',
  },
  {
    c: 'F28G 1',
    d: 'Non-rotary, e.g. reciprocated, appliances',
  },
  {
    c: 'F28G 3',
    d: 'Rotary appliances',
  },
  {
    c: 'F28G 5',
    d: 'Cleaning by distortion',
  },
  {
    c: 'F28G 7',
    d: 'Cleaning by vibration',
  },
  {
    c: 'F28G 9',
    d: 'Cleaning by flushing or washing, e.g. with chemical solvents',
  },
  {
    c: 'F28G 11',
    d: 'Cleaning by combustion processes, e.g. using squibs, using travelling burners',
  },
  {
    c: 'F28G 13',
    d: 'Appliances or processes not covered by groups F28G0001000000-F28G0011000000; Combinations of appliances or processes covered by groups F28G0001000000-F28G0011000000',
  },
  {
    c: 'F28G 15',
    d: 'Details',
  },
  {
    c: 'F41',
    d: 'WEAPONS',
  },
  {
    c: 'F41A',
    d: 'FUNCTIONAL FEATURES OR DETAILS COMMON TO BOTH SMALLARMS AND ORDNANCE, e.g. CANNONS; MOUNTINGS FOR SMALLARMS OR ORDNANCE',
  },
  {
    c: 'F41A 1',
    d: 'Missile propulsion characterised by the use of explosive or combustible propellant charges',
  },
  {
    c: 'F41A 3',
    d: 'Breech mechanism, e.g. locks',
  },
  {
    c: 'F41A 5',
    d: 'Mechanisms or systems operated by propellant charge energy for automatically opening the lock',
  },
  {
    c: 'F41A 7',
    d: 'Auxiliary mechanisms for bringing the breech-block or bolt or the barrel to the starting position before automatic firing',
  },
  {
    c: 'F41A 9',
    d: 'Feeding or loading of ammunition',
  },
  {
    c: 'F41A 11',
    d: 'Assembly or disassembly features; Modular concepts; Articulated or collapsible guns',
  },
  {
    c: 'F41A 13',
    d: 'Cooling or heating systems',
  },
  {
    c: 'F41A 15',
    d: 'Cartridge extractors, i.e. devices for pulling cartridges or cartridge cases at least partially out of the cartridge chamber; Cartridge ejectors, i.e. devices for throwing the extracted cartridges or cartridge cases free of the gun',
  },
  {
    c: 'F41A 17',
    d: 'Safety arrangements, e.g. safeties',
  },
  {
    c: 'F41A 19',
    d: 'Firing or trigger mechanisms; Cocking mechanisms',
  },
  {
    c: 'F41A 21',
    d: 'Barrels or gun tubes; Muzzle attachments; Barrel mounting means',
  },
  {
    c: 'F41A 23',
    d: 'Gun mountings, e.g. on vehicles; Disposition of guns on vehicles',
  },
  {
    c: 'F41A 25',
    d: 'Gun mountings permitting recoil or return to battery, e.g. gun cradles; Barrel buffers or brakes',
  },
  {
    c: 'F41A 27',
    d: 'Gun mountings permitting traversing or elevating movement, e.g. gun carriages',
  },
  {
    c: 'F41A 29',
    d: 'Cleaning or lubricating arrangements',
  },
  {
    c: 'F41A 31',
    d: 'Testing arrangements',
  },
  {
    c: 'F41A 33',
    d: 'Adaptations for training',
  },
  {
    c: 'F41A 35',
    d: 'Accessories or details not otherwise provided for',
  },
  {
    c: 'F41A 99',
    d: 'n.a.',
  },
  {
    c: 'F41B',
    d: 'WEAPONS FOR PROJECTING MISSILES WITHOUT USE OF EXPLOSIVE OR COMBUSTIBLE PROPELLANT CHARGE; WEAPONS NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'F41B 1',
    d: 'Blow guns, i.e. tubes for impelling projectiles, e.g. peas or darts, by the force of the breath',
  },
  {
    c: 'F41B 3',
    d: 'Sling weapons',
  },
  {
    c: 'F41B 4',
    d: 'Friction-wheel operated launchers',
  },
  {
    c: 'F41B 5',
    d: 'Bows; Crossbows',
  },
  {
    c: 'F41B 6',
    d: 'Electromagnetic launchers',
  },
  {
    c: 'F41B 7',
    d: 'Spring guns',
  },
  {
    c: 'F41B 9',
    d: 'Liquid ejecting guns, e.g. water pistols',
  },
  {
    c: 'F41B 11',
    d: 'Compressed-gas guns, e.g. air guns; Steam guns',
  },
  {
    c: 'F41B 13',
    d: 'Thrusting-weapons',
  },
  {
    c: 'F41B 15',
    d: 'Weapons not otherwise provided for',
  },
  {
    c: 'F41C',
    d: 'SMALLARMS, e.g. PISTOLS OR RIFLES',
  },
  {
    c: 'F41C 3',
    d: 'Pistols',
  },
  {
    c: 'F41C 7',
    d: 'Shoulder-fired smallarms, e.g. rifles, carbines or shotguns',
  },
  {
    c: 'F41C 9',
    d: 'Other smallarms, e.g. hidden smallarms or smallarms specially adapted for underwater use',
  },
  {
    c: 'F41C 23',
    d: 'Details',
  },
  {
    c: 'F41C 27',
    d: 'Accessories; Details or attachments not otherwise provided for',
  },
  {
    c: 'F41C 33',
    d: 'Means for wearing or carrying smallarms',
  },
  {
    c: 'F41F',
    d: 'APPARATUS FOR LAUNCHING PROJECTILES OR MISSILES FROM BARRELS, e.g. CANNONS',
  },
  {
    c: 'F41F 1',
    d: 'Launching apparatus for projecting projectiles or missiles from barrels, e.g. cannons',
  },
  {
    c: 'F41F 3',
    d: 'Rocket or torpedo launchers',
  },
  {
    c: 'F41F 5',
    d: 'Launching-apparatus for gravity-propelled missiles or projectiles',
  },
  {
    c: 'F41F 7',
    d: 'Launching-apparatus for projecting missiles or projectiles otherwise than from barrels',
  },
  {
    c: 'F41G',
    d: 'WEAPON SIGHTS; AIMING',
  },
  {
    c: 'F41G 1',
    d: 'Sighting devices',
  },
  {
    c: 'F41G 3',
    d: 'Aiming or laying means',
  },
  {
    c: 'F41G 5',
    d: 'Elevating or traversing control systems for guns',
  },
  {
    c: 'F41G 7',
    d: 'Direction control systems for self-propelled missiles',
  },
  {
    c: 'F41G 9',
    d: 'Systems for controlling missiles or projectiles, not provided for elsewhere',
  },
  {
    c: 'F41G 11',
    d: 'Details of sighting or aiming apparatus; Accessories',
  },
  {
    c: 'F41H',
    d: 'ARMOUR; ARMOURED TURRETS; ARMOURED OR ARMED VEHICLES; MEANS OF ATTACK OR DEFENCE, e.g. CAMOUFLAGE, IN GENERAL',
  },
  {
    c: 'F41H 1',
    d: 'Personal protection-gear',
  },
  {
    c: 'F41H 3',
    d: 'Camouflage, i.e. means or methods for concealment or disguise',
  },
  {
    c: 'F41H 5',
    d: 'Armour; Armour plates',
  },
  {
    c: 'F41H 7',
    d: 'Armoured or armed vehicles',
  },
  {
    c: 'F41H 9',
    d: 'Equipment for attack or defence by spreading flame, gas, or smoke; Chemical warfare equipment',
  },
  {
    c: 'F41H 11',
    d: 'Defence installations; Defence devices',
  },
  {
    c: 'F41H 13',
    d: 'Means of attack or defence not otherwise provided for',
  },
  {
    c: 'F41J',
    d: 'TARGETS; TARGET RANGES; BULLET CATCHERS',
  },
  {
    c: 'F41J 1',
    d: 'Targets; Target stands; Target holders',
  },
  {
    c: 'F41J 2',
    d: 'Reflecting targets, e.g. radar-reflector targets; Active targets transmitting electromagnetic waves',
  },
  {
    c: 'F41J 3',
    d: 'Targets for arrows or darts, e.g. for sporting or amusement purposes',
  },
  {
    c: 'F41J 5',
    d: 'Target indicating systems; Target-hit or score detecting systems',
  },
  {
    c: 'F41J 7',
    d: 'Movable targets which are stationary when fired at',
  },
  {
    c: 'F41J 9',
    d: 'Moving targets, i.e. moving when fired at',
  },
  {
    c: 'F41J 11',
    d: 'Target ranges',
  },
  {
    c: 'F41J 13',
    d: 'Bullet catchers',
  },
  {
    c: 'F42',
    d: 'AMMUNITION; BLASTING',
  },
  {
    c: 'F42B',
    d: 'EXPLOSIVE CHARGES, e.g. FOR BLASTING; FIREWORKS; AMMUNITION',
  },
  {
    c: 'F42B 1',
    d: 'Explosive charges characterised by form or shape but not dependent on shape of container',
  },
  {
    c: 'F42B 3',
    d: 'Blasting cartridges, i.e. case and explosive',
  },
  {
    c: 'F42B 4',
    d: 'Fireworks, i.e. pyrotechnic devices for amusement, display, illumination, or signal purposes',
  },
  {
    c: 'F42B 5',
    d: 'Cartridge ammunition, e.g. separately-loaded propellant charges',
  },
  {
    c: 'F42B 6',
    d: 'Projectiles or missiles specially adapted for projection without use of explosive or combustible propellant charge, e.g. for blow guns, bows or crossbows, hand-held spring or air guns',
  },
  {
    c: 'F42B 7',
    d: 'Shotgun ammunition',
  },
  {
    c: 'F42B 8',
    d: 'Practice or training ammunition',
  },
  {
    c: 'F42B 10',
    d: 'Means for influencing, e.g. improving, the aerodynamic properties of projectiles or missiles; Arrangements on projectiles or missiles for stabilising, steering, range-reducing, range-increasing or fall-retarding',
  },
  {
    c: 'F42B 12',
    d: 'Projectiles, missiles or mines characterised by the warhead, the intended effect, or the material',
  },
  {
    c: 'F42B 14',
    d: 'Projectiles or missiles characterised by arrangements for guiding or sealing them inside barrels, or for lubricating or cleaning barrels',
  },
  {
    c: 'F42B 15',
    d: 'Self-propelled projectiles or missiles, e.g. rockets; Guided missiles',
  },
  {
    c: 'F42B 17',
    d: 'Rocket torpedoes, i.e. missiles provided with separate propulsion means for movement through air and through water',
  },
  {
    c: 'F42B 19',
    d: 'Marine torpedoes, e.g. launched by surface vessels or submarines',
  },
  {
    c: 'F42B 21',
    d: 'Depth charges',
  },
  {
    c: 'F42B 22',
    d: 'Marine mines, e.g. launched by surface vessels or submarines',
  },
  {
    c: 'F42B 23',
    d: 'Land mines',
  },
  {
    c: 'F42B 25',
    d: 'Fall bombs',
  },
  {
    c: 'F42B 27',
    d: 'Hand grenades',
  },
  {
    c: 'F42B 29',
    d: 'Noiseless, smokeless, or flashless missiles launched by their own explosive propellant',
  },
  {
    c: 'F42B 30',
    d: 'Projectiles or missiles, not otherwise provided for, characterised by the ammunition class or type, e.g. by the launching apparatus or weapon used',
  },
  {
    c: 'F42B 33',
    d: 'Manufacture of ammunition; Dismantling of ammunition; Apparatus therefor',
  },
  {
    c: 'F42B 35',
    d: 'Testing or checking of ammunition',
  },
  {
    c: 'F42B 39',
    d: 'Packaging or storage of ammunition or explosive charges; Safety features thereof; Cartridge belts or bags',
  },
  {
    c: 'F42B 99',
    d: 'n.a.',
  },
  {
    c: 'F42C',
    d: 'AMMUNITION FUZES',
  },
  {
    c: 'F42C 1',
    d: 'Impact fuzes, i.e. fuzes actuated only by ammunition impact',
  },
  {
    c: 'F42C 3',
    d: 'Fuzes actuated by exposure to a liquid, e.g. sea-water',
  },
  {
    c: 'F42C 5',
    d: 'Fuzes actuated by exposure to a predetermined ambient fluid pressure',
  },
  {
    c: 'F42C 7',
    d: 'Fuzes actuated by application of a predetermined mechanical force, e.g. tension, torsion, pressure',
  },
  {
    c: 'F42C 9',
    d: 'Time fuzes; Combined time- and percussion- or pressure-actuated fuzes; Fuzes for timed self-destruction of ammunition',
  },
  {
    c: 'F42C 11',
    d: 'Electric fuzes',
  },
  {
    c: 'F42C 13',
    d: 'Proximity fuzes; Fuzes for remote detonation',
  },
  {
    c: 'F42C 14',
    d: 'Fuzes characterised by the ammunition class or type',
  },
  {
    c: 'F42C 15',
    d: 'Arming-means in fuzes; Safety means for preventing premature detonation of fuzes or charges',
  },
  {
    c: 'F42C 17',
    d: 'Fuze-setting apparatus',
  },
  {
    c: 'F42C 19',
    d: 'Details of fuzes',
  },
  {
    c: 'F42C 21',
    d: 'Checking fuzes; Testing fuzes',
  },
  {
    c: 'F42C 99',
    d: 'n.a.',
  },
  {
    c: 'F42D',
    d: 'BLASTING',
  },
  {
    c: 'F42D 1',
    d: 'Blasting methods or apparatus, e.g. for loading or tamping',
  },
  {
    c: 'F42D 3',
    d: 'Particular applications of blasting techniques',
  },
  {
    c: 'F42D 5',
    d: 'Safety arrangements',
  },
  {
    c: 'F42D 99',
    d: 'n.a.',
  },
  {
    c: 'F99',
    d: 'n.a.',
  },
  {
    c: 'F99Z',
    d: 'n.a.',
  },
  {
    c: 'F99Z 99',
    d: 'n.a.',
  },
  {
    c: 'G',
    d: 'PHYSICS',
  },
  {
    c: 'G01',
    d: 'MEASURING; TESTING',
  },
  {
    c: 'G01B',
    d: 'MEASURING LENGTH, THICKNESS OR SIMILAR LINEAR DIMENSIONS; MEASURING ANGLES; MEASURING AREAS; MEASURING IRREGULARITIES OF SURFACES OR CONTOURS',
  },
  {
    c: 'G01B 1',
    d: 'Measuring instruments characterised by the selection of material therefor',
  },
  {
    c: 'G01B 3',
    d: 'Instruments as specified in the subgroups and characterised by the use of mechanical measuring means',
  },
  {
    c: 'G01B 5',
    d: 'Measuring arrangements characterised by the use of mechanical means',
  },
  {
    c: 'G01B 7',
    d: 'Measuring arrangements characterised by the use of electric or magnetic means',
  },
  {
    c: 'G01B 9',
    d: 'Instruments as specified in the subgroups and characterised by the use of optical measuring means',
  },
  {
    c: 'G01B 11',
    d: 'Measuring arrangements characterised by the use of optical means',
  },
  {
    c: 'G01B 13',
    d: 'Measuring arrangements characterised by the use of fluids',
  },
  {
    c: 'G01B 15',
    d: 'Measuring arrangements characterised by the use of wave or particle radiation',
  },
  {
    c: 'G01B 17',
    d: 'Measuring arrangements characterised by the use of infrasonic, sonic, or ultrasonic vibrations',
  },
  {
    c: 'G01B 21',
    d: 'Measuring arrangements or details thereof in so far as they are not adapted to particular types of measuring means of the other groups of this subclass',
  },
  {
    c: 'G01C',
    d: 'MEASURING DISTANCES, LEVELS OR BEARINGS; SURVEYING; NAVIGATION; GYROSCOPIC INSTRUMENTS; PHOTOGRAMMETRY OR VIDEOGRAMMETRY',
  },
  {
    c: 'G01C 1',
    d: 'Measuring angles',
  },
  {
    c: 'G01C 3',
    d: 'Measuring distances in line of sight; Optical rangefinders',
  },
  {
    c: 'G01C 5',
    d: "Measuring height; Measuring distances transverse to line of sight; Levelling between separated points; Surveyors' levels",
  },
  {
    c: 'G01C 7',
    d: 'Tracing profiles',
  },
  {
    c: 'G01C 9',
    d: 'Measuring inclination, e.g. by clinometers, by levels',
  },
  {
    c: 'G01C 11',
    d: 'Photogrammetry or videogrammetry, e.g. stereogrammetry; Photographic surveying',
  },
  {
    c: 'G01C 13',
    d: 'Surveying specially adapted to open water, e.g. sea, lake, river or canal',
  },
  {
    c: 'G01C 15',
    d: 'Surveying instruments or accessories not provided for in groups G01C0001000000-G01C0013000000',
  },
  {
    c: 'G01C 17',
    d: 'Compasses; Devices for ascertaining true or magnetic north for navigation or surveying purposes',
  },
  {
    c: 'G01C 19',
    d: 'Gyroscopes; Turn-sensitive devices using vibrating masses; Turn-sensitive devices without moving masses; Measuring angular rate using gyroscopic effects',
  },
  {
    c: 'G01C 21',
    d: 'Navigation; Navigational instruments not provided for in groups G01C0001000000-G01C0019000000',
  },
  {
    c: 'G01C 22',
    d: 'Measuring distance traversed on the ground by vehicles, persons, animals or other moving solid bodies, e.g. using odometers or using pedometers',
  },
  {
    c: 'G01C 23',
    d: 'Combined instruments indicating more than one navigational value, e.g. for aircraft; Combined measuring devices for measuring two or more variables of movement, e.g. distance, speed, acceleration',
  },
  {
    c: 'G01C 25',
    d: 'Manufacturing, calibrating, cleaning, or repairing instruments or devices referred to in the other groups of this subclass',
  },
  {
    c: 'G01D',
    d: 'MEASURING NOT SPECIALLY ADAPTED FOR A SPECIFIC VARIABLE; ARRANGEMENTS FOR MEASURING TWO OR MORE VARIABLES NOT COVERED BY A SINGLE OTHER SUBCLASS; TARIFF METERING APPARATUS; TRANSFERRING OR TRANSDUCING ARRANGEMENTS NOT SPECIALLY ADAPTED FOR A SPECIFIC VARIABLE; MEASURING OR TESTING NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'G01D 1',
    d: 'Measuring arrangements giving results other than momentary value of variable, of general application',
  },
  {
    c: 'G01D 3',
    d: 'Measuring arrangements with provision for the special purposes referred to in the subgroups of this group',
  },
  {
    c: 'G01D 4',
    d: 'Tariff metering apparatus',
  },
  {
    c: 'G01D 5',
    d: 'Mechanical means for transferring the output of a sensing member; Means for converting the output of a sensing member to another variable where the form or nature of the sensing member does not constrain the means for converting; Transducers not specially adapted for a specific variable',
  },
  {
    c: 'G01D 7',
    d: 'Indicating measured values',
  },
  {
    c: 'G01D 9',
    d: 'Recording measured values',
  },
  {
    c: 'G01D 11',
    d: 'Component parts of measuring arrangements not specially adapted for a specific variable',
  },
  {
    c: 'G01D 13',
    d: 'Component parts of indicators for measuring arrangements not specially adapted for a specific variable',
  },
  {
    c: 'G01D 15',
    d: 'Component parts of recorders for measuring arrangements not specially adapted for a specific variable',
  },
  {
    c: 'G01D 18',
    d: 'Testing or calibrating apparatus or arrangements provided for in groups G01D0001000000-G01D0015000000',
  },
  {
    c: 'G01D 21',
    d: 'Measuring or testing not otherwise provided for',
  },
  {
    c: 'G01F',
    d: 'MEASURING VOLUME, VOLUME FLOW, MASS FLOW, OR LIQUID LEVEL; METERING BY VOLUME',
  },
  {
    c: 'G01F 1',
    d: 'Measuring volume flow',
  },
  {
    c: 'G01F 3',
    d: 'Measuring the volume flow of fluids or fluent solid material wherein the fluid passes through the meter in successive and more or less isolated quantities, the meter being driven by the flow',
  },
  {
    c: 'G01F 5',
    d: 'Measuring a proportion of the volume flow',
  },
  {
    c: 'G01F 7',
    d: 'Volume-flow measuring devices with two or more measuring ranges; Compound meters',
  },
  {
    c: 'G01F 9',
    d: 'Measuring volume flow relative to another variable, e.g. of liquid fuel for an engine',
  },
  {
    c: 'G01F 11',
    d: 'Metering by volume',
  },
  {
    c: 'G01F 13',
    d: 'Apparatus for measuring by volume and delivering fluids or fluent solid materials, not provided for in the preceding groups',
  },
  {
    c: 'G01F 15',
    d: 'Details of, or accessories for, apparatus of groups G01F0001000000-G01F0013000000 insofar as such details or appliances are not adapted to particular types of such apparatus',
  },
  {
    c: 'G01F 17',
    d: 'Measuring volume',
  },
  {
    c: 'G01F 19',
    d: 'Calibrated capacity measures for fluids or fluent solid material, e.g. measuring cups',
  },
  {
    c: 'G01F 22',
    d: 'Methods or apparatus for measuring volume of fluids or fluent solid material, not otherwise provided for',
  },
  {
    c: 'G01F 23',
    d: 'Level indicators',
  },
  {
    c: 'G01F 25',
    d: 'Testing or calibrating of apparatus for measuring volume, volume flow, or liquid level, or for metering by volume',
  },
  {
    c: 'G01G',
    d: 'WEIGHING',
  },
  {
    c: 'G01G 1',
    d: 'Weighing apparatus involving the use of a counterweight or other counterbalancing mass',
  },
  {
    c: 'G01G 3',
    d: 'Weighing apparatus characterised by the use of elastically-deformable members, e.g. spring balances',
  },
  {
    c: 'G01G 5',
    d: 'Weighing apparatus wherein the balancing is effected by fluid action',
  },
  {
    c: 'G01G 7',
    d: 'Weighing apparatus wherein the balancing is effected by magnetic, electromagnetic, or electrostatic action, or by means not provided for in groups G01G0001000000-G01G0005000000',
  },
  {
    c: 'G01G 9',
    d: 'Methods of, or apparatus for, the determination of weight, not provided for in groups G01G0001000000-G01G0007000000',
  },
  {
    c: 'G01G 11',
    d: 'Apparatus for weighing a continuous stream of material during flow; Conveyor-belt weighers',
  },
  {
    c: 'G01G 13',
    d: 'Weighing apparatus with automatic feed or discharge for weighing-out batches of material',
  },
  {
    c: 'G01G 15',
    d: 'Arrangements for check-weighing of materials dispensed into removable containers',
  },
  {
    c: 'G01G 17',
    d: 'Apparatus for, or methods of, weighing material of special form or property',
  },
  {
    c: 'G01G 19',
    d: 'Weighing apparatus or methods adapted for special purposes not provided for in groups G01G0011000000-G01G0017000000',
  },
  {
    c: 'G01G 21',
    d: 'Details of weighing apparatus',
  },
  {
    c: 'G01G 23',
    d: 'Auxiliary devices for weighing apparatus',
  },
  {
    c: 'G01H',
    d: 'MEASUREMENT OF MECHANICAL VIBRATIONS OR ULTRASONIC, SONIC OR INFRASONIC WAVES',
  },
  {
    c: 'G01H 1',
    d: 'Measuring vibrations in solids by using direct conduction to the detector',
  },
  {
    c: 'G01H 3',
    d: 'Measuring vibrations by using a detector in a fluid',
  },
  {
    c: 'G01H 5',
    d: 'Measuring propagation velocity of ultrasonic, sonic or infrasonic waves',
  },
  {
    c: 'G01H 7',
    d: 'Measuring reverberation time',
  },
  {
    c: 'G01H 9',
    d: 'Measuring mechanical vibrations or ultrasonic, sonic or infrasonic waves by using radiation-sensitive means, e.g. optical means',
  },
  {
    c: 'G01H 11',
    d: 'Measuring mechanical vibrations or ultrasonic, sonic or infrasonic waves by detecting changes in electric or magnetic properties',
  },
  {
    c: 'G01H 13',
    d: 'Measuring resonant frequency',
  },
  {
    c: 'G01H 15',
    d: 'Measuring mechanical or acoustic impedance',
  },
  {
    c: 'G01H 17',
    d: 'Measuring mechanical vibrations or ultrasonic, sonic or infrasonic waves, not provided for in the other groups of this subclass',
  },
  {
    c: 'G01J',
    d: 'MEASUREMENT OF INTENSITY, VELOCITY, SPECTRAL CONTENT, POLARISATION, PHASE OR PULSE CHARACTERISTICS OF INFRA-RED, VISIBLE OR ULTRA-VIOLET LIGHT; COLORIMETRY; RADIATION PYROMETRY',
  },
  {
    c: 'G01J 1',
    d: 'Photometry, e.g. photographic exposure meter',
  },
  {
    c: 'G01J 3',
    d: 'Spectrometry; Spectrophotometry; Monochromators; Measuring colours',
  },
  {
    c: 'G01J 4',
    d: 'Measuring polarisation of light',
  },
  {
    c: 'G01J 5',
    d: 'Radiation pyrometry',
  },
  {
    c: 'G01J 7',
    d: 'Measuring velocity of light',
  },
  {
    c: 'G01J 9',
    d: 'Measuring optical phase difference; Determining degree of coherence; Measuring optical wavelength',
  },
  {
    c: 'G01J 11',
    d: 'Measuring the characteristics of individual optical pulses or of optical pulse trains',
  },
  {
    c: 'G01K',
    d: 'MEASURING TEMPERATURE; MEASURING QUANTITY OF HEAT; THERMALLY-SENSITIVE ELEMENTS NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'G01K 1',
    d: 'Details of thermometers not specially adapted for particular types of thermometer',
  },
  {
    c: 'G01K 3',
    d: 'Thermometers giving results other than momentary value of temperature',
  },
  {
    c: 'G01K 5',
    d: 'Measuring temperature based on the expansion or contraction of a material',
  },
  {
    c: 'G01K 7',
    d: 'Measuring temperature based on the use of electric or magnetic elements directly sensitive to heat',
  },
  {
    c: 'G01K 9',
    d: 'Measuring temperature based on movements caused by redistribution of weight, e.g. tilting thermometer',
  },
  {
    c: 'G01K 11',
    d: 'Measuring temperature based on physical or chemical changes not covered by group G01K0003000000, G01K0005000000, G01K0007000000, or G01K0009000000',
  },
  {
    c: 'G01K 13',
    d: 'Adaptations of thermometers for specific purposes',
  },
  {
    c: 'G01K 15',
    d: 'Testing or calibrating of thermometers',
  },
  {
    c: 'G01K 17',
    d: 'Measuring quantity of heat',
  },
  {
    c: 'G01K 19',
    d: 'Testing or calibrating calorimeters',
  },
  {
    c: 'G01L',
    d: 'MEASURING FORCE, STRESS, TORQUE, WORK, MECHANICAL POWER, MECHANICAL EFFICIENCY, OR FLUID PRESSURE',
  },
  {
    c: 'G01L 1',
    d: 'Measuring force or stress, in general',
  },
  {
    c: 'G01L 3',
    d: 'Measuring torque, work, mechanical power, or mechanical efficiency, in general',
  },
  {
    c: 'G01L 5',
    d: 'Apparatus for, or methods of, measuring force, e.g. due to impact, work, mechanical power, or torque, adapted for special purposes',
  },
  {
    c: 'G01L 7',
    d: 'Measuring fluid pressure',
  },
  {
    c: 'G01L 9',
    d: 'Measuring steady or quasi-steady pressure of a fluid or a fluent solid material by electric or magnetic pressure-sensitive elements; Transmitting or indicating the displacement of mechanical pressure-sensitive elements, used to measure the steady or quasi-steady pressure of a fluid or fluent solid material, by electric or magnetic means',
  },
  {
    c: 'G01L 11',
    d: 'Measuring steady or quasi-steady pressure of a fluid or a fluent solid material by means not provided for in group G01L0007000000 or G01L0009000000',
  },
  {
    c: 'G01L 13',
    d: 'Devices or apparatus for measuring differences of two or more fluid pressure values',
  },
  {
    c: 'G01L 15',
    d: 'Devices or apparatus for measuring two or more fluid pressure values simultaneously',
  },
  {
    c: 'G01L 17',
    d: 'Devices or apparatus for measuring tyre pressure or the pressure in other inflated bodies',
  },
  {
    c: 'G01L 19',
    d: 'Details of, or accessories for, apparatus for measuring steady or quasi-steady pressure of a fluent medium insofar as such details or accessories are not special to particular types of pressure gauges',
  },
  {
    c: 'G01L 21',
    d: 'Vacuum gauges',
  },
  {
    c: 'G01L 23',
    d: 'Devices or apparatus for measuring or indicating or recording rapid changes, such as oscillations, in the pressure of steam, gas, or liquid; Indicators for determining work or energy of steam, internal-combustion, or other fluid-pressure engines from the condition of the working fluid',
  },
  {
    c: 'G01L 25',
    d: 'Testing or calibrating of apparatus for measuring force, torque, work, mechanical power, or mechanical efficiency',
  },
  {
    c: 'G01L 27',
    d: 'Testing or calibrating of apparatus for measuring fluid pressure',
  },
  {
    c: 'G01M',
    d: 'TESTING STATIC OR DYNAMIC BALANCE OF MACHINES OR STRUCTURES; TESTING OF STRUCTURES OR APPARATUS, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'G01M 1',
    d: 'Testing static or dynamic balance of machines or structures',
  },
  {
    c: 'G01M 3',
    d: 'Investigating fluid tightness of structures',
  },
  {
    c: 'G01M 5',
    d: 'Investigating the elasticity of structures, e.g. deflection of bridges or aircraft wings',
  },
  {
    c: 'G01M 7',
    d: 'Vibration-testing of structures; Shock-testing of structures',
  },
  {
    c: 'G01M 9',
    d: 'Aerodynamic testing; Arrangements in or on wind tunnels',
  },
  {
    c: 'G01M 10',
    d: 'Hydrodynamic testing; Arrangements in or on ship-testing tanks or water tunnels',
  },
  {
    c: 'G01M 11',
    d: 'Testing of optical apparatus; Testing structures by optical methods not otherwise provided for',
  },
  {
    c: 'G01M 13',
    d: 'Testing of machine parts',
  },
  {
    c: 'G01M 15',
    d: 'Testing of engines',
  },
  {
    c: 'G01M 17',
    d: 'Testing of vehicles',
  },
  {
    c: 'G01M 99',
    d: 'n.a.',
  },
  {
    c: 'G01N',
    d: 'INVESTIGATING OR ANALYSING MATERIALS BY DETERMINING THEIR CHEMICAL OR PHYSICAL PROPERTIES',
  },
  {
    c: 'G01N 1',
    d: 'Sampling; Preparing specimens for investigation',
  },
  {
    c: 'G01N 3',
    d: 'Investigating strength properties of solid materials by application of mechanical stress',
  },
  {
    c: 'G01N 5',
    d: 'Analysing materials by weighing, e.g. weighing small particles separated from a gas or liquid',
  },
  {
    c: 'G01N 7',
    d: 'Analysing materials by measuring the pressure or volume of a gas or vapour',
  },
  {
    c: 'G01N 9',
    d: 'Investigating density or specific gravity of materials; Analysing materials by determining density or specific gravity',
  },
  {
    c: 'G01N 11',
    d: 'Investigating flow properties of materials, e.g. viscosity, plasticity; Analysing materials by determining flow properties',
  },
  {
    c: 'G01N 13',
    d: 'Investigating surface or boundary effects, e.g. wetting power; Investigating diffusion effects; Analysing materials by determining surface, boundary, or diffusion effects',
  },
  {
    c: 'G01N 15',
    d: 'Investigating characteristics of particles; Investigating permeability, pore-volume or surface-area of porous materials',
  },
  {
    c: 'G01N 17',
    d: 'Investigating resistance of materials to the weather, to corrosion, or to light',
  },
  {
    c: 'G01N 19',
    d: 'Investigating materials by mechanical methods',
  },
  {
    c: 'G01N 21',
    d: 'Investigating or analysing materials by the use of optical means, i.e. using infra-red, visible or ultra-violet light',
  },
  {
    c: 'G01N 22',
    d: 'Investigating or analysing materials by the use of microwaves',
  },
  {
    c: 'G01N 23',
    d: 'Investigating or analysing materials by the use of wave or particle radiation, e.g. X-rays or neutrons, not covered by groups G01N0003000000-G01N0017000000, G01N0021000000 or G01N0022000000',
  },
  {
    c: 'G01N 24',
    d: 'Investigating or analysing materials by the use of nuclear magnetic resonance, electron paramagnetic resonance or other spin effects',
  },
  {
    c: 'G01N 25',
    d: 'Investigating or analysing materials by the use of thermal means',
  },
  {
    c: 'G01N 27',
    d: 'Investigating or analysing materials by the use of electric, electro-chemical, or magnetic means',
  },
  {
    c: 'G01N 29',
    d: 'Investigating or analysing materials by the use of ultrasonic, sonic or infrasonic waves; Visualisation of the interior of objects by transmitting ultrasonic or sonic waves through the object',
  },
  {
    c: 'G01N 30',
    d: 'Investigating or analysing materials by separation into components using adsorption, absorption or similar phenomena or using ion-exchange, e.g. chromatography',
  },
  {
    c: 'G01N 31',
    d: 'Investigating or analysing non-biological materials by the use of the chemical methods specified in the subgroups; Apparatus specially adapted for such methods',
  },
  {
    c: 'G01N 33',
    d: 'Investigating or analysing materials by specific methods not covered by groups G01N0001000000-G01N0031000000',
  },
  {
    c: 'G01N 35',
    d: 'Automatic analysis not limited to methods or materials provided for in any single one of groups G01N0001000000-G01N0033000000; Handling materials therefor',
  },
  {
    c: 'G01N 37',
    d: 'Details not covered by any other group of this subclass',
  },
  {
    c: 'G01P',
    d: 'MEASURING LINEAR OR ANGULAR SPEED, ACCELERATION, DECELERATION OR SHOCK; INDICATING PRESENCE OR ABSENCE OF MOVEMENT;  INDICATING DIRECTION OF MOVEMENT ',
  },
  {
    c: 'G01P 1',
    d: 'Details of instruments',
  },
  {
    c: 'G01P 3',
    d: 'Measuring linear or angular speed; Measuring differences of linear or angular speeds',
  },
  {
    c: 'G01P 5',
    d: 'Measuring speed of fluids, e.g. of air stream; Measuring speed of bodies relative to fluids, e.g. of ship, of aircraft',
  },
  {
    c: 'G01P 7',
    d: 'Measuring speed by integrating acceleration',
  },
  {
    c: 'G01P 11',
    d: 'Measuring average value of speed',
  },
  {
    c: 'G01P 13',
    d: 'Indicating or recording presence or absence of movement; Indicating or recording of direction of movement',
  },
  {
    c: 'G01P 15',
    d: 'Measuring acceleration; Measuring deceleration; Measuring shock, i.e. sudden change of acceleration',
  },
  {
    c: 'G01P 21',
    d: 'Testing or calibrating of apparatus or devices covered by the other groups of this subclass',
  },
  {
    c: 'G01Q',
    d: 'SCANNING-PROBE TECHNIQUES OR APPARATUS; APPLICATIONS OF SCANNING-PROBE TECHNIQUES, e.g. SCANNING-PROBE MICROSCOPY [SPM]',
  },
  {
    c: 'G01Q 10',
    d: 'Scanning or positioning arrangements, i.e. arrangements for actively controlling the movement or position of the probe',
  },
  {
    c: 'G01Q 20',
    d: 'Monitoring the movement or position of the probe',
  },
  {
    c: 'G01Q 30',
    d: 'Auxiliary means serving to assist or improve the scanning probe techniques or apparatus, e.g. display or data processing devices',
  },
  {
    c: 'G01Q 40',
    d: 'Calibration, e.g. of probes',
  },
  {
    c: 'G01Q 60',
    d: 'Particular types of SPM [Scanning-Probe Microscopy] or apparatus therefor; Essential components thereof',
  },
  {
    c: 'G01Q 70',
    d: 'General aspects of SPM probes, their manufacture or their related instrumentation, insofar as they are not specially adapted to a single SPM technique covered by group G01Q0060000000',
  },
  {
    c: 'G01Q 80',
    d: 'Applications, other than SPM, of scanning-probe techniques',
  },
  {
    c: 'G01Q 90',
    d: 'Scanning-probe techniques or apparatus not otherwise provided for',
  },
  {
    c: 'G01R',
    d: 'MEASURING ELECTRIC VARIABLES; MEASURING MAGNETIC VARIABLES',
  },
  {
    c: 'G01R 1',
    d: 'Details of instruments or arrangements of the types covered by groups G01R0005000000-G01R0013000000 or G01R0031000000',
  },
  {
    c: 'G01R 3',
    d: 'Apparatus or processes specially adapted for the manufacture of measuring instruments',
  },
  {
    c: 'G01R 5',
    d: 'Instruments for converting a single current or a single voltage into a mechanical displacement',
  },
  {
    c: 'G01R 7',
    d: 'Instruments capable of converting two or more currents or voltages into a single mechanical displacement',
  },
  {
    c: 'G01R 9',
    d: 'Instruments employing mechanical resonance',
  },
  {
    c: 'G01R 11',
    d: 'Electromechanical arrangements for measuring time integral of electric power or current, e.g. of consumption',
  },
  {
    c: 'G01R 13',
    d: 'Arrangements for displaying electric variables or waveforms',
  },
  {
    c: 'G01R 15',
    d: 'Details of measuring arrangements of the types provided for in groups G01R0017000000-G01R0029000000, G01R0033000000-G01R0033260000 or G01R0035000000',
  },
  {
    c: 'G01R 17',
    d: 'Measuring arrangements involving comparison with a reference value, e.g. bridge',
  },
  {
    c: 'G01R 19',
    d: 'Arrangements for measuring currents or voltages or for indicating presence or sign thereof',
  },
  {
    c: 'G01R 21',
    d: 'Arrangements for measuring electric power or power factor',
  },
  {
    c: 'G01R 22',
    d: 'Arrangements for measuring time integral of electric power or current, e.g. electricity meters',
  },
  {
    c: 'G01R 23',
    d: 'Arrangements for measuring frequencies; Arrangements for analysing frequency spectra',
  },
  {
    c: 'G01R 25',
    d: 'Arrangements for measuring phase angle between a voltage and a current or between voltages or currents',
  },
  {
    c: 'G01R 27',
    d: 'Arrangements for measuring resistance, reactance, impedance, or electric characteristics derived therefrom',
  },
  {
    c: 'G01R 29',
    d: 'Arrangements for measuring or indicating electric quantities not covered by groups G01R0019000000-G01R0027000000',
  },
  {
    c: 'G01R 31',
    d: 'Arrangements for testing electric properties; Arrangements for locating electric faults; Arrangements for electrical testing characterised by what is being tested not provided for elsewhere',
  },
  {
    c: 'G01R 33',
    d: 'Arrangements or instruments for measuring magnetic variables',
  },
  {
    c: 'G01R 35',
    d: 'Testing or calibrating of apparatus covered by the other groups of this subclass',
  },
  {
    c: 'G01S',
    d: 'RADIO DIRECTION-FINDING; RADIO NAVIGATION; DETERMINING DISTANCE OR VELOCITY BY USE OF RADIO WAVES; LOCATING OR PRESENCE-DETECTING BY USE OF THE REFLECTION OR RERADIATION OF RADIO WAVES; ANALOGOUS ARRANGEMENTS USING OTHER WAVES',
  },
  {
    c: 'G01S 1',
    d: 'Beacons or beacon systems transmitting signals having a characteristic or characteristics capable of being detected by non-directional receivers and defining directions, positions, or position lines fixed relatively to the beacon transmitters; Receivers co-operating therewith',
  },
  {
    c: 'G01S 3',
    d: 'Direction-finders for determining the direction from which infrasonic, sonic, ultrasonic, or electromagnetic waves, or particle emission, not having a directional significance, are being received',
  },
  {
    c: 'G01S 5',
    d: 'Position-fixing by co-ordinating two or more direction or position-line determinations; Position-fixing by co-ordinating two or more distance determinations',
  },
  {
    c: 'G01S 7',
    d: 'Details of systems according to groups G01S0013000000, G01S0015000000, G01S0017000000',
  },
  {
    c: 'G01S 11',
    d: 'Systems for determining distance or velocity not using reflection or reradiation',
  },
  {
    c: 'G01S 13',
    d: 'Systems using the reflection or reradiation of radio waves, e.g. radar systems; Analogous systems using reflection or reradiation of waves whose nature or wavelength is irrelevant or unspecified',
  },
  {
    c: 'G01S 15',
    d: 'Systems using the reflection or reradiation of acoustic waves, e.g. sonar systems',
  },
  {
    c: 'G01S 17',
    d: 'Systems using the reflection or reradiation of electromagnetic waves other than radio waves, e.g. lidar systems',
  },
  {
    c: 'G01S 19',
    d: 'Satellite radio beacon positioning systems; Determining position, velocity or attitude using signals transmitted by such systems',
  },
  {
    c: 'G01T',
    d: 'MEASUREMENT OF NUCLEAR OR X-RADIATION',
  },
  {
    c: 'G01T 1',
    d: 'Measuring X-radiation, gamma radiation, corpuscular radiation, or cosmic radiation',
  },
  {
    c: 'G01T 3',
    d: 'Measuring neutron radiation',
  },
  {
    c: 'G01T 5',
    d: 'Recording of movements or tracks of particles',
  },
  {
    c: 'G01T 7',
    d: 'Details of radiation-measuring instruments',
  },
  {
    c: 'G01V',
    d: 'GEOPHYSICS; GRAVITATIONAL MEASUREMENTS; DETECTING MASSES OR OBJECTS; TAGS',
  },
  {
    c: 'G01V 1',
    d: 'Seismology; Seismic or acoustic prospecting or detecting',
  },
  {
    c: 'G01V 3',
    d: 'Electric or magnetic prospecting or detecting; Measuring magnetic field characteristics of the earth, e.g. declination or deviation',
  },
  {
    c: 'G01V 5',
    d: 'Prospecting or detecting by the use of nuclear radiation, e.g. of natural or induced radioactivity',
  },
  {
    c: 'G01V 7',
    d: 'Measuring gravitational fields or waves; Gravimetric prospecting or detecting',
  },
  {
    c: 'G01V 8',
    d: 'Prospecting or detecting by optical means',
  },
  {
    c: 'G01V 9',
    d: 'Prospecting or detecting by methods not provided for in groups G01V0001000000-G01V0008000000',
  },
  {
    c: 'G01V 11',
    d: 'Prospecting or detecting by methods combining techniques covered by two or more of main groups G01V0001000000-G01V0009000000',
  },
  {
    c: 'G01V 13',
    d: 'Manufacturing, calibrating, cleaning, or repairing instruments or devices covered by groups G01V0001000000-G01V0011000000',
  },
  {
    c: 'G01V 15',
    d: 'Tags attached to, or associated with, an object, in order to enable detection of the object',
  },
  {
    c: 'G01V 99',
    d: 'n.a.',
  },
  {
    c: 'G01W',
    d: 'METEOROLOGY',
  },
  {
    c: 'G01W 1',
    d: 'Meteorology',
  },
  {
    c: 'G02',
    d: 'OPTICS',
  },
  {
    c: 'G02B',
    d: 'OPTICAL ELEMENTS, SYSTEMS, OR APPARATUS',
  },
  {
    c: 'G02B 1',
    d: 'Optical elements characterised by the material of which they are made',
  },
  {
    c: 'G02B 3',
    d: 'Simple or compound lenses',
  },
  {
    c: 'G02B 5',
    d: 'Optical elements other than lenses',
  },
  {
    c: 'G02B 6',
    d: 'Light guides; Structural details of arrangements comprising light guides and other optical elements, e.g. couplings',
  },
  {
    c: 'G02B 7',
    d: 'Mountings, adjusting means, or light-tight connections, for optical elements',
  },
  {
    c: 'G02B 9',
    d: 'Optical objectives characterised both by the number of the components and their arrangements according to their sign, i.e. + or –',
  },
  {
    c: 'G02B 11',
    d: 'Optical objectives characterised by the total number of simple and compound lenses forming the objective and their arrangement',
  },
  {
    c: 'G02B 13',
    d: 'Optical objectives specially designed for the purposes specified below',
  },
  {
    c: 'G02B 15',
    d: 'Optical objectives with means for varying the magnification',
  },
  {
    c: 'G02B 17',
    d: 'Systems with reflecting surfaces, with or without refracting elements',
  },
  {
    c: 'G02B 19',
    d: 'Condensers',
  },
  {
    c: 'G02B 21',
    d: 'Microscopes',
  },
  {
    c: 'G02B 23',
    d: 'Telescopes, e.g. binoculars',
  },
  {
    c: 'G02B 25',
    d: 'Eyepieces; Magnifying glasses',
  },
  {
    c: 'G02B 26',
    d: 'Optical devices or arrangements using movable or deformable optical elements for controlling the intensity, colour, phase, polarisation or direction of light, e.g. switching, gating, modulating',
  },
  {
    c: 'G02B 27',
    d: 'Other optical systems; Other optical apparatus',
  },
  {
    c: 'G02C',
    d: 'SPECTACLES; SUNGLASSES OR GOGGLES INSOFAR AS THEY HAVE THE SAME FEATURES AS SPECTACLES; CONTACT LENSES',
  },
  {
    c: 'G02C 1',
    d: 'Assemblies of lenses with bridges or browbars',
  },
  {
    c: 'G02C 3',
    d: 'Special supporting arrangement for lens assemblies or monocles',
  },
  {
    c: 'G02C 5',
    d: 'Constructions of non-optical parts',
  },
  {
    c: 'G02C 7',
    d: 'Optical parts',
  },
  {
    c: 'G02C 9',
    d: 'Attaching auxiliary optical parts',
  },
  {
    c: 'G02C 11',
    d: 'Non-optical adjuncts; Attachment thereof',
  },
  {
    c: 'G02C 13',
    d: 'Assembling; Repairing; Cleaning',
  },
  {
    c: 'G02F',
    d: 'DEVICES OR ARRANGEMENTS, THE OPTICAL OPERATION OF WHICH IS MODIFIED BY CHANGING THE OPTICAL PROPERTIES OF THE MEDIUM OF THE DEVICES OR ARRANGEMENTS FOR THE CONTROL OF THE INTENSITY, COLOUR, PHASE, POLARISATION OR DIRECTION OF LIGHT, e.g. SWITCHING, GATING, MODULATING OR DEMODULATING; TECHNIQUES OR PROCEDURES FOR THE OPERATION THEREOF; FREQUENCY-CHANGING; NON-LINEAR OPTICS; OPTICAL LOGIC ELEMENTS; OPTICAL ANALOGUE/DIGITAL CONVERTERS',
  },
  {
    c: 'G02F 1',
    d: 'Devices or arrangements for the control of the intensity, colour, phase, polarisation or direction of light arriving from an independent light source, e.g. switching, gating or modulating; Non-linear optics',
  },
  {
    c: 'G02F 2',
    d: 'Demodulating light; Transferring the modulation of modulated light; Frequency-changing of light',
  },
  {
    c: 'G02F 3',
    d: 'Optical logic elements; Optical bistable devices',
  },
  {
    c: 'G02F 7',
    d: 'Optical analogue/digital converters',
  },
  {
    c: 'G03',
    d: 'PHOTOGRAPHY; CINEMATOGRAPHY; ANALOGOUS TECHNIQUES USING WAVES OTHER THAN OPTICAL WAVES; ELECTROGRAPHY; HOLOGRAPHY',
  },
  {
    c: 'G03B',
    d: 'APPARATUS OR ARRANGEMENTS FOR TAKING PHOTOGRAPHS OR FOR PROJECTING OR VIEWING THEM; APPARATUS OR ARRANGEMENTS EMPLOYING ANALOGOUS TECHNIQUES USING WAVES OTHER THAN OPTICAL WAVES; ACCESSORIES THEREFOR',
  },
  {
    c: 'G03B 1',
    d: 'Details common to at least two of the following types of apparatus: cameras, projectors, printers',
  },
  {
    c: 'G03B 3',
    d: 'Focusing arrangements of general interest for cameras, projectors or printers',
  },
  {
    c: 'G03B 5',
    d: 'Adjustment of optical system relative to image or object surface other than for focusing of general interest for cameras, projectors or printers',
  },
  {
    c: 'G03B 7',
    d: 'Details common to cameras',
  },
  {
    c: 'G03B 9',
    d: 'Exposure-making shutters; Diaphragms',
  },
  {
    c: 'G03B 11',
    d: 'Filters or other obturators specially adapted for photographic purposes',
  },
  {
    c: 'G03B 13',
    d: 'Viewfinders; Focusing aids for cameras; Means for focusing for cameras; Autofocus systems for cameras',
  },
  {
    c: 'G03B 15',
    d: 'Special procedures for taking photographs; Apparatus therefor',
  },
  {
    c: 'G03B 17',
    d: 'Details of cameras or camera bodies; Accessories therefor',
  },
  {
    c: 'G03B 19',
    d: 'Cameras',
  },
  {
    c: 'G03B 21',
    d: 'Projectors or projection-type viewers; Accessories therefor',
  },
  {
    c: 'G03B 23',
    d: 'Devices for changing pictures in viewing apparatus or projectors',
  },
  {
    c: 'G03B 25',
    d: 'Viewers, other than projection viewers, giving motion-picture effects by persistence of vision, e.g. zoetrope',
  },
  {
    c: 'G03B 27',
    d: 'Photographic printing apparatus',
  },
  {
    c: 'G03B 29',
    d: 'Combinations of cameras, projectors or photographic printing apparatus with non-photographic non-optical apparatus, e.g. clocks or weapons; Cameras having the shape of other objects',
  },
  {
    c: 'G03B 31',
    d: 'Special techniques',
  },
  {
    c: 'G03B 33',
    d: 'Colour photography, other than mere exposure or projection of a colour film',
  },
  {
    c: 'G03B 35',
    d: 'Stereoscopic photography',
  },
  {
    c: 'G03B 37',
    d: 'Panoramic or wide-screen photography; Photographing extended surfaces, e.g. for surveying; Photographing internal surfaces, e.g. of pipe',
  },
  {
    c: 'G03B 39',
    d: 'High-speed photography',
  },
  {
    c: 'G03B 41',
    d: 'Special photographic techniques not covered by groups G03B0031000000-G03B0039000000; Apparatus therefor',
  },
  {
    c: 'G03B 42',
    d: 'Obtaining records using waves other than optical waves; Visualisation of such records by using optical means',
  },
  {
    c: 'G03B 43',
    d: 'Testing correct operation of photographic apparatus or parts thereof',
  },
  {
    c: 'G03C',
    d: 'PHOTOSENSITIVE MATERIALS FOR PHOTOGRAPHIC PURPOSES; PHOTOGRAPHIC PROCESSES, e.g. CINE, X-RAY, COLOUR OR STEREO-PHOTOGRAPHIC PROCESSES; AUXILIARY PROCESSES IN PHOTOGRAPHY',
  },
  {
    c: 'G03C 1',
    d: 'Photosensitive materials',
  },
  {
    c: 'G03C 3',
    d: 'Packages of films for inserting into cameras, e.g. roll-films or film-packs; Wrapping materials for light-sensitive plates, films, or papers, e.g. materials characterised by the use of special dyes, printing inks or adhesives',
  },
  {
    c: 'G03C 5',
    d: 'Photographic processes or agents therefor; Regeneration of such processing agents',
  },
  {
    c: 'G03C 7',
    d: 'Multicolour photographic processes or agents therefor; Regeneration of such processing agents; Photosensitive materials for multicolour processes',
  },
  {
    c: 'G03C 8',
    d: 'Diffusion transfer processes or agents therefor; Photosensitive materials for such processes',
  },
  {
    c: 'G03C 9',
    d: 'Stereo-photographic or similar processes',
  },
  {
    c: 'G03C 11',
    d: 'Auxiliary processes in photography',
  },
  {
    c: 'G03D',
    d: 'APPARATUS FOR PROCESSING EXPOSED PHOTOGRAPHIC MATERIALS; ACCESSORIES THEREFOR',
  },
  {
    c: 'G03D 3',
    d: 'Liquid processing apparatus involving immersion; Washing apparatus involving immersion',
  },
  {
    c: 'G03D 5',
    d: 'Liquid processing apparatus in which no immersion is effected; Washing apparatus in which no immersion is effected',
  },
  {
    c: 'G03D 7',
    d: 'Gas processing apparatus',
  },
  {
    c: 'G03D 9',
    d: 'Diffusion development apparatus',
  },
  {
    c: 'G03D 11',
    d: 'Reversal processing apparatus',
  },
  {
    c: 'G03D 13',
    d: 'Processing apparatus or accessories therefor, not covered by groups G03D0003000000-G03D0011000000',
  },
  {
    c: 'G03D 15',
    d: 'Apparatus for treating processed material',
  },
  {
    c: 'G03D 17',
    d: 'Dark-room arrangements not provided for in the other groups of this subclass; Portable dark-rooms',
  },
  {
    c: 'G03F',
    d: 'PHOTOMECHANICAL PRODUCTION OF TEXTURED OR PATTERNED SURFACES, e.g. FOR PRINTING, FOR PROCESSING OF SEMICONDUCTOR DEVICES; MATERIALS THEREFOR; ORIGINALS THEREFOR; APPARATUS SPECIALLY ADAPTED THEREFOR',
  },
  {
    c: 'G03F 1',
    d: 'Originals for photomechanical production of textured or patterned surfaces, e.g. masks, photo-masks or reticles; Mask blanks or pellicles therefor; Containers specially adapted therefor; Preparation thereof',
  },
  {
    c: 'G03F 3',
    d: 'Colour separation; Correction of tonal value',
  },
  {
    c: 'G03F 5',
    d: 'Screening processes; Screens therefor',
  },
  {
    c: 'G03F 7',
    d: 'Photomechanical, e.g. photolithographic, production of textured or patterned surfaces, e.g. printed surfaces; Materials therefor, e.g. comprising photoresists; Apparatus specially adapted therefor',
  },
  {
    c: 'G03F 9',
    d: 'Registration or positioning of originals, masks, frames, photographic sheets or textured or patterned surfaces, e.g. automatically',
  },
  {
    c: 'G03G',
    d: 'ELECTROGRAPHY; ELECTROPHOTOGRAPHY; MAGNETOGRAPHY',
  },
  {
    c: 'G03G 5',
    d: 'Recording-members for original recording by exposure e.g. to light, to heat, to electrons; Manufacture thereof; Selection of materials therefor',
  },
  {
    c: 'G03G 7',
    d: 'Selection of materials for use in image-receiving members, i.e. for reversal by physical contact; Manufacture thereof',
  },
  {
    c: 'G03G 8',
    d: 'Layers covering the final reproduction, e.g. for protecting, for writing thereon',
  },
  {
    c: 'G03G 9',
    d: 'Developers',
  },
  {
    c: 'G03G 11',
    d: 'Selection of substances for use as fixing agents',
  },
  {
    c: 'G03G 13',
    d: 'Electrographic processes using a charge pattern',
  },
  {
    c: 'G03G 15',
    d: 'Apparatus for electrographic processes using a charge pattern',
  },
  {
    c: 'G03G 16',
    d: 'Electrographic processes using deformation of thermoplastic layers',
  },
  {
    c: 'G03G 17',
    d: 'Electrographic processes using patterns other than charge patterns, e.g. an electric conductivity pattern; Processes involving a migration; e.g. photoelectrophoresis, photoelectrosolography; Processes involving a selective transfer, e.g. electrophoto-adhesive processes; Apparatus essentially involving a single such process',
  },
  {
    c: 'G03G 19',
    d: 'Processes using magnetic patterns; Apparatus therefor',
  },
  {
    c: 'G03G 21',
    d: 'Arrangements not provided for by groups G03G0013000000-G03G0019000000, e.g. cleaning, elimination of residual charge',
  },
  {
    c: 'G03H',
    d: 'HOLOGRAPHIC PROCESSES OR APPARATUS',
  },
  {
    c: 'G03H 1',
    d: 'Holographic processes or apparatus using light, infra-red, or ultra-violet waves for obtaining holograms or for obtaining an image from them; Details peculiar thereto',
  },
  {
    c: 'G03H 3',
    d: 'Holographic processes or apparatus using ultrasonic, sonic, or infrasonic waves for obtaining holograms; Processes or apparatus for obtaining an optical image from them',
  },
  {
    c: 'G03H 5',
    d: 'Holographic processes or apparatus using particles or using waves other than those covered by groups G03H0001000000 or G03H0003000000 for obtaining holograms; Processes or apparatus for obtaining an optical image from them',
  },
  {
    c: 'G04',
    d: 'HOROLOGY',
  },
  {
    c: 'G04B',
    d: 'MECHANICALLY-DRIVEN CLOCKS OR WATCHES; MECHANICAL PARTS OF CLOCKS OR WATCHES IN GENERAL; TIME-PIECES USING THE POSITION OF THE SUN, MOON, OR STARS',
  },
  {
    c: 'G04B 1',
    d: 'Driving mechanisms',
  },
  {
    c: 'G04B 3',
    d: 'Winding',
  },
  {
    c: 'G04B 5',
    d: 'Automatic winding-up',
  },
  {
    c: 'G04B 7',
    d: 'Combined normal and automatic winding-up',
  },
  {
    c: 'G04B 9',
    d: 'Supervision of the state of winding, e.g. indicating the amount of winding',
  },
  {
    c: 'G04B 11',
    d: 'Click devices, stop clicks or clutches for winding',
  },
  {
    c: 'G04B 13',
    d: 'Gearwork',
  },
  {
    c: 'G04B 15',
    d: 'Escapements',
  },
  {
    c: 'G04B 17',
    d: 'Mechanisms for stabilising frequency',
  },
  {
    c: 'G04B 18',
    d: 'Mechanisms for setting frequency',
  },
  {
    c: 'G04B 19',
    d: 'Time indicating',
  },
  {
    c: 'G04B 21',
    d: 'Indicating the time by acoustic means',
  },
  {
    c: 'G04B 23',
    d: 'Arrangements producing acoustic signals at preselected times',
  },
  {
    c: 'G04B 25',
    d: 'Indicating the time by other means or by combined means',
  },
  {
    c: 'G04B 27',
    d: 'Mechanical devices for setting the time-indicating means',
  },
  {
    c: 'G04B 29',
    d: 'Frameworks, supports, or arrangements of the clockwork parts in relation to each other, so-called calibers',
  },
  {
    c: 'G04B 31',
    d: 'Bearings; Point suspensions or counter-point suspensions; Pivot bearings; Single parts therefor',
  },
  {
    c: 'G04B 33',
    d: 'Calibers',
  },
  {
    c: 'G04B 35',
    d: 'Adjusting the gear train, e.g. the backlash of the arbors, depth of meshing of the gears',
  },
  {
    c: 'G04B 37',
    d: 'Protection of the clockwork against damage from outside',
  },
  {
    c: 'G04B 39',
    d: 'Watch crystals; Fastening or sealing crystals; Clock glasses',
  },
  {
    c: 'G04B 41',
    d: 'Locking or holding devices for pendulums, chimes, or the like, for use during transport',
  },
  {
    c: 'G04B 43',
    d: 'Protecting clockworks by shields or other means against external influences, e.g. magnetic fields',
  },
  {
    c: 'G04B 45',
    d: 'Clocks with unusual features',
  },
  {
    c: 'G04B 47',
    d: 'Time-pieces combined with other articles which do not interfere with the running or the time-keeping of the time-piece',
  },
  {
    c: 'G04B 49',
    d: 'Time-pieces using the position of the sun, moon, or stars',
  },
  {
    c: 'G04B 99',
    d: 'n.a.',
  },
  {
    c: 'G04C',
    d: 'ELECTROMECHANICAL CLOCKS OR WATCHES',
  },
  {
    c: 'G04C 1',
    d: 'Electric winding of mechanical clocks; Independent electric clocks or watches',
  },
  {
    c: 'G04C 3',
    d: 'Electromechanical clocks or watches independent of other time-pieces and in which the movement is maintained by electric means',
  },
  {
    c: 'G04C 5',
    d: 'Electric or magnetic means for converting oscillatory to rotary motion in time-pieces, i.e. electric or magnetic escapements',
  },
  {
    c: 'G04C 9',
    d: 'Electrically-actuated devices for setting the time-indicating means',
  },
  {
    c: 'G04C 10',
    d: 'Arrangements of electric power supplies in time-pieces',
  },
  {
    c: 'G04C 11',
    d: 'Electric clock installations; Master-and-slave clock systems; Synchronous-motor clocks',
  },
  {
    c: 'G04C 13',
    d: 'Driving mechanisms for clocks by master clocks',
  },
  {
    c: 'G04C 15',
    d: 'Clocks driven by synchronous motors',
  },
  {
    c: 'G04C 17',
    d: 'Indicating the time or producing time signals electrically',
  },
  {
    c: 'G04C 19',
    d: 'Producing optical time signals at prefixed times by electric means',
  },
  {
    c: 'G04C 21',
    d: 'Producing acoustic time signals by electrical means',
  },
  {
    c: 'G04C 23',
    d: 'Clocks with attached or built-in means operating any device at preselected times or after preselected time-intervals',
  },
  {
    c: 'G04C 99',
    d: 'n.a.',
  },
  {
    c: 'G04D',
    d: 'APPARATUS OR TOOLS SPECIALLY DESIGNED FOR MAKING OR MAINTAINING CLOCKS OR WATCHES',
  },
  {
    c: 'G04D 1',
    d: 'Gripping, holding, or supporting devices',
  },
  {
    c: 'G04D 3',
    d: "Watchmakers' or watch-repairers' machines or tools for working materials",
  },
  {
    c: 'G04D 5',
    d: 'Oiling devices; Special lubricant containers for watchmakers',
  },
  {
    c: 'G04D 7',
    d: 'Measuring, counting, calibrating, testing, or regulating apparatus',
  },
  {
    c: 'G04D 9',
    d: 'Demagnetising devices',
  },
  {
    c: 'G04D 99',
    d: 'n.a.',
  },
  {
    c: 'G04F',
    d: 'TIME-INTERVAL MEASURING',
  },
  {
    c: 'G04F 1',
    d: 'Apparatus which can be set and started to measure-off predetermined or adjustably-fixed time intervals without driving mechanisms, e.g. egg timer',
  },
  {
    c: 'G04F 3',
    d: 'Apparatus which can be set and started to measure-off predetermined or adjustably-fixed time intervals with driving mechanisms, e.g. dosimeter with clockwork',
  },
  {
    c: 'G04F 5',
    d: 'Apparatus for producing preselected time intervals for use as timing standards',
  },
  {
    c: 'G04F 7',
    d: 'Apparatus for measuring unknown time intervals by non-electric means',
  },
  {
    c: 'G04F 8',
    d: 'Apparatus for measuring unknown time intervals by electromechanical means',
  },
  {
    c: 'G04F 10',
    d: 'Apparatus for measuring unknown time intervals by electric means',
  },
  {
    c: 'G04F 13',
    d: 'Apparatus for measuring unknown time intervals by means not provided for in groups G04F0005000000-G04F0010000000',
  },
  {
    c: 'G04G',
    d: 'ELECTRONIC TIME-PIECES',
  },
  {
    c: 'G04G 3',
    d: 'Producing timing pulses',
  },
  {
    c: 'G04G 5',
    d: 'Setting, i.e. correcting or changing, the time-indication',
  },
  {
    c: 'G04G 7',
    d: 'Synchronisation',
  },
  {
    c: 'G04G 9',
    d: 'Visual time or date indication means',
  },
  {
    c: 'G04G 11',
    d: 'Producing optical signals at preselected times',
  },
  {
    c: 'G04G 13',
    d: 'Producing acoustic time signals',
  },
  {
    c: 'G04G 15',
    d: 'Time-pieces comprising means to be operated at preselected times or after preselected time intervals',
  },
  {
    c: 'G04G 17',
    d: 'Structural details; Housings',
  },
  {
    c: 'G04G 19',
    d: 'Electric power supply circuits specially adapted for use in electronic time-pieces',
  },
  {
    c: 'G04G 21',
    d: 'Input or output devices integrated in time-pieces',
  },
  {
    c: 'G04G 99',
    d: 'n.a.',
  },
  {
    c: 'G04R',
    d: 'RADIO-CONTROLLED TIME-PIECES',
  },
  {
    c: 'G04R 20',
    d: 'Setting the time according to the time information carried or implied by the radio signal',
  },
  {
    c: 'G04R 40',
    d: 'Correcting the clock frequency',
  },
  {
    c: 'G04R 60',
    d: 'Constructional details',
  },
  {
    c: 'G05',
    d: 'CONTROLLING; REGULATING',
  },
  {
    c: 'G05B',
    d: 'CONTROL OR REGULATING SYSTEMS IN GENERAL; FUNCTIONAL ELEMENTS OF SUCH SYSTEMS; MONITORING OR TESTING ARRANGEMENTS FOR SUCH SYSTEMS OR ELEMENTS',
  },
  {
    c: 'G05B 1',
    d: 'Comparing elements, i.e. elements for effecting comparison directly or indirectly between a desired value and existing or anticipated values',
  },
  {
    c: 'G05B 5',
    d: 'Anti-hunting arrangements',
  },
  {
    c: 'G05B 6',
    d: 'Internal feedback arrangements for obtaining particular characteristics, e.g. proportional, integral, differential',
  },
  {
    c: 'G05B 7',
    d: 'Arrangements for obtaining smooth engagement or disengagement of automatic control',
  },
  {
    c: 'G05B 9',
    d: 'Safety arrangements',
  },
  {
    c: 'G05B 11',
    d: 'Automatic controllers',
  },
  {
    c: 'G05B 13',
    d: 'Adaptive control systems, i.e. systems automatically adjusting themselves to have a performance which is optimum according to some preassigned criterion',
  },
  {
    c: 'G05B 15',
    d: 'Systems controlled by a computer',
  },
  {
    c: 'G05B 17',
    d: 'Systems involving the use of models or simulators of said systems',
  },
  {
    c: 'G05B 19',
    d: 'Programme-control systems',
  },
  {
    c: 'G05B 21',
    d: 'Systems involving sampling of the variable controlled',
  },
  {
    c: 'G05B 23',
    d: 'Testing or monitoring of control systems or parts thereof',
  },
  {
    c: 'G05B 24',
    d: 'Open-loop automatic control systems not otherwise provided for',
  },
  {
    c: 'G05B 99',
    d: 'n.a.',
  },
  {
    c: 'G05D',
    d: 'SYSTEMS FOR CONTROLLING OR REGULATING NON-ELECTRIC VARIABLES',
  },
  {
    c: 'G05D 1',
    d: 'Control of position, course, altitude, or attitude of land, water, air, or space vehicles, e.g. automatic pilot',
  },
  {
    c: 'G05D 3',
    d: 'Control of position or direction',
  },
  {
    c: 'G05D 5',
    d: 'Control of dimensions of material',
  },
  {
    c: 'G05D 7',
    d: 'Control of flow',
  },
  {
    c: 'G05D 9',
    d: 'Level control, e.g. controlling quantity of material stored in vessel',
  },
  {
    c: 'G05D 11',
    d: 'Ratio control',
  },
  {
    c: 'G05D 13',
    d: 'Control of linear speed; Control of angular speed; Control of acceleration or deceleration, e.g. of a prime mover',
  },
  {
    c: 'G05D 15',
    d: 'Control of mechanical force or stress; Control of mechanical pressure',
  },
  {
    c: 'G05D 16',
    d: 'Control of fluid pressure',
  },
  {
    c: 'G05D 17',
    d: 'Control of torque; Control of mechanical power',
  },
  {
    c: 'G05D 19',
    d: 'Control of mechanical oscillations, e.g. of amplitude, of frequency, of phase',
  },
  {
    c: 'G05D 21',
    d: 'Control of chemical or physico-chemical variables, e.g. pH-value',
  },
  {
    c: 'G05D 22',
    d: 'Control of humidity',
  },
  {
    c: 'G05D 23',
    d: 'Control of temperature',
  },
  {
    c: 'G05D 24',
    d: 'Control of viscosity',
  },
  {
    c: 'G05D 25',
    d: 'Control of light, e.g. intensity, colour, phase',
  },
  {
    c: 'G05D 27',
    d: 'Simultaneous control of variables covered by two or more of main groups G05D0001000000-G05D0025000000',
  },
  {
    c: 'G05D 29',
    d: 'Simultaneous control of electric and non-electric variables',
  },
  {
    c: 'G05D 99',
    d: 'n.a.',
  },
  {
    c: 'G05F',
    d: 'SYSTEMS FOR REGULATING ELECTRIC OR MAGNETIC VARIABLES',
  },
  {
    c: 'G05F 1',
    d: 'Automatic systems in which deviations of an electric quantity from one or more predetermined values are detected at the output of the system and fed back to a device within the system to restore the detected quantity to its predetermined value or values, i.e. retroactive systems',
  },
  {
    c: 'G05F 3',
    d: 'Non-retroactive systems for regulating electric variables by using an uncontrolled element, or an uncontrolled combination of elements, such element or such combination having self-regulating properties',
  },
  {
    c: 'G05F 5',
    d: 'Systems for regulating electric variables by detecting deviations in the electric input to the system and thereby controlling a device within the system to obtain a regulated output',
  },
  {
    c: 'G05F 7',
    d: 'Regulating magnetic variables',
  },
  {
    c: 'G05G',
    d: 'CONTROL DEVICES OR SYSTEMS INSOFAR AS CHARACTERISED BY MECHANICAL FEATURES ONLY',
  },
  {
    c: 'G05G 1',
    d: 'Controlling members, e.g. knobs or handles; Assemblies or arrangements thereof; Indicating position of controlling members',
  },
  {
    c: 'G05G 3',
    d: 'Controlled members',
  },
  {
    c: 'G05G 5',
    d: 'Means for preventing, limiting or returning the movements of parts of a control mechanism, e.g. locking controlling member',
  },
  {
    c: 'G05G 7',
    d: 'Manually-actuated control mechanisms provided with one single controlling member co-operating with one single controlled member; Details thereof',
  },
  {
    c: 'G05G 9',
    d: 'Manually-actuated control mechanisms provided with one single controlling member co-operating with two or more controlled members, e.g. selectively, simultaneously',
  },
  {
    c: 'G05G 11',
    d: 'Manually-actuated control mechanisms provided with two or more controlling members co-operating with one single controlled member',
  },
  {
    c: 'G05G 13',
    d: 'Manually-actuated control mechanisms provided with two or more controlling members and also two or more controlled members',
  },
  {
    c: 'G05G 15',
    d: 'Mechanical devices for initiating a movement automatically due to a specific cause',
  },
  {
    c: 'G05G 17',
    d: 'Mechanical devices for moving a member after being released; Trip or release mechanisms characterised thereby',
  },
  {
    c: 'G05G 19',
    d: 'Servo-mechanisms with follow-up action, e.g. occurring in steps',
  },
  {
    c: 'G05G 21',
    d: 'Mechanical apparatus for control of a series of operations, i.e. programme control, e.g. involving a set of cams',
  },
  {
    c: 'G05G 23',
    d: 'Means for ensuring the correct positioning of parts of control mechanisms, e.g. for taking-up play',
  },
  {
    c: 'G05G 25',
    d: 'Other details, features or accessories of control mechanisms, e.g. supporting intermediate members elastically',
  },
  {
    c: 'G06',
    d: 'COMPUTING; CALCULATING; COUNTING',
  },
  {
    c: 'G06C',
    d: 'DIGITAL COMPUTERS IN WHICH ALL THE COMPUTATION IS EFFECTED MECHANICALLY',
  },
  {
    c: 'G06C 1',
    d: 'Computing aids in which the computing members form at least part of the displayed result and are manipulated directly by hand, e.g. abacus, pocket adding device',
  },
  {
    c: 'G06C 3',
    d: 'Arrangements for table look-up, e.g. menstruation table',
  },
  {
    c: 'G06C 5',
    d: 'Non-functional elements',
  },
  {
    c: 'G06C 7',
    d: 'Input mechanisms',
  },
  {
    c: 'G06C 9',
    d: 'Transfer mechanisms, e.g. for transmitting figures from the input mechanism into the computing mechanism',
  },
  {
    c: 'G06C 11',
    d: 'Output mechanisms',
  },
  {
    c: 'G06C 13',
    d: 'Storage mechanisms',
  },
  {
    c: 'G06C 15',
    d: 'Computing mechanisms; Actuating devices therefor',
  },
  {
    c: 'G06C 17',
    d: 'Mechanisms for converting from one notational system to another, i.e. radix conversion',
  },
  {
    c: 'G06C 19',
    d: 'Decimal-point mechanisms; Analogous mechanisms for non-decimal notations',
  },
  {
    c: 'G06C 21',
    d: 'Programming-mechanisms for determining the steps to be performed by the computing machine, e.g. when a key or certain keys are depressed',
  },
  {
    c: 'G06C 23',
    d: 'Driving mechanisms for functional elements',
  },
  {
    c: 'G06C 25',
    d: 'Auxiliary functional arrangements, e.g. interlocks',
  },
  {
    c: 'G06C 27',
    d: 'Computing machines characterised by the structural interrelation of their functional units, e.g. invoicing machines',
  },
  {
    c: 'G06C 29',
    d: 'Combinations of computing machines with other machines, e.g. with typewriter, with money-changing apparatus',
  },
  {
    c: 'G06D',
    d: 'DIGITAL FLUID-PRESSURE COMPUTING DEVICES',
  },
  {
    c: 'G06D 1',
    d: 'Details, e.g. functional units',
  },
  {
    c: 'G06D 3',
    d: 'Computing devices characterised by the interrelationship of the functional units and having at least one moving part',
  },
  {
    c: 'G06D 5',
    d: 'Computing devices characterised by the interrelationship of the functional units and having no moving parts',
  },
  {
    c: 'G06D 7',
    d: 'Computing devices characterised by the combination of hydraulic or pneumatic functional elements with at least one other type of functional element',
  },
  {
    c: 'G06E',
    d: 'OPTICAL COMPUTING DEVICES',
  },
  {
    c: 'G06E 1',
    d: 'Devices for processing exclusively digital data',
  },
  {
    c: 'G06E 3',
    d: 'Devices not provided for in group G06E0001000000, e.g. for processing analogue or hybrid data',
  },
  {
    c: 'G06F',
    d: 'ELECTRIC DIGITAL DATA PROCESSING',
  },
  {
    c: 'G06F 1',
    d: 'Details not covered by groups G06F0003000000-G06F0013000000 and G06F0021000000',
  },
  {
    c: 'G06F 3',
    d: 'Input arrangements for transferring data to be processed into a form capable of being handled by the computer; Output arrangements for transferring data from processing unit to output unit, e.g. interface arrangements',
  },
  {
    c: 'G06F 5',
    d: 'Methods or arrangements for data conversion without changing the order or content of the data handled',
  },
  {
    c: 'G06F 7',
    d: 'Methods or arrangements for processing data by operating upon the order or content of the data handled',
  },
  {
    c: 'G06F 8',
    d: 'Arrangements for software engineering',
  },
  {
    c: 'G06F 9',
    d: 'Arrangements for program control, e.g. control units',
  },
  {
    c: 'G06F 11',
    d: 'Error detection; Error correction; Monitoring',
  },
  {
    c: 'G06F 12',
    d: 'Accessing, addressing or allocating within memory systems or architectures',
  },
  {
    c: 'G06F 13',
    d: 'Interconnection of, or transfer of information or other signals between, memories, input/output devices or central processing units',
  },
  {
    c: 'G06F 15',
    d: 'Digital computers in general',
  },
  {
    c: 'G06F 16',
    d: 'Information retrieval; Database structures therefor; File system structures therefor',
  },
  {
    c: 'G06F 17',
    d: 'Digital computing or data processing equipment or methods, specially adapted for specific functions',
  },
  {
    c: 'G06F 21',
    d: 'Security arrangements for protecting computers, components thereof, programs or data against unauthorised activity',
  },
  {
    c: 'G06G',
    d: 'ANALOGUE COMPUTERS',
  },
  {
    c: 'G06G 1',
    d: 'Hand-manipulated computing devices',
  },
  {
    c: 'G06G 3',
    d: 'Devices in which the computing operation is performed mechanically',
  },
  {
    c: 'G06G 5',
    d: 'Devices in which the computing operation is performed by means of fluid-pressure elements',
  },
  {
    c: 'G06G 7',
    d: 'Devices in which the computing operation is performed by varying electric or magnetic quantities',
  },
  {
    c: 'G06G 99',
    d: 'n.a.',
  },
  {
    c: 'G06J',
    d: 'HYBRID COMPUTING ARRANGEMENTS',
  },
  {
    c: 'G06J 1',
    d: 'Hybrid computing arrangements',
  },
  {
    c: 'G06J 3',
    d: 'Systems for conjoint operation of complete digital and complete analogue computers',
  },
  {
    c: 'G06K',
    d: 'RECOGNITION OF DATA; PRESENTATION OF DATA; RECORD CARRIERS; HANDLING RECORD CARRIERS',
  },
  {
    c: 'G06K 1',
    d: 'Methods or arrangements for marking the record carrier in digital fashion',
  },
  {
    c: 'G06K 3',
    d: 'Methods or arrangements for printing of data in the shape of alphanumeric or other characters from a record carrier, e.g. interpreting, printing-out from a magnetic tape',
  },
  {
    c: 'G06K 5',
    d: 'Methods or arrangements for verifying the correctness of markings on a record carrier; Column-detection devices',
  },
  {
    c: 'G06K 7',
    d: 'Methods or arrangements for sensing record carriers',
  },
  {
    c: 'G06K 9',
    d: 'Methods or arrangements for reading or recognising printed or written characters or for recognising patterns, e.g. fingerprints',
  },
  {
    c: 'G06K 11',
    d: 'Methods or arrangements for graph-reading or for converting the pattern of mechanical parameters, e.g. force or presence, into electrical signals',
  },
  {
    c: 'G06K 13',
    d: 'Conveying record carriers from one station to another, e.g. from stack to punching mechanism',
  },
  {
    c: 'G06K 15',
    d: 'Arrangements for producing a permanent visual presentation of the output data',
  },
  {
    c: 'G06K 17',
    d: 'Methods or arrangements for effecting co-operative working between equipments covered by two or more of main groups G06K0001000000-G06K0015000000, e.g. automatic card files incorporating conveying and reading operations',
  },
  {
    c: 'G06K 19',
    d: 'Record carriers for use with machines and with at least a part designed to carry digital markings',
  },
  {
    c: 'G06K 21',
    d: 'Information retrieval from punched cards designed for manual use or handling by machine',
  },
  {
    c: 'G06M',
    d: 'COUNTING MECHANISMS; COUNTING OF OBJECTS NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'G06M 1',
    d: 'Design features of general application',
  },
  {
    c: 'G06M 3',
    d: 'Counters with additional facilities',
  },
  {
    c: 'G06M 7',
    d: 'Counting of objects',
  },
  {
    c: 'G06M 9',
    d: 'Counting of objects in a stack thereof',
  },
  {
    c: 'G06M 11',
    d: 'Counting of objects distributed at random, e.g. on a surface',
  },
  {
    c: 'G06M 15',
    d: 'Counting of objects, not otherwise provided for',
  },
  {
    c: 'G06N',
    d: 'COMPUTER SYSTEMS BASED ON SPECIFIC COMPUTATIONAL MODELS',
  },
  {
    c: 'G06N 3',
    d: 'Computer systems based on biological models',
  },
  {
    c: 'G06N 5',
    d: 'Computer systems using knowledge-based models',
  },
  {
    c: 'G06N 7',
    d: 'Computer systems based on specific mathematical models',
  },
  {
    c: 'G06N 10',
    d: 'Quantum computers, i.e. computer systems based on quantum-mechanical phenomena',
  },
  {
    c: 'G06N 20',
    d: 'Machine learning',
  },
  {
    c: 'G06N 99',
    d: 'n.a.',
  },
  {
    c: 'G06Q',
    d: 'DATA PROCESSING SYSTEMS OR METHODS, SPECIALLY ADAPTED FOR ADMINISTRATIVE, COMMERCIAL, FINANCIAL, MANAGERIAL, SUPERVISORY OR FORECASTING PURPOSES; SYSTEMS OR METHODS SPECIALLY ADAPTED FOR ADMINISTRATIVE, COMMERCIAL, FINANCIAL, MANAGERIAL, SUPERVISORY OR FORECASTING PURPOSES, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'G06Q 10',
    d: 'Administration; Management',
  },
  {
    c: 'G06Q 20',
    d: 'Payment architectures, schemes or protocols',
  },
  {
    c: 'G06Q 30',
    d: 'Commerce, e.g. shopping or e-commerce',
  },
  {
    c: 'G06Q 40',
    d: 'Finance; Insurance; Tax strategies; Processing of corporate or income taxes',
  },
  {
    c: 'G06Q 50',
    d: 'Systems or methods specially adapted for specific business sectors, e.g. utilities or tourism',
  },
  {
    c: 'G06Q 90',
    d: 'Systems or methods specially adapted for administrative, commercial, financial, managerial, supervisory or forecasting purposes, not involving significant data processing',
  },
  {
    c: 'G06Q 99',
    d: 'n.a.',
  },
  {
    c: 'G06T',
    d: 'IMAGE DATA PROCESSING OR GENERATION, IN GENERAL',
  },
  {
    c: 'G06T 1',
    d: 'General purpose image data processing',
  },
  {
    c: 'G06T 3',
    d: 'Geometric image transformation in the plane of the image',
  },
  {
    c: 'G06T 5',
    d: 'Image enhancement or restoration',
  },
  {
    c: 'G06T 7',
    d: 'Image analysis',
  },
  {
    c: 'G06T 9',
    d: 'Image coding',
  },
  {
    c: 'G06T 11',
    d: '2D [Two Dimensional] image generation',
  },
  {
    c: 'G06T 13',
    d: 'Animation',
  },
  {
    c: 'G06T 15',
    d: '3D [Three Dimensional] image rendering',
  },
  {
    c: 'G06T 17',
    d: '3D modelling for computer graphics',
  },
  {
    c: 'G06T 19',
    d: 'Manipulating 3D models or images for computer graphics',
  },
  {
    c: 'G07',
    d: 'CHECKING-DEVICES',
  },
  {
    c: 'G07B',
    d: 'TICKET-ISSUING APPARATUS; TAXIMETERS; ARRANGEMENTS OR APPARATUS FOR COLLECTING FARES, TOLLS OR ENTRANCE FEES AT ONE OR MORE CONTROL POINTS;  FRANKING APPARATUS',
  },
  {
    c: 'G07B 1',
    d: 'Machines for printing and issuing tickets',
  },
  {
    c: 'G07B 3',
    d: 'Machines for issuing preprinted tickets',
  },
  {
    c: 'G07B 5',
    d: 'Details of, or auxiliary devices for, ticket-issuing machines',
  },
  {
    c: 'G07B 7',
    d: 'Holders providing direct manual access to the tickets',
  },
  {
    c: 'G07B 9',
    d: 'Ticket punches',
  },
  {
    c: 'G07B 11',
    d: 'Apparatus for validating or cancelling issued tickets',
  },
  {
    c: 'G07B 13',
    d: 'Taximeters',
  },
  {
    c: 'G07B 15',
    d: 'Arrangements or apparatus for collecting fares, tolls or entrance fees at one or more control points',
  },
  {
    c: 'G07B 17',
    d: 'Franking apparatus',
  },
  {
    c: 'G07C',
    d: 'TIME OR ATTENDANCE REGISTERS; REGISTERING OR INDICATING THE WORKING OF MACHINES; GENERATING RANDOM NUMBERS; VOTING OR LOTTERY APPARATUS; ARRANGEMENTS, SYSTEMS OR APPARATUS FOR CHECKING NOT PROVIDED FOR ELSEWHERE',
  },
  {
    c: 'G07C 1',
    d: 'Registering, indicating, or recording the time of events or elapsed time, e.g. time-recorders for work people',
  },
  {
    c: 'G07C 3',
    d: 'Registering or indicating the condition or the working of machines or other apparatus, other than vehicles',
  },
  {
    c: 'G07C 5',
    d: 'Registering or indicating the working of vehicles',
  },
  {
    c: 'G07C 7',
    d: 'Details or accessories common to the registering or indicating apparatus of groups G07C0003000000 and G07C0005000000',
  },
  {
    c: 'G07C 9',
    d: 'Individual entry or exit registers',
  },
  {
    c: 'G07C 11',
    d: 'Arrangements, systems, or apparatus for checking, e.g. the occurrence of a condition, not provided for elsewhere',
  },
  {
    c: 'G07C 13',
    d: 'Voting apparatus',
  },
  {
    c: 'G07C 15',
    d: 'Generating random numbers; Lottery apparatus',
  },
  {
    c: 'G07D',
    d: 'HANDLING OF COINS OR OF VALUABLE PAPERS, e.g. TESTING, SORTING BY DENOMINATIONS, COUNTING, DISPENSING, CHANGING OR DEPOSITING',
  },
  {
    c: 'G07D 1',
    d: 'Coin dispensers',
  },
  {
    c: 'G07D 3',
    d: 'Sorting a mixed bulk of coins into denominations',
  },
  {
    c: 'G07D 5',
    d: 'Testing specially adapted to determine the identity or genuineness of coins, e.g. for segregating coins which are unacceptable or alien to a currency',
  },
  {
    c: 'G07D 7',
    d: 'Testing specially adapted to determine the identity or genuineness of valuable papers or for segregating those which are unacceptable, e.g. banknotes that are alien to a currency',
  },
  {
    c: 'G07D 9',
    d: 'Counting coins',
  },
  {
    c: 'G07D 11',
    d: 'Devices accepting coins; Devices accepting, dispensing, sorting or counting valuable papers',
  },
  {
    c: 'G07D 13',
    d: 'Handling of coins or of valuable papers, characterised by a combination of mechanisms not covered by a single one of groups G07D0001000000-G07D0011000000',
  },
  {
    c: 'G07F',
    d: 'COIN-FREED OR LIKE APPARATUS',
  },
  {
    c: 'G07F 1',
    d: 'Coin inlet arrangements; Coins specially adapted to operate coin-freed mechanisms',
  },
  {
    c: 'G07F 5',
    d: 'Coin-actuated mechanisms; Interlocks',
  },
  {
    c: 'G07F 7',
    d: 'Mechanisms actuated by objects other than coins to free or to actuate vending, hiring, coin or paper currency dispensing or refunding apparatus',
  },
  {
    c: 'G07F 9',
    d: 'Details other than those peculiar to special kinds or types of apparatus',
  },
  {
    c: 'G07F 11',
    d: 'Coin-freed apparatus for dispensing, or the like, discrete articles',
  },
  {
    c: 'G07F 13',
    d: 'Coin-freed apparatus for controlling dispensing of fluids, semiliquids or granular material from reservoirs',
  },
  {
    c: 'G07F 15',
    d: 'Coin-freed apparatus with meter-controlled dispensing of liquid, gas, or electricity',
  },
  {
    c: 'G07F 17',
    d: 'Coin-freed apparatus for hiring articles; Coin-freed facilities or services',
  },
  {
    c: 'G07F 19',
    d: 'Complete banking systems; coded card-freed arrangements adapted for dispensing or receiving monies or the like and posting such transactions to existing accounts, e.g. automatic teller machines',
  },
  {
    c: 'G07G',
    d: 'REGISTERING THE RECEIPT OF CASH, VALUABLES, OR TOKENS',
  },
  {
    c: 'G07G 1',
    d: 'Cash registers',
  },
  {
    c: 'G07G 3',
    d: 'Alarm indicators, e.g. bells',
  },
  {
    c: 'G07G 5',
    d: 'Receipt-giving machines',
  },
  {
    c: 'G08',
    d: 'SIGNALLING',
  },
  {
    c: 'G08B',
    d: 'SIGNALLING OR CALLING SYSTEMS; ORDER TELEGRAPHS; ALARM SYSTEMS',
  },
  {
    c: 'G08B 1',
    d: 'Systems for signalling characterised solely by the form of transmission of the signal',
  },
  {
    c: 'G08B 3',
    d: 'Audible signalling systems; Audible personal calling systems',
  },
  {
    c: 'G08B 5',
    d: 'Visible signalling systems, e.g. personal calling systems, remote indication of seats occupied',
  },
  {
    c: 'G08B 6',
    d: 'Tactile signalling systems, e.g. personal calling systems',
  },
  {
    c: 'G08B 7',
    d: 'Signalling systems according to more than one of groups G08B0003000000-G08B0006000000; Personal calling systems according to more than one of groups G08B0003000000-G08B0006000000',
  },
  {
    c: 'G08B 9',
    d: 'Order telegraph apparatus, i.e. means for transmitting one of a finite number of different orders at the discretion of the user, e.g. bridge to engine room orders in ships',
  },
  {
    c: 'G08B 13',
    d: 'Burglar, theft or intruder alarms',
  },
  {
    c: 'G08B 15',
    d: 'Identifying, scaring or incapacitating burglars, thieves or intruders, e.g. by explosives',
  },
  {
    c: 'G08B 17',
    d: 'Fire alarms; Alarms responsive to explosion',
  },
  {
    c: 'G08B 19',
    d: 'Alarms responsive to two or more different undesired or abnormal conditions, e.g. burglary and fire, abnormal temperature and abnormal rate of flow',
  },
  {
    c: 'G08B 21',
    d: 'Alarms responsive to a single specified undesired or abnormal condition and not otherwise provided for',
  },
  {
    c: 'G08B 23',
    d: 'Alarms responsive to unspecified undesired or abnormal conditions',
  },
  {
    c: 'G08B 25',
    d: 'Alarm systems in which the location of the alarm condition is signalled to a central station, e.g. fire or police telegraphic systems',
  },
  {
    c: 'G08B 26',
    d: 'Alarm systems in which substations are interrogated in succession by a central station',
  },
  {
    c: 'G08B 27',
    d: 'Alarm systems in which the alarm condition is signalled from a central station to a plurality of substations',
  },
  {
    c: 'G08B 29',
    d: 'Checking or monitoring of signalling or alarm systems; Prevention or correction of operating errors, e.g. preventing unauthorised operation',
  },
  {
    c: 'G08B 31',
    d: 'Predictive alarm systems characterised by extrapolation or other computation using updated historic data',
  },
  {
    c: 'G08C',
    d: 'TRANSMISSION SYSTEMS FOR MEASURED VALUES, CONTROL OR SIMILAR SIGNALS',
  },
  {
    c: 'G08C 13',
    d: 'Arrangements for influencing the relationship between signals at input and output, e.g. differentiating, delaying',
  },
  {
    c: 'G08C 15',
    d: 'Arrangements characterised by the use of multiplexing for the transmission of a plurality of signals over a common path',
  },
  {
    c: 'G08C 17',
    d: 'Arrangements for transmitting signals characterised by the use of a wireless electrical link',
  },
  {
    c: 'G08C 19',
    d: 'Electric signal transmission systems',
  },
  {
    c: 'G08C 21',
    d: 'Systems for transmitting the position of an object with respect to a predetermined reference system, e.g. tele-autographic system',
  },
  {
    c: 'G08C 23',
    d: 'Non-electric signal transmission systems, e.g. optical systems',
  },
  {
    c: 'G08C 25',
    d: 'Arrangements for preventing or correcting errors; Monitoring arrangements',
  },
  {
    c: 'G08G',
    d: 'TRAFFIC CONTROL SYSTEMS',
  },
  {
    c: 'G08G 1',
    d: 'Traffic control systems for road vehicles',
  },
  {
    c: 'G08G 3',
    d: 'Traffic control systems for marine craft',
  },
  {
    c: 'G08G 5',
    d: 'Traffic control systems for aircraft',
  },
  {
    c: 'G08G 7',
    d: 'Traffic control systems for simultaneous control of two or more different kinds of craft',
  },
  {
    c: 'G08G 9',
    d: 'Traffic control systems for craft where the kind of craft is irrelevant or unspecified',
  },
  {
    c: 'G08G 99',
    d: 'n.a.',
  },
  {
    c: 'G09',
    d: 'EDUCATING; CRYPTOGRAPHY; DISPLAY; ADVERTISING; SEALS',
  },
  {
    c: 'G09B',
    d: 'EDUCATIONAL OR DEMONSTRATION APPLIANCES; APPLIANCES FOR TEACHING, OR COMMUNICATING WITH, THE BLIND, DEAF OR MUTE; MODELS; PLANETARIA; GLOBES; MAPS; DIAGRAMS',
  },
  {
    c: 'G09B 1',
    d: 'Manually- or mechanically-operated educational appliances using elements forming or bearing symbols, signs, pictures, or the like which are arranged or adapted to be arranged in one or more particular ways',
  },
  {
    c: 'G09B 3',
    d: 'Manually- or mechanically-operated teaching appliances working with questions and answers',
  },
  {
    c: 'G09B 5',
    d: 'Electrically-operated educational appliances',
  },
  {
    c: 'G09B 7',
    d: 'Electrically-operated teaching apparatus or devices working with questions and answers',
  },
  {
    c: 'G09B 9',
    d: 'Simulators for teaching or training purposes',
  },
  {
    c: 'G09B 11',
    d: 'Teaching hand-writing, shorthand, drawing, or painting',
  },
  {
    c: 'G09B 13',
    d: 'Teaching typing',
  },
  {
    c: 'G09B 15',
    d: 'Teaching music',
  },
  {
    c: 'G09B 17',
    d: 'Teaching reading',
  },
  {
    c: 'G09B 19',
    d: 'Teaching not covered by other main groups of this subclass',
  },
  {
    c: 'G09B 21',
    d: 'Teaching, or communicating with, the blind, deaf or mute',
  },
  {
    c: 'G09B 23',
    d: 'Models for scientific, medical, or mathematical purposes, e.g. full-sized device for demonstration purposes',
  },
  {
    c: 'G09B 25',
    d: 'Models for purposes not provided for in group G09B0023000000, e.g. full-sized devices for demonstration purposes',
  },
  {
    c: 'G09B 27',
    d: 'Planetaria; Globes',
  },
  {
    c: 'G09B 29',
    d: 'Maps',
  },
  {
    c: 'G09C',
    d: 'CIPHERING OR DECIPHERING APPARATUS FOR CRYPTOGRAPHIC OR OTHER PURPOSES INVOLVING THE NEED FOR SECRECY',
  },
  {
    c: 'G09C 1',
    d: 'Apparatus or methods whereby a given sequence of signs, e.g. an intelligible text, is transformed into an unintelligible sequence of signs by transposing the signs or groups of signs or by replacing them by others according to a predetermined system',
  },
  {
    c: 'G09C 3',
    d: 'Typewriters for ciphering or deciphering cryptographic text',
  },
  {
    c: 'G09C 5',
    d: 'Ciphering or deciphering apparatus or methods not provided for in other groups of this subclass, e.g. involving the concealment or deformation of graphic data such as designs, written or printed messages',
  },
  {
    c: 'G09D',
    d: 'RAILWAY OR LIKE TIME OR FARE TABLES; PERPETUAL CALENDARS',
  },
  {
    c: 'G09D 1',
    d: 'Railway or like time or fare tables; Indicating or reading aids therefor',
  },
  {
    c: 'G09D 3',
    d: 'Perpetual calendars',
  },
  {
    c: 'G09F',
    d: 'DISPLAYING; ADVERTISING; SIGNS; LABELS OR NAME-PLATES; SEALS',
  },
  {
    c: 'G09F 1',
    d: 'Cardboard or like show-cards of foldable or flexible material',
  },
  {
    c: 'G09F 3',
    d: 'Labels, tag tickets, or similar identification or indication means; Seals; Postage or like stamps',
  },
  {
    c: 'G09F 5',
    d: 'Means for displaying samples',
  },
  {
    c: 'G09F 7',
    d: 'Signs, name or number plates, letters, numerals, or symbols; Panels or boards',
  },
  {
    c: 'G09F 9',
    d: 'Indicating arrangements for variable information in which the information is built-up on a support by selection or combination of individual elements',
  },
  {
    c: 'G09F 11',
    d: 'Indicating arrangements for variable information in which the complete information is permanently attached to a movable support which brings it to the display position',
  },
  {
    c: 'G09F 13',
    d: 'Illuminated signs; Luminous advertising',
  },
  {
    c: 'G09F 15',
    d: 'Boards, hoardings, pillars, or like structures for notices, placards, posters, or the like',
  },
  {
    c: 'G09F 17',
    d: 'Flags; Banners; Mountings therefor',
  },
  {
    c: 'G09F 19',
    d: 'Advertising or display means not otherwise provided for',
  },
  {
    c: 'G09F 21',
    d: 'Mobile visual advertising',
  },
  {
    c: 'G09F 23',
    d: 'Advertising on or in specific articles, e.g. ashtrays, letter-boxes',
  },
  {
    c: 'G09F 25',
    d: 'Audible advertising',
  },
  {
    c: 'G09F 27',
    d: 'Combined visual and audible advertising or displaying, e.g. for public address',
  },
  {
    c: 'G09G',
    d: 'ARRANGEMENTS OR CIRCUITS FOR CONTROL OF INDICATING DEVICES USING STATIC MEANS TO PRESENT VARIABLE INFORMATION',
  },
  {
    c: 'G09G 1',
    d: 'Control arrangements or circuits, of interest only in connection with cathode-ray tube indicators',
  },
  {
    c: 'G09G 3',
    d: 'Control arrangements or circuits, of interest only in connection with visual indicators other than cathode-ray tubes',
  },
  {
    c: 'G09G 5',
    d: 'Control arrangements or circuits for visual indicators common to cathode-ray tube indicators and other visual indicators',
  },
  {
    c: 'G10',
    d: 'MUSICAL INSTRUMENTS; ACOUSTICS',
  },
  {
    c: 'G10B',
    d: 'ORGANS, HARMONIUMS OR SIMILAR WIND MUSICAL INSTRUMENTS WITH ASSOCIATED BLOWING APPARATUS',
  },
  {
    c: 'G10B 1',
    d: 'General design of organs, harmoniums or similar wind musical instruments with associated blowing apparatus',
  },
  {
    c: 'G10B 3',
    d: 'Details or accessories',
  },
  {
    c: 'G10C',
    d: 'PIANOS, HARPSICHORDS, SPINETS OR SIMILAR STRINGED MUSICAL INSTRUMENTS WITH ONE OR MORE KEYBOARDS',
  },
  {
    c: 'G10C 1',
    d: 'General design of pianos, harpsichords, spinets or similar stringed musical instruments with one or more keyboards',
  },
  {
    c: 'G10C 3',
    d: 'Details or accessories',
  },
  {
    c: 'G10C 5',
    d: 'Combinations with other musical instruments, e.g. with bells or xylophones',
  },
  {
    c: 'G10C 9',
    d: 'Methods, tools or materials specially adapted for the manufacture or maintenance of musical instruments covered by this subclass',
  },
  {
    c: 'G10D',
    d: 'STRINGED MUSICAL INSTRUMENTS; WIND MUSICAL INSTRUMENTS; ACCORDIONS OR CONCERTINAS; PERCUSSION MUSICAL INSTRUMENTS; MUSICAL INSTRUMENTS NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'G10D 1',
    d: 'General design of stringed musical instruments',
  },
  {
    c: 'G10D 3',
    d: 'Details of, or accessories for, stringed musical instruments, e.g. slide-bars',
  },
  {
    c: 'G10D 7',
    d: 'General design of wind musical instruments',
  },
  {
    c: 'G10D 9',
    d: 'Details of, or accessories for, wind musical instruments',
  },
  {
    c: 'G10D 11',
    d: 'Accordions, concertinas or the like; Keyboards therefor',
  },
  {
    c: 'G10D 13',
    d: 'Percussion musical instruments; Details or accessories',
  },
  {
    c: 'G10D 15',
    d: 'Combinations of different musical instruments',
  },
  {
    c: 'G10D 17',
    d: 'Musical instruments not provided for in any other group of this subclass, e.g. Aeolian harp, singing-flame musical instrument',
  },
  {
    c: 'G10F',
    d: 'AUTOMATIC MUSICAL INSTRUMENTS',
  },
  {
    c: 'G10F 1',
    d: 'Automatic musical instruments',
  },
  {
    c: 'G10F 3',
    d: 'Independent players for keyboard instruments',
  },
  {
    c: 'G10F 5',
    d: 'Details or accessories',
  },
  {
    c: 'G10G',
    d: 'AIDS FOR MUSIC; SUPPORTS FOR MUSICAL INSTRUMENTS; OTHER AUXILIARY DEVICES OR ACCESSORIES FOR MUSIC OR MUSICAL INSTRUMENTS',
  },
  {
    c: 'G10G 1',
    d: 'Means for the representation of music',
  },
  {
    c: 'G10G 3',
    d: 'Recording music in notation form, e.g. recording the mechanical operation of a musical instrument',
  },
  {
    c: 'G10G 5',
    d: 'Supports for musical instruments',
  },
  {
    c: 'G10G 7',
    d: "Other auxiliary devices or accessories, e.g. conductors' batons or separate holders for resin or strings",
  },
  {
    c: 'G10H',
    d: 'ELECTROPHONIC MUSICAL INSTRUMENTS; INSTRUMENTS IN WHICH THE TONES ARE GENERATED BY ELECTROMECHANICAL MEANS OR ELECTRONIC GENERATORS, OR IN WHICH THE TONES ARE SYNTHESISED FROM A DATA STORE',
  },
  {
    c: 'G10H 1',
    d: 'Details of electrophonic musical instruments',
  },
  {
    c: 'G10H 3',
    d: 'Instruments in which the tones are generated by electromechanical means',
  },
  {
    c: 'G10H 5',
    d: 'Instruments in which the tones are generated by means of electronic generators',
  },
  {
    c: 'G10H 7',
    d: 'Instruments in which the tones are synthesised from a data store, e.g. computer organs',
  },
  {
    c: 'G10K',
    d: 'SOUND-PRODUCING DEVICES',
  },
  {
    c: 'G10K 1',
    d: 'Devices in which sound is produced by striking a resonating body, e.g. bells, chimes or gongs',
  },
  {
    c: 'G10K 3',
    d: 'Rattles or like noise-producing devices',
  },
  {
    c: 'G10K 5',
    d: 'Whistles',
  },
  {
    c: 'G10K 7',
    d: 'Sirens',
  },
  {
    c: 'G10K 9',
    d: 'Devices in which sound is produced by vibrating a diaphragm or analogous element, e.g. fog horns, vehicle hooters or buzzers',
  },
  {
    c: 'G10K 11',
    d: 'Methods or devices for transmitting, conducting or directing sound in general; Methods or devices for protecting against, or for damping, noise or other acoustic waves in general',
  },
  {
    c: 'G10K 13',
    d: 'Cones, diaphragms, or the like, for emitting or receiving sound in general',
  },
  {
    c: 'G10K 15',
    d: 'Acoustics not otherwise provided for',
  },
  {
    c: 'G10L',
    d: 'SPEECH ANALYSIS OR SYNTHESIS; SPEECH RECOGNITION; SPEECH OR VOICE PROCESSING; SPEECH OR AUDIO CODING OR DECODING',
  },
  {
    c: 'G10L 13',
    d: 'Speech synthesis; Text to speech systems',
  },
  {
    c: 'G10L 15',
    d: 'Speech recognition',
  },
  {
    c: 'G10L 17',
    d: 'Speaker identification or verification',
  },
  {
    c: 'G10L 19',
    d: 'Speech or audio signal analysis-synthesis techniques for redundancy reduction, e.g. in vocoders; Coding or decoding of speech or audio signals, using source filter models or psychoacoustic analysis',
  },
  {
    c: 'G10L 21',
    d: 'Processing of the speech or voice signal to produce another audible or non-audible signal, e.g. visual or tactile, in order to modify its quality or its intelligibility',
  },
  {
    c: 'G10L 25',
    d: 'Speech or voice analysis techniques not restricted to a single one of groups G10L0015000000-G10L0021000000',
  },
  {
    c: 'G10L 99',
    d: 'n.a.',
  },
  {
    c: 'G11',
    d: 'INFORMATION STORAGE',
  },
  {
    c: 'G11B',
    d: 'INFORMATION STORAGE BASED ON RELATIVE MOVEMENT BETWEEN RECORD CARRIER AND TRANSDUCER',
  },
  {
    c: 'G11B 3',
    d: 'Recording by mechanical cutting, deforming or pressing, e.g. of grooves or pits; Reproducing by mechanical sensing; Record carriers therefor',
  },
  {
    c: 'G11B 5',
    d: 'Recording by magnetisation or demagnetisation of a record carrier; Reproducing by magnetic means; Record carriers therefor',
  },
  {
    c: 'G11B 7',
    d: 'Recording or reproducing by optical means, e.g. recording using a thermal beam of optical radiation, reproducing using an optical beam at lower power; Record carriers therefor',
  },
  {
    c: 'G11B 9',
    d: 'Recording or reproducing using a method or means not covered by one of the main groups G11B0003000000-G11B0007000000; Record carriers therefor',
  },
  {
    c: 'G11B 11',
    d: 'Recording on, or reproducing from, the same record carrier wherein for these two operations the methods or means are covered by different main groups of groups G11B0003000000-G11B0007000000 or by different subgroups of group G11B0009000000; Record carriers therefor',
  },
  {
    c: 'G11B 13',
    d: 'Recording simultaneously or selectively by methods or means covered by different main groups; Record carriers therefor; Reproducing simultaneously or selectively therefrom',
  },
  {
    c: 'G11B 15',
    d: 'Driving, starting or stopping record carriers of filamentary or web form; Driving both such record carriers and heads; Guiding such record carriers or containers therefor; Control thereof; Control of operating function',
  },
  {
    c: 'G11B 17',
    d: 'Guiding record carriers not specifically of filamentary or web form, or of supports therefor',
  },
  {
    c: 'G11B 19',
    d: 'Driving, starting, stopping record carriers not specifically of filamentary or web form, or of supports therefor; Control thereof; Control of operating function',
  },
  {
    c: 'G11B 20',
    d: 'Signal processing not specific to the method of recording or reproducing; Circuits therefor',
  },
  {
    c: 'G11B 21',
    d: 'Head arrangements not specific to the method of recording or reproducing',
  },
  {
    c: 'G11B 23',
    d: 'Record carriers not specific to the method of recording or reproducing; Accessories, e.g. containers, specially adapted for co-operation with the recording or reproducing apparatus',
  },
  {
    c: 'G11B 25',
    d: 'Apparatus characterised by the shape of record carrier employed but not specific to the method of recording or reproducing',
  },
  {
    c: 'G11B 27',
    d: 'Editing; Indexing; Addressing; Timing or synchronising; Monitoring; Measuring tape travel',
  },
  {
    c: 'G11B 31',
    d: 'Arrangements for the associated working of recording or reproducing apparatus with related apparatus',
  },
  {
    c: 'G11B 33',
    d: 'Constructional parts, details or accessories not provided for in the other groups of this subclass',
  },
  {
    c: 'G11C',
    d: 'STATIC STORES',
  },
  {
    c: 'G11C 5',
    d: 'Details of stores covered by group G11C0011000000',
  },
  {
    c: 'G11C 7',
    d: 'Arrangements for writing information into, or reading information out from, a digital store',
  },
  {
    c: 'G11C 8',
    d: 'Arrangements for selecting an address in a digital store',
  },
  {
    c: 'G11C 11',
    d: 'Digital stores characterised by the use of particular electric or magnetic storage elements; Storage elements therefor',
  },
  {
    c: 'G11C 13',
    d: 'Digital stores characterised by the use of storage elements not covered by groups G11C0011000000, G11C0023000000, or G11C0025000000',
  },
  {
    c: 'G11C 14',
    d: 'Digital stores characterised by arrangements of cells having volatile and non-volatile storage properties for back-up when the power is down',
  },
  {
    c: 'G11C 15',
    d: 'Digital stores in which information comprising one or more characteristic parts is written into the store and in which information is read-out by searching for one or more of these characteristic parts, i.e. associative or content-addressed stores',
  },
  {
    c: 'G11C 16',
    d: 'Erasable programmable read-only memories',
  },
  {
    c: 'G11C 17',
    d: 'Read-only memories programmable only once; Semi-permanent stores, e.g. manually-replaceable information cards',
  },
  {
    c: 'G11C 19',
    d: 'Digital stores in which the information is moved stepwise, e.g. shift registers',
  },
  {
    c: 'G11C 21',
    d: 'Digital stores in which the information circulates',
  },
  {
    c: 'G11C 23',
    d: 'Digital stores characterised by movement of mechanical parts to effect storage, e.g. using balls; Storage elements therefor',
  },
  {
    c: 'G11C 25',
    d: 'Digital stores characterised by the use of flowing media; Storage elements therefor',
  },
  {
    c: 'G11C 27',
    d: 'Electric analogue stores, e.g. for storing instantaneous values',
  },
  {
    c: 'G11C 29',
    d: 'Checking stores for correct operation; Testing stores during standby or offline operation',
  },
  {
    c: 'G11C 99',
    d: 'n.a.',
  },
  {
    c: 'G12',
    d: 'INSTRUMENT DETAILS',
  },
  {
    c: 'G12B',
    d: 'CONSTRUCTIONAL DETAILS OF INSTRUMENTS, OR COMPARABLE DETAILS OF OTHER APPARATUS, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'G12B 1',
    d: 'Sensitive elements capable of producing movement or displacement for purposes not limited to measurement; Associated transmission mechanisms therefor',
  },
  {
    c: 'G12B 3',
    d: 'Details of movements not otherwise provided for',
  },
  {
    c: 'G12B 5',
    d: 'Adjusting position or attitude, e.g. level, of instruments or other apparatus, or of parts thereof; Compensating for the effects of tilting or acceleration, e.g. for optical apparatus',
  },
  {
    c: 'G12B 7',
    d: 'Compensating for the effects of temperature',
  },
  {
    c: 'G12B 9',
    d: 'Housing or supporting of instruments or other apparatus',
  },
  {
    c: 'G12B 11',
    d: 'Indicating elements; Illumination thereof',
  },
  {
    c: 'G12B 13',
    d: 'Calibrating of instruments or apparatus',
  },
  {
    c: 'G12B 15',
    d: 'Cooling',
  },
  {
    c: 'G12B 17',
    d: 'Screening',
  },
  {
    c: 'G16',
    d: 'INFORMATION AND COMMUNICATION TECHNOLOGY [ICT] SPECIALLY ADAPTED FOR SPECIFIC APPLICATION FIELDS',
  },
  {
    c: 'G16B',
    d: 'BIOINFORMATICS, i.e. INFORMATION AND COMMUNICATION TECHNOLOGY [ICT] SPECIALLY ADAPTED FOR GENETIC OR PROTEIN-RELATED DATA PROCESSING IN COMPUTATIONAL MOLECULAR BIOLOGY',
  },
  {
    c: 'G16B 5',
    d: 'ICT specially adapted for modelling or simulations in systems biology, e.g. gene-regulatory networks, protein interaction networks or metabolic networks',
  },
  {
    c: 'G16B 10',
    d: 'ICT specially adapted for evolutionary bioinformatics, e.g. phylogenetic tree construction or analysis',
  },
  {
    c: 'G16B 15',
    d: 'ICT specially adapted for analysing two-dimensional or three-dimensional molecular structures, e.g. structural or functional relations or structure alignment',
  },
  {
    c: 'G16B 20',
    d: 'ICT specially adapted for functional genomics or proteomics, e.g. genotype-phenotype associations',
  },
  {
    c: 'G16B 25',
    d: 'ICT specially adapted for hybridisation; ICT specially adapted for gene or protein expression',
  },
  {
    c: 'G16B 30',
    d: 'ICT specially adapted for sequence analysis involving nucleotides or amino acids',
  },
  {
    c: 'G16B 35',
    d: 'ICT specially adapted for in silico combinatorial libraries of nucleic acids, proteins or peptides',
  },
  {
    c: 'G16B 40',
    d: 'ICT specially adapted for biostatistics; ICT specially adapted for bioinformatics-related machine learning or data mining, e.g. knowledge discovery or pattern finding',
  },
  {
    c: 'G16B 45',
    d: 'ICT specially adapted for bioinformatics-related data visualisation, e.g. displaying of maps or networks',
  },
  {
    c: 'G16B 50',
    d: 'ICT programming tools or database systems specially adapted for bioinformatics',
  },
  {
    c: 'G16B 99',
    d: 'n.a.',
  },
  {
    c: 'G16C',
    d: 'COMPUTATIONAL CHEMISTRY; CHEMOINFORMATICS; COMPUTATIONAL MATERIALS SCIENCE',
  },
  {
    c: 'G16C 10',
    d: 'Computational theoretical chemistry, i.e. ICT specially adapted for theoretical aspects of quantum chemistry, molecular mechanics, molecular dynamics or the like',
  },
  {
    c: 'G16C 20',
    d: 'Chemoinformatics, i.e. ICT specially adapted for the handling of physicochemical or structural data of chemical particles, elements, compounds or mixtures',
  },
  {
    c: 'G16C 60',
    d: 'Computational materials science, i.e. ICT specially adapted for investigating the physical or chemical properties of materials or phenomena associated with their design, synthesis, processing, characterisation or utilisation',
  },
  {
    c: 'G16C 99',
    d: 'n.a.',
  },
  {
    c: 'G16H',
    d: 'HEALTHCARE INFORMATICS, i.e. INFORMATION AND COMMUNICATION TECHNOLOGY [ICT] SPECIALLY ADAPTED FOR THE HANDLING OR PROCESSING OF MEDICAL OR HEALTHCARE DATA',
  },
  {
    c: 'G16H 10',
    d: 'ICT specially adapted for the handling or processing of patient-related medical or healthcare data',
  },
  {
    c: 'G16H 15',
    d: 'ICT specially adapted for medical reports, e.g. generation or transmission thereof',
  },
  {
    c: 'G16H 20',
    d: 'ICT specially adapted for therapies or health-improving plans, e.g. for handling prescriptions, for steering therapy or for monitoring patient compliance',
  },
  {
    c: 'G16H 30',
    d: 'ICT specially adapted for the handling or processing of medical images',
  },
  {
    c: 'G16H 40',
    d: 'ICT specially adapted for the management or administration of healthcare resources or facilities; ICT specially adapted for the management or operation of medical equipment or devices',
  },
  {
    c: 'G16H 50',
    d: 'ICT specially adapted for medical diagnosis, medical simulation or medical data mining; ICT specially adapted for detecting, monitoring or modelling epidemics or pandemics',
  },
  {
    c: 'G16H 70',
    d: 'ICT specially adapted for the handling or processing of medical references',
  },
  {
    c: 'G16H 80',
    d: 'ICT specially adapted for facilitating communication between medical practitioners or patients, e.g. for collaborative diagnosis, therapy or health monitoring',
  },
  {
    c: 'G16Z',
    d: 'INFORMATION AND COMMUNICATION TECHNOLOGY [ICT] SPECIALLY ADAPTED FOR SPECIFIC APPLICATION FIELDS, NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'G16Z 99',
    d: 'n.a.',
  },
  {
    c: 'G21',
    d: 'NUCLEAR PHYSICS; NUCLEAR ENGINEERING',
  },
  {
    c: 'G21B',
    d: 'FUSION REACTORS',
  },
  {
    c: 'G21B 1',
    d: 'Thermonuclear fusion reactors',
  },
  {
    c: 'G21B 3',
    d: 'Low-temperature nuclear fusion reactors, e.g. alleged cold fusion reactors',
  },
  {
    c: 'G21C',
    d: 'NUCLEAR REACTORS',
  },
  {
    c: 'G21C 1',
    d: 'Reactor types',
  },
  {
    c: 'G21C 3',
    d: 'Reactor fuel elements or their assemblies; Selection of substances for use as reactor fuel elements',
  },
  {
    c: 'G21C 5',
    d: 'Moderator or core structure; Selection of materials for use as moderator',
  },
  {
    c: 'G21C 7',
    d: 'Control of nuclear reaction',
  },
  {
    c: 'G21C 9',
    d: 'Emergency protection arrangements structurally associated with the reactor',
  },
  {
    c: 'G21C 11',
    d: 'Shielding structurally associated with the reactor',
  },
  {
    c: 'G21C 13',
    d: 'Pressure vessels; Containment vessels; Containment in general',
  },
  {
    c: 'G21C 15',
    d: 'Cooling arrangements within the pressure vessel containing the core; Selection of specific coolants',
  },
  {
    c: 'G21C 17',
    d: 'Monitoring; Testing',
  },
  {
    c: 'G21C 19',
    d: 'Arrangements for treating, for handling, or for facilitating the handling of, fuel or other materials which are used within the reactor, e.g. within its pressure vessel',
  },
  {
    c: 'G21C 21',
    d: 'Apparatus or processes specially adapted to the manufacture of reactors or parts thereof',
  },
  {
    c: 'G21C 23',
    d: 'Adaptations of reactors to facilitate experimentation or irradiation',
  },
  {
    c: 'G21D',
    d: 'NUCLEAR POWER PLANT',
  },
  {
    c: 'G21D 1',
    d: 'Details of nuclear power plant',
  },
  {
    c: 'G21D 3',
    d: 'Control of nuclear power plant',
  },
  {
    c: 'G21D 5',
    d: 'Arrangements of reactor and engine in which reactor-produced heat is converted into mechanical energy',
  },
  {
    c: 'G21D 7',
    d: 'Arrangements for direct production of electric energy from fusion or fission reactions',
  },
  {
    c: 'G21D 9',
    d: 'Arrangements to provide heat for purposes other than conversion into power, e.g. for heating buildings',
  },
  {
    c: 'G21F',
    d: 'PROTECTION AGAINST X-RADIATION, GAMMA RADIATION, CORPUSCULAR RADIATION OR PARTICLE BOMBARDMENT; TREATING RADIOACTIVELY CONTAMINATED MATERIAL; DECONTAMINATION ARRANGEMENTS THEREFOR',
  },
  {
    c: 'G21F 1',
    d: 'Shielding characterised by the composition of the material',
  },
  {
    c: 'G21F 3',
    d: 'Shielding characterised by its physical form, e.g. granules, or shape of the material',
  },
  {
    c: 'G21F 5',
    d: 'Transportable or portable shielded containers',
  },
  {
    c: 'G21F 7',
    d: 'Shielded cells or rooms',
  },
  {
    c: 'G21F 9',
    d: 'Treating radioactively contaminated material; Decontamination arrangements therefor',
  },
  {
    c: 'G21G',
    d: 'CONVERSION OF CHEMICAL ELEMENTS; RADIOACTIVE SOURCES',
  },
  {
    c: 'G21G 1',
    d: 'Arrangements for converting chemical elements by electromagnetic radiation, corpuscular radiation, or particle bombardment, e.g. producing radioactive isotopes',
  },
  {
    c: 'G21G 4',
    d: 'Radioactive sources',
  },
  {
    c: 'G21G 5',
    d: 'Alleged conversion of chemical elements by chemical reaction',
  },
  {
    c: 'G21G 7',
    d: 'Conversion of chemical elements not provided for in other groups of this subclass',
  },
  {
    c: 'G21H',
    d: 'OBTAINING ENERGY FROM RADIOACTIVE SOURCES; APPLICATIONS OF RADIATION FROM RADIOACTIVE SOURCES, NOT OTHERWISE PROVIDED FOR; UTILISING COSMIC RADIATION',
  },
  {
    c: 'G21H 1',
    d: 'Arrangements for obtaining electrical energy from radioactive sources, e.g. from radioactive isotopes',
  },
  {
    c: 'G21H 3',
    d: 'Arrangements for direct conversion of radiation energy from radioactive sources into forms of energy other than electric energy, e.g. light',
  },
  {
    c: 'G21H 5',
    d: 'Applications of radiation from radioactive sources or arrangements therefor, not otherwise provided for',
  },
  {
    c: 'G21H 7',
    d: 'Use of effects of cosmic radiation',
  },
  {
    c: 'G21J',
    d: 'NUCLEAR EXPLOSIVES; APPLICATIONS THEREOF',
  },
  {
    c: 'G21J 1',
    d: 'Nuclear explosive devices',
  },
  {
    c: 'G21J 3',
    d: 'Peaceful applications of nuclear explosive devices',
  },
  {
    c: 'G21J 5',
    d: 'Detection arrangements for nuclear explosions',
  },
  {
    c: 'G21K',
    d: 'TECHNIQUES FOR HANDLING PARTICLES OR IONISING RADIATION NOT OTHERWISE PROVIDED FOR; IRRADIATION DEVICES; GAMMA RAY OR X-RAY MICROSCOPES',
  },
  {
    c: 'G21K 1',
    d: 'Arrangements for handling particles or ionising radiation, e.g. focusing or moderating',
  },
  {
    c: 'G21K 3',
    d: 'Ionising radiation filters, e.g. X-ray filters',
  },
  {
    c: 'G21K 4',
    d: 'Conversion screens for the conversion of the spatial distribution of particles or ionising radiation into visible images, e.g. fluoroscopic screens',
  },
  {
    c: 'G21K 5',
    d: 'Irradiation devices',
  },
  {
    c: 'G21K 7',
    d: 'Gamma ray or X-ray microscopes',
  },
  {
    c: 'G99',
    d: 'n.a.',
  },
  {
    c: 'G99Z',
    d: 'n.a.',
  },
  {
    c: 'G99Z 99',
    d: 'n.a.',
  },
  {
    c: 'H',
    d: 'ELECTRICITY',
  },
  {
    c: 'H01',
    d: 'BASIC ELECTRIC ELEMENTS',
  },
  {
    c: 'H01B',
    d: 'CABLES; CONDUCTORS; INSULATORS; SELECTION OF MATERIALS FOR THEIR CONDUCTIVE, INSULATING OR DIELECTRIC PROPERTIES',
  },
  {
    c: 'H01B 1',
    d: 'Conductors or conductive bodies characterised by the conductive materials; Selection of materials as conductors',
  },
  {
    c: 'H01B 3',
    d: 'Insulators or insulating bodies characterised by the insulating materials; Selection of materials for their insulating or dielectric properties',
  },
  {
    c: 'H01B 5',
    d: 'Non-insulated conductors or conductive bodies characterised by their form',
  },
  {
    c: 'H01B 7',
    d: 'Insulated conductors or cables characterised by their form',
  },
  {
    c: 'H01B 9',
    d: 'Power cables',
  },
  {
    c: 'H01B 11',
    d: 'Communication cables or conductors',
  },
  {
    c: 'H01B 12',
    d: 'Superconductive or hyperconductive conductors, cables or transmission lines',
  },
  {
    c: 'H01B 13',
    d: 'Apparatus or processes specially adapted for manufacturing conductors or cables',
  },
  {
    c: 'H01B 15',
    d: 'Apparatus or processes for salvaging material from cables',
  },
  {
    c: 'H01B 17',
    d: 'Insulators or insulating bodies characterised by their form',
  },
  {
    c: 'H01B 19',
    d: 'Apparatus or processes specially adapted for manufacturing insulators or insulating bodies',
  },
  {
    c: 'H01C',
    d: 'RESISTORS',
  },
  {
    c: 'H01C 1',
    d: 'Details',
  },
  {
    c: 'H01C 3',
    d: 'Non-adjustable metal resistors made of wire or ribbon, e.g. coiled, woven, or formed as grids',
  },
  {
    c: 'H01C 7',
    d: 'Non-adjustable resistors formed as one or more layers or coatings; Non-adjustable resistors made from powdered conducting material or powdered semi-conducting material with or without insulating material',
  },
  {
    c: 'H01C 8',
    d: 'Non-adjustable resistors consisting of loose powdered or granular conducting, or powdered or granular semi-conducting material',
  },
  {
    c: 'H01C 10',
    d: 'Adjustable resistors',
  },
  {
    c: 'H01C 11',
    d: 'Non-adjustable liquid resistors',
  },
  {
    c: 'H01C 13',
    d: 'Resistors not provided for elsewhere',
  },
  {
    c: 'H01C 17',
    d: 'Apparatus or processes specially adapted for manufacturing resistors',
  },
  {
    c: 'H01F',
    d: 'MAGNETS; INDUCTANCES; TRANSFORMERS; SELECTION OF MATERIALS FOR THEIR MAGNETIC PROPERTIES',
  },
  {
    c: 'H01F 1',
    d: 'Magnets or magnetic bodies characterised by the magnetic materials therefor; Selection of materials for their magnetic properties',
  },
  {
    c: 'H01F 3',
    d: 'Cores, yokes or armatures',
  },
  {
    c: 'H01F 5',
    d: 'Coils',
  },
  {
    c: 'H01F 6',
    d: 'Superconducting magnets; Superconducting coils',
  },
  {
    c: 'H01F 7',
    d: 'Magnets',
  },
  {
    c: 'H01F 10',
    d: 'Thin magnetic films, e.g. of one-domain structure',
  },
  {
    c: 'H01F 13',
    d: 'Apparatus or processes for magnetising or demagnetising',
  },
  {
    c: 'H01F 17',
    d: 'Fixed inductances of the signal type',
  },
  {
    c: 'H01F 19',
    d: 'Fixed transformers or mutual inductances of the signal type',
  },
  {
    c: 'H01F 21',
    d: 'Variable inductances or transformers of the signal type',
  },
  {
    c: 'H01F 27',
    d: 'Details of transformers or inductances, in general',
  },
  {
    c: 'H01F 29',
    d: 'Variable transformers or inductances not covered by group H01F0021000000',
  },
  {
    c: 'H01F 30',
    d: 'Fixed transformers not covered by group H01F0019000000',
  },
  {
    c: 'H01F 36',
    d: 'Transformers with superconductive windings or with windings operating at cryogenic temperatures',
  },
  {
    c: 'H01F 37',
    d: 'Fixed inductances not covered by group H01F0017000000',
  },
  {
    c: 'H01F 38',
    d: 'Adaptations of transformers or inductances for specific applications or functions',
  },
  {
    c: 'H01F 41',
    d: 'Apparatus or processes specially adapted for manufacturing or assembling magnets, inductances or transformers; Apparatus or processes specially adapted for manufacturing materials characterised by their magnetic properties',
  },
  {
    c: 'H01G',
    d: 'CAPACITORS; CAPACITORS, RECTIFIERS, DETECTORS, SWITCHING DEVICES, LIGHT-SENSITIVE OR TEMPERATURE-SENSITIVE DEVICES OF THE ELECTROLYTIC TYPE',
  },
  {
    c: 'H01G 2',
    d: 'Details of capacitors not covered by a single one of groups H01G0004000000-H01G0011000000',
  },
  {
    c: 'H01G 4',
    d: 'Fixed capacitors; Processes of their manufacture',
  },
  {
    c: 'H01G 5',
    d: 'Capacitors in which the capacitance is varied by mechanical means, e.g. by turning a shaft; Processes of their manufacture',
  },
  {
    c: 'H01G 7',
    d: 'Capacitors in which the capacitance is varied by non-mechanical means; Processes of their manufacture',
  },
  {
    c: 'H01G 9',
    d: 'Electrolytic capacitors, rectifiers, detectors, switching devices, light-sensitive or temperature-sensitive devices; Processes of their manufacture',
  },
  {
    c: 'H01G 11',
    d: 'Hybrid capacitors, i.e. capacitors having different positive and negative electrodes; Electric double-layer [EDL] capacitors; Processes for the manufacture thereof or of parts thereof',
  },
  {
    c: 'H01G 13',
    d: 'Apparatus specially adapted for manufacturing capacitors; Processes specially adapted for manufacturing capacitors not provided for in groups H01G0004000000-H01G0011000000',
  },
  {
    c: 'H01G 15',
    d: 'Structural combinations of capacitors or other devices covered by at least two different main groups of this subclass with each other',
  },
  {
    c: 'H01G 17',
    d: 'Structural combinations of capacitors or other devices covered by at least two different main groups of this subclass with other electric elements, not covered by this subclass, e.g. RC combinations',
  },
  {
    c: 'H01H',
    d: 'ELECTRIC SWITCHES; RELAYS; SELECTORS; EMERGENCY PROTECTIVE DEVICES',
  },
  {
    c: 'H01H 1',
    d: 'Electric switches',
  },
  {
    c: 'H01H 3',
    d: 'Mechanisms for operating contacts',
  },
  {
    c: 'H01H 5',
    d: 'Snap-action arrangements, i.e. in which during a single opening operation or a single closing operation energy is first stored and then released to produce or assist the contact movement',
  },
  {
    c: 'H01H 7',
    d: 'Devices for introducing a predetermined time delay between the initiation of the switching operation and the opening or closing of the contacts',
  },
  {
    c: 'H01H 9',
    d: 'Details of switching devices, not covered by groups H01H0001000000-H01H0007000000',
  },
  {
    c: 'H01H 11',
    d: 'Apparatus or processes specially adapted for the manufacture of electric switches',
  },
  {
    c: 'H01H 13',
    d: 'Switches having rectilinearly-movable operating part or parts adapted for pushing or pulling in one direction only, e.g. push-button switch',
  },
  {
    c: 'H01H 15',
    d: 'Switches having rectilinearly-movable operating part or parts adapted for actuation in opposite directions, e.g. slide switch',
  },
  {
    c: 'H01H 17',
    d: 'Switches having flexible operating part adapted only for pulling, e.g. cord, chain',
  },
  {
    c: 'H01H 19',
    d: 'Switches operated by an operating part which is rotatable about a longitudinal axis thereof and which is acted upon directly by a solid body external to the switch, e.g. by a hand',
  },
  {
    c: 'H01H 21',
    d: 'Switches operated by an operating part in the form of a pivotable member acted upon directly by a solid body, e.g. by a hand',
  },
  {
    c: 'H01H 23',
    d: 'Tumbler or rocker switches, i.e. switches characterised by being operated by rocking an operating member in the form of a rocker button',
  },
  {
    c: 'H01H 25',
    d: 'Switches with compound movement of handle or other operating part',
  },
  {
    c: 'H01H 27',
    d: 'Switches operated by a removable member, e.g. key, plug or plate; Switches operated by setting members according to a single predetermined combination out of several possible settings',
  },
  {
    c: 'H01H 29',
    d: 'Switches having at least one liquid contact',
  },
  {
    c: 'H01H 31',
    d: 'Air-break switches for high tension without arc-extinguishing or arc-preventing means',
  },
  {
    c: 'H01H 33',
    d: 'High-tension or heavy-current switches with arc-extinguishing or arc-preventing means',
  },
  {
    c: 'H01H 35',
    d: 'Switches operated by change of a physical condition',
  },
  {
    c: 'H01H 36',
    d: 'Switches actuated by change of magnetic field or of electric field, e.g. by change of relative position of magnet and switch, by shielding',
  },
  {
    c: 'H01H 37',
    d: 'Thermally-actuated switches',
  },
  {
    c: 'H01H 39',
    d: 'Switching devices actuated by an explosion produced within the device and initiated by an electric current',
  },
  {
    c: 'H01H 41',
    d: 'Switches providing a selected number of consecutive operations of the contacts by a single manual actuation of the operating part',
  },
  {
    c: 'H01H 43',
    d: 'Time or time-programme switches providing a choice of time-intervals for executing one or more switching actions and automatically terminating their operation after the programme is completed',
  },
  {
    c: 'H01H 45',
    d: 'Relays',
  },
  {
    c: 'H01H 47',
    d: 'Circuit arrangements not adapted to a particular application of the relay and designed to obtain desired operating characteristics or to provide energising current',
  },
  {
    c: 'H01H 49',
    d: 'Apparatus or processes specially adapted to the manufacture of relays or parts thereof',
  },
  {
    c: 'H01H 50',
    d: 'Details of electromagnetic relays',
  },
  {
    c: 'H01H 51',
    d: 'Electromagnetic relays',
  },
  {
    c: 'H01H 53',
    d: 'Relays using the dynamo-electric effect, i.e. relays in which contacts are opened or closed due to relative movement of current-carrying conductor and magnetic field caused by force of interaction between them',
  },
  {
    c: 'H01H 55',
    d: 'Magnetostrictive relays',
  },
  {
    c: 'H01H 57',
    d: 'Electrostrictive relays; Piezo-electric relays',
  },
  {
    c: 'H01H 59',
    d: 'Electrostatic relays; Electro-adhesion relays',
  },
  {
    c: 'H01H 61',
    d: 'Electrothermal relays',
  },
  {
    c: 'H01H 63',
    d: 'Selectors',
  },
  {
    c: 'H01H 65',
    d: 'Apparatus or processes specially adapted to the manufacture of selector switches or parts thereof',
  },
  {
    c: 'H01H 67',
    d: 'Electrically-operated selector switches',
  },
  {
    c: 'H01H 69',
    d: 'Emergency protective devices',
  },
  {
    c: 'H01H 71',
    d: 'Details of the protective switches or relays covered by groups H01H0073000000-H01H0083000000',
  },
  {
    c: 'H01H 73',
    d: 'Protective overload circuit-breaking switches in which excess current opens the contacts by automatic release of mechanical energy stored by previous operation of a hand reset mechanism',
  },
  {
    c: 'H01H 75',
    d: 'Protective overload circuit-breaking switches in which excess current opens the contacts by automatic release of mechanical energy stored by previous operation of power reset mechanism',
  },
  {
    c: 'H01H 77',
    d: 'Protective overload circuit-breaking switches operated by excess current and requiring separate action for resetting',
  },
  {
    c: 'H01H 79',
    d: 'Protective switches in which excess current causes the closing of contacts, e.g. for short-circuiting the apparatus to be protected',
  },
  {
    c: 'H01H 81',
    d: 'Protective switches in which contacts are normally closed but are repeatedly opened and reclosed as long as a condition causing excess current persists, e.g. for current limiting',
  },
  {
    c: 'H01H 83',
    d: 'Protective switches, e.g. circuit-breaking switches, or protective relays operated by abnormal electrical conditions otherwise than solely by excess current',
  },
  {
    c: 'H01H 85',
    d: 'Protective devices in which the current flows through a part of fusible material and this current is interrupted by displacement of the fusible material when this current becomes excessive',
  },
  {
    c: 'H01H 87',
    d: 'Protective devices in which a current flowing through a liquid or solid is interrupted by the evaporation of the liquid or by the melting and evaporation of the solid when the current becomes excessive, the circuit continuity being reestablished on cooling',
  },
  {
    c: 'H01H 89',
    d: 'Combinations of two or more different basic types of electric switches, relays, selectors and emergency protective devices, not covered by any single one of the other main groups of this subclass',
  },
  {
    c: 'H01J',
    d: 'ELECTRIC DISCHARGE TUBES OR DISCHARGE LAMPS',
  },
  {
    c: 'H01J 1',
    d: 'Details of electrodes, of magnetic control means, of screens, or of the mounting or spacing thereof, common to two or more basic types of discharge tubes or lamps',
  },
  {
    c: 'H01J 3',
    d: 'Details of electron-optical or ion-optical arrangements or of ion traps common to two or more basic types of discharge tubes or lamps',
  },
  {
    c: 'H01J 5',
    d: 'Details relating to vessels or to leading-in conductors common to two or more basic types of discharge tubes or lamps',
  },
  {
    c: 'H01J 7',
    d: 'Details not provided for in groups H01J0001000000-H01J0005000000 and common to two or more basic types of discharge tubes or lamps',
  },
  {
    c: 'H01J 9',
    d: 'Apparatus or processes specially adapted for the manufacture of electric discharge tubes, discharge lamps, or parts thereof; Recovery of material from discharge tubes or lamps',
  },
  {
    c: 'H01J 11',
    d: 'Gas-filled discharge tubes with alternating current induction of the discharge, e.g. AC-PDPs [Alternating Current Plasma Display Panels]',
  },
  {
    c: 'H01J 13',
    d: 'Discharge tubes with liquid-pool cathodes, e.g. metal-vapour rectifying tubes',
  },
  {
    c: 'H01J 15',
    d: 'Gas-filled discharge tubes with gaseous cathodes, e.g. plasma cathodes',
  },
  {
    c: 'H01J 17',
    d: 'Gas-filled discharge tubes with solid cathodes',
  },
  {
    c: 'H01J 19',
    d: 'Details of vacuum tubes of the types covered by group H01J0021000000',
  },
  {
    c: 'H01J 21',
    d: 'Vacuum tubes',
  },
  {
    c: 'H01J 23',
    d: 'Details of transit-time tubes of the types covered by group H01J0025000000',
  },
  {
    c: 'H01J 25',
    d: 'Transit-time tubes, e.g. klystrons, travelling-wave tubes, magnetrons',
  },
  {
    c: 'H01J 27',
    d: 'Ion beam tubes',
  },
  {
    c: 'H01J 29',
    d: 'Details of cathode-ray tubes or of electron-beam tubes of the types covered by group H01J0031000000',
  },
  {
    c: 'H01J 31',
    d: 'Cathode-ray tubes; Electron-beam tubes',
  },
  {
    c: 'H01J 33',
    d: 'Discharge tubes with provision for emergence of electrons or ions from the vessel',
  },
  {
    c: 'H01J 35',
    d: 'X-ray tubes',
  },
  {
    c: 'H01J 37',
    d: 'Discharge tubes with provision for introducing objects or material to be exposed to the discharge, e.g. for the purpose of examination or processing thereof',
  },
  {
    c: 'H01J 40',
    d: 'Photoelectric discharge tubes not involving the ionisation of a gas',
  },
  {
    c: 'H01J 41',
    d: 'Discharge tubes and means integral therewith for measuring gas pressure; Discharge tubes for evacuation by diffusion of ions',
  },
  {
    c: 'H01J 43',
    d: 'Secondary-emission tubes; Electron-multiplier tubes',
  },
  {
    c: 'H01J 45',
    d: 'Discharge tubes functioning as thermionic generators',
  },
  {
    c: 'H01J 47',
    d: 'Tubes for determining the presence, intensity, density or energy of radiation or particles',
  },
  {
    c: 'H01J 49',
    d: 'Particle spectrometers or separator tubes',
  },
  {
    c: 'H01J 61',
    d: 'Discharge lamps',
  },
  {
    c: 'H01J 63',
    d: 'Cathode-ray or electron-stream lamps',
  },
  {
    c: 'H01J 65',
    d: 'Lamps without any electrode inside the vessel; Lamps with at least one main electrode outside the vessel',
  },
  {
    c: 'H01J 99',
    d: 'n.a.',
  },
  {
    c: 'H01K',
    d: 'ELECTRIC INCANDESCENT LAMPS',
  },
  {
    c: 'H01K 1',
    d: 'Details',
  },
  {
    c: 'H01K 3',
    d: 'Apparatus or processes adapted to the manufacture, installing, removal or maintenance of incandescent lamps or parts thereof',
  },
  {
    c: 'H01K 5',
    d: 'Lamps for general lighting',
  },
  {
    c: 'H01K 7',
    d: 'Lamps for purposes other than general lighting',
  },
  {
    c: 'H01K 9',
    d: 'Lamps having two or more incandescent bodies separately heated',
  },
  {
    c: 'H01K 11',
    d: 'Lamps having an incandescent body which is not conductively heated, e.g. heated inductively, heated by electronic discharge',
  },
  {
    c: 'H01K 13',
    d: 'Lamps having an incandescent body which is substantially non-conductive until heated, e.g. Nernst lamp',
  },
  {
    c: 'H01L',
    d: 'SEMICONDUCTOR DEVICES; ELECTRIC SOLID STATE DEVICES NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'H01L 21',
    d: 'Processes or apparatus specially adapted for the manufacture or treatment of semiconductor or solid state devices or of parts thereof',
  },
  {
    c: 'H01L 23',
    d: 'Details of semiconductor or other solid state devices',
  },
  {
    c: 'H01L 25',
    d: 'Assemblies consisting of a plurality of individual semiconductor or other solid state devices',
  },
  {
    c: 'H01L 27',
    d: 'Devices consisting of a plurality of semiconductor or other solid-state components formed in or on a common substrate',
  },
  {
    c: 'H01L 29',
    d: 'Semiconductor devices specially adapted for rectifying, amplifying, oscillating or switching and having at least one potential-jump barrier or surface barrier; Capacitors or resistors with at least one potential-jump barrier or surface barrier, e.g. PN-junction depletion layer or carrier concentration layer; Details of semiconductor bodies or of electrodes thereof',
  },
  {
    c: 'H01L 31',
    d: 'Semiconductor devices sensitive to infra-red radiation, light, electromagnetic radiation of shorter wavelength, or corpuscular radiation and specially adapted either for the conversion of the energy of such radiation into electrical energy or for the control of electrical energy by such radiation; Processes or apparatus specially adapted for the manufacture or treatment thereof or of parts thereof; Details thereof',
  },
  {
    c: 'H01L 33',
    d: 'Semiconductor devices with at least one potential-jump barrier or surface barrier specially adapted for light emission; Processes or apparatus specially adapted for the manufacture or treatment thereof or of parts thereof; Details thereof',
  },
  {
    c: 'H01L 35',
    d: 'Thermoelectric devices comprising a junction of dissimilar materials, i.e. exhibiting Seebeck or Peltier effect with or without other thermoelectric effects or thermomagnetic effects; Processes or apparatus specially adapted for the manufacture or treatment thereof or of parts thereof; Details thereof',
  },
  {
    c: 'H01L 37',
    d: 'Thermoelectric devices without a junction of dissimilar materials; Thermomagnetic devices, e.g. using Nernst-Ettinghausen effect; Processes or apparatus specially adapted for the manufacture or treatment thereof or of parts thereof',
  },
  {
    c: 'H01L 39',
    d: 'Devices using superconductivity or hyperconductivity; Processes or apparatus specially adapted for the manufacture or treatment thereof or of parts thereof',
  },
  {
    c: 'H01L 41',
    d: 'Piezo-electric devices in general; Electrostrictive devices in general; Magnetostrictive devices in general; Processes or apparatus specially adapted for the manufacture or treatment thereof or of parts thereof; Details thereof',
  },
  {
    c: 'H01L 43',
    d: 'Devices using galvano-magnetic or similar magnetic effects; Processes or apparatus specially adapted for the manufacture or treatment thereof or of parts thereof',
  },
  {
    c: 'H01L 45',
    d: 'Solid state devices specially adapted for rectifying, amplifying, oscillating, or switching without a potential-jump barrier or surface barrier, e.g. dielectric triodes; Ovshinsky-effect devices; Processes or apparatus specially adapted for the manufacture or treatment thereof or of parts thereof',
  },
  {
    c: 'H01L 47',
    d: 'Bulk negative resistance effect devices, e.g. Gunn-effect devices; Processes or apparatus specially adapted for the manufacture or treatment thereof or of parts thereof',
  },
  {
    c: 'H01L 49',
    d: 'Solid state devices not provided for in groups H01L0027000000-H01L0047000000 and H01L0051000000 and not provided for in any other subclass; Processes or apparatus specially adapted for the manufacture or treatment thereof or of parts thereof',
  },
  {
    c: 'H01L 51',
    d: 'Solid state devices using organic materials as the active part, or using a combination of organic materials with other materials as the active part; Processes or apparatus specially adapted for the manufacture or treatment of such devices, or of parts thereof',
  },
  {
    c: 'H01M',
    d: 'PROCESSES OR MEANS, e.g. BATTERIES, FOR THE DIRECT CONVERSION OF CHEMICAL ENERGY INTO ELECTRICAL ENERGY',
  },
  {
    c: 'H01M 2',
    d: 'Constructional details, or processes of manufacture, of the non-active parts',
  },
  {
    c: 'H01M 4',
    d: 'Electrodes',
  },
  {
    c: 'H01M 6',
    d: 'Primary cells; Manufacture thereof',
  },
  {
    c: 'H01M 8',
    d: 'Fuel cells; Manufacture thereof',
  },
  {
    c: 'H01M 10',
    d: 'Secondary cells; Manufacture thereof',
  },
  {
    c: 'H01M 12',
    d: 'Hybrid cells; Manufacture thereof',
  },
  {
    c: 'H01M 14',
    d: 'Electrochemical current or voltage generators not provided for in groups H01M0006000000-H01M0012000000; Manufacture thereof',
  },
  {
    c: 'H01M 16',
    d: 'Structural combinations of different types of electrochemical generators',
  },
  {
    c: 'H01P',
    d: 'WAVEGUIDES; RESONATORS, LINES OR OTHER DEVICES OF THE WAVEGUIDE TYPE',
  },
  {
    c: 'H01P 1',
    d: 'Auxiliary devices',
  },
  {
    c: 'H01P 3',
    d: 'Waveguides; Transmission lines of the waveguide type',
  },
  {
    c: 'H01P 5',
    d: 'Coupling devices of the waveguide type',
  },
  {
    c: 'H01P 7',
    d: 'Resonators of the waveguide type',
  },
  {
    c: 'H01P 9',
    d: 'Delay lines of the waveguide type',
  },
  {
    c: 'H01P 11',
    d: 'Apparatus or processes specially adapted for manufacturing waveguides or resonators, lines, or other devices of the waveguide type',
  },
  {
    c: 'H01Q',
    d: 'ANTENNAS, i.e. RADIO AERIALS',
  },
  {
    c: 'H01Q 1',
    d: 'Details of, or arrangements associated with, antennas',
  },
  {
    c: 'H01Q 3',
    d: 'Arrangements for changing or varying the orientation or the shape of the directional pattern of the waves radiated from an antenna or antenna system',
  },
  {
    c: 'H01Q 5',
    d: 'Arrangements for simultaneous operation of antennas on two or more different wavebands, e.g. dual-band or multi-band arrangements',
  },
  {
    c: 'H01Q 7',
    d: 'Loop antennas with a substantially uniform current distribution around the loop and having a directional radiation pattern in a plane perpendicular to the plane of the loop',
  },
  {
    c: 'H01Q 9',
    d: 'Electrically-short antennas having dimensions not more than twice the operating wavelength and consisting of conductive active radiating elements',
  },
  {
    c: 'H01Q 11',
    d: 'Electrically-long antennas having dimensions more than twice the shortest operating wavelength and consisting of conductive active radiating elements',
  },
  {
    c: 'H01Q 13',
    d: 'Waveguide horns or mouths; Slot antennas; Leaky-waveguide antennas; Equivalent structures causing radiation along the transmission path of a guided wave',
  },
  {
    c: 'H01Q 15',
    d: 'Devices for reflection, refraction, diffraction or polarisation of waves radiated from an antenna, e.g. quasi-optical devices',
  },
  {
    c: 'H01Q 17',
    d: 'Devices for absorbing waves radiated from an antenna; Combinations of such devices with active antenna elements or systems',
  },
  {
    c: 'H01Q 19',
    d: 'Combinations of primary active antenna elements and units with secondary devices, e.g. with quasi-optical devices, for giving the antenna a desired directional characteristic',
  },
  {
    c: 'H01Q 21',
    d: 'Antenna arrays or systems',
  },
  {
    c: 'H01Q 23',
    d: 'Antennas with active circuits or circuit elements integrated within them or attached to them',
  },
  {
    c: 'H01Q 25',
    d: 'Antennas or antenna systems providing at least two radiating patterns',
  },
  {
    c: 'H01R',
    d: 'ELECTRICALLY-CONDUCTIVE CONNECTIONS; STRUCTURAL ASSOCIATIONS OF A PLURALITY OF MUTUALLY-INSULATED ELECTRICAL CONNECTING ELEMENTS; COUPLING DEVICES; CURRENT COLLECTORS',
  },
  {
    c: 'H01R 3',
    d: 'Electrically-conductive connections not otherwise provided for',
  },
  {
    c: 'H01R 4',
    d: 'Electrically-conductive connections between two or more conductive members in direct contact, i.e. touching one another; Means for effecting or maintaining such contact; Electrically-conductive connections having two or more spaced connecting locations for conductors and using contact members penetrating insulation',
  },
  {
    c: 'H01R 9',
    d: 'Structural associations of a plurality of mutually-insulated electrical connecting elements, e.g. terminal strips or terminal blocks; Terminals or binding posts mounted upon a base or in a case; Bases therefor',
  },
  {
    c: 'H01R 11',
    d: 'Individual connecting elements providing two or more spaced connecting locations for conductive members which are, or may be, thereby interconnected, e.g. end pieces for wires or cables supported by the wire or cable and having means for facilitating electrical connection to some other wire, terminal, or conductive member, blocks of binding posts',
  },
  {
    c: 'H01R 12',
    d: 'Structural associations of a plurality of mutually-insulated electrical connecting elements, specially adapted for printed circuits, e.g. printed circuit boards',
  },
  {
    c: 'H01R 13',
    d: 'Details of coupling devices of the kinds covered by groups H01R0012700000 or H01R0024000000-H01R0033000000',
  },
  {
    c: 'H01R 24',
    d: 'Two-part coupling devices, or either of their cooperating parts, characterised by their overall structure',
  },
  {
    c: 'H01R 25',
    d: 'Coupling parts adapted for simultaneous co-operation with two or more identical counterparts, e.g. for distributing energy to two or more circuits',
  },
  {
    c: 'H01R 27',
    d: 'Coupling parts adapted for co-operation with two or more dissimilar counterparts',
  },
  {
    c: 'H01R 29',
    d: 'Coupling parts for selective co-operation with a counterpart in different ways to establish different circuits, e.g. for voltage selection, for series/parallel selection',
  },
  {
    c: 'H01R 31',
    d: 'Coupling parts supported only by co-operation with counterpart',
  },
  {
    c: 'H01R 33',
    d: 'Coupling devices specially adapted for supporting apparatus and having one part acting as a holder providing support and electrical connection via a counterpart which is structurally associated with the apparatus, e.g. lamp holders; Separate parts thereof',
  },
  {
    c: 'H01R 35',
    d: 'Flexible or turnable line connectors',
  },
  {
    c: 'H01R 39',
    d: 'Rotary current collectors, distributors or interrupters',
  },
  {
    c: 'H01R 41',
    d: 'Non-rotary current collectors for maintaining contact between moving and stationary parts of an electric circuit',
  },
  {
    c: 'H01R 43',
    d: 'Apparatus or processes specially adapted for manufacturing, assembling, maintaining, or repairing of line connectors or current collectors or for joining electric conductors',
  },
  {
    c: 'H01R 101',
    d: 'Indexing scheme associated with group H01R0024000000, relating to the number of poles in a two-part coupling device.',
  },
  {
    c: 'H01R 103',
    d: 'Two poles',
  },
  {
    c: 'H01R 105',
    d: 'Three poles',
  },
  {
    c: 'H01R 107',
    d: 'Four or more poles',
  },
  {
    c: 'H01S',
    d: 'DEVICES USING THE PROCESS OF LIGHT AMPLIFICATION BY STIMULATED EMISSION OF RADIATION [LASER] TO AMPLIFY OR GENERATE LIGHT; DEVICES USING STIMULATED EMISSION OF ELECTROMAGNETIC RADIATION IN WAVE RANGES OTHER THAN OPTICAL',
  },
  {
    c: 'H01S 1',
    d: 'Masers, i.e. devices using stimulated emission of electromagnetic radiation in the microwave range',
  },
  {
    c: 'H01S 3',
    d: 'Lasers, i.e. devices using stimulated emission of electromagnetic radiation in the infrared, visible or ultraviolet wave range',
  },
  {
    c: 'H01S 4',
    d: 'Devices using stimulated emission of electromagnetic radiation in wave ranges other than those covered by groups H01S0001000000, H01S0003000000 or H01S0005000000, e.g. phonon masers, X-ray lasers or gamma-ray lasers',
  },
  {
    c: 'H01S 5',
    d: 'Semiconductor lasers',
  },
  {
    c: 'H01T',
    d: 'SPARK GAPS; OVERVOLTAGE ARRESTERS USING SPARK GAPS; SPARKING PLUGS; CORONA DEVICES; GENERATING IONS TO BE INTRODUCED INTO NON-ENCLOSED GASES',
  },
  {
    c: 'H01T 1',
    d: 'Details of spark gaps',
  },
  {
    c: 'H01T 2',
    d: 'Spark gaps comprising auxiliary triggering means',
  },
  {
    c: 'H01T 4',
    d: 'Overvoltage arresters using spark gaps',
  },
  {
    c: 'H01T 7',
    d: 'Rotary spark gaps, i.e. devices having one or more rotating electrodes',
  },
  {
    c: 'H01T 9',
    d: 'Spark gaps specially adapted for generating oscillations',
  },
  {
    c: 'H01T 11',
    d: 'Spark gaps specially adapted as rectifiers',
  },
  {
    c: 'H01T 13',
    d: 'Sparking plugs',
  },
  {
    c: 'H01T 14',
    d: 'Spark gaps not provided for in groups H01T0002000000-H01T0013000000',
  },
  {
    c: 'H01T 15',
    d: 'Circuits specially adapted for spark gaps, e.g. ignition circuits',
  },
  {
    c: 'H01T 19',
    d: 'Devices providing for corona discharge',
  },
  {
    c: 'H01T 21',
    d: 'Apparatus or processes specially adapted for the manufacture or maintenance of spark gaps or sparking plugs',
  },
  {
    c: 'H01T 23',
    d: 'Apparatus for generating ions to be introduced into non-enclosed gases, e.g. into the atmosphere',
  },
  {
    c: 'H02',
    d: 'GENERATION, CONVERSION, OR DISTRIBUTION OF ELECTRIC POWER',
  },
  {
    c: 'H02B',
    d: 'BOARDS, SUBSTATIONS, OR SWITCHING ARRANGEMENTS FOR THE SUPPLY OR DISTRIBUTION OF ELECTRIC POWER',
  },
  {
    c: 'H02B 1',
    d: 'Frameworks, boards, panels, desks, casings; Details of substations or switching arrangements',
  },
  {
    c: 'H02B 3',
    d: 'Apparatus specially adapted for the manufacture, assembly, or maintenance of boards or switchgear',
  },
  {
    c: 'H02B 5',
    d: 'Non-enclosed substations; Substations with enclosed and non-enclosed equipment',
  },
  {
    c: 'H02B 7',
    d: 'Enclosed substations, e.g. compact substations',
  },
  {
    c: 'H02B 11',
    d: 'Switchgear having carriage withdrawable for isolation',
  },
  {
    c: 'H02B 13',
    d: 'Arrangement of switchgear in which switches are enclosed in, or structurally associated with, a casing, e.g. cubicle',
  },
  {
    c: 'H02B 15',
    d: 'Supervisory desks or panels for centralised control or display',
  },
  {
    c: 'H02B 99',
    d: 'n.a.',
  },
  {
    c: 'H02G',
    d: 'INSTALLATION OF ELECTRIC CABLES OR LINES, OR OF COMBINED OPTICAL AND ELECTRIC CABLES OR LINES',
  },
  {
    c: 'H02G 1',
    d: 'Methods or apparatus specially adapted for installing, maintaining, repairing, or dismantling electric cables or lines',
  },
  {
    c: 'H02G 3',
    d: 'Installations of electric cables or lines or protective tubing therefor in or on buildings, equivalent structures or vehicles',
  },
  {
    c: 'H02G 5',
    d: 'Installations of bus-bars',
  },
  {
    c: 'H02G 7',
    d: 'Overhead installations of electric lines or cables',
  },
  {
    c: 'H02G 9',
    d: 'Installations of electric cables or lines in or on the ground or water',
  },
  {
    c: 'H02G 11',
    d: 'Arrangements of electric cables or lines between relatively-movable parts',
  },
  {
    c: 'H02G 13',
    d: 'Installations of lightning conductors; Fastening thereof to supporting structure',
  },
  {
    c: 'H02G 15',
    d: 'Cable fittings',
  },
  {
    c: 'H02H',
    d: 'EMERGENCY PROTECTIVE CIRCUIT ARRANGEMENTS',
  },
  {
    c: 'H02H 1',
    d: 'Details of emergency protective circuit arrangements',
  },
  {
    c: 'H02H 3',
    d: 'Emergency protective circuit arrangements for automatic disconnection directly responsive to an undesired change from normal electric working condition, with or without subsequent reconnection',
  },
  {
    c: 'H02H 5',
    d: 'Emergency protective circuit arrangements for automatic disconnection directly responsive to an undesired change from normal non-electric working conditions with or without subsequent reconnection',
  },
  {
    c: 'H02H 6',
    d: 'Emergency protective circuit arrangements responsive to undesired changes from normal non-electric working conditions using simulators of the apparatus being protected, e.g. using thermal images',
  },
  {
    c: 'H02H 7',
    d: 'Emergency protective circuit arrangements specially adapted for specific types of electric machines or apparatus or for sectionalised protection of cable or line systems, and effecting automatic switching in the event of an undesired change from normal working conditions',
  },
  {
    c: 'H02H 9',
    d: 'Emergency protective circuit arrangements for limiting excess current or voltage without disconnection',
  },
  {
    c: 'H02H 11',
    d: 'Emergency protective circuit arrangements for preventing the switching-on in case an undesired electric working condition might result',
  },
  {
    c: 'H02H 99',
    d: 'n.a.',
  },
  {
    c: 'H02J',
    d: 'CIRCUIT ARRANGEMENTS OR SYSTEMS FOR SUPPLYING OR DISTRIBUTING ELECTRIC POWER; SYSTEMS FOR STORING ELECTRIC ENERGY',
  },
  {
    c: 'H02J 1',
    d: 'Circuit arrangements for dc mains or dc distribution networks',
  },
  {
    c: 'H02J 3',
    d: 'Circuit arrangements for ac mains or ac distribution networks',
  },
  {
    c: 'H02J 4',
    d: 'Circuit arrangements for mains or distribution networks not specified as ac or dc',
  },
  {
    c: 'H02J 5',
    d: 'Circuit arrangements for transfer of electric power between ac networks and dc networks',
  },
  {
    c: 'H02J 7',
    d: 'Circuit arrangements for charging or depolarising batteries or for supplying loads from batteries',
  },
  {
    c: 'H02J 9',
    d: 'Circuit arrangements for emergency or stand-by power supply, e.g. for emergency lighting',
  },
  {
    c: 'H02J 11',
    d: 'Circuit arrangements for providing service supply to auxiliaries of stations in which electric power is generated, distributed or converted',
  },
  {
    c: 'H02J 13',
    d: 'Circuit arrangements for providing remote indication of network conditions, e.g. an instantaneous record of the open or closed condition of each circuitbreaker in the network; Circuit arrangements for providing remote control of switching means in a power distribution network, e.g. switching in and out of current consumers by using a pulse code signal carried by the network',
  },
  {
    c: 'H02J 15',
    d: 'Systems for storing electric energy',
  },
  {
    c: 'H02J 50',
    d: 'Circuit arrangements or systems for wireless supply or distribution of electric power',
  },
  {
    c: 'H02K',
    d: 'DYNAMO-ELECTRIC MACHINES',
  },
  {
    c: 'H02K 1',
    d: 'Details of the magnetic circuit',
  },
  {
    c: 'H02K 3',
    d: 'Details of windings',
  },
  {
    c: 'H02K 5',
    d: 'Casings; Enclosures; Supports',
  },
  {
    c: 'H02K 7',
    d: 'Arrangements for handling mechanical energy structurally associated with dynamo-electric machines, e.g. structural association with mechanical driving motors or auxiliary dynamo-electric machines',
  },
  {
    c: 'H02K 9',
    d: 'Arrangements for cooling or ventilating',
  },
  {
    c: 'H02K 11',
    d: 'Structural association of dynamo-electric machines with electric components or with devices for shielding, monitoring or protection',
  },
  {
    c: 'H02K 13',
    d: 'Structural associations of current collectors with motors or generators, e.g. brush mounting plates or connections to windings',
  },
  {
    c: 'H02K 15',
    d: 'Methods or apparatus specially adapted for manufacturing, assembling, maintaining or repairing of dynamo-electric machines',
  },
  {
    c: 'H02K 16',
    d: 'Machines with more than one rotor or stator',
  },
  {
    c: 'H02K 17',
    d: 'Asynchronous induction motors; Asynchronous induction generators',
  },
  {
    c: 'H02K 19',
    d: 'Synchronous motors or generators',
  },
  {
    c: 'H02K 21',
    d: 'Synchronous motors having permanent magnets; Synchronous generators having permanent magnets',
  },
  {
    c: 'H02K 23',
    d: 'DC commutator motors or generators having mechanical commutator; Universal AC/DC commutator motors',
  },
  {
    c: 'H02K 24',
    d: 'Machines adapted for the instantaneous transmission or reception of the angular displacement of rotating parts, e.g. synchro, selsyn',
  },
  {
    c: 'H02K 25',
    d: 'DC interrupter motors or generators',
  },
  {
    c: 'H02K 26',
    d: 'Machines adapted to function as torque motors, i.e. to exert a torque when stalled',
  },
  {
    c: 'H02K 27',
    d: 'AC commutator motors or generators having mechanical commutator',
  },
  {
    c: 'H02K 29',
    d: 'Motors or generators having non-mechanical commutating devices, e.g. discharge tubes or semiconductor devices',
  },
  {
    c: 'H02K 31',
    d: 'Acyclic motors or generators, i.e. DC machines having drum or disc armatures with continuous current collectors',
  },
  {
    c: 'H02K 33',
    d: 'Motors with reciprocating, oscillating or vibrating magnet, armature or coil system',
  },
  {
    c: 'H02K 35',
    d: 'Generators with reciprocating, oscillating or vibrating coil system, magnet, armature or other part of the magnetic circuit',
  },
  {
    c: 'H02K 37',
    d: 'Motors with rotor rotating step by step and without interrupter or commutator driven by the rotor, e.g. stepping motors',
  },
  {
    c: 'H02K 39',
    d: 'Generators specially adapted for producing a desired non-sinusoidal waveform',
  },
  {
    c: 'H02K 41',
    d: 'Propulsion systems in which a rigid body is moved along a path due to dynamo-electric interaction between the body and a magnetic field travelling along the path',
  },
  {
    c: 'H02K 44',
    d: 'Machines in which the dynamo-electric interaction between a plasma or flow of conductive liquid or of fluid-borne conductive or magnetic particles and a coil system or magnetic field converts energy of mass flow into electrical energy or vice versa',
  },
  {
    c: 'H02K 47',
    d: 'Dynamo-electric converters',
  },
  {
    c: 'H02K 49',
    d: 'Dynamo-electric clutches; Dynamo-electric brakes',
  },
  {
    c: 'H02K 51',
    d: 'Dynamo-electric gears, i.e. dynamo-electric means for transmitting mechanical power from a driving shaft to a driven shaft and comprising structurally interrelated motor and generator parts',
  },
  {
    c: 'H02K 53',
    d: 'Alleged dynamo-electric perpetua mobilia',
  },
  {
    c: 'H02K 55',
    d: 'Dynamo-electric machines having windings operating at cryogenic temperatures',
  },
  {
    c: 'H02K 99',
    d: 'n.a.',
  },
  {
    c: 'H02M',
    d: 'APPARATUS FOR CONVERSION BETWEEN AC AND AC, BETWEEN AC AND DC, OR BETWEEN DC AND DC, AND FOR USE WITH MAINS OR SIMILAR POWER SUPPLY SYSTEMS; CONVERSION OF DC OR AC INPUT POWER INTO SURGE OUTPUT POWER; CONTROL OR REGULATION THEREOF',
  },
  {
    c: 'H02M 1',
    d: 'Details of apparatus for conversion',
  },
  {
    c: 'H02M 3',
    d: 'Conversion of dc power input into dc power output',
  },
  {
    c: 'H02M 5',
    d: 'Conversion of ac power input into ac power output, e.g. for change of voltage, for change of frequency, for change of number of phases',
  },
  {
    c: 'H02M 7',
    d: 'Conversion of ac power input into dc power output; Conversion of dc power input into ac power output',
  },
  {
    c: 'H02M 9',
    d: 'Conversion of dc or ac input power into surge output power',
  },
  {
    c: 'H02M 11',
    d: 'Power conversion systems not covered by the other groups of this subclass',
  },
  {
    c: 'H02N',
    d: 'ELECTRIC MACHINES NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'H02N 1',
    d: 'Electrostatic generators or motors using a solid moving electrostatic charge carrier',
  },
  {
    c: 'H02N 2',
    d: 'Electric machines in general using piezo-electric effect, electrostriction or magnetostriction',
  },
  {
    c: 'H02N 3',
    d: 'Generators in which thermal or kinetic energy is converted into electrical energy by ionisation of a fluid and removal of the charge therefrom',
  },
  {
    c: 'H02N 10',
    d: 'Electric motors using thermal effects',
  },
  {
    c: 'H02N 11',
    d: 'Generators or motors not provided for elsewhere; Alleged perpetua mobilia obtained by electric or magnetic means',
  },
  {
    c: 'H02N 13',
    d: 'Clutches or holding devices using electrostatic attraction, e.g. using Johnson-Rahbek effect',
  },
  {
    c: 'H02N 15',
    d: 'Holding or levitation devices using magnetic attraction or repulsion, not otherwise provided for',
  },
  {
    c: 'H02N 99',
    d: 'n.a.',
  },
  {
    c: 'H02P',
    d: 'CONTROL OR REGULATION OF ELECTRIC MOTORS, ELECTRIC GENERATORS OR DYNAMO-ELECTRIC CONVERTERS; CONTROLLING TRANSFORMERS, REACTORS OR CHOKE COILS',
  },
  {
    c: 'H02P 1',
    d: 'Arrangements for starting electric motors or dynamo-electric converters',
  },
  {
    c: 'H02P 3',
    d: 'Arrangements for stopping or slowing electric motors, generators, or dynamo-electric converters',
  },
  {
    c: 'H02P 4',
    d: 'Arrangements specially adapted for regulating or controlling the speed or torque of electric motors that can be connected to two or more different electric power supplies',
  },
  {
    c: 'H02P 5',
    d: 'Arrangements specially adapted for regulating or controlling the speed or torque of two or more electric motors',
  },
  {
    c: 'H02P 6',
    d: 'Arrangements for controlling synchronous motors or other dynamo-electric motors using electronic commutation dependent on the rotor position; Electronic commutators therefor',
  },
  {
    c: 'H02P 7',
    d: 'Arrangements for regulating or controlling the speed or torque of electric DC motors',
  },
  {
    c: 'H02P 8',
    d: 'Arrangements for controlling dynamo-electric motors rotating step by step',
  },
  {
    c: 'H02P 9',
    d: 'Arrangements for controlling electric generators for the purpose of obtaining a desired output',
  },
  {
    c: 'H02P 11',
    d: 'Arrangements for controlling dynamo-electric converters',
  },
  {
    c: 'H02P 13',
    d: 'Arrangements for controlling transformers, reactors or choke coils, for the purpose of obtaining a desired output',
  },
  {
    c: 'H02P 15',
    d: 'Arrangements for controlling dynamo-electric brakes or clutches',
  },
  {
    c: 'H02P 17',
    d: 'Arrangements for controlling dynamo-electric gears',
  },
  {
    c: 'H02P 21',
    d: 'Arrangements or methods for the control of electric machines by vector control, e.g. by control of field orientation',
  },
  {
    c: 'H02P 23',
    d: 'Arrangements or methods for the control of AC motors characterised by a control method other than vector control',
  },
  {
    c: 'H02P 25',
    d: 'Arrangements or methods for the control of AC motors characterised by the kind of AC motor or by structural details',
  },
  {
    c: 'H02P 27',
    d: 'Arrangements or methods for the control of AC motors characterised by the kind of supply voltage',
  },
  {
    c: 'H02P 29',
    d: 'Arrangements for regulating or controlling electric motors, appropriate for both AC and DC motors',
  },
  {
    c: 'H02P 31',
    d: 'Arrangements for regulating or controlling electric motors not provided for in groups H02P0001000000-H02P0005000000, H02P0007000000 or H02P0021000000-H02P0029000000',
  },
  {
    c: 'H02P 101',
    d: 'Indexing scheme associated with groups relating to the arrangements for controlling electric generators',
  },
  {
    c: 'H02P 103',
    d: 'Controlling arrangements characterised by the type of generator',
  },
  {
    c: 'H02S',
    d: 'GENERATION OF ELECTRIC POWER BY CONVERSION OF INFRA-RED RADIATION, VISIBLE LIGHT OR ULTRAVIOLET LIGHT, e.g. USING PHOTOVOLTAIC [PV] MODULES',
  },
  {
    c: 'H02S 10',
    d: 'PV power plants; Combinations of PV energy systems with other systems for the generation of electric power',
  },
  {
    c: 'H02S 20',
    d: 'Supporting structures for PV modules',
  },
  {
    c: 'H02S 30',
    d: 'Structural details of PV modules other than those related to light conversion',
  },
  {
    c: 'H02S 40',
    d: 'Components or accessories in combination with PV modules, not provided for in groups H02S0010000000-H02S0030000000',
  },
  {
    c: 'H02S 50',
    d: 'Monitoring or testing of PV systems, e.g. load balancing or fault identification',
  },
  {
    c: 'H02S 99',
    d: 'n.a.',
  },
  {
    c: 'H03',
    d: 'BASIC ELECTRONIC CIRCUITRY',
  },
  {
    c: 'H03B',
    d: 'GENERATION OF OSCILLATIONS, DIRECTLY OR BY FREQUENCY-CHANGING, BY CIRCUITS EMPLOYING ACTIVE ELEMENTS WHICH OPERATE IN A NON-SWITCHING MANNER; GENERATION OF NOISE BY SUCH CIRCUITS',
  },
  {
    c: 'H03B 1',
    d: 'Details',
  },
  {
    c: 'H03B 5',
    d: 'Generation of oscillations using amplifier with regenerative feedback from output to input',
  },
  {
    c: 'H03B 7',
    d: 'Generation of oscillations using active element having a negative resistance between two of its electrodes',
  },
  {
    c: 'H03B 9',
    d: 'Generation of oscillations using transit-time effects',
  },
  {
    c: 'H03B 11',
    d: 'Generation of oscillations using a shock-excited tuned circuit',
  },
  {
    c: 'H03B 13',
    d: 'Generation of oscillations using deflection of electron beam in a cathode-ray tube',
  },
  {
    c: 'H03B 15',
    d: 'Generation of oscillations using galvano-magnetic devices, e.g. Hall-effect devices, devices using spin transfer effects, devices using giant magnetoresistance, or using super-conductivity effects',
  },
  {
    c: 'H03B 17',
    d: 'Generation of oscillations using a radiation source and a detector',
  },
  {
    c: 'H03B 19',
    d: 'Generation of oscillations by non-regenerative frequency multiplication or division of a signal from a separate source',
  },
  {
    c: 'H03B 21',
    d: 'Generation of oscillations by combining unmodulated signals of different frequencies',
  },
  {
    c: 'H03B 23',
    d: 'Generation of oscillations periodically swept over a predetermined frequency range',
  },
  {
    c: 'H03B 25',
    d: 'Simultaneous generation by a free-running oscillator of oscillations having different frequencies',
  },
  {
    c: 'H03B 27',
    d: 'Generation of oscillations providing a plurality of outputs of the same frequency but differing in phase, other than merely two anti-phase outputs',
  },
  {
    c: 'H03B 28',
    d: 'Generation of oscillations by methods not covered by groups H03B0005000000-H03B0027000000, including modification of the waveform to produce sinusoidal oscillations',
  },
  {
    c: 'H03B 29',
    d: 'Generation of noise currents and voltages',
  },
  {
    c: 'H03C',
    d: 'MODULATION',
  },
  {
    c: 'H03C 1',
    d: 'Amplitude modulation',
  },
  {
    c: 'H03C 3',
    d: 'Angle modulation',
  },
  {
    c: 'H03C 5',
    d: 'Amplitude modulation and angle modulation produced simultaneously or at will by the same modulating signal',
  },
  {
    c: 'H03C 7',
    d: 'Modulating electromagnetic waves',
  },
  {
    c: 'H03C 99',
    d: 'n.a.',
  },
  {
    c: 'H03D',
    d: 'DEMODULATION OR TRANSFERENCE OF MODULATION FROM ONE CARRIER TO ANOTHER',
  },
  {
    c: 'H03D 1',
    d: 'Demodulation of amplitude-modulated oscillations',
  },
  {
    c: 'H03D 3',
    d: 'Demodulation of angle-modulated oscillations',
  },
  {
    c: 'H03D 5',
    d: 'Circuits for demodulating amplitude-modulated or angle-modulated oscillations at will',
  },
  {
    c: 'H03D 7',
    d: 'Transference of modulation from one carrier to another, e.g. frequency-changing',
  },
  {
    c: 'H03D 9',
    d: 'Demodulation or transference of modulation of modulated electromagnetic waves',
  },
  {
    c: 'H03D 11',
    d: 'Super-regenerative demodulator circuits',
  },
  {
    c: 'H03D 13',
    d: 'Circuits for comparing the phase or frequency of two mutually-independent oscillations',
  },
  {
    c: 'H03D 99',
    d: 'n.a.',
  },
  {
    c: 'H03F',
    d: 'AMPLIFIERS',
  },
  {
    c: 'H03F 1',
    d: 'Details of amplifiers with only discharge tubes, only semiconductor devices or only unspecified devices as amplifying elements',
  },
  {
    c: 'H03F 3',
    d: 'Amplifiers with only discharge tubes or only semiconductor devices as amplifying elements',
  },
  {
    c: 'H03F 5',
    d: 'Amplifiers with both discharge tubes and semiconductor devices as amplifying elements',
  },
  {
    c: 'H03F 7',
    d: 'Parametric amplifiers',
  },
  {
    c: 'H03F 9',
    d: 'Magnetic amplifiers',
  },
  {
    c: 'H03F 11',
    d: 'Dielectric amplifiers',
  },
  {
    c: 'H03F 13',
    d: 'Amplifiers using amplifying element consisting of two mechanically- or acoustically-coupled transducers, e.g. telephone-microphone amplifier',
  },
  {
    c: 'H03F 15',
    d: 'Amplifiers using galvano-magnetic effects not involving mechanical movement, e.g. using Hall effect',
  },
  {
    c: 'H03F 17',
    d: 'Amplifiers using electroluminescent element or photocell',
  },
  {
    c: 'H03F 19',
    d: 'Amplifiers using superconductivity effects',
  },
  {
    c: 'H03F 99',
    d: 'n.a.',
  },
  {
    c: 'H03G',
    d: 'CONTROL OF AMPLIFICATION',
  },
  {
    c: 'H03G 1',
    d: 'Details of arrangements for controlling amplification',
  },
  {
    c: 'H03G 3',
    d: 'Gain control in amplifiers or frequency changers',
  },
  {
    c: 'H03G 5',
    d: 'Tone control or bandwidth control in amplifiers',
  },
  {
    c: 'H03G 7',
    d: 'Volume compression or expansion in amplifiers',
  },
  {
    c: 'H03G 9',
    d: 'Combinations of two or more types of control, e.g. gain control and tone control',
  },
  {
    c: 'H03G 11',
    d: 'Limiting amplitude; Limiting rate of change of amplitude',
  },
  {
    c: 'H03G 99',
    d: 'n.a.',
  },
  {
    c: 'H03H',
    d: 'IMPEDANCE NETWORKS, e.g. RESONANT CIRCUITS; RESONATORS',
  },
  {
    c: 'H03H 1',
    d: 'Constructional details of impedance networks whose electrical mode of operation is not specified or applicable to more than one type of network',
  },
  {
    c: 'H03H 2',
    d: 'Networks using elements or techniques not provided for in groups H03H0003000000-H03H0021000000',
  },
  {
    c: 'H03H 3',
    d: 'Apparatus or processes specially adapted for the manufacture of impedance networks, resonating circuits, resonators',
  },
  {
    c: 'H03H 5',
    d: 'One-port networks comprising only passive electrical elements as network components',
  },
  {
    c: 'H03H 7',
    d: 'Multiple-port networks comprising only passive electrical elements as network components',
  },
  {
    c: 'H03H 9',
    d: 'Networks comprising electromechanical or electro-acoustic elements; Electromechanical resonators',
  },
  {
    c: 'H03H 11',
    d: 'Networks using active elements',
  },
  {
    c: 'H03H 15',
    d: 'Transversal filters',
  },
  {
    c: 'H03H 17',
    d: 'Networks using digital techniques',
  },
  {
    c: 'H03H 19',
    d: 'Networks using time-varying elements, e.g. N-path filters',
  },
  {
    c: 'H03H 21',
    d: 'Adaptive networks',
  },
  {
    c: 'H03J',
    d: 'TUNING RESONANT CIRCUITS; SELECTING RESONANT CIRCUITS',
  },
  {
    c: 'H03J 1',
    d: 'Details of adjusting, driving, indicating, or mechanical control arrangements for resonant circuits in general',
  },
  {
    c: 'H03J 3',
    d: 'Continuous tuning',
  },
  {
    c: 'H03J 5',
    d: 'Discontinuous tuning; Selecting predetermined frequencies; Selecting frequency bands with or without continuous tuning in one or more of the bands, e.g. push-button tuning, turret tuner',
  },
  {
    c: 'H03J 7',
    d: 'Automatic frequency control; Automatic scanning over a band of frequencies',
  },
  {
    c: 'H03J 9',
    d: 'Remote-control of tuned circuits; Combined remote-control of tuning and other functions, e.g. brightness, amplification',
  },
  {
    c: 'H03K',
    d: 'PULSE TECHNIQUE',
  },
  {
    c: 'H03K 3',
    d: 'Circuits for generating electric pulses; Monostable, bistable or multistable circuits',
  },
  {
    c: 'H03K 4',
    d: 'Generating pulses having essentially a finite slope or stepped portions',
  },
  {
    c: 'H03K 5',
    d: 'Manipulation of pulses not covered by one of the other main groups of this subclass',
  },
  {
    c: 'H03K 6',
    d: 'Manipulating pulses having a finite slope and not covered by one of the other main groups of this subclass',
  },
  {
    c: 'H03K 7',
    d: 'Modulating pulses with a continuously-variable modulating signal',
  },
  {
    c: 'H03K 9',
    d: 'Demodulating pulses which have been modulated with a continuously-variable signal',
  },
  {
    c: 'H03K 11',
    d: 'Transforming types of modulation, e.g. position-modulated pulses into duration-modulated pulses',
  },
  {
    c: 'H03K 12',
    d: 'Producing pulses by distorting or combining sinusoidal waveforms',
  },
  {
    c: 'H03K 17',
    d: 'Electronic switching or gating, i.e. not by contact-making and -breaking',
  },
  {
    c: 'H03K 19',
    d: 'Logic circuits, i.e. having at least two inputs acting on one output',
  },
  {
    c: 'H03K 21',
    d: 'Details of pulse counters or frequency dividers',
  },
  {
    c: 'H03K 23',
    d: 'Pulse counters comprising counting chains; Frequency dividers comprising counting chains',
  },
  {
    c: 'H03K 25',
    d: 'Pulse counters with step-by-step integration and static storage; Analogous frequency dividers',
  },
  {
    c: 'H03K 27',
    d: 'Pulse counters in which pulses are continuously circulated in a closed loop; Analogous frequency dividers',
  },
  {
    c: 'H03K 29',
    d: 'Pulse counters comprising multi-stable elements, e.g. for ternary scale, for decimal scale; Analogous frequency dividers',
  },
  {
    c: 'H03K 99',
    d: 'n.a.',
  },
  {
    c: 'H03L',
    d: 'AUTOMATIC CONTROL, STARTING, SYNCHRONISATION, OR STABILISATION OF GENERATORS OF ELECTRONIC OSCILLATIONS OR PULSES',
  },
  {
    c: 'H03L 1',
    d: 'Stabilisation of generator output against variations of physical values, e.g. power supply',
  },
  {
    c: 'H03L 3',
    d: 'Starting of generators',
  },
  {
    c: 'H03L 5',
    d: 'Automatic control of voltage, current, or power',
  },
  {
    c: 'H03L 7',
    d: 'Automatic control of frequency or phase; Synchronisation',
  },
  {
    c: 'H03L 9',
    d: 'Automatic control not provided for in other groups of this subclass',
  },
  {
    c: 'H03M',
    d: 'CODING, DECODING OR code CONVERSION, IN GENERAL',
  },
  {
    c: 'H03M 1',
    d: 'Analogue/digital conversion; Digital/analogue conversion',
  },
  {
    c: 'H03M 3',
    d: 'Conversion of analogue values to or from differential modulation',
  },
  {
    c: 'H03M 5',
    d: 'Conversion of the form of the representation of individual digits',
  },
  {
    c: 'H03M 7',
    d: 'Conversion of a code where information is represented by a given sequence or number of digits to a code where the same information is represented by a different sequence or number of digits',
  },
  {
    c: 'H03M 9',
    d: 'Parallel/series conversion or vice versa',
  },
  {
    c: 'H03M 11',
    d: 'Coding in connection with keyboards or like devices, i.e. coding of the position of operated keys',
  },
  {
    c: 'H03M 13',
    d: 'Coding, decoding or code conversion, for error detection or error correction; Coding theory basic assumptions; Coding bounds; Error probability evaluation methods; Channel models; Simulation or testing of codes',
  },
  {
    c: 'H03M 99',
    d: 'n.a.',
  },
  {
    c: 'H04',
    d: 'ELECTRIC COMMUNICATION TECHNIQUE',
  },
  {
    c: 'H04B',
    d: 'TRANSMISSION',
  },
  {
    c: 'H04B 1',
    d: 'Details of transmission systems, not covered by a single one of groups H04B0003000000-H04B0013000000; Details of transmission systems not characterised by the medium used for transmission',
  },
  {
    c: 'H04B 3',
    d: 'Line transmission systems',
  },
  {
    c: 'H04B 5',
    d: 'Near-field transmission systems, e.g. inductive loop type',
  },
  {
    c: 'H04B 7',
    d: 'Radio transmission systems, i.e. using radiation field',
  },
  {
    c: 'H04B 10',
    d: 'Transmission systems employing electromagnetic waves other than radio-waves, e.g. infrared, visible or ultraviolet light, or employing corpuscular radiation, e.g. quantum communication',
  },
  {
    c: 'H04B 11',
    d: 'Transmission systems employing ultrasonic, sonic or infrasonic waves',
  },
  {
    c: 'H04B 13',
    d: 'Transmission systems characterised by the medium used for transmission, not provided for in groups H04B0003000000-H04B0011000000',
  },
  {
    c: 'H04B 14',
    d: 'Transmission systems not characterised by the medium used for transmission',
  },
  {
    c: 'H04B 15',
    d: 'Suppression or limitation of noise or interference',
  },
  {
    c: 'H04B 17',
    d: 'Monitoring; Testing',
  },
  {
    c: 'H04H',
    d: 'BROADCAST COMMUNICATION',
  },
  {
    c: 'H04H 20',
    d: 'Arrangements for broadcast or for distribution combined with broadcast',
  },
  {
    c: 'H04H 40',
    d: 'Arrangements specially adapted for receiving broadcast information',
  },
  {
    c: 'H04H 60',
    d: 'Arrangements for broadcast applications with a direct linkage to broadcast information or to broadcast space-time; Broadcast-related systems',
  },
  {
    c: 'H04J',
    d: 'MULTIPLEX COMMUNICATION',
  },
  {
    c: 'H04J 1',
    d: 'Frequency-division multiplex systems',
  },
  {
    c: 'H04J 3',
    d: 'Time-division multiplex systems',
  },
  {
    c: 'H04J 4',
    d: 'Combined time-division and frequency-division multiplex systems',
  },
  {
    c: 'H04J 7',
    d: 'Multiplex systems in which the amplitudes or durations of the signals in individual channels are characteristic of those channels',
  },
  {
    c: 'H04J 9',
    d: 'Multiplex systems in which each channel is represented by a different type of modulation of the carrier',
  },
  {
    c: 'H04J 11',
    d: 'Orthogonal multiplex systems',
  },
  {
    c: 'H04J 13',
    d: 'code division multiplex systems',
  },
  {
    c: 'H04J 14',
    d: 'Optical multiplex systems',
  },
  {
    c: 'H04J 99',
    d: 'n.a.',
  },
  {
    c: 'H04K',
    d: 'SECRET COMMUNICATION; JAMMING OF COMMUNICATION',
  },
  {
    c: 'H04K 1',
    d: 'Secret communication',
  },
  {
    c: 'H04K 3',
    d: 'Jamming of communication; Counter-measures',
  },
  {
    c: 'H04L',
    d: 'TRANSMISSION OF DIGITAL INFORMATION, e.g. TELEGRAPHIC COMMUNICATION',
  },
  {
    c: 'H04L 1',
    d: 'Arrangements for detecting or preventing errors in the information received',
  },
  {
    c: 'H04L 5',
    d: 'Arrangements affording multiple use of the transmission path',
  },
  {
    c: 'H04L 7',
    d: 'Arrangements for synchronising receiver with transmitter',
  },
  {
    c: 'H04L 9',
    d: 'Arrangements for secret or secure communication',
  },
  {
    c: 'H04L 12',
    d: 'Data switching networks',
  },
  {
    c: 'H04L 13',
    d: 'Details of the apparatus or circuits covered by groups H04L0015000000 or H04L0017000000',
  },
  {
    c: 'H04L 15',
    d: 'Apparatus or local circuits for transmitting or receiving dot-and-dash codes, e.g. Morse code',
  },
  {
    c: 'H04L 17',
    d: 'Apparatus or local circuits for transmitting or receiving codes wherein each character is represented by the same number of equal-length code elements, e.g. Baudot code',
  },
  {
    c: 'H04L 19',
    d: 'Apparatus or local circuits for step-by-step systems',
  },
  {
    c: 'H04L 21',
    d: 'Apparatus or local circuits for mosaic printer telegraph systems',
  },
  {
    c: 'H04L 23',
    d: 'Apparatus or local circuits for telegraphic systems other than those covered by groups H04L0015000000-H04L0021000000',
  },
  {
    c: 'H04L 25',
    d: 'Baseband systems',
  },
  {
    c: 'H04L 27',
    d: 'Modulated-carrier systems',
  },
  {
    c: 'H04L 29',
    d: 'Arrangements, apparatus, circuits or systems, not covered by a single one of groups H04L0001000000-H04L0027000000',
  },
  {
    c: 'H04M',
    d: 'TELEPHONIC COMMUNICATION',
  },
  {
    c: 'H04M 1',
    d: 'Substation equipment, e.g. for use by subscribers',
  },
  {
    c: 'H04M 3',
    d: 'Automatic or semi-automatic exchanges',
  },
  {
    c: 'H04M 5',
    d: 'Manual exchanges',
  },
  {
    c: 'H04M 7',
    d: 'Arrangements for interconnection between switching centres',
  },
  {
    c: 'H04M 9',
    d: 'Arrangements for interconnection not involving centralised switching',
  },
  {
    c: 'H04M 11',
    d: 'Telephonic communication systems specially adapted for combination with other electrical systems',
  },
  {
    c: 'H04M 13',
    d: 'Party-line systems',
  },
  {
    c: 'H04M 15',
    d: 'Arrangements for metering, time-control or time-indication',
  },
  {
    c: 'H04M 17',
    d: 'Prepayment telephone systems',
  },
  {
    c: 'H04M 19',
    d: 'Current supply arrangements for telephone systems',
  },
  {
    c: 'H04M 99',
    d: 'n.a.',
  },
  {
    c: 'H04N',
    d: 'PICTORIAL COMMUNICATION, e.g. TELEVISION',
  },
  {
    c: 'H04N 1',
    d: 'Scanning, transmission or reproduction of documents or the like, e.g. facsimile transmission; Details thereof',
  },
  {
    c: 'H04N 3',
    d: 'Scanning details of television systems; Combination thereof with generation of supply voltages',
  },
  {
    c: 'H04N 5',
    d: 'Details of television systems',
  },
  {
    c: 'H04N 7',
    d: 'Television systems',
  },
  {
    c: 'H04N 9',
    d: 'Details of colour television systems',
  },
  {
    c: 'H04N 11',
    d: 'Colour television systems',
  },
  {
    c: 'H04N 13',
    d: 'Stereoscopic video systems; Multi-view video systems; Details thereof',
  },
  {
    c: 'H04N 17',
    d: 'Diagnosis, testing or measuring for television systems or their details',
  },
  {
    c: 'H04N 19',
    d: 'Methods or arrangements for coding, decoding, compressing or decompressing digital video signals',
  },
  {
    c: 'H04N 21',
    d: 'Selective content distribution, e.g. interactive television or video on demand [VOD]',
  },
  {
    c: 'H04N 101',
    d: 'Indexing scheme associated with groups H04N0001000000-H04N0017000000, relating to still video cameras.',
  },
  {
    c: 'H04Q',
    d: 'SELECTING',
  },
  {
    c: 'H04Q 1',
    d: 'Details of selecting apparatus or arrangements',
  },
  {
    c: 'H04Q 3',
    d: 'Selecting arrangements',
  },
  {
    c: 'H04Q 5',
    d: 'Selecting arrangements wherein two or more subscriber stations are connected by the same line to the exchange',
  },
  {
    c: 'H04Q 9',
    d: 'Arrangements in telecontrol or telemetry systems for selectively calling a substation from a main station, in which substation desired apparatus is selected for applying a control signal thereto or for obtaining measured values therefrom',
  },
  {
    c: 'H04Q 11',
    d: 'Selecting arrangements for multiplex systems',
  },
  {
    c: 'H04R',
    d: 'LOUDSPEAKERS, MICROPHONES, GRAMOPHONE PICK-UPS OR LIKE ACOUSTIC ELECTROMECHANICAL TRANSDUCERS; DEAF-AID SETS; PUBLIC ADDRESS SYSTEMS',
  },
  {
    c: 'H04R 1',
    d: 'Details of transducers',
  },
  {
    c: 'H04R 3',
    d: 'Circuits for transducers',
  },
  {
    c: 'H04R 5',
    d: 'Stereophonic arrangements',
  },
  {
    c: 'H04R 7',
    d: 'Diaphragms for electromechanical transducers; Cones',
  },
  {
    c: 'H04R 9',
    d: 'Transducers of moving-coil, moving-strip, or moving-wire type',
  },
  {
    c: 'H04R 11',
    d: 'Transducers of moving-armature or moving-core type',
  },
  {
    c: 'H04R 13',
    d: 'Transducers having an acoustic diaphragm of magnetisable material directly co-acting with electromagnet',
  },
  {
    c: 'H04R 15',
    d: 'Magnetostrictive transducers',
  },
  {
    c: 'H04R 17',
    d: 'Piezo-electric transducers; Electrostrictive transducers',
  },
  {
    c: 'H04R 19',
    d: 'Electrostatic transducers',
  },
  {
    c: 'H04R 21',
    d: 'Variable-resistance transducers',
  },
  {
    c: 'H04R 23',
    d: 'Transducers other than those covered by groups H04R0009000000-H04R0021000000',
  },
  {
    c: 'H04R 25',
    d: 'Deaf-aid sets',
  },
  {
    c: 'H04R 27',
    d: 'Public address systems',
  },
  {
    c: 'H04R 29',
    d: 'Monitoring arrangements; Testing arrangements',
  },
  {
    c: 'H04R 31',
    d: 'Apparatus or processes specially adapted for the manufacture of transducers or diaphragms therefor',
  },
  {
    c: 'H04S',
    d: 'STEREOPHONIC SYSTEMS',
  },
  {
    c: 'H04S 1',
    d: 'Two-channel systems',
  },
  {
    c: 'H04S 3',
    d: 'Systems employing more than two channels, e.g. quadraphonic',
  },
  {
    c: 'H04S 5',
    d: 'Pseudo-stereo systems, e.g. in which additional channel signals are derived from monophonic signals by means of phase shifting, time delay or reverberation',
  },
  {
    c: 'H04S 7',
    d: 'Indicating arrangements; Control arrangements, e.g. balance control',
  },
  {
    c: 'H04W',
    d: 'WIRELESS COMMUNICATION NETWORKS',
  },
  {
    c: 'H04W 4',
    d: 'Services specially adapted for wireless communication networks; Facilities therefor',
  },
  {
    c: 'H04W 8',
    d: 'Network data management',
  },
  {
    c: 'H04W 12',
    d: 'Security arrangements, e.g. access security or fraud detection; Authentication, e.g. verifying user identity or authorisation; Protecting privacy or anonymity',
  },
  {
    c: 'H04W 16',
    d: 'Network planning, e.g. coverage or traffic planning tools; Network deployment, e.g. resource partitioning or cell structures',
  },
  {
    c: 'H04W 24',
    d: 'Supervisory, monitoring or testing arrangements',
  },
  {
    c: 'H04W 28',
    d: 'Network traffic or resource management',
  },
  {
    c: 'H04W 36',
    d: 'Handoff or reselecting arrangements',
  },
  {
    c: 'H04W 40',
    d: 'Communication routing or communication path finding',
  },
  {
    c: 'H04W 48',
    d: 'Access restriction',
  },
  {
    c: 'H04W 52',
    d: 'Power management, e.g. TPC [Transmission Power Control], power saving or power classes',
  },
  {
    c: 'H04W 56',
    d: 'Synchronisation arrangements',
  },
  {
    c: 'H04W 60',
    d: 'Affiliation to network, e.g. registration; Terminating affiliation with the network, e.g. de-registration',
  },
  {
    c: 'H04W 64',
    d: 'Locating users or terminals for network management purposes, e.g. mobility management',
  },
  {
    c: 'H04W 68',
    d: 'User notification, e.g. alerting or paging, for incoming communication, change of service or the like',
  },
  {
    c: 'H04W 72',
    d: 'Local resource management, e.g. selection or allocation of wireless resources or wireless traffic scheduling',
  },
  {
    c: 'H04W 74',
    d: 'Wireless channel access, e.g. scheduled or random access',
  },
  {
    c: 'H04W 76',
    d: 'Connection management',
  },
  {
    c: 'H04W 80',
    d: 'Wireless network protocols or protocol adaptations to wireless operation',
  },
  {
    c: 'H04W 84',
    d: 'Network topologies',
  },
  {
    c: 'H04W 88',
    d: 'Devices specially adapted for wireless communication networks, e.g. terminals, base stations or access point devices',
  },
  {
    c: 'H04W 92',
    d: 'Interfaces specially adapted for wireless communication networks',
  },
  {
    c: 'H04W 99',
    d: 'n.a.',
  },
  {
    c: 'H05',
    d: 'ELECTRIC TECHNIQUES NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'H05B',
    d: 'ELECTRIC HEATING; ELECTRIC LIGHTING NOT OTHERWISE PROVIDED FOR',
  },
  {
    c: 'H05B 1',
    d: 'Heating',
  },
  {
    c: 'H05B 3',
    d: 'Ohmic-resistance heating',
  },
  {
    c: 'H05B 6',
    d: 'Heating by electric, magnetic, or electromagnetic fields',
  },
  {
    c: 'H05B 7',
    d: 'Heating by electric discharge',
  },
  {
    c: 'H05B 11',
    d: 'Heating by combined application of processes covered by two or more of groups H05B0003000000-H05B0007000000',
  },
  {
    c: 'H05B 31',
    d: 'Lighting',
  },
  {
    c: 'H05B 33',
    d: 'Electroluminescent light sources',
  },
  {
    c: 'H05B 35',
    d: 'Electric light sources using a combination of different types of light generation',
  },
  {
    c: 'H05B 37',
    d: 'Circuit arrangements for electric light sources in general',
  },
  {
    c: 'H05B 39',
    d: 'Circuit arrangements or apparatus for operating incandescent light sources and not adapted to a particular application',
  },
  {
    c: 'H05B 41',
    d: 'Circuit arrangements or apparatus for igniting or operating discharge lamps',
  },
  {
    c: 'H05B 43',
    d: 'Circuit arrangements for light sources, not otherwise provided for',
  },
  {
    c: 'H05C',
    d: 'ELECTRIC CIRCUITS OR APPARATUS SPECIALLY DESIGNED FOR USE IN EQUIPMENT FOR KILLING, STUNNING, ENCLOSING OR GUIDING LIVING BEINGS',
  },
  {
    c: 'H05C 1',
    d: 'Circuits or apparatus for generating electric shock effects',
  },
  {
    c: 'H05C 3',
    d: 'Other circuits or apparatus',
  },
  {
    c: 'H05F',
    d: 'STATIC ELECTRICITY; NATURALLY-OCCURRING ELECTRICITY',
  },
  {
    c: 'H05F 1',
    d: 'Preventing the formation of electrostatic charges',
  },
  {
    c: 'H05F 3',
    d: 'Carrying-off electrostatic charges',
  },
  {
    c: 'H05F 7',
    d: 'Use of naturally-occurring electricity',
  },
  {
    c: 'H05G',
    d: 'X-RAY TECHNIQUE',
  },
  {
    c: 'H05G 1',
    d: 'X-ray apparatus involving X-ray tubes; Circuits therefor',
  },
  {
    c: 'H05G 2',
    d: 'Apparatus or processes specially adapted for producing X-rays, not involving X-ray tubes, e.g. involving generation of a plasma',
  },
  {
    c: 'H05H',
    d: 'PLASMA TECHNIQUE',
  },
  {
    c: 'H05H 1',
    d: 'Generating plasma; Handling plasma',
  },
  {
    c: 'H05H 3',
    d: 'Production or acceleration of neutral particle beams, e.g. molecular or atomic beams',
  },
  {
    c: 'H05H 5',
    d: 'Direct voltage accelerators; Accelerators using single pulses',
  },
  {
    c: 'H05H 6',
    d: 'Targets for producing nuclear reactions',
  },
  {
    c: 'H05H 7',
    d: 'Details of devices of the types covered by groups H05H0009000000-H05H0013000000',
  },
  {
    c: 'H05H 9',
    d: 'Linear accelerators',
  },
  {
    c: 'H05H 11',
    d: 'Magnetic induction accelerators, e.g. betatrons',
  },
  {
    c: 'H05H 13',
    d: 'Magnetic resonance accelerators; Cyclotrons',
  },
  {
    c: 'H05H 15',
    d: 'Methods or devices for acceleration of charged particles not otherwise provided for',
  },
  {
    c: 'H05K',
    d: 'PRINTED CIRCUITS; CASINGS OR CONSTRUCTIONAL DETAILS OF ELECTRIC APPARATUS; MANUFACTURE OF ASSEMBLAGES OF ELECTRICAL COMPONENTS',
  },
  {
    c: 'H05K 1',
    d: 'Printed circuits',
  },
  {
    c: 'H05K 3',
    d: 'Apparatus or processes for manufacturing printed circuits',
  },
  {
    c: 'H05K 5',
    d: 'Casings, cabinets or drawers for electric apparatus',
  },
  {
    c: 'H05K 7',
    d: 'Constructional details common to different types of electric apparatus',
  },
  {
    c: 'H05K 9',
    d: 'Screening of apparatus or components against electric or magnetic fields',
  },
  {
    c: 'H05K 10',
    d: 'Arrangements for improving the operating reliability of electronic equipment, e.g. by providing a similar stand-by unit',
  },
  {
    c: 'H05K 11',
    d: 'Combinations of a radio or television receiver with apparatus having a different main function',
  },
  {
    c: 'H05K 13',
    d: 'Apparatus or processes specially adapted for manufacturing or adjusting assemblages of electric components',
  },
  {
    c: 'H99',
    d: 'n.a.',
  },
  {
    c: 'H99Z',
    d: 'n.a.',
  },
  {
    c: 'H99Z 99',
    d: 'n.a.',
  },
]
