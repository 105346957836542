import { deburr, filter, escapeRegExp } from 'lodash'
import { DropdownItemProps } from 'semantic-ui-react'

export const ipcSearchFn = (options: DropdownItemProps[], query: string): DropdownItemProps[] => {
  // Taken from react-semantic-ui, extended for search in values field (to match 'us' for 'united states')
  const strippedQuery = deburr(query)

  // Default search
  let regex = new RegExp(escapeRegExp(strippedQuery), 'i')

  // If query seems to be (parts of) an ipc code
  if (
    /^[abcdefgh]$/i.test(strippedQuery) ||
    /^[abcdefgh]\d/i.test(strippedQuery) ||
    /^[abcdefgh]\d\d/i.test(strippedQuery) ||
    /^[abcdefgh]\d\d[a-z] ?\d/i.test(strippedQuery) ||
    /^[abcdefgh]\d\d[a-z] ?\d\d/i.test(strippedQuery)
  ) {
    regex = new RegExp(`^${escapeRegExp(strippedQuery)}`, 'i')
  }

  const filteredOptions: DropdownItemProps[] = filter(options, (opt) => regex.test(deburr(opt.desc as string)))
  return filteredOptions
}
