import { FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { observer } from 'mobx-react-lite'

import { DefaultProductBtnProps } from './IpButton'
import ProductButtonWrap from './ProductButtonWrap'
import { CustomButton } from '..'

type Props = DefaultProductBtnProps & { onToggleFamilyType: (familyType: FamilyType) => void }

const TrademarkButton = ({
  isMobile,
  minimizedSideBar,
  isAuthenticated,
  hasFamilyType,
  applicationStore,
  isSmallHight,
  onToggleFamilyType,
  ...rest
}: Props) => {
  const handleClick = () => {
    if (!applicationStore.hasTrademarksPermission) applicationStore.setModal({ open: true, version: '' })
    else onToggleFamilyType('T')
  }
  return (
    <CustomButton
      active={hasFamilyType('T') && !hasFamilyType('P')}
      handleClick={handleClick}
      translationKey="sidebar.buttons.trademarks"
      disabled={isAuthenticated ? false : true}
      btnColor="#ffc000"
      showText={!isMobile && (isSmallHight || minimizedSideBar) ? false : true}
      {...rest}
    />
  )
}

export default ProductButtonWrap(observer(TrademarkButton))
