import React from 'react'

import { Trans } from '@lingui/react'
import UpgradeImage from '@pp/assets/upgradeModalImage.webp'
import { errorMessageCss, h1Css, h2Css, h4Css } from '@pp/common/css/error-page.css'
import { Grid, Image, Message } from 'semantic-ui-react'

function ErrorPage() {
  return (
    <Message floating className={errorMessageCss}>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={10} verticalAlign="middle">
            <h1 className={h1Css}>
              <Trans id="errorPage.title.text" />
            </h1>
            <h2 className={h2Css}>
              <Trans id="errorPage.subTitle.text" />
            </h2>
            <h4 className={h4Css}>
              <Trans id="errorPage.paragraph.text" />
            </h4>
          </Grid.Column>
          <Grid.Column width={6}>
            <Image src={UpgradeImage} alt="upgrade-image" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Message>
  )
}

export default ErrorPage
