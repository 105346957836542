import { MappedFilterType } from '@pp/common/components/filters/typescript/filters.type'
import _ from 'lodash'

export const mapValInhouseFilters = (mappedFilters: MappedFilterType) => {
  const clonedFilters = _.cloneDeep(mappedFilters)
  if (!clonedFilters.val_inhouse || !clonedFilters.validation) return clonedFilters

  if ((clonedFilters.val_inhouse[0] as string).includes('!')) {
    if (clonedFilters.val_inhouse.includes('!INTERNAL' as never)) clonedFilters.validation.ep_rep_inhouse = [true]
    else clonedFilters.validation.val_inhouse = [true]
  } else {
    if (clonedFilters.val_inhouse.includes('INTERNAL' as never)) clonedFilters.validation.val_inhouse = [true]
    else clonedFilters.validation.ep_rep_inhouse = [true]
  }
  delete clonedFilters.val_inhouse
  return clonedFilters
}
