import React, { ReactNode, useContext } from 'react'

import { DeviceInfo } from '@pp/common/typescript/tracking.type'
import {
  osVersion,
  osName,
  browserVersion,
  browserName,
  mobileVendor,
  mobileModel,
  engineName,
  getUA,
  deviceType,
} from 'react-device-detect'

type InitialState = { deviceInfo: DeviceInfo }
type Props = { children: ReactNode }

const initialState: InitialState = {
  deviceInfo: {
    osVersion: '',
    osName: '',
    browserVersion: '',
    browserName: '',
    mobileVendor: '',
    mobileModel: '',
    engineName: '',
    getUA: '',
    deviceType: '',
  },
}

const DeviceInfoContext = React.createContext(initialState)
const useDeviceInfo = () => useContext(DeviceInfoContext)

export const DeviceInfoProvider = ({ children }: Props) => {
  const deviceInfo =
    mobileModel === 'none'
      ? { osVersion, osName, browserVersion, browserName, engineName, getUA, deviceType }
      : {
          osVersion,
          osName,
          browserVersion,
          browserName,
          mobileVendor,
          mobileModel,
          engineName,
          getUA,
          deviceType,
        }

  return <DeviceInfoContext.Provider value={{ deviceInfo }}>{children}</DeviceInfoContext.Provider>
}

export { useDeviceInfo }
