import { MappedFilterType, SearchFilters } from '@pp/common/components/filters/typescript/filters.type'
import _ from 'lodash'

import { mapEpValSearchType } from './map-ep-val-search-type'
import { mapEpValType } from './map-ep-val-type'
import { mapLocationFilters } from './map-location-filters'
import { mapStandardNegativeFilters } from './map-standard-negative-filters'
import { mapValInhouseFilters } from './map-val-inhouse-filters'
import { mapValidationTypes } from './map-validation-types'
import { removeEmptyPropsFromMappedFilters } from './remove-empty-props-from-mapped-filters'

const MAX_NUMBER_OF_FILINGS = 1000000

const mapSearchFiltersPipe =
  (
    mapEpValSearchType: (mappedFilters: MappedFilterType) => MappedFilterType,
    mapValInhouseFilters: (mappedFilters: MappedFilterType) => MappedFilterType,
  ) =>
  (response: MappedFilterType) =>
    mapValInhouseFilters(mapEpValSearchType(response))

export const mapSearchFilters = <T>(filters: T) => {
  const mappedFilters = _.cloneDeep(filters) as unknown as SearchFilters
  mappedFilters.applicant = {}
  mappedFilters.validation = {}

  if (mappedFilters.relevant_count_from && !mappedFilters.relevant_count_to)
    mappedFilters.relevant_count_to = [MAX_NUMBER_OF_FILINGS]

  const partialMappedFilters = mapSearchFiltersPipe(mapEpValSearchType, mapValInhouseFilters)(mappedFilters)

  const standardNegativeFiltersMapping = mapStandardNegativeFilters(
    [
      { prop: 'lifecycle' as const, values: ['!DEAD', 'DEAD', 'ALIVE'] },
      { prop: 'representative_type' as const, values: ['!INTERNAL', 'INTERNAL', 'EXTERNAL'] },
      { prop: 'grant_status' as const, values: ['!GRANTED', 'GRANTED', 'NOT_GRANTED'] },
      { prop: 'route' as const, values: ['!DIR', 'DIR', 'PCT'] },
      { prop: 'applicant_origin' as const, values: ['!DOMESTIC', 'DOMESTIC', 'FOREIGN'] },
      { prop: 'int_decision_maker' as const, values: ['!A', 'A', 'LF'] },
      { prop: 'decision_maker' as const, values: ['!A', 'A', 'LF'] },
      { prop: 'nice_category' as const, values: ['!GOODS', 'GOODS', 'SERVICES'] },
    ],
    partialMappedFilters,
  )

  const epValTypeMapping = mapEpValType(standardNegativeFiltersMapping)

  const locationBoundsFilterMapping = mapLocationFilters(
    [
      'rep_geo_bounds',
      'applicant.applicant_geo_bounds',
      'int_rep_geo_bounds',
      'dom_rep_geo_bounds',
      'foreign_rep_geo_bounds',
    ],
    epValTypeMapping,
  )

  const validationFiltersMapping = mapValidationTypes(
    [
      { prop: 'ep_rep_url' as const, options: [] },
      { prop: 'ep_rep_country' as const, options: [] },
      { prop: 'applicant_url' as const, options: [], isAppl: true },
      { prop: 'val_rep_url' as const, options: [] },
      { prop: 'val_appln_auth' as const, options: [] },
      { prop: 'applicant_country' as const, options: [], isAppl: true },
      {
        prop: 'val_decision_maker' as const,
        options: ['A', 'LF'],
      },
      { prop: 'val_service_provider' as const, options: [] },
      { prop: 'val_rep_country' as const, options: [] },
    ],
    locationBoundsFilterMapping,
  )
  const removedEmptyPropsMapping = removeEmptyPropsFromMappedFilters(
    ['applicant', 'validation'],
    validationFiltersMapping,
  )

  delete removedEmptyPropsMapping.locale

  return removedEmptyPropsMapping
}
