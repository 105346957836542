import React, { ReactNode, useContext } from 'react'

import { gql, useQuery } from '@apollo/client'
import DefaultFailure from '@pp/common/components/default-failure/DefaultFailure'
import Spinner from '@pp/common/components/spinner/Spinner'

export const GET_USER_DATA = gql`
  query {
    getUser {
      user_id
      name
      email
      last_login
      logins_count
      created_at
      app_metadata {
        created_by
        permissions
        products
        versions
      }
      user_metadata {
        first_name
        last_name
        organization
      }
    }
  }
`

const initialState = {
  userInfo: {
    userId: '',
    userName: '',
    email: '',
    organization: '',
    details: {
      createdAt: '',
      createdBy: '',
      loginsCount: '',
      lastLogin: '',
    },
    app_metadata: {
      permissions: [],
      products: [],
      versions: [],
    },
    user_metadata: {
      first_name: '',
      last_name: '',
      organization: '',
    },
    rawData: {
      user_id: '',
      name: '',
      email: '',
      last_login: '',
      logins_count: 0,
      created_at: '',
      app_metadata: {
        created_by: '',
        permissions: [],
        products: [],
        versions: [],
      },
      user_metadata: {
        first_name: '',
        last_name: '',
        organization: '',
      },
    },
  },
}

const UserContext = React.createContext(initialState)
const useUser = () => useContext(UserContext)

type Props = { children: ReactNode }

export const UserProvider = ({ children }: Props) => {
  const { data: { getUser = {} } = {}, loading, error } = useQuery(GET_USER_DATA)

  if (loading) return <Spinner />
  if (error) return <DefaultFailure error={error} />

  window.analytics.identify(getUser.user_id, {
    name: getUser.user_metadata
      ? `${getUser.user_metadata.first_name} ${getUser.user_metadata.last_name}`
      : getUser.name,
    email: getUser.email,
    company: getUser.user_metadata && getUser.user_metadata.organization,
    account: getUser.user_metadata && getUser.user_metadata.organization,
    createdAt: getUser.created_at,
    firstName: getUser.user_metadata && getUser.user_metadata.first_name,
    lastName: getUser.user_metadata && getUser.user_metadata.last_name,
    id: getUser.user_id,
  })

  if (getUser.user_metadata)
    window.analytics.group(getUser.user_metadata.organization, {
      name: getUser.user_metadata && getUser.user_metadata.organization,
    })

  const userInfo = {
    userId: getUser.user_id,
    userName:
      getUser.user_metadata && getUser.user_metadata.first_name && getUser.user_metadata.last_name
        ? `${getUser.user_metadata.first_name} ${getUser.user_metadata.last_name}`
        : getUser && getUser.name
        ? getUser.name
        : '',
    email: getUser.email,
    organization: getUser.user_metadata && getUser.user_metadata.organization ? getUser.user_metadata.organization : '',
    details: {
      createdAt: getUser.created_at,
      createdBy: getUser.app_metadata && getUser.app_metadata.created_by,
      loginsCount: getUser.logins_count,
      lastLogin: getUser.last_login,
    },
    user_metadata: getUser.user_metadata
      ? {
          first_name: getUser.user_metadata.first_name ? getUser.user_metadata.first_name : '',
          last_name: getUser.user_metadata.last_name ? getUser.user_metadata.last_name : '',
          organization: getUser.user_metadata.organization ? getUser.user_metadata.organization : '',
        }
      : {
          first_name: '',
          last_name: '',
          organization: '',
        },
    app_metadata: getUser.app_metadata
      ? {
          permissions: getUser.app_metadata.permissions ? getUser.app_metadata.permissions : [],
          products: getUser.app_metadata.products ? getUser.app_metadata.products : [],
          versions: getUser.app_metadata.versions ? getUser.app_metadata.versions : [],
        }
      : {
          permissions: [],
          products: [],
          versions: [],
        },
    rawData: getUser,
  }

  return <UserContext.Provider value={{ userInfo }}>{children}</UserContext.Provider>
}

export { useUser }
