const fetchRequest = async (url: string, options: RequestInit | undefined) => {
  const defaultOptions = {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.token}` },
  }
  const mergedOptions = { ...defaultOptions, ...options }
  try {
    const res = await fetch(url, mergedOptions)
    if (!res.ok) throw new Error(res.statusText)
    const contentType = res.headers.get('content-type')
    // Handle successful response
    if (res.status === 204) return res
    if (contentType && contentType.includes('application/json')) return await res.json()
    // Handle non-JSON response (e.g., plain text or HTML)
    return await res.text()
  } catch (err) {
    console.error(`Error occurred while fetching data: ${err}`)
  }
}

export const apiService = { fetchRequest }
