import { ReactNode } from 'react'

import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { messages as messagesEn } from '@pp/i18n/locales/en-us/messages.js'
import { messages as messagesCn } from '@pp/i18n/locales/zh-cn/messages.js'
import { LanguageEnum } from '@pp/modules/analyse/common/types/app.type'
import { useStores } from '@pp/store/useStore.hook'
import { en, zh } from 'make-plural/plurals'
import { observer } from 'mobx-react-lite'

function CustomI18nProvider({ children }: { children: ReactNode }) {
  const { applicationStore } = useStores()
  const search = window.location.search
  const urlSearchParams = new URLSearchParams(search)
  const localLocale = localStorage.getItem('locale')
  const defaultLocalLocale = localLocale && (localLocale === 'en-US' || localLocale === 'zh-CN') && localLocale
  const urlLocale = urlSearchParams.get('locale')
  const defaultUrlLocale = urlLocale && (urlLocale === 'en-US' || urlLocale === 'zh-CN') && urlLocale
  const locale = defaultUrlLocale || defaultLocalLocale || 'en-US'
  i18n.loadLocaleData({ 'en-US': { plurals: en } })
  i18n.loadLocaleData({ 'zh-CN': { plurals: zh } })
  i18n.load({ 'en-US': messagesEn, 'zh-CN': messagesCn })
  applicationStore.setLanguage(locale as LanguageEnum)
  i18n.activate(locale)

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>
}

export default observer(CustomI18nProvider)
