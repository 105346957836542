import { TrackingEvent } from '@pp/common/typescript/tracking.type'
import _ from 'lodash'

type SortLayer = (dataLayer: TrackingEvent[]) => TrackingEvent[]

const sortLayer = (dataLayer: TrackingEvent[]) =>
  dataLayer.sort((a: TrackingEvent, b: TrackingEvent) => b.date - a.date)

const layerPipe =
  (deepClone: (value: TrackingEvent[]) => TrackingEvent[], sortLayer: SortLayer) => (args: TrackingEvent[]) =>
    sortLayer(deepClone(args))

export const getSortedEvents = layerPipe(_.cloneDeep, sortLayer)
