import { auth0 } from './auth0-production'
import { Environment } from './environment.interface'

export const environment: Environment = {
  name: 'production',
  websocketUrl: 'https://frontend-pdf-export.ip-pilot.com',
  graphqlApiUrl: 'https://api.ip-pilot.com/graphql',
  staticApiUrl: 'https://static.ip-pilot.com',
  trackingApiUrl: 'https://infrastructure-um-api.patent-pilot.com/userSession',
  segmentWriteKey: 'WXZvdUJnaWZJR0Z5UjFqR09mTzJVZ2pLckRiMXdrNmQ6',
  publicGAKey: 'G-GL7G9T3YS2',
  appGAKey: 'G-N8KMELP5TJ',
  auth0,
}
