import { Suspense, lazy, useEffect, useRef } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import ErrorPage from '@pp/common/components/error-pages/404/ErrorPage'
import Auth0ErrorPage from '@pp/common/components/error-pages/auth0-errors/Auth0ErrorPage'
import Spinner from '@pp/common/components/spinner/Spinner'
import { useEventTracker } from '@pp/common/custom-hooks/trackers/useEventTracker.hook'
import { compareFilters, extractFilters } from '@pp/common/helpers'
import { Route as RouteName } from '@pp/common/helpers/routing'
import { Filters } from '@pp/common/typescript/helpers.type'
import { BuilderPurpose } from '@pp/modules/analyse/common/types/app.type'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { Location } from 'history'
import { observer } from 'mobx-react-lite'
import { Switch, Route, useHistory, Redirect } from 'react-router'

//Home (Dashboard Module)
const HomePage = lazy(() => import('@pp/modules/main/home-page/HomePage'))
const Home = lazy(() => import('@pp/modules/main/MainContainer'))

// Analyze modules
const Applicants = lazy(() => import('@pp/modules/analyse/applicants/ApplicantsContainer'))
// const Attorneys = lazy(() => import('@pp/modules/analyse/attorneys/AttorneysContainer'))
const LawFirms = lazy(() => import('@pp/modules/analyse/law-firms/LawFirmContainer'))

// Search modules
const AdvancedSearch = lazy(() => import('@pp/modules/search/advanced-search/AdvancedSearchContainer'))
// const SmartSearch = lazy(() => import('@pp/modules/search/smart-search/SmartSearchContainer'))

// Check modules
// const Filing = lazy(() => import('@pp/modules/check/filing/FilingContainer'))
// const Conflict = lazy(() => import('@pp/modules/check/conflict/ConflictContainer'))
// const Instruction = lazy(() => import('@pp/modules/check/instruction/InstructionContainer'))
const Whitespot = lazy(() => import('@pp/modules/check/whitespot/WhitespotContainer'))
const FilingCheck = lazy(() => import('@pp/modules/check/filing/FilingContainer'))
const Mutuality = lazy(() => import('@pp/modules/check/mutuality/MutualityContainer'))
const Similarity = lazy(() => import('@pp/modules/check/similarity/SimilarityContainer'))

// Explore modules
// const Comparisons = lazy(() => import('@pp/modules/explore/comparisons/ComparisonsContainer'))

// Resources modules
const DataCoverage = lazy(() => import('@pp/modules/resources/data-coverage/DataCoverageContainer'))
// const WhatsNew = lazy(() => import('@pp/modules/resources/updates/UpdatesContainer'))

// Build your own charts
const BuildYourOwnCharts = lazy(() => import('@pp/modules/byoc/BuildYourOwnChartsContainer'))

// Help module
// const Help = lazy(() => import('@pp/modules/help/HelpContainer'))

// Settings module
const Settings = lazy(() => import('@pp/modules/settings/SettingsContainer'))

function Router() {
  const history = useHistory()
  const { handleEventTracker } = useEventTracker()
  const tempFilter = useRef<Filters[]>()
  const location = history.location
  const { filterStore, applicationStore, routerStore } = useStores()
  const { user, isAuthenticated, isLoading } = useAuth0()

  useEffect(() => {
    // If there are query parameters initialize them
    if (location.pathname.indexOf('law-firms') > -1 || location.pathname.indexOf('applicants')) {
      if (location.search || location.pathname.split('/').length > 2) {
        filterStore.initializeFiltersFromUrl(location.pathname as RouteName, location.search)
      }
    }

    if (location.pathname.indexOf('/print') > -1) {
      applicationStore.setPdfExport(true)
    }

    applicationStore.setPermissions(user)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  useEffect(() => {
    trackPageView() // To track the first pageview upon load
    history.listen(trackPageView) // To track the subsequent pageviews
    history.listen(setCorrectBuilderPurpose)
    history.listen(setLanguageLocale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  function setCorrectBuilderPurpose(location: Location) {
    const localLocation: Location = location

    if (localLocation.pathname.includes('/network')) {
      applicationStore.setBuilderPurpose(BuilderPurpose.CASE_EXCHANGE)
    } else if (localLocation.pathname.includes('/ep-validations')) {
      applicationStore.setBuilderPurpose(BuilderPurpose.VALIDATION)
    } else applicationStore.setBuilderPurpose(BuilderPurpose.OVERVIEW)
  }

  function setLanguageLocale(location: Location) {
    const localLocation: Location = location

    if (!localLocation.search.includes('locale')) {
      const urlSearchParams = new URLSearchParams(routerStore.location.search)
      urlSearchParams.set('locale', applicationStore.language)
      routerStore.replace(`${routerStore.location.pathname}?${urlSearchParams.toString()}`)
    }
  }

  function trackPageView() {
    const extractedFilters = extractFilters(window.location.href)

    if (tempFilter.current === undefined) {
      tempFilter.current = extractedFilters
    } else {
      const hasSameFilters = compareFilters(tempFilter.current, extractedFilters)
      if (!hasSameFilters) {
        if (extractedFilters)
          extractedFilters.forEach((el: Filters) => {
            const filter = Object.entries(el)[0].join(' ')
            handleEventTracker({ event: { type: 'used-filter', filter } })
          })
        tempFilter.current = extractedFilters
      }
    }

    handleEventTracker({ event: { type: 'page-view' } })

    // window.analytics.page()
  }

  if (isLoading || applicationStore.permissionsFetched === null) return null

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path="/">
          <Redirect to={routes.homepageRoute} />
        </Route>
        <Route exact path="/home-page" component={HomePage} />
        <Route path="/dashboards" component={Home} />
        <Route path={routes.agentsRoute} component={LawFirms} />
        <Route path={routes.applicantsRoute} component={Applicants} />
        <Route path={routes.advancedSearchRoute} component={AdvancedSearch} />
        <Route path="/advanced-search">
          <Redirect to={routes.advancedSearchRoute} />
        </Route>
        <Route path={routes.whitespotCheckRoute} component={Whitespot} />
        <Route path={routes.filingCheckRoute} component={FilingCheck} />
        <Route path={routes.mutualityRoute} component={Mutuality} />
        <Route path={routes.similarityRoute} component={Similarity} />
        <Route path={routes.dataCoverageRoute} component={DataCoverage} />
        <Route path={routes.settingsRoute} component={Settings} />
        <Route path={routes.buildYourOwnChartsRoute} component={BuildYourOwnCharts} />
        {!isAuthenticated ? <Route path="/callback" component={Auth0ErrorPage} /> : null}
        <Route component={ErrorPage} />
      </Switch>
    </Suspense>
  )
}

export default observer(Router)
