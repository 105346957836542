import { PageInfo } from '@pp/common/typescript/helpers.type'

export const getPageInfo = (pathName: string | never): PageInfo => {
  const splitted = pathName.split('/')
  const result: PageInfo = { containerName: '' }

  splitted.forEach((el: string, index: number) => {
    if (el !== '') {
      switch (index) {
        case 1:
          result.containerName = el
          break
        case 2:
          result.pageName = el
          break
        case 3:
          result.tabName = el
      }
    }
  })
  return result
}
