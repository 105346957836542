import { useEffect, useState } from 'react'

type Props<T> = { initialState: T; options?: RequestInit | undefined; url: string }

export const useFetch = <T>({ url, options, initialState }: Props<T>) => {
  const [response, setResponse] = useState<T>(initialState)
  const [error, setError] = useState<null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const controller = new AbortController()

  const fetchData = async () => {
    setIsLoading(true)
    fetch(url, { signal: controller.signal, ...options })
      .then((res) => {
        if (res.status === 204) return res
        return res.json().then((data) => {
          if (res.ok) return data
          throw new Error(data.message)
        })
      })
      .then(setResponse)
      .catch((err) => {
        setError(err.message)
        return Promise.reject(err)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    fetchData()
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { response, error, isLoading }
}
