import { FilterStore, FilterType } from '@pp/common/components/filters/filter.store'
import { EntityFilter } from '@pp/common/components/filters/typescript/filters.type'
import * as R from 'ramda'

export function toggleFilter(
  value: string | number | boolean,
  valueCount: number,
  filterType: EntityFilter,
  filterStore: FilterStore,
  keepFilter?: boolean,
) {
  const newFilters: FilterType = {}
  const toggleValues = [filterStore.negativeFilter ? `!${value}` : value]

  const activeFilterValues = filterStore.activeFilters[filterType] || []
  const filtersToAdd = R.without(activeFilterValues, toggleValues)
  const filtersToRemove = R.intersection(toggleValues, toggleValues)

  const newFilterValues = R.union(filtersToAdd, R.without(filtersToRemove, activeFilterValues))

  let removeFilter: boolean

  if (keepFilter) removeFilter = R.isEmpty(newFilterValues)
  else removeFilter = R.isEmpty(newFilterValues) || R.length(newFilterValues) === valueCount

  // Remove filter if a) filtered value is none or b) all possible values are filtered, which makes the filter obsolete
  // const removeFilter = R.isEmpty(newFilterValues) || R.length(newFilterValues) === valueCount

  if (removeFilter) filterStore.removeFilter(filterType, value as string)
  else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newFilters[filterType] = newFilterValues
    filterStore.updateFilters(newFilters)
  }
}
