import { t } from '@lingui/macro'
import EntityLabel from '@pp/common/components/entity-label/EntityLabel'
import { EntityFilter } from '@pp/common/components/filters/typescript/filters.type'
import { getNameOfField, getNameOfSector } from '@pp/modules/analyse/common/charts/technical-fields/technical-sectors'
import _ from 'lodash'

import { getDecisionMakerType } from '..'

const getFamilyType = (value: string) => {
  switch (value) {
    case 'T':
      return 'Trademarks'
    case 'P':
      return 'Patents'
  }
}

const getDateType = (value: string) => {
  switch (value) {
    case 'm':
      return t`yearTypeOptions.prosecutionYear`
    case 'p':
      return t`yearTypeOptions.publicationYear`
    case 'f':
      return t`yearTypeOptions.filingYear`
    case 'g':
      return t`yearTypeOptions.grantYear`
    case 't':
      return t`yearTypeOptions.caseExchangeYear`
    default:
      return value
  }
}

const getLocalizedSequenceString = (sequence: number): string => {
  switch (sequence) {
    case 1:
      return t`data.attributes.typeOfFiling.1`
    case 2:
      return t`data.attributes.typeOfFiling.2`
    case 4:
      return t`data.attributes.typeOfFiling.4`
    case 5:
      return t`data.attributes.typeOfFiling.5`
    case 6:
      return t`data.attributes.typeOfFiling.6`
    default:
      return ''
  }
}

const getLocalizedLegalStateString = (legalState: string): string => {
  switch (legalState) {
    case 'Unknown':
      return t`data.attributes.legalState.unknown`
    case 'Pending':
      return t`data.attributes.legalState.pending`
    case 'Expired':
      return t`data.attributes.legalState.expired`
    case 'Revoked':
      return t`data.attributes.legalState.revoked`
    case 'In force':
      return t`data.attributes.legalState.inForce`
    case 'Lapsed before grant':
      return t`data.attributes.legalState.lapsedBeforeGrant`
    case 'Lapsed after grant':
      return t`data.attributes.legalState.lapsedAfterGrant`
    case 'Opposed':
      return t`data.attributes.legalState.opposed`
    case 'Refused':
      return t`data.attributes.legalState.refused`
    case 'Granted':
      return t`data.attributes.legalState.granted`
    default:
      return legalState
  }
}

export const getLabelValue = ({
  filterType,
  value,
}: {
  filterType: EntityFilter | 'entities' | 'entityType'
  value: string
}) => {
  switch (filterType) {
    case 'lifecycle':
      return value === 'DEAD' ? t`data.attributes.alive.false` : t`data.attributes.alive.true`
    case 'decision_maker':
    case 'int_decision_maker':
      return value === 'LF' ? t`data.attributes.applicantDecision.false` : t`data.attributes.applicantDecision.true`
    case 'route':
      return value === 'DIR' ? t`data.attributes.filedViaPCT.false` : t`data.attributes.filedViaPCT.true`
    case 'grant_status':
      return value === 'NOT_GRANTED' ? t`data.attributes.granted.false` : t`data.attributes.granted.true`
    case 'direction':
      return value === 'RECEIVED' ? t`data.attributes.transfer.receivedCases` : t`data.attributes.transfer.sentCases`
    case 'representative_type':
      return value === 'INTERNAL'
        ? t`data.attributes.representativeType.internal`
        : t`data.attributes.representativeType.external`
    case 'applicant_origin':
      return value === 'FOREIGN' ? t`data.attributes.domesticFiling.false` : t`data.attributes.domesticFiling.true`
    case 'techn_field_nr':
      return getNameOfField(value)
    case 'techn_sector_nr':
      return getNameOfSector(value)
    case 'sequence':
      return getLocalizedSequenceString(Number(value))
    case 'legal_state':
      return getLocalizedLegalStateString(value)
    case 'rep_url':
    case 'applicant_url':
    case 'dom_rep_url':
    case 'int_rep_url':
    case 'ep_val_rep_url':
    case 'val_rep_url':
    case 'ep_val_applicant_url':
    case 'ep_val_foreign_rep_url':
    case 'foreign_rep_url':
    case 'entities':
      return <EntityLabel urlIdentifier={value} key={value} />
    case 'entityType':
      return _.capitalize(value)
    case 'val_decision_maker':
      return getDecisionMakerType(value)
    case 'attorney_url':
      return value
        .split('-')
        .map((entry) => entry.charAt(0).toUpperCase() + entry.slice(1))
        .join(' ')
    case 'date_type':
      return getDateType(value)
    case 'ep_val_type':
      return _.capitalize(value)
    case 'family_type':
      return getFamilyType(value)
    default:
      return value
  }
}
