import { TrackingEvent } from '@pp/common/typescript/tracking.type'
import _ from 'lodash'

import {
  checkIfSameEventAndDescription,
  getChartsRenderDuration,
  getPreviousDataFetchingEvents,
  getReducedChartsRenderDuration,
  getRenderedCharts,
  getSortedEvents,
} from '.'

const handleChartFetchingDuration = (chartsRendered: TrackingEvent[], previousDataFetchingEvents: TrackingEvent[]) => {
  const chartsRenderDuration = getChartsRenderDuration(chartsRendered, previousDataFetchingEvents)
  const combinedChartsRenderDuration = getReducedChartsRenderDuration(chartsRenderDuration)

  Object.values(combinedChartsRenderDuration).forEach((event) => {
    const dataToSend = { ..._.cloneDeep(event) }

    dataToSend.event.type = 'chart-fetching-duration'

    // SEGMENT.IO
    window.analytics.track(dataToSend.event.type, dataToSend)
  })
}

export const handleDataFetchingDuration = (event: TrackingEvent, dataLayer: TrackingEvent[]) => {
  if (checkIfSameEventAndDescription(event, dataLayer, 'search-submitted')) {
    const sortedEvents = getSortedEvents(dataLayer)
    const previousDataFetchingEvents = getPreviousDataFetchingEvents(event, sortedEvents)

    if (previousDataFetchingEvents.length > 1) {
      const renderedCharts = getRenderedCharts(previousDataFetchingEvents)
      const isCharts = !renderedCharts.some((chart) => !chart.event.chart)

      if (isCharts) handleChartFetchingDuration(renderedCharts, previousDataFetchingEvents)

      const totalLoadingTime =
        previousDataFetchingEvents[0].date - previousDataFetchingEvents[previousDataFetchingEvents.length - 1].date

      const dataToSend = { ..._.cloneDeep(previousDataFetchingEvents[previousDataFetchingEvents.length - 1]) }

      dataToSend.event.type = 'data-fetching-duration'
      dataToSend.event.duration = totalLoadingTime / 1000

      // SEGMENT.IO
      window.analytics.track(dataToSend.event.type, dataToSend)
    }
  }
}
