export type CaseFlowNode<A> = {
  country: string
  isApplicant: boolean
  name: string
  nodeWeight: number
  nodes: A[]
  profile: boolean
  urlIdentifier?: string
}

export type CaseFlowFinal = CaseFlowNode<never>

export type Caseflow = CaseFlowNode<CaseFlowFinal>[]

export enum CaseFlowTypes {
  Edges = 'edges',
  Nodes = 'nodes',
}

export enum CaseflowDirection {
  Inbound,
  Outbound,
  Unknown,
}

export type TParsedNode = {
  color?: string
  colorIndex?: number | string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  dataLabels?: string
  from: string
  hideInLegend?: boolean
  id?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  key: any
  name?: string
  outgoing?: boolean
  parent?: string
  sortIndex?: string
  to: string
  tooltipName?: string
  value?: number
  weight: number
}

type CaseFlowAgentMetadata = {
  rep_country: string
  rep_inhouse: boolean
  rep_name: string
  rep_url: string
}

type CaseFlowApplicantMetadata = {
  applicant_country: string
  applicant_isorg: boolean
  applicant_name: string
  applicant_url: string
}

type CaseFlowValidationAgentMetadata = {
  val_rep_country: string
  val_rep_inhouse: boolean
  val_rep_name: string
  val_rep_url: string
}

export type TCaseFlowMetadata = CaseFlowAgentMetadata & CaseFlowApplicantMetadata & CaseFlowValidationAgentMetadata

export type TMappedCaseFlowMetadata = {
  country: string
  isApplicant: boolean
  name: string
  value: string
}

export type TCaseFlowBase = {
  country: string
  isApplicant: boolean
  name: string
  nodeWeight: number
  urlIdentifier: string
}

export type TCaseFlowRecursive = TCaseFlowBase & {
  nodes?: TCaseFlowRecursive[]
}

export type TCaseflow = {
  data: TCaseFlowRecursive[]
  metadata: TCaseFlowMetadata[]
}

//
type TEntity<K> = {
  color?: string
  colorIndex?: number
  hideInLegend?: boolean
  key: K
  sortIndex?: string
}

type TTree<K = string> = TEntity<K> & {
  data: Array<TTree<K> | TPoint<K>>
  name: string
  value?: number
}

type TPoint<K = string> = TEntity<K> & {
  name: string
  value: number
}

// TODO: replace outdated types wth these guys #157
type TNode<K = string> = TTree<K> | TPoint<K>

type TEdge = {
  from: string
  isOutgoing?: boolean
  to: string
  tooltipName?: string
  weight: number
}

export type TChartNodes = {
  edges: TEdge[]
  nodes: TNode<string>[]
}
