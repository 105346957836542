import { css } from '@emotion/css'
import Logo from '@pp/assets/ip-logo.svg'
import { Dimmer, Header, Icon, Image } from 'semantic-ui-react'

import { increaseSpecificity } from './common/css/css.helper'

export const iconCss = css`
  ${increaseSpecificity(`
  padding: 20px;
`)}
`

export const subheaderCss = css`
  ${increaseSpecificity(`
  padding: 20px;
`)}
`

export const logoCss = css`
  width: 200px;
  margin: 0 auto;
`

export const dimmerCss = css`
  background-color: rgba(0, 0, 0, 0.5);
`

const AdBlockWarning = () => {
  return (
    <Dimmer className={dimmerCss} active={true} page>
      <Image className={logoCss} src={Logo} alt="logo" />
      <Header as="h2" icon inverted>
        <Icon className={iconCss} name="ban" />
        It appears you are using a browser extension which blocks scripts from executing (e.g. uBlock Origin).
        <Header.Subheader className={subheaderCss}>
          Such scripts are essential for our application. Please disable the extension and refresh the page to start
          using IP-Pilot. If this did not work, please send us a message to{' '}
          <a href="mailto:info@ip-pilot.com">info@ip-pilot.com</a>.
        </Header.Subheader>
      </Header>
    </Dimmer>
  )
}

export default AdBlockWarning
