import React from 'react'

import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import UpgradeImage from '@pp/assets/upgradeModalImage.webp'
import { errorMessageCss, h1Css, h4Css } from '@pp/common/css/error-page.css'
import { useLocation } from 'react-router'
import { Grid, Image, Message } from 'semantic-ui-react'

import ErrorPage from '../404/ErrorPage'

function Auth0ErrorPage() {
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  let isUserBlocked = false
  for (const entry of urlSearchParams.entries()) {
    if (entry[1] === 'user is blocked') isUserBlocked = true
  }

  if (!isUserBlocked) return <ErrorPage />

  return (
    <Message floating className={errorMessageCss}>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={10} verticalAlign="middle">
            <h1 className={h1Css}>
              <Trans id="auth0ErrorPage.title.text" />
            </h1>
            <h4 className={h4Css} dangerouslySetInnerHTML={{ __html: t`auth0ErrorPage.paragraph.text` }} />
          </Grid.Column>
          <Grid.Column width={6}>
            <Image src={UpgradeImage} alt="upgrade-image" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Message>
  )
}

export default Auth0ErrorPage
